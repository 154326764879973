import { CheckCircleFilled } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { Alert, Button, Checkbox, message } from "antd";
import { changePlan, createCheckoutSession } from "api/stripe";
import classNames from "classnames";
import { CouponConfig } from "fixtures/coupons";
import { useEffect, useMemo, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getUserDetails } from "store/user/userActions";
import { selectUserInfo } from "store/user/userSlice";
import useGlobalModal from "../GlobalModalContext/useGlobalModal";
import {
  DEFAULT_YEARLY_DISCOUNT,
  PlanKey,
  PLANS_DICT,
  SubscriptionInterval,
} from "./subscriptionConstants";
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

type PlanCardProps = {
  planKey: PlanKey;
  interval: SubscriptionInterval;
  activeCoupons: CouponConfig[];
  successPath: string;
  loadingPlan: PlanKey | null;
  setLoadingPlan: (name: PlanKey | null) => void;
  closeModal: () => void;
};

const applyDiscount = (applyTo: number, discountPercent: number) =>
  Math.ceil(applyTo * (1 - discountPercent));

const PlanCard = ({
  planKey,
  interval,
  activeCoupons,
  successPath,
  loadingPlan,
  setLoadingPlan,
  closeModal,
}: PlanCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { subscription, isAnonymous } = useAppSelector(selectUserInfo);
  const { openGlobalModal } = useGlobalModal();

  const [appliedCoupon, setAppliedCoupon] = useState<CouponConfig | null>(null);

  const isCurrentPlan = subscription?.plan?.includes(planKey);

  const planConfig = useMemo(() => PLANS_DICT[planKey], [planKey]);

  useEffect(() => {
    appliedCoupon?.limitToIntervals &&
      !appliedCoupon.limitToIntervals.includes(interval) &&
      setAppliedCoupon(null);
  }, [interval]);

  const { isYearly, couponSavings, currentPrice, crossOutMonthly } =
    useMemo(() => {
      const isYearly = interval === "yearly";
      const monthlyPrice = planConfig.monthlyPrice;

      const discountPercent = activeCoupons.length
        ? appliedCoupon?.discountPercent ?? 0
        : isYearly
          ? DEFAULT_YEARLY_DISCOUNT
          : 0;

      const oneMonthSaving =
        monthlyPrice - applyDiscount(monthlyPrice, discountPercent);

      return {
        isYearly,
        couponSavings: isYearly ? oneMonthSaving * 12 : oneMonthSaving,
        currentPrice: discountPercent
          ? applyDiscount(monthlyPrice, discountPercent)
          : monthlyPrice,
        crossOutMonthly: Boolean(
          discountPercent && (appliedCoupon || isYearly)
        ),
      };
    }, [planConfig.monthlyPrice, interval, appliedCoupon]);

  const handleClick = async () => {
    if (isAnonymous) {
      closeModal && closeModal();

      return openGlobalModal({
        modalName: "signup",
        modalData: {
          successCallback: () => initiateStripeCheckout(),
          closeAfterSuccessCallback: true,
        },
      });
    }

    initiateStripeCheckout();
  };

  // TODO: Duplicate code/function name with AnonymousUserCheckout
  const initiateStripeCheckout = async () => {
    setLoadingPlan(planKey);
    try {
      const stripe = await stripePromise;

      if (subscription?.plan === "free") {
        const session = await createCheckoutSession(
          {
            plan: `${planKey}-${interval}`,
          },
          {
            ...(successPath && {
              successUrl: `${
                window.location.origin
              }/welcome?nextPage=${encodeURIComponent(successPath)}`,
            }),
          },
          appliedCoupon?.couponCode
        );
        const result = await stripe?.redirectToCheckout({
          sessionId: session.sessionId,
        });

        if (result?.error) {
          console.error("Error redirecting to Stripe:", result.error);
        }
      } else {
        await changePlan({
          plan: `${planKey}-${interval}`,
        });
        await dispatch(getUserDetails());
        message.success("Plan upgraded successfully.");
        setLoadingPlan(null);
        closeModal && closeModal();
        successPath && navigate(successPath);
      }
    } catch (error) {
      console.error("Error initiating Stripe Checkout:", error);
      setLoadingPlan(null);
    }
  };

  return (
    <div
      className={classNames("plan-card plan-card--large gap-4", {
        "plan-card--gradient": planConfig.isPrimaryOption,
      })}
      key={planConfig.name}
    >
      {planConfig.isMostPopular && (
        <div className="most-popular">Most Popular</div>
      )}
      <div>
        <div className="plan-card__top">
          <span className="plan-card__top__best-selling">
            {planConfig.name}
          </span>
        </div>

        <div className="plan-card__price">
          {crossOutMonthly && (
            <span className="plan-card__price__old-price">
              ${planConfig.monthlyPrice}
            </span>
          )}
          {/* Use interval as a key to force a re-render when the website is being translated */}
          <span key={interval} className="plan-card__name">
            ${currentPrice}{" "}
            <span>
              {appliedCoupon
                ? isYearly
                  ? "/month for the first year"
                  : "for the first month"
                : "/month"}
            </span>
          </span>
          <p className="plan-card__price__name">{planConfig.subtitle}</p>
        </div>
        <div className="plan-card__perks">
          {planConfig.features.map((feature) => (
            <div className="plan-card__perks__perk" key={feature}>
              <BsCheckLg />
              <span className="ml-2">{feature}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-3">
        {!isCurrentPlan &&
          activeCoupons.map((coupon) => {
            const isApplied = appliedCoupon?.couponCode === coupon.couponCode;
            return (
              <Alert
                key={coupon.couponCode}
                type={isApplied ? "success" : "info"}
                className="py-2 px-4 items-center"
                message={
                  <div
                    onClick={() => !isApplied && setAppliedCoupon(coupon)}
                    className={classNames("flex items-center gap-4", {
                      "cursor-pointer": !isApplied,
                    })}
                  >
                    {isApplied ? (
                      <CheckCircleFilled className="text-antd-colorSuccess [&_svg]:size-5" />
                    ) : (
                      <Checkbox />
                    )}

                    <div className="space-y-0.5">
                      <div className="text-sm">
                        {isApplied ? (
                          <>
                            <strong>{coupon.displayName}</strong> applied
                          </>
                        ) : (
                          <>
                            Apply <strong>{coupon.displayName}</strong> coupon
                          </>
                        )}
                      </div>
                      {couponSavings && appliedCoupon?.discountPercent ? (
                        <div className="text-antd-colorTextSecondary text-[13px]">
                          {isApplied ? "You saved " : "Save "}${couponSavings} (
                          {appliedCoupon.discountPercent * 100}% off first{" "}
                          {isYearly ? "year" : "month"})
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              />
            );
          })}
        <Button
          type={planConfig.isPrimaryOption ? "primary" : undefined}
          onClick={handleClick}
          disabled={Boolean(loadingPlan || isCurrentPlan)}
          loading={loadingPlan === planKey}
          className="subscribe-btn w-full"
        >
          {isCurrentPlan ? "Current Plan" : "Get Started"}
        </Button>
      </div>
    </div>
  );
};

export default PlanCard;
