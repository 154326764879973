import cx from "classnames";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import UpgradePlanBanner from "components/UpgradePlan/UpgradePlanBanner";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { AGENCY_NAV_ITEMS, AGENCY_ROUTES, NAV_ITEMS } from "fixtures/constants";
import { AnimatePresence } from "framer-motion";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { useEffect, useMemo } from "react";
import { MdOutlineAccountCircle } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";
import { currentBusinessGetter, selectUserInfo } from "store/user/userSlice";
import LowImageWarning from "./LowImageWarning";
import MobileHeader from "./MobileHeader";

const Header = ({ hideOnLargeScreen }: { hideOnLargeScreen?: boolean }) => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const {
    headerChildrenState: [headerChildren],
  } = useAppContext();
  const { hasPermission, hasSomePermissions } = useUserPermissions();
  const canReadBusiness = hasPermission("business_details_read");
  const canViewSocialsPage = hasSomePermissions([
    "posting_schedule_write",
    "socials_write",
  ]);
  const { openGlobalModal } = useGlobalModal();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { scheduled } = useAppSelector(selectPostStore);
  const user = useAppSelector(selectUserInfo);
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const isBusinessDashboardPage = useMemo(
    () => AGENCY_ROUTES.includes(pathname),
    [pathname]
  );

  const { pageTitle, PageIcon } = useMemo(() => {
    if (["/manage-account", "/proxy"].includes(pathname)) {
      return { pageTitle: "Manage Account", PageIcon: MdOutlineAccountCircle };
    }

    const items = isBusinessDashboardPage ? AGENCY_NAV_ITEMS : NAV_ITEMS;
    const currentPageItem = items.find((item) => item.pathname === pathname);

    return {
      pageTitle: currentPageItem?.title,
      PageIcon: currentPageItem?.Icon,
    };
  }, [pathname, isBusinessDashboardPage]);

  useEffect(() => {
    const publishedCount =
      currentBusiness?.posts_data?.status_counts?.PUBLISHED ?? 0;

    if (publishedCount + scheduled.count > 2 && user.email === "NO_EMAIL") {
      openGlobalModal({
        modalName: "signup",
      });
    }
  }, [scheduled.count, user, currentBusiness]);

  useEffect(() => {
    if (user.email && import.meta.env.VITE_ENV === "PRODUCTION") {
      // identify user for crisp
      user.email !== "NO_EMAIL" &&
        window.$crisp?.push(["set", "user:email", [user.email]]);
      user?.subscription?.plan &&
        window.$crisp?.push([
          "set",
          "session:data",
          [[["plan", user?.subscription?.plan]]],
        ]);
      user.given_name &&
        window.$crisp?.push([
          "set",
          "user:nickname",
          [`${user.given_name} ${user.family_name}`],
        ]);
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      (pathname === INTEGRATIONS_ROUTE && !canViewSocialsPage) ||
      (!canReadBusiness &&
        ["/topics", "/business", "/branding"].includes(pathname))
    ) {
      navigate(defaultBusinessRoute);
    }
  }, [canViewSocialsPage, canReadBusiness, pathname]);

  return (
    <div
      className={cx("header [grid-area:header] flex flex-col overflow-x-auto", {
        "!border-none lg:!bg-transparent": hideOnLargeScreen,
      })}
    >
      <MobileHeader />
      {pathname !== "/dashboard" && currentBusiness.id && <LowImageWarning />}
      {!hideOnLargeScreen && (
        <div className="w-full grid grid-flow-col auto-cols-[minmax(min-content,_1fr)] items-center gap-4 max-lg:hidden min-h-[65px] py-3 px-4 overflow-x-auto">
          <div
            className={cx("hidden lg:flex items-center", {
              "header__left--themes": pageTitle === "Themes",
            })}
          >
            {PageIcon && <PageIcon className="page-icon" />}

            {pageTitle && <h4>{pageTitle}</h4>}
          </div>
          <AnimatePresence>{headerChildren}</AnimatePresence>
        </div>
      )}
      <UpgradePlanBanner />
    </div>
  );
};

export default Header;
