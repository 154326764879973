import pluralize from "pluralize";
export type MissingImagesMessageProps = {
  count: number;
  className?: string;
};

const MissingImagesMessage = ({
  count,
  className,
}: MissingImagesMessageProps) =>
  count > 0 && (
    <div className={`text-sm font-normal ${className}`}>
      <span className="font-medium">Not seeing an image? </span>
      {pluralize("image", count, true)} {pluralize("was", count)} unable to be
      retrieved.
      <a
        className="block underline"
        target="_blank"
        href="https://help.mymarky.ai/en/article/supported-image-formats-19fcmxj"
      >
        Learn more here.
      </a>
    </div>
  );

export default MissingImagesMessage;
