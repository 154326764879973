import { ColumnsType } from "antd/es/table";
import { MemberResponse, Permissions } from "api/config/chalice-api";
import AvatarInitials from "components/AvatarInitials";
import { MARKY_REALM_ID } from "config/envVars";
import { UseStateReturn } from "fixtures/globalConstants";
import { isEqual } from "lodash";
import { getFullName } from "store/user/userActions";
import { PERMISSIONS_PRESET } from "../EditMemberPermissions/memberPermissionConstants";
import ClientPermissionsColumn from "./ClientPermissionsColumn";

export type TeamsTableEntry = MemberResponse & {
  isOwner?: boolean;
};

export const isClient = (member: MemberResponse) =>
  member.realm_id !== MARKY_REALM_ID;

const getMemberRoleByPermissions = (permissions: Permissions) => {
  if (isEqual(permissions, PERMISSIONS_PRESET.reviewer.permissions)) {
    return PERMISSIONS_PRESET.reviewer.label;
  }

  if (isEqual(permissions, PERMISSIONS_PRESET.contributor.permissions)) {
    return PERMISSIONS_PRESET.contributor.label;
  }

  return "Custom";
};

export const getBaseTeamMemberColumns = ({
  isClientsPage,
  hasMembers,
  isMarkyRealm,
  loadingPreviewForState,
}: {
  isClientsPage: boolean;
  hasMembers: boolean;
  isMarkyRealm: boolean;
  loadingPreviewForState: UseStateReturn<string | null>;
}): ColumnsType<TeamsTableEntry> => [
  {
    title: "Name",
    key: "name",
    width: hasMembers ? 200 : undefined,
    render: (_, member) => (
      <div className="member-details">
        <div className="member-details__dp">
          <AvatarInitials user={member} size={36} />
        </div>
        <div className="member-details__name-and-email">
          <span className="member-details__name">
            {getFullName(member, {
              fallbackToEmail: false,
              fallbackName: "",
            })}
          </span>
          <span className="member-details__email">{member.email}</span>
        </div>
      </div>
    ),
  },
  {
    title: isClientsPage ? "Permissions" : "Role",
    key: "role",
    dataIndex: "role",
    width: hasMembers ? 125 : undefined,
    render: (_, member) => (
      <div className="flex items-center gap-2">
        <span>
          {isClientsPage
            ? getMemberRoleByPermissions(member.permissions)
            : member.isOwner
              ? "Owner"
              : "Member"}
        </span>
        {isClientsPage && isMarkyRealm && (
          <ClientPermissionsColumn {...{ member, loadingPreviewForState }} />
        )}
      </div>
    ),
  },
];
