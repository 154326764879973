import { PublishStatus } from "api/config/chalice-api";
import { PublishErrorKey } from "fixtures/publishErrors";
import { IntegrationType } from "store/user/userConstants";
import { formatSocialName } from "./generalUtils";

export type PublishError = {
  social?: IntegrationType;
  message?: string | null;
  code?: PublishErrorKey;
  name?: string;
};

export type PublishResults = {
  successLinks: { data: Record<string, string>; count: number };
  failedLinks: { data: Record<string, PublishError>; count: number };
  pendingLinks: {
    data: Record<
      string,
      { timeout?: number | null; created_at?: string; state: string }
    >;
    count: number;
  };
};

const EMPTY_PUBLISH_RESULTS = (): PublishResults => ({
  successLinks: { data: {}, count: 0 },
  failedLinks: { data: {}, count: 0 },
  pendingLinks: { data: {}, count: 0 },
});

const groupPublishLinksByState = (publishResults?: {
  [name: string]: PublishStatus;
}) =>
  publishResults
    ? Object.entries(publishResults).reduce(
        (
          acc,
          [key, { post_url: link, state, error_info, created_at, timeout }]
        ) => {
          const formattedSocial = formatSocialName(key);
          if (state === "success") {
            acc.successLinks.data[formattedSocial] = link ?? "";
            acc.successLinks.count += 1;
          } else if (state === "failed") {
            acc.failedLinks.data[formattedSocial] = {
              code: error_info?.error_type,
              message: error_info?.message,
              name: formattedSocial,
              social: key as IntegrationType,
            };
            acc.failedLinks.count += 1;
          } else {
            acc.pendingLinks.data[formattedSocial] = {
              created_at,
              timeout,
              state,
            };
            acc.pendingLinks.count += 1;
          }

          return acc;
        },
        EMPTY_PUBLISH_RESULTS()
      )
    : EMPTY_PUBLISH_RESULTS();

export default groupPublishLinksByState;
