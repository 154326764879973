import { LoadingOutlined } from "@ant-design/icons";
import { Select, Tooltip, message } from "antd";
import { getColorsFromLogo } from "api/businesses";
import { getPresignedUrl } from "api/postsApi";
import axios from "axios";
import cx from "classnames";
import { genericError } from "fixtures/globalConstants";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { currentBusinessGetter, selectUserInfo } from "store/user/userSlice";
import addStaticCacheBusterParam from "utils/addNoCacheParam";
import {
  ACCEPTED_IMAGE_FORMATS_STR,
  validateUploadedFile,
} from "utils/validateFileUpload";
import BrandingImageTooltip from "./BrandingImageTooltip";
import { UploadComponentProps } from "./uploadConstants";

const LOGO_BG_OPTIONS = [
  { value: "#FFFFFF", label: "White" },
  { value: "#000000", label: "Black" },
  { value: "#00000000", label: "None" },
];

const LogoUpload = ({
  isRecreatingAccount,
  isNewClient,
  canEditBusiness,
}: UploadComponentProps) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectUserInfo);
  const { brand, ...business } = useAppSelector(currentBusinessGetter);

  const [logo, setLogo] = useState(
    isRecreatingAccount ? localStorage.logo : undefined
  );
  const [logoImage, setLogoImage] = useState<string | undefined>(
    isRecreatingAccount ? localStorage.logo : undefined
  );
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [logoBg, setLogoBg] = useState(
    business.logo_background_color ?? LOGO_BG_OPTIONS[0].value
  );

  const areColorsNotFilled = useMemo(() => {
    if (isNewClient) {
      return !localStorage.clientColors;
    }

    return (
      !brand.palettes?.length ||
      brand.palettes.every((palette) => !Object.values(palette))
    );
  }, [brand, isNewClient]);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      throw { message: "File is invalid or doesn't exist." };
    } else if (validateUploadedFile(file, "image")) {
      setIsUploadingLogo(true);
      setLogoImage(URL.createObjectURL(file));
      try {
        const { url } = await getPresignedUrl(file);

        await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        setLogo(url.split("?")[0]);
      } catch (error) {
        genericError(error);
      }
      setIsUploadingLogo(false);
    }
  };

  const updateLogo = async (isRemoving?: boolean) => {
    if (
      (!logo || logo === brand?.logo || logo === localStorage.logo) &&
      !isRemoving
    ) {
      return;
    }
    setIsUploadingLogo(true);

    try {
      let colors = {};

      if (areColorsNotFilled) {
        await getColorsFromLogo(logo)
          .then((response) => (colors = response))
          .catch(() => {});
      }

      if (isNewClient) {
        localStorage.setItem("clientLogo", logo);
        Object.keys(colors).length &&
          localStorage.setItem("clientColors", JSON.stringify({ ...colors }));
      } else {
        await dispatch(
          updateBusinessDetails({
            logo: isRemoving ? null : logo,
            ...colors,
          })
        );
      }

      if (isRecreatingAccount) {
        isRemoving
          ? localStorage.removeItem("logo")
          : localStorage.setItem("logo", logo);
      }

      message.success(
        isRemoving ? "Logo removed successfully" : "Logo uploaded successfully."
      );
      isRemoving && setLogoImage(undefined);
    } catch (error) {
      genericError(error);
    }

    setIsUploadingLogo(false);
  };

  const handleRemove = () => {
    if (isUploadingLogo) {
      return;
    }

    updateLogo(true);
  };

  useEffect(() => {
    updateLogo();
  }, [logo]);

  useEffect(() => {
    logoBg !== business.logo_background_color &&
      dispatch(updateBusinessDetails({ logo_background_color: logoBg }));
  }, [logoBg]);

  useEffect(() => {
    if (
      (isRecreatingAccount && localStorage.logo !== brand?.logo) ||
      isNewClient
    ) {
      return;
    }

    setLogo(brand?.logo);
    setLogoImage(brand?.logo);
  }, [userInfo, brand]);

  useEffect(() => {
    if (!isNewClient) {
      return;
    }

    if (isNewClient && localStorage.clientLogo) {
      setLogo(localStorage.clientLogo);
      setLogoImage(localStorage.clientLogo);
    }
  });

  if (isRecreatingAccount && !localStorage.logo) {
    return;
  }

  if (!canEditBusiness) {
    return logoImage ? (
      <div className="logo-upload-container">
        <h5 className="your-brand__section__heading mb-2.5">Logo</h5>
        <div className="logo-container">
          <img
            src={addStaticCacheBusterParam(logoImage)}
            className="logo-image"
          />
        </div>
      </div>
    ) : null;
  }

  return (
    <div className="logo-upload-container">
      <h5 className="your-brand__section__heading mb-2.5 flex gap-1">Logo</h5>
      {!logo && !logoImage ? (
        <label htmlFor="logo-upload" className="logo-upload">
          <BiPlus />
          <input
            accept={ACCEPTED_IMAGE_FORMATS_STR}
            onChange={handleUpload}
            type="file"
            id="logo-upload"
          />
          Upload
        </label>
      ) : (
        <div className="space-y-2">
          <div
            className={cx("logo-container", {
              "logo-container--loading": isUploadingLogo,
            })}
          >
            <img
              src={addStaticCacheBusterParam(logoImage)}
              className="logo-image"
            />
            <Tooltip
              trigger="hover"
              title={isUploadingLogo ? "" : "Upload new logo"}
              placement="right"
            >
              <label htmlFor="logo-edit" className="edit-icon">
                {isUploadingLogo ? (
                  <LoadingOutlined className="loading-icon" />
                ) : (
                  <FiEdit2 />
                )}
                <input
                  accept={ACCEPTED_IMAGE_FORMATS_STR}
                  onChange={handleUpload}
                  type="file"
                  id="logo-edit"
                  disabled={isUploadingLogo}
                />
              </label>
            </Tooltip>
            <Tooltip title="Remove" placement="right">
              <AiFillCloseCircle
                className="remove-icon"
                onClick={handleRemove}
              />
            </Tooltip>
          </div>
          <div className="space-y-1 max-w-fit">
            <label className="text-sm font-medium" htmlFor="logo-bg">
              <BrandingImageTooltip
                image={logo || logoImage}
                description="Set the background that will be used behind your logo when adding it to posts. Preview:"
                background={logoBg}
              >
                Logo Background
              </BrandingImageTooltip>
            </label>
            <Select
              id="logo-bg"
              options={LOGO_BG_OPTIONS}
              value={logoBg}
              onChange={setLogoBg}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoUpload;
