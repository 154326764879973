import { PageLoader } from "designSystem/Loader";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";

const IndexRoutePage = () => {
  const navigate = useNavigate();

  const defaultBusinessRoute = useDefaultBusinessRoute();

  const user = useAppSelector(selectUserInfo);

  useEffect(() => {
    navigate(
      user.id
        ? user.is_agency
          ? "/dashboard"
          : defaultBusinessRoute
        : "/login"
    );
  }, [user]);

  return <PageLoader />;
};

export default IndexRoutePage;
