import { CustomTooltipProps } from "@tremor/react";
import dayjs from "dayjs";
import PercentChangeIndicator from "./PercentChangeIndicator";

const PreviousPeriodChartTooltip = ({
  chartTitle,
  payload,
}: {
  payload: CustomTooltipProps["payload"];
  chartTitle: string;
}) => {
  let currentData, prevData;

  if (payload?.length && payload.length > 1) {
    [prevData, currentData] = payload;
  } else if (payload?.length && payload.length === 1) {
    [currentData] = payload;
  }

  const currentValue = currentData?.dataKey
    ? currentData?.payload?.[currentData.dataKey]
    : undefined;
  const prevValue = prevData?.dataKey
    ? prevData.payload?.[prevData.dataKey]
    : undefined;

  return (
    <div className="bg-antd-colorBgBase rounded shadow-md flex flex-col gap-1 py-2 w-48">
      <div className="border-b border-antd-colorBorderSecondary pb-2 w-full flex justify-between items-center px-2">
        <span className="font-medium">{chartTitle}</span>
        <PercentChangeIndicator
          currentValue={currentValue}
          previousValue={prevValue}
        />
      </div>
      {prevData !== undefined && prevData !== null && (
        <LegendDisplay
          className={`${prevData?.className} order-last`}
          label={prevData.payload?.previousPeriodDate}
          value={prevValue.toLocaleString()}
        />
      )}
      {currentData?.dataKey && (
        <LegendDisplay
          label={dayjs(currentData.payload?.date).format("MMM D, YYYY")}
          className={currentData?.className}
          value={currentValue.toLocaleString()}
        />
      )}
    </div>
  );
};

const LegendDisplay = ({
  label,
  value,
  className,
}: Record<string, string | undefined>) => (
  <div className={`flex justify-between px-2 ${className}`}>
    <div className="flex items-center gap-2 font-normal text-antd-colorTextSecondary">
      <svg width="8" height="8">
        <circle r="0.1" cx="4" cy="4" strokeWidth="8" />
      </svg>
      {label}
    </div>
    <div className="!font-medium">{value}</div>
  </div>
);

export default PreviousPeriodChartTooltip;
