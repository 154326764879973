import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, message } from "antd";

import { getUserDetails } from "store/user/userActions";
import {
  autoSetCurrentBusiness,
  resetUser,
  selectUserInfo,
  setShowOnboardingTour,
  setUserInfo,
} from "store/user/userSlice";

import { trackSignup } from "api/firstpromoter";
import {
  claimAnonymousUser,
  getFrillSSOToken,
  googleLogin,
  googleSignup,
} from "api/user";

import { removeAuthToken, setAuthToken } from "api/config/axiosConfig";

import { ReactComponent as GoogleIcon } from "assets/icons/google-icon.svg";
import { genericError } from "fixtures/globalConstants";
import { SHOW_TOPICS_TOUR } from "fixtures/localStorageKeys";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "store";

const GoogleLogin = ({
  buttonText,
  callback,
}: {
  buttonText: string;
  callback?: () => void;
}) => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const [, setShowTopicsTour] = useLocalStorage(SHOW_TOPICS_TOUR);
  const { isAnonymous } = useAppSelector(selectUserInfo);

  const isFrillSSO = localStorage.isFrillSSO === "true";
  const redirect = localStorage.redirect;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = useCallback(
    async (response: CodeResponse) => {
      setIsLoading(true);
      const isLogin = buttonText === "Log in with Google";
      const isAgencyLogin = !!searchParams.get("accountType");

      try {
        let userDetails;

        if (isAnonymous) {
          userDetails = await claimAnonymousUser({ code: response.code });
        } else if (isLogin) {
          userDetails = await googleLogin(response.code);
        } else {
          userDetails = await googleSignup(response.code);
          trackSignup({
            email: userDetails.user.email ?? "",
            uid: userDetails.user.id,
          });
        }

        if (!isAnonymous) {
          setAuthToken(userDetails.token);
          dispatch(setUserInfo(userDetails));
        }

        const { payload } = await dispatch(getUserDetails());
        dispatch(autoSetCurrentBusiness());

        if (isFrillSSO) {
          const { token } = await getFrillSSOToken();
          localStorage.removeItem("isFrillSSO");
          localStorage.removeItem("redirect");
          window.location.href = `${redirect}?ssoToken=${token}`;
          return;
        }

        message.success(`${isLogin ? "Logged in" : "Signed up"} successfully`);

        if (isLogin) {
          // @ts-expect-error missing types
          if (!payload.businesses[0]?.title) {
            navigate("/business-metadata");
          } else {
            navigate(
              userDetails.user.is_agency ? "/dashboard" : defaultBusinessRoute
            );
          }
        } else {
          window.$crisp?.push(["set", "session:event", ["user:created"]]);
          setShowTopicsTour(true);
          dispatch(setShowOnboardingTour(true));
          navigate(isAgencyLogin ? "/account-details" : defaultBusinessRoute);
          callback && callback();
        }
      } catch (error) {
        localStorage.removeItem("token");
        dispatch(resetUser());
        removeAuthToken();

        if (error instanceof Error && error.message === "User already exists") {
          navigate("/login");
        }

        genericError(error);
      }
      setIsLoading(false);
    },
    [isFrillSSO, redirect, defaultBusinessRoute]
  );

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: handleGoogleLogin,
    onError: () => message.error("Google login failed"),
  });

  if (
    /FBAN|FBAV/i.test(window.navigator.userAgent) ||
    /Instagram/.test(window.navigator.userAgent)
  ) {
    return null;
  }

  return (
    <>
      <Button
        icon={<GoogleIcon className="google-login" />}
        className="google-login-button"
        onClick={login}
        loading={isLoading}
        disabled={isLoading}
        block
      >
        {buttonText}
      </Button>
      <Divider className="auth__divider">OR</Divider>
    </>
  );
};

export default GoogleLogin;
