import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CommentOutlined,
  MoreOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, InputRef, message, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ItemType } from "antd/es/menu/interface";
import {
  deleteComment,
  postComment,
  resolveComment,
  updateComment,
} from "api/comments";
import classNames from "classnames";
import AvatarInitials from "components/AvatarInitials";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { PostCommentData } from "store/posts/postConstants";
import { getFullName } from "store/user/userActions";
import { UserInfo } from "store/user/userConstants";
import { getTimeAgo } from "./postCommentUtil";

type PostCommentProps = {
  postId: string;
  currentUser: UserInfo;
  readonly?: boolean;
  currentComment?: PostCommentData;
  editingComment: "new" | string | null;
  setEditingComment: (commentId: "new" | string | null) => void;
  refetchComments: () => Promise<unknown>;
};

/**
 * Renders a comment on a post. Handles rendering both an existing comment, or a new comment.
 *
 * @param postId The post that this comment belongs to.
 * @param currentUser Signed in user.
 * @param readonly Show in readonly mode. Hides certain buttons.
 * @param currentComment The existing comment to render.
 * @param editingComment The id of another PostComment within the same container that is being edited, or `new` if the comment
 * being edited does not have an id yet. Used to disable certain buttons when a different comment is being edited.
 * @param setEditingComment Callback to set the `editingComment` within the container.
 * @param refetchComments Refetch the comments for this post.
 */
const PostComment = ({
  postId,
  currentUser,
  readonly,
  currentComment,
  editingComment,
  setEditingComment,
  refetchComments,
}: PostCommentProps) => {
  const [commentBody, setCommentBody] = useState(currentComment?.body ?? "");
  const [showBodyInput, setShowBodyInput] = useState(false);
  const [loading, setLoading] = useState<
    "comment" | "delete" | "resolve" | false
  >(false);
  const [commentTimeAgo, setCommentTimeAgo] = useState(
    getTimeAgo(currentComment?.created_at)
  );

  const bodyInputRef = useRef<InputRef>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);

  let updateTimeAgoInterval: ReturnType<typeof setInterval>;
  useEffect(() => {
    const updateTimeAgo = () =>
      setCommentTimeAgo(getTimeAgo(currentComment?.created_at));

    updateTimeAgo();

    clearInterval(updateTimeAgoInterval);
    updateTimeAgoInterval = setInterval(updateTimeAgo, 60000);

    return () => clearInterval(updateTimeAgoInterval);
  }, [currentComment?.created_at]);

  useEffect(() => {
    currentComment?.body && setCommentBody(currentComment.body);
  }, [currentComment?.body]);

  useLayoutEffect(() => {
    if (showBodyInput) {
      setEditingComment(currentComment?.id ?? "new");
      bodyInputRef.current?.focus();
    } else {
      setEditingComment(null);
    }
  }, [showBodyInput]);

  useClickAway(
    inputContainerRef,
    () =>
      ((currentComment && currentComment.body === commentBody) ||
        (!loading && !commentBody)) &&
      setShowBodyInput(false)
  );

  const saveComment = async (resolve?: boolean) => {
    try {
      if (currentComment) {
        if (resolve === undefined) {
          setLoading("comment");

          await updateComment({
            id: currentComment.id,
            body: commentBody,
          });
        } else {
          setLoading("resolve");
          await resolveComment({
            id: currentComment.id,
            resolved: resolve,
          });
        }
      } else {
        setLoading("comment");
        await postComment({ postId, commentBody: commentBody });
      }

      await refetchComments();
      setShowBodyInput(false);
    } catch (error) {
      message.error("Unable to save comment, please try again.");
    }

    !currentComment && setCommentBody("");
    setLoading(false);
  };

  const removeComment = async () => {
    if (!currentComment) {
      return;
    }

    setLoading("delete");
    try {
      await deleteComment(currentComment);
      await refetchComments();
    } catch (error) {
      message.error("Unable to delete comment, please try again.");
    }
    setLoading(false);
  };

  const handleKeyDown = (key: string, shiftKey: boolean) => {
    switch (key) {
      case "Enter":
        !shiftKey && commentBody.trim() && saveComment();
        break;
      case "Escape":
        setShowBodyInput(false);
        setCommentBody(currentComment?.body ?? "");
        break;
    }
  };

  const CURRENT_COMMENT_MENU: ItemType[] | null = useMemo(
    () =>
      !readonly && currentComment && currentComment.user_id === currentUser.id
        ? [
            {
              key: "edit",
              label: "Edit",
              disabled: Boolean(
                editingComment && editingComment !== currentComment.id
              ),
              onClick: () => setShowBodyInput(true),
            },
            { key: "delete", label: "Delete", onClick: removeComment },
          ]
        : null,
    [currentComment, editingComment, currentUser, readonly]
  );

  return (
    <>
      {currentComment || showBodyInput ? (
        <div
          ref={inputContainerRef}
          className={classNames(
            "grid grid-cols-[auto_1fr_auto] gap-y-1 gap-x-2",
            {
              "pr-2": currentComment && showBodyInput,
              "items-center": !showBodyInput,
            }
          )}
        >
          <AvatarInitials
            size="small"
            user={
              currentComment
                ? {
                    ...currentComment,
                    picture: currentComment.profile_pic ?? "",
                  }
                : currentUser
            }
          />

          {showBodyInput ? (
            <>
              <TextArea
                // TODO: Theme config is incorrectly forcing all inputs to be 40px, should make those use 'large' size instead
                className="!min-h-6"
                size="small"
                autoSize
                ref={bodyInputRef}
                value={commentBody}
                readOnly={loading === "comment"}
                placeholder="Add a comment"
                onChange={({ target }) => setCommentBody(target.value)}
                onKeyDown={({ key, shiftKey }) =>
                  !loading && handleKeyDown(key, shiftKey)
                }
              />
              <Button
                size="small"
                className="ant-btn-small-fix mt-auto"
                icon={<SendOutlined />}
                disabled={!commentBody}
                loading={loading === "comment"}
                onClick={() => saveComment()}
              />
            </>
          ) : (
            currentComment && (
              <>
                <span className="text-xs flex flex-wrap gap-1">
                  <span className="font-medium">
                    {getFullName(currentComment)}
                  </span>{" "}
                  <span className="text-antd-colorTextTertiary">
                    Left {commentTimeAgo}
                  </span>
                </span>
                <span className="[&_button]:text-antd-colorIcon">
                  {(!readonly || currentComment.resolved) && (
                    <Tooltip
                      mouseLeaveDelay={0}
                      title={
                        readonly
                          ? "Resolved"
                          : `Mark as ${currentComment.resolved ? "unresolved" : "resolved"}`
                      }
                    >
                      <Button
                        type="text"
                        size="small"
                        className={classNames("ant-btn-small-fix", {
                          "!cursor-default": readonly,
                        })}
                        loading={loading === "resolve"}
                        disabled={readonly}
                        onClick={() => saveComment(!currentComment.resolved)}
                        icon={
                          currentComment.resolved ? (
                            <CheckCircleFilled className="text-antd-colorSuccess" />
                          ) : (
                            <CheckCircleOutlined />
                          )
                        }
                      />
                    </Tooltip>
                  )}

                  {CURRENT_COMMENT_MENU && (
                    <Dropdown
                      disabled={Boolean(loading)}
                      trigger={["click"]}
                      menu={{ items: CURRENT_COMMENT_MENU }}
                    >
                      <Button
                        loading={loading === "delete"}
                        type="text"
                        size="small"
                        className="ant-btn-small-fix"
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )}
                </span>

                <div className="col-start-2 text-sm whitespace-pre-line break-all">
                  {currentComment.body}
                </div>
              </>
            )
          )}
        </div>
      ) : (
        !readonly && (
          <Button
            className="ant-btn-small-fix self-start"
            size="small"
            disabled={Boolean(editingComment && editingComment !== "new")}
            onClick={() => setShowBodyInput(true)}
          >
            <CommentOutlined />
            Comment
          </Button>
        )
      )}
    </>
  );
};

export default PostComment;
