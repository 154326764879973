import dayjs from "dayjs";
import pluralize from "pluralize";

export const getTimeAgo = (createdAt?: string) => {
  if (!createdAt) {
    return "Unknown";
  }

  const timestamp = dayjs.utc(createdAt);
  const now = dayjs.utc();
  const hourDiff = now.diff(timestamp, "h");

  let diffString = "";
  if (hourDiff < 24) {
    diffString =
      hourDiff < 1
        ? pluralize("minute", now.diff(timestamp, "m"), true)
        : pluralize("hour", hourDiff, true);
  } else {
    diffString = pluralize("day", now.diff(timestamp, "d"), true);
  }

  return `${diffString} ago`;
};
