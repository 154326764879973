import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { VOID_FUNCTION } from "fixtures/globalConstants";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "store";
import { Post, PostScreen } from "store/posts/postConstants";
import { currentBusinessGetter } from "store/user/userSlice";
import PostSelectionActionBar from "./PostSelectionActionBar";

const SELECTION_PERMISSION_NEEDED: PermissionString[] = [
  "post_status_draft_write",
  "post_status_trash_write",
  "post_status_schedule_write",
];

type PostSelectionContextType = {
  selectedPostList: Post[];
  inSelectionMode: boolean;
  allSelected: boolean;
  selectionDisabled: boolean;
  exitSelectionMode: () => void;
  setSelectionDisabled: (disabled: boolean) => void;
  selectPost: (post: Post) => void;
  selectAll: (select: boolean) => void;
  isSelected: (post: Pick<Post, "id">) => boolean;
};

const DEFAULT_POST_SELECTION_CONTEXT: PostSelectionContextType = {
  selectedPostList: [],
  allSelected: false,
  inSelectionMode: false,
  selectionDisabled: false,
  exitSelectionMode: VOID_FUNCTION,
  setSelectionDisabled: VOID_FUNCTION,
  selectPost: VOID_FUNCTION,
  selectAll: VOID_FUNCTION,
  isSelected: () => false,
};

export const PostSelectionContext = createContext(
  DEFAULT_POST_SELECTION_CONTEXT
);

const PostSelectionProvider = ({
  postScreen,
  postList,
  children,
}: {
  postScreen: PostScreen;
  postList: Post[];
  children: ReactNode;
}) => {
  const { hasSomePermissions } = useUserPermissions();
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const [inSelectionMode, setInSelectionMode] = useState(false);
  const [selectionDisabled, setSelectionDisabled] = useState(false);
  const [selectedPosts, setSelectedPosts] = useState<
    Record<string, { selected: boolean } & Post>
  >({});

  const selectedPostList = useMemo(
    () => Object.values(selectedPosts).filter(({ selected }) => selected),
    [selectedPosts]
  );

  useEffect(() => {
    setSelectionDisabled(!hasSomePermissions(SELECTION_PERMISSION_NEEDED));
  }, [currentBusiness]);

  const resetSelection = useCallback(() => {
    setInSelectionMode(false);
    setSelectedPosts({});
  }, []);

  useEffect(() => {
    const resetOnEscape = (e: KeyboardEvent) =>
      e.key === "Escape" && resetSelection();

    !selectionDisabled && document.addEventListener("keydown", resetOnEscape);
    return () => document.removeEventListener("keydown", resetOnEscape);
  }, [selectionDisabled]);

  useEffect(() => {
    resetSelection();
  }, [currentBusiness, postScreen]);

  useEffect(() => {
    !inSelectionMode && resetSelection();
  }, [inSelectionMode]);

  const selectPost: PostSelectionContextType["selectPost"] = (post) => {
    if (selectionDisabled) {
      return;
    }

    const selected = !selectedPosts[post.id]?.selected;
    setSelectedPosts((prevSelected) => ({
      ...prevSelected,
      [post.id]: { ...post, selected },
    }));

    selected && setInSelectionMode(true);
  };

  const selectAll: PostSelectionContextType["selectAll"] = (select) =>
    !selectionDisabled &&
    setSelectedPosts(
      select
        ? Object.fromEntries(
            postList.map((post) => [post.id, { ...post, selected: true }])
          )
        : {}
    );

  const isSelected: PostSelectionContextType["isSelected"] = ({ id }) =>
    selectedPosts[id]?.selected;

  return (
    <PostSelectionContext.Provider
      value={{
        selectedPostList,
        inSelectionMode,
        selectionDisabled,
        allSelected: selectedPostList.length === postList.length,
        setSelectionDisabled,
        selectAll,
        selectPost,
        isSelected,
        exitSelectionMode: () => setInSelectionMode(false),
      }}
    >
      {children}
      <PostSelectionActionBar screen={postScreen} />
    </PostSelectionContext.Provider>
  );
};

export default PostSelectionProvider;
