import { Button } from "antd";

const SelectAllButton = <T = string,>({
  setSelectedItems,
  selectedItems,
  allItems,
}: {
  setSelectedItems: (selectedImages: T[]) => void;
  selectedItems: T[];
  allItems: T[];
}) => {
  const isAllSelected =
    selectedItems.length && selectedItems.length === allItems.length;

  const handleSelectAllToggle = () =>
    isAllSelected ? setSelectedItems([]) : setSelectedItems(allItems);

  return (
    <Button type="text" onClick={handleSelectAllToggle}>
      {isAllSelected ? "Unselect" : "Select"} All
    </Button>
  );
};

export default SelectAllButton;
