import { loadStripe } from "@stripe/stripe-js";
import { Button } from "antd";
import { createCheckoutSession } from "api/stripe";
import { useCallback, useMemo, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import { AppSumoPlan } from "./appSumoPlansConstants";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const AppSumoPlanCard = ({ plan }: { plan: AppSumoPlan }) => {
  const { pathname } = useLocation();

  const user = useAppSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);

  const currentPlan = user.subscription?.plan || "";
  const currentTier = Number(currentPlan.replace("marky_tier", ""));

  const buttonText = useMemo(() => {
    if (plan.key === "extra-10-brands-monthly") {
      return "Subscribe";
    }

    if (currentPlan === plan.key) {
      return "Current Plan";
    } else {
      return currentTier > plan.tier ? "Downgrade" : "Upgrade";
    }
  }, [currentPlan, plan, currentTier]);

  const handleButtonClick = useCallback(async () => {
    if (plan.key === "extra-10-brands-monthly") {
      setIsLoading(true);
      try {
        const stripe = await stripePromise;

        const session = await createCheckoutSession(
          {
            plan: "extra-10-brands-monthly",
          },
          {
            successUrl: `${
              window.location.origin
            }/welcome?nextPage=${encodeURIComponent(pathname)}`,
          }
        );
        const result = await stripe?.redirectToCheckout({
          sessionId: session.sessionId,
        });

        if (result?.error) {
          console.error("Error redirecting to Stripe:", result.error);
        }
      } catch (error) {
        console.error("Error initiating Stripe Checkout:", error);
      }
      setIsLoading(false);
    } else {
      window.open(
        `https://appsumo.com/account/redemption/${user.subscription?.subscription_id}#change-plan`,
        "_blank"
      );
    }
  }, [plan, user, pathname]);

  return (
    <div className="plan-card plan-card--gradient" key={plan.name}>
      {plan.isMostPopular && <div className="most-popular">Most Popular</div>}
      <div>
        <div className="plan-card__top">
          <span className="plan-card__top__best-selling">{plan.name}</span>
        </div>

        <div className="plan-card__price">
          <span className="plan-card__price__old-price">{plan.price.old}</span>
          <span className="plan-card__name">
            {plan.price.new} {plan.interval && <span>/{plan.interval}</span>}
          </span>
          <p className="plan-card__price__name">{plan.subtitle}</p>
        </div>
        <div className="plan-card__perks">
          {plan.features.map((feature) => (
            <div className="plan-card__perks__perk" key={feature}>
              <BsCheckLg />
              <span className="ml-2">{feature}</span>
            </div>
          ))}
        </div>
      </div>
      <Button
        disabled={user.subscription?.plan === plan.key}
        onClick={handleButtonClick}
        loading={isLoading}
        type="primary"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default AppSumoPlanCard;
