import { Alert, Button, message, Table } from "antd";
import classNames from "classnames";
import { MdContentCopy } from "react-icons/md";
import { useAppSelector } from "store";

const UrlWithCopyButton = ({
  url,
  isModal,
}: {
  url: string;
  isModal?: boolean;
}) => {
  return (
    <div className="flex items-center gap-2">
      <span
        className={classNames("truncate", {
          "max-w-14": isModal,
          "max-w-36": !isModal,
        })}
      >
        {url}
      </span>
      {url?.length > 1 && (
        <Button
          onClick={() => {
            message.success("Text copied successfully to clipboard.");
            navigator.clipboard.writeText(url);
          }}
          type="text"
        >
          <MdContentCopy className="cursor-pointer" />
        </Button>
      )}
    </div>
  );
};

const DomainSetupInstructions = ({ isModal }: { isModal?: boolean }) => {
  const { ownedRealm } = useAppSelector((state) => state.user);

  if (!ownedRealm?.cname) {
    return null;
  }

  const customDomain = ownedRealm.domain || "";
  const { dns_records } = ownedRealm.cname;

  const isSubdomain = customDomain.split(".").length > 2;

  const dnsTableColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Name/Host",
      dataIndex: "host",
      key: "host",
      render: (host: string) => (
        <UrlWithCopyButton url={host} isModal={isModal} />
      ),
    },
    {
      title: "Value/Target",
      dataIndex: "target",
      key: "target",
      render: (host: string) => (
        <UrlWithCopyButton url={host} isModal={isModal} />
      ),
    },
  ];

  const dnsTableDataSource = [
    {
      type: "CNAME",
      host: dns_records[0]?.Name.split(".").slice(0, -3).join("."), // Extract the subdomain
      target: dns_records[0]?.Value,
    },
    ...(isSubdomain
      ? [
          {
            type: "CNAME",
            host: customDomain.split(".")[0],
            target: dns_records[1]?.Value,
          },
        ]
      : [{ type: "ALIAS", host: "@", target: dns_records[1]?.Value }]),
  ];

  return (
    <Alert
      description={
        <div className="space-y-2">
          <span>Setup Instructions</span>
          <ol className="list-decimal">
            <li>
              Login to your DNS manager for <strong>{customDomain}</strong>.
            </li>
            <li>
              Add two DNS records:
              <Table
                dataSource={dnsTableDataSource}
                columns={dnsTableColumns}
                className="mt-1 mb-3"
                pagination={false}
              />
            </li>
            <li>
              Wait for DNS to propagate (this can take up to 48 hours but
              normally happens within 2-15 minutes).{" "}
              {isModal && "You can close this popup."}
            </li>
          </ol>
        </div>
      }
    />
  );
};

export default DomainSetupInstructions;
