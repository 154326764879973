export const BACKGROUND_COLORS: Record<string, string> = {
  A: "#8d6cf9",
  B: "#EB546F",
  C: "#56a3f5",
  D: "#1a1d29",
  E: "#1a223a",
  F: "#1e1e1e",
  G: "#2d2d2d",
  H: "#1e140f",
  I: "#173d4e",
  J: "#674da0",
  K: "#2b2b2b",
  L: "#5a7b8c",
  M: "#254045",
  N: "#f96c7a",
  O: "#09213E",
  P: "#F4B94D",
  Q: "#EE834E",
  R: "#f8f5e8",
  S: "#1a1d29",
  T: "#EE8271",
  U: "#f3e5f5",
  V: "#397a59",
  W: "#f96c7a",
  X: "#254045",
  Y: "#2d2d2d",
  Z: "#56a3f5",
};
