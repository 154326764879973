import { Dropdown, Tooltip } from "antd";
import PaletteColorBlocks from "components/PaletteColorBlocks";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import PRESET_PALETTES, {
  DEFAULT_PALETTE,
} from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/PRESET_PALETTES";
import { useCallback, useEffect, useState } from "react";
import { BsPalette2 } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { currentBusinessGetter } from "store/user/userSlice";
import ColorPalette from "./ColorPalette";

const PALETTE_OPTIONS = PRESET_PALETTES.map((palette, index) => ({
  label: <PaletteColorBlocks palette={palette} />,
  key: index,
}));

const ColorSelector = () => {
  const { hasPermission } = useUserPermissions();
  const canEditBusiness = hasPermission("business_details_write");

  const dispatch = useAppDispatch();
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const [palettes, setPalettes] = useState(
    currentBusiness.brand.palettes || []
  );

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const updateColorInBackground = useCallback(async () => {
    if (
      JSON.stringify(palettes) ===
      JSON.stringify(currentBusiness.brand.palettes)
    ) {
      return;
    }

    if (abortController) {
      abortController?.abort();
    }

    localStorage.setItem("areColorsUpdated", "true");

    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    await dispatch(updateBusinessDetails({ palettes }));
  }, [palettes, abortController]);

  const handleColorChange = ({
    key,
    value,
    index,
  }: {
    key: string;
    value: string;
    index: number;
  }) => {
    setPalettes(
      palettes.map((palette, paletteIndex) =>
        paletteIndex === index ? { ...palette, [key]: value } : palette
      )
    );
  };

  const addPalette = (index?: number) => {
    if (index === undefined) {
      setPalettes([
        ...palettes,
        palettes[palettes.length - 1] ?? DEFAULT_PALETTE,
      ]);
    } else {
      setPalettes([...palettes, PRESET_PALETTES[index]]);
    }
  };

  const removePalette = (index: number) => {
    setPalettes((prev) => {
      const palettesClone = [...prev];
      palettesClone.splice(index, 1);
      return palettesClone;
    });
  };

  useEffect(() => {
    setPalettes(
      currentBusiness.brand.palettes?.length
        ? currentBusiness.brand.palettes
        : []
    );
  }, [currentBusiness]);

  useEffect(() => {
    updateColorInBackground();
  }, [palettes]);

  return (
    <div className="flex flex-col text-sm gap-3">
      <div>
        <p className="font-medium">Colors</p>
        <span className="text-antd-colorTextSecondary ">
          Select the colors you want to use for your posts.
        </span>
      </div>

      <div className="space-y-2">
        {palettes.map((palette, index) => (
          <ColorPalette
            key={index}
            handleColorChange={(data) => handleColorChange({ ...data, index })}
            canEditBusiness={canEditBusiness}
            removePalette={() => removePalette(index)}
            canRemovePalette={palettes.length > 1}
            palette={palette}
          />
        ))}
      </div>

      {canEditBusiness && (
        <Dropdown.Button
          menu={{
            className: "max-h-[300px] overflow-auto space-y-2",
            items: PALETTE_OPTIONS,
            onClick: ({ key }) => addPalette(parseInt(key)),
          }}
          onClick={() => addPalette()}
          icon={
            <Tooltip title="Select from presets">
              <BsPalette2 />
            </Tooltip>
          }
        >
          Add Palette
        </Dropdown.Button>
      )}
    </div>
  );
};

export default ColorSelector;
