import { BadgeProps } from "antd";
import { CloudFrontDistributionStatus, CName } from "api/config/chalice-api";

export const ROOT_WHITE_LABEL_DOMAIN = ".socialmediapostmanager.com";

export const getCNameStatus = (
  cname?: CName | null
): { tooltip: string; label: string; status: BadgeProps["status"] } => {
  let defaultStatus = {
    tooltip: "Unknown",
    label: "Unknown",
    status: "warning" as BadgeProps["status"],
  };

  if (!cname) {
    return defaultStatus;
  }

  const cfStatus = getCFDistroStatus(cname.cloudfront_distribution_status);
  const sslStatus = getSSLStatus(cname.ssl_status);

  if (sslStatus === "PENDING") {
    defaultStatus = {
      tooltip: "DNS validation pending. Please wait.",
      label: "Pending",
      status: "warning",
    };
  } else if (sslStatus === "FAILED") {
    defaultStatus = {
      tooltip:
        "ailed to validate DNS. Please double-check your configuration. If the problem persists, try again with a different domain or reach out to support.",
      label: "Failed",
      status: "error",
    };
  } else if (sslStatus === "SUCCESS" && cfStatus === "PENDING") {
    defaultStatus = {
      tooltip:
        "DNS validation succeeded (you did everything right) but we are waiting for the new portal to deploy.",
      label: "Pending",
      status: "warning",
    };
  } else if (sslStatus === "SUCCESS" && cfStatus === "SUCCESS") {
    defaultStatus = {
      tooltip:
        "DNS validation succeeded and portal deployed. You may now use the custom domain.",
      label: "Success",
      status: "success",
    };
  }

  return {
    ...defaultStatus,
    tooltip: `${defaultStatus.tooltip} [SSL Status: ${cname.ssl_status}, Deploy Status: ${cname.cloudfront_distribution_status}]`,
    status: defaultStatus.status as BadgeProps["status"],
  };
};

const getSSLStatus = (sslStatusKey: CName["ssl_status"]) => {
  switch (sslStatusKey) {
    case "VALIDATION_TIMED_OUT":
    case "EXPIRED":
    case "INACTIVE":
    case "REVOKED":
    case "FAILED":
      return "FAILED";

    case "PENDING_VALIDATION":
    case "SDK_UNKNOWN":
      return "PENDING";

    case "REQUESTING_NEW_CERTIFICATE":
    case "ISSUED":
      return "SUCCESS";
  }
};

const getCFDistroStatus = (cfStatusKey: CloudFrontDistributionStatus) => {
  switch (cfStatusKey) {
    case "InProgress":
      return "PENDING";

    case "Deployed":
      return "SUCCESS";
  }
};
