import { message } from "antd";
import { ItemType } from "antd/es/menu/interface";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { MdOutlineDownload } from "react-icons/md";
import { useCSVDownloader } from "react-papaparse";
import { useAppSelector } from "store";
import { Post, PostScreen, STATUS_TO_SCREEN } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import { integrationsTypeGetter } from "store/user/userSlice";
import { fetchMediaBlob, getFileType } from "utils/fileDownloadUtils";
import { getPostRenderInfo } from "../utils/useGetPostRenderInfo";
import { useGetAllowedScreens } from "./useGetAllowedScreens";

const useDownloadPostsMenuItems = ({
  postList: postListProp,
  downloadScreens,
  setIsDownloading,
}: {
  postList?: Post[];
  downloadScreens?: PostScreen[];
  setIsDownloading: (isDownloading: boolean) => void;
}): { menuItems: ItemType[]; downloadItem: ItemType } => {
  const { CSVDownloader } = useCSVDownloader();
  const connectedSocials = useAppSelector(integrationsTypeGetter);
  const postData = useAppSelector(selectPostStore);
  const { screenParam } = useGetAllowedScreens();

  const postList = useMemo(() => {
    if (postListProp) {
      return postListProp;
    } else if (downloadScreens) {
      return downloadScreens.flatMap((status) => postData[status].postList);
    } else {
      return screenParam ? postData[screenParam].postList : [];
    }
  }, [postListProp, screenParam, postData]);

  const fileName = postList?.length
    ? `${STATUS_TO_SCREEN[postList[0].status]}-posts`
    : "post-download";

  const formatPostsForCSV = (posts: Post[]) =>
    posts.map((post) => ({
      id: post.id,
      caption: post.caption,
      created_at: post.created_at,
      media_url: Object.values(post.media_urls || {}).join(","),
      ...(post.status === "SCHEDULED" && {
        publish_at: postData.scheduled.publishAt[post.id],
        platforms: sortBy(post.publish_to || connectedSocials).join(", "),
      }),
    }));

  const createDownloadZip = async () => {
    if (!postList?.length) {
      return;
    }

    setIsDownloading(true);
    try {
      const zip = new JSZip();
      const folder = zip.folder("collection");

      if (!folder) {
        return;
      }

      let carouselNumber = 0;
      let imageNumber = 0;
      let imagePromises: Promise<void>[] = [];

      postList.forEach((post) => {
        if (!post.media_urls) {
          return;
        }

        const { isVideo, isCarousel } = getPostRenderInfo(post);

        if (isCarousel) {
          carouselNumber += 1;
        }

        imagePromises = imagePromises.concat(
          post.media_urls.map(async (url, index) => {
            let fileName = "";

            if (isCarousel) {
              fileName = `carousel-${carouselNumber}/${isVideo ? "video" : "image"}-${index + 1}`;
            } else {
              imageNumber += 1;
              fileName = `${isVideo ? "video" : "image"}-${imageNumber}`;
            }

            const blob = await fetchMediaBlob(url);
            folder.file(`${fileName}.${getFileType(url)}`, blob);
          })
        );
      });

      await Promise.all(imagePromises);

      const content = await folder.generateAsync({ type: "blob" });
      saveAs(content, "posts");
      message.success("Download successful.");
    } catch (error) {
      message.error(
        "Something went wrong. Please text our support line now for an instant response."
      );
    }
    setIsDownloading(false);
  };

  const menuItems = [
    {
      key: "csv",
      disabled: !postList.length,
      label: postList && (
        <CSVDownloader
          filename={fileName}
          data={formatPostsForCSV(postList)}
          bom={true}
        >
          Download CSV
        </CSVDownloader>
      ),
    },
    {
      key: "images",
      onClick: () => createDownloadZip(),
      label: "Download Post Media",
      disabled: !postList.length,
    },
  ];

  return {
    downloadItem: {
      key: "download",
      label: "Download",
      disabled: !postList.length,
      className: "[&_div]:flex [&_div]:items-center",
      icon: <MdOutlineDownload size={16} />,
      children: menuItems,
    },

    menuItems,
  };
};

export default useDownloadPostsMenuItems;
