import {
  DeleteOutlined,
  EditOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, UploadFile, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { limitedMediaMessage, MAX_SLIDES_FOR_POST } from "fixtures/constants";
import { UseStateReturn } from "fixtures/globalConstants";
import Carousel from "pages/Posts/components/Carousel";
import { HTMLProps, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { uploadToS3 } from "utils/generalUtils";
import {
  ACCEPTED_IMAGE_FORMATS_STR,
  ACCEPTED_VIDEO_FORMATS_STR,
  validateUploadedFile,
  validateUploadedFileList,
} from "utils/validateFileUpload";
import { v4 } from "uuid";

const CustomPostMedia = ({
  mediaState: [mediaUrls, setMediaUrls],
  postLoading = false,
}: {
  mediaState: UseStateReturn<string[]>;
  postLoading: boolean;
}) => {
  const { hasPermission } = useUserPermissions();
  const canEditDesign = hasPermission("post_design_write");

  const [isLoading, setIsLoading] = useState<
    "post" | "upload" | "replace" | null
  >(postLoading ? "post" : null);

  const uploadFilesRef = useRef<HTMLInputElement>(null);
  const replaceFileRef = useRef<HTMLInputElement>(null);
  const currentSlideRef = useRef<number>(0);

  useEffect(() => {
    !postLoading && isLoading === "post" && setIsLoading(null);
  }, [postLoading]);

  const saveMedia = async (
    files: (UploadFile | File)[],
    replaceCurrent: boolean
  ) => {
    setIsLoading(replaceCurrent ? "replace" : "upload");
    const resolvedMedia = await Promise.all(
      files.map((file) => {
        const extension = file.name.split(".").pop()?.toLowerCase();
        const fileName = `${v4()}.${extension}`;

        return uploadToS3(file, fileName);
      }) as Promise<string>[]
    );

    if (replaceCurrent) {
      setMediaUrls(
        mediaUrls.map((media, index) =>
          index === currentSlideRef.current ? resolvedMedia[0] : media
        )
      );
    } else {
      setMediaUrls((prevImages) => prevImages.concat(resolvedMedia));
    }
    setIsLoading(null);
  };

  const handleFileSelection: UploadProps["beforeUpload"] = async (
    file,
    fileList
  ) => {
    if (validateUploadedFile(file)) {
      if (
        file.uid === fileList[fileList.length - 1].uid &&
        fileList.length > MAX_SLIDES_FOR_POST
      ) {
        limitedMediaMessage();
      } else {
        saveMedia([file], false);
      }
    }
  };

  const uploadMoreFiles = async (
    { target }: React.ChangeEvent<HTMLInputElement>,
    replaceCurrent: boolean
  ) => {
    if (target.files && mediaUrls.length < MAX_SLIDES_FOR_POST) {
      const validFiles = validateUploadedFileList(target.files);
      validFiles.length &&
        saveMedia(
          validFiles.slice(0, MAX_SLIDES_FOR_POST - mediaUrls.length),
          replaceCurrent
        );
      if (validFiles.length > MAX_SLIDES_FOR_POST - mediaUrls.length) {
        limitedMediaMessage();
      }
    }
  };

  const removeMedia = () =>
    setMediaUrls(
      mediaUrls.filter((_url, index) => index !== currentSlideRef.current)
    );

  const commonFileInputProps: HTMLProps<HTMLInputElement> = {
    className: "hidden",
    accept: `${ACCEPTED_IMAGE_FORMATS_STR}, ${ACCEPTED_VIDEO_FORMATS_STR}`,
    type: "file",
  };

  return mediaUrls.length ? (
    <Carousel
      inModal
      mediaUrls={mediaUrls}
      currentSlideCallback={(currentSlide) =>
        (currentSlideRef.current = currentSlide)
      }
      addNewSlideProps={
        canEditDesign && mediaUrls.length < MAX_SLIDES_FOR_POST
          ? {
              onClick: () => !isLoading && uploadFilesRef.current?.click(),
              icon: isLoading === "upload" ? <LoadingOutlined /> : undefined,
            }
          : undefined
      }
    >
      {canEditDesign && (
        <>
          <div className="absolute top-2 left-2 space-x-2">
            <Button
              icon={<EditOutlined />}
              loading={isLoading === "replace"}
              onClick={() => replaceFileRef.current?.click()}
            />
            {mediaUrls.length > 1 && (
              <Button icon={<DeleteOutlined />} onClick={removeMedia} />
            )}
          </div>

          <input
            {...commonFileInputProps}
            ref={uploadFilesRef}
            onChange={(e) => uploadMoreFiles(e, false)}
            multiple
          />
          <input
            {...commonFileInputProps}
            ref={replaceFileRef}
            onChange={(e) => uploadMoreFiles(e, true)}
          />
        </>
      )}
    </Carousel>
  ) : (
    <div className="h-80 w-[400px]">
      {isLoading ? (
        <Skeleton className="h-full w-full" />
      ) : canEditDesign ? (
        <Dragger
          beforeUpload={handleFileSelection}
          accept={commonFileInputProps.accept}
          multiple
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag image/video to this area to upload
          </p>
          <p className="ant-upload-hint">Add your own content here.</p>
        </Dragger>
      ) : (
        "Error: Post is missing media"
      )}
    </div>
  );
};

export default CustomPostMedia;
