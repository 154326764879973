import { Config } from "tailwindcss";
import resolveConfig from "tailwindcss/resolveConfig";
import config from "../../tailwind.config";

const { theme } = resolveConfig(config as unknown as Config);

type ScreenKey = keyof typeof theme.screens;
export const screenSizes = Object.entries(theme.screens).reduce(
  (prev, [screenKey, size]) => {
    prev[screenKey as ScreenKey] = parseInt(size.split("px")[0]);
    return prev;
  },
  {} as { [key in ScreenKey]: number }
);

export default theme;
