import {
  ImportOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Dropdown,
  message,
  Modal,
  Tag,
  Tooltip,
} from "antd";
import { MenuItemType } from "antd/es/menu/interface";
import { ImageSource } from "api/config/chalice-api";
import { addImageViaTaskQueue, deleteQueueTask } from "api/media";
import classNames from "classnames";
import SelectAllButton from "components/Common/SelectAllButton";
import FileUploadButton from "designSystem/FileUploadButton";
import _ from "lodash";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import {
  MdOutlineAutoFixHigh,
  MdOutlineKeyboardArrowDown,
  MdOutlinePhoto,
  MdSettings,
} from "react-icons/md";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import {
  deleteImagesFromLibrary,
  fetchImagesInLibrary,
  fetchPendingTasks,
} from "store/imageLibrary/imageLibraryActions";
import { LibraryImage } from "store/imageLibrary/imageLibraryConstants";
import { resetFailedTasks } from "store/imageLibrary/imageLibrarySlice";
import { currentBusinessGetter } from "store/user/userSlice";
import AddImagesFromWebsite from "./AddImagesFromWebsite";
import AutoFindStockModal from "./AutoFindStockModal/AutoFindStockModal";
import ImageDropZone from "./ImageDropZone";
import ImageGallery from "./ImageGallery";
import { IMAGES_LIMIT, SHIFT_CLICK_MESSAGE } from "./imageLibraryFixtures";
import useImageLibrary from "./useImageLibrary";

type StatusInfo = {
  imageCount: number;
  status: string;
  statusColor?: string;
  statusTooltip?: string;
};

const DEFAULT_STATUS = {
  status: "Full",
  statusColor: "success",
  statusTooltip:
    "The library has the maximum amount of images and no more can be added.",
};

const STATUS_BREAKPOINTS = (count: number): StatusInfo[] => [
  {
    imageCount: 1,
    status: "Empty",
    statusColor: "error",
    statusTooltip:
      "You have no images added. You will not be able to generate posts.",
  },
  {
    imageCount: 25,
    status: "Low",
    statusColor: "warning",
    statusTooltip: `You have ${count} images added. We recommend having at least 25 images to see diversity and relevancy in your posts.`,
  },
  {
    imageCount: 100,
    status: "Moderate",
    statusTooltip: `You have ${count} images added. This is a decent amount, but adding more could enhance the variety and relevance of your posts.`,
  },
  {
    imageCount: IMAGES_LIMIT,
    status: "High",
    statusTooltip: `You have ${count} images added. This is good but if you want to see more relevance and diversity, you can add more.`,
  },
];

const IMAGE_LIBRARY_SOURCES = [
  { label: "Image Library", value: "media_library" },
  { label: "Stock Photos", value: "unsplash_free" },
] as const;

const ImageLibrary = () => {
  const dispatch = useAppDispatch();

  const { preferences } = useAppSelector(currentBusinessGetter);
  const {
    imagesInLibrary,
    isLoadingImages,
    mediaServiceDown,
    failedQueueTasks,
    pendingQueueTasks,
    isMediaLibraryFetched,
  } = useAppSelector((state) => state.imageLibrary);
  const { handleImageUpload } = useImageLibrary();

  const [isRetrying, setIsRetrying] = useState(false);
  const [selectedImages, setSelectedImages] = useState([] as LibraryImage[]);
  const [openModal, setOpenModal] = useState<
    "stock" | "import-website" | "settings" | null
  >(null);
  const [imageSources, setImageSources] = useState<ImageSource[]>(
    preferences?.image_sources || []
  );
  const showImageLibrarySourceWarning =
    !imageSources.includes("media_library") && imagesInLibrary.length > 0;

  useEffect(() => {
    if (pendingQueueTasks.length && !isLoadingImages) {
      dispatch(fetchImagesInLibrary());
    }
  }, [pendingQueueTasks.length, isLoadingImages]);

  const { status, statusColor, statusTooltip } = useMemo(
    () => ({
      ...DEFAULT_STATUS,
      ...STATUS_BREAKPOINTS(imagesInLibrary.length).find(
        ({ imageCount }) => imagesInLibrary.length < imageCount
      ),
    }),
    [imagesInLibrary]
  );
  const libraryFull = status === "Full";

  const deleteFailedImages = () => {
    return failedQueueTasks.map(({ task }) => deleteQueueTask(task.task_id));
  };

  const retryImageUpload = async () => {
    setIsRetrying(true);

    try {
      const addImagePromises = failedQueueTasks.map(({ task }) => {
        return addImageViaTaskQueue({
          task: {
            task_kwargs: JSON.parse(
              task.task_kwargs.slice(1, -1).replace(/'/g, '"')
            ),
          },
        });
      });

      await Promise.all([...addImagePromises, ...deleteFailedImages()]);

      await dispatch(fetchPendingTasks());
      dispatch(resetFailedTasks());
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    } finally {
      setIsRetrying(false);
    }
  };

  const deleteImages = () => {
    setSelectedImages([]);
    dispatch(deleteImagesFromLibrary(selectedImages));
  };

  const addImageMenuItems: MenuItemType[] = [
    {
      key: "upload",
      label: (
        <FileUploadButton
          onChange={handleImageUpload}
          id="image-library-upload"
          className="-my-2 -mx-3 py-2 px-3 block"
        >
          <div className="flex items-center">
            <UploadOutlined className="mr-2" />
            Upload
          </div>
        </FileUploadButton>
      ),
    },
    {
      key: "search-stock",
      label: "Search Stock",
      icon: <MdOutlineAutoFixHigh />,
      onClick: () => setOpenModal("stock"),
    },
    {
      key: "import-from-website",
      label: "Pull from website",
      icon: <ImportOutlined />,
      onClick: () => setOpenModal("import-website"),
    },
  ];

  const handleImageSourceChange = (checked: boolean, value: ImageSource) => {
    let newImageSources = imageSources;
    if (checked) {
      newImageSources = imageSources.concat(value);
    } else {
      const filteredImageSources = imageSources.filter(
        (source) => source !== value
      );
      if (filteredImageSources.length) {
        newImageSources = filteredImageSources;
      } else {
        message.error("At least one image source should always be selected.");
      }
    }

    setImageSources(newImageSources);
    !_.isEqual(imageSources, newImageSources) &&
      dispatch(
        updateBusinessDetails({
          // TODO: BE has bad type for ImageSource, should be ImageSource[], instead it's [ImageSource, ...ImageSource[]]
          preferences: {
            ...preferences,
            image_sources: newImageSources as [ImageSource],
          },
        })
      );
  };

  return (
    <div className="card">
      <header>
        <h2 className="font-medium text-antd-colorText">Image Library</h2>
        <span className="text-antd-colorTextSecondary text-sm">
          Add images to your library to start seeing them show up in posts and
          use them in the design editor. {SHIFT_CLICK_MESSAGE}
        </span>
      </header>

      <ImageDropZone onDropAccepted={handleImageUpload} noClick>
        <div
          className={classNames("flex flex-col gap-2 sticky top-0 z-10", {
            "mt-2 px-1.5":
              failedQueueTasks.length ||
              pendingQueueTasks.length ||
              showImageLibrarySourceWarning,
          })}
        >
          {!!failedQueueTasks.length && (
            <Alert
              message={`Failed to add ${pluralize("image", failedQueueTasks.length, true)}.`}
              action={
                <Button
                  className="h-8"
                  onClick={retryImageUpload}
                  loading={isRetrying}
                >
                  Retry
                </Button>
              }
              onClose={deleteFailedImages}
              type="error"
              closable
            />
          )}
          {showImageLibrarySourceWarning && (
            <Alert
              type="warning"
              message={
                <div>
                  These images won't be used in posts, select{" "}
                  <strong>Image Library</strong> as a source in your settings to
                  use them.
                </div>
              }
            />
          )}
          {!!pendingQueueTasks.length && (
            <Alert
              message={`${pluralize("image", pendingQueueTasks.length, true)} ${pluralize("is", pendingQueueTasks.length)} being uploaded.`}
              icon={<LoadingOutlined />}
              type="info"
              showIcon
            />
          )}
        </div>

        {Boolean(!isLoadingImages || imagesInLibrary.length) &&
          imageSources.includes("media_library") &&
          imageSources.length === 1 && (
            <div className="flex gap-2 px-4 pt-3">
              <span className="!text-sm">Image Variety:</span>
              <Tag color={statusColor} bordered={false} className="mx-0">
                {status}
              </Tag>
              <Tooltip title={statusTooltip}>
                <QuestionCircleOutlined className="text-antd-colorTextSecondary [&_svg]:w-5" />
              </Tooltip>
            </div>
          )}

        {!mediaServiceDown && (isLoadingImages || imagesInLibrary.length) ? (
          <ImageGallery
            loading={!isMediaLibraryFetched && isLoadingImages}
            images={imagesInLibrary}
            {...{ selectedImages, setSelectedImages }}
          />
        ) : (
          <Alert
            className="m-4"
            description={
              mediaServiceDown ? (
                <div className="flex flex-col items-center gap-4">
                  We're having difficulty loading your image library right now,
                  please try again or contact support if this error persists.
                  <Button
                    loading={isLoadingImages}
                    size="small"
                    type="primary"
                    onClick={() => dispatch(fetchImagesInLibrary())}
                  >
                    Check connection
                  </Button>
                </div>
              ) : (
                "You don't have any images in your library at the moment. Add images to have more control over which images are used for your posts."
              )
            }
            type={mediaServiceDown ? "error" : "warning"}
          />
        )}
      </ImageDropZone>

      {!mediaServiceDown && (
        <footer>
          {!selectedImages.length ? (
            <div className="text-sm text-antd-colorTextSecondary">
              {isLoadingImages ? (
                <LoadingOutlined />
              ) : (
                `${imagesInLibrary.length}/${IMAGES_LIMIT} images`
              )}
            </div>
          ) : (
            <SelectAllButton
              allItems={imagesInLibrary}
              setSelectedItems={setSelectedImages}
              selectedItems={selectedImages}
            />
          )}
          <div className="flex flex-wrap justify-end gap-3">
            {selectedImages.length ? (
              <>
                <Button key="cancel" onClick={() => setSelectedImages([])}>
                  Cancel
                </Button>
                <Button key="delete" type="primary" onClick={deleteImages}>
                  Delete
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => setOpenModal("settings")}
                  icon={<MdSettings />}
                  type="text"
                >
                  Settings
                </Button>
                <Dropdown
                  disabled={libraryFull}
                  menu={{
                    items: addImageMenuItems,
                  }}
                >
                  <Button icon={<MdOutlinePhoto className="scale-125" />}>
                    {libraryFull ? "Library Full" : "Add Images"}
                    <MdOutlineKeyboardArrowDown className="scale-125" />
                  </Button>
                </Dropdown>
              </>
            )}
          </div>
        </footer>
      )}

      <AutoFindStockModal
        isGlobal={false}
        close={() => setOpenModal(null)}
        isOpen={openModal === "stock"}
      />
      <AddImagesFromWebsite
        close={() => setOpenModal(null)}
        isOpen={openModal === "import-website"}
      />
      <Modal
        onCancel={() => setOpenModal(null)}
        open={openModal === "settings"}
        title="Image Library Settings"
        footer={null}
        centered
        closable
      >
        <div className="font-medium mb-3">
          Image Sources{" "}
          <Tooltip title="We use all enabled sources to find the best image for your post. Disable sources if you don't want to include them in your posts.">
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
        <div className="flex flex-col gap-1">
          {IMAGE_LIBRARY_SOURCES.map(({ label, value }) => (
            <Checkbox
              checked={imageSources.includes(value)}
              onChange={(e) => handleImageSourceChange(e.target.checked, value)}
              key={value}
            >
              {label}
            </Checkbox>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ImageLibrary;
