import { message } from "antd";
import {
  addImage,
  deleteImage,
  fetchImagesInBatches,
  getImageQueueTasks,
} from "api/media";
import { isAxiosError } from "axios";
import { createAppAsyncThunk } from "store/storeHelpers";
import { LibraryImage } from "./imageLibraryConstants";
import {
  setLibraryImages,
  updateLibraryImages,
  updateQueueTasks,
} from "./imageLibrarySlice";

export const fetchImagesInLibrary = createAppAsyncThunk(
  "imageLibrary/fetchImagesInLibrary",
  async (_args: undefined, { dispatch, rejectWithValue }) => {
    try {
      const pendingQueueTasks = await getImageQueueTasks("PENDING");
      const failedQueueTasks = await getImageQueueTasks("FAILURE");

      dispatch(updateQueueTasks({ pendingQueueTasks, failedQueueTasks }));

      for await (const batch of fetchImagesInBatches()) {
        if (batch) {
          dispatch(updateLibraryImages(batch));
        }
      }
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchPendingTasks = createAppAsyncThunk(
  "imageLibrary/fetchPendingTasks",
  async (_args: undefined, { rejectWithValue }) => {
    try {
      const pendingQueueTasks = await getImageQueueTasks("PENDING");

      return pendingQueueTasks;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchFailedTasks = createAppAsyncThunk(
  "imageLibrary/fetchFailedTasks",
  async (_args: undefined, { rejectWithValue }) => {
    try {
      const failedQueueTasks = await getImageQueueTasks("FAILURE");

      return failedQueueTasks;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addImageToLibrary = createAppAsyncThunk(
  "imageLibrary/addImageToLibrary",
  async (
    { localId, data }: { localId: string; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await addImage(data);
      return { localId, ...response };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteImagesFromLibrary = createAppAsyncThunk(
  "imageLibrary/deleteImageFromLibrary",
  async (deleteImages: LibraryImage[], { getState, dispatch }) => {
    const {
      imageLibrary: { imagesInLibrary },
    } = getState();

    dispatch(
      setLibraryImages(
        imagesInLibrary.filter(
          (image) => !deleteImages.find(({ id }) => id === image.id)
        )
      )
    );

    let refetch = false;
    const failedDeletes = [] as LibraryImage[];

    try {
      await Promise.all(
        deleteImages.map((image) =>
          deleteImage(image.id).catch((error) => {
            refetch = true;
            // 404 error on delete means the image doesn't exist in the library already
            if (!isAxiosError(error) || error.response?.status !== 404) {
              failedDeletes.push(image);
            }
          })
        )
      );
    } catch (e) {
      refetch = true;
      message.error("Something went wrong. Please try again.");
    }

    refetch && dispatch(fetchImagesInLibrary());

    if (failedDeletes.length) {
      dispatch(updateLibraryImages(failedDeletes));
    }

    return { failedDeletes };
  }
);
