import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/es/menu/interface";
import Link from "antd/es/typography/Link";
import cx from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import dayjs from "dayjs";
import { LONG_DATE_TIME_FORMAT } from "fixtures/globalConstants";
import { isRTLLanguage } from "fixtures/languages";
import { useMemo } from "react";
import { useAppSelector } from "store";
import { Post } from "store/posts/postConstants";
import { integrationsGetter } from "store/user/userSlice";
import { validateImageUrl } from "utils/validateFileUpload";
import ConnectSocialsTooltip from "../components/ConnectSocialsTooltip";
import PostCardWrapper from "../components/PostCardWrapper";
import SocialsSelect from "../components/SocialsSelect";
import useScheduleCustomTime from "../ScheduleCustomTime/useScheduleCustomTime";
import useUpdatePostActions from "../utils/useUpdatePostActions";

export type ScheduledPostCardProps = {
  post: Post;
} & AllOrNone<{
  autoScheduledTimes: string[];
  autoScheduledIndex: number;
  moveToTop: (index: number) => void;
  moveToBottom: (index: number) => void;
}>;

// TODO: refactor
const ScheduledPostCard = ({
  post,
  autoScheduledTimes,
  autoScheduledIndex,
  moveToTop,
  moveToBottom,
}: ScheduledPostCardProps) => {
  const { hasPermission, hasSomePermissions } = useUserPermissions();
  const canEditPosts = hasSomePermissions([
    "caption_write",
    "post_design_write",
  ]);

  const {
    isLoading,
    loading: loadingPostAction,
    savePost,
    duplicatePost,
    schedulePost,
    discardPost,
    publishPost,
  } = useUpdatePostActions();
  const { realm } = useAppContext();

  const connectedSocials = useAppSelector(integrationsGetter);

  const { publishPostDate, publishPostDateFormatted } = useMemo(() => {
    let publishPost: string | number | undefined = undefined;
    if (autoScheduledIndex !== undefined) {
      publishPost = autoScheduledTimes[autoScheduledIndex];
    } else if (post.adhoc_publish_minute_timestamp) {
      publishPost = post.adhoc_publish_minute_timestamp * 60000;
    }

    const publishPostDate = dayjs(publishPost).local();

    return {
      publishPostDate,
      publishPostDateFormatted: publishPostDate?.format(LONG_DATE_TIME_FORMAT),
    };
  }, [
    autoScheduledIndex,
    autoScheduledTimes,
    post.adhoc_publish_minute_timestamp,
  ]);

  const { customTimeMenuItem, setCustomTimeModalOpen, CustomTimeModal } =
    useScheduleCustomTime({
      hideIcon: true,
    });

  const handleAction = (which: string) => {
    switch (which) {
      case "save-post":
        return savePost({ post, updateBody: { status: "LIKED" } });
      case "publish-post":
        return publishPost(post);
      case "move-to-top":
        return moveToTop && moveToTop(autoScheduledIndex);
      case "move-to-bottom":
        return moveToBottom && moveToBottom(autoScheduledIndex);
      case "move-to-queue":
        return schedulePost({ post });
      case "duplicate-post":
        return duplicatePost(post);
      case "discard-post":
        return discardPost(post);
    }
  };

  const actionItems = useMemo(() => {
    const items: ItemType[] = [];

    canEditPosts &&
      items.push({
        key: "edit-post",
        label: "Edit Post",
      });

    hasPermission("post_status_draft_write") &&
      items.push({ key: "save-post", label: "Move to Drafts" });

    hasPermission("post_status_publish_write") &&
      items.push({
        key: "publish-post",
        label: <ConnectSocialsTooltip>Publish Now</ConnectSocialsTooltip>,
        disabled: !connectedSocials.length,
      });

    autoScheduledIndex !== undefined &&
      items.push(
        ...(hasPermission("post_status_schedule_write")
          ? [
              {
                key: "move-to-top",
                label: "Move to top (post next)",
                disabled: autoScheduledIndex === 0,
              },
              {
                key: "move-to-bottom",
                label: "Move to bottom (post last)",
                disabled: autoScheduledIndex === autoScheduledTimes.length - 1,
              },
              customTimeMenuItem,
            ]
          : [{ key: "move-to-queue", label: "Move to Queue" }])
      );

    realm.isMarky &&
      items.push({ key: "duplicate-post", label: "Duplicate Post" });

    hasPermission("post_status_trash_write") &&
      items.push({ key: "discard-post", label: "Trash" });

    return items;
  }, [connectedSocials.length, autoScheduledIndex, autoScheduledTimes?.length]);

  const { isCarousel, images, mediaURL } = useMemo(() => {
    const images = post.media_urls;
    return {
      images,
      isCarousel: (images?.length ?? 0) > 1,
      mediaURL: post?.media_urls?.[0],
    };
  }, [post]);

  const isRTL = useMemo(
    () => isRTLLanguage(post.caption || ""),
    [post.caption]
  );

  return (
    <PostCardWrapper {...{ post, loadingPostAction }}>
      {({ openFullScreenEditor, openMiniEditor }) => (
        <div
          className={cx("single-scheduled-post", {
            "single-scheduled-post--disabled": isLoading,
          })}
        >
          {isCarousel && images && (
            <span className="single-scheduled-post__carousel-indicator">
              1/{images.length}
            </span>
          )}
          {mediaURL && validateImageUrl(mediaURL) ? (
            <img
              onDoubleClick={() => canEditPosts && openFullScreenEditor()}
              className={cx({ "cursor-pointer": canEditPosts })}
              onClick={() => canEditPosts && openMiniEditor()}
              src={mediaURL}
              alt=""
            />
          ) : (
            <video src={mediaURL} controls />
          )}
          <div className="single-scheduled-post__details">
            <div className="flex flex-wrap gap-2 text-xs sm:text-sm">
              Posting to
              <SocialsSelect post={post} />
              on
              {post.queue_ordering_number ? (
                <span className="scheduled-date">
                  {publishPostDateFormatted}
                </span>
              ) : (
                <Link onClick={() => setCustomTimeModalOpen(true)}>
                  {publishPostDateFormatted}
                </Link>
              )}
            </div>
            <div
              className={cx("single-scheduled-post__caption", {
                "cursor-pointer": canEditPosts,
                "text-right": isRTL,
              })}
              onClick={() => canEditPosts && openMiniEditor()}
            >
              {post.caption?.replace(/^\s*[\r\n]/gm, "")}
            </div>
          </div>
          <Dropdown
            menu={{
              items: actionItems,
              onClick: ({ key }) =>
                key === "edit-post" ? openMiniEditor(true) : handleAction(key),
            }}
            disabled={isLoading}
          >
            <Button
              className="ml-auto aspect-square"
              size="small"
              icon={<EllipsisOutlined />}
              loading={isLoading}
            />
          </Dropdown>
          <CustomTimeModal post={post} publishAt={publishPostDate} />
        </div>
      )}
    </PostCardWrapper>
  );
};

export default ScheduledPostCard;
