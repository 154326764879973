import { Button } from "antd";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import useAppContext from "config/AppContext/useAppContext";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import UserMenu from "../UserMenu/UserMenu";
import AccountDetails from "./AccountDetails";
import ProfilePicture from "./ProfilePicture";

const UserDetailsCard = () => {
  const { openGlobalModal } = useGlobalModal();
  const navigate = useNavigate();

  const { userInfo, isAgencyMember } = useAppSelector((state) => state.user);
  const businesses = useAppSelector((state) => state.user.businesses);
  const { subscription } = userInfo;
  const isFreePlan = subscription?.plan === "free";

  const { realm } = useAppContext();

  const shouldShowUpgradeButton = useMemo(
    () => realm.isMarky && !isAgencyMember && isFreePlan,
    [subscription, realm.isMarky, isAgencyMember]
  );

  const title = useMemo(() => {
    if (!userInfo.email) {
      return "Guest";
    }

    if (!userInfo.given_name && !userInfo.family_name) {
      return;
    }

    return `${userInfo.given_name} ${userInfo.family_name}`;
  }, [userInfo, businesses]);

  const email = useMemo(() => {
    if (userInfo.email !== "NO_EMAIL") {
      return userInfo.email;
    }
  }, [userInfo]);

  const openUpgradeAccountModal = useCallback(() => {
    openGlobalModal({
      modalName: "subscription",
      modalData: {
        modalType: isFreePlan ? "default" : "upgradeToProfessional",
      },
    });
  }, [subscription, isFreePlan]);

  const logout = useCallback(() => {
    navigate("/logout");
  }, []);

  return (
    <>
      <div className="user-details-card">
        <div className="user-details-card__top">
          <div className="user-details-card__top__left">Account</div>
          <div className="user-details-card__top__right">
            <UserMenu logout={logout} />
          </div>
        </div>

        <div className="user-details-card__main">
          <div className="user-details-card__main__left flex items-center">
            <ProfilePicture />
            <div className="user-details-card__main__left__user-info">
              {title && <strong className="account-title">{title}</strong>}
              <span>{email}</span>
              <span>{userInfo.phone}</span>
            </div>
          </div>
        </div>
        {realm.isMarky && <AccountDetails />}

        <div className="user-details-card__bottom">
          {shouldShowUpgradeButton && (
            <Button
              onClick={() =>
                userInfo.email
                  ? openUpgradeAccountModal()
                  : openGlobalModal({
                      modalName: "signup",
                      modalData: { successCallback: openUpgradeAccountModal },
                    })
              }
              id="upgrade-btn-manage-account"
            >
              Upgrade Account
            </Button>
          )}
          {localStorage.isAnonymous && (
            <Button
              onClick={() =>
                openGlobalModal({
                  modalName: "signup",
                })
              }
            >
              Claim Account
            </Button>
          )}
          {subscription?.is_appsumo && (
            <Link
              to={`https://appsumo.com/account/redemption/${subscription.subscription_id}#change-plan`}
              target="_blank"
            >
              <Button>Manage AppSumo License</Button>
            </Link>
          )}
          <Button onClick={logout}>Logout</Button>
        </div>
      </div>
    </>
  );
};

export default UserDetailsCard;
