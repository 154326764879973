import { Button, Modal } from "antd";

type CustomScheduleConfirmationModalProps = {
  resetSchedule: () => void;
  saveSchedule: () => void;
  close: () => void;
  open: boolean;
};

const CustomScheduleConfirmationModal = ({
  resetSchedule,
  saveSchedule,
  close,
  open,
}: CustomScheduleConfirmationModalProps) => {
  return (
    <Modal
      open={open}
      centered
      onCancel={close}
      title="Posts will be rescheduled"
      className="custom-schedule-confirmation-modal"
      footer={
        <Footer
          resetSchedule={resetSchedule}
          saveSchedule={saveSchedule}
          close={close}
        />
      }
    >
      <p>
        Posts will be rescheduled based on your new schedule. Any custom time
        schedules will stay the same.
      </p>
    </Modal>
  );
};

const Footer = ({
  close,
  saveSchedule,
  resetSchedule,
}: Omit<CustomScheduleConfirmationModalProps, "open">) => {
  return (
    <div className="custom-schedule-confirmation-modal__footer">
      <Button
        onClick={() => {
          resetSchedule();
          close();
        }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={saveSchedule}>
        Confirm
      </Button>
    </div>
  );
};

export default CustomScheduleConfirmationModal;
