import { ModalProps } from "antd";
import PremiumFeatureModal, {
  PremiumFeatureIcon,
} from "components/GlobalModals/PremiumFeatureModal";
import { useMemo, useState } from "react";
import { MdEditCalendar } from "react-icons/md";
import { useAppSelector } from "store";
import {
  currentBusinessGetter,
  integrationsGetter,
} from "store/user/userSlice";
import ConnectSocialsTooltip from "../components/ConnectSocialsTooltip";
import ScheduleCustomTimeModal, {
  ScheduleCustomTimeModalProps,
} from "./ScheduleCustomTimeModal";

export const CUSTOM_SCHEDULE_ITEM_KEY = "custom-schedule";

const useScheduleCustomTime = ({
  key = CUSTOM_SCHEDULE_ITEM_KEY,
  hideIcon,
}: {
  key?: string;
  hideIcon?: boolean;
} = {}) => {
  const connectedSocials = useAppSelector(integrationsGetter);
  const [modalOpen, setModalOpen] = useState(false);

  const business = useAppSelector(currentBusinessGetter);
  const { can_publish_at_custom_time: canSetCustomTime = true } =
    business.owner?.subscription?.restrictions ?? {};

  const CustomTimeModal = useMemo(() => {
    const ModalComponent = canSetCustomTime
      ? ScheduleCustomTimeModal
      : PremiumFeatureModal;
    const closeModal = () => setModalOpen(false);

    return (props: ScheduleCustomTimeModalProps & ModalProps) => (
      <ModalComponent
        {...props}
        open={modalOpen}
        onCancel={closeModal}
        onClose={closeModal}
      />
    );
  }, [modalOpen, canSetCustomTime]);

  return {
    CustomTimeModal,
    customTimeMenuItem: {
      key,
      label: <ConnectSocialsTooltip>Custom Time</ConnectSocialsTooltip>,
      icon: !canSetCustomTime ? (
        <PremiumFeatureIcon />
      ) : hideIcon ? undefined : (
        <MdEditCalendar />
      ),
      onClick: () => setModalOpen(true),
      disabled: !connectedSocials.length,
    },
    customTimeModalOpen: modalOpen,
    setCustomTimeModalOpen: setModalOpen,
  };
};

export default useScheduleCustomTime;
