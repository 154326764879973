import { chaliceClient } from "./baseClients";
import { Paths, Permissions } from "./config/chalice-api";

export const inviteUserToTeam = ({
  businessId,
  userId,
}: {
  businessId: string;
  userId: string;
}) =>
  chaliceClient.post<Paths.V1BusinessAddMember.Responses.$200>(
    `/business/${businessId}/team`,
    {
      member_user_id: userId,
    }
  );

export const removeMember = ({
  businessId,
  roleId,
}: {
  businessId: string;
  roleId: string;
}) => chaliceClient.delete(`/business/${businessId}/team/${roleId}`);

export const updatePermissions = ({
  permissions,
  businessId,
  memberId,
}: {
  permissions: Permissions;
  businessId: string;
  memberId: string;
}) =>
  chaliceClient.put<Paths.V1BusinessUpdateMembership.Responses.$200>(
    `/business/${businessId}/team/${memberId}`,
    {
      permissions,
    }
  );
