import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import classNames from "classnames";
import dayjs from "dayjs";
import { range } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getPostsThunk, schedulePostThunk } from "store/posts/postActions";
import { Post } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import { sortScheduledPosts } from "store/posts/postUtil";
import PostCardList from "../components/PostCardList";
import DraggablePost from "./DraggablePost";
import ScheduledPostCard from "./ScheduledPostCard";

export type AutoScheduledPost = Post<"SCHEDULED"> & {
  queue_ordering_number: number;
};
// TODO: refactor/simplify the logic in here
const OrderedScheduledPosts = ({
  postList,
}: {
  postList: AutoScheduledPost[];
}) => {
  const dispatch = useAppDispatch();
  const {
    scheduled: { publishAt },
  } = useAppSelector(selectPostStore);

  const defaultOrderedPosts = () => [...postList].sort(sortScheduledPosts);
  const [orderedPosts, setOrderedPosts] = useState<AutoScheduledPost[]>(
    defaultOrderedPosts()
  );

  useEffect(() => {
    if (postList.length !== Object.keys(publishAt).length) {
      dispatch(getPostsThunk({ postScreen: "scheduled" }));
    }
  }, [postList.length]);

  useEffect(() => {
    setOrderedPosts(defaultOrderedPosts());
  }, [postList]);

  const autoScheduledTimes = useMemo(
    () =>
      postList
        .map(({ id }) => publishAt[id])
        .sort((a, b) => (a === b ? 0 : dayjs(a).isBefore(dayjs(b)) ? -1 : 1)),
    [postList, publishAt]
  );

  const getNewQueueNumber = (newIndex: number, oldIndex: number) => {
    if (newIndex === 0) {
      return orderedPosts[0].queue_ordering_number - 1;
    } else if (newIndex === orderedPosts.length - 1) {
      return orderedPosts[newIndex].queue_ordering_number + 1;
    }

    const [beforeIndex, afterIndex] =
      newIndex > oldIndex ? [newIndex, newIndex + 1] : [newIndex - 1, newIndex];

    const before = orderedPosts[beforeIndex].queue_ordering_number;
    const after = orderedPosts[afterIndex].queue_ordering_number;
    return (before + after) / 2;
  };

  const handleReorder = ({
    newIndex,
    oldIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) => {
    if (newIndex === -1) {
      return;
    }

    const updatedPosts = arrayMove(orderedPosts, oldIndex, newIndex);
    const queue = getNewQueueNumber(newIndex, oldIndex);

    setOrderedPosts(
      updatedPosts.map((post, index) =>
        index === newIndex ? { ...post, queue_ordering_number: queue } : post
      )
    );

    dispatch(
      schedulePostThunk({
        post: updatedPosts[newIndex],
        queue,
      })
    );
  };

  const moveToTop = (index: number) => {
    if (index === 0) {
      return;
    }

    handleReorder({ newIndex: 0, oldIndex: index });
    const doc = document.querySelector(".scheduled-posts");
    doc && doc.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const moveToBottom = (index: number) => {
    if (index === orderedPosts.length - 1) {
      return;
    }

    handleReorder({ newIndex: orderedPosts.length - 1, oldIndex: index });
    const doc = document.querySelector(".scheduled-posts");
    doc && doc.scrollTo({ top: 10000, left: 0, behavior: "smooth" });
  };

  const handleUp = (index: number) => {
    if (index === 0) {
      return;
    }

    handleReorder({ newIndex: index - 1, oldIndex: index });
  };

  const handleDown = (index: number) => {
    if (index === orderedPosts.length - 1) {
      return;
    }
    handleReorder({ newIndex: index + 1, oldIndex: index });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = orderedPosts.findIndex((post) => post.id === active.id);
      const newIndex = orderedPosts.findIndex((post) => post.id === over?.id);
      handleReorder({ oldIndex, newIndex });
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext disabled={orderedPosts.length < 2} items={orderedPosts}>
        <PostCardList postList={orderedPosts} className="space-y-4">
          {(post, index) => (
            <DraggablePost
              handleDown={
                index < orderedPosts.length - 1
                  ? () => handleDown(index)
                  : undefined
              }
              handleUp={index > 0 ? () => handleUp(index) : undefined}
              key={post.id}
              id={post.id}
            >
              <ScheduledPostCard
                autoScheduledIndex={index}
                {...{ post, autoScheduledTimes, moveToTop, moveToBottom }}
              />
            </DraggablePost>
          )}
        </PostCardList>

        {orderedPosts.length > 0 && (
          <div className="w-full flex flex-col gap-4 max-sm:gap-8 items-center max-sm:mt-4 sm:pl-8">
            {range(2).map((_, index) => (
              <div
                key={index}
                className={classNames(
                  "rounded-md min-h-[152px] w-full max-w-[600px] bg-antd-colorFillSecondary",
                  {
                    "bg-antd-colorFillTertiary": index === 1,
                  }
                )}
              />
            ))}
          </div>
        )}
      </SortableContext>
    </DndContext>
  );
};

export default OrderedScheduledPosts;
