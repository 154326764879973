import { loadStripe } from "@stripe/stripe-js";
import { Alert, message, Modal, Tooltip } from "antd";
import { createCheckoutSession } from "api/stripe";
import dayjs from "dayjs";
import { genericError } from "fixtures/globalConstants";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { selectUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export const UPGRADE_BUSINESS_PARAM = "businessToBeUpgraded";

const ChangeBusinessTierModal = ({
  businessName,
  businessId,
  modalType,
  close,
}: {
  modalType: "downgrade" | "upgrade" | null;
  businessName: string;
  businessId: string;
  close: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { subscription } = useAppSelector(selectUserInfo);
  const { businesses } = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const isLimitReached = useMemo(() => {
    const allowed =
      (subscription?.advanced_whitelabel_businesses ?? 0) +
      (subscription?.restrictions?.advanced_whitelabel_businesses ?? 0);
    const current = businesses.filter(
      (b) => b.whitelabel_tier === "ADVANCED"
    ).length;
    return current >= allowed;
  }, [subscription, businesses]);

  const { modalTitle, modalDescription, okText } = useMemo(() => {
    switch (modalType) {
      case "upgrade":
        return {
          modalTitle: `Upgrade ${businessName} to Advance white labeling?`,
          modalDescription: (
            <span>
              Upgrading {businessName} will allow setting edit access and custom
              permissions for clients within the business.
              {isLimitReached && (
                <>
                  <CostAlert />
                  <a
                    className="block mt-2"
                    href="https://help.mymarky.ai/en/article/standard-vs-advanced-white-labeling-for-agency-jil9w1/"
                    target="_blank"
                  >
                    Learn more about paid plans
                  </a>
                </>
              )}
            </span>
          ),
          okText: isLimitReached ? "Purchase" : "Upgrade",
        };
      case "downgrade":
        return {
          modalTitle: `Downgrade ${businessName} to Standard white labeling?`,
          modalDescription: `Downgrading ${businessName} means clients within this business will no longer be able to have edit access or custom permissions. This will take effect immediately and your account will be credited for your remaining time.`,
          okText: "Downgrade",
        };
      default:
        return {
          modalTitle: "",
          modalDescription: "",
          okText: "",
        };
    }
  }, [modalType, businessName]);

  const changeTier = async () => {
    const isUpgrading = modalType === "upgrade";
    setIsLoading(true);
    try {
      if (!subscription?.subscription_id && isLimitReached && isUpgrading) {
        const stripe = await stripePromise;
        const session = await createCheckoutSession(
          { advanced_whitelabel_businesses: 1 },
          {
            successUrl: `${window.location.origin}/dashboard?${UPGRADE_BUSINESS_PARAM}=${businessId}`,
          }
        );
        await stripe?.redirectToCheckout({
          sessionId: session.sessionId,
        });
      } else {
        await dispatch(
          updateBusinessDetails({
            id: businessId,
            whitelabel_tier: isUpgrading ? "ADVANCED" : "STANDARD",
          })
        );
        trackEvent("upgraded_wl_tier");

        message.success("Business tier changed successfully.");
      }
      close();
    } catch (error) {
      genericError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={Boolean(modalType)}
      title={modalTitle}
      onCancel={close}
      okText={okText}
      onOk={changeTier}
      centered
      okButtonProps={{
        danger: modalType === "downgrade",
        loading: isLoading,
      }}
      cancelButtonProps={{ disabled: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      {modalDescription}
    </Modal>
  );
};

export default ChangeBusinessTierModal;

const CostAlert = () => {
  const user = useAppSelector(selectUserInfo);

  const monthlyRate =
    user.subscription?.is_appsumo &&
    dayjs(user.created_at) < dayjs("2024-06-01")
      ? "$15"
      : "$35";
  const showMonthlyPrice =
    user.subscription?.plan?.includes("monthly") ||
    user.subscription?.is_appsumo;
  const costText = showMonthlyPrice ? `${monthlyRate}/month` : "$420/year";

  const message = (
    <span>
      This will cost an additional {costText} (
      <Tooltip
        title="Prorated means you will be charged immediately for the full term. You can downgrade at any time which will credit your Marky account with the remaining balance."
        trigger="click"
      >
        <span className="underline cursor-pointer">prorated</span>
      </Tooltip>
      ).
    </span>
  );

  return <Alert showIcon type="warning" message={message} className="my-4" />;
};
