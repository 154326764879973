import { Form, Input, ModalProps } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import ModalWithBorders from "components/ModalWithBorders";
import { genericError } from "fixtures/globalConstants";
import { useEffect, useMemo, useState } from "react";
import { trackEvent } from "utils/eventTracking/trackEvents";

type EditTopicOrMemoryForm = { title: string; description: string };

type EditTopicOrMemoryProps = ModalProps & {
  initialValues?: EditTopicOrMemoryForm;
  entityType: "topic" | "memory";
  onSubmit: (values: EditTopicOrMemoryForm) => Promise<void> | void;
  onClose: () => void;
};

const EditTopicOrMemory = ({
  initialValues,
  entityType,
  onSubmit,
  onClose,
  ...modalProps
}: EditTopicOrMemoryProps) => {
  const [form] = useForm<EditTopicOrMemoryForm>();

  const title = useWatch("title", form);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initialValues && form.setFieldsValue(initialValues);
  }, [initialValues]);

  const placeholders = useMemo(
    () =>
      entityType === "topic"
        ? {
            title: "Title of the topic",
            description:
              "Couple sentence explanation of your topic. This will be used as the starting point for a new post.",
          }
        : {
            title: "Title of the memory",
            description:
              "Describe whatever you want us to remember for future posts. Examples include a couple sentences about the services you offer or an essay about the history of your company.",
          },
    [entityType]
  );

  const handleSave = async (formData: EditTopicOrMemoryForm) => {
    setIsLoading(true);

    try {
      await onSubmit(formData);
      entityType === "topic" &&
        trackEvent("updated_topics", { action: "edit" });
      onClose();
    } catch (error) {
      genericError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWithBorders
      okButtonProps={{ loading: isLoading }}
      okText="Save"
      onOk={form.submit}
      onCancel={onClose}
      afterClose={() => form.resetFields()}
      {...modalProps}
    >
      <Form
        onFinish={handleSave}
        id="edit-topic-memory"
        layout="vertical"
        form={form}
        requiredMark={false}
      >
        <Form.Item
          rules={[{ required: true, max: 50 }]}
          label="Title"
          name="title"
        >
          <Input
            placeholder={placeholders.title}
            suffix={
              <span className="text-antd-colorTextSecondary">
                {title?.length ?? 0} / 50
              </span>
            }
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="Description"
          name="description"
        >
          <TextArea placeholder={placeholders.description} rows={3} />
        </Form.Item>
      </Form>
    </ModalWithBorders>
  );
};

export default EditTopicOrMemory;
