import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col items-center justify-center w-dvw space-y-6"
      style={{ height: window.innerHeight }}
    >
      <img
        src="https://media.tenor.com/lx2WSGRk8bcAAAAC/pulp-fiction-john-travolta.gif"
        alt=""
      />
      <p className="text-2xl font-semibold text-center">
        We don't know where that page went!
      </p>
      <Button type="primary" onClick={() => navigate("/")}>
        Return to safety
      </Button>
    </div>
  );
};

export default NotFoundPage;
