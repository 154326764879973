import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Input, Select, Slider, Tag, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DefaultOptionType } from "antd/es/select";
import { fetchWritingTemplates } from "api/businesses";
import { MixMatchChoicesBody } from "api/config/chalice-api";
import { modifyCaption } from "api/postsApi";
import ModalWithBorders from "components/ModalWithBorders";
import PaletteColorBlocks from "components/PaletteColorBlocks";
import useThemes from "hooks/useThemes";
import DiscardChangesConfirmation from "pages/Posts/PostEditor/DiscardChangesConfirmation";
import PRESET_PALETTES from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/PRESET_PALETTES";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useClickAway } from "react-use";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";

type ConfigType = {
  customWritingTemplate: string;
  writingTemplates: string | string[];
  numberOfPosts: number;
  customTopic: string;
  customCTA: string;
  palettes: number[];
  themes: string[];
  topic: string;
  cta: string;
};

type ConfigKeys = keyof ConfigType;

const GeneratePostPanel = ({
  generateMorePosts,
  isOpen,
  close,
}: {
  generateMorePosts: (generateBody: MixMatchChoicesBody) => void;
  close: () => void;
  isOpen: boolean;
}) => {
  useThemes();

  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { themes } = useAppSelector((state) => state.themes);

  const allPalettes = [
    ...(currentBusiness.brand.palettes || []),
    ...PRESET_PALETTES,
  ];

  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
  const [writingTemplateOptions, setWritingTemplateOptions] = useState<
    { label: JSX.Element; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState<ConfigType>({
    customWritingTemplate: "",
    writingTemplates: [],
    numberOfPosts: 3,
    customTopic: "",
    customCTA: "",
    palettes: [],
    themes: [],
    topic: "",
    cta: "",
  });

  const isCTALoading = config.cta === "auto" && !config.customCTA;

  const topicOptions = useMemo(() => {
    const topics = currentBusiness.topics.reduce<DefaultOptionType[]>(
      (prev, { title, body, enabled }) => {
        enabled &&
          prev.push({
            label: <div className="label-container">{title ?? body}</div>,
            value: `${title && title + " "}${body}`,
          });
        return prev;
      },
      []
    );

    return [{ label: "Custom", value: "custom" }, ...topics];
  }, [currentBusiness]);

  const themesOptions = useMemo(() => {
    return themes.map((theme) => ({
      label: (
        <div>
          <img src={theme.image_url} className="theme-select-option" />
        </div>
      ),
      value: theme.name,
      key: theme.display_name,
    }));
  }, [themes]);

  const colorPaletteOptions = useMemo(
    () =>
      allPalettes.map((palette, index) => ({
        label: <PaletteColorBlocks palette={palette} />,
        value: index,
      })),
    [currentBusiness]
  );

  const ctaOptions = useMemo(() => {
    const currentCTAs = currentBusiness.ctas.map((cta) => ({
      label: cta,
      value: cta,
    }));

    const isTopicSelected = !!config.customTopic || !!config.topic;

    return [
      ...(isTopicSelected
        ? [
            {
              label: "Auto",
              value: "auto",
            },
          ]
        : []),
      { label: "Custom", value: "custom" },
      ...currentCTAs,
    ];
  }, [config]);

  const handleConfigChange = <T,>(key: ConfigKeys, value: T) => {
    setConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const closeModal = () => {
    close();
    setConfig({
      customWritingTemplate: "",
      writingTemplates: [],
      numberOfPosts: 1,
      customTopic: "",
      customCTA: "",
      palettes: [],
      themes: [],
      topic: "",
      cta: "",
    });
  };

  const popupContainerSelector = () =>
    document.getElementById("generate-post-panel") as HTMLElement;

  const getThemeName = useCallback(
    (theme: string) => {
      return themes.find(({ name }) => name === theme)?.display_name;
    },
    [themes]
  );
  const generatePosts = async () => {
    setShowDiscardChangesModal(false);
    setIsLoading(true);

    const requestBody: MixMatchChoicesBody = {
      batch_size: config.numberOfPosts,
    };

    if (config.cta) {
      requestBody.ctas = ["custom", "auto"].includes(config.cta)
        ? [config.customCTA]
        : [config.cta];
    }

    if (config.topic) {
      requestBody.topics =
        config.topic === "custom" ? [config.customTopic] : [config.topic];
    }

    if (config.palettes.length) {
      requestBody.palettes = config.palettes.map((i) => allPalettes[i]);
    }

    if (config.writingTemplates.length) {
      requestBody.caption_templates = Array.isArray(config.writingTemplates)
        ? config.writingTemplates.map((template) => ({ body: template }))
        : [{ body: config.customWritingTemplate }];
    }

    if (config.themes.length) {
      requestBody.theme_names = config.themes;
    }

    try {
      await generateMorePosts(requestBody);
    } catch (error) {
      console.error(error);
    }
    closeModal();
    setIsLoading(false);
  };

  const handleOutsideClick = () => {
    if (isLoading) {
      return;
    }

    const unsavedChanges = Object.entries(config).some(
      ([key, value]) =>
        !["numberOfPosts", "palettes"].includes(key) &&
        (Array.isArray(value) ? value.length : value)
    );

    if (unsavedChanges) {
      setShowDiscardChangesModal(true);
    } else {
      closeModal();
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  useClickAway(ref, handleOutsideClick);

  useEffect(() => {
    const brandPalettes = currentBusiness.brand.palettes?.map((palette) =>
      allPalettes.findIndex(
        (p) => JSON.stringify(p) === JSON.stringify(palette)
      )
    );
    setConfig((prev) => ({ ...prev, palettes: brandPalettes as number[] }));
  }, [currentBusiness]);

  useEffect(() => {
    if (config.cta === "auto") {
      setConfig({ ...config, customCTA: "" });
      modifyCaption({
        caption: config.customTopic || config.topic,
        instructions: "create a short (~5) word call-to-action for this topic",
      }).then((res) => setConfig({ ...config, customCTA: res ?? "" }));
    }
  }, [config.cta, config.customTopic, config.topic]);

  useEffect(() => {
    if (isOpen) {
      fetchWritingTemplates().then(({ templates }) => {
        setWritingTemplateOptions((prev) => [
          ...prev,
          ...(templates
            ? templates.map(({ body }) => ({
                label: <div className="label-container">{body}</div>,
                value: body,
              }))
            : []),
        ]);
      });
    }
  }, [isOpen]);

  return (
    <ModalWithBorders
      modalRender={(modal: ReactNode) => <div ref={ref}>{modal}</div>}
      title="Custom Generate"
      onCancel={closeModal}
      maskClosable={false}
      open={isOpen}
      footer={
        <>
          <Button disabled={isLoading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={generatePosts}
            disabled={isCTALoading}
            loading={isLoading}
            type="primary"
          >
            Generate
          </Button>
        </>
      }
      className="generate-post-panel"
    >
      <div className="generate-post-panel__block" id="generate-post-panel">
        <label>What do you want to post about?</label>
        <Select
          getPopupContainer={popupContainerSelector}
          onChange={(value) => handleConfigChange("topic", value)}
          popupClassName="topic-select-dropdown"
          placeholder="All Content Topics"
          options={topicOptions}
          value={config.topic}
          allowClear
        />
        {config.topic === "custom" && (
          <TextArea
            placeholder="Write a couple sentences on what you want to post about."
            onChange={(e) => handleConfigChange("customTopic", e.target.value)}
            value={config.customTopic}
            style={{ marginTop: 8 }}
          />
        )}
      </div>

      <div className="generate-post-panel__block">
        <label>
          Call to Action{" "}
          <Tooltip title="This field is for the message or prompt that encourages your audience to take a specific action, such as 'Buy Now', 'Sign Up,' or 'Learn More.' It should be clear, concise, and motivating.">
            <InfoCircleOutlined />
          </Tooltip>
        </label>
        <Select
          getPopupContainer={popupContainerSelector}
          onChange={(value) => handleConfigChange("cta", value)}
          placeholder="All CTAs"
          options={ctaOptions}
          value={config.cta}
          allowClear
        />
        {["auto", "custom"].includes(config.cta) && (
          <Input
            onChange={(e) => handleConfigChange("customCTA", e.target.value)}
            placeholder={isCTALoading ? "Loading..." : ""}
            disabled={isCTALoading}
            value={config.customCTA}
            style={{ marginTop: 8 }}
          />
        )}
      </div>

      <div className="generate-post-panel__block">
        <label>
          Color Palettes{" "}
          <Tooltip title="If none is selected, all brand color palettes will be shuffled.">
            <InfoCircleOutlined />
          </Tooltip>
        </label>
        <Select
          getPopupContainer={popupContainerSelector}
          onChange={(value) => handleConfigChange("palettes", value)}
          options={colorPaletteOptions}
          value={config.palettes}
          mode="tags"
        />
      </div>

      <div className="generate-post-panel__block">
        <label>
          Theme{" "}
          <Tooltip title="If none is selected, all the themes will be shuffled.">
            <InfoCircleOutlined />
          </Tooltip>
        </label>
        <Select
          getPopupContainer={popupContainerSelector}
          onChange={(value) => handleConfigChange("themes", value)}
          tagRender={({ value, closable, onClose }) => (
            <Tag closable={closable} onClose={onClose}>
              {getThemeName(value)}
            </Tag>
          )}
          placeholder="All Themes"
          value={config.themes}
          className="themes-select"
          loading={!themes.length}
          options={themesOptions}
          mode="tags"
        />
      </div>

      <div className="generate-post-panel__block">
        <label>
          Writing Template{" "}
          <Tooltip title="Here is a template for the AI to fill. Use curly brackets for variables. For example: Here are {number} tips to use for {achieving audience goal}: {numbered list with new lines}">
            <InfoCircleOutlined />
          </Tooltip>
        </label>
        <Select
          getPopupContainer={popupContainerSelector}
          onChange={(value) => handleConfigChange("writingTemplates", value)}
          loading={!writingTemplateOptions.length}
          popupClassName="writing-template-select"
          placeholder="All Writing Templates"
          options={writingTemplateOptions}
          value={config.writingTemplates}
          mode="tags"
          allowClear
          tagRender={({ value, onClose, closable }) => (
            <Tag closable={closable} onClose={onClose}>
              {value?.slice(0, 20)}...
            </Tag>
          )}
        />
        {config.writingTemplates === "custom" && (
          <TextArea
            value={config.customWritingTemplate}
            onChange={(e) =>
              handleConfigChange("customWritingTemplate", e.target.value)
            }
            style={{ marginTop: 8 }}
          />
        )}
      </div>

      <div className="generate-post-panel__block">
        <label>Number of Posts</label>
        <Slider
          onChange={(value) => handleConfigChange("numberOfPosts", value)}
          value={config.numberOfPosts}
          marks={{ 1: 1, 10: 10 }}
          max={10}
          step={1}
          min={1}
        />
      </div>
      <DiscardChangesConfirmation
        confirmationOpen={showDiscardChangesModal}
        setConfirmationOpen={setShowDiscardChangesModal}
        discardChanges={closeModal}
      />
    </ModalWithBorders>
  );
};

export default GeneratePostPanel;
