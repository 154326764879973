import { Button, Input, message } from "antd";
import { updateUser } from "api/user";
import PhoneNumberInput from "components/Common/PhoneNumberInput";
import ModalWithBorders from "components/ModalWithBorders";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Value as PhoneValue } from "react-phone-number-input";
import { useAppDispatch, useAppSelector } from "store";
import { setUserInfo } from "store/user/userSlice";

type UserDetails = {
  firstName: string;
  lastName: string;
  email: string;
};

const EditUserDetails = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { ownedRealm, userInfo, isAgencyOwner } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();
  const [phone, setPhone] = useState(userInfo.phone);

  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>({
    firstName: "",
    lastName: "",
    email: "",
  });

  const isButtonDisabled = useMemo(() => {
    return Object.values(userDetails).some((value) => !value);
  }, [userDetails]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prevValue) => {
      return {
        ...prevValue,
        [event.target.name]: event.target.value,
      };
    });
  };

  const getStatus = (key: keyof UserDetails) => {
    return userDetails[key] ? "" : "error";
  };

  const updateUserDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const updatedUser = await updateUser({
        given_name: userDetails.firstName.trim(),
        family_name: userDetails.lastName.trim(),
        email: userDetails.email.trim(),
        ...(phone ? { phone } : { phone: null }),
      });

      dispatch(setUserInfo({ user: updatedUser }));
      message.success("Account details updated successfully");
      close();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [userDetails, isAgencyOwner, phone]);

  useEffect(() => {
    setUserDetails({
      firstName: userInfo.given_name ?? "",
      lastName: userInfo.family_name ?? "",
      email: userInfo.email ?? "",
    });
  }, [userInfo, isAgencyOwner, ownedRealm]);

  return (
    <ModalWithBorders
      centered
      title="Edit Account Details"
      open={isOpen}
      closable={!isLoading}
      footer={
        <div className="flex gap-2 justify-end">
          <Button disabled={isLoading} onClick={close}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={updateUserDetails}
            disabled={isButtonDisabled}
            loading={isLoading}
          >
            Update
          </Button>
        </div>
      }
      onCancel={close}
      maskClosable={!isLoading}
    >
      <form className="form-wrapper [&_div]:!mb-0">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstName">First Name</label>
            <Input
              value={userDetails.firstName}
              onChange={handleChange}
              name="firstName"
              id="firstName"
              status={getStatus("firstName")}
            />
          </div>
          <div>
            <label htmlFor="lastName">Last Name</label>
            <Input
              value={userDetails.lastName}
              id="lastName"
              name="lastName"
              onChange={handleChange}
              status={getStatus("lastName")}
            />
          </div>
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <Input
            value={userDetails.email}
            id="email"
            name="email"
            onChange={handleChange}
            status={getStatus("email")}
          />
        </div>
        <PhoneNumberInput
          onChange={(value: PhoneValue) => setPhone(value)}
          value={phone as PhoneValue}
          showLabel
        />
      </form>
    </ModalWithBorders>
  );
};

export default EditUserDetails;
