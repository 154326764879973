import { Tour } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils.ts";
import { useAppDispatch, useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";
import {
  setOnboardingTourStep,
  setShowOnboardingTour,
} from "store/user/userSlice";
import useGlobalModal from "../GlobalModalContext/useGlobalModal.tsx";
import { ONBOARDING_TOUR_STEPS } from "./onboardingTourSteps.tsx";

const OnboardingTour = () => {
  const onboardingTourEnabled = useTypedFeatureIsOn("onboarding-tour");

  const dispatch = useAppDispatch();

  const { realm } = useAppContext();
  const { globalModal } = useGlobalModal();
  const { generated } = useAppSelector(selectPostStore);
  const { showOnboardingTour } = useAppSelector((state) => state.user);

  if (!realm.isMarky || !onboardingTourEnabled) {
    return;
  }

  const closeTour = () => {
    dispatch(setShowOnboardingTour(false));
    dispatch(setOnboardingTourStep(null));
  };
  const openTour =
    !globalModal.modalName &&
    (showOnboardingTour === "force" ||
      (showOnboardingTour && generated.count > 0));

  return (
    // TODO: Remove this .onboarding-tour-container and use the Tour props to style the buttons instead
    <div className="onboarding-tour-container">
      <Tour
        getPopupContainer={() =>
          document.querySelector(".onboarding-tour-container") as HTMLElement
        }
        // @ts-expect-error Allowing `target` function to resolve to null
        steps={ONBOARDING_TOUR_STEPS}
        open={openTour}
        onFinish={closeTour}
        onClose={closeTour}
        disabledInteraction
        onChange={(step) => dispatch(setOnboardingTourStep(step))}
        arrow
      />
    </div>
  );
};

export default OnboardingTour;
