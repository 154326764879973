import { UploadFile } from "antd";
import { Dayjs } from "dayjs";
import { Post, PostStatus } from "store/posts/postConstants";
import { IntegrationType } from "store/user/userConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { v4 as uuidv4 } from "uuid";
import { chaliceClient } from "./baseClients";
import {
  GetPostResponse,
  MixMatchChoicesBody,
  Paths,
  PublishStatus,
  ReviewStatus,
  UpdatePostBody,
} from "./config/chalice-api";

export type GetPostsResponse = Omit<GetPostResponse, "posts"> & {
  posts: Post[];
};

export type GetPostsPaginationArgs = {
  count?: number;
  start?: Dayjs;
  end?: Dayjs;
};

export const getNextPostPage = ({
  businessId,
  nextPageUrl,
}: {
  businessId: string;
  nextPageUrl: string;
}) =>
  chaliceClient.get<GetPostsResponse>(
    `/business/${businessId}/posts${nextPageUrl}`
  );

export const getPosts = async ({
  postStatus,
  businessId,
  reviewStatus,
  count,
  start,
  end,
}: {
  postStatus: PostStatus;
  businessId: string;
  reviewStatus?: ReviewStatus;
} & GetPostsPaginationArgs) =>
  chaliceClient.get<GetPostsResponse>(`/business/${businessId}/posts`, {
    params: {
      ...(reviewStatus && { review_status: reviewStatus }),
      status: postStatus,

      ...(start || end
        ? {
            count,
            status_date_gt: start?.toISOString(),
            status_date_lt: end?.toISOString(),
          }
        : // TODO: Can't paginate scheduled posts correctly: showing queue and custom
          // lists separately to user, but both are fetched on same call.
          // Need to be able to fetch them separately for pagination and total count.
          // https://mymarky.atlassian.net/browse/SWE-2244
          postStatus !== "SCHEDULED" && { count: count ?? 50 }),
    },
  });

export const updatePost = async ({
  postId,
  body,
}: {
  postId: string;
  body: UpdatePostBody;
}) =>
  chaliceClient.put<{ post: Post }>(`/posts/${postId}`, {
    ...body,
    should_render: body.should_render || false,
  });

export const generateNewPosts = async (
  businessId: string,
  body?: MixMatchChoicesBody
) =>
  chaliceClient.post<{ ids: string[] }>(
    `/business/${businessId}/generate-posts-async`,
    body ?? {}
  );

export const getPostDetails = async (postId: string) =>
  chaliceClient.get<{ post: Post }>(`/posts/${postId}`);

export const autoAnimateTemplate = async (post: Post) =>
  chaliceClient.post<Paths.V1AutoAnimatePost.Responses.$200>(
    "/posts/auto-animate",
    post
  );

export const autoAnimatePost = async (postId: string) =>
  chaliceClient.get<Paths.V1AutoAnimatePost.Responses.$200>(
    `/posts/${postId}/auto-animate`
  );

export const schedulePost = async ({
  postId,
  body,
  newlyScheduled = false,
}: {
  postId: string;
  body: Paths.V1SchedulePost.RequestBody;
  newlyScheduled?: boolean;
}) => {
  const response = await chaliceClient.put<Paths.V1SchedulePost.Responses.$200>(
    `/posts/${postId}/schedule`,
    body
  );

  newlyScheduled &&
    trackEvent("scheduled_post", {
      refinerArgs: {
        addAttributes: { post_id: postId },
      },
    });

  return response;
};

export const deletePost = (postId: string) =>
  chaliceClient.put(`/posts/${postId}`, {
    status: "REJECTED",
  });

export const publishPost = ({
  postId,
  id,
}: {
  postId: string;
  social: IntegrationType;
  id: string;
}) =>
  chaliceClient.post<Paths.V1PublishPostToIntegration.Responses.$200>(
    `/posts/${postId}/publish/${id}`
  );

export const publishPostToAllSocials = ({ postId }: { postId: string }) =>
  chaliceClient.post<Paths.V1PublishPost.Responses.$200>(
    `/posts/${postId}/publish`
  );

export const getPresignedUrl = async (
  file: UploadFile | File,
  fileType?: string,
  fileName?: string
) => {
  const { url } =
    await chaliceClient.get<Paths.V1GetPresignedUrl.Responses.$200>(
      `/posts/presigned-url`,
      {
        params: {
          filename: fileName || `${uuidv4()}__${file.name}`,
          type: file.type || fileType,
        },
      }
    );

  if (!url) {
    throw new Error();
  }

  return { url };
};

export const createPost = (body: Paths.V1CreatePost.RequestBody) =>
  chaliceClient.post<{ post: Post }>("/posts", body);

export const generateCaptionFromImage = ({
  businessId,
  imageUrl,
}: {
  businessId: string;
  imageUrl: string;
}) =>
  chaliceClient.get<Paths.V1GetCaptionForImage.Responses.$200>(
    `/business/${businessId}/caption-for-image`,
    {
      params: { image_url: imageUrl },
    }
  );

export const modifyCaption = async ({
  caption,
  instructions,
}: {
  caption: string;
  instructions: string;
}) => {
  const response = await chaliceClient.post<Paths.V1ModifyText.Responses.$200>(
    `/posts/modify-text`,
    {
      text: caption,
      instructions,
    }
  );
  return response.result;
};

export const fetchPublishStatus = async (postId: string) => {
  const response = await chaliceClient.put<{
    results: Record<IntegrationType, PublishStatus>;
  }>(`/posts/${postId}/publish-status`);
  return response.results;
};

export const fetchPostsQuota = () =>
  chaliceClient.get<Paths.V1PostQuota.Responses.$200>("/posts/quota");
