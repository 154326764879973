import {
  ClockCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  HeartOutlined,
  MinusOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { ButtonProps } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import { DesignTemplate } from "api/config/chalice-api";
import Hashtag from "assets/icons/Hashtag";
import Suitcase from "assets/icons/Suitcase";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import ConnectSocialsTooltip from "pages/Posts/components/ConnectSocialsTooltip";
import { ReactNode } from "react";
import { FaRegEdit } from "react-icons/fa";
import {
  MdOutlineFastForward,
  MdOutlineRedo,
  MdOutlineSend,
} from "react-icons/md";
import { Post } from "store/posts/postConstants";

export type PostEditingDetails = {
  mediaUrls?: string[] | null;
  template?: DesignTemplate | null;
  inputs?: Post["inputs"];
};

export type CaptionModifyMenuKey = keyof typeof CAPTION_MODIFY_MENU;
export const CAPTION_MODIFY_MENU = {
  shorten: {
    icon: <MinusOutlined />,
    label: "Shorten",
    prompt: "Decrease the length of this social media post",
  },
  lengthen: {
    icon: <PlusOutlined />,
    label: "Lengthen",
    prompt: "Increase the length of this social media post",
  },
  moreFormal: {
    icon: <Suitcase />,
    label: "More Formal",
    prompt:
      "Make the tone of this social media post more formal and professional",
  },
  lessFormal: {
    icon: <SmileOutlined />,
    label: "More Casual",
    prompt: "Make the tone of this social media post more casual and friendly",
  },
  addEmojis: {
    icon: <HeartOutlined />,
    label: "More Emojis",
    prompt: "Look at this post and find places to insert some more emojis",
  },
  addHashtags: {
    icon: <Hashtag />,
    label: "Add Hashtags",
    prompt:
      "For this social media post, add a series of hashtags to the end of the entire post that relate to the post and boost searchability",
  },
  custom: { icon: <FaRegEdit />, label: "Custom" },
};

export const CAPTION_MODIFY_MENU_ITEMS: MenuItemType[] = Object.entries(
  CAPTION_MODIFY_MENU
).map(([key, { icon, label }]) => ({
  key,
  icon,
  label,
}));

export const DRAFT_MENU = [
  {
    key: "duplicate" as const,
    icon: <CopyOutlined />,
    label: "Duplicate to Drafts",
  },
  {
    key: "download" as const,
    icon: <DownloadOutlined />,
    label: "Download",
  },
  {
    key: "delete" as const,
    icon: <DeleteOutlined />,
    label: "Delete",
  },
];

export const SCHEDULE_MENU = [
  {
    key: "publish" as const,
    icon: <MdOutlineSend />,
    label: "Publish Now",
  },
  {
    key: "schedule-next" as const,
    icon: <MdOutlineRedo />,
    label: "Schedule Next",
  },
  {
    key: "schedule-last" as const,
    icon: <MdOutlineFastForward />,
    label: "Schedule Last",
  },
];

export type DropdownButtonConfig = {
  label: string;
  key: "save" | "schedule";
  labelIcon?: ReactNode;
  buttonType?: ButtonProps["type"];
  items: MenuItemType[];
  menuActionsRequirePost?: boolean;
  permissionNeeded: PermissionString;
  labelActionKey: SaveDraftActionKey | ScheduleActionKey;
  loadingKeys: string[];
};

const validatePublishAction = (
  hasConnectedSocials: boolean,
  items: MenuItemType[]
) =>
  items.map((item) =>
    hasConnectedSocials || item.key !== "publish"
      ? item
      : {
          ...item,
          label: <ConnectSocialsTooltip>{item.label}</ConnectSocialsTooltip>,
          disabled: true,
        }
  );

export const DROPDOWN_BUTTONS = ({
  hasConnectedSocials,
  customTimeMenuItem,
  post,
}: {
  hasConnectedSocials: boolean;
  customTimeMenuItem: ItemType;
  post?: Post;
}): DropdownButtonConfig[] => {
  const isScheduledPost = post?.status === "SCHEDULED";

  const getDropdownLoadingKeys = (
    items: MenuItemType[],
    labelActionKey: DropdownButtonConfig["labelActionKey"]
  ) => {
    const itemKeys = items.map(({ key }) => key as string);

    if (isScheduledPost) {
      return labelActionKey === "schedule"
        ? itemKeys.concat([labelActionKey, "save"])
        : itemKeys;
    }
    return itemKeys.concat(labelActionKey);
  };

  return [
    {
      label: isScheduledPost ? "Move to Drafts" : "Save to Drafts",
      key: "save",
      items: DRAFT_MENU,
      menuActionsRequirePost: true,
      permissionNeeded: "post_status_draft_write",
      labelActionKey: "save",
      loadingKeys: getDropdownLoadingKeys(DRAFT_MENU, "save"),
    },
    {
      label: isScheduledPost ? "Save" : "Schedule",
      key: "schedule",
      labelIcon: isScheduledPost ? undefined : <ClockCircleOutlined />,
      buttonType: "primary",
      items: validatePublishAction(hasConnectedSocials, [
        ...SCHEDULE_MENU,
        customTimeMenuItem,
      ] as MenuItemType[]),
      permissionNeeded: "post_status_schedule_write",
      labelActionKey: "schedule",
      loadingKeys: getDropdownLoadingKeys(SCHEDULE_MENU, "schedule"),
    },
  ];
};

export type SaveDraftActionKey = (typeof DRAFT_MENU)[number]["key"] | "save";
export type ScheduleActionKey =
  | (typeof SCHEDULE_MENU)[number]["key"]
  | "schedule";
export type EditorActionKey = SaveDraftActionKey | ScheduleActionKey;
