import { InstagramIntegrationType } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { IntegrationType } from "store/user/userConstants";
import { chaliceClient } from "./baseClients";
import { AuthCredentials, Integration, Paths } from "./config/chalice-api";

export const connectSocial = (body: Paths.V1CreateIntegration.RequestBody) =>
  chaliceClient.post<Paths.V1CreateIntegration.Responses.$200>(
    `/integrations`,
    body
  );

export const getToken = ({
  code,
  type,
  redirectUri,
  appName = "app_2",
}: {
  code: string;
  type: IntegrationType;
  redirectUri: string;
  appName?: string;
}) =>
  chaliceClient.get<{ credentials: AuthCredentials }>(
    `/integrations/tokens/${type}`,
    {
      params: {
        code,
        redirect_uri: redirectUri,
        ...(["pinterest", "twitter"].includes(type) && { appName }),
      },
    }
  );

export const fetchPages = ({
  type,
  token,
}: {
  type: IntegrationType;
  token: string;
}) =>
  chaliceClient.get<Paths.V1GetIntegrationPages.Responses.$200>(
    `/integrations/pages/${type}`,
    {
      params: { access_token: token },
    }
  );

export type SocialAccountDetails = {
  username: string;
  profile_id: string;
  picture_url?: string | null;
};

export type FacebookPagesWithInstagramAccount = [
  string,
  SocialAccountDetails | null,
][];

type FetchSocialAccountDetailsResponse<T extends IntegrationType> =
  T extends InstagramIntegrationType
    ? FacebookPagesWithInstagramAccount
    : SocialAccountDetails;

export const fetchSocialAccountDetails = async <T extends IntegrationType>({
  type,
  token,
}: {
  type: T;
  token: string;
}): Promise<FetchSocialAccountDetailsResponse<T>> =>
  chaliceClient.get<FetchSocialAccountDetailsResponse<T>>(
    `/integrations/account-details/${type}`,
    {
      params: { access_token: token },
    }
  );

export const fetchConnectedSocials = () => chaliceClient.get("/integrations");

export const deleteIntegration = (id: string) =>
  chaliceClient.delete(`/integrations/${id}`);

export const updateIntegration = (id: string, body: Partial<Integration>) =>
  chaliceClient.put<Paths.V1UpdateIntegration.Responses.$200>(
    `/integrations/${id}`,
    body
  );

export const fetchInstagramAccounts = ({ token }: { token: string }) =>
  chaliceClient.get("/integrations/instagram/business-accounts", {
    params: { token },
  });
