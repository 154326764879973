import { Modal, Radio, message } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { useAppDispatch, useAppSelector } from "store";
import { updatePostThunk } from "store/posts/postActions";
import {
  resetConsecutiveRejections,
  selectPostStore,
} from "store/posts/postSlice";

const PostRejectionFeedbackModal = () => {
  const dispatch = useAppDispatch();
  const {
    consecutiveRejections,
    rejected: { postList },
  } = useAppSelector(selectPostStore);

  const handleChange = (event: RadioChangeEvent) => {
    const lastRejectedPost = postList[postList.length - 1];
    dispatch(
      updatePostThunk({
        post: lastRejectedPost,
        body: {
          reject_reason: event.target.value,
        },
      })
    );
    message.success("Thanks! Your feedback helps us improve.");
    dispatch(resetConsecutiveRejections());
  };

  return (
    <Modal
      centered
      open={Boolean(consecutiveRejections) && consecutiveRejections >= 4}
      title="Why did you reject?"
      width={418}
      className="post-feedback-modal"
      footer={null}
      onCancel={() => dispatch(resetConsecutiveRejections())}
    >
      <p className="post-feedback-modal__description">
        This helps the AI improve.
      </p>
      <Radio.Group
        onChange={handleChange}
        value={null}
        style={{ marginBlock: 12 }}
      >
        <Radio.Button value="GRAPHIC_TEXT">Graphic Text</Radio.Button>
        <Radio.Button value="GRAPHIC_DESIGN">Graphic Design</Radio.Button>
        <Radio.Button value="CAPTION">Caption</Radio.Button>
      </Radio.Group>
    </Modal>
  );
};

export default PostRejectionFeedbackModal;
