import { Flex, Select } from "antd";
import ModalWithBorders from "components/ModalWithBorders";
import useAppContext from "config/AppContext/useAppContext";
import { DARK_CLASSNAME } from "config/theme/themeConfigs";

const themeOptions = [
  { label: "System Default", value: "system" },
  { label: "Light", value: "default" },
  { label: "Dark", value: DARK_CLASSNAME },
];

const Settings = ({
  isOpen,
  close,
}: {
  close: () => void;
  isOpen: boolean;
}) => {
  const {
    themeNameState: [theme, setTheme],
  } = useAppContext();

  return (
    <ModalWithBorders
      className="settings-modal"
      onCancel={close}
      title="Settings"
      footer={null}
      open={isOpen}
    >
      <Flex justify="space-between" align="center">
        <span>Theme mode</span>
        <Select
          onChange={setTheme}
          style={{ width: 250 }}
          options={themeOptions}
          value={theme}
        />
      </Flex>
    </ModalWithBorders>
  );
};

export default Settings;
