import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getPresignedUrl } from "api/postsApi";
import axios from "axios";
import cx from "classnames";
import { genericError } from "fixtures/globalConstants";
import { ImageElementType } from "polotno/model/image-model";
import { useMemo } from "react";
import {
  ACCEPTED_VIDEO_FORMATS_STR,
  validateUploadedFile,
  VIDEO_EXTENSIONS,
} from "utils/validateFileUpload";
import { addPolotnoElement } from "../../polotnoUtil";

const ImageUploader: ObserverComponent<{
  element?: ImageElementType;
  isSVG?: boolean;
  isVideo?: boolean;
}> = ({ element, isSVG, store, isVideo }) => {
  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target?.files?.[0];
      if (!file || !validateUploadedFile(file)) {
        return;
      }

      const fileExtension = file.name?.split(".").pop();

      const fileUrl = URL.createObjectURL(file);
      let newImageElement = element;

      if (!newImageElement) {
        newImageElement = addPolotnoElement({
          type:
            fileExtension && VIDEO_EXTENSIONS.includes(fileExtension)
              ? "video"
              : "image",
          store,
          src: fileUrl,
          position: {
            x: 100,
            y: 100,
          },
        });
      } else if (isSVG) {
        newImageElement.set({ maskSrc: fileUrl });
      } else {
        newImageElement.set({ src: fileUrl });
      }

      const { url } = await getPresignedUrl(file);
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      const hostedImageURL = url.split("?")[0];

      if (isSVG) {
        newImageElement.set({ maskSrc: hostedImageURL });
      } else {
        newImageElement.set({ src: hostedImageURL });
      }
    } catch (error) {
      genericError(error);
    }
  };

  const handleClick = () => {
    const labelDoc = document.getElementById(
      element ? "image-change-label" : "image-upload-label"
    );
    labelDoc?.click();
  };

  const acceptedExtensions = useMemo(
    () => (isVideo ? `image/*, ${ACCEPTED_VIDEO_FORMATS_STR}` : "image/*"),
    [isVideo]
  );

  return (
    <div className="image-uploader-container">
      <label
        id={element ? "image-change-label" : "image-upload-label"}
        htmlFor="image-editor-upload"
        className={cx("image-upload", {
          "image-upload--disabled": false,
        })}
      >
        <Button icon={<UploadOutlined />} onClick={handleClick}>
          Upload
        </Button>

        <input
          accept={acceptedExtensions}
          onChange={handleUpload}
          type="file"
          id="image-editor-upload"
        />
      </label>
    </div>
  );
};

export default ImageUploader;
