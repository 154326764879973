import { Select, Tooltip } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import { JITTER_OPTIONS, JITTER_TOOLTIP } from "./scheduleConstants";

const JitterSelect = ({
  setJitter,
  isEditing,
  jitter,
}: {
  setJitter: (jitter: number) => void;
  isEditing: boolean;
  jitter: number;
}) => {
  if (!isEditing) {
    return (
      <div className="flex">
        <span className="schedule-manager__main__block__value">
          {jitter} min {jitter === 0 && "(disabled)"}
        </span>
        <Tooltip title={JITTER_TOOLTIP}>
          <InfoCircleOutlined
            className="info-icon"
            style={{ marginBottom: 4 }}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex">
      <Select
        value={jitter}
        onChange={(value) => setJitter(value)}
        placeholder="Select jitter"
        options={JITTER_OPTIONS}
        style={{ width: 150 }}
      />
      <Tooltip title={JITTER_TOOLTIP}>
        <InfoCircleOutlined className="info-icon" />
      </Tooltip>
    </div>
  );
};

export default JitterSelect;
