import ImproveYourContent from "assets/improveYourPostsImages/improve-your-content.svg";
import ImproveYourDesign from "assets/improveYourPostsImages/improve-your-design.svg";
import { IconType } from "react-icons";
import {
  MdColorLens,
  MdOutlineColorLens,
  MdOutlinePerson,
  MdOutlinePhotoSizeSelectActual,
  MdOutlineStickyNote2,
  MdPerson,
  MdPhotoSizeSelectActual,
  MdStickyNote2,
} from "react-icons/md";
import { To } from "react-router-dom";

export type ImproveYourPostsCardConfig = {
  id: string;
  title: string;

  parentId?: string;
  subtitle?: string;
  path?: To;
} & Either<
  { imageUrl: string },
  { icon: { foreground: IconType; background: IconType } }
>;

export const IMPROVE_POSTS_ROOT_CARDS: ImproveYourPostsCardConfig[] = [
  {
    id: "improve-design-card" as const,
    imageUrl: ImproveYourDesign,
    title: "Design",
  },
  {
    id: "improve-content-card" as const,
    imageUrl: ImproveYourContent,
    title: "Content",
  },
];

export const IMPROVE_POSTS_CHILD_CARDS: ImproveYourPostsCardConfig[] = [
  {
    id: "improve-themes-card",
    parentId: "improve-design-card",
    icon: {
      foreground: MdOutlinePhotoSizeSelectActual,
      background: MdPhotoSizeSelectActual,
    },
    title: "Themes",
    subtitle: "Layout and graphics",
    path: "/themes",
  },
  {
    id: "improve-brand-card",
    parentId: "improve-design-card",
    icon: {
      foreground: MdOutlineColorLens,
      background: (props) => MdColorLens({ ...props, style: { top: "2px" } }),
    },
    title: "Brand",
    subtitle: "Logo and colors",
    path: "/branding",
  },
  {
    id: "improve-topics-card",
    parentId: "improve-content-card",
    icon: {
      foreground: MdOutlineStickyNote2,
      background: MdStickyNote2,
    },
    title: "Topics",
    subtitle: "Subject and focus",
    path: "/topics",
  },
  {
    id: "improve-business-card",
    parentId: "improve-content-card",
    icon: {
      foreground: MdOutlinePerson,
      background: MdPerson,
    },
    title: "Business",
    subtitle: "Voice and audience",
    path: "/business-profile",
  },
];
