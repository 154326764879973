/* eslint-disable react-refresh/only-export-components */
import { Modal, ModalProps } from "antd";
import classNames from "classnames";

/**
 * Purpose is to replace designSystem/Modal
 * Add additional props and styling (title/subtitle) if needed
 *
 * Can also set 'wireframe = true' in the theme config to add these borders,
 * but any modals with custom paddings/margins on the footer get messed up -- tech debt to
 * resolve that
 */
const ModalWithBorders = ({
  classNames: classNamesProp,
  size: sizeProp,
  width: widthProp,
  ...props
}: ModalProps & { size?: keyof typeof MODAL_WIDTHS }) => {
  const width = sizeProp ? MODAL_WIDTHS[sizeProp] : widthProp;

  return (
    <Modal
      {...props}
      width={width}
      classNames={{
        ...classNamesProp,
        header: classNames(classNamesProp?.header, "!p-4 !mb-0"),
        body: classNames(
          classNamesProp?.body,
          "border-y border-antd-colorBorder !p-4"
        ),
        footer: classNames(classNamesProp?.footer, "!p-2 !mt-0"),
        content: "!p-0",
      }}
    />
  );
};

/**
 * Use this to set the size of modals, add more properties/config as used
 */
export const MODAL_WIDTHS = {
  large: 1200,
};

export default ModalWithBorders;
