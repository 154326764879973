import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { updateUser } from "api/user";
import { useTypedFeatureValue } from "config/Growthbook/growthbookUtils";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { setUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { passwordValidators } from "utils/getPasswordErrorMessage";
import ONBOARDING_STEPPER_IMAGE_DICT from "../onboardingStepper/onboardingStepperImageDict";
import OnboardingModalTemplate from "./OnboardingModalTemplate";

/** This form is a paired down version of the signup form that only collects a subset
 of the information (to reduce friction)
 and is intended to be used in the onboarding flow.
*/
const SimpleSignupModal = ({
  iterateNext,
  prefillEmail,
}: {
  iterateNext: () => void;
  prefillEmail?: string | null;
}) => {
  type SignupForm = { email: string; password: string };

  const [form] = useForm<SignupForm>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const imageSrc = ONBOARDING_STEPPER_IMAGE_DICT.signup;

  const dispatch = useAppDispatch();

  const signupBeforeOnboardingVersion = useTypedFeatureValue(
    "signup-before-onboarding",
    "OFF"
  );

  const handleSubmit = async ({ email, password }: SignupForm) => {
    setSubmitLoading(true);
    try {
      const user = await updateUser({ email, password });
      dispatch(setUserInfo({ user }));
      trackEvent("sign_up");
      iterateNext();
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    signupBeforeOnboardingVersion === "OFF" && iterateNext();
  }, [signupBeforeOnboardingVersion]);

  return signupBeforeOnboardingVersion === "OFF" ? null : (
    <OnboardingModalTemplate
      imageSrc={imageSrc}
      open
      hideTransitions
      header="Claim your posts"
      subHeader="Ready to boost your social media growth? Save your profile to an account so that you can come back at any time."
      footer={
        <Button
          className="w-full"
          type="primary"
          form="signupForm"
          htmlType="submit"
          loading={submitLoading}
        >
          Continue
        </Button>
      }
    >
      <Form
        id="signupForm"
        className="mt-8"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={submitLoading}
        initialValues={{ email: prefillEmail }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "An email is required" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        {signupBeforeOnboardingVersion === "V2" && (
          <>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "A password is required" },
                ...passwordValidators,
              ]}
            >
              <Input.Password id="password" placeholder="Enter your password" />
            </Form.Item>
          </>
        )}
      </Form>
    </OnboardingModalTemplate>
  );
};

export default SimpleSignupModal;
