import { message } from "antd";
import { addStockImage } from "api/media";
import pluralize from "pluralize";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  addImageToLibrary,
  fetchImagesInLibrary,
} from "store/imageLibrary/imageLibraryActions";
import { LibraryImage } from "store/imageLibrary/imageLibraryConstants";
import { updateLibraryImages } from "store/imageLibrary/imageLibrarySlice";
import { fetchSearchTerms } from "store/user/userActions";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { v4 as uuidv4 } from "uuid";
import {
  ACCEPTED_FILE_TYPES,
  IMAGE_TOO_BIG_MESSAGE,
  IMAGES_LIMIT,
  INVALID_FILE_TYPE_MESSAGE,
  MAX_FILE_SIZE,
} from "./imageLibraryFixtures";

const validateFiles = (
  files: File[]
): { validFiles: File[]; errorMessage: string } => {
  const errors = { type: 0, size: 0 };

  const validFiles = files.filter(({ name: fileName, size }) => {
    if (
      !ACCEPTED_FILE_TYPES.includes(
        fileName.toLowerCase().substring(fileName.lastIndexOf("."))
      )
    ) {
      errors.type += 1;
      return false;
    }

    if (size > MAX_FILE_SIZE) {
      errors.size += 1;
      return false;
    }

    return true;
  });

  return {
    validFiles,
    errorMessage: (errors.type ? [INVALID_FILE_TYPE_MESSAGE(errors.type)] : [])
      .concat(errors.size ? IMAGE_TOO_BIG_MESSAGE : [])
      .join(" "),
  };
};

export const allowUploadCount = (
  imagesCount: number,
  limit: number,
  newDataCount: number
): number | true => {
  if (imagesCount >= limit) {
    message.error({
      content: `The library has has the maximum number of images. No more images can be added.`,
      duration: 10,
    });

    return 0;
  } else if (imagesCount + newDataCount > limit) {
    const amountToAdd = limit - imagesCount;
    message.info({
      content: `The library has ${imagesCount} images and the limit is ${limit}. Only the first ${pluralize("image", amountToAdd, amountToAdd > 1)} will be added.`,
      duration: 10,
    });

    return amountToAdd;
  }

  return true;
};

const useImageLibrary = ({
  limit = IMAGES_LIMIT,
  preventEventTracking,
}: { limit?: number; preventEventTracking?: boolean } = {}) => {
  const { isMediaLibraryFetched, isLoadingImages, imagesInLibrary } =
    useAppSelector((state) => state.imageLibrary);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoadingImages && !isMediaLibraryFetched) {
      dispatch(fetchSearchTerms());
      dispatch(fetchImagesInLibrary());
    }
  }, [isMediaLibraryFetched, isLoadingImages]);

  const addImagesWrapper: PromiseFunctionWrapper<
    null,
    (LibraryImage | File)[],
    never[]
  > = (fn) => async (args) => {
    const uploadLength = allowUploadCount(
      imagesInLibrary.length,
      limit,
      args.length
    );
    if (!uploadLength) {
      return [];
    }

    try {
      const uploadData =
        typeof uploadLength === "number" ? args.slice(0, uploadLength) : args;

      const result = await fn(uploadData);

      !preventEventTracking && trackEvent("added_images");
      return result;
    } catch (error) {
      message.error(
        "We're having difficulty loading your image library right now, please try again or contact support if this error persists."
      );
      dispatch(fetchImagesInLibrary());
    }

    return [];
  };

  const handleAddStock = async (selectedImages: LibraryImage[]) => {
    dispatch(
      updateLibraryImages(
        selectedImages.map((image) => ({ ...image, isUploading: true }))
      )
    );
    await Promise.all(selectedImages.map(({ id }) => addStockImage(id)));
    await dispatch(fetchImagesInLibrary());
    return selectedImages;
  };

  const handleImageUpload = async (files: File[]) => {
    const uploadImage = async (localId: string, file: File) => {
      const data = new FormData();
      data.append("file", file);
      data.append("filename", file.name);
      data.append("attribution_instructions", "{}");

      try {
        await dispatch(addImageToLibrary({ localId, data }));
      } catch {
        failedUploads += 1;
      }
    };

    const { validFiles, errorMessage } = validateFiles(files);
    errorMessage && message.error(errorMessage);
    if (!validFiles.length) {
      return [];
    }

    let failedUploads = 0;
    await Promise.all(
      validFiles.reduce((prev, file) => {
        const tempImage = {
          id: uuidv4(),
          file: {
            url: URL.createObjectURL(file),
            original_file_name: file.name,
          },
          isUploading: true,
        };

        dispatch(updateLibraryImages(tempImage));
        prev.push(uploadImage(tempImage.id, file));

        return prev;
      }, [] as Promise<void>[])
    );

    failedUploads &&
      message.error(
        `${pluralize("image", failedUploads, true)} failed to upload.`
      );

    return validFiles;
  };

  return {
    handleImageUpload: addImagesWrapper(handleImageUpload, null),
    handleAddStock: addImagesWrapper(handleAddStock, null),
  };
};

export default useImageLibrary;
