import { Permissions } from "api/config/chalice-api";
import { GetPostsResponse } from "api/postsApi";
import _ from "lodash";
import { Post, PostScreen, PostScreenInfo, PostStatus } from "./postConstants";

const DATE_SORT_KEY: Record<
  PostStatus,
  Extract<
    keyof Post,
    | "created_at"
    | "liked_at"
    | "scheduled_at"
    | "published_at"
    | "finished_at"
    | "rejected_at"
  >
> = {
  NEW: "finished_at",
  LIKED: "liked_at",
  SCHEDULED: "scheduled_at",
  PUBLISHED: "published_at",
  REJECTED: "rejected_at",
};

/** Sort posts based on their status. Both posts should have the same status. */
export const sortPostsByDate = (...[{ status, ...postA }, postB]: Post[]) => {
  const dateKey = DATE_SORT_KEY[status];
  const dateA = postA[dateKey] && new Date(postA[dateKey]);
  const dateB = postB[dateKey] && new Date(postB[dateKey]);

  const [firstDate, secondDate] =
    status === "PUBLISHED" ? [dateB, dateA] : [dateA, dateB];

  return !firstDate
    ? -1
    : !secondDate
      ? 1
      : new Date(firstDate).getTime() - new Date(secondDate).getTime();
};

export const sortScheduledPosts = (...[postA, postB]: Post<"SCHEDULED">[]) => {
  if (postA.schedule_queue_id && postA.queue_ordering_number) {
    return postA.queue_ordering_number - (postB.queue_ordering_number ?? 0);
  } else if (postA.adhoc_publish_minute_timestamp) {
    return (
      postA.adhoc_publish_minute_timestamp -
      (postB.adhoc_publish_minute_timestamp ?? 0)
    );
  }
  return 0;
};

export const reduceFetchPosts = ({
  postScreen,
  isGenerating,
  postResponse: {
    posts,
    publish_at,
    next_url,
    posts_in_status_count,
    loading_posts_count,
    limitByPermissions,
  },
}: {
  postScreen: PostScreen;
  isGenerating: boolean | undefined;
  postResponse: GetPostsResponse & { limitByPermissions?: Permissions };
}) => {
  const postScreenInfo: PostScreenInfo = {
    isInitialized: true,
    isLoading: false,
    count: posts_in_status_count,
    postList: [...posts].sort(sortPostsByDate),
    nextPageUrl: next_url,
  };

  if (postScreen === "scheduled") {
    return {
      ...postScreenInfo,
      publishAt: publish_at,
    };
  } else if (postScreen === "generated") {
    return {
      ...postScreenInfo,
      isGenerating,
      loadingCount: loading_posts_count,
    };
  } else if (postScreen === "draft" && limitByPermissions) {
    const canViewPostsWithReviewStatus =
      limitByPermissions.post_approval_status_approve_write ||
      limitByPermissions.post_approval_status_reject_write;

    if (
      !canViewPostsWithReviewStatus ||
      !limitByPermissions.post_approval_status_ready_for_review_write
    ) {
      const draftPosts = postScreenInfo.postList.filter((post) =>
        post.review_status === null
          ? limitByPermissions.post_approval_status_ready_for_review_write
          : canViewPostsWithReviewStatus
      );

      return {
        ...postScreenInfo,
        count: draftPosts.length,
        postList: draftPosts,
      };
    }
  }

  return postScreenInfo;
};

export const consolidatePostList = (
  currentList: Post[],
  newList: Post[],
  strategy: "concat" | "merge"
): Post[] => {
  const allPosts = currentList.concat(newList);

  return strategy === "concat"
    ? allPosts
    : _.uniqBy(allPosts, (post) => post.id).sort(sortPostsByDate);
};
