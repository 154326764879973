import { VOID_FUNCTION } from "fixtures/globalConstants";
import { StateParam } from "fixtures/socialAuthConfig";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-use";
import { useAppDispatch } from "store";
import { setCurrentBusiness } from "store/user/userSlice";
import { useQueryParam } from "use-query-params";
import { GLOBAL_MODAL_DICT, ValidGlobalModalData } from "./globalModalConfig";

const EMPTY_GLOBAL_MODAL = { modalName: null, modalData: {} };

export type GlobalModalContextType = {
  globalModal: ValidGlobalModalData | typeof EMPTY_GLOBAL_MODAL;
  openGlobalModal: (openModal: ValidGlobalModalData) => void;
  closeGlobalModal: () => void;
};

export const GlobalModalContext = createContext<GlobalModalContextType>({
  globalModal: EMPTY_GLOBAL_MODAL,
  openGlobalModal: VOID_FUNCTION,
  closeGlobalModal: VOID_FUNCTION,
});

const GlobalModalProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [integrationStateParams] = useQueryParam("state", StateParam);

  const [globalModal, setGlobalModal] =
    useState<GlobalModalContextType["globalModal"]>(EMPTY_GLOBAL_MODAL);

  const closeGlobalModal = () => setGlobalModal(EMPTY_GLOBAL_MODAL);

  useEffect(() => {
    if (
      integrationStateParams?.integrationType &&
      pathname !== INTEGRATIONS_ROUTE
    ) {
      dispatch(setCurrentBusiness(localStorage.currentBusiness));
      setGlobalModal({
        modalName: "create-business",
        modalData: { openConnectSocials: true },
      });
    }
  }, [integrationStateParams]);

  return (
    <GlobalModalContext.Provider
      value={{
        closeGlobalModal: () => setGlobalModal(EMPTY_GLOBAL_MODAL),
        openGlobalModal: setGlobalModal,
        globalModal,
      }}
    >
      {children}
      {Object.entries(GLOBAL_MODAL_DICT).map(
        ([modalName, Modal]) =>
          modalName === globalModal.modalName && (
            <Modal
              key={modalName}
              isOpen
              closeModal={closeGlobalModal}
              {...globalModal.modalData}
            />
          )
      )}
    </GlobalModalContext.Provider>
  );
};

export default GlobalModalProvider;
