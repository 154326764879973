import { PostCommentData } from "store/posts/postConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { createOpenApiInstance } from "./baseClients";

const commentInstance = createOpenApiInstance("CHALICE_API", {
  baseUrlSuffix: "/api/v1/posts",
});

export const postComment = async ({
  postId,
  commentBody,
}: {
  postId: string;
  commentBody: string;
}) => {
  trackEvent("added_comment");
  const client = await commentInstance;
  return client.post(`/${postId}/comment`, {
    body: commentBody,
  });
};

export const updateComment = ({
  id,
  body,
}: Pick<PostCommentData, "id" | "body">) =>
  commentInstance.then((client) => client.put(`/comment/${id}`, { body }));

export const resolveComment = ({
  id,
  resolved,
}: Pick<PostCommentData, "id" | "resolved">) =>
  commentInstance.then((client) => client.put(`/comment/${id}`, { resolved }));

export const deleteComment = ({ id }: PostCommentData) =>
  commentInstance.then((client) => client.delete(`/comment/${id}`));
