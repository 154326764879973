import dayjs, { Dayjs } from "dayjs";
import { SELECTED_INTEGRATIONS } from "fixtures/localStorageKeys";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import AnalyticsEmptyState from "./components/AnalyticsEmptyState";
import BusinessHeader from "./components/BusinessHeader";
import FilterBar from "./components/FilterBar";
import IntegrationStats from "./components/IntegrationStats";
import {
  ALL_TIME_PERIOD_OPTION,
  DateRange,
  DEFAULT_PERIOD_OPTION,
  PeriodOption,
} from "./postAnalyticsFixtures";
import { PostAnalyticsContext } from "./usePostAnalytics";

const getDateRangeFromPeriod = ({
  defaultEnd,
  subtractAmount,
  subtractUnit,
}: { defaultEnd?: Dayjs } & Required<
  Pick<PeriodOption, "subtractAmount" | "subtractUnit">
>) => {
  const endingDate = defaultEnd ?? dayjs();
  return {
    start: endingDate.startOf("day").subtract(subtractAmount, subtractUnit),
    end: endingDate.endOf("day"),
  };
};

const PostAnalyticsProvider = () => {
  const [period, setPeriod] = useState<PeriodOption>(DEFAULT_PERIOD_OPTION);
  const [dateRange, setDateRange] = useState<
    Record<"current" | "previous", DateRange | undefined>
  >({ current: undefined, previous: undefined });
  const [integrationIds, setIntegrationIds, removeIntegrationIds] =
    useLocalStorage<string[]>(SELECTED_INTEGRATIONS, []);

  const [allTimeRange, setAllTimeRange] = useState<DateRange>();

  useEffect(() => {
    const { subtractAmount, subtractUnit } = period;
    if (subtractUnit && subtractAmount) {
      const currentDateRange = getDateRangeFromPeriod({
        subtractUnit,
        subtractAmount,
      });
      const previousDateRange = getDateRangeFromPeriod({
        defaultEnd: currentDateRange.start,
        subtractUnit,
        subtractAmount,
      });

      setDateRange({ current: currentDateRange, previous: previousDateRange });
    } else if (period.value === ALL_TIME_PERIOD_OPTION.value) {
      setDateRange({ current: undefined, previous: undefined });
    }
  }, [period]);

  return (
    <PostAnalyticsContext.Provider
      value={{
        integrationIdsState: [
          integrationIds,
          setIntegrationIds,
          removeIntegrationIds,
        ],
        dateRangeState: [dateRange, setDateRange],
        periodState: [period, setPeriod],
        allTimeRangeState: [allTimeRange, setAllTimeRange],
      }}
    >
      <div className="pt-16 space-y-9 pb-10 px-6 md:pl-8 md:pr-16">
        <BusinessHeader />
        <FilterBar />
        {integrationIds?.length === 0 ? (
          <AnalyticsEmptyState />
        ) : (
          <>
            {integrationIds?.map((integrationId) => (
              <IntegrationStats
                key={integrationId}
                integrationId={integrationId}
              />
            ))}
          </>
        )}
      </div>
    </PostAnalyticsContext.Provider>
  );
};

export default PostAnalyticsProvider;
