import { Alert, Button } from "antd";
import AutoFindStockModal from "pages/ImageLibrary/AutoFindStockModal/AutoFindStockModal";
import useImageLibrary from "pages/ImageLibrary/useImageLibrary";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { POST_SCREEN_TAB } from "pages/Posts/utils/useGetAllowedScreens";
import { useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";

const LowImageWarning = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { preferences } = useAppSelector(currentBusinessGetter);

  const { isMediaLibraryFetched, imagesInLibrary, isLoadingImages } =
    useAppSelector((state) => state.imageLibrary);

  useImageLibrary();

  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);

  const isLibraryEmpty = imagesInLibrary?.length === 0;

  const isLibraryOnlyImageSource =
    preferences?.image_sources.length === 1 &&
    preferences?.image_sources.includes("media_library");

  const message = useMemo(() => {
    return isLibraryEmpty
      ? "Image library is empty. Add images in order to generate new posts."
      : "Library is low on images, this may result in poor image variety.";
  }, [isLibraryEmpty]);

  const showImageLibraryWarning = useMemo(
    () =>
      isMediaLibraryFetched &&
      imagesInLibrary?.length < 25 &&
      pathname === POSTS_PAGE_ROUTE &&
      searchParams.get(POST_SCREEN_TAB) === "generated" &&
      isLibraryOnlyImageSource,
    [
      searchParams,
      imagesInLibrary,
      pathname,
      isLibraryOnlyImageSource,
      isMediaLibraryFetched,
    ]
  );

  if (!showImageLibraryWarning) {
    return null;
  }

  return (
    <>
      {!isLoadingImages && (
        <Alert
          type={isLibraryEmpty ? "error" : "warning"}
          className="fixed top-[75px] flex gap-8 max-lg:hidden z-antd-popup"
          message={message}
          action={
            <Button size="small" onClick={() => setIsAddImageModalOpen(true)}>
              Add Images
            </Button>
          }
        />
      )}
      <AutoFindStockModal
        close={() => setIsAddImageModalOpen(false)}
        isOpen={isAddImageModalOpen}
        isGlobal
      />
    </>
  );
};

export default LowImageWarning;
