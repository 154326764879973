import Link from "antd/es/typography/Link";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

export const EMPTY_INTEGRATION_DESCRIPTION: Record<string, ReactNode> = {
  pinterest: (
    <>
      We couldn't find any Pinterest boards associated with your account. To
      connect your Pinterest, please create a board first. Need help creating a
      board?{" "}
      <RouterLink
        to="https://help.pinterest.com/en/article/create-a-board"
        target="_blank"
      >
        <Link className="hover:!underline">
          Check out the guide on how to create a Pinterest board.
        </Link>
      </RouterLink>
    </>
  ),
  linkedIn:
    "We couldn't find any LinkedIn Company pages associated with your account. To connect your LinkedIn, please create a page first.",
  facebook: (
    <div className="space-y-4">
      <p>
        We didn't find any Facebook pages for you. Did you select opt into all
        current and future at every step? (This is the most common reason you
        see this)
      </p>
      <img
        src="https://marky-image-posts.s3.us-east-2.amazonaws.com/fb-ss.jpeg"
        className="screenshot"
      />
    </div>
  ),
  googleBusiness:
    "We couldn't find any Google Business pages associated with your account. To connect your business, please create a Google Business profile first.",
};
