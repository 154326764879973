import { getActiveCoupons } from "fixtures/coupons";
import Cookies from "js-cookie";
import { REFERRAL_PARAM } from "pages/ReferralReroute";
import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import {
  BannerConfig,
  DEFAULT_BANNER,
  REFERRAL_BANNER,
} from "./upgradePlanConstants";

const useGetUpgradeInfo = () => {
  const { subscription } = useAppSelector(selectUserInfo);

  const upgradeInfo: BannerConfig | null = useMemo(() => {
    if (subscription?.plan !== "free") {
      return null;
    }

    const activeCoupons = getActiveCoupons({ currentPlan: subscription?.plan });
    if (activeCoupons.length) {
      return { ...activeCoupons[0], bannerType: "coupon" };
    } else if (Cookies.get(REFERRAL_PARAM)) {
      return REFERRAL_BANNER;
    } else {
      return DEFAULT_BANNER;
    }
  }, [getActiveCoupons, subscription?.plan]);

  return upgradeInfo;
};

export default useGetUpgradeInfo;
