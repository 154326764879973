import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { BUSINESS_FORM_SECTIONS } from "pages/NewBusinessOnboarding/businessFormConstants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBusinessUpdated } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import BusinessCard from "./BusinessCard/BusinessCard";
import BusinessActions from "./BusinessProfileActions";

const BusinessProfilePage = () => {
  const dispatch = useDispatch();
  const { hasPermission } = useUserPermissions();

  useEffect(() => {
    return () => {
      dispatch(
        setBusinessUpdated({
          content: localStorage.areBusinessDetailsUpdated,
        })
      );
      localStorage.areBusinessDetailsUpdated &&
        trackEvent("updated_business_content", { page: "profile" });
    };
  }, []);

  return (
    <div className="flex flex-col gap-6">
      {BUSINESS_FORM_SECTIONS.map((section) =>
        section.hideFromBusinessPage ? null : (
          <BusinessCard key={section.key} section={section} />
        )
      )}
      {hasPermission("business_details_write") && <BusinessActions />}
    </div>
  );
};

export default BusinessProfilePage;
