import { UploadOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import { ACCEPTED_FILE_TYPES } from "pages/ImageLibrary/imageLibraryFixtures";
import { ChangeEvent, InputHTMLAttributes, ReactNode, useState } from "react";

type FileUploadButtonProps = {
  isSmall?: boolean;
  children?: ReactNode;
  id: string;
  onChange: (files: File[]) => Promise<object | void> | null | void;
  buttonProps?: ButtonProps;
  acceptedFileTypes?: string[];
  labelText?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "id" | "onChange">;

const FileUploadButton = ({
  isSmall,
  children,
  id,
  onChange,
  acceptedFileTypes = ACCEPTED_FILE_TYPES,
  labelText,
  className,
  ...inputProps
}: FileUploadButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files?.length) {
      setLoading(true);
      await onChange(Array.from(target.files));
      setLoading(false);
      target.value = "";
    }
  };

  return (
    <>
      <input
        id={id}
        className="hidden"
        accept={acceptedFileTypes.join(", ")}
        onChange={handleChange}
        type="file"
        multiple
        {...inputProps}
      />

      {children ? (
        <label htmlFor={id} className={`cursor-pointer ${className}`}>
          {children}
        </label>
      ) : (
        <Button
          className={classNames({ "h-8": isSmall })}
          loading={loading}
          icon={<UploadOutlined />}
          onClick={(e) =>
            (e.target as HTMLButtonElement)
              .getElementsByTagName("label")[0]
              ?.click()
          }
        >
          <label htmlFor={id} className="cursor-pointer">
            {labelText ?? "Upload"}
          </label>
        </Button>
      )}
    </>
  );
};

export default FileUploadButton;
