import { Rule } from "antd/lib/form";

interface PasswordTest {
  message: string;
  test: (password: string) => boolean;
}

const passwordTests: PasswordTest[] = [
  {
    message: "At least one lowercase letter",
    test: (password: string) => /[a-z]/.test(password),
  },
  {
    message: "At least one uppercase letter",
    test: (password: string) => /[A-Z]/.test(password),
  },
  {
    message: "At least one number",
    test: (password: string) => /\d/.test(password),
  },
  {
    message: "Be at least 8 characters long",
    test: (password: string) => password.length >= 8,
  },
];

const getPasswordErrorMessage = (password: string): Record<string, boolean> =>
  Object.fromEntries(
    passwordTests.map(({ message, test }) => [message, test(password)])
  );

/**
 * This can be used in antd form fields
 */
export const passwordValidators = passwordTests.map(({ message, test }) => ({
  validator: (_: Rule, value: string) =>
    test(value) ? Promise.resolve() : Promise.reject(message),
}));

export default getPasswordErrorMessage;
