import { Button, message } from "antd";
import { ReviewStatus } from "api/config/chalice-api";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { isNull } from "lodash";
import { useState } from "react";
import { useAppSelector } from "store";
import { Post } from "store/posts/postConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";

type PostReviewActionProps = {
  post: Post;
  changeReviewStatus: (newStatus: ReviewStatus | null) => Promise<unknown>;
  disabled?: boolean;
};

const PostReviewActions = ({
  post,
  changeReviewStatus,
  disabled,
}: PostReviewActionProps) => {
  const { isAgencyOwner } = useAppSelector((state) => state.user);
  const { hasPermission, hasSomePermissions } = useUserPermissions();

  const canMarkPostReadyForReview =
    isAgencyOwner ||
    hasPermission("post_approval_status_ready_for_review_write", false);

  const [loadingReviewStatusButton, setLoadingReviewStatusButton] = useState<
    ReviewStatus | null | false
  >(false);
  const isLoading = Boolean(loadingReviewStatusButton);

  const areChangesRequested = post.review_status === "CHANGES_REQUESTED";

  const disableButtons = isLoading || disabled;

  const handleReviewStatusChange = async (
    reviewStatus: ReviewStatus | null,
    showMessage = true
  ) => {
    const isGeneratedPost = post.status === "NEW";
    setLoadingReviewStatusButton(reviewStatus);

    try {
      await changeReviewStatus(reviewStatus);

      showMessage &&
        message.success(
          isGeneratedPost
            ? "Post moved to drafts and marked as ready for review."
            : reviewStatus === "READY_FOR_REVIEW"
              ? "Post marked as ready for review"
              : reviewStatus === "APPROVED"
                ? "Post marked as approved."
                : "Requested changes successfully."
        );
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong. Couldn't mark the post ready for review. Please try again."
      );
    }
    setLoadingReviewStatusButton(false);

    if (reviewStatus === "READY_FOR_REVIEW") {
      trackEvent("marked_post_ready_for_review");
    }
  };

  return (
    <>
      {post.review_status &&
        hasSomePermissions(
          [
            "post_approval_status_reject_write",
            "post_approval_status_approve_write",
          ],
          false
        ) && (
          <div className="flex gap-4">
            {hasPermission("post_approval_status_reject_write", false) && (
              <Button
                onClick={() => handleReviewStatusChange("CHANGES_REQUESTED")}
                loading={loadingReviewStatusButton === "CHANGES_REQUESTED"}
                disabled={isLoading}
                size="small"
              >
                Request Changes
              </Button>
            )}
            {hasPermission("post_approval_status_approve_write", false) && (
              <Button
                onClick={() => handleReviewStatusChange("APPROVED")}
                loading={loadingReviewStatusButton === "APPROVED"}
                disabled={isLoading}
                size="small"
                type="primary"
              >
                Approve
              </Button>
            )}
          </div>
        )}

      {canMarkPostReadyForReview && ["NEW", "LIKED"].includes(post.status) && (
        <div className="flex gap-2">
          {(!post.review_status || areChangesRequested) && (
            <Button
              onClick={() => handleReviewStatusChange("READY_FOR_REVIEW")}
              loading={loadingReviewStatusButton === "READY_FOR_REVIEW"}
              disabled={disableButtons}
              className="ant-btn-small-fix"
            >
              {`${areChangesRequested ? "Change to" : "Mark"} Ready for Review`}
            </Button>
          )}
          {post.review_status && (
            <Button
              onClick={() => handleReviewStatusChange(null, false)}
              loading={isNull(loadingReviewStatusButton)}
              disabled={disableButtons}
              className="ant-btn-small-fix"
            >
              Remove Status
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default PostReviewActions;
