import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { BusinessFormSection } from "pages/NewBusinessOnboarding/businessFormConstants";
import { useRef } from "react";
import { useClickAway } from "react-use";
import BusinessCardBody from "./BusinessCardBody";
import useBusinessCard from "./useBusinessCard";

const BusinessCard = ({ section }: { section: BusinessFormSection }) => {
  const { realm } = useAppContext();
  const { hasPermission } = useUserPermissions();
  const canEditBusiness = hasPermission("business_details_write");

  const business = useBusinessCard();
  const {
    handleOutsideClick,
    isLoading,
    isEditing,

    handleCancel,
    setIsEditing,
    handleSave,
  } = business;

  const cardRef = useRef<HTMLDivElement>(null);
  useClickAway(cardRef, handleOutsideClick);

  return (
    <div className="card" ref={cardRef}>
      <header>
        <h2>{section.header}</h2>
        {/* TODO: Remove the condition once the copy is updated for clients */}
        {realm.isMarky && <span>{section.subHeader}</span>}
      </header>

      {section.items && (
        <div onClick={() => setIsEditing(canEditBusiness)}>
          <BusinessCardBody items={section.items} {...business} />
        </div>
      )}

      {canEditBusiness && (
        <footer>
          {isEditing ? (
            <>
              <Button onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => handleSave()}
                loading={isLoading}
              >
                {isLoading ? "Saving" : "Save"}
              </Button>
            </>
          ) : (
            <Button
              icon={<EditOutlined />}
              onClick={() => setIsEditing((prevValue) => !prevValue)}
              disabled={isLoading}
            >
              Edit
            </Button>
          )}
        </footer>
      )}
    </div>
  );
};

export default BusinessCard;
