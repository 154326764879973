import { message } from "antd";
import TypographyLink from "antd/es/typography/Link";
import classNames from "classnames";
import dayjs from "dayjs";
import PostEditor, { PostEditorType } from "pages/Posts/PostEditor/PostEditor";
import useScheduleCustomTime from "pages/Posts/ScheduleCustomTime/useScheduleCustomTime";
import { useEffect, useState } from "react";
import { Event, EventProps } from "react-big-calendar";
import { MdSchedule } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Post } from "store/posts/postConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { POSTS_PAGE_ROUTE } from "../PostsPage";
import { POST_SCREEN_TAB } from "../utils/useGetAllowedScreens";
import PublishDetailsPopover from "./PublishDetailsPopover";

export type PostCalendarEvent = Event & { post: Post };

const DRAG_MESSAGE_KEY = "drag-message";

const MonthCard = ({ event: { post } }: EventProps<PostCalendarEvent>) => {
  const navigate = useNavigate();
  const { setCustomTimeModalOpen, CustomTimeModal } = useScheduleCustomTime();

  const [showEditor, setShowEditor] = useState<PostEditorType | null>(null);

  const isPublished = Boolean(post.published_at);

  useEffect(() => {
    return () => setShowEditor(null);
  }, [post.id]);

  const trackDragAttempt = (e: React.DragEvent) => {
    e.preventDefault();
    trackEvent("attempted_calendar_event_drag");

    message.info({
      key: DRAG_MESSAGE_KEY,
      content: (
        <div className="ml-2">
          Posts aren't draggable yet, but this feature is on our roadmap. <br />{" "}
          In the meantime, you can reorder posts using the{" "}
          <TypographyLink
            className="!text-antd-colorInfo hover:!text-antd-colorInfoHover"
            onClick={() => {
              navigate({
                pathname: POSTS_PAGE_ROUTE,
                search: `${POST_SCREEN_TAB}=scheduled`,
              });
              message.destroy(DRAG_MESSAGE_KEY);
            }}
          >
            scheduled posts page
          </TypographyLink>
          .
        </div>
      ),
    });
  };

  return (
    <div
      key={post.id}
      onClick={(e) => e.stopPropagation()}
      draggable
      onDragStart={trackDragAttempt}
    >
      <div
        className={classNames(
          "flex justify-between h-10 items-center rounded-lg border border-solid border-antd-colorBorderSecondary shadow-sm text-antd-colorTextSecondary dark:text-antd-colorTextBase [&_*:not(svg)]:text-xs",
          isPublished
            ? "bg-antd-colorBgLayout dark:bg-antd-colorBgContainer [&>*]:opacity-75"
            : "bg-antd-colorBgContainer dark:bg-antd-colorBgLayout"
        )}
        onClick={() => setShowEditor("mini")}
      >
        <div className="h-full flex gap-2 truncate items-center px-3">
          {isPublished && (
            <PublishDetailsPopover post={post as Post<"PUBLISHED">} />
          )}

          <div className="truncate">{post.caption}</div>
          {!isPublished && post.adhoc_publish_minute_timestamp && (
            <MdSchedule
              className="min-w-4 text-antd-colorIcon cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setCustomTimeModalOpen(true);
              }}
            />
          )}
        </div>

        <img
          src={post.thumbnail_url || post.media_urls?.[0]}
          className="min-w-0 sm:min-w-10 h-full rounded-r-lg"
          alt=""
        />
      </div>
      <PostEditor
        // TODO: refetchAfterSchedule is ultimately passed down to useUpdatePostActions in MiniEditorModal --
        // find a better way to declare this, instead of passing down through children (or always refetch?)
        refetchAfterSchedule
        miniByDefault
        {...{
          post,
          showEditor,
          setShowEditor,
        }}
      />
      <CustomTimeModal
        refetchAfterSchedule
        post={post}
        publishAt={dayjs(post.will_publish_at)}
      />
    </div>
  );
};

export default MonthCard;
