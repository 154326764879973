import { Color } from "antd/es/color-picker";
import ColorPickerWithPresets from "components/Common/ColorPickerWithPresets";
import ColorPaletteSelect from "../ColorPaletteSelect/ColorPaletteSelect";

const PageToolbar: ObserverComponent = ({ store }) => (
  <div className="flex gap-2 items-center mr-2">
    <ColorPaletteSelect store={store} />
    {store.activePage && (
      <ColorPickerWithPresets
        showText={() => "Background Color"}
        value={store.activePage.background}
        onChange={(value: Color) => {
          store.activePage.set({
            background: value.toHexString(),
          });
        }}
      />
    )}
  </div>
);

export default PageToolbar;
