import { Switch, Tooltip } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";
import { PublishSettingItemType, PublishSettingType } from "./settingConstants";

const PublishSettingItem = ({
  setPublishSettings,
  disabledStateTooltip,
  disabled,
  checked,
  tooltip,
  label,
  field,
}: {
  setPublishSettings: React.Dispatch<React.SetStateAction<PublishSettingType>>;
} & PublishSettingItemType) => {
  const handleChange = (value: boolean) => {
    setPublishSettings((prev) => ({
      ...prev,
      [field]: value,
      ...(field === "directPublish" && !value && { autoAddMusic: false }),
    }));
  };

  return (
    <div className="flex items-center justify-between">
      <div className="space-x-1.5">
        <label htmlFor="">{label}</label>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined className="text-antd-colorIcon" />
          </Tooltip>
        )}
      </div>
      <Tooltip title={disabledStateTooltip}>
        <Switch checked={checked} onChange={handleChange} disabled={disabled} />
      </Tooltip>
    </div>
  );
};

export default PublishSettingItem;
