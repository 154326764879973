import { message } from "antd";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isCancel,
} from "axios";
import env from "../../config/envVars";

type ErrorResponseData = {
  machine_error?: string;
  Message?: string;
  Code?: string;
};

type AxiosErrorWithResponse = AxiosError & {
  response?: AxiosResponse<ErrorResponseData>;
};

const chaliceAxios: AxiosInstance = axios.create({
  baseURL: `${env.CHALICE_API}/api/v1`,
});

// TODO: What's the point of this, lines 46-49 sets the token
export const setAuthToken = (token: string) => {
  chaliceAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthToken = () => {
  chaliceAxios.defaults.headers.common.Authorization = "";
};

chaliceAxios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;

chaliceAxios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  return config;
});

chaliceAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosErrorWithResponse) => {
    const isSearchByEmailEndpoint =
      error.request?.responseURL?.includes("search-by-email");
    if (
      isSearchByEmailEndpoint &&
      error.response?.data?.Code === "NotFoundError"
    ) {
      return Promise.reject(error);
    }
    if (error.response?.status !== 402) {
      // Axios bug: isCancel returning false modifies error type to be `never`
      // https://github.com/axios/axios/issues/5153
      !isCancel(error) &&
        message.error(
          (error as AxiosErrorWithResponse)?.response?.data?.Message ||
            "Something went wrong. Please try again later"
        );
    }

    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.machine_error !==
        "BASIC_ACCOUNT_POST_INTERACTION_LIMIT_REACHED"
    ) {
      message.error("Action not allowed.");
    }
    if (error.response && error.response.status === 504) {
      message.error("Request timed out. Please try again");
    }

    if (error.response && error.response.status === 402) {
      window.dispatchEvent(new Event("payment-required"));
    }
  }
);

export default chaliceAxios;
