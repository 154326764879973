import { Button, Input, message } from "antd";
import { forgotPassword } from "api/user";
import useAppContext from "config/AppContext/useAppContext";
import LogoLink from "config/theme/LogoLink";
import AuthWrapper from "layout/AuthWrapper";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { realm } = useAppContext();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setIsLoading(true);

      try {
        await forgotPassword(email);
        const userMessage = realm.isMarky
          ? `A password reset email has been successfully sent to the provided email address.
             Please check your inbox for further instructions.`
          : `A password reset email has been successfully sent to your agency.
             Please ask them to check their inbox for further instructions.`;
        message.success(userMessage);
        navigate("/login");
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    },
    [email]
  );

  return (
    <AuthWrapper>
      <div className="auth">
        <LogoLink linkToDomain />
        <h3>Forgot Password?</h3>
        <p>Please provide your email address to reset it</p>
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="auth__input-field">
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <Button
            type="primary"
            loading={isLoading}
            disabled={!email}
            htmlType="submit"
          >
            Reset Password
          </Button>
          {!isLoading && (
            <Link to="/login" className="auth__back-to-login">
              Back to login
            </Link>
          )}
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
