const TermsAndConditions = () => (
  <div className="privacy-policy px-20 py-40 md:px-8 md:py-12">
    <h2 className="mb-6 text-2xl md:text-xl">Terms of Service</h2>
    <p className="mb-4 text-base md:text-sm">
      Please read these Terms of Service ("Terms") carefully before using our
      application ("App"). These Terms constitute a legally binding agreement
      between you ("User," "you," or "your") and Marky ("we," "us," "our," or
      the "Company") governing your use of the App. By accessing or using the
      App, you agree to be bound by these Terms. If you do not agree with any
      part of these Terms, you should not use the App.
    </p>
    <ul className="space-y-6">
      <li>
        <h3 className="text-lg">1. Acceptance of Terms</h3>
        <p className="text-base">
          By using the App, you acknowledge that you have read, understood, and
          agree to be bound by these Terms and our Privacy Policy. If you do not
          agree with any part of these Terms or our Privacy Policy, you should
          not use the App. By using our application ("App"), you acknowledge and
          agree to these Terms and to comply with TikTok's Developer Terms,
          where applicable.
        </p>
      </li>

      <li>
        <h3 className="text-lg">2. Description of the App</h3>
        <p className="text-base">
          The App provides a platform that connects to your social media
          accounts, generates AI-generated content, and allows you to approve
          and automatically post selected content to your social media accounts.
          When connecting to TikTok, the App adheres to TikTok's API and data
          usage policies.
        </p>
      </li>

      <li>
        <h3 className="text-lg">3. User Obligations</h3>
        <ul className="pl-4 space-y-2">
          <li>
            3.1 Account Creation: In order to use the App, you must create an
            account by providing accurate and up-to-date information. You are
            responsible for maintaining the confidentiality of your account
            credentials and for all activities that occur under your account.
          </li>
          <li>
            3.2 Compliance with Laws: You agree to use the App in compliance
            with all applicable laws, regulations, and guidelines, including
            those of the social media platforms you connect to. You are solely
            responsible for any content you create, approve, and post using the
            App.
          </li>
          <li>
            3.3 Restrictions: You agree not to engage in any of the following
            prohibited activities:
            <ul className="pl-4 space-y-1">
              <li>
                (a) Attempting to gain unauthorized access to the App or another
                user's account.
              </li>
              <li>
                (b) Using the App for any illegal or unauthorized purpose.
              </li>
              <li>
                (c) Interfering with or disrupting the operation of the App.
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li>
        <h3 className="text-lg">4. Intellectual Property</h3>
        <p className="text-base">
          The App and all related materials, including but not limited to
          content, graphics, logos, and software, are the intellectual property
          of the Company or its licensors. You may not reproduce, modify,
          distribute, or create derivative works based on the App without our
          prior written consent.
        </p>
      </li>

      <li>
        <h3 className="text-lg">5. Termination</h3>
        <p className="text-base">
          We reserve the right to suspend or terminate your access to the App at
          any time without notice if you violate these Terms or engage in any
          activity that we determine to be harmful or inappropriate.
        </p>
      </li>

      <li>
        <h3 className="text-lg">6. Disclaimer of Warranties</h3>
        <p className="text-base">
          The App is provided on an "as-is" and "as available" basis without any
          warranties, express or implied. We do not guarantee that the App will
          be error-free, secure, or uninterrupted. Your use of the App is at
          your own risk.
        </p>
      </li>

      <li>
        <h3 className="text-lg">7. Limitation of Liability</h3>
        <p className="text-base">
          To the fullest extent permitted by applicable law, we disclaim any
          liability for any direct, indirect, incidental, special,
          consequential, or punitive damages arising out of or related to your
          use of the App.
        </p>
      </li>

      <li>
        <h3 className="text-lg">8. Changes to the Terms</h3>
        <p className="text-base">
          We reserve the right to modify or update these Terms at any time. Any
          changes to these Terms will be posted within the App. Your continued
          use of the App after the effective date of the revised Terms
          constitutes your acceptance of the changes.
        </p>
      </li>

      <li>
        <h3 className="text-lg">9. Contact Us</h3>
        <p className="text-base">
          If you have any questions or concerns about these Terms, please
          contact us at <a href="mailto:josiah@coad.net">josiah@coad.net</a>.
        </p>
      </li>
    </ul>

    <span className="block mt-10">Last Updated: Dec 10, 2023</span>
  </div>
);

export default TermsAndConditions;
