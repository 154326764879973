import { Button, Empty } from "antd";
import EmptyStateImage from "assets/images/analytics-empty-state.svg";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { integrationsTypeGetter } from "store/user/userSlice";
import {
  UNSUPPORTED_SOCIALS_FOR_ANALYTICS,
  UnsupportedSocialForAnalytics,
} from "../postAnalyticsFixtures";

const AnalyticsEmptyState = () => {
  const integrations = useAppSelector(integrationsTypeGetter);

  const { emptyStateBtnText, emptyStateBtnDescription } = useMemo(() => {
    const shouldShowConnectButton = integrations.every((integration) =>
      UNSUPPORTED_SOCIALS_FOR_ANALYTICS.includes(
        integration as UnsupportedSocialForAnalytics
      )
    );

    return {
      emptyStateBtnText: shouldShowConnectButton ? "Connect Socials" : null,
      emptyStateBtnDescription: `Let's get those numbers rolling! ${shouldShowConnectButton ? "Connect your social accounts to track your growth." : "Please select socials from the dropdown to see analytics."}`,
    };
  }, [integrations]);

  return (
    <Empty
      description={emptyStateBtnDescription}
      image={EmptyStateImage}
      children={
        emptyStateBtnText && (
          <Link to={INTEGRATIONS_ROUTE}>
            <Button type="primary">{emptyStateBtnText}</Button>
          </Link>
        )
      }
      className="flex flex-col justify-center [&_img]:w-[534px] [&_img]:mx-auto [&_.ant-empty-image]:h-auto"
    />
  );
};

export default AnalyticsEmptyState;
