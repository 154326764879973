import { TourStepProps } from "antd";
import { SidebarSectionKey } from "components/SidebarNav/sidebarFixtures";
import {
  ANALYTICS_ITEM_ID,
  BRANDING_MENU_ITEM_ID,
  BUSINESS_PROFILE_MENU_ITEM_ID,
  EDIT_POST_BUTTON_ID,
  POST_MENU_ITEM_ID,
  SOCIALS_MENU_ITEM_ID,
  TOPICS_MENU_ITEM_ID,
} from "fixtures/elementIDs";

const getTargetElementById = (elementId: string) => {
  if (window.innerWidth <= 900) {
    return null;
  }

  return document.getElementById(elementId)?.parentElement
    ?.parentElement as HTMLElement;
};

const getTargetElementByClass = (classSelector: string) => {
  if (window.innerWidth <= 900) {
    return null;
  }

  return document.querySelector(classSelector) as HTMLElement;
};

type TourStep = {
  sidebarSection?: SidebarSectionKey;
  scrollSidebarToEnd?: boolean;
  target: () => HTMLElement | null;
} & Omit<TourStepProps, "target">;

export const ONBOARDING_TOUR_STEPS: TourStep[] = [
  {
    title: "View your posts",
    description:
      "Marky generates posts for you, tailored to your business. Schedule, publish, trash, or save posts for later. The more you use Marky, the better it gets.",
    target: () => getTargetElementById(POST_MENU_ITEM_ID),
  },
  {
    title: "Edit posts with ease",
    description:
      "You have the ability to edit everything about a post: the images, design, text and caption.",
    cover: (
      <img
        alt="Edit post modal"
        src="https://marky-image-posts.s3.us-east-2.amazonaws.com/Screenshot+2024-03-10+at+1.08.14%E2%80%AFAM.png"
      />
    ),
    target: () => getTargetElementById(EDIT_POST_BUTTON_ID),
  },
  {
    title: "Monitor your analytics",
    description:
      "Track key performance metrics and monitor trends to help grow your socials.",
    target: () => getTargetElementById(ANALYTICS_ITEM_ID),
  },
  {
    title: "Content topics",
    description: "Choose what Marky posts about through editing your topics",
    target: () => getTargetElementById(TOPICS_MENU_ITEM_ID),
  },
  {
    title: "Business profile",
    description: "Control how Marky talks through your business profile",
    target: () => getTargetElementById(BUSINESS_PROFILE_MENU_ITEM_ID),
    sidebarSection: "business",
  },
  {
    title: "Branding customization",
    description:
      "Control how your posts look through editing your brand and themes",
    target: () => getTargetElementById(BRANDING_MENU_ITEM_ID),
    sidebarSection: "business",
  },
  {
    title: "Publishing schedule & socials",
    description:
      "Control when you publish and connect new socials in the socials tab",
    target: () => getTargetElementById(SOCIALS_MENU_ITEM_ID),
    sidebarSection: "business",
  },
  {
    title: "Talk to a real person",
    description: "Any questions? A live team member is just a message away",
    target: () => getTargetElementByClass(".cc-157aw"),
  },
];
