import { Brand, Palette } from "api/config/chalice-api";
import { genericError } from "fixtures/globalConstants";
import { random } from "lodash";
import { getBestContrastBW } from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/colorsUtils";
import { PageType } from "polotno/model/page-model";
import createStore from "polotno/model/store";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  getPostDetailsThunk,
  getPostsThunk,
  updatePostThunk,
} from "store/posts/postActions";
import { Post } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import { currentBusinessGetter } from "store/user/userSlice";

type CustomColorKey = "ON_ACCENT" | "BACKGROUND" | "ACCENT" | "TEXT";
type FontKey = "HEADER" | "BODY";

const useApplyBrandingChanges = () => {
  const dispatch = useAppDispatch();

  const {
    generated: { postList },
  } = useAppSelector(selectPostStore);
  const { brand } = useAppSelector(currentBusinessGetter);

  const updateColors = (page: PageType, palette: Palette) => {
    const colorsMapping = {
      ON_ACCENT: getBestContrastBW(palette.accent_color ?? "#000000"),
      BACKGROUND: palette.background_color,
      ACCENT: palette.accent_color,
      TEXT: palette.text_color,
    };

    const pageColor = page.custom?.colorType as CustomColorKey | undefined;
    if (pageColor && colorsMapping[pageColor]) {
      page.set({
        background: colorsMapping[pageColor],
      });
    }

    page.children.forEach((element) => {
      if (element.custom) {
        const color = colorsMapping[element.custom.colorType as CustomColorKey];
        const strokeColor =
          colorsMapping[element.custom.strokeColorType as CustomColorKey];

        if (element.type === "text" && color) {
          element.set({ fill: color });
        }

        if (element.type === "svg" && color) {
          Object.keys(element.colorsReplace.toJSON()).forEach((key) =>
            element.replaceColor(key, color)
          );
        }

        if (element.type === "figure" && color) {
          element.set({ fill: color });
        }

        if (element.type === "figure" && strokeColor) {
          element.set({ stroke: strokeColor });
        }
      }
    });
  };

  const updateImage = useCallback(
    (page: PageType, imageType = "LOGO") => {
      const avatarElements = page.children.filter(
        (element) => element?.custom?.type === imageType
      );

      const updatedImage = brand[imageType.toLowerCase() as keyof Brand];

      avatarElements.forEach((element) => {
        element.set({ src: updatedImage });
      });
    },
    [brand]
  );

  const updateFont = useCallback(
    (page: PageType) => {
      const textElements = page.children.filter(
        (element) => element.type === "text"
      );

      const fontMapping = {
        HEADER: brand.header_font,
        BODY: brand.body_font,
      };

      textElements.forEach((element) => {
        element.set({
          fontFamily:
            (element.custom?.type &&
              fontMapping[element.custom.type as FontKey]) ||
            brand.body_font,
        });
      });
    },
    [brand]
  );

  const clearLocalStorage = (onlyBusiness?: boolean) => {
    localStorage.removeItem("areBusinessDetailsUpdated");
    if (!onlyBusiness) {
      localStorage.removeItem("isFontUpdated");
      localStorage.removeItem("areColorsUpdated");
      localStorage.removeItem("isLogoUpdated");
      localStorage.removeItem("isAvatarUpdated");
      localStorage.removeItem("areThemesUpdated");
    }
  };

  const updatePost = async (post: Post) => {
    const store = createStore({
      key: import.meta.env.VITE_POLOTNO_KEY,
      showCredit: false,
    });

    const postDetails = await dispatch(getPostDetailsThunk(post.id)).unwrap();
    const palette = brand.palettes?.[random(brand.palettes.length - 1)];

    store.loadJSON(postDetails.template);
    await store.waitLoading();

    if (localStorage.isFontUpdated) {
      if (brand.header_font && brand.header_font.includes("http")) {
        store.addFont({
          fontFamily: brand.header_font,
          url: brand.header_font,
        });
      }
      if (brand.body_font && brand.body_font.includes("http")) {
        store.addFont({
          fontFamily: brand.body_font,
          url: brand.body_font,
        });
      }
    }

    const updatedPages = store.pages.map((page) => {
      if (localStorage.isAvatarUpdated) {
        updateImage(page, "AVATAR");
      }
      if (localStorage.isLogoUpdated) {
        updateImage(page, "LOGO");
      }
      if (localStorage.areColorsUpdated && palette) {
        updateColors(page, palette);
      }
      if (localStorage.isFontUpdated) {
        updateFont(page);
      }
      return page;
    });

    await dispatch(
      updatePostThunk({
        post,
        body: {
          should_render: true,
          template: {
            ...postDetails.template,
            fonts: store.fonts,
            // @ts-expect-error wrong type for pages
            pages: updatedPages,
          },
        },
      })
    );

    store.clear();
  };

  const updatePostsBranding = async () => {
    try {
      const { posts: latestPosts } = postList.length
        ? { posts: postList }
        : await dispatch(getPostsThunk({ postScreen: "generated" })).unwrap();

      await Promise.all(latestPosts.map((post) => updatePost(post)));
      clearLocalStorage();
    } catch (error) {
      genericError(error);
    }
  };

  const applyBrandingChanges = () => {
    if (
      (localStorage.areThemesUpdated ||
        localStorage.areBusinessDetailsUpdated) &&
      !localStorage.isFontUpdated &&
      !localStorage.isLogoUpdated &&
      !localStorage.isAvatarUpdated &&
      !localStorage.areColorsUpdated
    ) {
      clearLocalStorage();
      return;
    }
    return updatePostsBranding();
  };

  return { applyBrandingChanges, clearLocalStorage };
};

export default useApplyBrandingChanges;
