import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, ColorPicker } from "antd";
import { Palette } from "store/business/businessConstants";

const COLOR_PICKERS = [
  { label: "Brand Color", key: "accent_color" },
  {
    label: "Background Color",
    key: "background_color",
    tooltip: "Choose a subtle color that has high contrast to your text color",
  },
  {
    label: "Text Color",
    key: "text_color",
    tooltip:
      "Text should have high contrast to your brand and background colors and be easily legible.",
  },
] as const;

type ColorPaletteProps = {
  canEditBusiness: boolean;
  canRemovePalette: boolean;
  palette: Palette;
  handleColorChange: (data: { key: string; value: string }) => void;
  removePalette: () => void;
};

const ColorPalette = ({
  canEditBusiness,
  canRemovePalette,
  palette,
  handleColorChange,
  removePalette,
}: ColorPaletteProps) => (
  <div className="flex gap-2">
    {COLOR_PICKERS.map(({ label, key }) => (
      <ColorPicker
        key={key}
        showText={() => <span>{label}</span>}
        value={palette[key] || ""}
        disabledAlpha
        onChangeComplete={(value) => {
          handleColorChange({
            value: value.toHexString(),
            key,
          });
        }}
        disabled={!canEditBusiness}
      />
    ))}
    {canEditBusiness && canRemovePalette && (
      <Button
        size="small"
        type="text"
        icon={
          <MinusCircleOutlined
            onClick={removePalette}
            className="text-antd-colorError"
          />
        }
      />
    )}
  </div>
);

export default ColorPalette;
