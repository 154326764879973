import { FormInstance } from "antd";
import { Rule } from "antd/es/form";
import { Business } from "api/config/chalice-api";
import { LANGUAGES } from "fixtures/languages";
import IntegrationsList from "pages/Integrations/components/IntegrationList/IntegrationsList";
import { FunctionComponent } from "react";
import { BusinessDetails } from "./businessOnboardingConstants";
import BusinessDesign from "./onboardingStepper/sections/BusinessDesign";
import ContentTopics from "./onboardingStepper/sections/ContentTopics";
import YourBusiness from "./onboardingStepper/sections/YourBusiness";

const BUSINESS_INFO_GROUPS = [
  "your-business",
  "market-strategy",
  "content",
  "design",
  "connect-socials",
  "caption-ending",
  "additional-settings",
] as const;
export type BusinessInfoGroup = (typeof BUSINESS_INFO_GROUPS)[number];

type SectionComponentProps = {
  form: FormInstance<BusinessDetails>;
};
export type SectionComponent<T = void> = FunctionComponent<
  T extends void ? SectionComponentProps : T & SectionComponentProps
>;

type HideSettings = {
  hideFromOnboarding?: boolean;
  hideFromBusinessPage?: boolean;
};

export type SectionItem = {
  field: keyof Omit<Business, "id"> | "captionLength";
  label: string;
  type:
    | "text"
    | "array"
    | "textarea"
    | "languageSelect"
    | "phone"
    | "radio"
    | "slider";
  placeholder?: string;
  options?: { value: string }[];
  prefix?: string;
  tooltip?: string;
  warning?: string;
  rules?: Rule[];
} & HideSettings;

export type BusinessFormSection = {
  key: BusinessInfoGroup;
  StepComponent?: SectionComponent;
  header: string;
  subHeader: string;
  items?: SectionItem[];
} & HideSettings;

const VOICE_SECTION: SectionItem = {
  label: "Voice",
  type: "textarea",
  field: "tone",

  tooltip:
    "The voice of your business should include a general description of what you sound like. Here you can also provide specifics on words you want to avoid using, how you want to refer to yourself, and similar constraints.",
};

const LANGUAGE_SECTION: SectionItem = {
  label: "Post Language",
  type: "languageSelect",
  placeholder: "Select Language",
  field: "language",
  options: LANGUAGES,
  tooltip:
    "Choose the language you want your posts to be written in. Enter business details in with the same language to drive better results.",
};

const CTA_SECTION: SectionItem = {
  label: "Call to Actions (CTAs)",
  type: "array",
  field: "ctas",

  tooltip:
    "This field is for the message or prompt that encourages your audience to take a specific action, such as 'Buy Now', 'Sign Up,' or 'Learn More.' It should be clear, concise, and motivating.",
};

const SUFFIX_SECTION: SectionItem = {
  label: "Caption-Suffix/Hashtags",
  type: "text",
  field: "caption_suffix",

  tooltip:
    "Add hashtags and anything else you'd like to include at the end of your caption.",
};

export const BUSINESS_FORM_SECTIONS: BusinessFormSection[] = [
  // #region Your Business
  {
    key: "your-business",
    header: "Your Business",
    subHeader:
      "Your business details will be referenced in your captions and post designs.",
    StepComponent: YourBusiness,
    items: [
      {
        label: "Business Name",
        type: "text",
        field: "title",

        tooltip: "Your business name ",
      },
      {
        field: "contact_email",
        label: "Business Email",
        type: "text",
        hideFromOnboarding: true,
      },
      {
        label: "Business Phone",
        field: "contact_phone",
        type: "phone",
        hideFromOnboarding: true,
      },
      {
        label: "Business Website",
        type: "text",
        placeholder: "",
        field: "website",

        warning:
          "Without a website link, carousels will post as a photo album.",
        tooltip:
          "A website will make posts clickable on certain socials and will be included in some captions.",
        hideFromOnboarding: true,
      },
      {
        label: "Social Handle",
        type: "text",
        field: "social_handle",
        prefix: "@",

        tooltip:
          "This is intended to be your username of your primary social account. It may get included in some post designs.",
        hideFromOnboarding: true,
      },
      { ...VOICE_SECTION, hideFromBusinessPage: true },
    ],
  },
  // #endregion
  // #region Market Strategy
  {
    key: "market-strategy",
    header: "Marketing Strategy",
    subHeader:
      "Your marketing strategy helps give context around your business and assists in creating content that fits your audience.",
    items: [
      { ...VOICE_SECTION, hideFromOnboarding: true },
      {
        label: "Core Values",
        type: "textarea",
        field: "core_values",
        placeholder: "What principles guide your business?",

        tooltip:
          "The principles that guide your business's culture and mission.",
      },
      {
        label: "Industry",
        type: "text",
        field: "industry",
        tooltip:
          "Specify the industry your business operates in to tailor content appropriately.",
      },
      {
        label: "Niche",
        type: "textarea",
        placeholder: "What makes your business unique?",
        field: "niche",

        tooltip:
          "The specific traits that make your business stand out from the market.",
      },
      {
        label: "Audience",
        type: "textarea",
        placeholder: "Who are you targeting as a business?",
        field: "audience",

        tooltip: "Who you serve as a business.",
      },
      {
        label: "Audience Objectives",
        type: "textarea",
        placeholder: "What is your audience trying to achieve?",
        field: "objectives",

        tooltip: "The goals your audience is trying to achieve.",
      },
      {
        label: "Audience Pain Points",
        type: "textarea",
        placeholder: "What is your audience's pain points?",
        field: "pain_points",

        tooltip: "The struggles your audience faces.",
      },
    ],
  },
  // #endregion
  // #region Content
  {
    key: "content",
    hideFromBusinessPage: true,
    header: "Content",
    subHeader:
      "These settings are used when generating content for a specific post and guides us in the topics it posts about.",
    StepComponent: ContentTopics,
    items: [CTA_SECTION, LANGUAGE_SECTION],
  },
  // #endregion
  // #region Design
  {
    key: "design",
    hideFromBusinessPage: true,
    header: "Design",
    subHeader:
      "Tailor the look and feel of your posts to create a unique and recognizable brand image.",
    StepComponent: BusinessDesign,
  },
  // #endregion
  // #region Social
  {
    key: "connect-socials",
    hideFromBusinessPage: true,
    header: "Socials",
    subHeader:
      "Connect one or more socials to quickly start scheduling content.",
    StepComponent: IntegrationsList,
  },
  // #region Caption Ending
  {
    key: "caption-ending",
    hideFromOnboarding: true,
    header: "Caption Ending",
    subHeader: "These get included at the end of your caption.",
    items: [
      CTA_SECTION,
      { ...SUFFIX_SECTION, label: "Hashtags and Caption Ending" },
    ],
  },
  // #endregion
  // #region Additional Settings
  {
    key: "additional-settings",
    hideFromOnboarding: true,
    header: "Additional Settings",
    subHeader:
      "These preferences help further refine how your content is styled.",
    items: [
      {
        label: "Caption Length in Sentences",
        type: "slider",
        placeholder: "",
        field: "captionLength",
        tooltip: "Roughly how many sentences you want your captions to have.",
      },
      {
        label: "Emoji Usage",
        type: "radio",
        placeholder: "",
        field: "emoji_level",
        tooltip: "Set how often emojis appear in your content.",
      },
      {
        label: "Self Promotion",
        type: "radio",
        placeholder: "",
        field: "self_promotion_level",
        tooltip:
          "How much you want to promote your own business and offerings in your content.",
      },
      {
        label: "Writing Samples",
        type: "array",
        field: "writing_samples",
        tooltip:
          "Give past examples of your writing so we can emulate your style. Each sample can be a maximum of 300 characters long.",
      },
      LANGUAGE_SECTION,
    ],
  },
  // #endregion
];

export const ONBOARDING_FORM_SECTIONS = BUSINESS_FORM_SECTIONS.filter(
  ({ hideFromOnboarding }) => !hideFromOnboarding
);
