import { Button, Dropdown, Form } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormListOperation } from "antd/lib";
import { ContentTopic } from "api/config/chalice-api";
import { SectionComponent } from "pages/NewBusinessOnboarding/businessFormConstants";
import TopicModal, {
  TopicModalData,
  TopicModalProps,
} from "pages/Topics/TopicModal";
import { useState } from "react";
import {
  MdAdd,
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineMoreVert,
} from "react-icons/md";

const DEFAULT_TOPICS_VIEWABLE = 3;

const ContentTopics: SectionComponent<{
  isFromNewTopicModal?: boolean;
}> = ({ form, isFromNewTopicModal }) => {
  const [showAllTopics, setShowAllTopics] = useState(isFromNewTopicModal);
  const [showModal, setShowModal] = useState<TopicModalData | null>(null);

  const topics = useWatch("topics", form);

  const showAllTopicsButton =
    topics && topics.length > DEFAULT_TOPICS_VIEWABLE && !showAllTopics;

  const editTopic = (index: number) =>
    setShowModal({
      modalType: "edit-topic",
      topic: { ...topics![index], index },
    });

  const updateTopic: TopicModalProps["updateTopic"] = (newTopic, index) => {
    form.setFieldValue(
      "topics",
      topics?.map((topic, topicIndex) =>
        index === topicIndex ? newTopic : topic
      )
    );
  };

  return (
    <Form.Item
      name="topics"
      label={isFromNewTopicModal ? undefined : "Topics"}
    >
      <Form.List key="topics" name="topics">
        {(arrayFields, { add, remove }) => (
          <div className="space-y-2">
            {arrayFields
              .slice(0, showAllTopics ? undefined : DEFAULT_TOPICS_VIEWABLE)
              .map(({ key }, index) => (
                <ContentTopicItem
                  {...{
                    topic: topics?.[index],
                    isFromNewTopicModal,
                    editTopic,
                    remove,
                    index,
                    key,
                  }}
                />
              ))}
            {showAllTopicsButton ? (
              <Button
                size="small"
                type="link"
                onClick={() => setShowAllTopics(true)}
                className="-ml-4 !mt-2 block"
              >
                See Remaining {topics.length - DEFAULT_TOPICS_VIEWABLE} Topics
              </Button>
            ) : !isFromNewTopicModal ? (
              <Button
                onClick={() => {
                  setShowModal({ modalType: "new-topic" });
                  setShowAllTopics(true);
                }}
              >
                <MdAdd size={18} /> New Topic
              </Button>
            ) : null}
            {showModal !== null && (
              <TopicModal
                modalData={showModal}
                addTopic={add}
                updateTopic={updateTopic}
                closeModal={() => setShowModal(null)}
              />
            )}
          </div>
        )}
      </Form.List>
    </Form.Item>
  );
};

type ContentTopicItemProps = {
  remove: FormListOperation["remove"];
  editTopic: (index: number) => void;
  isFromNewTopicModal?: boolean;
  topic?: ContentTopic;
  index: number;
};

const ContentTopicItem = ({
  isFromNewTopicModal,
  editTopic,
  remove,
  topic,
  index,
}: ContentTopicItemProps) => (
  <div className="flex items-center gap-3">
    <Form.Item name={[index, "title"]} noStyle>
      <div
        className="w-full flex items-center justify-between gap-12 border rounded-md !p-2.5 !border-antd-colorBorderSecondary hover:!border-antd-colorPrimary cursor-pointer"
        onClick={() => editTopic(index)}
      >
        <div className="flex flex-col gap-2">
          <div>{topic?.title}</div>
          {isFromNewTopicModal && (
            <div className="text-antd-colorTextSecondary leading-5">
              {topic?.body}
            </div>
          )}
        </div>
        <Dropdown
          placement="bottomCenter"
          menu={{
            items: [
              {
                key: "edit",
                icon: <MdOutlineEdit size={16} />,
                label: "Edit",
                onClick: () => editTopic(index),
              },
              {
                key: "delete",
                icon: <MdOutlineDelete size={16} />,
                label: "Delete",
                onClick: () => remove(index),
              },
            ],
            onClick: ({ domEvent }) => domEvent.stopPropagation(),
          }}
        >
          <MdOutlineMoreVert
            onClick={(e) => e.stopPropagation()}
            className="text-antd-colorIcon min-w-5"
            size={20}
          />
        </Dropdown>
      </div>
    </Form.Item>
  </div>
);

export default ContentTopics;
