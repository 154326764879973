import { DatePicker, Select, Tag, Tooltip } from "antd";
import Link from "antd/es/typography/Link";
import dayjs, { Dayjs } from "dayjs";
import { trackEvent } from "utils/eventTracking/trackEvents";
import {
    ALL_TIME_PERIOD_OPTION,
    CUSTOM_PERIOD_OPTION,
    PERIOD_OPTIONS,
} from "../postAnalyticsFixtures";
import usePostAnalyticsContext from "../usePostAnalytics";
import SocialAccountFilter from "./SocialAccountFilter";

const PERIOD_OPTIONS_DICT = Object.fromEntries(
  PERIOD_OPTIONS.map((option) => [option.value, option])
);

const FilterBar = () => {
  const {
    dateRangeState: [{ current: currentDateRange }, setDateRange],
    periodState: [period, setPeriod],
    allTimeRangeState: [allTimeRange],
  } = usePostAnalyticsContext();

  const isAllTime = period.value === ALL_TIME_PERIOD_OPTION.value;

  const applyLocalRangeSelection = (dates?: (Dayjs | null)[] | null) => {
    const start = dates?.[0]?.tz().startOf("day");
    const end = dates?.[1]?.tz().endOf("day");

    if (start && end) {
      const dateDiff = end.diff(start);

      setPeriod(CUSTOM_PERIOD_OPTION);
      setDateRange({
        current: { start, end },
        previous: { start: start.subtract(dateDiff), end: start },
      });
    } else {
      setPeriod(ALL_TIME_PERIOD_OPTION);
      setDateRange({ current: undefined, previous: undefined });
    }
  };

  return (
    <div className="space-y-4 w-full sticky z-20 -top-14 bg-antd-colorBgLayout pt-6 pb-4">
      <div className="flex items-center gap-2 mb-2">
        <h3 className="m-0">Analytics</h3>
        <Tooltip title="This feature is under development. For any questions or feedback, please reach out to us over live chat!">
          <Tag color="#108ee9">Beta</Tag>
        </Tooltip>
        <Link
          type="secondary"
          className="!underline hover:!text-antd-colorTextSecondary"
          onClick={() =>
            trackEvent("refiner_launch_survey", {
                refinerArgs: {
                  surveyId: "4add4950-356f-11ef-8319-cb5fb9bc9e40",
                  triggerSurvey: true,
                },
            })
          }
        >
          Give feedback
        </Link>
      </div>

      <div className="flex flex-wrap gap-2 items-start">
        <SocialAccountFilter />
        <div className="flex gap-2 max-md:w-full">
          <Select
            className="basis-1/2 md:w-36"
            onChange={(period) => setPeriod(PERIOD_OPTIONS_DICT[period])}
            options={PERIOD_OPTIONS}
            value={period.value}
          />

          <DatePicker.RangePicker
            value={
              isAllTime
                ? [allTimeRange?.start, allTimeRange?.end]
                : [currentDateRange?.start, currentDateRange?.end]
            }
            className="md:min-w-fit basis-1/2 [&_input]:truncate"
            onChange={applyLocalRangeSelection}
            allowClear={!isAllTime}
            maxDate={dayjs().tz()}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
