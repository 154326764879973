import { PostSkeleton } from "designSystem/Loader";
import { range } from "lodash";
import Skeleton from "react-loading-skeleton";
import { PostScreen } from "store/posts/postConstants";
import SchedulePostSkeleton from "../ScheduledPosts/SchedulePostSkeleton";

const PostLoaderSkeleton = ({
  postScreen,
}: {
  postScreen: PostScreen | string;
}) => (
  <div className="pt-10">
    {/* TODO: combine both the loaders */}
    {range(4).map((_, index) =>
      postScreen === "scheduled" ? (
        index === 0 ? (
          <Skeleton
            className="block !mx-auto !h-10 w-[150px] rounded-lg"
            key={index}
          />
        ) : (
          <SchedulePostSkeleton key={index} />
        )
      ) : (
        <PostSkeleton
          key={index}
          showButtons={postScreen !== "published"}
          numberOfButtons={2}
          className="mb-20 w-sm"
        />
      )
    )}
  </div>
);

export default PostLoaderSkeleton;
