import { Button, Input, message } from "antd";
import { MdOutlineContentCopy } from "react-icons/md";

const CopyLinkBox = ({ link, label }: { link: string; label?: string }) => (
  <div className="space-y-1">
    <label className="font-medium">{label}</label>
    <Input
      value={link}
      className="py-0 pr-0"
      suffix={
        <Button
          onClick={() => {
            navigator.clipboard.writeText(link);
            message.success("Link copied successfully.");
          }}
          className="rounded-l-none"
          type="text"
          icon={<MdOutlineContentCopy />}
        />
      }
      readOnly
    />
  </div>
);

export default CopyLinkBox;
