import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./assets/scss/main.scss";

import * as Sentry from "@sentry/react";
import { message } from "antd";
import { setupApiClients } from "api/baseClients.js";
import ErrorFallback from "components/ErrorFallback.js";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import localizedFormat from "dayjs/plugin/localizedFormat.js";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import store from "store";
import App from "./App.jsx";

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(utc);

message.config({
  duration: 5,
  maxCount: 1,
});

if (import.meta.env.VITE_ENV === "PRODUCTION") {
  try {
    Sentry.init({
      environment: import.meta.env.VITE_ENV,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 0.5,
      tracePropagationTargets: [
        "https://psuf5gocxc.execute-api.us-east-1.amazonaws.com/api/v1",
        "https://stage.media.mymarky.net/api/v1",
        "https://stage.backend.mymarky.net/api/v1",
        "https://x5x7v0gecd.execute-api.us-east-1.amazonaws.com/api/v1",
        "https://media.mymarky.net/api/v1",
        "https://backend.mymarky.net/api/v1",
      ],
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 0.5,
    });
  } catch (error) {
    console.error("Failed to initialize Sentry", error);
  }
} else {
  // TODO: TEMP! antd doesn't support React StrictMode, hide these annoying errors
  // https://github.com/ant-design/ant-design/issues/22493
  // https://github.com/ant-design/ant-design/pull/27755
  const consoleError = console.error.bind(console);
  console.error = (errObj, ...args) => {
    try {
      if (
        errObj.includes(
          "%s is deprecated in StrictMode. %s was passed an instance of %s"
        ) ||
        errObj.includes("findDOMNode") ||
        (typeof errObj.message === "string" && args.includes("findDOMNode"))
      ) {
        return;
      }
      consoleError(errObj, ...args);
    } catch {
      consoleError(errObj, ...args);
    }
  };
}

const rootElement = document.querySelector("#root") as Element;

(async () => {
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    await setupApiClients();

    root.render(
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Provider store={store}>
            <App />
          </Provider>
        </ErrorBoundary>
      </React.StrictMode>
    );
  }
})();
