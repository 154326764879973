import { Theme } from "api/config/chalice-api";
import cx from "classnames";
import { useState } from "react";
import { MdCheck } from "react-icons/md";
import Skeleton from "react-loading-skeleton";

const OnboardingThemeCard = ({
  toggleTheme,
  theme,
  themes,
}: {
  theme: Theme;
  themes: string[];
  toggleTheme: (themeName: string) => void;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div className="themes__grid__item" onClick={() => toggleTheme(theme.name)}>
      {isImageLoaded && themes.includes(theme.name) && (
        <div className="themes__grid__item__check">
          <div className="themes__grid__item__check__icon-container">
            <MdCheck />
          </div>
        </div>
      )}
      <img
        src={theme.image_url}
        alt=""
        onLoad={() => setIsImageLoaded(true)}
        className={cx({ "loading-image": !isImageLoaded })}
      />
      {!isImageLoaded && (
        <Skeleton
          borderRadius={8}
          className="aspect-square"
          containerClassName="theme-skeleton"
        />
      )}
    </div>
  );
};

export default OnboardingThemeCard;
