import { Modal } from "antd";
import { updateUser } from "api/user";
import GoogleLogin from "components/Common/GoogleLogin";
import SignupForm from "components/Common/SignupForm";
import { genericError } from "fixtures/globalConstants";
import { useAppDispatch } from "store";
import { SignupCredentials } from "store/user/userConstants";
import { setUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { GlobalModalConfig } from "./GlobalModalContext/globalModalConfig";

export type SignupModalProps = {
  successCallback?: () => void;
  isClosable?: boolean;
  closeAfterSuccessCallback?: boolean;
};

export type SignupModalData = GlobalModalConfig<"signup", SignupModalProps>;

const SignUpModal = ({
  closeAfterSuccessCallback,
  isClosable = true,
  successCallback,
  closeModal,
  isOpen,
}: SignupModalData["propTypes"]) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async (credentials: SignupCredentials) => {
    try {
      const user = await updateUser({
        ...credentials,
        given_name: credentials.firstName,
        family_name: credentials.lastName,
      });
      dispatch(setUserInfo({ user }));

      await handleSuccess({ user });
    } catch (error) {
      genericError(error);
    }
  };

  // @ts-expect-error convert api/user.js file to TS
  const handleSuccess = async (response) => {
    window.$crisp?.push(["set", "session:event", ["user:created"]]);

    if (!closeAfterSuccessCallback) {
      closeModal();
    }

    if (successCallback) {
      await successCallback();
    }

    if (closeAfterSuccessCallback) {
      closeModal();
    }

    if (response.user.email) {
      trackEvent("sign_up");
    }
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      className="signup-modal auth-modal"
      title="Sign up"
      onCancel={closeModal}
      maskClosable={isClosable}
      closable={isClosable}
      centered
    >
      <div className="signup-modal__content auth-modal__content">
        <GoogleLogin buttonText="Sign up with Google" callback={closeModal} />
        <SignupForm handleSubmit={handleSubmit} />
      </div>
    </Modal>
  );
};

export default SignUpModal;
