import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { FaRedo, FaUndo } from "react-icons/fa";

const UndoRedoControls: ObserverComponent = observer(({ store }) => {
  const element = store.selectedElements[0];

  useEffect(() => {
    if (element) {
      element.set({ contentEditable: true });
    }
  }, [element]);

  return (
    <div className="grid grid-cols-2 gap-1 [&_button]:size-8 [&_button]:p-0 [&_button]:flex [&_button]:items-center">
      <Tooltip title="Undo">
        <Button
          onClick={() => store.history.undo()}
          disabled={!store.history.canUndo}
        >
          <FaUndo />
        </Button>
      </Tooltip>
      <Tooltip title="Redo">
        <Button
          onClick={() => store.history.redo()}
          disabled={!store.history.canRedo}
        >
          <FaRedo />
        </Button>
      </Tooltip>
    </div>
  );
});

export default UndoRedoControls;
