import { Button, message, Table } from "antd";
import { removeMember } from "api/teams";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { sortBy, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  currentBusinessGetter,
  currentUserBusinessesGetter,
  removeTeamMember,
} from "store/user/userSlice";
import AccessDropdown from "./AccessDropdown";
import InviteNewUserModal from "./InviteNewUserModal";
import TeamMemberActions from "./TeamMemberActions";
import {
  getBaseTeamMemberColumns,
  isClient,
  TeamsTableEntry,
} from "./teamMembersUtil";

// TODO: Further refactor and breakdown this file to improve clarity
// https://github.com/Marky-Team/web-frontend/pull/839#discussion_r1964750303
const TeamMembers = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const businesses = useAppSelector(currentUserBusinessesGetter);
  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { userInfo, isAgencyOwner, isAgencyMember } = useAppSelector(
    (state) => state.user
  );

  const { isCurrentBusinessOwner } = useUserPermissions();
  const { realm } = useAppContext();

  const loadingPreviewForState = useState<string | null>(null);
  const [disableActionButtons, setDisableActionButtons] = useState<
    string | null
  >(null);

  const isTeamsPage = pathname === "/team";
  const isBusinessLevelAgencyTeam = isAgencyMember && isTeamsPage;
  const isAgencyLevelTeam = pathname === "/agency-team";
  const isClientsPage = pathname === "/clients";

  const showFooter =
    realm.isMarky &&
    (isBusinessLevelAgencyTeam
      ? isAgencyOwner || isCurrentBusinessOwner
      : isAgencyMember || isCurrentBusinessOwner);

  const pageSubtitle = useMemo(() => {
    if (isClientsPage) {
      return;
    } else if (isAgencyLevelTeam) {
      return "Add people to your agency team to give access to manage your businesses.";
    } else if (isBusinessLevelAgencyTeam) {
      return `Listed are those who currently have access to this account. ${isAgencyOwner ? "This can be further managed from the agency dashboard." : ""}`;
    } else if (isTeamsPage) {
      return "View and manage your team. Invite new members to easily collaborate.";
    }
  }, [
    isBusinessLevelAgencyTeam,
    isAgencyLevelTeam,
    isAgencyOwner,
    isTeamsPage,
  ]);

  const getUserBusinessesWithRoles = (userId: string) =>
    businesses.reduce<Record<string, { title: string; roleId: string }>>(
      (acc, business) => {
        const roleId = business.members.find(
          ({ id }) => id === userId
        )?.role_id;

        if (roleId) {
          acc[business.id] = {
            title: business.title,
            roleId,
          };
        }

        return acc;
      },
      {}
    );

  const tableDataSource = useMemo((): TeamsTableEntry[] => {
    const owner: TeamsTableEntry = {
      ...(currentBusiness.owner.id ? currentBusiness.owner : userInfo),
      role_id: "",
      permissions: {},
      token: "",
      realm_id: "",
      isOwner: true,
    };

    let teamMembers = [];

    if (isClientsPage) {
      teamMembers = currentBusiness.members.filter(isClient);
    } else if (!isAgencyLevelTeam) {
      teamMembers = currentBusiness.members.filter(
        (member) => !isClient(member)
      );
    } else {
      teamMembers = uniqBy(
        businesses.flatMap((b) => b.members),
        "id"
      ).filter((member) => !isClient(member));
    }

    return [
      ...(isClientsPage ? [] : [owner]),
      ...sortBy(
        teamMembers,
        (member) =>
          member.given_name?.toLowerCase() ||
          member.email?.toLowerCase() ||
          "Unknown User"
      ),
    ].map((member) => ({ ...member, key: member.id }));
  }, [isAgencyLevelTeam, currentBusiness, businesses, isClientsPage]);

  const removeMemberFromBusiness = async (
    roleId: string,
    businessId?: string
  ) => {
    try {
      await removeMember({
        businessId: businessId || currentBusiness.id,
        roleId,
      });
      dispatch(removeTeamMember(roleId));
      !businessId && message.success("Member removed successfully.");
    } catch (error) {
      console.error(error);
      message.error("Something went wrong. Please try again later.");
    }
  };

  const removeMemberFromTeam = async (memberId: string) => {
    await Promise.all(
      Object.entries(getUserBusinessesWithRoles(memberId)).map(
        ([businessId, { roleId }]) =>
          removeMemberFromBusiness(roleId, businessId)
      )
    );
    message.success("Member removed successfully.");
  };

  const getColumns = () => {
    const doMembersExist =
      !!currentBusiness?.members?.length || isAgencyLevelTeam;

    const tableColumns = getBaseTeamMemberColumns({
      isClientsPage,
      hasMembers: !!currentBusiness?.members?.length || isAgencyLevelTeam,
      isMarkyRealm: realm.isMarky,
      loadingPreviewForState,
    });

    if (isAgencyLevelTeam) {
      tableColumns.push({
        title: "Access",
        key: "access",
        width: 125,
        render: (user) => (
          <AccessDropdown
            key={user.id}
            {...{ setDisableActionButtons, removeMemberFromBusiness, user }}
            userBusinesses={getUserBusinessesWithRoles(user.id)}
          />
        ),
      });
    }

    if (doMembersExist && !isBusinessLevelAgencyTeam && realm.isMarky) {
      tableColumns.push({
        title: "Actions",
        align: "center",
        key: "actions",
        width: 90,
        render: (_, user) => (
          // TODO: It seems like the below is only rendered if on the agency-team page, yet it still has code checking for currentBusinessId, which is unset on that page
          // Confused about flow
          <TeamMemberActions
            {...{
              removeMemberFromBusiness,
              removeMemberFromTeam,
              isAgencyLevelTeam,
              isClientsPage,
              user,
            }}
            disabled={disableActionButtons === user.id}
            businesses={getUserBusinessesWithRoles(user.id)}
          />
        ),
      });
    }

    return tableColumns;
  };

  return (
    <div className="card">
      <header className="space-y-0.5 p-4 border-b border-antd-colorBorderSecondary">
        <h2>
          {isClientsPage
            ? "Clients"
            : isAgencyLevelTeam
              ? "Agency Team"
              : "Team Members"}
        </h2>
        {pageSubtitle && <span>{pageSubtitle}</span>}
      </header>

      <div className="team-members team-members__main">
        <Table
          dataSource={tableDataSource}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={false}
        />
      </div>

      {showFooter && (
        <div className="team-members__footer">
          {isBusinessLevelAgencyTeam ? (
            <Button onClick={() => navigate("/agency-team")}>
              Manage Team
            </Button>
          ) : (
            <InviteNewUserModal
              isAgencyLevelTeam={isAgencyLevelTeam}
              isClientsPage={isClientsPage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TeamMembers;
