import { SearchOutlined } from "@ant-design/icons";
import { Input, Tag } from "antd";
import axios from "axios";
import useDebounce from "hooks/useDebounce";
import { random, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import { addPolotnoElement } from "pages/Posts/PostEditor/polotnoUtil";
import { StoreType } from "polotno/model/store";
import { ImagesGrid } from "polotno/side-panel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { imageSearchTermsGetter } from "store/user/userSlice";
import { UnsplashImage } from "../../polotnoTypes";

const CustomPhotosPanel: ObserverComponent<{ store: StoreType }> = observer(
  ({ store }) => {
    const imageSearchTerms = useSelector(imageSearchTermsGetter) as string[];
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState<string[]>([]);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);

    const debounceQuery = useDebounce(query, 500);

    const loadImages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          "https://api.unsplash.com/search/photos",
          {
            params: {
              client_id: import.meta.env.VITE_UNSPLASH_CLIENT_ACCESS_KEY,
              query: query || imageSearchTerms[random(5, false)],
              per_page: 20,
              page,
            },
          }
        );

        const fetchedImages = response.data.results.map(
          (img: UnsplashImage) => img.urls.regular
        );
        if (page === 1) {
          setImages(fetchedImages);
        } else {
          setImages((prev) => uniq([...prev, ...fetchedImages]));
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      loadImages();
    }, [page, debounceQuery]);

    useEffect(() => {
      setPage(1);
    }, [debounceQuery]);

    return (
      <div className="flex flex-col gap-2 h-full">
        <Input
          onChange={(e) => setQuery(e.target.value)}
          prefix={<SearchOutlined />}
          placeholder="Search"
          value={query}
          allowClear
        />

        {imageSearchTerms.length > 1 && (
          <div className="flex flex-wrap gap-y-1 cursor-pointer max-h-[150px] md:max-h-[400px] overflow-auto">
            {imageSearchTerms.map((searchTerm) => (
              <Tag key={searchTerm} onClick={() => setQuery(searchTerm)}>
                {searchTerm}
              </Tag>
            ))}
          </div>
        )}
        <ImagesGrid
          images={images}
          getPreview={(image) => image}
          onSelect={async (image, position) => {
            addPolotnoElement({
              store,
              src: image,
              position,
            });
          }}
          rowsNumber={2}
          loadMore={() => setPage(page + 1)}
          isLoading={isLoading}
        />
      </div>
    );
  }
);

export default CustomPhotosPanel;
