export type AppSumoPlan = {
  name: string;
  price: {
    old: string;
    new: string;
  };
  cardType: string;
  subtitle: string;
  features: string[];
  key:
    | "marky_tier1"
    | "marky_tier2"
    | "marky_tier3"
    | "extra-10-brands-monthly";
  btnText: string;
  tier: number;
  isMostPopular?: boolean;
  interval?: string;
};

export const APPSUMO_PLANS: AppSumoPlan[] = [
  {
    name: "AppSumo Tier 1",
    price: {
      old: "$552",
      new: "$59",
    },
    cardType: "2",
    subtitle: "One time purchase",
    features: [
      "3 brands",
      "1 posting time per day",
      "300 generated posts per brand",
    ],
    key: "marky_tier1",
    btnText: "Buy",
    tier: 1,
  },
  {
    name: "AppSumo Tier 2",
    price: {
      old: "$1200",
      new: "$139",
    },
    cardType: "2",
    subtitle: "One time purchase",
    features: [
      "6 brands",
      "Unlimited posting times per day",
      "3000 generated posts per brand",
    ],
    key: "marky_tier2",
    btnText: "Buy",
    tier: 2,
  },
  {
    name: "AppSumo Tier 3",
    price: {
      old: "$3564",
      new: "$399",
    },
    cardType: "2",
    subtitle: "One time purchase",
    features: [
      "50 brands",
      "Unlimited posting times per day",
      "Unlimited generated posts per brand",
      "Agency dashboard/progress report",
    ],
    key: "marky_tier3",
    btnText: "Buy",
    tier: 3,
  },
];

export const EXTRA_BRANDS_PACK: AppSumoPlan = {
  name: "10 Brands Pack",
  price: {
    old: "$150",
    new: "$100",
  },
  interval: "month",
  cardType: "2",
  subtitle: "Buy 10 extra brands",
  features: ["10 extra brands"],
  key: "extra-10-brands-monthly",
  btnText: "Buy",
  tier: 0,
};
