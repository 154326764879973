import { useState } from "react";
import MissingImagesMessage, {
  MissingImagesMessageProps,
} from "components/Common/MissingImagesMessage";

const useMissingImages = () => {
  const [missingCount, setMissingCount] = useState(0);
  const incrementMissing = () => setMissingCount(missingCount + 1);

  return {
    MissingImagesMessage: (props: Omit<MissingImagesMessageProps, "count">) => (
      <MissingImagesMessage count={missingCount} {...props} />
    ),
    incrementMissing,
    resetMissing: () => setMissingCount(0),
  };
};

export default useMissingImages;
