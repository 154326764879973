import {
  CommentWithUserDetails,
  PostExtended,
  PostGenerationInput,
  PostQuotaResponse,
} from "api/config/chalice-api";

export type PostStatus = Exclude<
  PostExtended["status"],
  "LOADING" | "FAILED" | undefined
>;

// TODO: Files should get type of Memory (Document?) directly from chalice
export type Memory = { id: string; title: string };

export type Post<T extends PostStatus = PostStatus> = Required<PostExtended> & {
  status: T;
  comments?: PostCommentData[];
  // TODO: PostGenerationInput should include a type for 'Memory', don't add on
  inputs: PostGenerationInput & { memory?: Memory[] };
};

export type PostCommentData = Record<
  "given_name" | "family_name" | "picture",
  string
> &
  Required<CommentWithUserDetails>;

export type PostScreenInfo = {
  isInitialized: boolean;
  isLoading: boolean;
  count: number;
  postList: Post[];
  nextPageUrl: string | null;
};

export const POST_SCREENS = [
  "generated",
  "draft",
  "scheduled",
  "published",
  "rejected",
] as const;

export type PostScreen = (typeof POST_SCREENS)[number];

export const STATUS_TO_SCREEN: Record<PostStatus, PostScreen> = {
  NEW: "generated",
  LIKED: "draft",
  SCHEDULED: "scheduled",
  PUBLISHED: "published",
  REJECTED: "rejected",
};

export const SCREEN_TO_STATUS: Record<PostScreen, PostStatus> = {
  generated: "NEW",
  draft: "LIKED",
  scheduled: "SCHEDULED",
  published: "PUBLISHED",
  rejected: "REJECTED",
};

export const DEFAULT_POST_STATUS_INFO: PostScreenInfo = {
  isInitialized: false,
  isLoading: false,
  count: 0,
  postList: [],
  nextPageUrl: null,
};

export type PostInfoDict = Record<PostScreen, PostScreenInfo> & {
  generated: PostScreenInfo & { isGenerating?: boolean; loadingCount: number };
} & {
  scheduled: PostScreenInfo & { publishAt: Record<string, string> };
};

export type PostState = PostInfoDict & {
  postLimitInfo: PostQuotaResponse | null;
  animateTab: PostScreen | null;
  consecutiveRejections: number;

  // TODO: Find a way to move this info out of the post store
  activePostPageIndex: Record<string, number | undefined>;
};

export const INITIAL_POSTS_STATE: PostState = {
  generated: {
    ...DEFAULT_POST_STATUS_INFO,
    isGenerating: false,
    loadingCount: 0,
  },
  draft: DEFAULT_POST_STATUS_INFO,
  scheduled: { ...DEFAULT_POST_STATUS_INFO, publishAt: {} },
  published: DEFAULT_POST_STATUS_INFO,
  rejected: DEFAULT_POST_STATUS_INFO,

  postLimitInfo: null,
  animateTab: null,
  consecutiveRejections: 0,
  activePostPageIndex: {},
};

export const GENERATED_POST_CONTAINER_ID = "generated-post-container";
