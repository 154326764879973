import { Button, Popconfirm, message } from "antd";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import ChangeOwnerModal from "pages/BusinessProfile/components/ChangeOwnerModal";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { deleteCurrentBusiness } from "store/business/businessActions";
import { resetPostData } from "store/posts/postSlice";
import { currentBusinessGetter } from "store/user/userSlice";
import ImportFromWebsiteModal from "./components/ImportFromWebsiteModal";

const BusinessActions = () => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { businesses, userInfo } = useAppSelector((state) => state.user);

  const isBusinessOwner = currentBusiness.user_id === userInfo.id;

  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenFromWebsiteModalOpen, setIsOpenFromWebsiteModalOpen] =
    useState(false);
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] =
    useState(false);

  const businessConfirmationText = useMemo(() => {
    return `Are you sure you want to delete the business: ${currentBusiness.title}? This is not reversible and will delete all business assets including all social connections, posts, schedules, team roles and information.`;
  }, [currentBusiness]);

  const handleBusinessDeletion = async () => {
    setIsDeleting(true);
    await dispatch(deleteCurrentBusiness());
    dispatch(resetPostData());
    message.success("Business deleted successfully.");

    setIsDeleting(false);
    navigate(
      businesses.length <= 1 ? "/business-metadata" : defaultBusinessRoute
    );
  };

  if (!isBusinessOwner) {
    return;
  }

  return (
    <div className="card">
      <div className="business-actions__block">
        <div className="business-actions__block__left">
          <h5>Transfer Ownership</h5>
          <p>Transfer this business to another user.</p>
        </div>
        <Button danger onClick={() => setIsTransferOwnershipModalOpen(true)}>
          Transfer Ownership
        </Button>
      </div>

      <div className="business-actions__block">
        <div className="business-actions__block__left">
          <h5>Change Business</h5>
          <p>Enter website URL or manually input the new business details.</p>
        </div>
        <Button onClick={() => setIsOpenFromWebsiteModalOpen(true)} danger>
          Change Business
        </Button>
      </div>

      {!localStorage.isAnonymous && (
        <div className="business-actions__block">
          <div className="business-actions__block__left">
            <h5>Delete Business</h5>
            <p>
              Deleting a business is irreversible. Please proceed with caution
              and ensure your decision is final.
            </p>
          </div>
          <Popconfirm
            title={`Delete ${currentBusiness.title}`}
            overlayClassName="delete-business-confirmation"
            description={businessConfirmationText}
            onConfirm={handleBusinessDeletion}
            disabled={isDeleting}
            cancelText="No"
            okText="Yes"
          >
            <Button danger loading={isDeleting}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      )}

      {isOpenFromWebsiteModalOpen ? (
        <ImportFromWebsiteModal
          close={() => setIsOpenFromWebsiteModalOpen(false)}
          recreateAccount
        />
      ) : (
        isTransferOwnershipModalOpen && (
          <ChangeOwnerModal
            close={() => setIsTransferOwnershipModalOpen(false)}
          />
        )
      )}
    </div>
  );
};

export default BusinessActions;
