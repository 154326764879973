import { Realm } from "api/config/chalice-api";
import { MARKY_REALM_ID } from "config/envVars";
import { MARKY_FAVICON_URL } from "fixtures/constants";

export type RealmDetails = {
  id?: string;
  isMarky: boolean;
  domain: string;
  logoUrl: string;
  colorPrimary?: string;
  name?: string;
};

export const setFavicon = (realm: RealmDetails, realmFetched: boolean) => {
  document.title = !realmFetched
    ? "Loading..."
    : realm.isMarky
      ? "Marky"
      : realm.name || "Client Portal";

  const link: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");

  const faviconUrl = realm.isMarky ? MARKY_FAVICON_URL : realm.logoUrl;

  if (!link) {
    const newLink = document.createElement("link");
    newLink.rel = "icon";
    newLink.href = faviconUrl;
    document.head.appendChild(newLink);
  } else {
    link.href = faviconUrl;
  }
};

export const extractRealmDetails = (realm: Realm): RealmDetails => ({
  id: realm.id,
  isMarky: realm.id === MARKY_REALM_ID,
  colorPrimary: realm.primary_color,
  logoUrl: realm.logo_url,
  domain: realm.domain,
  name: realm.name,
});
