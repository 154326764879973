import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import Icon from "@ant-design/icons/lib/components/Icon";

const Suitcase = (props: AntdIconProps) => (
  <Icon {...props} viewBox="0 0 14 14">
    <path
      d="M8.33325 3.59306V2.25972H5.66659V3.59306H8.33325ZM1.66659 4.92639V12.2597H12.3333V4.92639H1.66659ZM12.3333 3.59306C13.0733 3.59306 13.6666 4.18639 13.6666 4.92639V12.2597C13.6666 12.9997 13.0733 13.5931 12.3333 13.5931H1.66659C0.926585 13.5931 0.333252 12.9997 0.333252 12.2597L0.339919 4.92639C0.339919 4.18639 0.926585 3.59306 1.66659 3.59306H4.33325V2.25972C4.33325 1.51972 4.92659 0.926392 5.66659 0.926392H8.33325C9.07325 0.926392 9.66659 1.51972 9.66659 2.25972V3.59306H12.3333Z"
      fill="currentColor"
      fillOpacity="0.88"
    />
  </Icon>
);

export default Suitcase;
