import { Button, Dropdown } from "antd";
import { observer } from "mobx-react-lite";

import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { StoreType } from "polotno/model/store";

const ZOOM_OPTIONS = [
  {
    key: "0",
    label: <span>10%</span>,
    value: 0.1,
  },
  {
    key: "1",
    label: <span>25%</span>,
    value: 0.25,
  },
  {
    key: "2",
    label: <span>50%</span>,
    value: 0.5,
  },
  {
    key: "3",
    label: <span>75%</span>,
    value: 0.75,
  },
  {
    key: "4",
    label: <span>100%</span>,
    value: 1,
  },
  {
    key: "5",
    label: <span>150%</span>,
    value: 1.5,
  },
  {
    key: "6",
    label: <span>200%</span>,
    value: 2,
  },
  {
    key: "7",
    label: <span>300%</span>,
    value: 3,
  },
];

const ZoomControls: ObserverComponent<{ store: StoreType }> = observer(
  ({ store }) => {
    const handleClick = ({ key }: { key: string }) => {
      const index = parseInt(key);
      store.setScale(ZOOM_OPTIONS[index].value);
    };

    return (
      <div className="grid grid-cols-3 gap-1 absolute bg-antd-colorBgContainer rounded p-1 bottom-3 left-1/2 -translate-x-1/2 [&_button]:!h-6">
        <Button
          size="small"
          onClick={() => store.setScale(store.scale - 0.1)}
          disabled={store.scale <= 0.1}
        >
          <MinusOutlined />
        </Button>
        <Dropdown
          getPopupContainer={() =>
            document.querySelector(".go3456988929") as HTMLElement
          }
          menu={{ items: ZOOM_OPTIONS, onClick: handleClick }}
          placement="top"
        >
          <Button size="small">{Math.round(store.scale * 100)}%</Button>
        </Dropdown>
        <Button size="small" onClick={() => store.setScale(store.scale + 0.1)}>
          <PlusOutlined />
        </Button>
      </div>
    );
  }
);

export default ZoomControls;
