import { message } from "antd";
import { ReactNode, RefObject } from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import {
  ACCEPTED_FILE_TYPES,
  IMAGE_TOO_BIG_MESSAGE,
  INVALID_FILE_TYPE_MESSAGE,
  MAX_FILE_SIZE,
} from "./imageLibraryFixtures";

const REJECTION_CODE_MESSAGE: { [key: string]: string } = {
  "file-invalid-type": INVALID_FILE_TYPE_MESSAGE(1),
  "file-too-large": IMAGE_TOO_BIG_MESSAGE,
};

const RejectionMessages = (rejections: FileRejection[]) => (
  <div className="space-y-2 ml-4">
    {rejections.flatMap(({ file, errors }) => {
      // Only show first message for a file
      const { code, message } = errors[0];
      return (
        <div>
          Unable to import <strong>{file.name}</strong>:{" "}
          {REJECTION_CODE_MESSAGE[code] ?? `${message}.`}
        </div>
      );
    })}
  </div>
);

const ImageDropZone = ({
  children = null,
  scrollRef,
  className,
  ...dropzoneOptions
}: {
  children?: ReactNode;
  scrollRef?: RefObject<HTMLDivElement>;
  className?: string;
} & DropzoneOptions) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: MAX_FILE_SIZE,
    accept: { "image/*": ACCEPTED_FILE_TYPES },
    onDropRejected: (rejections) => {
      message.error(RejectionMessages(rejections));
    },
    ...dropzoneOptions,
  });

  return (
    <div
      className={`flex flex-col min-h-0 relative ${className}`}
      {...getRootProps()}
    >
      <div ref={scrollRef} className="h-full overflow-auto">
        {children}
      </div>
      {isDragActive && (
        <div className="absolute h-full w-full top-0 left-0 cursor-pointer bg-white/95 dark:bg-black/95 p-4">
          <div className="h-full w-full flex justify-center items-center border-2 border-dashed border-antd-colorBorderSecondary rounded-md">
            Drop the images here
          </div>
        </div>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export default ImageDropZone;
