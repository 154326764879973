import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { message } from "antd";
import pluralize from "pluralize";
import {
  addImageToLibrary,
  deleteImagesFromLibrary,
  fetchFailedTasks,
  fetchImagesInLibrary,
  fetchPendingTasks,
} from "./imageLibraryActions";
import {
  ImageLibraryState,
  INITIAL_STATE,
  LibraryImage,
} from "./imageLibraryConstants";

const imageLibrarySlice = createSlice({
  name: "imageLibrary",
  initialState: INITIAL_STATE,
  reducers: {
    resetImageLibrary: () => INITIAL_STATE,
    setLibraryImages: (state, action: PayloadAction<LibraryImage[]>) => {
      state.imagesInLibrary = action.payload;
    },
    updateLibraryImages: (
      state,
      action: PayloadAction<LibraryImage | LibraryImage[]>
    ) => {
      state.imagesInLibrary = [
        ...new Map(
          state.imagesInLibrary
            .concat(action.payload)
            .map((image) => [image.id, image])
        ).values(),
      ];
    },
    resetFailedTasks: (state) => {
      state.failedQueueTasks = [];
    },
    updateQueueTasks: (state, { payload }) => {
      state.pendingQueueTasks = payload.pendingQueueTasks;
      state.failedQueueTasks = payload.failedQueueTasks;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ImageLibraryState>) => {
    builder
      .addCase(fetchImagesInLibrary.pending, (state) => {
        state.isLoadingImages = true;
      })
      .addCase(fetchImagesInLibrary.fulfilled, (state) => {
        state.isLoadingImages = false;
        state.mediaServiceDown = false;
        state.isMediaLibraryFetched = true;
      })
      .addCase(fetchImagesInLibrary.rejected, (state) => {
        state.isLoadingImages = false;
        state.mediaServiceDown = true;
      })
      .addCase(fetchPendingTasks.fulfilled, (state, { payload }) => {
        state.pendingQueueTasks = payload;
      })
      .addCase(fetchFailedTasks.fulfilled, (state, { payload }) => {
        state.failedQueueTasks = payload;
      })
      .addCase(
        addImageToLibrary.fulfilled,
        (state, { payload: { localId, ...newImage } }) => {
          state.imagesInLibrary = state.imagesInLibrary.map((image) =>
            image.id === localId ? newImage : image
          );
        }
      )
      .addCase(addImageToLibrary.rejected, (state, { meta }) => {
        state.imagesInLibrary = state.imagesInLibrary.filter(
          (image) => meta.arg.localId !== image.id
        );
      })
      .addCase(
        deleteImagesFromLibrary.fulfilled,
        (_state, { meta, payload: { failedDeletes } }) => {
          const deletedCount = meta.arg.length - failedDeletes.length;
          deletedCount &&
            message.success(
              `Deleted ${pluralize("image", deletedCount, true)}`
            );
          failedDeletes.length &&
            message.error(
              `${pluralize("image", failedDeletes.length, true)} could not be deleted.`
            );
        }
      );
  },
});

export const {
  resetImageLibrary,
  setLibraryImages,
  updateLibraryImages,
  resetFailedTasks,
  updateQueueTasks,
} = imageLibrarySlice.actions;
export default imageLibrarySlice.reducer;
