import { CopyOutlined, DeleteOutlined, MailOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import { sendInviteEmail } from "api/businesses";
import InfoTooltip from "components/Common/InfoTooltip";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import getInviteLink from "./getInviteLink";
import { TeamsTableEntry } from "./teamMembersUtil";

// TODO: consolidate with user store properties/typedef
type TeamMemberActionsProps = {
  user: TeamsTableEntry;
  isAgencyLevelTeam: boolean;
  isClientsPage: boolean;
  businesses: { [businessId: string]: { title: string } };
  removeMemberFromTeam: (roleId: string) => void;
  removeMemberFromBusiness: (roleId: string) => void;
  disabled: boolean;
};

const TeamMemberActions = ({
  disabled,
  user,
  isAgencyLevelTeam,
  isClientsPage,
  businesses,
  removeMemberFromBusiness,
  removeMemberFromTeam,
}: TeamMemberActionsProps) => {
  const { ownedRealm } = useAppSelector((state) => state.user);
  const { id: currentBusinessId } = useAppSelector(currentBusinessGetter);

  const sendInviteEmailToUser = async () => {
    try {
      if (currentBusinessId) {
        await sendInviteEmail(currentBusinessId, user.id);
      } else {
        await Promise.all(
          Object.entries(businesses).map(([businessId, { title }]) =>
            sendInviteEmail(businessId, user.id).catch((error) => {
              console.error(error);
              message.error(`Invitation to ${title} failed to send.`);
            })
          )
        );
      }
      message.success(`Invite email sent successfully to ${user.email}.`);
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    }
  };

  const copyInviteLink = () => {
    if (user.email) {
      navigator.clipboard.writeText(
        getInviteLink({
          token: user.token,
          email: user.email,
          whiteLabelDomain:
            isClientsPage && ownedRealm ? ownedRealm.domain : undefined,
        })
      );
      message.success("Signup link copied successfully.");
    } else {
      message.error("User is missing an email.");
    }
  };

  return (
    <div className="flex justify-center gap-2">
      {user.token && !user.given_name && (
        <>
          <Tooltip title="Copy signup link">
            <Button
              type="text"
              className="cursor-pointer px-3"
              onClick={copyInviteLink}
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          {!isClientsPage && (
            <Tooltip title={!disabled && "Send invite email"}>
              <Button
                className="cursor-pointer px-3"
                onClick={sendInviteEmailToUser}
                disabled={disabled}
                type="text"
              >
                <MailOutlined />
              </Button>
            </Tooltip>
          )}
        </>
      )}

      {/* TODO: this check doesn't make sense, why is user.given_name relevant here? */}
      {user.given_name && !user.isOwner && (
        <InfoTooltip
          title={
            isClientsPage
              ? "Client has an account. Ask your client to log in via your client portal to access this business."
              : "Member already has account on Marky. Ask them to log in to access this business."
          }
        />
      )}

      {!user.isOwner && (
        <Tooltip title={!disabled && "Remove member"}>
          <Button
            onClick={() =>
              isAgencyLevelTeam
                ? removeMemberFromTeam(user.id)
                : removeMemberFromBusiness(user.role_id)
            }
            className="px-3"
            type="text"
            disabled={disabled}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default TeamMemberActions;
