import { Button, message } from "antd";
import { getFrillSSOToken } from "api/user";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const FrillSSO = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const getSSOToken = async () => {
    setIsLoading(true);
    try {
      const { token } = await getFrillSSOToken();
      window.location.href = `${searchParams.get("redirect")}?ssoToken=${token}`;
    } catch (error) {
      message.error("Unable to connect with Frill, please try again.");
    }
    setIsLoading(false);
  };

  return (
    <div className="flex items-center justify-center h-dvh">
      <Button type="primary" loading={isLoading} onClick={getSSOToken}>
        Sign in to Frill with SSO
      </Button>
    </div>
  );
};

export default FrillSSO;
