import { useContext } from "react";
import { UserPermissionsContext } from "./UserPermissionsProvider";

/**
 * Use this context to get an object of permissions a user has, to check
 * if a user has specific permission(s), or to override the permissions
 * in the app for testing.
 *
 * Permission objects are business-specific. There is only a permission
 * object for business members. It's assumed that business owners
 * have all permissions.
 *
 * Permission checking functions take a boolean flag to indicate whether
 * owners should be assumed to have that permission by default.
 * Default value of the flag is true.
 */
const useUserPermissions = () => useContext(UserPermissionsContext);

export default useUserPermissions;
