import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import Icon from "@ant-design/icons/lib/components/Icon";

const Hashtag = (props: AntdIconProps) => (
  <Icon {...props} viewBox="0 0 14 14">
    <path
      d="M11.3334 4.92639V3.59306H8.66675V0.926392H7.33341V3.59306H4.66675V0.926392H3.33341V3.59306H0.666748V4.92639H3.33341V7.59306H0.666748V8.92639H3.33341V11.5931H4.66675V8.92639H7.33341V11.5931H8.66675V8.92639H11.3334V7.59306H8.66675V4.92639H11.3334ZM7.33341 7.59306H4.66675V4.92639H7.33341V7.59306Z"
      fill="currentColor"
      fillOpacity="0.88"
    />
  </Icon>
);

export default Hashtag;
