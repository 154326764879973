import { removeAuthToken } from "api/config/axiosConfig";
import useAppContext from "config/AppContext/useAppContext";
import { ENV_NAME } from "config/envVars";
import { clear as clearIndexedDB } from "idb-keyval";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPostData } from "store/posts/postSlice";
import { resetThemes } from "store/themes/themesSlice";
import { resetUser } from "store/user/userSlice";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    realm,
    previewModeState: [, , resetPreviewMode],
    themeNameState: [themeName, setThemeName],
  } = useAppContext();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    clearIndexedDB();
    dispatch(resetUser());
    dispatch(resetThemes());
    dispatch(resetPostData());
    resetPreviewMode();
    localStorage.clear();
    removeAuthToken();

    setThemeName(themeName);

    const redirect = searchParams.get("redirectTo");
    if (redirect) {
      navigate(`/${redirect}`);
    } else if (ENV_NAME === "DEV" || (realm && !realm.isMarky)) {
      navigate("/login");
    } else {
      window.location.href = "https://mymarky.ai/";
    }
  }, []);

  return null;
};

export default Logout;
