import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Tooltip } from "antd";
import { MenuItemType } from "antd/es/menu/interface";
import cx from "classnames";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { EDIT_POST_BUTTON_ID } from "fixtures/elementIDs";
import { useMemo, useRef, useState } from "react";
import { FiBookmark } from "react-icons/fi";
import { MdOutlineSend, MdOutlineSendAndArchive } from "react-icons/md";
import { useAppSelector } from "store";
import { Post } from "store/posts/postConstants";
import { integrationsGetter, selectUserInfo } from "store/user/userSlice";
import PostCommentsContainer from "../PostComments/PostCommentsContainer";
import usePostSelectionContext, {
  SELECTION_ENABLED_STATUSES,
} from "../PostSelection/usePostSelectionContext";
import useScheduleCustomTime from "../ScheduleCustomTime/useScheduleCustomTime";
import useUpdatePostActions from "../utils/useUpdatePostActions";
import ConnectSocialsTooltip from "./ConnectSocialsTooltip";
import PostCardMedia from "./PostCardMedia";
import PostCardWrapper from "./PostCardWrapper";
import PostReviewActions from "./PostReviewActions";
import PublishDetailsAlert from "./PublishDetailsAlert";

const GenericPostCard = ({ post }: { post: Post }) => {
  const { hasPermission, hasSomePermissions } = useUserPermissions();
  const { isAnonymous } = useAppSelector(selectUserInfo);
  const { openGlobalModal } = useGlobalModal();
  const { selectionDisabled, inSelectionMode, isSelected, selectPost } =
    usePostSelectionContext();
  const {
    isLoading,
    loading: loadingPostAction,
    savePost,
    updatePostReviewStatus,
    schedulePost,
    publishPost,
    discardPost,
  } = useUpdatePostActions();
  const { customTimeMenuItem, CustomTimeModal } = useScheduleCustomTime();

  const { isAgencyMember } = useAppSelector((state) => state.user);
  const connectedSocials = useAppSelector(integrationsGetter);
  const hasConnectedSocials = connectedSocials.length > 0;

  const [isCardFlipped, setIsCardFlipped] = useState(false);

  const postContainerRef = useRef<HTMLDivElement>(null);

  const disableButtons = isLoading || isCardFlipped;
  const isPublishedPost = post.status === "PUBLISHED";
  const disablePolotnoEdit = !post.inputs && !post.render_design_as;

  const canPublishPosts = hasPermission("post_status_publish_write");
  const canEditPosts = hasSomePermissions([
    "caption_write",
    "post_design_write",
  ]);

  const postNowTooltip = useMemo(
    () =>
      hasConnectedSocials
        ? "This will override your scheduled publishing time and remove it from your scheduled posts"
        : "Please connect at least one social to start posting",
    [hasConnectedSocials]
  );

  const dropdownItems = useMemo(() => {
    const items: MenuItemType[] = [
      {
        label: "Schedule Next",
        icon: <MdOutlineSendAndArchive />,
        key: "schedule",
        onClick: () =>
          postActionWrapper(schedulePost, {
            post,
            scheduleType: "schedule-next",
          }),
      },
    ];

    if (canPublishPosts) {
      items.unshift({
        label: <ConnectSocialsTooltip>Publish Now</ConnectSocialsTooltip>,
        icon: <MdOutlineSend />,
        key: "publish",
        onClick: () => postActionWrapper(publishPost, post),
        disabled: !hasConnectedSocials,
      });
    }

    items.push(customTimeMenuItem);

    return items;
  }, [connectedSocials, canPublishPosts]);

  const postActionWrapper = <T extends (args: never) => unknown>(
    actionCallback: T,
    args: Parameters<T>[0]
  ) => {
    if (isAnonymous) {
      openGlobalModal({
        modalName: "signup",
        modalData: { successCallback: () => actionCallback(args) },
      });
    } else {
      actionCallback(args);
    }
  };

  return (
    <PostCardWrapper {...{ post, loadingPostAction }}>
      {({ openMiniEditor, openFullScreenEditor }) => (
        <div className="w-full flex flex-col sm:grid sm:grid-cols-[1fr_450px_1fr] gap-x-0 gap-y-4 px-2">
          <div
            tabIndex={1}
            className="post-container relative px-6 group/card col-start-2 h-fit max-w-full"
          >
            {isPublishedPost && (
              <PublishDetailsAlert post={post as Post<"PUBLISHED">} />
            )}

            {!isCardFlipped &&
              SELECTION_ENABLED_STATUSES.includes(post.status) && (
                <Checkbox
                  className={cx(
                    "absolute top-0 left-0 cursor-pointer transition-all",
                    {
                      "visible opacity-100": inSelectionMode,
                      "invisible opacity-0": !inSelectionMode,
                      "group-focus/card:visible group-hover/card:visible group-hover/card:opacity-100":
                        !selectionDisabled,
                    }
                  )}
                  checked={isSelected(post)}
                  onChange={() => selectPost(post)}
                />
              )}

            <div
              ref={postContainerRef}
              // e.detail === 1 to prevent click handler from getting called on the second click of the double click event
              onClick={(e) =>
                inSelectionMode
                  ? selectPost(post)
                  : e.detail === 1 && openMiniEditor()
              }
              onDoubleClick={() =>
                !disablePolotnoEdit &&
                !inSelectionMode &&
                openFullScreenEditor()
              }
              className={cx("w-full", {
                "cursor-pointer": !isPublishedPost,
                "border-antd-colorInfo": isSelected(post),
              })}
            >
              <PostCardMedia
                toggleFlip={() => setIsCardFlipped(!isCardFlipped)}
                isCardFlipped={isCardFlipped}
                post={post}
              />
            </div>
            {!inSelectionMode && !isPublishedPost && (
              <div className="mt-5 flex flex-col gap-4 items-center">
                <CustomTimeModal post={post} />
                <div className="post-actions">
                  {canEditPosts && (
                    <Tooltip
                      title={
                        window.matchMedia("(pointer: coarse)").matches
                          ? ""
                          : "Edit post"
                      }
                    >
                      <Button
                        id={EDIT_POST_BUTTON_ID}
                        className="icon-btn"
                        icon={<EditOutlined />}
                        disabled={disableButtons}
                        onClick={() => openMiniEditor(true)}
                      />
                    </Tooltip>
                  )}
                  {hasPermission("post_status_draft_write") &&
                    post.status !== "LIKED" && (
                      <Tooltip title="Move to drafts">
                        <Button
                          icon={
                            <FiBookmark style={{ height: 20, width: 17 }} />
                          }
                          onClick={() =>
                            postActionWrapper(savePost, {
                              post,
                              updateBody: { status: "LIKED" },
                            })
                          }
                          disabled={disableButtons}
                          className="icon-btn"
                        />
                      </Tooltip>
                    )}
                  {hasPermission("post_status_trash_write") &&
                    post.status !== "REJECTED" && (
                      <Tooltip title="Move to trash">
                        <Button
                          onClick={() => postActionWrapper(discardPost, post)}
                          icon={<DeleteOutlined />}
                          disabled={disableButtons}
                          className="icon-btn"
                        />
                      </Tooltip>
                    )}
                  {hasPermission("post_status_schedule_write") ? (
                    <Dropdown.Button
                      className="w-fit [&_button]:flex [&_button]:items-center"
                      icon={<DownOutlined />}
                      menu={{
                        items: dropdownItems,
                      }}
                      onClick={() => postActionWrapper(schedulePost, { post })}
                      disabled={disableButtons}
                    >
                      Schedule
                    </Dropdown.Button>
                  ) : (
                    canPublishPosts && (
                      <Tooltip title={postNowTooltip}>
                        <Button
                          disabled={!hasConnectedSocials || isCardFlipped}
                          icon={<CheckOutlined />}
                          onClick={() => postActionWrapper(publishPost, post)}
                        >
                          Post Now
                        </Button>
                      </Tooltip>
                    )
                  )}
                </div>

                <PostReviewActions
                  post={post}
                  disabled={isCardFlipped}
                  changeReviewStatus={(reviewStatus) =>
                    updatePostReviewStatus({
                      post,
                      reviewStatus,
                    })
                  }
                />
              </div>
            )}
          </div>
          {!inSelectionMode &&
            post.status === "LIKED" &&
            (isAgencyMember ||
              hasSomePermissions(["comment_read", "comment_write"], false)) && (
              <PostCommentsContainer
                post={post}
                containerHeight={postContainerRef.current?.clientHeight}
                // TODO: Need to work with BE to refine how these permissions are set & handled
                readonly={
                  isAgencyMember
                    ? false
                    : !hasPermission("comment_write", false)
                }
              />
            )}
        </div>
      )}
    </PostCardWrapper>
  );
};

export default GenericPostCard;
