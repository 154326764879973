import { Subscription } from "api/config/chalice-api";

export const PLAN_COSTS: {
  [key in Required<Subscription>["plan"]]?: number;
} & { DEFAULT: number } = {
  DEFAULT: 10,
  "personal-monthly": 20,
  "personal-yearly": 200,
  "professional-monthly": 50,
  "professional-yearly": 500,
  "agency-monthly": 200,
  "agency-yearly": 2000,
};
