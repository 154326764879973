import { useInfiniteQuery } from "@tanstack/react-query";
import { Empty, Input } from "antd";
import { openverseClient } from "api/baseClients";
import useDebounce from "hooks/useDebounce";
import { range, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import AudioItem from "./AudioItem";

const SCROLL_THRESHOLD = 240;

const AudiosPanel: ObserverComponent = observer(({ store }) => {
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchQuery = useDebounce(inputValue, 700);

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["audios", debouncedSearchQuery],
      queryFn: async ({ pageParam = 1 }) => {
        const res = await openverseClient.audio_search({
          q: debouncedSearchQuery,
          page: pageParam,
          category: "music",
        });
        return res.data;
      },
      getNextPageParam: (lastPage) => {
        const { page, page_count } = lastPage;
        return page < page_count ? page + 1 : undefined;
      },
      initialPageParam: 1,
    });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } =
      event.target as HTMLDivElement;

    if (
      scrollTop + clientHeight >= scrollHeight - SCROLL_THRESHOLD &&
      scrollTop > SCROLL_THRESHOLD &&
      !isLoading &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  const audios =
    uniqBy(
      data?.pages.flatMap((page) => page.results),
      "id"
    ) || [];
  const showEmptyState = !isLoading && !isFetchingNextPage && !audios.length;

  return (
    <div className="h-full">
      <Input
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Search Audios"
        value={inputValue}
        className="mb-4"
      />
      {!showEmptyState ? (
        <div
          className="flex flex-col gap-2 overflow-auto h-[calc(100%-64px)]"
          onScroll={handleScroll}
        >
          {audios.map((audio) => (
            <AudioItem key={audio.id} audioTrack={audio} store={store} />
          ))}
          {(isLoading || isFetchingNextPage) &&
            range(8).map((i) => <Skeleton height={102} key={i} />)}
        </div>
      ) : (
        <div className="flex justify-center items-center h-2/3">
          <Empty description="No audios found" />
        </div>
      )}
    </div>
  );
});

export default AudiosPanel;
