import { Button, message, Tooltip } from "antd";
import { Dropdown, MenuProps } from "antd/lib";
import { default as classNames } from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import { saveAs } from "file-saver";
import { isRTLLanguage } from "fixtures/languages";
import { motion } from "framer-motion";
import useGetPostDetailsQuery from "hooks/useGetPostDetailsQuery";
import JSZip from "jszip";
import { startCase } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  MdChatBubbleOutline,
  MdContentCopy,
  MdMoreVert,
  MdOpenInNew,
  MdOutlineDownload,
  MdOutlineVisibility,
} from "react-icons/md";
import { useAppSelector } from "store";
import { Post } from "store/posts/postConstants";
import { selectUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import {
  downloadFile,
  fetchMediaBlob,
  getFileType,
} from "utils/fileDownloadUtils";
import usePostSelectionContext from "../PostSelection/usePostSelectionContext";
import { useGetPostRenderInfo } from "../utils/useGetPostRenderInfo";
import useUpdatePostActions from "../utils/useUpdatePostActions";
import PostInputs from "./PostInputs";
import PostMediaRenderer from "./PostMediaRenderer";

const PostCardMedia = ({
  isCardFlipped,
  toggleFlip,
  post,
}: {
  isCardFlipped: boolean;
  toggleFlip: () => void;
  post: Post;
}) => {
  const { realm } = useAppContext();
  const user = useAppSelector(selectUserInfo);
  const { inSelectionMode, isSelected } = usePostSelectionContext();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);

  const { loading, duplicatePost } = useUpdatePostActions();

  const { isCarousel, isVideo } = useGetPostRenderInfo(post);

  const cardRef = useRef<HTMLDivElement>(null);
  const captionRef = useRef<HTMLDivElement>(null);

  const {
    data: postDetail = post,
    refetch,
    isLoading: isFetchingPostDetails,
  } = useGetPostDetailsQuery(post.id, {
    enabled: isCardFlipped,
  });

  const isMenuLoading = Boolean(
    isDownloading || loading || isFetchingPostDetails
  );

  const isRTL = useMemo(
    () => isRTLLanguage(post.caption || ""),
    [post.caption]
  );

  // TODO: repeat code with PostList downloading image/carousel
  const downloadSingleImage = async () => {
    try {
      await downloadFile(post?.media_urls?.[0], isVideo ? "video" : "image");
      message.success(`${isVideo ? "Video" : "Image"} download has started.`);
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "Something went wrong. Text our support line now for an instant response."
        );
      }
    }
  };

  const downloadCarousel = async () => {
    if (!post.media_urls) {
      message.info("Post has no media to download.");
      return;
    }

    try {
      const zip = new JSZip();
      const folder = zip.folder("collection");

      for (let i = 0; i < post.media_urls.length; i++) {
        const blob = await fetchMediaBlob(post.media_urls[i]);

        folder?.file(`image-${i + 1}.${getFileType(post.media_urls[i])}`, blob);
      }

      const content = await folder?.generateAsync({ type: "blob" });
      content && saveAs(content, "carousel");
      message.success("Download started.");
    } catch (error) {
      message.error(
        "Something went wrong. Please text our support line now for an instant response."
      );
    }
  };

  const handleImageDownload = async () => {
    setIsDownloading(true);

    if (isCarousel) {
      await downloadCarousel();
    } else {
      await downloadSingleImage();
    }

    setIsDownloading(false);
  };

  const goToTemplate = async () => {
    try {
      let templateId = postDetail?.template?.id;

      if (!templateId) {
        const { data: post } = await refetch();
        templateId = post?.template?.id;
      }

      window.open(
        `https://editor.mymarky.ai/?template-id=${templateId}`,
        "_blank"
      );
    } catch (error) {
      console.error(error);
      message.error("Something went wrong. Please try again later.");
    }
  };

  const menuItems = useMemo(() => {
    const items = [];

    if (post.inputs) {
      items.push({
        key: "inputs",
        label: "Show Inputs",
        icon: <MdOutlineVisibility size={18} />,
      });
    }

    if (realm.isMarky) {
      ["PUBLISHED", "LIKED"].includes(post.status) &&
        items.push({
          key: "duplicate",
          label: "Duplicate post",
          icon: <MdContentCopy size={18} />,
        });

      items.push(
        ...[
          {
            key: "download",
            label: `Download ${isVideo ? "Video" : `Image${isCarousel ? "s" : ""}`}`,
            icon: <MdOutlineDownload size={18} />,
          },
          {
            key: "feedback",
            label: "Leave feedback",
            icon: <MdChatBubbleOutline size={18} />,
          },
        ]
      );
    }

    if (user.is_superuser) {
      (post.inputs || !post.media_urls?.length) &&
        items.push({
          key: "go-to-template",
          label: "Go to template",
          icon: <MdOpenInNew size={18} />,
        });
    }

    return items;
  }, [realm.isMarky, post]);

  const handleMenuClick: MenuProps["onClick"] = ({ key, domEvent }) => {
    domEvent.stopPropagation();

    switch (key) {
      case "download":
        handleImageDownload();
        break;
      case "feedback":
        {
          trackEvent("refiner_launch_survey", {
            refinerArgs: {
              surveyId: "650f8210-3586-11ef-a4fe-1932cfced824",
              addAttributes: { post_id: post.id },
              triggerSurvey: true,
            },
          });
        }
        break;
      case "duplicate":
        duplicatePost(post);
        break;
      case "go-to-template":
        goToTemplate();
        break;
      case "inputs":
        toggleFlip();
    }
  };

  const updatePostCardHeight = () => {
    cardRef.current?.offsetHeight &&
      setCardHeight(cardRef.current.offsetHeight);
  };

  useEffect(() => {
    updatePostCardHeight();
  }, [cardRef.current, isExpanded]);

  const needsShowMoreButton = () => {
    if (captionRef.current) {
      return captionRef.current.scrollHeight > captionRef.current.clientHeight;
    } else {
      return post.caption && post.caption.length > 50;
    }
  };

  return (
    <motion.div
      style={cardHeight && isCardFlipped ? { height: cardHeight } : {}}
      initial={{ perspective: "1000px" }}
    >
      <motion.div
        style={{ transformStyle: "preserve-3d" }}
        animate={{ rotateY: isCardFlipped ? 180 : 0 }}
        transition={{ duration: 0.7 }}
        initial={{ rotateY: 0 }}
        className={classNames(
          "bg-antd-colorBgContainer border border-solid border-antd-colorBorder rounded-lg mx-auto w-full h-full p-4 shadow-md relative",
          {
            "outline outline-1 outline-antd-colorInfo !border-antd-colorInfo":
              isSelected(post),
            group: !inSelectionMode,
          }
        )}
        ref={cardRef}
      >
        <motion.div
          animate={{
            opacity: isCardFlipped ? 0 : 1,
          }}
          transition={{ duration: 0.3 }}
          initial={{ opacity: 1 }}
          className="w-full h-full"
          style={{ backfaceVisibility: "hidden" }}
        >
          {post.status !== "PUBLISHED" && post.review_status && (
            <div
              className={classNames(
                "absolute text-sm text-white font-medium rounded-3xl px-4 left-1/2 -translate-x-1/2 -top-3 leading-6",
                {
                  "bg-antd-colorSuccess": post.review_status === "APPROVED",
                  "bg-antd-colorInfo":
                    post.review_status === "READY_FOR_REVIEW",
                  "bg-antd-colorWarning":
                    post.review_status === "CHANGES_REQUESTED",
                }
              )}
            >
              {startCase(post.review_status?.toLowerCase()).replace(
                "For",
                "for"
              )}
            </div>
          )}
          <div className="relative">
            <Dropdown
              disabled={isMenuLoading}
              menu={{ items: menuItems, onClick: handleMenuClick }}
              onOpenChange={(open) => setIsMenuOpen(open)}
              trigger={["click"]}
            >
              <Tooltip title="More options">
                <Button
                  loading={isMenuLoading}
                  className={classNames(
                    "!size-10 z-10 absolute right-2 group-hover:visible shadow-lg",
                    isMenuOpen || isMenuLoading || post.status === "PUBLISHED"
                      ? "visible"
                      : "invisible",
                    isCarousel ? "top-11" : "top-1.5"
                  )}
                  onClick={(e) => e.stopPropagation()}
                  icon={<MdMoreVert size={18} />}
                />
              </Tooltip>
            </Dropdown>

            <PostMediaRenderer
              updatePostCardHeight={updatePostCardHeight}
              post={post}
            />
          </div>

          <div className="text-xs mt-4">
            <div
              ref={captionRef}
              className={classNames("whitespace-pre-wrap", {
                "line-clamp-4": !isExpanded,
                "text-right": isRTL,
              })}
            >
              {post.caption}
            </div>
            {needsShowMoreButton() && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(!isExpanded);
                }}
                className="!text-xs mt-1 pl-0"
                size="small"
                type="link"
              >
                Show {isExpanded ? "less" : "more"}
              </Button>
            )}
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: isCardFlipped ? 1 : 0,
          }}
          transition={{ duration: 0.3 }}
          className="w-full h-full absolute top-0 left-0 p-4"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <PostInputs
            postDetail={postDetail}
            showAsPopover={false}
            close={toggleFlip}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default PostCardMedia;
