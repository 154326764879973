import { UserBusinessTaskResult } from "api/config/django-api";
import { StockImage } from "api/config/media-api";

export type LibraryImage = Partial<Omit<StockImage, "file">> & {
  id: string;
  file: Partial<StockImage["file"]> & {
    url: string;
  };
  isUploading?: boolean;
};

export type QueueTaskStatusType = "FAILURE" | "PENDING" | "SUCCESS";

export type QueueTask = {
  task_id: string;
  task_name: string;
  task_kwargs: string;
  status: QueueTaskStatusType;
  content_type: string;
  content_encoding: string;
  result: string;
  date_created: string;
  date_updated: string;
};

export type ImageLibraryState = {
  imagesInLibrary: LibraryImage[];
  isLoadingImages: boolean;
  mediaServiceDown: boolean;
  pendingQueueTasks: UserBusinessTaskResult[];
  failedQueueTasks: UserBusinessTaskResult[];
  isMediaLibraryFetched: boolean;
};

export const INITIAL_STATE: ImageLibraryState = {
  isLoadingImages: false,
  mediaServiceDown: false,
  imagesInLibrary: [],
  pendingQueueTasks: [],
  failedQueueTasks: [],
  isMediaLibraryFetched: false,
};
