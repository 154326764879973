import { DefaultOptionType } from "antd/es/select";
import axios from "axios";
import LogoAndAvatar from "components/LogoAndAvatar/LogoAndAvatar";
import useAppContext from "config/AppContext/useAppContext";
import { getGoogleFontImage, getGoogleFontsListAPI } from "polotno/config";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setBusinessUpdated } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import ColorSelector from "./ColorSelector/ColorSelector";
import FontInput from "./FontInput";

const FONT_SELECTORS = [
  { label: "Header Font", key: "header_font" },
  { label: "Body Font", key: "body_font" },
];

const BrandingPage = () => {
  const dispatch = useDispatch();
  const url = getGoogleFontsListAPI();
  const { realm } = useAppContext();

  const [fonts, setFonts] = useState([] as string[]);

  useEffect(() => {
    return () => {
      const brandingUpdated =
        localStorage.isFontUpdated ||
        localStorage.areColorsUpdated ||
        localStorage.isLogoUpdated ||
        localStorage.isAvatarUpdated;

      dispatch(
        setBusinessUpdated({
          branding: brandingUpdated,
          content: localStorage.areThemesUpdated,
        })
      );

      (brandingUpdated || localStorage.areThemesUpdated) &&
        trackEvent("updated_business_content", { page: "branding" });
    };
  }, []);

  const options = useMemo(() => {
    return [
      {
        label: <span className="pl-7">Theme Font</span>,
        value: "",
      },
      ...fonts.reduce((acc, font) => {
        if (font !== "Roboto") {
          acc.push({
            value: font,
            label: (
              <img
                src={getGoogleFontImage(font)}
                className="font-image dark:invert"
              />
            ),
          });
        }

        return acc;
      }, [] as DefaultOptionType[]),
    ];
  }, [fonts]);

  const fetchFonts = async () => {
    const response = await axios.get<string[]>(url);
    setFonts(
      response.data.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      )
    );
  };

  useEffect(() => {
    fetchFonts();
  }, []);

  return (
    <div className="card">
      <header>
        <h2>Your Brand</h2>
        {realm.isMarky && (
          <span>
            Choose how your business presents itself. Marky uses your brand to
            give a unique look to the posts it generates.
          </span>
        )}
      </header>

      <main className="space-y-4">
        <LogoAndAvatar />
        <ColorSelector />
        <div className="mt-6">
          <h5 className="your-brand__section__heading">Font</h5>
          <p className="your-brand__section__subtitle">
            Choose fonts to use on all your posts.
          </p>

          <div className="space-y-3">
            {FONT_SELECTORS.map((item) => (
              <div
                key={item.key}
                className="space-y-2 flex flex-col [&_.ant-select]:!w-full"
              >
                <span className="text-sm">{item.label}</span>
                <FontInput
                  // @ts-expect-error convert file to TS to restrict item.key's value
                  fontKey={item.key}
                  options={options}
                  key={item.key}
                  fonts={fonts}
                />
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default BrandingPage;
