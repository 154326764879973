import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Button, Modal } from "antd";
import { AuthCredentials } from "api/config/chalice-api";
import { FacebookPagesWithInstagramAccount } from "api/integrations";
import FBIcon from "assets/icons/facebook-logo-filled.png";
import { CONNECT_IG_GUIDE_LINK } from "fixtures/constants";
import useInstagramConnection from "hooks/useInstagramConnection";
import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { IntegrationType } from "store/user/userConstants";
import { formatSocialName } from "utils/generalUtils";
import { InstagramIntegrationType } from "../IntegrationSettings/settingConstants";

export type BusinessAccount = [
  string,
  { picture_url?: string; username?: string },
];

const InstagramAccountSelector = ({
  integrationCredentials,
  resetCredentials,
  integrationType,
  setLoadingItem,
}: {
  setLoadingItem: (loadingItem: IntegrationType | null) => void;
  integrationCredentials: AuthCredentials | null;
  integrationType: InstagramIntegrationType;
  resetCredentials: () => void;
}) => {
  const { contextHolder, addInstagramAccount, handleInstagramAuthSuccess } =
    useInstagramConnection(setLoadingItem);

  const [instagramAccounts, setInstagramAccounts] =
    useState<FacebookPagesWithInstagramAccount>([]);

  const closeModal = () => setInstagramAccounts([]);

  useEffect(() => {
    if (integrationCredentials) {
      handleInstagramAuthSuccess({
        token: integrationCredentials.access_token,
        setInstagramAccounts,
        integrationType,
      });
    }
  }, [integrationCredentials]);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      centered
      title={`Which ${formatSocialName(integrationType)} account would you like to add?`}
      open={Boolean(instagramAccounts.length)}
      className="social-account-selector"
      footer={null}
      closable={!isLoading}
      maskClosable={!isLoading}
      onCancel={closeModal}
    >
      <div className="social-account-selector__main">
        <p className="social-account-selector__description">
          Select the {formatSocialName(integrationType)} business you want us to
          post to.
        </p>
        <div className="social-account-selector__account-list">
          {instagramAccounts.map(([fbPage, instagramAccount], i) => (
            <div key={i} className="social-account-selector__account">
              <div className="fb-page-name">
                <img src={FBIcon} alt="" className="fb-logo" />
                <strong>
                  {fbPage} <span>(Facebook page)</span>
                </strong>
              </div>
              {instagramAccount ? (
                <div className="social-account-selector__instagram-account">
                  {contextHolder}
                  <div className="social-account-selector__instagram-account__info">
                    {instagramAccount.picture_url ? (
                      <img src={instagramAccount.picture_url} alt="" />
                    ) : (
                      <FaUserCircle className="user-icon" />
                    )}
                    <div>
                      <h6 className="font-medium text-antd-colorPrimary">
                        {instagramAccount.username}
                      </h6>
                      <span>
                        {formatSocialName(integrationType)} Business Account
                      </span>
                    </div>
                  </div>
                  <Button
                    onClick={() =>
                      addInstagramAccount({
                        account: instagramAccount,
                        integrationCredentials,
                        resetCredentials,
                        integrationType,
                        setIsLoading,
                        closeModal,
                      })
                    }
                    loading={isLoading}
                  >
                    Add
                  </Button>
                </div>
              ) : (
                <InstagramBusinessAccountAlert />
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

const InstagramBusinessAccountAlert = () => {
  return (
    <Alert
      showIcon
      icon={<InfoCircleFilled />}
      type="error"
      description={
        <div>
          There is no Instagram business account linked to this Facebook page.
          <a
            href={CONNECT_IG_GUIDE_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            {` Learn how to connect Instagram to a facebook page`}
          </a>
        </div>
      }
    />
  );
};

export default InstagramAccountSelector;
