import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Empty, Tooltip } from "antd";
import {
  AuthCredentials,
  Integration,
  SelectedPage,
} from "api/config/chalice-api";
import { fetchPages, updateIntegration } from "api/integrations";
import ModalWithBorders from "components/ModalWithBorders";
import { genericError } from "fixtures/globalConstants";
import { range } from "lodash";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  integrationsGetter,
  updateIntegrationItem,
} from "store/user/userSlice";
import { formatSocialName } from "utils/generalUtils";
import { EMPTY_INTEGRATION_DESCRIPTION } from "../IntegrationPageSelector/integrationPageFixtures";
import PageSelect from "../PageSelect";
import IntegrationSettingItem from "./PublishSettingItem";
import {
  getSettingItems,
  HandleIntegrationConnect,
  INTEGRATIONS_REQUIRING_PAGE_SELECTION,
  IntegrationWithSettings,
} from "./settingConstants";

type IntegrationSettingsProps = {
  createIntegration?: HandleIntegrationConnect;
  integrationType: IntegrationWithSettings;
  integrationCredentials: AuthCredentials | null;
  closeModal: () => void;
};

const IntegrationSettings = ({
  integrationCredentials,
  createIntegration,
  integrationType,
  closeModal,
}: IntegrationSettingsProps) => {
  const dispatch = useDispatch();
  const connectedSocials = useSelector(integrationsGetter);

  const [publishSettings, setPublishSettings] = useState({
    brandOrganicToggle: false,
    aiGeneratedTitle: false,
    directPublish: false,
    autoAddMusic: false,
    autoResize: false,
  });

  const [selectedPage, setSelectedPage] = useState<SelectedPage | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const settingItems = useMemo(
    () => getSettingItems({ integrationType, publishSettings }),
    [publishSettings, integrationType]
  );

  const currentIntegration = useMemo(
    () =>
      connectedSocials.find(
        (social) => social.type === integrationType
      ) as Integration,
    [connectedSocials, integrationType]
  );

  const isIntegrationAlreadyCreated = Boolean(currentIntegration);

  const isPageSelectionRequired = useMemo(
    () =>
      INTEGRATIONS_REQUIRING_PAGE_SELECTION.includes(
        integrationType as (typeof INTEGRATIONS_REQUIRING_PAGE_SELECTION)[number]
      ),
    [integrationType]
  );

  const saveSettings = async () => {
    setIsLoading(true);

    const publishSettingsData =
      integrationType === "tiktok"
        ? {
            brand_organic_toggle: publishSettings.brandOrganicToggle,
            ai_generate_title: publishSettings.aiGeneratedTitle,
            direct_publish: publishSettings.directPublish,
            auto_add_music: publishSettings.autoAddMusic,
          }
        : {
            ai_generate_title: publishSettings.aiGeneratedTitle,
          };

    try {
      if (currentIntegration?.id) {
        const { integration } = await updateIntegration(currentIntegration.id, {
          publish_settings: publishSettingsData,
          ...(isPageSelectionRequired &&
            selectedPage && { selected_page: selectedPage }),
        });
        dispatch(updateIntegrationItem(integration));
      } else if (createIntegration) {
        await createIntegration({
          authCredentials: integrationCredentials,
          type: integrationType,
          publishSettings,
          selectedPage,
        });
      }
      closeModal();
    } catch (error) {
      genericError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    currentIntegration?.selected_page &&
      setSelectedPage(currentIntegration.selected_page as SelectedPage);

    if (!currentIntegration?.publish_settings) {
      return;
    }

    if (integrationType === "tiktok") {
      setPublishSettings((prev) => ({
        ...prev,
        aiGeneratedTitle:
          currentIntegration.publish_settings?.ai_generate_title,
        directPublish: currentIntegration.publish_settings?.direct_publish,
        autoAddMusic: currentIntegration.publish_settings?.auto_add_music,
        brandOrganicToggle:
          currentIntegration.publish_settings?.brand_organic_toggle,
      }));
    } else if (
      ["pinterest", "linkedIn", "googleBusiness", "facebook"].includes(
        integrationType
      )
    ) {
      setPublishSettings((prev) => ({
        ...prev,
        aiGeneratedTitle:
          currentIntegration.publish_settings?.ai_generate_title,
        autoResize: currentIntegration.publish_settings?.auto_resize,
      }));
    }
  }, [currentIntegration]);

  const { data: pages = [], isLoading: areLoadingPages } = useQuery({
    queryKey: [currentIntegration?.id],
    queryFn: () =>
      fetchPages({
        type: integrationType,
        token: (currentIntegration?.auth_credentials.access_token ||
          integrationCredentials?.access_token) as string,
      }),
    enabled: isPageSelectionRequired,
  });

  const showEmptyState =
    !isIntegrationAlreadyCreated &&
    isPageSelectionRequired &&
    !areLoadingPages &&
    pages.length === 0;

  const entityType =
    integrationType === "googleBusiness"
      ? "Business"
      : integrationType === "pinterest"
        ? "Board"
        : "Page";

  const missingPageSelection = isPageSelectionRequired && !selectedPage;

  return (
    <ModalWithBorders
      title={
        currentIntegration
          ? `${formatSocialName(integrationType)} Integration Settings`
          : `Connect ${formatSocialName(integrationType)}`
      }
      onCancel={closeModal}
      open
      footer={
        <>
          <Button disabled={isLoading} onClick={closeModal}>
            Close
          </Button>
          {!showEmptyState && (
            <Tooltip
              title={
                missingPageSelection
                  ? "Select a page to connect before continuing."
                  : null
              }
            >
              <Button
                disabled={missingPageSelection}
                onClick={saveSettings}
                loading={isLoading}
                type="primary"
              >
                {isIntegrationAlreadyCreated ? "Save" : "Continue"}
              </Button>
            </Tooltip>
          )}
        </>
      }
    >
      {areLoadingPages && !isIntegrationAlreadyCreated ? (
        <div className="grid gap-3">
          {range(3).map((i) => (
            <Skeleton height={32} key={i} />
          ))}
        </div>
      ) : showEmptyState ? (
        <Empty
          description={EMPTY_INTEGRATION_DESCRIPTION[integrationType]}
          className="text-center"
        />
      ) : (
        <div className="grid gap-3">
          {settingItems.map((setting, index) => (
            <IntegrationSettingItem
              setPublishSettings={setPublishSettings}
              {...setting}
              key={index}
            />
          ))}
          {isPageSelectionRequired && (
            <div className="flex items-center justify-between">
              <div className="space-x-1.5">
                <label>{entityType}</label>
                <Tooltip
                  title={`Choose your preferred ${formatSocialName(integrationType)} ${entityType} for posting`}
                >
                  <InfoCircleOutlined className="text-antd-colorIcon" />
                </Tooltip>
              </div>

              <PageSelect
                setSelectedPage={setSelectedPage}
                selectedPage={selectedPage}
                isLoading={areLoadingPages}
                pages={pages}
              />
            </div>
          )}
        </div>
      )}
    </ModalWithBorders>
  );
};

export default IntegrationSettings;
