import { Button, Modal } from "antd";

type DiscardChangesConfirmationProps = {
  confirmationOpen: boolean;
  buttonText?: string;
  setConfirmationOpen: (open: boolean) => void;
  discardChanges: () => void;
};

const DiscardChangesConfirmation = ({
  confirmationOpen,
  buttonText = "Keep Editing",
  setConfirmationOpen,
  discardChanges,
}: DiscardChangesConfirmationProps) => {
  const closeConfirmation = () => setConfirmationOpen(false);

  return (
    <Modal
      open={confirmationOpen}
      title="Discard Changes?"
      centered
      onCancel={closeConfirmation}
      footer={
        <div className="discard-changes-buttons">
          <Button onClick={closeConfirmation} type="text">
            {buttonText}
          </Button>
          <Button
            danger
            onClick={() => {
              closeConfirmation();
              discardChanges();
            }}
          >
            Discard Changes
          </Button>
        </div>
      }
    >
      You'll lose all your changes, this can't be undone.
    </Modal>
  );
};

export default DiscardChangesConfirmation;
