import { ThemeConfig } from "antd";

export const DARK_CLASSNAME = "dark" as const;
export const ALLOW_OVERRIDES_CLASSNAME = "allow-antd-overrides" as const;
export type ThemeConfigName = typeof DARK_CLASSNAME | "default";

const COMPONENT_CONFIGS: ThemeConfig["components"] = {
  Menu: {
    iconSize: 18,
  },
  Input: {
    controlHeight: 40,
  },
  Checkbox: {
    colorPrimary: "var(--antd-colorInfo)",
    colorPrimaryHover: "var(--antd-colorInfoHover)",
  },
  Button: {
    controlHeight: 40,
    // TODO: FE made normal buttons be the large size (40px) instead of customizing how big large buttons are and using `size=large`.
    // Size `small` now reflects how default-sized buttons look in Figma
    borderRadiusSM: 6,
    paddingInlineSM: 15,
    controlHeightSM: 32,
  },
  Radio: {
    colorPrimary: "var(--antd-colorInfo)",
    colorPrimaryHover: "var(--antd-colorInfo)",
    buttonColor: "var(--antd-colorTextSecondary)",
    buttonCheckedBg: "var(--antd-colorInfoBg)",
  },
};

export const THEME_CONFIGS: { [key in ThemeConfigName]: ThemeConfig } = {
  default: {
    token: {
      colorText: "#000",
      colorTextBase: "#171717",
      colorTextSecondary: "#797979",
      colorBgLayout: "#f9f9f9",

      colorPrimary: "#1b1b1b",
      colorPrimaryBg: "#efefef",
      colorPrimaryBorder: "#952ed159",

      colorLink: "#952ed1",
      colorInfo: "#952ed1",
      red: "#e53e3e",
      green: "#158d13",
      controlOutline: "#952ed133",
    },
    components: {
      ...COMPONENT_CONFIGS,
      Menu: {
        ...COMPONENT_CONFIGS.Menu,
        itemSelectedColor: "#952ed1",
        itemColor: "rgba(0, 0, 0, 0.65)",
        itemSelectedBg: "#FCF0FF",
        itemActiveBg: "#FCF0FF",
      },
    },
  },

  [DARK_CLASSNAME]: {
    token: {
      colorText: "#fff",
      colorTextBase: "rgba(255, 255, 255, 0.85)",
      colorTextSecondary: "rgba(255, 255, 255, 0.45)",

      colorBorder: "rgba(253, 253, 253, 0.12)",
      colorBorderSecondary: "rgba(253, 253, 253, 0.12)",

      colorPrimary: "#6c6c6c",
      colorPrimaryBorder: "#dc9affcc",

      colorLink: "#dc9aff",
      colorInfo: "#ce7aff",
      red: "#dc4446",
      green: "#158d13",
    },
    components: {
      ...COMPONENT_CONFIGS,
      Menu: {
        ...COMPONENT_CONFIGS.Menu,
        itemSelectedColor: "#ce7aff",
        itemSelectedBg: "#27202F",
        itemActiveBg: "#27202F",
      },
      Button: {
        ...COMPONENT_CONFIGS.Button,
        colorPrimary: "#f4f4f4",
        primaryColor: "#26262C",
      },
      Switch: {
        colorPrimary: "#f4f4f4",
        handleBg: "#26262C",
      },
    },
  },
};
