import BusinessOnboardingModal, {
  BusinessOnboardingModalData,
} from "pages/NewBusinessOnboarding/BusinessOnboardingModal";
import { FunctionComponent } from "react";
import AppSumoPlans, {
  AppSumoPlansModalData,
} from "../AppSumoPlans/AppSumoPlans";
import PostLimitModal from "../PostLimitModal";
import SignUpModal, { SignupModalData } from "../SignUpModal";
import SubscriptionModal, {
  SubscriptionModalData,
} from "../SubscriptionModal/SubscriptionModal";

type GlobalModalCommonProps = { isOpen: boolean; closeModal: () => void };

export type GlobalModalConfig<
  ModalName extends string,
  ModalData = object,
  DataRequired = false,
> = { modalName: ModalName } & GlobalModalPropsConfig<ModalData, DataRequired>;

type GlobalModalPropsConfig<
  ModalData = object,
  DataRequired = false,
> = DataRequired extends true
  ? {
      modalData: ModalData;
      propTypes: ModalData & GlobalModalCommonProps;
    }
  : {
      modalData?: ModalData;
      propTypes: Partial<ModalData> & {
        isOpen: boolean;
        closeModal: () => void;
      };
    };

type GlobalModalData<T extends { propTypes: object }> = Omit<T, "propTypes">;

/**
 * To add a Global Modal, create and export a `GlobalModalConfig` that includes the
 * `modalName` (required), and the type of `modalData` to pass onto the modal (optional).
 *
 * If the Global Modal requires props to be sent, set `DataRequired` to `true`.
 *
 * Add the Config type to `ValidGlobalModalData`, and add an entry to `GLOBAL_MODAL_DICT`
 * to link the component. Wrap the type in `GlobalModalData` so that `propTypes` is
 * omitted.
 *
 * For Global Modals that require props, cast the component as `FunctionComponent` in
 * `GLOBAL_MODAL_DICT`. Typechecking on required props is performed when using the
 * `openGlobalModal` method in the context.
 *
 * Note: `GlobalModalConfig.propTypes` is only for the component who instantiates the
 * `GlobalModalConfig` type, so that it may reference the configured props.
 */
export type ValidGlobalModalData = { markyOnly?: boolean } & (
  | GlobalModalData<BusinessOnboardingModalData>
  | GlobalModalData<SignupModalData>
  | GlobalModalConfig<"post-limit", object>
  | GlobalModalData<SubscriptionModalData>
  | GlobalModalData<AppSumoPlansModalData>
);

export const GLOBAL_MODAL_DICT: {
  [key in ValidGlobalModalData["modalName"]]: FunctionComponent<GlobalModalCommonProps>;
} = {
  "appsumo-plans": AppSumoPlans as FunctionComponent,
  "create-business": BusinessOnboardingModal,
  subscription: SubscriptionModal,
  "post-limit": PostLimitModal,
  signup: SignUpModal,
};
