import {
  MixMatchChoicesBody,
  Permissions,
  PostQuotaResponse,
  UpdatePostBody,
} from "api/config/chalice-api";
import {
  fetchPostsQuota,
  generateNewPosts,
  getNextPostPage,
  getPostDetails,
  getPosts,
  GetPostsPaginationArgs,
  schedulePost,
  updatePost,
} from "api/postsApi";
import { createAppAsyncThunk } from "store/storeHelpers";
import { IntegrationType } from "store/user/userConstants";
import { Post, PostScreen, SCREEN_TO_STATUS } from "./postConstants";
import { syncPostData } from "./postSlice";
import { sortPostsByDate } from "./postUtil";

const MAX_GENERATED_CALLS = 10;

export const getPostsThunk = createAppAsyncThunk(
  "posts/getPostsThunk",
  async (
    {
      postScreen,
      limitByPermissions,
      nextPageUrl,
      ...paginationArgs
    }: {
      postScreen: PostScreen;
      limitByPermissions?: Permissions;
      nextPageUrl?: string;
    } & GetPostsPaginationArgs,
    { getState }
  ) => {
    const businessId = getState().user.currentBusiness.id;
    const { posts, ...postsResponse } = await (nextPageUrl
      ? getNextPostPage({ businessId, nextPageUrl })
      : getPosts({
          businessId,
          postStatus: SCREEN_TO_STATUS[postScreen],
          ...paginationArgs,
        }));

    return {
      ...postsResponse,
      posts: posts.sort(sortPostsByDate),
      limitByPermissions,
    };
  }
);

export const generatePostsThunk = createAppAsyncThunk(
  "posts/generatePostsThunk",
  async (
    data: MixMatchChoicesBody | undefined = undefined,
    { getState, dispatch }
  ) => {
    const businessId = getState().user.currentBusiness.id;
    const { ids: newPostIds } = await generateNewPosts(businessId, data);
    dispatch(fetchPostsQuotaThunk());

    if (newPostIds.length) {
      dispatch(pollGeneratedPostsThunk());
    }
  }
);

export const pollGeneratedPostsThunk = createAppAsyncThunk(
  "posts/pollGeneratedPosts",
  async (_: undefined, { dispatch }) => {
    const generatePosts = () =>
      dispatch(getPostsThunk({ postScreen: "generated" })).unwrap();
    const { loading_posts_count } = await generatePosts();
    if (!loading_posts_count) {
      return;
    }

    return new Promise<void>((resolve) => {
      let consecutiveCalls = 1;

      const createPollTimeout = () =>
        setTimeout(async () => {
          const { loading_posts_count } = await generatePosts();
          consecutiveCalls += 1;

          if (
            !loading_posts_count ||
            consecutiveCalls === MAX_GENERATED_CALLS
          ) {
            resolve();
          } else {
            createPollTimeout();
          }
        }, 12000);

      createPollTimeout();
    });
  }
);

export const getPostDetailsThunk = createAppAsyncThunk(
  "posts/getPostDetailsThunk",
  async (postId: string, { dispatch }) => {
    const { post } = await getPostDetails(postId);
    dispatch(syncPostData({ post }));
    return post;
  }
);

export const updatePostThunk = createAppAsyncThunk(
  "posts/updatePostThunk",
  async (
    {
      post,
      body,
      preventAnimation,
    }: { post: Post; body: UpdatePostBody; preventAnimation?: boolean },
    { dispatch }
  ) => {
    const { post: updatedPost } = await updatePost({
      postId: post.id,
      body,
    });
    dispatch(
      syncPostData({
        post: updatedPost,
        previousPost: post,
        preventAnimation,
      })
    );
    return updatedPost;
  }
);

export const schedulePostThunk = createAppAsyncThunk(
  "posts/schedulePostThunk",
  async (
    {
      post,
      publishTo,
      preventAnimation,
      publishTimestamp,
      queue,
    }: {
      post: Pick<Post, "id" | "status">;
      publishTo?: IntegrationType[];
      preventAnimation?: boolean;
    } & Either<{ publishTimestamp: number }, { queue: number }>,
    { dispatch }
  ) => {
    const { post: updatedPost } = (await schedulePost({
      postId: post.id,
      body: {
        publish_to: publishTo,
        adhoc_publish_time: queue ? null : String(publishTimestamp),
        queue_ordering_number: queue ?? null,
      },
    })) as { post: Post };

    return dispatch(
      syncPostData({
        post: updatedPost,
        previousPost: post,
        preventAnimation,
      })
    );
  }
);

export const fetchPostsQuotaThunk = createAppAsyncThunk<
  PostQuotaResponse,
  undefined
>("posts/fetchPostsQuotaThunk", async () => fetchPostsQuota());
