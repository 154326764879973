import { message } from "antd";
import Facebook from "assets/icons/facebook-logo-filled.png";
import GoogleBusiness from "assets/icons/google-business.svg";
import InstagramStories from "assets/icons/instagram-stories.svg";
import Instagram from "assets/icons/instagram.svg";
import LinkedIn from "assets/icons/linkedin.svg";
import Pinterest from "assets/icons/pinterest.svg";
import TikTok from "assets/icons/tiktok-colored.svg";
import Twitter from "assets/icons/x.png";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { POST_ANALYTICS_ROUTE } from "pages/PostAnalytics/postAnalyticsFixtures";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { POST_CALENDAR_ROUTE } from "pages/Posts/utils/postCalendarFixtures";
import pluralize from "pluralize";
import {
  MdBorderAll,
  MdBusiness,
  MdCalendarMonth,
  MdMemory,
  MdOutlineColorLens,
  MdOutlineGridView,
  MdOutlineImage,
  MdOutlinePeople,
  MdOutlinePermMedia,
  MdOutlineSupervisedUserCircle,
  MdOutlineTextSnippet,
  MdStorefront,
  MdWebAsset,
} from "react-icons/md";

// TODO: Align/use this with sidebar nav, source of truth for nav label and path
export const NAV_ITEMS = [
  {
    title: "Posts",
    pathname: POSTS_PAGE_ROUTE,
    Icon: MdOutlineImage,
  },
  {
    title: "Calendar",
    pathname: "/calendar",
    Icon: MdCalendarMonth,
  },
  {
    title: "Topics",
    pathname: "/topics",
    Icon: MdOutlineTextSnippet,
  },
  {
    title: "Business",
    pathname: "/business-profile",
    Icon: MdStorefront,
  },
  {
    title: "Media",
    pathname: "/library-media",
    Icon: MdOutlinePermMedia,
  },
  {
    title: "Branding",
    pathname: "/branding",
    Icon: MdOutlineColorLens,
  },
  {
    title: "Socials",
    pathname: INTEGRATIONS_ROUTE,
    Icon: MdOutlineGridView,
  },
  {
    title: "Team",
    pathname: "/team",
    Icon: MdOutlinePeople,
  },
  {
    title: "Clients",
    pathname: "/clients",
    Icon: MdOutlineSupervisedUserCircle,
  },
  {
    title: "Themes",
    pathname: "/themes",
    Icon: MdBorderAll,
  },
  {
    title: "Memory",
    pathname: "/memory",
    Icon: MdMemory,
  },
];

export const AGENCY_NAV_ITEMS = [
  {
    title: "Businesses",
    pathname: "/dashboard",
    Icon: MdBusiness,
  },
  {
    title: "Team",
    pathname: "/agency-team",
    Icon: MdOutlinePeople,
  },
  {
    Icon: MdWebAsset,
    title: "Whitelabel",
    pathname: "/white-label",
  },
];

export const AGENCY_ROUTES = ["/dashboard", "/white-label", "/agency-team"];

export const BUSINESS_ROUTES = [
  POST_ANALYTICS_ROUTE,
  POSTS_PAGE_ROUTE,
  POST_CALENDAR_ROUTE,
  "/topics",
  "/library",
  INTEGRATIONS_ROUTE,
  "/business",
  "/team",
  "/clients",
  "/branding",
];

export const INTEGRATION_ITEMS = [
  {
    integrationType: "facebook",
    icon: Facebook,
    title: "Facebook Page",
  },
  {
    integrationType: "instagram",
    icon: Instagram,
    title: "Instagram",
  },
  {
    integrationType: "instagramStory",
    icon: InstagramStories,
    title: "Instagram Story",
  },
  {
    integrationType: "twitter",
    icon: Twitter,
    title: "X (Twitter)",
  },
  {
    integrationType: "linkedIn",
    icon: LinkedIn,
    title: "LinkedIn Company Page",
  },
  {
    integrationType: "linkedInProfile",
    icon: LinkedIn,
    title: "LinkedIn Personal Profile",
    isAnalyticsUnavailable: true,
  },
  {
    integrationType: "tiktok",
    icon: TikTok,
    title: "TikTok",
  },
  {
    integrationType: "pinterest",
    icon: Pinterest,
    title: "Pinterest",
  },
  {
    integrationType: "googleBusiness",
    icon: GoogleBusiness,
    title: "Google Business",
  },
] as const;

export const INTEGRATION_ITEMS_DICT = Object.fromEntries(
  INTEGRATION_ITEMS.map((integration) => [
    integration.integrationType,
    integration,
  ])
);

export const HIDDEN_SIDEBAR_ROUTES = [
  "/",
  "/payment-success",
  "/business-metadata",
  "/login",
  "/sign-up",
  "/forgot-password",
  "/reset-password",
  "/privacy-policy",
  "/gdpr-compliance",
  "/terms-of-service",
  "/welcome",
  "/not-found",
  "/logout",
  "/generate-for-my-website",
  "/frill-sso",
  "/checkout",
  "/preview",
];

export const HIDDEN_HEADER_ROUTES = [POST_ANALYTICS_ROUTE, POST_CALENDAR_ROUTE];

export const PUBLIC_ROUTES = [
  "/",
  "/login",
  "/sign-up",
  "/not-found",
  "/logout",
  "/privacy-policy",
  "/terms-of-service",
  "/generate-for-my-website",
  "/forgot-password",
  "/reset-password",
  "/checkout",
];

export const REQUEST_FONT_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLScnFwxOseRnoIDRkbj2a9G90gcjFOCyLDGKhxRd_WiwCy5JDg/viewform?usp=sf_link";

export const DELETE_ACCOUNT_CONFIRMATION =
  "Please note that this action is irreversible, and all your data, posts, and account information will be permanently deleted. You will no longer have access to your account, and any associated content will be lost. If there is an active subscription on this account it will not renew.";

export const INVALID_URL_TOOLTIP =
  "The url you entered doesn’t seem to be valid. Please fix";

export const FACEBOOK_GOOGLE_LOGIN_NOTE =
  "We've detected that you're using the Facebook in-app browser. Please note that Google login is currently not supported in this browser. For the best experience, we recommend opening our app in your device's default browser. Simply copy and paste the link into your browser for seamless Google login. Alternatively, you can use our email/password signup option below. Thank you for your understanding!";

export const INSTAGRAM_GOOGLE_LOGIN_NOTE =
  "We've detected that you're using the Instagram in-app browser. Please note that Google login is currently not supported in this browser. For the best experience, we recommend opening our app in your device's default browser. Simply copy and paste the link into your browser for seamless Google login. Alternatively, you can use our email/password signup option below. Thank you for your understanding!";

export const COLOR_PICKERS = [
  { label: "Brand Color", key: "accent_color" },
  {
    label: "Background Color",
    key: "background_color",
    tooltip: "Choose a subtle color that has high contrast to your text color",
  },
  {
    label: "Text Color",
    key: "text_color",
    tooltip:
      "Text should have high contrast to your brand and background colors and be easily legible.",
  },
];

export const MARKY_FAVICON_URL =
  "https://marky-image-posts.s3.us-east-2.amazonaws.com/marky-favicon.png";

export const CONNECT_IG_GUIDE_LINK =
  "https://help.mymarky.ai/en/article/how-to-connect-an-instagram-account-to-marky-a5jeiz/?bust=1708090816780";

export const MAX_SLIDES_FOR_POST = 8;

export const limitedMediaMessage = () =>
  message.info({
    key: "max-slides-message",
    content: `Due to limitations on certain social platforms, we've restricted to a maximum of ${pluralize("slide", MAX_SLIDES_FOR_POST, true)}.`,
    duration: 7,
  });
