import { limitedMediaMessage, MAX_SLIDES_FOR_POST } from "fixtures/constants";
import { observer } from "mobx-react-lite";
import { AiOutlineDelete } from "react-icons/ai";
import {
  MdContentCopy,
  MdContentPaste,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { PageControlProps } from "../../polotnoTypes";
import ControlButton, { ControlButtonProps } from "./ControlButton";

const PageControls: ObserverComponent<
  PageControlProps & {
    disablePaste: boolean;
    onPaste: () => void;
  }
> = observer(({ xPadding, store, disablePaste, onPaste, yPadding }) => {
  const duplicatePage = () => {
    if (store.pages.length >= MAX_SLIDES_FOR_POST) {
      return limitedMediaMessage();
    }
    store.activePage?.clone();
  };

  const currentPageIndex = (store.pages as { id: string }[]).findIndex(
    ({ id }) => id === store.activePage.id
  );

  const CONTROLS: ControlButtonProps[] = [
    {
      title: "Duplicate page",
      icon: <MdContentCopy className="scale-up" />,
      onClick: duplicatePage,
    },
    {
      title: "Paste",
      icon: <MdContentPaste className="scale-up" />,
      disabled: disablePaste,
      onClick: onPaste,
    },
    {
      title: "Move up",
      icon: <MdKeyboardArrowUp className="scale-up" />,
      disabled: currentPageIndex === 0,
      onClick: () => store.activePage.setZIndex(currentPageIndex - 1),
    },
    {
      title: "Move down",
      icon: <MdKeyboardArrowDown className="scale-up" />,
      disabled: currentPageIndex >= store.pages.length - 1,
      onClick: () => store.activePage.setZIndex(currentPageIndex + 1),
    },
    {
      title: "Remove page",
      icon: <AiOutlineDelete />,
      disabled: store.pages.length === 1,
      onClick: () => store.deletePages([store.activePage.id]),
    },
  ];

  return (
    <div
      className="page-controls flex gap-1.5 absolute top-5"
      style={{
        // 30px for button height + 10px for margin
        top: yPadding - 40,
        right: xPadding,
      }}
    >
      {CONTROLS.map((props, index) => (
        <ControlButton key={index} {...props} />
      ))}
    </div>
  );
});

export default PageControls;
