import { Alert, Button } from "antd";
import { useUpdateCheck } from "react-update-notification";
import { UpdateStatus } from "react-update-notification/lib/types";

const NewVersionBanner = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 60000,
  });

  return status === UpdateStatus.available ? (
    <Alert
      type="info"
      description="A new version of the app is available. Refresh the app to update and enjoy the latest features."
      className="[grid-area:banner] py-2 items-center [&_.ant-alert-content]:flex [&_.ant-alert-content]:gap-4 [&_.ant-alert-content]:items-center [&_.ant-alert-message]:!mb-0"
      action={
        <Button onClick={reloadPage} className="ml-auto h-8">
          Refresh
        </Button>
      }
      showIcon={false}
      banner
    />
  ) : null;
};

export default NewVersionBanner;
