import axios from "api/config/axiosConfig";
import { isAxiosError } from "axios";

export const fetchThemes = async () => {
  try {
    const response = await axios.get<Theme[]>("/themes?approved=true");
    return response.data;
  } catch (error) {
    const message = isAxiosError(error)
      ? error.response?.data.message || error.response?.data.Message
      : "Unable to fetch themes, please try again.";
    throw new Error(message);
  }
};

export type Theme = {
  id: string;
  created_at: string;
  display_name: string;
  name: string;
  image_url: string;
  description: string;
  approved: boolean;
  preselected: boolean;
};
