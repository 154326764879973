import TourModal, { TourModalData } from "components/TourModal";
import useAppContext from "config/AppContext/useAppContext";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";

const POST_TOURS: Record<string, TourModalData> = {
  integrations: {
    title: "Connect Socials",
    description:
      "Automatic posting is currently disabled. To enable it, connect one or more socials.",
    btnText: "Connect Socials",
    pathname: INTEGRATIONS_ROUTE,
  },
  book: {
    title: "Get better posts",
    description:
      "If you want better posts, edit the AI-generated content under the Content page found in the navigation menu",
  },
};

const PostTour = () => {
  const { realm } = useAppContext();
  const { scheduled } = useAppSelector(selectPostStore);
  const { currentBusiness } = useAppSelector((state) => state.user);
  const [tourData, setTourData] = useState<TourModalData | null>(null);

  useEffect(() => {
    if (window.innerWidth > 900) {
      return;
    }

    if (
      realm.isMarky &&
      !currentBusiness.integrations.length &&
      scheduled.count &&
      scheduled.count % 3 === 0
    ) {
      setTourData(POST_TOURS.integrations);
    }
  }, [currentBusiness.integrations, scheduled.count]);

  return (
    tourData && <TourModal data={tourData} close={() => setTourData(null)} />
  );
};

export default PostTour;
