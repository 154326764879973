import cx from "classnames";
import { MdCheckCircleOutline, MdRadioButtonUnchecked } from "react-icons/md";

import getPasswordErrorMessage from "utils/getPasswordErrorMessage";

const PasswordRequirements = ({ password }: { password: string }) => {
  if (!password) {
    return null;
  }

  return (
    <div className="auth__password-requirements">
      <span className="auth__error-message">
        Password must meet the following requirements:
      </span>
      <ul>
        {Object.entries(getPasswordErrorMessage(password)).map(
          ([key, value], i) => {
            return (
              <div
                className={cx("auth__password-requirements__requirement", {
                  "auth__password-requirements__requirement--fulfilled": value,
                })}
                key={i}
              >
                {value ? <MdCheckCircleOutline /> : <MdRadioButtonUnchecked />}
                <span>{key}</span>
              </div>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default PasswordRequirements;
