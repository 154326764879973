import UserDetailsCard from "./components/UserDetailsCard/UserDetailsCard";

const ManageAccount = () => {
  return (
    <div className="manage-account-container">
      <UserDetailsCard />
    </div>
  );
};

export default ManageAccount;
