import PhoneInput, {
  DefaultInputComponentProps,
  Props as PhoneInputProps,
} from "react-phone-number-input";

const PhoneNumberInput = ({
  showLabel,
  ...inputProps
}: PhoneInputProps<DefaultInputComponentProps> & {
  showLabel?: boolean;
}) => (
  <div>
    {showLabel && <label htmlFor="phone">Phone Number</label>}
    <PhoneInput
      {...inputProps}
      className="border border-antd-colorBorder rounded-md overflow-hidden h-10 gap-1"
      name="phone"
      countryCallingCodeEditable={false}
      withCountryCallingCode
      international
    />
  </div>
);

export default PhoneNumberInput;
