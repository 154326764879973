import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";

const LoadingIndicator = ({
  children: message,
  className,
}: {
  children?: string;
  className?: string;
}) => (
  <div
    className={classNames("text-antd-colorIcon text-xs flex gap-2", className)}
  >
    {message}
    <LoadingOutlined />
  </div>
);

export default LoadingIndicator;
