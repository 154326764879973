import { Button } from "antd";
import { TbServerOff } from "react-icons/tb";

const ServerDownMessage = () => (
  <div className="[grid-area:content] w-full flex flex-col justify-center items-center gap-2 p-5 pb-20 text-xl">
    <TbServerOff className="mt-auto" size={60} />
    <p className="text-center max-w-lg">
      We apologize, but it seems our app is currently experiencing technical
      difficulties. You can try resolving this by logging out of your account
      and logging back in. If the issue persists, kindly contact us. We
      appreciate your patience and understanding.
    </p>
    <div className="mt-auto flex gap-2">
      <Button type="primary" onClick={() => window.location.reload()}>
        Reload
      </Button>
      <Button
        onClick={() => {
          localStorage.clear();
          window.location.pathname = "/logout";
        }}
      >
        Logout
      </Button>
    </div>
  </div>
);

export default ServerDownMessage;
