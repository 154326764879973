import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

const BusinessLoader = ({
  complete,
}: {
  showFooter?: boolean;
  complete?: boolean;
}) => {
  const [seconds, setSeconds] = useState(0);
  const [isComplete, setIsComplete] = useState(complete ?? false);

  const loadingTexts = useMemo(
    () => [
      {
        text: "Understanding your business",
        isCompleted: seconds >= 5,
      },
      {
        text: "Determining target audience",
        isCompleted: seconds >= 10,
      },

      {
        text: "Building your brand",
        isCompleted: seconds >= 15,
      },
      {
        text: "Creating content topics for your posts",
        isCompleted: seconds >= 30,
      },
      {
        text: "Optimizing your hashtags",
        isCompleted: false,
      },
    ],
    [seconds]
  );

  useEffect(() => {
    if (complete) {
      setIsComplete(true);
    }
  }, [complete]);

  useEffect(() => {
    const secondsPast = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(secondsPast);
    };
  }, []);

  return (
    <div className="!fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-[9999] bg-antd-colorBgLayout p-4">
      <div className="max-w-sm space-y-6">
        <div className="space-y-1">
          <h4 className="text-2xl font-semibold">Generating your profile...</h4>
          <h6>
            Marky is building a unique profile for your business. Hang tight,
            this could take 20-30 seconds.
          </h6>
        </div>
        <div className="space-y-5">
          {loadingTexts.map((item, index) => (
            <div key={index} className="flex justify-between items-center">
              <span className="text-sm font-medium">{item.text}</span>
              {isComplete || item.isCompleted ? (
                <BsFillCheckCircleFill className="text-antd-colorSuccess size-5" />
              ) : (
                <LoadingOutlined />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessLoader;
