import { setAuthToken } from "api/config/axiosConfig";
import Cookies from "js-cookie";
import {
  TypesafeChaliceUser,
  TypesafeUserResponse,
} from "store/user/userActions";
import { SignupCredentials } from "store/user/userConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { chaliceClient, djangoClient } from "./baseClients";
import { Palette, Paths, Realm, SocialLinks } from "./config/chalice-api";

export type LoginResponse = {
  user: TypesafeChaliceUser;
  token: string;
};

let controller: AbortController;

export const fetchUserDetails = () =>
  chaliceClient.get<TypesafeUserResponse>("/users");

export const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  chaliceClient.post<LoginResponse>(`/users/login`, {
    email,
    password,
  });

export const signup = async (
  args?: Partial<SignupCredentials> & { realmId?: string },
  isSelf = true
) => {
  const { email, password, firstName, lastName, realmId } = args || {};
  const response = await chaliceClient.post<LoginResponse>(
    `/users/signup`,
    {
      email,
      password,
      given_name: firstName,
      family_name: lastName,
      realm_id: realmId,
    },
    {
      params: {
        _fprom_tid: Cookies.get("_fprom_tid"),
      },
    }
  );
  if (isSelf) {
    // TODO: remove the setAuthToken in favor or just storing token in localStorage
    const token = response.token as string;
    setAuthToken(token);
    localStorage.setItem("token", token);

    trackEvent("user_created");
    if (email) {
      trackEvent("sign_up");
    }
  }

  return response;
};

export const claimAnonymousUser = (body: { code: string }) =>
  chaliceClient.post<LoginResponse>("/users/google-claim", body);

export const googleLogin = (code: string) =>
  chaliceClient.post<LoginResponse>("/users/google-login", { code });

export const googleSignup = (code: string) =>
  chaliceClient.post<LoginResponse>(
    "/users/google-signup",
    {
      code,
    },
    {
      params: {
        _fprom_tid: Cookies.get("_fprom_tid"),
      },
    }
  );

export const updateUser = async (
  body: Paths.V1UpdateUser.RequestBody,
  signal: AbortSignal | null = null,
  token?: string | null
) => {
  if (controller) {
    controller.abort();
  }

  if (!signal) {
    controller = new AbortController();
  }

  return chaliceClient.put<TypesafeChaliceUser>(`/users`, body, {
    signal: signal || controller.signal,
    // for reset password
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });
};

export const deleteUser = () => chaliceClient.delete("/users");

export type ScrapedData = {
  content?: string;
  social_links?: SocialLinks;
  truncated?: boolean;
  truncated_limit?: number;
  content_size?: number;
  logo_url?: string;
  palette?: Palette;
  image_urls?: string[];
};

export const omniboxRequest = ({
  url,
  crawl,
}: {
  url: string;
  crawl: boolean;
}) =>
  chaliceClient.get<ScrapedData>("/omnibox/scrape", {
    params: { crawl, url },
  });

export const forgotPassword = (email: string) =>
  chaliceClient.post("/users/forgot-password", {
    email,
  });

export const getFrillSSOToken = () =>
  chaliceClient.get<Paths.V1GetFrillToken.Responses.$200>("/users/frill-token");

export const searchUserByEmail = (email: string, realmId?: string) =>
  chaliceClient.get<{ user: TypesafeChaliceUser }>("/users/search-by-email", {
    params: {
      email,
      realm_id: realmId,
    },
  });

export const updateRealm = async (body: Realm) => {
  const response = await chaliceClient.put<Paths.V1RealmCreate.Responses.$200>(
    `/realms/${body.id}`,
    body
  );
  return response.realm;
};

export const fetchRealm = async (id: string) => {
  const response = await chaliceClient.get<Paths.V1RealmGet.Responses.$200>(
    `/realms/${id}`
  );
  return response.realm;
};

export const trackSubscription = ({
  email,
  phone = "",
}: {
  email: string;
  phone?: string;
}) =>
  djangoClient.v1_facebook_track_purchase_create(null, {
    email,
    phone,
    client_user_agent: navigator.userAgent,
    client_ip_address: "",
  });

export const getOTC = (userId: string) =>
  chaliceClient.post<Paths.V1GenerateOtc.Responses.$200>(
    "/users/generate-otc",
    {
      user_id: userId,
    }
  );

export const getOTCForClientPortal = ({
  userId,
  businessId,
}: {
  userId: string;
  businessId: string;
}) =>
  chaliceClient.post<Paths.V1GenerateOtc.Responses.$200>(
    "/users/generate-client-preview-otc",
    {
      business_id: businessId,
      client_user_id: userId,
    }
  );

export const getPreviewToken = (otc: string) =>
  chaliceClient.post<Paths.V1ExchangeOtc.Responses.$200>(
    `/users/exchange-otc/${otc}`
  );
