import { Empty } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";

const LOADING_TEXTS = [
  "Creating posts",
  "Double click on a post to edit it",
  "You can upload your own photo to use in a post",
  "Optimizing for engagement",
  "We have 100's of designs to choose from. Choose your theme(s) under 'Branding' tab",
  "Finding hashtags",
  "Request your own designs to be added! Reach out to us.",
];

const GeneratedPostsEmptyScreen = ({
  isApplyingBrandingChanges,
}: {
  isApplyingBrandingChanges: boolean;
}) => {
  const {
    generated: { isGenerating: isGenerating },
  } = useAppSelector(selectPostStore);

  const [currentLoadingTextIndex, setCurrentLoadingTextIndex] = useState(-1);

  const loadingText = useMemo(() => {
    if (currentLoadingTextIndex === -1) {
      return "Please wait, we're crafting your posts...";
    }

    return LOADING_TEXTS[currentLoadingTextIndex];
  }, [currentLoadingTextIndex]);

  const description = useMemo(() => {
    if (isApplyingBrandingChanges) {
      return "Please wait, we're applying branding changes to your posts...";
    }

    return isGenerating
      ? loadingText
      : "You've reached the end of available posts.";
  }, [isGenerating, loadingText, isApplyingBrandingChanges]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLoadingTextIndex((prevValue = -1) =>
        prevValue >= 3 ? 0 : prevValue + 1
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const showAnimation = isGenerating || isApplyingBrandingChanges;

  return (
    <Empty
      className={classNames({ "my-auto": showAnimation })}
      image={
        showAnimation ? (
          <div className="flex justify-center">
            <div
              className="size-[60px] bg-antd-colorPrimary"
              style={{ animation: "flipX 1s linear infinite" }}
            />
          </div>
        ) : undefined
      }
      description={description}
    />
  );
};

export default GeneratedPostsEmptyScreen;
