import { message } from "antd";
import { getPresignedUrl } from "api/postsApi";
import TooltipImage from "assets/images/avatar-tooltip.png";
import axios from "axios";
import ImageUploader from "components/Common/ImageUploader";
import { genericError } from "fixtures/globalConstants";
import { ChangeEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import addStaticCacheBusterParam from "utils/addNoCacheParam";
import BrandingImageTooltip from "./BrandingImageTooltip";
import { UploadComponentProps } from "./uploadConstants";

const AvatarUpload = ({
  isNewClient,
  isRecreatingAccount,
  canEditBusiness,
}: UploadComponentProps) => {
  const { pathname } = useLocation();

  const { avatar = null } = useAppSelector(
    (state) => state.user.currentBusiness.brand
  );
  const dispatch = useAppDispatch();

  const [isUploadingLogo, setIsUploadingLogo] = useState(false);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploadingLogo(false);
    try {
      if (!e.target.files) {
        return;
      }

      setIsUploadingLogo(true);
      const file = e.target.files[0];
      const { url } = await getPresignedUrl(file);
      await axios.put(url, file, {
        headers: {
          "Content-Type": file?.type,
        },
      });
      const link = url.split("?")[0];

      if (pathname.includes("metadata")) {
        localStorage.setItem("avatarURL", link);
        setIsUploadingLogo(false);
        return;
      }

      if (isNewClient) {
        localStorage.setItem("clientLogo", link);
      } else {
        await dispatch(
          updateBusinessDetails({
            avatar: link,
          })
        );
      }

      if (isRecreatingAccount) {
        localStorage.setItem("avatar", link);
      }
    } catch (error) {
      genericError(error);
    }
    setIsUploadingLogo(false);
  };

  const removeImage = async () => {
    if (pathname.includes("metadata")) {
      localStorage.removeItem("avatarURL");
      return;
    }

    setIsUploadingLogo(true);
    try {
      await dispatch(
        updateBusinessDetails({
          avatar: null,
        })
      );
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    }
    setIsUploadingLogo(false);
  };

  if (!canEditBusiness) {
    return avatar ? (
      <div className="avatar-container">
        <h5 className="your-brand__section__heading mb-2.5">Headshot</h5>
        <div className="logo-container logo-container--circle">
          <img src={addStaticCacheBusterParam(avatar)} className="logo-image" />
        </div>
      </div>
    ) : null;
  }

  return (
    <div className="avatar-container">
      <h5 className="your-brand__section__heading mb-2.5">
        <BrandingImageTooltip
          description="The headshot is used in some posts to show the face of the company. If
          there is no face of the company, just use your logo for the headshot.
          See the following image for an example."
          image={TooltipImage}
        >
          Headshot
        </BrandingImageTooltip>
      </h5>
      <ImageUploader
        handleChange={handleUpload}
        isLoading={isUploadingLogo}
        handleRemove={removeImage}
        image={avatar}
        name="avatar-1"
        isCircle
      />
    </div>
  );
};

export default AvatarUpload;
