import {
  createBusiness,
  deleteBusiness,
  inferBusinessDetails,
  updateBusiness,
} from "api/businesses";
import { UpdateBusinessBody } from "api/config/chalice-api";
import { createAppAsyncThunk } from "store/storeHelpers";
import { removeBusinessById } from "store/user/userSlice";
import { CreateBusinessPayload } from "./businessConstants";

export const createNewBusiness = createAppAsyncThunk(
  "integrations/createNewBusiness",
  async (payload: CreateBusinessPayload) => {
    const { details, topics } = await inferBusinessDetails({
      content: payload.summary,
    });
    return createBusiness({
      ...details,
      ...payload,
      topics: topics || [],
    });
  }
);

export const updateBusinessDetails = createAppAsyncThunk(
  "integrations/updateBusinessDetails",
  async (data: UpdateBusinessBody & { id?: string }, { getState }) => {
    const businessId = data.id || getState().user.currentBusiness.id;
    return updateBusiness(businessId, data);
  }
);

export const deleteCurrentBusiness = createAppAsyncThunk(
  "integrations/deleteCurrentBusiness",
  async (_arg: undefined, { getState, dispatch }) => {
    const businessId = getState().user.currentBusiness.id;
    return dispatch(deleteBusinessById(businessId));
  }
);

export const deleteBusinessById = createAppAsyncThunk(
  "integrations/deleteBusinessById",
  async (businessId: string, { dispatch }) => {
    await deleteBusiness(businessId);
    dispatch(removeBusinessById(businessId));
  }
);
