import { RefinerEvent } from "./fixtures/refinerEventsFixture";

/** Global tracking event key, not necessarily tied to any tracker */
export type TrackingEventKey =
  | "updated_business_content"
  | "created_business"
  | "upgraded_wl_tier"
  | "invited_team_member"
  | "invited_client"
  | "added_memory"
  | "generated_posts"
  | "used_post_editor"
  | "updated_topics"
  | "created_custom_post"
  | "user_created"
  | "added_social"
  | "generator_panel"
  | "new_post_decided"
  | "sign_up"
  | "tutorial_complete"
  | "view_item_list"
  | "begin_checkout"
  | "purchase"
  | "user_data"
  | "experiment_viewed"
  | "scheduled_post"
  | "liked_post"
  | "deleted_post"
  | "published_post"
  | "added_images"
  | "added_comment"
  | "marked_post_ready_for_review"
  | "refiner_launch_survey"
  | "attempted_calendar_event_drag";

const DEFAULT_EVENT_MAPPING = {
  fbqEvent: undefined,
  gtmEvent: undefined,
  refinerEvent: undefined,
  ttqEvent: undefined,
};

// RAW_EVENT_MAPPING_DICT is an extension of type EventMapping, but it needs to be interpretted literally
// to enforce params in trackEvent. Do not type as EventMapping.
const RAW_EVENT_MAPPING_DICT = {
  created_business: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Created Business",
    gtmEvent: "created_business",
  },
  upgraded_wl_tier: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "upgraded_wl_tier",
  },
  created_custom_post: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "created_custom_post",
  },
  added_images: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Added Images",
    gtmEvent: "added_images",
  },
  invited_team_member: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Invited Team Member",
    gtmEvent: "invited_team_member",
  },
  invited_client: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Invited Client",
    gtmEvent: "invited_client",
  },
  used_post_editor: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "used_post_editor",
  },
  updated_topics: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "updated_topics",
  },
  updated_business_content: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "updated_business_content",
  },
  user_created: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "user_created",
  },
  added_social: { ...DEFAULT_EVENT_MAPPING, gtmEvent: "added_social" },
  added_memory: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "added_memory",
    refinerEvent: "Added Memory",
  },
  generated_posts: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "generated_posts",
    refinerEvent: "Used 'Generate More Posts' Button",
  },
  generator_panel: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "generator_panel",
    refinerEvent: "Used Generator Panel",
  },
  new_post_decided: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "new_post_decided",
  },
  sign_up: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "sign_up",
    ttqEvent: "CompleteRegistration",
  },
  tutorial_complete: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "tutorial_complete",
  },
  view_item_list: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "view_item_list",
  },
  begin_checkout: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "begin_checkout",
  },
  purchase: {
    ...DEFAULT_EVENT_MAPPING,
    fbqEvent: "Purchase",
    gtmEvent: "purchase",
    ttqEvent: "CompletePayment",
  },
  user_data: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "userData",
  },
  experiment_viewed: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "experiment_viewed",
  },
  scheduled_post: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Scheduled Post",
  },
  liked_post: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Liked Post",
  },
  deleted_post: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Deleted Post",
  },
  published_post: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Published Post",
  },
  added_comment: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "New Comment",
  },
  marked_post_ready_for_review: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "Marked Ready for Review",
  },
  refiner_launch_survey: {
    ...DEFAULT_EVENT_MAPPING,
    refinerEvent: "launch_survey",
  },
  attempted_calendar_event_drag: {
    ...DEFAULT_EVENT_MAPPING,
    gtmEvent: "attempted_calendar_event_drag",
  },
} as const;

export type EventMapping = typeof RAW_EVENT_MAPPING_DICT;

/**
 * Shorthand event keys:
 * fbqEvent - Facebook Pixel
 * gtmEvent - Google Tag Manager
 * ttqEvent - TikTok Pixel
 */
type GenericEventMapping = {
  [key in TrackingEventKey]: {
    fbqEvent: MetaPixelEvent | undefined;
    gtmEvent: GtmEvent | undefined;
    refinerEvent: RefinerEvent | undefined;
    ttqEvent: TikTokEvent | undefined;
  };
};

// This type conversion will error out if RAW_EVENT_MAPPING_DICT violates some constraints of EventMapping
export const EVENT_MAPPING_DICT = RAW_EVENT_MAPPING_DICT as GenericEventMapping;
