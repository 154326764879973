import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Input, Radio, Slider, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import LanguageSelect from "components/Common/LanguageSelect";
import PhoneNumberInput from "components/Common/PhoneNumberInput";
import { SectionItem } from "pages/NewBusinessOnboarding/businessFormConstants";
import { useCallback } from "react";
import { Value } from "react-phone-number-input";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import useBusinessCard from "./useBusinessCard";

// TODO: File needs refactor, use same component that's used in onboarding, remove reliance on useBusinessCard hook and then delete the hook
// Converted to TS so that could catch errors related to changing type of `items`
// Did not fix code otherwise, not in scope

type PhoneInputType = Value | string | undefined;

type BusinessCardBodyProps = {
  items: SectionItem[];
} & ReturnType<typeof useBusinessCard>;

const BusinessCardBody = ({
  sectionFields,
  isEditing,
  items,
  phone,

  setPhone,
  removeArrayItem,
  handleChange,
  addNewItem,
}: BusinessCardBodyProps) => {
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const getMetaData = useCallback(
    (field: SectionItem["field"], prefix = "") => {
      if (["ctas", "writing_samples"].includes(field)) {
        return (
          <div>
            {Array.isArray(sectionFields[field]) && sectionFields[field].length
              ? (sectionFields[field] as string[])?.map((item, index) => (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ))
              : "-"}
          </div>
        );
      } else if (field === "captionLength") {
        return (
          <div>
            {currentBusiness.min_caption_length / 100}-
            {currentBusiness.max_caption_length / 100}
          </div>
        );
      } else if (field.includes("level")) {
        const valueMapping: Record<string, string> = {
          1: "Low",
          2: "Mid",
          3: "High",
        };
        return <div>{valueMapping[sectionFields[field] as string] || "-"}</div>;
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html:
                prefix +
                  ((sectionFields[field] as string)?.replace(/\n/g, "<br />") ||
                    "") || "-",
            }}
          />
        );
      }
    },
    [sectionFields]
  );

  if (isEditing) {
    return (
      <main>
        {items.map(
          (item, index) =>
            !item.hideFromBusinessPage && (
              <div
                className={`your-book__metadata__block your-book__metadata__block--${item.field}`}
                key={index}
              >
                <div className="your-book__metadata__block__label">
                  <label htmlFor={item.field}>{item.label}</label>
                  {item.tooltip && (
                    <div className="flex">
                      <Tooltip title={item.tooltip}>
                        <InfoCircleOutlined
                          style={{
                            color: "#171717",
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                {item.type === "textarea" && (
                  <TextArea
                    placeholder={item.placeholder}
                    value={sectionFields[item.field] as string}
                    onChange={(e) => handleChange(e.target.value, item.field)}
                    autoSize={{ minRows: 1, maxRows: 15 }}
                    maxLength={400}
                  />
                )}
                {item.type === "text" && (
                  <Input
                    type="text"
                    placeholder={item.placeholder}
                    value={sectionFields[item.field] as string}
                    onChange={(e) => handleChange(e.target.value, item.field)}
                    addonBefore={item.prefix}
                    maxLength={400}
                  />
                )}
                {item.type === "languageSelect" && (
                  <LanguageSelect
                    handleChange={(value: string) =>
                      handleChange(value, item.field)
                    }
                    currentValue={sectionFields[item.field] as string}
                  />
                )}
                {item.type === "array" && (
                  <>
                    {(sectionFields[item.field] as string[])?.map(
                      (elem, index) => (
                        <div key={index} className="flex mb-3 gap-3">
                          {item.field === "ctas" && (
                            <Input
                              value={elem}
                              onChange={(e) =>
                                handleChange(e.target.value, item.field, index)
                              }
                              maxLength={400}
                            />
                          )}
                          {item.field === "writing_samples" && (
                            <TextArea
                              value={elem}
                              onChange={(e) =>
                                handleChange(e.target.value, item.field, index)
                              }
                              autoSize={{ minRows: 1, maxRows: 15 }}
                              maxLength={300}
                            />
                          )}
                          <MinusCircleOutlined
                            onClick={() => removeArrayItem(item.field, index)}
                            className="cursor-pointer text-antd-red"
                          />
                        </div>
                      )
                    )}
                    <Button
                      onClick={() => addNewItem(item.field)}
                      disabled={
                        item.field === "writing_samples" &&
                        (sectionFields[item.field]?.length ?? 0) >= 5
                      }
                    >
                      Add New
                    </Button>
                  </>
                )}
                {item.type === "slider" && (
                  <Slider
                    value={[
                      // @ts-expect-error sectionFields bad typing
                      sectionFields.min_caption_length / 100,
                      // @ts-expect-error sectionFields bad typing
                      sectionFields.max_caption_length / 100,
                    ]}
                    range
                    step={1}
                    min={1}
                    max={10}
                    onChange={([min, max]) => {
                      handleChange(min * 100, "min_caption_length");
                      handleChange(max * 100, "max_caption_length");
                    }}
                  />
                )}
                {item.type === "radio" && (
                  <Radio.Group
                    onChange={(e) => handleChange(e.target.value, item.field)}
                    value={sectionFields[item.field]}
                  >
                    <Radio.Button value={1}>Low</Radio.Button>
                    <Radio.Button value={2}>Mid</Radio.Button>
                    <Radio.Button value={3}>High</Radio.Button>
                  </Radio.Group>
                )}
                {item.type === "phone" && (
                  <PhoneNumberInput
                    onChange={setPhone as (phone: PhoneInputType) => void}
                    value={phone as PhoneInputType}
                  />
                )}
              </div>
            )
        )}
      </main>
    );
  }

  return (
    <main>
      {items.map(
        ({ field, label, warning, prefix, hideFromBusinessPage }, i) =>
          !hideFromBusinessPage && (
            <div
              key={i}
              className={`your-book__section__main__item your-book__section__main__item--${field}`}
            >
              <div className="flex items-center">
                <span className="your-book__section__main__item__label">
                  {label}
                </span>
                {!sectionFields[field] && warning && (
                  <Tooltip title={warning}>
                    <WarningOutlined style={{ marginLeft: 7 }} />
                  </Tooltip>
                )}
              </div>
              <div className="your-book__section__main__item__value">
                {getMetaData(field, prefix) || "-"}
              </div>
            </div>
          )
      )}
    </main>
  );
};

export default BusinessCardBody;
