import { Button, Modal } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";
import { updateUserDetails } from "store/user/userActions";
import { selectUserInfo } from "store/user/userSlice";

const NewPostGenerationsModal = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { postLimitInfo } = useAppSelector(selectPostStore);
  const user = useAppSelector(selectUserInfo);

  const closeModal = () => {
    dispatch(updateUserDetails({ show_posts_refilled_modal_after: null }));
    setIsOpen(false);
  };

  useEffect(() => {
    if (
      user.show_posts_refilled_modal_after &&
      dayjs().isAfter(dayjs.utc(user.show_posts_refilled_modal_after)) &&
      postLimitInfo?.count_generated_posts === 0
    ) {
      setIsOpen(true);
    }
  }, [
    user.show_posts_refilled_modal_after,
    postLimitInfo?.count_generated_posts,
  ]);

  return (
    <Modal
      open={isOpen}
      centered
      title="Great news, your post generations have been refilled! 🎉"
      footer={
        <Button type="primary" onClick={closeModal}>
          Let's Go!
        </Button>
      }
      onCancel={closeModal}
    >
      You now have {postLimitInfo?.count_allowed_posts ?? "unlimited"} post
      generations. Are you ready to create amazing content and share your brand
      with the world?
    </Modal>
  );
};

export default NewPostGenerationsModal;
