import axios from "axios";

export const getFileType = (url: string) => url.split(".").pop();

export const downloadFile = async (
  url: string | null | undefined,
  fileName: string
) => {
  if (!url) {
    throw Error("No URL provided for download.");
  }

  const blob = await fetchMediaBlob(url);
  const link = document.createElement("a");
  link.download = `${fileName}.${getFileType(url)}`;
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fetchMediaBlob = async (url: string) => {
  const response = await axios.get(url, {
    responseType: "blob",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    },
  });
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  return blob;
};
