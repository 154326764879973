import { CheckboxOptionType, message, Tooltip } from "antd";
import { Type } from "api/config/chalice-api";
import cx from "classnames";
import CheckboxDropdown from "components/Common/CheckboxDropdown";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { isEqual, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "store";
import { updatePostThunk } from "store/posts/postActions";
import { Post } from "store/posts/postConstants";
import { IntegrationType } from "store/user/userConstants";
import { integrationsTypeGetter } from "store/user/userSlice";
import { formatSocialName } from "utils/generalUtils";

const ALL_SOCIALS_OPTION = {
  value: "all-socials",
  label: "All Socials",
};

const SocialsSelect = ({
  post,
  setPublishTo,
}: {
  post?: Post;
  setPublishTo?: (publishTo: IntegrationType[] | null) => void;
}) => {
  const { hasPermission } = useUserPermissions();

  const dispatch = useAppDispatch();
  const connectedSocials = useAppSelector(integrationsTypeGetter) as string[];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([] as string[]);

  const setSelectionToDefault = () => {
    if (
      !post?.publish_to ||
      !connectedSocials.length ||
      post.publish_to.length === connectedSocials.length
    ) {
      setSelectedOptions(connectedSocials.concat(ALL_SOCIALS_OPTION.value));
    } else {
      setSelectedOptions(post.publish_to);
    }
  };

  useEffect(() => {
    setSelectionToDefault();
  }, [post?.publish_to]);

  const getValidSelections = (selections: string[]) =>
    selections.filter((value) => value !== ALL_SOCIALS_OPTION.value) as Type[];

  const validSelectedSocials = useMemo(
    () => getValidSelections(selectedOptions),
    [selectedOptions]
  );

  const allOptions = useMemo(
    () =>
      [
        {
          ...ALL_SOCIALS_OPTION,
          disabled: !connectedSocials.length,
        } as CheckboxOptionType<string>,
      ].concat(
        connectedSocials.map((social) => ({
          value: social,
          label: formatSocialName(social),
        }))
      ),
    [connectedSocials]
  );

  const updateSocials = async () => {
    if (
      isEqual(sortBy(validSelectedSocials), sortBy(post?.publish_to)) ||
      !connectedSocials.length
    ) {
      return;
    }
    setUpdateLoading(true);

    // TODO: SWE-1857 allow the FE to set publish_to to `null` to indicate that all socials should be
    // published to
    // const publishToList = allSelected ? null : validCheckedSocials;
    const publishToList = validSelectedSocials;

    if (!post) {
      setPublishTo && setPublishTo(publishToList);
    } else {
      try {
        await dispatch(
          updatePostThunk({
            post,
            body: {
              publish_to: publishToList,
            },
          })
        );
      } catch (error) {
        // `update` function already shows an error message to user
        console.error(error);
        setSelectionToDefault();
      }
    }

    setUpdateLoading(false);
  };

  const allOptionsSelected = useMemo(
    () => selectedOptions.length === allOptions.length,
    [selectedOptions, allOptions]
  );

  const handleSelectionChanged = (selected: string[]) => {
    const validSelectons = getValidSelections(selected);

    if (!validSelectons.length) {
      message.info({
        key: "minimum-selection",
        content: "At least one social must be selected.",
      });
      return;
    }

    const hasSelectAll = selected.includes(ALL_SOCIALS_OPTION.value);

    if (
      !allOptionsSelected &&
      (hasSelectAll || validSelectons.length === connectedSocials.length)
    ) {
      setSelectedOptions(allOptions.map(({ value }) => value));
    } else if (!hasSelectAll && allOptionsSelected && connectedSocials.length) {
      if (connectedSocials.length === 1) {
        // Unchecking 'Select All' when only one social available: no effect
        return;
      }
      const newChecked =
        !post?.publish_to || post.publish_to.length === connectedSocials.length
          ? [connectedSocials[0]]
          : post.publish_to;
      setSelectedOptions(newChecked);
    } else {
      setSelectedOptions(validSelectons);
    }
  };

  const selectedSocialsText = useMemo(() => {
    if (
      (!validSelectedSocials.length || validSelectedSocials.length > 1) &&
      allOptionsSelected
    ) {
      return "all socials";
    } else if (validSelectedSocials.length > 1) {
      return `${validSelectedSocials.length} socials`;
    }
    return formatSocialName(validSelectedSocials[0]);
  }, [validSelectedSocials, allOptionsSelected]);

  return hasPermission("post_status_schedule_write") ? (
    <CheckboxDropdown
      disabled={updateLoading}
      onOpenChange={(open, trigger) => {
        !open && updateSocials();
        trigger.source === "trigger" && setIsDropdownOpen(open);
      }}
      className="flex items-center gap-1 text-antd-colorInfo cursor-pointer"
      checkboxGroupProps={{
        value: selectedOptions,
        options: allOptions,
        onChange: handleSelectionChanged,
      }}
    >
      <div
        className={cx({
          "cursor-wait": updateLoading,
        })}
      >
        {selectedSocialsText}
        <FaAngleDown
          className={cx("transition-transform", {
            "rotate-180": isDropdownOpen,
          })}
        />
      </div>
    </CheckboxDropdown>
  ) : (
    <Tooltip title={joinWithOxfordComma(selectedOptions.map(formatSocialName))}>
      <span
        className={cx({
          "text-antd-colorInfo cursor-pointer": selectedOptions.length > 1,
        })}
      >
        {selectedSocialsText}
      </span>
    </Tooltip>
  );
};

const joinWithOxfordComma = (words: string[]) => {
  const wordsCopy = [...words];
  const lastWord = wordsCopy.pop();
  if (!wordsCopy.length) {
    return "";
  }

  return wordsCopy.length < 2
    ? words.join(" and ")
    : `${wordsCopy.join(", ")}, and ${lastWord}`;
};

export default SocialsSelect;
