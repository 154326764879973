import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useState } from "react";
import { Post } from "store/posts/postConstants";
import useDownloadPostsMenuItems from "../utils/useDownloadPostsMenuItems";

const DownloadPostsDropdown = ({ postList }: { postList: Post[] }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { menuItems } = useDownloadPostsMenuItems({
    postList,
    setIsDownloading,
  });

  return (
    <div className="w-full flex justify-center sm:w-auto sm:absolute sm:right-0">
      <Dropdown
        className="shadow-sm !bg-antd-colorBgContainer sm:!bg-antd-colorBgLayout sm:shadow-none"
        disabled={isDownloading || !postList.length}
        menu={{
          items: menuItems,
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          loading={isDownloading}
          type="link"
          size="small"
        >
          Download Posts
        </Button>
      </Dropdown>
    </div>
  );
};

export default DownloadPostsDropdown;
