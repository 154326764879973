import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { message } from "antd";
import { Post as ChalicePost, Permissions } from "api/config/chalice-api";
import pluralize from "pluralize";
import { RootState } from "store";
import { Business } from "store/business/businessConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import {
  fetchPostsQuotaThunk,
  generatePostsThunk,
  getPostDetailsThunk,
  getPostsThunk,
  pollGeneratedPostsThunk,
} from "./postActions";
import {
  INITIAL_POSTS_STATE,
  Post,
  PostScreen,
  PostScreenInfo,
  PostStatus,
  STATUS_TO_SCREEN,
} from "./postConstants";
import {
  consolidatePostList,
  reduceFetchPosts,
  sortPostsByDate,
} from "./postUtil";

const postSlice = createSlice({
  name: "posts",
  initialState: INITIAL_POSTS_STATE,
  reducers: {
    resetPostData: (state) => ({
      ...INITIAL_POSTS_STATE,
      // TODO: Idea postLimitInfo is fetched when the user changes, and applies to all businesses
      // Perhaps that should be stored in the user store, instead of the post store
      postLimitInfo: state.postLimitInfo,
    }),
    resetGeneratedPosts: (state) => {
      state.generated = INITIAL_POSTS_STATE.generated;
    },
    resetConsecutiveRejections: (state) => {
      state.consecutiveRejections = 0;
    },
    incrementConsecutiveRejections: (
      state,
      { payload }: { payload: number }
    ) => {
      state.consecutiveRejections += payload;
    },
    setActivePostPageIndex: (
      state,
      { payload }: { payload: { postId: string; pageIndex: number } }
    ) => {
      state.activePostPageIndex[payload.postId] = payload.pageIndex;
    },
    setAnimateTab: (state, { payload }: { payload: PostScreen | null }) => {
      state.animateTab = payload;
    },
    setPostCountsForBusiness: (
      state,
      {
        payload: { business, limitByPermissions },
      }: { payload: { business: Business; limitByPermissions?: Permissions } }
    ) => {
      if (!business.posts_data) {
        const { generated, draft, scheduled, published, rejected } =
          INITIAL_POSTS_STATE;
        return {
          ...state,
          generated,
          draft,

          scheduled,
          published,
          rejected,
        };
      }

      (
        Object.entries(business.posts_data.status_counts) as [
          PostStatus,
          number,
        ][]
      ).forEach(([postStatus, count]) => {
        const screenKey = STATUS_TO_SCREEN[postStatus];
        if (screenKey) state[screenKey].count = count;
      });

      // Don't include posts with null review status in count of draft posts if user can't view them
      if (
        limitByPermissions &&
        !limitByPermissions.post_approval_status_ready_for_review_write
      ) {
        state.draft.count = Object.entries(
          business.posts_data.review_status_counts
        ).reduce((prev, [, count]) => (prev += count.LIKED ?? 0), 0);
      }
    },
    syncPostData: (
      state,
      {
        payload: {
          post,
          isNewPost,
          previousPost,
          preventAnimation,
          skipConsecutiveRejections,
        },
      }: {
        payload: {
          post: ChalicePost;
          isNewPost?: boolean;
          previousPost?: Pick<Post, "id" | "status">;
          preventAnimation?: boolean;
          skipConsecutiveRejections?: boolean;
        };
      }
    ) => {
      // TODO: Idea -- have a post detail dict of { post.id: post }, update that with the details
      // when fetching posts, save the id to postList, and save returned post data to the
      // post detail dict
      // postList just has ids, selector maps the ids to the post detail dict
      const postStatus = post.status as PostStatus;
      const screenKey = STATUS_TO_SCREEN[postStatus];
      const addToPostData = state[screenKey];
      const postStatusChanged =
        previousPost && previousPost?.status !== postStatus;

      if (
        postStatusChanged &&
        previousPost?.status === "NEW" &&
        ["LIKED", "SCHEDULED", "PUBLISHED", "REJECTED"].includes(postStatus)
      ) {
        trackEvent("new_post_decided", {
          decision: postStatus,
          approved: post.status !== "REJECTED",
        });
      }

      if (isNewPost || postStatusChanged) {
        addToPostData.postList = addToPostData.postList
          .concat(post as Post)
          .sort(sortPostsByDate);
        addToPostData.count += 1;
      } else {
        addToPostData.postList = addToPostData.postList.map((existingPost) =>
          existingPost.id === post.id ? post : existingPost
        ) as Post[];
      }

      if (postStatusChanged) {
        const removeFromPostData = state[STATUS_TO_SCREEN[previousPost.status]];
        const newPostList = removeFromPostData.postList.filter(
          (existingPost) => existingPost.id !== post.id
        );
        removeFromPostData.postList = newPostList;
        removeFromPostData.count = newPostList.length;

        if (
          !skipConsecutiveRejections &&
          post.status === "REJECTED" &&
          previousPost.status === "NEW"
        ) {
          state.consecutiveRejections += 1;
        }
      }

      if ((!preventAnimation && postStatusChanged) || isNewPost) {
        state.animateTab = screenKey;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getPostsThunk.pending,
        (
          state,
          {
            meta: {
              arg: { postScreen },
            },
          }
        ) => {
          state[postScreen].isLoading = true;
        }
      )
      .addCase(
        getPostsThunk.fulfilled,
        (
          state,
          {
            meta: {
              arg: { postScreen, nextPageUrl, start, end },
            },
            payload,
          }
        ) => {
          const postData = reduceFetchPosts({
            postScreen,
            isGenerating: state.generated.isGenerating,
            postResponse: payload,
          });

          const newPostList = {
            ...postData,

            // TODO: Total draft posts visible depends on permissions, need BE support to get
            // correct count from GET /posts response when requests are paginated
            // https://mymarky.atlassian.net/browse/SWE-2222
            count: postScreen === "draft" ? state.draft.count : postData.count,
          };

          if (nextPageUrl || start || end) {
            newPostList.postList = consolidatePostList(
              state[postScreen].postList,
              postData.postList,
              // BE only returns a `next` URL if no start/end params were provided
              nextPageUrl ? "concat" : "merge"
            );
          }

          (state[postScreen] as PostScreenInfo) = newPostList;
        }
      )
      .addCase(
        getPostsThunk.rejected,
        (
          state,
          {
            meta: {
              arg: { postScreen },
            },
          }
        ) => {
          state[postScreen].isLoading = false;
        }
      )
      .addCase(fetchPostsQuotaThunk.fulfilled, (state, { payload }) => {
        state.postLimitInfo = payload;
      })
      .addCase(
        getPostDetailsThunk.rejected,
        (_state, { meta: { arg: postId }, error }) => {
          console.error(error);
          message.error({
            key: "post-details-rejected",
            content: `Unable to get post details for post ${postId}`,
          });
        }
      )
      .addCase(generatePostsThunk.rejected, (state) => {
        state.generated.isGenerating = false;
      })
      .addMatcher(
        isAnyOf(generatePostsThunk.pending, pollGeneratedPostsThunk.pending),
        (state) => {
          state.generated.isGenerating = true;
        }
      )
      .addMatcher(
        isAnyOf(
          pollGeneratedPostsThunk.fulfilled,
          pollGeneratedPostsThunk.rejected
        ),
        (state) => {
          if (state.generated.loadingCount) {
            message.error({
              key: "generation-failed",
              content: `${pluralize("post", state.generated.loadingCount, true)} failed to finish generating, please try again.`,
            });
            state.generated.loadingCount = 0;
          }
          state.generated.isGenerating = false;
        }
      );
  },
});

export const selectPostStore = ({ posts }: RootState) => posts;

export const {
  resetPostData,
  resetGeneratedPosts,
  resetConsecutiveRejections,
  incrementConsecutiveRejections,
  setActivePostPageIndex,
  setAnimateTab,
  setPostCountsForBusiness,
  syncPostData,
} = postSlice.actions;
export default postSlice.reducer;
