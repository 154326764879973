import { message } from "antd";

const MAX_VIDEO_FILE_SIZE = 200;

export const VIDEO_EXTENSIONS = ["mp4"];
export const IMAGE_EXTENSIONS = ["png", "jpg", "jpeg"];

const ACCEPTED_IMAGE_TYPES = IMAGE_EXTENSIONS.map((ext) => `image/${ext}`);
const ACCEPTED_VIDEO_TYPES = VIDEO_EXTENSIONS.map((ext) => `video/${ext}`);

const addFullExtension = (ext: string) => `.${ext}`;
export const IMAGE_FULL_EXTENSION = IMAGE_EXTENSIONS.map(addFullExtension);
export const ACCEPTED_IMAGE_FORMATS_STR = IMAGE_FULL_EXTENSION.join(", ");
export const ACCEPTED_VIDEO_FORMATS_STR =
  VIDEO_EXTENSIONS.map(addFullExtension).join(", ");

export const validateUploadedFile = (
  file: File,
  mustBeType?: "image" | "video"
) => {
  if (mustBeType === "image" || file.type.includes("image/")) {
    return validateImageFile(file);
  } else if (mustBeType === "video" || file.type.includes("video/")) {
    return validateVideoFile(file);
  }

  message.error("Only image or video files are accepted.");
  return false;
};

export const validateUploadedFileList = (files: FileList) =>
  Array.from(files).filter((file) => validateUploadedFile(file));

const validateVideoFile = (file: File) => {
  if (!ACCEPTED_VIDEO_TYPES.includes(file.type)) {
    message.error(
      `Video files must be in ${ACCEPTED_VIDEO_FORMATS_STR} format.`
    );
    return false;
  }

  const fileSize = file.size / 1024 / 1024;
  if (fileSize >= MAX_VIDEO_FILE_SIZE) {
    message.error(`Video must be smaller than ${MAX_VIDEO_FILE_SIZE}MB.`);
    return false;
  }

  return true;
};

const validateImageFile = (file: File) => {
  if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
    message.error(
      `Image files must be in ${ACCEPTED_IMAGE_FORMATS_STR} format.`
    );
    return false;
  }
  return true;
};

export const validateImageUrl = (url: string) => {
  const lowercaseUrl = url.toLowerCase();
  return IMAGE_FULL_EXTENSION.some((extension) =>
    lowercaseUrl.endsWith(extension)
  );
};
