import { message } from "antd";
import { Business } from "api/config/chalice-api";
import _ from "lodash";
import { SectionItem } from "pages/NewBusinessOnboarding/businessFormConstants";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { currentBusinessGetter, setBusiness } from "store/user/userSlice";

type SettableField = SectionItem["field"];
type BusinessFields = Business & { captionLength: string };

/** This component needs a major refactor, out of scope for initial TS conversion  */
/** @deprecated */
const useBusinessCard = () => {
  const dispatch = useAppDispatch();

  const currentBusiness = useAppSelector(currentBusinessGetter);

  const getBusinessClone = (): BusinessFields => {
    const { id: _id, ...rest } = currentBusiness;
    return { ..._.cloneDeep(rest), captionLength: "" };
  };
  const [sectionFields, setSectionFields] = useState(getBusinessClone());
  const [phone, setPhone] = useState(currentBusiness.contact_phone);

  const [areChangesMade, setAreChangesMade] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setSectionFieldsToBusiness = () => {
    setSectionFields(getBusinessClone());
    setPhone(currentBusiness.contact_phone);
    setAreChangesMade(false);
  };

  useEffect(() => {
    setSectionFieldsToBusiness();
  }, [currentBusiness]);

  useEffect(() => {
    setAreChangesMade(true);
  }, [phone]);

  const handleChange = (
    value: string | number,
    key: SettableField,
    index?: number
  ) => {
    setAreChangesMade(true);
    setSectionFields((prevValue) => {
      if (
        ["ctas", "writing_samples"].includes(key) &&
        Array.isArray(prevValue[key])
      ) {
        return {
          ...prevValue,
          [key]:
            prevValue?.[key]?.map((elem, i) => (i === index ? value : elem)) ||
            [],
        };
      } else if (key === "social_handle" && typeof value === "string") {
        return {
          ...prevValue,
          [key]: value.toLowerCase(),
        };
      } else {
        return {
          ...prevValue,
          [key]: value,
        };
      }
    });
  };

  const removeArrayItem = (field: SettableField, index: number) =>
    setSectionFields((prev) => ({
      ...prev,
      [field]:
        (Array.isArray(prev?.[field]) &&
          prev?.[field]?.filter((_item, i) => index !== i)) ||
        [],
    }));

  const addNewItem = (field: SettableField) =>
    setSectionFields((prev) => {
      return {
        ...prev,
        [field]: [
          ...((Array.isArray(prev?.[field]) && prev?.[field]) || []),
          "",
        ],
      };
    });

  const handleSave = async (showMessage = true) => {
    if (!areChangesMade) {
      return setIsEditing(false);
    }

    localStorage.setItem("areBusinessDetailsUpdated", "true");

    showMessage && setIsLoading(true);
    try {
      const businessBody: Partial<BusinessFields> = {};

      (Object.keys(sectionFields) as SettableField[]).forEach((key) => {
        if (
          ["ctas", "writing_samples"].includes(key) &&
          Array.isArray(sectionFields[key])
        ) {
          (businessBody[key] as (typeof sectionFields)[typeof key]) =
            sectionFields[key]?.filter((item) => item);
          return;
        }

        if (key === "contact_phone") {
          businessBody[key] = phone;
        }

        if (
          !(key in currentBusiness) ||
          sectionFields[key] !== currentBusiness[key as keyof Business]
        ) {
          (businessBody[key] as (typeof sectionFields)[typeof key]) =
            sectionFields[key];
        }
      });

      if (!Object.keys(businessBody).length) {
        return setIsEditing(false);
      }

      dispatch(setBusiness({ ...currentBusiness, ...businessBody }));
      await dispatch(updateBusinessDetails(businessBody));

      showMessage && message.success("Updated business details successfully.");
      setAreChangesMade(false);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      message.error("Unable to update business details, please try again.");
    }
    setIsLoading(false);
  };

  const handleOutsideClick = () => {
    if (isEditing) {
      setIsEditing(false);
      handleSave(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setSectionFieldsToBusiness();
  };

  return {
    sectionFields,
    isEditing,
    isLoading,
    phone,

    setPhone,
    handleOutsideClick,
    removeArrayItem,
    setSectionFields, //
    handleChange,
    handleCancel,
    setIsEditing,
    handleSave,
    addNewItem,
  };
};

export default useBusinessCard;
