import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Tooltip } from "antd";
import { ButtonProps } from "antd/lib";

import { fetchThemes } from "api/themes";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { GeneratePostsFrom } from "pages/Posts/components/NewGeneratePanel";
import pluralize from "pluralize";
import { forwardRef, ReactNode, useMemo } from "react";
import { MdOutlineOpenInNew, MdOutlineTune } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import { PostEditingDetails } from "./miniEditorConstants";

const PostInputsPopoverContent = forwardRef<
  HTMLDivElement,
  {
    postDetail: PostEditingDetails;
    onOpenGeneratePanel: (generateFrom: GeneratePostsFrom) => void;
  }
>(({ postDetail, onOpenGeneratePanel }, ref) => {
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const inputArchived = useMemo(
    () => ({
      topic: !currentBusiness.topics.find(
        ({ title, body }) =>
          postDetail.inputs?.topic.includes(title) ||
          postDetail.inputs?.topic.includes(body)
      ),
      memories: postDetail.inputs?.memory?.reduce(
        (prev, memory) => {
          prev[memory.id] = !currentBusiness.memories?.find(
            ({ id }) => id === memory.id
          );
          return prev;
        },
        {} as Record<string, boolean>
      ),
    }),
    [
      postDetail.inputs?.topic,
      postDetail.inputs?.memory,
      currentBusiness.topics,
      currentBusiness.memories,
    ]
  );

  const themeQuery = useQuery({
    queryKey: ["theme-detail", postDetail?.template?.theme],
    queryFn: async () => {
      const themes = (await fetchThemes()) as {
        name: string;
        display_name: string;
      }[];
      return themes.find(({ name }) => name === postDetail?.template?.theme)
        ?.display_name;
    },
    enabled: Boolean(postDetail?.template?.theme),
  });

  return (
    <div ref={ref} className="mt-4 space-y-4 text-sm max-w-xs">
      <InputDetail
        title="Topic"
        pagePath="/topics"
        onGenerate={() =>
          onOpenGeneratePanel({
            type: "topic",
            id: postDetail.inputs?.topic ?? "",
          })
        }
        disabled={!postDetail.inputs?.topic}
        inputArchived={inputArchived.topic}
        pluralizeTooltips
      >
        {postDetail.inputs?.topic ?? "N/A"}
      </InputDetail>
      {(themeQuery.isFetching || themeQuery.isFetched) && (
        <InputDetail title="Theme" pagePath="/themes" pluralizeTooltips>
          {themeQuery.data ?? (
            <LoadingOutlined className="text-antd-colorIcon ml-2" />
          )}
        </InputDetail>
      )}
      {Boolean(postDetail.inputs?.memory?.length) && (
        <InputDetail
          title="Memory"
          pagePath="/memory"
          className="flex-wrap"
          hideColon
        >
          <div className="w-full order-1 pl-2 space-y-1.5 pt-1.5">
            {postDetail.inputs?.memory ? (
              postDetail.inputs.memory.map((memory, index) => (
                <div key={index} className="flex items-center">
                  <span
                    key={index}
                    className="overflow-hidden text-ellipsis whitespace-nowrap"
                  >
                    {memory.title}
                  </span>
                  <GenerateButton
                    title="Memory"
                    onClick={() =>
                      onOpenGeneratePanel({
                        type: "memory",
                        id: memory.id,
                      })
                    }
                  />
                </div>
              ))
            ) : (
              <p className="text-antd-colorTextPlaceholder">
                No memories used in post
              </p>
            )}
          </div>
        </InputDetail>
      )}
    </div>
  );
});

const InputDetail = ({
  title,
  children,
  pagePath,
  className,
  hideColon,
  pluralizeTooltips,
  onGenerate,
  ...generateButtonProps
}: {
  children: ReactNode;
  pagePath: string;
  className?: string;
  hideColon?: boolean;
  pluralizeTooltips?: boolean;
  onGenerate?: () => void;
} & GenerateButtonProps) => (
  <div className={`flex max-w-full items-center ${className}`}>
    <span className="font-semibold">
      {title}
      {!hideColon && ":"}
    </span>
    {typeof children === "string" ? (
      <span className="ml-1 order-0 overflow-hidden text-ellipsis whitespace-nowrap">
        {children}
      </span>
    ) : (
      children
    )}
    {onGenerate && (
      <GenerateButton
        title={title}
        onClick={onGenerate}
        {...generateButtonProps}
      />
    )}
    <Tooltip title={`Go to ${pluralizeTooltips ? pluralize(title, 2) : title}`}>
      <Link to={pagePath}>
        <SmallSquareButton icon={<MdOutlineOpenInNew size={14} />} />
      </Link>
    </Tooltip>
  </div>
);

const SmallSquareButton = (buttonProps: ButtonProps) => (
  <Button
    {...buttonProps}
    className="!h-6 aspect-square"
    size="small"
    type="link"
  />
);

type GenerateButtonProps = ButtonProps & {
  title: string;
  inputArchived?: boolean;
};

const GenerateButton = ({
  title,
  inputArchived,
  ...buttonProps
}: GenerateButtonProps) => {
  const showNewInputFeatures = useTypedFeatureIsOn("new-generator-panel");

  return showNewInputFeatures ? (
    <Tooltip
      title={
        inputArchived
          ? `${title} no longer in your library`
          : buttonProps.disabled
            ? ""
            : `Generate from ${title}`
      }
    >
      <SmallSquareButton
        {...buttonProps}
        disabled={buttonProps.disabled || inputArchived}
        icon={<MdOutlineTune size={14} />}
      />
    </Tooltip>
  ) : null;
};
export default PostInputsPopoverContent;
