import { Button, Input, message } from "antd";
import { updateUser } from "api/user";
import PasswordRequirements from "components/Common/PasswordRequirements";
import LogoLink from "config/theme/LogoLink";
import { FormEvent, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import getPasswordErrorMessage from "utils/getPasswordErrorMessage";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isButtonDisabled = useMemo(
    () =>
      !password ||
      Object.values(getPasswordErrorMessage(password)).some((value) => !value),
    [password]
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await updateUser(
        {
          password,
        },
        null,
        searchParams.get("token")
      );
      message.success(
        "Your password has been successfully updated. Kindly log in using your new password."
      );
      navigate("/login");
    } catch (error) {
      console.error(error);
      message.error("Unable to update your password, please try again.");
    }

    setIsLoading(false);
  };

  return (
    <div className="auth">
      <Link to="https://mymarky.ai/">
        <LogoLink />
      </Link>
      <h3>Reset Password.</h3>
      <p>Set Your New Password</p>
      <form className="auth__form" onSubmit={handleSubmit}>
        <div className="auth__input-field">
          <label htmlFor="password">New Password</label>
          <Input.Password
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <PasswordRequirements password={password} />
        </div>
        <Button
          type="primary"
          disabled={isButtonDisabled}
          loading={isLoading}
          htmlType="submit"
        >
          Set New Password
        </Button>
        {!isLoading && (
          <Link to="/login" className="auth__back-to-login">
            Back to login
          </Link>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
