import { Button, Modal, message } from "antd";
import { deleteIntegration } from "api/integrations";
import { fetchPublishStatus, publishPost } from "api/postsApi";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { PUBLISH_ERRORS } from "fixtures/publishErrors";
import { buildAuthURL } from "fixtures/socialAuthConfig";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "store/posts/postConstants";
import { syncPostData } from "store/posts/postSlice";
import { integrationsGetter } from "store/user/userSlice";
import { PublishError } from "utils/groupPublishLinksByState";

const PublishErrorModal = ({
  error,
  close,
  post,
}: {
  error?: PublishError | null;
  close: () => void;
  post: Post;
}) => {
  const { hasPermission } = useUserPermissions();

  const dispatch = useDispatch();
  const connectedSocials = useSelector(integrationsGetter);

  const currentSocial = connectedSocials.find(
    ({ type }) => type === error?.social
  );

  const [isPublishing, setIsPublishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isMessageAvailable =
    error?.message && ![null, "null"].includes(error.message);

  const publishAgain = useCallback(async () => {
    if (!currentSocial || !error?.social) {
      return;
    }

    setIsPublishing(true);
    try {
      // TODO: create action for publishPost, sync post on
      await publishPost({
        id: currentSocial?.id,
        social: error?.social,
        postId: post.id,
      });
      const response = await fetchPublishStatus(post.id);
      const publishStatus = response[error?.social];

      if (publishStatus.state === "success") {
        dispatch(
          syncPostData({
            post: {
              ...post,
              publish_results: {
                ...(post.publish_results || {}),
                [error?.social ?? "Unknown"]: publishStatus,
              },
            },
          })
        );
        // TODO: create action for fetchPublishStatus, sync post on
        const updatedStatus = await fetchPublishStatus(post.id);
        dispatch(
          syncPostData({ post: { ...post, publish_results: updatedStatus } })
        );
        message.success("Post published successfully");
        close();
      } else if (publishStatus.state === "failed") {
        throw new Error();
      } else {
        const updatedStatus = await fetchPublishStatus(post.id);
        dispatch(
          syncPostData({ post: { ...post, publish_results: updatedStatus } })
        );
        message.info("Publishing started again");
        close();
      }
    } catch (error) {
      message.error("Couldn't publish post");
    }
    setIsPublishing(false);
  }, [post, currentSocial, error]);

  const handleReconnect = async () => {
    if (isLoading || isPublishing) {
      return;
    }

    setIsLoading(true);
    currentSocial && deleteIntegration(currentSocial.id);

    window.location.href = buildAuthURL({
      // @ts-expect-error Not sure what this is supposed to do, var 'name' is not defined
      socialType: name,
    });
  };

  const solution = useMemo(() => {
    return error?.code && PUBLISH_ERRORS[error.code];
  }, []);

  return (
    <Modal
      open={Boolean(error?.name)}
      centered
      title={`Couldn't publish post to ${error?.name}.`}
      footer={
        currentSocial && hasPermission("post_status_publish_write") ? (
          <Button
            className="publish-again-btn"
            loading={isPublishing}
            onClick={publishAgain}
            disabled={isLoading}
          >
            Try again
          </Button>
        ) : null
      }
      onCancel={close}
      closable={!isLoading && !isPublishing}
      maskClosable={!isLoading && !isPublishing}
      className="publish-error-modal"
    >
      <div>
        {isMessageAvailable && (
          <p>
            <strong>Message from {error?.name}:</strong> {error.message}
          </p>
        )}

        <div className="solution">
          {!isMessageAvailable && solution && <p>{solution}</p>}
          {!solution && !isMessageAvailable && (
            <div>
              Please reach out to{" "}
              <span
                className="text-antd-colorInfo cursor-pointer"
                onClick={() => window.$crisp?.push(["do", "chat:open"])}
              >
                support
              </span>{" "}
              for help.
            </div>
          )}
          {error?.code === "INSUFFICIENT_PERMISSIONS" && (
            <p>
              Please{" "}
              <span
                className="text-antd-colorInfo cursor-pointer"
                onClick={handleReconnect}
              >
                reconnect
              </span>{" "}
              your {error?.name} account and try again.
            </p>
          )}
        </div>

        {!(!solution && !isMessageAvailable) && (
          <div className="publish-error-note">
            <span className="text-antd-red">*</span> The social platforms are
            always introducing new error types. If you are not able to resolve
            it, please{" "}
            <span
              className="text-antd-colorInfo cursor-pointer"
              onClick={() => window.$crisp?.push(["do", "chat:open"])}
            >
              reach out for immediate support
            </span>
            .
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PublishErrorModal;
