import { chaliceClient } from "./baseClients";
import { Realm } from "./config/chalice-api";

export type RealmResponse = {
  realm: {
    created_at: string;
    domain: string;
    id: string;
    logo_url: string;
    owning_user_id: string;
    primary_color: string;
  };
};
export const getRealmDetails = () =>
  chaliceClient.get<{ realm: Realm }>("/realms");
