import { ConfigProvider, theme, ThemeConfig } from "antd";
import { RealmDetails } from "config/AppContext/appContextUtil";
import { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import {
  ALLOW_OVERRIDES_CLASSNAME,
  DARK_CLASSNAME,
  THEME_CONFIGS,
} from "./themeConfigs";
import { saveToken } from "./themeUtil";

const { useToken } = theme;

const darkInWindow = () => window.matchMedia("(prefers-color-scheme: dark)");

const ThemeConfigProvider = ({
  realm,
  themeName = "default",
  children,
}: {
  realm: RealmDetails;
  themeName?: string;
  children: ReactNode;
}) => {
  const [themeConfig, setThemeConfig] = useState<ThemeConfig>(
    THEME_CONFIGS.default
  );

  const isDark = (): boolean =>
    themeName === "dark" || (themeName === "system" && darkInWindow().matches);

  const [darkMode, setDarkMode] = useState(isDark());

  useEffect(() => {
    const toggleDarkmode = () => setDarkMode(isDark());

    toggleDarkmode();

    darkInWindow().addEventListener("change", toggleDarkmode);

    return () => darkInWindow().removeEventListener("change", toggleDarkmode);
  }, [themeName]);

  useEffect(() => {
    const root = document.documentElement;

    darkMode
      ? root.classList.add(DARK_CLASSNAME)
      : root.classList.remove(DARK_CLASSNAME);

    if (realm.isMarky || !realm.colorPrimary) {
      setThemeConfig(darkMode ? THEME_CONFIGS.dark : THEME_CONFIGS.default);
      root.classList.add(ALLOW_OVERRIDES_CLASSNAME);
    } else {
      setThemeConfig({ token: { colorPrimary: realm.colorPrimary } });

      root.classList.remove(ALLOW_OVERRIDES_CLASSNAME);
    }
  }, [realm, darkMode]);

  return (
    <ConfigProvider
      theme={{
        ...themeConfig,
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <TokenConsumer />
      {children}
    </ConfigProvider>
  );
};

export default ThemeConfigProvider;

const TokenConsumer = () => {
  const { token } = useToken();

  useLayoutEffect(() => {
    token &&
      Object.entries(token).forEach(([key, value]) => saveToken(value, key));
  }, [token]);

  return null;
};
