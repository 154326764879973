import { Button, message } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import dayjs from "dayjs";
import useGetPostDetailsQuery from "hooks/useGetPostDetailsQuery";
import _ from "lodash";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { usePrevious } from "react-use";
import { useAppSelector } from "store";
import { Post, PostCommentData } from "store/posts/postConstants";
import { selectUserInfo } from "store/user/userSlice";
import PostComment from "./PostComment";

const PostCommentsContainer = ({
  post,
  containerHeight,
  readonly,
}: {
  post: Post;
  containerHeight?: number;
  readonly: boolean;
}) => {
  const {
    previewModeState: [isPreviewMode],
  } = useAppContext();
  const user = useAppSelector(selectUserInfo);

  const [showResolved, setShowResolved] = useState(false);
  const [editingComment, setEditingComment] = useState<"new" | string | null>(
    null
  );

  const sortComments = (comments: PostCommentData[]) =>
    _.orderBy(comments, ({ created_at }) => dayjs(created_at), "asc");

  const commentListContainer = useRef<HTMLDivElement>(null);

  const {
    data: postComments = [],
    refetch: refetchComments,
    error: postCommentsError,
  } = useGetPostDetailsQuery(post.id, {
    refetchOnMount: false,
    select: (post) => sortComments(post.comments ?? []),
  });

  const hasResolvedComments = postComments.some(({ resolved }) => resolved);
  const commentList = useMemo(
    () =>
      showResolved
        ? postComments
        : postComments.filter(({ resolved }) => !resolved),
    [postComments, showResolved]
  );

  const COMMENT_PROPS = {
    currentUser: user,
    postId: post.id,
    editingComment,
    setEditingComment,
    refetchComments,
  };

  const scrollToBottom = () =>
    commentListContainer.current?.scrollBy(
      0,
      commentListContainer.current.scrollHeight
    );

  useLayoutEffect(() => {
    scrollToBottom();
  }, [containerHeight]);

  const prevLength = usePrevious(postComments.length);
  useLayoutEffect(() => {
    prevLength && postComments.length > prevLength && scrollToBottom();
  }, [postComments.length]);

  if (postCommentsError) {
    message.error("Unable to get post comments.");
    return null;
  }

  return (
    <div
      className="max-sm:max-h-[200px] max-sm:!h-[unset] sm:max-w-xs bg-antd-colorBgLayout w-full h-fit z-50 flex flex-col gap-2 sm:px-2 py-0.5"
      style={{ height: containerHeight }}
    >
      {hasResolvedComments && (
        <Button
          type="link"
          className="self-start ant-btn-small-fix"
          onClick={() => setShowResolved(!showResolved)}
        >
          {showResolved ? "Hide" : "View"} Resolved
        </Button>
      )}
      {commentList.length > 0 && (
        <div
          className="overflow-auto space-y-4 py-2 pr-0.5"
          ref={commentListContainer}
        >
          {commentList.map((comment) => (
            <PostComment
              readonly={readonly || Boolean(isPreviewMode)}
              currentComment={comment}
              key={comment.id}
              {...COMMENT_PROPS}
            />
          ))}
        </div>
      )}
      {!readonly && !isPreviewMode && <PostComment {...COMMENT_PROPS} />}
    </div>
  );
};

export default PostCommentsContainer;
