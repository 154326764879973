import React, { useMemo, useState } from "react";

import { Button, Input, Modal } from "antd";

import { InfoCircleFilled } from "@ant-design/icons";
import { useAppSelector } from "store";

const Footer = ({
  successCallback,
  isDisabled,
  isLoading,
  close,
}: {
  successCallback: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  close: () => void;
}) => {
  return (
    <div className="confirmation-modal__footer">
      <Button
        onClick={successCallback}
        disabled={isDisabled}
        loading={isLoading}
        type="primary"
        danger
      >
        Delete all data
      </Button>
      <Button type="text" onClick={close} disabled={isLoading}>
        Cancel
      </Button>
    </div>
  );
};

const DeleteConfirmationModal = ({
  successCallback,
  isLoading,
  isOpen,
  close,
  Icon,
}: {
  successCallback: () => void;
  isLoading: boolean;
  isOpen: boolean;
  close: () => void;
  Icon?: React.FC;
}) => {
  const [inputValue, setInputValue] = useState("");

  const { businesses, userInfo } = useAppSelector((state) => state.user);

  const title = useMemo(() => {
    const businessCount = businesses.filter(
      (business: { user_id: string }) => business.user_id === userInfo.id
    ).length;

    return `Are you sure you want to delete your account and ${businessCount} business${
      businessCount > 1 ? "es" : ""
    }?`;
  }, [userInfo.id, businesses]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const isDisabled = inputValue.toLowerCase() !== "delete all my data";

  return (
    <Modal
      footer={
        <Footer
          successCallback={successCallback}
          isDisabled={isDisabled}
          isLoading={isLoading}
          close={close}
        />
      }
      className="confirmation-modal"
      closable={false}
      onCancel={close}
      open={isOpen}
      centered
    >
      <div className="confirmation-modal__container">
        {Icon ? <Icon /> : <InfoCircleFilled />}
        <div className="confirmation-modal__right">
          <h3 className="confirmation-modal__heading">{title}</h3>
          <p className="confirmation-modal__content">
            Please note that <strong>this action is irreversible</strong>, and
            all your data, posts, and account information will be permanently
            deleted. You will no longer have access to your account, and any
            associated content will be lost. If there is an active subscription
            on this account it will not renew.
          </p>
          <Input
            placeholder='Type "delete all my data" to confirm'
            className="mt-16 mb-2"
            onChange={handleChange}
            disabled={isLoading}
            value={inputValue}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
