import {
  HIDDEN_HEADER_ROUTES,
  HIDDEN_SIDEBAR_ROUTES,
} from "fixtures/constants";
import { useLocation } from "react-router-dom";
import Header from "./Header/Header";
import SidebarMenu from "./SidebarNav/SidebarMenu";

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    !HIDDEN_SIDEBAR_ROUTES.includes(pathname) && (
      <>
        <SidebarMenu />
        <Header hideOnLargeScreen={HIDDEN_HEADER_ROUTES.includes(pathname)} />
      </>
    )
  );
};

export default Navigation;
