import { Button } from "antd";
import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import { MOBILE_SIDEBAR_BREAKPOINT } from "fixtures/globalConstants";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import useGlobalModal from "../GlobalModals/GlobalModalContext/useGlobalModal";
import { BANNER_BUTTON_CONFIGS } from "./upgradePlanConstants";
import useGetUpgradeInfo from "./useGetUpgradeInfo";

const UpgradePlanBanner = () => {
  const { realm } = useAppContext();
  const bannerConfig = useGetUpgradeInfo();
  const { openGlobalModal } = useGlobalModal();

  const { subscription } = useAppSelector(selectUserInfo);
  const isFreePlan = subscription?.plan === "free";

  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);

  useEffect(() => {
    setShowUpgradeBanner(
      window.innerWidth <= MOBILE_SIDEBAR_BREAKPOINT && isFreePlan
    );
  }, [subscription]);

  if (!isFreePlan || !bannerConfig || !showUpgradeBanner || !realm.isMarky) {
    return;
  }

  return (
    <div
      className={classNames(
        "flex items-center justify-between px-2 w-full text-antd-colorWhite",
        bannerConfig.bannerType === "default"
          ? "bg-antd-colorInfo"
          : "bg-antd-colorPrimary py-3"
      )}
    >
      <div className="flex items-center gap-3">
        <MdClose
          onClick={() => setShowUpgradeBanner(false)}
          className="cursor-pointer"
        />
        <div className="flex flex-col gap-0.5">
          <span className="text-sm font-medium">
            {bannerConfig.bannerTitle}
          </span>
          {bannerConfig.bannerSubtitle && (
            <span className="text-xs">{bannerConfig.bannerSubtitle}</span>
          )}
        </div>
      </div>
      <Button
        onClick={() =>
          openGlobalModal({
            modalName: "subscription",
            modalData: { initialInterval: bannerConfig.openOnInterval },
          })
        }
        {...BANNER_BUTTON_CONFIGS[bannerConfig.bannerType]}
      />
    </div>
  );
};

export default UpgradePlanBanner;
