import { Button, Checkbox, Dropdown, Modal } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { AnimatePresence, motion } from "framer-motion";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import {
  MdBookmarkBorder,
  MdExpandMore,
  MdOutlineDelete,
  MdSchedule,
} from "react-icons/md";
import { useAppSelector } from "store";
import { Post, PostScreen } from "store/posts/postConstants";
import { currentBusinessGetter } from "store/user/userSlice";
import useUpdatePostActions, {
  LoadingType,
} from "../utils/useUpdatePostActions";
import usePostSelectionContext from "./usePostSelectionContext";

const POST_ACTIONS: ({
  key: LoadingType;
  permissionNeeded: PermissionString;
  onlyShowInScreen?: PostScreen;
} & MenuItemType)[] = [
  {
    key: "save",
    label: "Move to Drafts",
    onlyShowInScreen: "generated",
    permissionNeeded: "post_status_draft_write",
    icon: <MdBookmarkBorder />,
  },
  {
    key: "delete",
    label: "Move to Trash",
    permissionNeeded: "post_status_trash_write",
    icon: <MdOutlineDelete />,
  },
  {
    key: "schedule",
    label: "Schedule",
    permissionNeeded: "post_status_schedule_write",
    icon: <MdSchedule />,
  },
];

const PostSelectionActionBar = ({ screen }: { screen: PostScreen }) => {
  const {
    inSelectionMode,
    selectedPostList,
    allSelected,
    selectionDisabled,
    setSelectionDisabled,
    selectPost,
    exitSelectionMode,
    selectAll,
  } = usePostSelectionContext();
  const { bulkSavePosts, bulkSchedulePosts, bulkDiscardPosts } =
    useUpdatePostActions();
  const { hasPermission } = useUserPermissions();
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const [confirmAction, setConfirmAction] = useState<LoadingType | null>(null);

  const getConfirmText = (action: LoadingType) => {
    const postText = pluralize("post", selectedPostList.length, true);
    const showMoveText = ["save", "delete"].includes(action);
    return `${showMoveText ? "move" : action} ${postText}${showMoveText ? ` to ${action === "save" ? "drafts" : "trash"}` : ""}`;
  };

  const postActions = useMemo(
    () =>
      POST_ACTIONS.reduce(
        (prev, { onlyShowInScreen, permissionNeeded, ...item }) => {
          (!onlyShowInScreen || onlyShowInScreen === screen) &&
            hasPermission(permissionNeeded) &&
            prev.push(item);
          return prev;
        },
        [] as ItemType[]
      ),
    [screen, currentBusiness]
  );

  const handlePostAction = async (action: LoadingType) => {
    setSelectionDisabled(true);
    let removeSelection: Post[] = [];

    switch (action) {
      case "save":
        removeSelection = await bulkSavePosts(selectedPostList);
        break;
      case "delete":
        removeSelection = await bulkDiscardPosts(selectedPostList);
        break;
      case "schedule":
        removeSelection = await bulkSchedulePosts(selectedPostList);
    }

    removeSelection.length === selectedPostList.length
      ? exitSelectionMode()
      : removeSelection.forEach(selectPost);

    setSelectionDisabled(false);
  };

  return (
    <>
      <AnimatePresence>
        {inSelectionMode && (
          <motion.div
            initial={{ opacity: 0, bottom: 4 }}
            exit={{ opacity: 0, bottom: 0 }}
            animate={{ opacity: 1, bottom: 12 }}
            transition={{ duration: 0.15 }}
            className="absolute left-1/2 -translate-x-1/2 z-50 duration-100 flex flex-wrap gap-4 items-center max-sm:justify-center border border-antd-colorBorder py-3 px-6 w-full max-w-[90%] sm:max-w-xl rounded-lg bg-antd-colorBgContainer shadow-lg "
          >
            <Checkbox
              checked={selectedPostList.length > 0}
              indeterminate={selectedPostList.length > 0 && !allSelected}
              onClick={() => selectAll(!allSelected)}
              disabled={selectionDisabled}
            >
              {selectedPostList.length} selected
            </Checkbox>
            <Dropdown
              menu={{
                items: postActions,
                onClick: ({ key }) => setConfirmAction(key as LoadingType),
              }}
              disabled={selectionDisabled || !selectedPostList.length}
            >
              <Button loading={selectionDisabled}>
                Actions <MdExpandMore />
              </Button>
            </Dropdown>
            <Button
              className="max-sm:w-full ml-auto"
              type="link"
              onClick={exitSelectionMode}
              disabled={selectionDisabled}
            >
              Cancel
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      {confirmAction && (
        <Modal
          open
          centered
          title="Bulk Edit"
          okText="Confirm"
          onOk={() => {
            handlePostAction(confirmAction);
            setConfirmAction(null);
          }}
          onCancel={() => setConfirmAction(null)}
        >
          Are you sure you want to {getConfirmText(confirmAction)}?
        </Modal>
      )}
    </>
  );
};

export default PostSelectionActionBar;
