import { Modal, ModalProps } from "antd";
import classNames from "classnames";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import OnboardingModalTitle, {
  OnboardingModalTitleProps,
} from "./OnboardingModalTitle";

export type OnboardingModalTemplateProps = Omit<ModalProps, "classNames"> &
  OnboardingModalTitleProps & {
    footer: ReactNode;
    hideTransitions?: boolean;
    imageBg?: string;
    imageSrc?: string;
  };

const OnboardingModalTemplate = ({
  title,
  header,
  subHeader,
  children,
  footer,
  centerTitle,
  hideTransitions,
  imageBg,
  imageSrc,
  ...modalProps
}: OnboardingModalTemplateProps) => (
  <Modal
    closable={false}
    // @ts-expect-error `null` prop is technically not allowed, but removes auto set width on modal's parent (no
    // other way to access parent)
    width={imageSrc ? null : undefined}
    centered
    footer={null}
    {...{
      ...(hideTransitions && { transitionName: "", maskTransitionName: "" }),
    }}
    classNames={{ content: "!p-0 overflow-hidden" }}
    children={
      <div
        className={classNames({
          "w-full lg:max-w-[1024px] lg:grid grid-cols-[1fr_1fr] overflow-hidden":
            imageSrc,
        })}
      >
        <div
          className={classNames("flex flex-col px-6 py-5", {
            "text-center": centerTitle,
            "gap-4 h-[90vh] lg:h-[742px] max-w-screen-sm": imageSrc,
            "gap-8 h-full": !imageSrc,
          })}
        >
          <OnboardingModalTitle
            {...{ title, header, subHeader, centerTitle }}
          />
          <div className="px-6 -mx-6 flex-grow overflow-auto [&_label]:font-medium">
            {children}
          </div>
          <div className="pt-2 [&_*]:flex-wrap">{footer}</div>
        </div>
        {imageSrc && (
          <div
            style={{
              background:
                "linear-gradient(159deg, #E18FFF 1.17%, #CC66FB 35.66%, #A61EF5 96.99%)",
            }}
            className="max-lg:hidden [&_img]:object-cover [&_img]:h-full relative"
          >
            <img src={imageBg} />
            <motion.img
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.15, ease: "easeInOut" }}
              src={imageSrc}
              key={imageSrc}
              className="absolute top-0 left-0"
            />
          </div>
        )}
      </div>
    }
    {...modalProps}
  />
);

export default OnboardingModalTemplate;
