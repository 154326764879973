import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Modal } from "antd";
import { openverseClient } from "api/baseClients";
import {
  DesignPage,
  DesignRenderType,
  DesignTemplate,
} from "api/config/chalice-api";
import { autoAnimatePost, autoAnimateTemplate } from "api/postsApi";
import classNames from "classnames";
import { genericError, VOID_FUNCTION } from "fixtures/globalConstants";
import useGetPostDetailsQuery from "hooks/useGetPostDetailsQuery";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import useUpdatePostActions from "pages/Posts/utils/useUpdatePostActions";
import { PolotnoContainer, WorkspaceWrap } from "polotno";
import { registerNextDomDrop } from "polotno/canvas/page";
import {
  unstable_setAnimationsEnabled,
  unstable_setTextOverflow,
  unstable_setTextVerticalResizeEnabled,
} from "polotno/config";
import createStore from "polotno/model/store";
import { PagesTimeline } from "polotno/pages-timeline";
import Toolbar from "polotno/toolbar/toolbar";
import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  MdOutlinePhoto,
  MdOutlinePlayCircle,
  MdOutlineVideoSettings,
} from "react-icons/md";
import { useAppDispatch, useAppSelector } from "store";
import { updatePostThunk } from "store/posts/postActions";
import { Post } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import { addDateCacheBusterParam } from "utils/addNoCacheParam";
import { trackEvent } from "utils/eventTracking/trackEvents";
import DiscardChangesConfirmation from "../DiscardChangesConfirmation";
import {
  addPolotnoElement,
  ANIMATE_BUTTONS,
  TOOLBAR_COMPONENTS,
} from "../polotnoUtil";
import AnimateButton from "./AnimateButtons/AnimateButton";
import AutoAnimateButton from "./AnimateButtons/AutoAnimateButton";
import ImageUploader from "./ControlButtons/ImageUpload";
import ZoomControls from "./ControlButtons/ZoomControls";
import ImageWorkspace from "./ImageWorkspace/ImageWorkspace";
import CustomSidePanel from "./SidePanel/CustomSidePanel";

unstable_setTextOverflow("change-font-size");
unstable_setTextVerticalResizeEnabled(true);
unstable_setAnimationsEnabled(true);
// unstable_useHtmlTextRender(true); // TODO: Temp disabled, possible cause of lagginess

const store = createStore({
  key: import.meta.env.VITE_POLOTNO_KEY,
  showCredit: false,
});

type MediaEditorProps = {
  closeFullscreen: () => void;
  isOpen: boolean;
  post?: Post;
};

const OPENVERSE_MAX_PAGE_LIMIT = 12;

const MediaEditor = observer(
  ({ post, closeFullscreen, isOpen }: MediaEditorProps) => {
    const dispatch = useAppDispatch();
    const { activePostPageIndex } = useAppSelector(selectPostStore);
    const { createNewPost } = useUpdatePostActions();

    const containerEl = useRef<HTMLDivElement>(null);

    const [saveLoading, setSaveLoading] = useState(false);
    const [isAutoAnimating, setIsAutoAnimating] = useState(false);
    const [showDiscardChangesModal, setShowDiscardChangesModal] =
      useState(false);
    const [isDesignEdited, setIsDesignEdited] = useState(false);

    const [editorType, setEditorType] = useState<DesignRenderType>("IMAGE");
    const isVideoEditor = editorType === "VIDEO";

    useEffect(() => {
      return () => store.clear();
    }, []);

    const { data: originalPostTemplate, refetch } = useGetPostDetailsQuery(
      post?.id,
      {
        select: (postDetails) => postDetails.template,
      }
    );

    useLayoutEffect(() => {
      const setupStore = async () => {
        store.on("change", () => setIsDesignEdited(true));

        if (post) {
          store.loadJSON(originalPostTemplate);
          await store.waitLoading();

          setTimeout(() => {
            // Polotno editor has issues scrolling to active page due to template already being loaded
            const activePageIndex = activePostPageIndex[post.id];
            if (!activePageIndex) {
              const workspaceWrap = containerEl.current?.getElementsByClassName(
                "polotno-workspace-inner"
              )[0];
              workspaceWrap?.scroll({ top: 0 });
            } else {
              setTimeout(() => {
                store.selectPage(store.pages[activePageIndex]?.id);
              }, 300);
            }
          }, 100);
        } else {
          store.addPage();
        }
      };

      if (post) {
        originalPostTemplate &&
          setEditorType(originalPostTemplate.render_design_as);
      }

      setupStore();
    }, [originalPostTemplate]);

    const handleDragStart = (e: SyntheticEvent) => {
      // Exit early if the dragged element is not an image (draggedImageURL will be undefined)
      if (["elements", "videos"].includes(store.openedSidePanel)) {
        return;
      }

      const draggedImageURL = addDateCacheBusterParam(
        (e.target as HTMLImageElement).src
      );

      registerNextDomDrop(async (position, element) => {
        if (element && element.type === "image") {
          element.set({
            src: draggedImageURL,
          });
          return;
        }
        addPolotnoElement({
          store,
          src: draggedImageURL,
          position,
        });
      });
    };

    const close = () => {
      store.isPlaying && store.stop();
      setTimeout(closeFullscreen, 1);
    };

    const handleCancel = () => {
      isDesignEdited ? setShowDiscardChangesModal(true) : close();
    };

    const getDesignTemplate = (): DesignTemplate => {
      const { pages, ...jsonTemplate } = store.toJSON();
      return {
        ...jsonTemplate,
        pages: pages as DesignPage[],
        render_design_as: editorType,
      };
    };

    const autoAnimate = async () => {
      if (!post) {
        return;
      }

      setIsAutoAnimating(true);
      try {
        const data = await (store
          ? autoAnimateTemplate({ ...post, template: getDesignTemplate() })
          : autoAnimatePost(post.id));
        const template = data.post?.template;
        if (!template) {
          throw "Auto-animate failed";
        }

        store.loadJSON(template);

        const { data: audiosResponse } = await openverseClient.audio_search({
          page: Math.floor(Math.random() * OPENVERSE_MAX_PAGE_LIMIT) + 1,
          category: "music",
        });
        await store.waitLoading();

        const randomTrack =
          audiosResponse.results[
            Math.floor(Math.random() * audiosResponse.results.length)
          ];

        store.addAudio({ src: randomTrack.url });
        setEditorType("VIDEO");
      } catch (error) {
        genericError(error);
      }

      setIsAutoAnimating(false);

      // play after 1 second as it takes some time to load the audio
      setTimeout(() => store.play(), 1000);
    };

    const handleSave = async () => {
      const areChangesMade =
        isDesignEdited || editorType !== originalPostTemplate?.render_design_as;

      if (!areChangesMade) {
        close();
        return;
      }

      setSaveLoading(true);

      const designTemplate = getDesignTemplate();

      try {
        if (post) {
          await dispatch(
            updatePostThunk({
              post,
              body: {
                template: designTemplate,
                should_render: true,
              },
            })
          );
          trackEvent("used_post_editor", {
            converted_to_video:
              originalPostTemplate?.render_design_as !== "VIDEO" &&
              isVideoEditor,
          });

          await refetch();

          message.success("Updated design successfully");
          close();
        } else {
          const newPost = await createNewPost({
            showMessage: true,
            template: designTemplate,
          });
          trackEvent("created_custom_post", {
            used_post_editor: true,
            post_type: designTemplate.render_design_as,
          });
          newPost && close();
        }
      } catch (error) {
        genericError(error);
      }

      setSaveLoading(false);
    };

    useEffect(() => {
      if (!isVideoEditor) {
        return;
      }

      // Logic for changing the 'Pages' button text to 'Timeline'. Currently, there's no other way to achieve this.
      const elements = document.querySelectorAll(".bp5-button-text");
      const pageTimelineElement = Array.from(elements).find(
        (element) => element.innerHTML === "Pages"
      ) as HTMLElement;

      if (pageTimelineElement) {
        pageTimelineElement.innerHTML = "Timeline";
        pageTimelineElement.click();
      }
    }, [isVideoEditor]);

    return (
      <Modal
        className="!w-[95vw] !h-[95vh] [&_.ant-modal-content]:!p-0"
        onCancel={handleCancel}
        onClose={handleCancel}
        closable={false}
        footer={null}
        maskClosable
        open={isOpen}
        title={null}
        centered
      >
        <div
          className="relative rounded-lg overflow-hidden flex flex-col w-full h-full !w-[95vw] !h-[95vh] !m-0 rounded-md"
          onDragStart={handleDragStart}
          ref={containerEl}
        >
          <div
            className={classNames(
              "flex flex-wrap gap-x-4 gap-y-2 justify-between py-2 px-4 border-antd-colorBorderSecondary border-b z-50 bg-antd-colorBgContainer",
              {
                "!bg-antd-colorInfoBg": isVideoEditor,
              }
            )}
          >
            <div className="md:text-base max-md:w-full flex items-center justify-center gap-1.5">
              {isVideoEditor ? (
                <MdOutlinePlayCircle size={18} />
              ) : (
                <MdOutlinePhoto size={18} />
              )}
              <span>{capitalize(editorType)} Post Editor</span>
            </div>
            <div className="flex gap-1 md:gap-2 max-md:[&_button]:text-xs max-md:[&_button]:!p-2 max-md:-my-2 max-md:-mx-3.5 max-md:px-1 max-md:py-2 overflow-auto">
              <Button
                onClick={close}
                disabled={saveLoading}
                size="small"
                type="text"
              >
                Cancel
              </Button>
              {isVideoEditor && post?.id && (
                <AutoAnimateButton
                  autoAnimate={autoAnimate}
                  isLoading={isAutoAnimating}
                  store={store}
                />
              )}
              {editorType &&
                (isVideoEditor || !post?.id ? (
                  <Button
                    disabled={saveLoading}
                    className="[&_.ant-btn-icon]:flex"
                    onClick={() =>
                      setEditorType(isVideoEditor ? "IMAGE" : "VIDEO")
                    }
                    icon={
                      !isVideoEditor ? (
                        <MdOutlinePlayCircle size={18} />
                      ) : (
                        <MdOutlinePhoto size={18} />
                      )
                    }
                    size="small"
                  >
                    Change to {isVideoEditor ? "Image" : "Video"}
                  </Button>
                ) : (
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    menu={{
                      items: [
                        {
                          label: (
                            <AutoAnimateButton
                              autoAnimate={autoAnimate}
                              isLoading={isAutoAnimating}
                              store={store}
                            >
                              <div className="flex items-center gap-2">
                                <MdOutlineVideoSettings size={18} />
                                Auto-animate
                              </div>
                            </AutoAnimateButton>
                          ),
                          key: "auto-animate",
                        },
                      ],
                    }}
                    onClick={() => setEditorType("VIDEO")}
                    loading={isAutoAnimating}
                    size="small"
                    buttonsRender={([leftButton, rightButton]) => [
                      React.cloneElement(leftButton as ReactElement, {
                        icon: <MdOutlinePlayCircle size={18} />,
                        className: "[&_.ant-btn-icon]:flex",
                      }),
                      React.cloneElement(rightButton as ReactElement),
                    ]}
                  >
                    Change to Video
                  </Dropdown.Button>
                ))}
              {store.pages.length > 0 && (
                <Button
                  loading={saveLoading}
                  type="primary"
                  size="small"
                  onClick={handleSave}
                >
                  Save and Close
                </Button>
              )}
            </div>
          </div>

          <PolotnoContainer id="polotno-container">
            <CustomSidePanel store={store} isVideoEditor={isVideoEditor} />
            <WorkspaceWrap id="workspace-wrap">
              <Toolbar
                store={store}
                components={{
                  ...TOOLBAR_COMPONENTS,
                  PageMediaUploader: <T extends DefaultObserverProps>(
                    props: T
                  ) => <ImageUploader {...props} isVideo={isVideoEditor} />,
                  ...Object.fromEntries(
                    ANIMATE_BUTTONS.map((buttonName) => [
                      buttonName,
                      isVideoEditor ? AnimateButton : VOID_FUNCTION,
                    ])
                  ),
                }}
              />
              <ImageWorkspace store={store} />
              {isVideoEditor && <PagesTimeline store={store} />}
              <ZoomControls store={store} />
            </WorkspaceWrap>
          </PolotnoContainer>
        </div>
        <DiscardChangesConfirmation
          confirmationOpen={showDiscardChangesModal}
          setConfirmationOpen={setShowDiscardChangesModal}
          discardChanges={close}
        />
      </Modal>
    );
  }
);

export default MediaEditor;
