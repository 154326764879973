import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { ENV_NAME } from "config/envVars";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import imageLibrarySlice from "./imageLibrary/imageLibrarySlice";
import postSlice from "./posts/postSlice";
import themesSlice from "./themes/themesSlice";
import userReducer from "./user/userSlice";

export const throwMiddleware: Middleware = () => (next) => (action) => {
  next(action);
  if (action?.error) {
    throw action.error;
  }
};

const rootReducer = combineReducers({
  imageLibrary: imageLibrarySlice,
  themes: themesSlice,
  posts: postSlice,
  user: userReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(throwMiddleware),
    devTools: ENV_NAME === "DEV",
  });

const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

// TODO: upgrade to redux v9 -- common .withTypes notation to do the below
export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
