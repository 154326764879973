import { message } from "antd";
import {
  DesignTemplate,
  ReviewStatus,
  UpdatePostBody,
} from "api/config/chalice-api";
import {
  createPost,
  deletePost,
  generateCaptionFromImage,
  modifyCaption,
  publishPostToAllSocials,
  schedulePost as updatePostQueue,
} from "api/postsApi";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import pluralize from "pluralize";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  getPostDetailsThunk,
  getPostsThunk,
  schedulePostThunk,
  updatePostThunk,
} from "store/posts/postActions";
import { Post, STATUS_TO_SCREEN } from "store/posts/postConstants";
import {
  incrementConsecutiveRejections,
  selectPostStore,
  setAnimateTab,
  syncPostData,
} from "store/posts/postSlice";
import { IntegrationType } from "store/user/userConstants";
import { currentBusinessGetter, queueIdGetter } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { downloadFile, fetchMediaBlob } from "utils/fileDownloadUtils";

export const firstQueueNumber = (scheduledPosts: Post[]) => {
  const allPositions = scheduledPosts.map(
    ({ queue_ordering_number: position }) => position ?? 0
  );
  return { queue_ordering_number: Math.min(...allPositions) - 1 };
};

export type CreatePostPayload = {
  caption?: string;
  showMessage?: boolean;
  mediaUrls?: string[];
  publishTo?: IntegrationType[] | null;
  template?: DesignTemplate;
};

export type LoadingType =
  | "save"
  | "update-review-status"
  | "duplicate"
  | "caption"
  | "schedule"
  | "delete"
  | "download"
  | "publish";

// TODO: Move this logic into new post store, these should be the actions/side-effects
const useUpdatePostActions = () => {
  const dispatch = useAppDispatch();
  const {
    scheduled: { postList: scheduledPosts },
  } = useAppSelector(selectPostStore);
  const currentBusiness = useAppSelector(currentBusinessGetter);
  const queueId = useAppSelector(queueIdGetter);

  const [loading, setLoading] = useState<LoadingType | false>(false);

  const loadingWrapper: PromiseFunctionWrapper<LoadingType> =
    (fn, loadingKey) => async (args) => {
      setLoading(loadingKey);
      let successResponse = false as ReturnType<typeof fn>;

      try {
        successResponse = (await fn(args)) as ReturnType<typeof fn>;
      } catch (error) {
        console.error(error);
        if (error instanceof Error && error.message) {
          message.error(error.message);
        } else {
          message.error(`Unable to ${loadingKey} post, please try again.`);
        }
      }
      setLoading(false);

      return successResponse;
    };

  const bulkLoadingWrapper: PromiseFunctionWrapper<LoadingType, Post[]> =
    (fn, loadingKey) => async (args) => {
      setLoading(loadingKey);

      let successes = [] as Post[];
      try {
        successes = await fn(args);
      } catch (error) {
        console.error(error);
        message.error(`Unable to ${loadingKey} any posts, please try again.`);
      }

      setLoading(false);
      return successes;
    };

  const bulkSynchronousAction = async (
    postList: Post[],
    action: "save" | "delete"
  ) => {
    const updatePostPromise = async (post: Post) => {
      try {
        if (action === "save") {
          await savePost({
            post,
            showMessage: false,
            updateBody: { status: "LIKED" },
          });
        } else {
          await discardPost(post, false);
        }
        return post;
      } catch (error) {
        console.error(error);
      }
    };

    const updatedPosts = await Promise.all(postList.map(updatePostPromise));
    const successes = updatedPosts.filter((post) => post) as Post[];
    successes.length && action === "save" && dispatch(setAnimateTab("draft"));

    if (action === "delete") {
      dispatch(incrementConsecutiveRejections(successes.length));
    }
    return { successes, failureCount: postList.length - successes.length };
  };

  const createNewPost = async ({
    caption,
    showMessage,
    mediaUrls,
    publishTo,
    template,
  }: CreatePostPayload) => {
    const now = dayjs().toISOString();
    const { post } = await createPost({
      caption,
      business_id: currentBusiness.id,
      finished_at: now,
      liked_at: now,
      status: "LIKED",
      media_urls: mediaUrls,
      ...(Boolean(publishTo?.length) && { publish_to: publishTo }),
      template,
    });

    if (!post) {
      return;
    }

    if (showMessage) {
      dispatch(syncPostData({ post, isNewPost: true }));
      message.success(`Post created successfully and moved to drafts.`);
    }

    return post;
  };

  const bulkSavePosts = async (postList: Post[]) => {
    const { successes, failureCount } = await bulkSynchronousAction(
      postList,
      "save"
    );

    successes.length &&
      message.success(
        `Moved ${pluralize("post", successes.length, true)} to drafts.`
      );

    failureCount &&
      message.error(
        `Failed to move ${pluralize("post", failureCount, true)}, please try again.`
      );

    return successes;
  };

  const savePost = async ({
    post,
    // BE Bug: setting status changes the post order, even if the new status is the same
    updateBody: { status: newStatus, ...updateBody } = {},
    showMessage = true,
  }: {
    post: Post;
    updateBody?: UpdatePostBody;
    showMessage?: boolean;
  }) => {
    const hasStatusChange = newStatus && newStatus !== post.status;
    const updatedPost = await dispatch(
      updatePostThunk({
        post,
        body: {
          ...updateBody,
          ...(hasStatusChange && { status: newStatus }),
        },
        preventAnimation: !showMessage,
      })
    ).unwrap();

    showMessage &&
      message.success(
        `Post saved ${hasStatusChange && newStatus === "LIKED" ? "to Drafts " : ""}successfully.`
      );

    return updatedPost;
  };

  const updatePostReviewStatus = ({
    post,
    reviewStatus,
  }: {
    post: Post;
    reviewStatus: ReviewStatus | null;
  }) =>
    savePost({
      post,
      updateBody: {
        review_status: reviewStatus,
        // NEW posts should be moved to drafts after adding a review status
        ...(post.status === "NEW" && { status: "LIKED" }),
      },
      showMessage: false,
    });

  const duplicatePost = async (post: Post) => {
    let template = post.template;

    if (!template) {
      const postDetail = await dispatch(getPostDetailsThunk(post.id)).unwrap();
      template = postDetail.template;
    }

    const response = await createPost({
      business_id: post.business_id,
      media_urls: post.media_urls,
      finished_at: dayjs.utc().toISOString(),
      liked_at: dayjs.utc().toISOString(),
      caption: post.caption,
      inputs: post.inputs,
      status: "LIKED",
      template,
    });

    if (!response.post) {
      return false;
    }

    dispatch(syncPostData({ post: response.post, isNewPost: true }));
    dispatch(getPostsThunk({ postScreen: "draft" }));

    message.success("Post duplicated and added to Drafts.");
    return true;
  };

  const getModifiedCaption = ({
    modifyInstruction,
    caption,
  }: {
    modifyInstruction: string;
    caption: string;
  }) =>
    modifyCaption({
      caption,
      instructions: modifyInstruction,
    });

  const getCaptionForImage = (imageUrl: string) =>
    generateCaptionFromImage({
      businessId: currentBusiness.id,
      imageUrl,
    });

  const bulkSchedulePosts = async (postList: Post[]) => {
    const successes: Post[] = [];
    let failureCount = 0;
    for (const post of postList) {
      try {
        await schedulePost({ post, showMessage: false });
        successes.push(post);
      } catch (error) {
        console.error(error);
        failureCount += 1;
      }
    }

    if (successes.length) {
      message.success(
        `Scheduled ${pluralize("post", successes.length, true)}.`
      );
      dispatch(setAnimateTab("scheduled"));
    }

    failureCount &&
      message.error(
        `Failed to schedule ${pluralize("post", failureCount, true)}, please try again.`
      );

    return successes;
  };

  const schedulePost = async ({
    post,
    scheduleType,
    publishTimestamp,
    showMessage = true,
    refetchAfterSchedule,
  }: {
    post: Pick<Post, "id" | "status">;
    scheduleType?: "schedule-next" | "schedule-custom" | string;
    publishTimestamp?: number;
    showMessage?: boolean;
    refetchAfterSchedule?: boolean;
  }) => {
    const isNext = scheduleType === "schedule-next";

    if (scheduleType === "schedule-custom") {
      if (!publishTimestamp) {
        message.info("Publish timestamp is required.");
        return false;
      }
      await dispatch(
        schedulePostThunk({
          post,
          publishTimestamp,
          preventAnimation: !showMessage,
        })
      );
      refetchAfterSchedule &&
        (await dispatch(getPostsThunk({ postScreen: "scheduled" })));
    } else {
      const { post: scheduledPost } = await updatePostQueue({
        postId: post.id,
        body: {
          queue_id: queueId,
          ...(isNext && firstQueueNumber(scheduledPosts)),
        },
        newlyScheduled: true,
      });

      if (refetchAfterSchedule) {
        const refetchPrevList =
          post.status !== "SCHEDULED"
            ? dispatch(
                getPostsThunk({ postScreen: STATUS_TO_SCREEN[post.status] })
              )
            : null;
        await Promise.all([
          dispatch(getPostsThunk({ postScreen: "scheduled" })),
          refetchPrevList,
        ]);
      } else if (scheduledPost) {
        dispatch(
          syncPostData({
            post: scheduledPost,
            previousPost: post,
            preventAnimation: !showMessage,
          })
        );
      } else {
        return false;
      }
    }

    showMessage &&
      message.success(
        `Post scheduled successfully${isNext ? " to be published next." : "."}`
      );

    return true;
  };

  const bulkDiscardPosts = async (postList: Post[]) => {
    const { successes, failureCount } = await bulkSynchronousAction(
      postList,
      "delete"
    );

    successes.length &&
      message.success(`Deleted ${pluralize("post", successes.length, true)}.`);

    failureCount &&
      message.error(
        `Failed to delete ${pluralize("post", failureCount, true)}, please try again.`
      );

    return successes;
  };

  const discardPost = async (post: Post, showMessage: boolean = true) => {
    const { post: deletedPost } = (await deletePost(post.id)) as { post: Post };

    dispatch(
      syncPostData({
        post: deletedPost,
        previousPost: post,
        preventAnimation: true,
        skipConsecutiveRejections: !showMessage,
      })
    );

    showMessage &&
      message.warning({ content: "Post moved to trash", duration: 2 });

    trackEvent("deleted_post", {
      refinerArgs: {
        addAttributes: { post_id: post.id },
      },
    });
    return true;
  };

  const publishPost = async (post: Post) => {
    // TODO: Could BE return the post here, instead of 'success'?
    await publishPostToAllSocials({
      postId: post.id,
    });

    dispatch(
      syncPostData({
        post: { ...post, status: "PUBLISHED" },
        previousPost: post,
      })
    );
    dispatch(getPostsThunk({ postScreen: "published" }));

    message.success("Post moved to published list.");
    trackEvent("published_post", {
      refinerArgs: {
        addAttributes: { post_id: post.id },
      },
    });

    return true;
  };

  const downloadPost = async (post: Post) => {
    if (!post.media_urls) {
      message.info("Post has no media to download.");
      return false;
    }

    if (post.media_urls.length === 1) {
      await downloadFile(post.media_urls[0], "image");
    } else {
      const zip = new JSZip();
      const folder = zip.folder("collection");

      if (!folder) {
        throw "Unable to save folder.";
      }

      await Promise.all(
        post.media_urls.map(async (url, index) => {
          const blob = await fetchMediaBlob(url);
          folder.file(`image-${index + 1}.jpg`, blob);
        })
      );

      const content = await folder.generateAsync({ type: "blob" });
      saveAs(content, "carousel");
    }

    return true;
  };

  return {
    loading,
    isLoading: Boolean(loading),
    createNewPost: loadingWrapper(createNewPost, "save"),
    bulkSavePosts: bulkLoadingWrapper(bulkSavePosts, "save"),
    savePost: loadingWrapper(savePost, "save"),
    updatePostReviewStatus: loadingWrapper(
      updatePostReviewStatus,
      "update-review-status"
    ),
    duplicatePost: loadingWrapper(duplicatePost, "duplicate"),
    getModifiedCaption: loadingWrapper(getModifiedCaption, "caption"),
    getCaptionForImage: loadingWrapper(getCaptionForImage, "caption"),
    bulkSchedulePosts: bulkLoadingWrapper(bulkSchedulePosts, "schedule"),
    schedulePost: loadingWrapper(schedulePost, "schedule"),
    bulkDiscardPosts: bulkLoadingWrapper(bulkDiscardPosts, "delete"),
    discardPost: loadingWrapper(discardPost, "delete"),
    publishPost: loadingWrapper(publishPost, "publish"),
    downloadPost: loadingWrapper(downloadPost, "download"),
  };
};

export default useUpdatePostActions;
