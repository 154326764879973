import { Palette } from "store/business/businessConstants";

export const DEFAULT_PALETTE: Palette = {
  accent_color: "#12A37F",
  text_color: "#121212",
  background_color: "#FFFFFF",
};

const PRESET_PALETTES: Palette[] = [
  {
    accent_color: "#000000",
    background_color: "#8d6cf9",
    text_color: "#FFFFFF",
  },
  {
    accent_color: "#220257",
    background_color: "#EB546F",
    text_color: "#FFFFFF",
  },
  {
    accent_color: "#06247f",
    background_color: "#56a3f5",
    text_color: "#ffffff",
  },
  {
    accent_color: "#c5c5ff",
    background_color: "#1a1d29",
    text_color: "#ffffff",
  },
  {
    accent_color: "#ee834e",
    background_color: "#ffffff",
    text_color: "#231b54",
  },
  {
    accent_color: "#d4674c",
    background_color: "#dde5e7",
    text_color: "#2c3035",
  },
  {
    accent_color: "#61ccc1",
    background_color: "#ffffff",
    text_color: "#0567a8",
  },
  {
    accent_color: "#f49285",
    background_color: "#f8f5e8",
    text_color: "#474747",
  },
  {
    accent_color: "#a199e5",
    background_color: "#F2D7EE",
    text_color: "#262626",
  },
  {
    accent_color: "#f28e1c",
    background_color: "#eaeec8",
    text_color: "#2b2b2b",
  },
  {
    accent_color: "#8491C3",
    background_color: "#F9E2D2",
    text_color: "#2C2C2C",
  },
  {
    accent_color: "#96f7d2",
    background_color: "#280647",
    text_color: "#b3aee9",
  },
  {
    accent_color: "#4D0A65",
    background_color: "#EE8271",
    text_color: "#FFFFFF",
  },
  {
    accent_color: "#ffaf87",
    background_color: "#1a223a",
    text_color: "#ffffff",
  },
  {
    accent_color: "#52c3c9",
    background_color: "#1e1e1e",
    text_color: "#ffffff",
  },
  {
    accent_color: "#ffcc66",
    background_color: "#2d2d2d",
    text_color: "#ffffff",
  },
  {
    accent_color: "#ff9a8d",
    background_color: "#1e140f",
    text_color: "#fbe1b7",
  },
  {
    accent_color: "#ffb347",
    background_color: "#173d4e",
    text_color: "#ffffff",
  },
  {
    accent_color: "#fac318",
    background_color: "#674da0",
    text_color: "#ffffff",
  },
  {
    accent_color: "#ff8a80",
    background_color: "#f3e5f5",
    text_color: "#2b1a30",
  },
  {
    accent_color: "#ff8a65",
    background_color: "#e5f7ff",
    text_color: "#23395b",
  },
  {
    accent_color: "#f44336",
    background_color: "#2b2b2b",
    text_color: "#f7f7f7",
  },
  {
    accent_color: "#fbc02d",
    background_color: "#ffffff",
    text_color: "#212121",
  },
  {
    accent_color: "#ff9800",
    background_color: "#f1f8e9",
    text_color: "#37474f",
  },
  {
    accent_color: "#e74c3c",
    background_color: "#f2f3f4",
    text_color: "#2c3e50",
  },
  {
    accent_color: "#ffab91",
    background_color: "#f1f8e9",
    text_color: "#424242",
  },
  {
    accent_color: "#ffcc80",
    background_color: "#5a7b8c",
    text_color: "#ffffff",
  },
  {
    accent_color: "#8760ef",
    background_color: "#ffffff",
    text_color: "#2f343e",
  },
  {
    accent_color: "#31d097",
    background_color: "#ffffff",
    text_color: "#44515b",
  },
  {
    accent_color: "#ff9800",
    background_color: "#ede7f6",
    text_color: "#212121",
  },
  {
    accent_color: "#1D1D76",
    background_color: "#F4B94D",
    text_color: "#FFFFFF",
  },
  {
    accent_color: "#70F1E8",
    background_color: "#09213E",
    text_color: "#FFFFFF",
  },
  {
    accent_color: "#132f71",
    background_color: "#EE834E",
    text_color: "#FFFFFF",
  },
  {
    accent_color: "#eb4b62",
    background_color: "#fcf078",
    text_color: "#2e0000",
  },
  {
    accent_color: "#ff6522",
    background_color: "#ffded6",
    text_color: "#07193a",
  },
  {
    accent_color: "#fcce19",
    background_color: "#397a59",
    text_color: "#fffcfa",
  },
  {
    accent_color: "#72cafd",
    background_color: "#ffffff",
    text_color: "#3a5d7b",
  },
  {
    accent_color: "#b0c0d1",
    background_color: "#254045",
    text_color: "#ffffff",
  },
  {
    accent_color: "#2f2829",
    background_color: "#f96c7a",
    text_color: "#ffffff",
  },
  {
    accent_color: "#8bbb91",
    background_color: "#ffffff",
    text_color: "#44515b",
  },
];

export default PRESET_PALETTES;
