import { Button, Form, ModalProps, Select, Slider, Tag } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { DefaultOptionType } from "antd/es/select";
import InfoTooltip from "components/Common/InfoTooltip";
import SegmentedFormItem from "components/Common/SegmentedFormItem";
import ModalWithBorders from "components/ModalWithBorders";
import PaletteColorBlocks from "components/PaletteColorBlocks";
import {
  getInputFieldConfig,
  getPdfInputFieldConfig,
  MemoryType,
  TaskFormItemProps,
  TaskInputForm,
} from "pages/Memory/components/utils";
import { useMemoriesQuery } from "pages/Memory/hooks/useMemoriesQuery";
import { useEffect, useMemo, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "store";
import { fetchThemesList } from "store/themes/themesActions";
import { currentBusinessGetter } from "store/user/userSlice";
import PRESET_PALETTES from "../PostEditor/ImageEditor/ColorPaletteSelect/PRESET_PALETTES";

export type GeneratePostsFrom = { type: "topic" | "memory"; id: string };

type GeneratePostForm = TaskInputForm & {
  generateWith: MemoryType | GeneratePostsFrom["type"];
  numberOfPosts: number;
  themes: string[];
  palettes: [];
  cta: string;
  text?: string;
  topic?: string;
  memory?: string;
};

const NewGeneratePanel = ({
  generateFrom,
  onSubmit,
  ...modalProps
}: {
  generateFrom?: GeneratePostsFrom;
  onSubmit: () => void;
} & ModalProps) => {
  const [form] = useForm<GeneratePostForm>();

  const dispatch = useAppDispatch();
  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { themes } = useAppSelector((state) => state.themes);

  const [showMoreSettings, setShowMoreSettings] = useState(false);

  const generateWith = useWatch("generateWith", form);

  const { data: memories, isLoading: memoriesLoading } = useMemoriesQuery();

  useEffect(() => {
    dispatch(fetchThemesList());
  }, []);

  useEffect(() => {
    if (generateFrom?.type) {
      const setValue = options[generateFrom.type]?.find(
        ({ value }) => value === generateFrom.id
      );
      setValue && form.setFieldValue(generateFrom.type, setValue);
    }
  }, [generateFrom]);

  const businessOptions = useMemo(
    () => ({
      topic: currentBusiness.topics.reduce((prev, { title, enabled }) => {
        enabled &&
          prev.push({
            label: title,
            value: title,
          });
        return prev;
      }, [] as DefaultOptionType[]),
      memory: memories?.map((memory) => ({
        label: memory.title,
        value: memory.id,
      })),
      palette: [
        ...(currentBusiness.brand.palettes || []),
        ...PRESET_PALETTES,
      ].map((palette, index) => ({
        label: <PaletteColorBlocks palette={palette} />,
        value: index,
      })),
    }),
    [memories, currentBusiness]
  );

  const themeOptions = useMemo(
    () =>
      themes.map((theme) => ({
        label: <img src={theme.image_url} className="h-[100px]" />,
        value: theme.name,
        key: theme.display_name,
      })),
    [themes]
  );

  const options = { ...businessOptions, themes: themeOptions };

  const generateWithInputFieldConfig: TaskFormItemProps<GeneratePostForm> =
    useMemo(() => {
      switch (generateWith) {
        case "topic":
          return {
            label: "Which topic do you want to use?",
            name: "topic",
            children: (
              <Select placeholder="Select a topic" options={options.topic} />
            ),
          };
        case "memory":
          return {
            input: <Select placeholder="Select a memory" />,
            label: "Which memory do you want to use?",
            name: "memory",
            children: (
              <Select
                loading={memoriesLoading}
                placeholder="Select a memory"
                options={options.memory}
              />
            ),
          };
        case "url":
          return {
            ...getInputFieldConfig("URL", true),
            label: "What link to do want to use to generate posts?",
          };
        case "pdf":
          return getPdfInputFieldConfig({
            label: "Upload a document to generate posts based on it's content",
            showAddToMemoryCheckbox: true,
          });
        default:
          return {
            label: "What do you want to post about?",
            name: "text",
            children: (
              <TextArea placeholder="Type in whatever you would like to post on." />
            ),
          };
      }
    }, [generateWith]);

  const getThemeName = (theme: string) =>
    themes.find(({ name }) => name === theme)?.display_name;

  const submitForm = (values: GeneratePostForm) => {
    console.info(values);
    onSubmit();
  };

  return (
    <ModalWithBorders
      classNames={{ body: "max-h-[75vh] overflow-auto" }}
      title="Generate Posts"
      open
      okText="Generate"
      onOk={form.submit}
      {...modalProps}
    >
      <Form
        onFinish={submitForm}
        id="generate-panel-form"
        initialValues={{
          generateWith: generateFrom?.type || "text",
          numberOfPosts: 5,
          theme: [],
          palettes: [],
          cta: null,
          addToMemory: true,
        }}
        layout="vertical"
        form={form}
      >
        <SegmentedFormItem
          options={["text", "topic", "memory", "url", "pdf"]}
          formItemProps={{
            label: "Generate with",
            name: "generateWith",
          }}
        />
        <Form.Item {...generateWithInputFieldConfig} />
        <Form.Item label="Number of Posts" name="numberOfPosts">
          <Slider
            marks={{ 1: 1, 5: 5, 10: 10, 15: 15 }}
            max={15}
            step={1}
            min={1}
          />
        </Form.Item>
        <Button
          onClick={() => setShowMoreSettings(!showMoreSettings)}
          icon={
            showMoreSettings ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />
          }
          type="link"
        >
          {showMoreSettings ? "Hide" : "More"} Settings
        </Button>
        {showMoreSettings && (
          <>
            <Form.Item
              label={
                <div className="flex items-center">
                  <span>Theme</span>
                  <InfoTooltip title="If none is selected, all the themes will be shuffled." />{" "}
                </div>
              }
              name="theme"
            >
              <Select
                tagRender={({ value, closable, onClose }) => (
                  <Tag closable={closable} onClose={onClose}>
                    {getThemeName(value)}
                  </Tag>
                )}
                placeholder="All Themes"
                loading={!themes.length}
                options={options.themes}
                mode="tags"
              />
            </Form.Item>
            <Form.Item
              label={
                <div className="flex items-center">
                  <span>Color Palettes</span>
                  <InfoTooltip title="If none is selected, all brand color palettes will be shuffled." />{" "}
                </div>
              }
              name="palettes"
            >
              <Select
                options={options.palette}
                placeholder="All Palettes"
                mode="tags"
              />
            </Form.Item>
            <Form.Item
              label={
                <div className="flex items-center">
                  <span>Call To Action</span>
                  <InfoTooltip title="This field is for the message or prompt that encourages your audience to take a specific action, such as 'Buy Now', 'Sign Up,' or 'Learn More.' It should be clear, concise, and motivating." />
                </div>
              }
              name="cta"
            >
              <Select
                placeholder="All CTAs"
                options={currentBusiness.ctas.map((cta) => ({
                  label: cta,
                  value: cta,
                }))}
                allowClear
              />
            </Form.Item>
          </>
        )}
      </Form>
    </ModalWithBorders>
  );
};

export default NewGeneratePanel;
