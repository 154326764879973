import Skeleton from "react-loading-skeleton";

const SchedulePostSkeleton = () => (
  <div className="scheduled-post-skeleton">
    <Skeleton width={120} height={120} />
    <div className="scheduled-post-skeleton__right">
      <div className="scheduled-post-skeleton__right__top">
        <Skeleton width={80} height={22} />
        <Skeleton width={22} height={22} />
      </div>
      <Skeleton height={20} style={{ marginBottom: 16 }} />
      <Skeleton height={41} />
    </div>
  </div>
);

export default SchedulePostSkeleton;
