import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { experimental_createPersister } from "@tanstack/react-query-persist-client";
import { Alert, Button, Empty, Tooltip } from "antd";
import { chaliceClient } from "api/baseClients";
import { PageStatsResponse } from "api/config/chalice-api";
import { deleteIntegration } from "api/integrations";
import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import dayjs from "dayjs";
import { INTEGRATION_ITEMS_DICT } from "fixtures/constants";
import { buildAuthURL } from "fixtures/socialAuthConfig";
import { del, get, set } from "idb-keyval";
import { range, sortBy } from "lodash";
import { useEffect, useMemo } from "react";
import { MdOutlineSync } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "store";
import { integrationsGetter } from "store/user/userSlice";
import { formatSocialName } from "utils/generalUtils";
import { ALL_TIME_PERIOD_OPTION } from "../postAnalyticsFixtures";
import usePostAnalyticsContext from "../usePostAnalytics";
import IntegrationStatCard from "./IntegrationsStatCard";

const IntegrationStats = ({ integrationId }: { integrationId: string }) => {
  const {
    themeNameState: [theme],
  } = useAppContext();
  const {
    periodState: [period],
    allTimeRangeState: [allTimeRange, setAllTimeRange],
  } = usePostAnalyticsContext();

  const integrations = useAppSelector(integrationsGetter);
  const currentIntegration = useMemo(
    () => integrations.find((integration) => integration.id === integrationId),
    [integrations]
  );

  const { integrationType, icon } = useMemo(() => {
    if (!currentIntegration) {
      return {
        integrationType: "",
        icon: "",
      };
    }

    return INTEGRATION_ITEMS_DICT[currentIntegration?.type];
  }, [currentIntegration?.type]);

  const {
    data: { data, error } = {},
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [
      "analyticsQuery",
      integrationId,
      dayjs().utc().format("YYYY-MM-DD"),
    ],
    queryFn: async () => {
      try {
        const response = await chaliceClient.get<PageStatsResponse>(
          `/integrations/${integrationId}/page-stats`
        );
        return response;
      } catch (error) {
        return {
          data: null,
          error: {
            error_type: null,
            error_classification: "system",
            message: null,
          },
        };
      }
    },
    persister: experimental_createPersister({
      storage: {
        getItem: get,
        setItem: set,
        removeItem: del,
      },
      // Cache data for 24 hours
      maxAge: 1000 * 60 * 60 * 24,
      filters: { predicate: (query) => !query.state.error },
    }),
    staleTime: () => {
      const now = dayjs().utc();
      return now.endOf("day").diff(now);
    },
  });

  const isConnectionError = error?.error_classification === "reconnect";
  const isSystemError = error?.error_classification === "system";

  useEffect(() => {
    if (period.value === ALL_TIME_PERIOD_OPTION.value && data) {
      const earliestDate = dayjs.min(
        data.flatMap((stat) => Object.keys(stat.data).map(dayjs))
      );

      earliestDate?.isBefore(allTimeRange?.start) &&
        setAllTimeRange({ start: earliestDate, end: dayjs() });
    }
  }, [period.value, data]);

  if (!currentIntegration) {
    return;
  }

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 sticky pt-2 pb-4 z-10 top-24 md:top-16 bg-antd-colorBgLayout">
        <img
          src={icon}
          className={classNames("!size-6", {
            "!h-8 object-cover": integrationType === "tiktok",
            invert: integrationType === "twitter" && theme === "dark",
          })}
        />
        <div className="font-semibold text-lg">
          {formatSocialName(integrationType)}{" "}
          <span className="text-antd-colorTextSecondary text-sm font-normal ml-2">
            {currentIntegration?.selected_page?.name ||
              `@${currentIntegration?.username}`}
          </span>
        </div>
        <Button
          size="small"
          type="text"
          className="text-antd-colorIcon ml-auto"
          icon={<MdOutlineSync size={16} />}
          onClick={() => refetch()}
        />
      </div>

      <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-4">
        {isLoading || isRefetching ? (
          error ? (
            <Skeleton containerClassName="col-span-full" className="h-24" />
          ) : (
            range(6).map((i) => <Skeleton key={i} className="h-72" />)
          )
        ) : error ? (
          <Alert
            className="col-span-full"
            description={
              isConnectionError
                ? `Please reconnect your ${formatSocialName(integrationType)} account to view analytics.`
                : isSystemError
                  ? "Sorry! Unknown system error. Please contact support for further assistance."
                  : error.message
            }
            action={
              (isSystemError || isConnectionError) && (
                <Button
                  onClick={() => {
                    // TODO: Extract to function in component
                    if (!isConnectionError) {
                      // TODO: Use `Crisp` (has types, different syntax for opening chat and creating messages)
                      // instead of untyped $crisp window object
                      $crisp?.push([
                        "set",
                        "message:text",
                        [
                          `Help! I am having issues with the analytics dashboard.` +
                            `${
                              error.error_type || error.message
                                ? ` This is the error I am getting: ${error.message || ""} (error_type=${error.error_type})`
                                : ""
                            }`,
                        ],
                      ]);
                      $crisp?.push(["do", "chat:open"]);

                      return;
                    }
                    currentIntegration &&
                      deleteIntegration(currentIntegration.id);
                    window.location.href = buildAuthURL({
                      socialType: currentIntegration.type,
                    });
                  }}
                >
                  {isConnectionError ? "Reconnect" : "Chat Support"}
                </Button>
              )
            }
            message={
              isConnectionError ? (
                "Reconnection Required"
              ) : (
                <div className="flex gap-2 items-center">
                  <span>
                    Issue connecting to {formatSocialName(integrationType)}
                  </span>
                  {isSystemError && error.error_type && (
                    <Tooltip
                      title={`${error.message || ""} (error_type = ${error.error_type})`}
                    >
                      <InfoCircleOutlined className="text-antd-colorIcon size-3.5" />
                    </Tooltip>
                  )}
                </div>
              )
            }
            type="error"
            showIcon
          />
        ) : data?.length === 0 ? (
          <Empty
            description={`${formatSocialName(integrationType)} does not provide any statistics.`}
            className="col-span-2 h-64 flex flex-col justify-center"
          />
        ) : (
          sortBy(data, "name")?.map((item, index) => (
            <IntegrationStatCard {...item} key={index} />
          ))
        )}
      </div>
    </div>
  );
};

export default IntegrationStats;
