import { Alert, Checkbox, message, Select } from "antd";
import Link from "antd/es/typography/Link";
import { Permissions } from "api/config/chalice-api";
import { updatePermissions } from "api/teams";
import ModalWithBorders from "components/ModalWithBorders";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import { genericError } from "fixtures/globalConstants";
import { isEqual } from "lodash";
import ChangeBusinessTierModal from "pages/AgencyDashboard/components/ChangeBusinessTierModal";
import { useEffect, useMemo, useState } from "react";
import { MdOutlineUpgrade } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Business } from "store/business/businessConstants";
import { currentBusinessGetter, editTeamMember } from "store/user/userSlice";
import {
  PermissionPreset,
  PERMISSIONS_CONFIG,
  PERMISSIONS_PRESET,
} from "./memberPermissionConstants";

const EditMemberPermissions = ({
  member,
  close,
}: {
  member?: Business["members"][number];
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const currentBusiness = useSelector(currentBusinessGetter);

  const [isBusinessUpgradeModalOpen, setIsBusinessUpgradeModalOpen] =
    useState(false);
  const [memberPermissions, setMemberPermissions] = useState<Permissions>(
    member?.permissions ?? {}
  );
  const [isSaving, setIsSaving] = useState(false);

  const isStandardBusinessPlan = currentBusiness.whitelabel_tier === "STANDARD";

  useEffect(() => {
    member && setMemberPermissions(member?.permissions);
  }, [member]);

  const presetValue = useMemo(() => {
    if (isEqual(memberPermissions, PERMISSIONS_PRESET.reviewer.permissions)) {
      return "reviewer";
    }

    if (
      isEqual(memberPermissions, PERMISSIONS_PRESET.contributor.permissions)
    ) {
      return "contributor";
    }

    return "Custom";
  }, [memberPermissions]);

  if (!member) {
    return null;
  }

  const handlePermissionUpdate = (
    updatePermissionKeys: PermissionString[],
    value: boolean,
    supersetPermission?: PermissionString
  ) => {
    let toggleOff: Permissions;

    if (!value) {
      toggleOff = PERMISSIONS_CONFIG.reduce<Permissions>((prev, config) => {
        if (
          config.supersetPermission &&
          updatePermissionKeys.includes(config.supersetPermission)
        ) {
          config.permissions.forEach((permission) =>
            permission.keys.forEach((key) => (prev[key] = false))
          );
        }
        return prev;
      }, {});
    }

    setMemberPermissions((prev) => ({
      ...prev,
      ...Object.fromEntries(updatePermissionKeys.map((key) => [key, value])),
      ...toggleOff,
      ...(supersetPermission && value && { [supersetPermission]: true }),
    }));
  };

  const saveChanges = async () => {
    setIsSaving(true);
    try {
      const res = await updatePermissions({
        businessId: currentBusiness.id,
        memberId: member.role_id,
        permissions: memberPermissions,
      });
      message.success("Changes saved successfully.");
      dispatch(
        editTeamMember({ permissions: res.role?.permissions, id: member.id })
      );
      close();
    } catch (error) {
      genericError(error);
    }
    setIsSaving(false);
  };

  return (
    <>
      <ModalWithBorders
        title="Edit Client Permissions"
        onCancel={close}
        open={!!member}
        onClose={close}
        okText="Save Changes"
        okButtonProps={{
          loading: isSaving,
          onClick: saveChanges,
          disabled: Object.values(memberPermissions).every(
            (permission) => !permission
          ),
        }}
        className="[&_.ant-modal-body]:overflow-auto [&_.ant-modal-body]:max-h-[75vh]"
      >
        <div className="space-y-5">
          {currentBusiness.whitelabel_tier === "STANDARD" && (
            <Alert
              icon={<MdOutlineUpgrade size={24} />}
              showIcon
              message={
                <p>
                  To enable contributor and custom permissions,{" "}
                  <Link
                    onClick={() => setIsBusinessUpgradeModalOpen(true)}
                    className="!underline"
                  >
                    upgrade this business
                  </Link>{" "}
                  to an Advanced plan.
                </p>
              }
              type="success"
            />
          )}
          <div className="space-y-2">
            <Select
              onChange={(value: PermissionPreset | "Custom") =>
                value !== "Custom" &&
                setMemberPermissions(PERMISSIONS_PRESET[value].permissions)
              }
              value={presetValue}
              options={Object.entries(PERMISSIONS_PRESET).map(
                ([value, { label }]) => ({
                  value,
                  label,
                  disabled: isStandardBusinessPlan && value !== "reviewer",
                })
              )}
              className="h-10 !w-64"
            />
            <p className="text-antd-colorTextSecondary font-normal">
              Setting permissions for {member.email}
            </p>
          </div>
          <div className="space-y-4">
            {PERMISSIONS_CONFIG.map((config) => (
              <div className="space-y-1.5" key={config.label}>
                <label className="font-medium">{config.label}</label>
                <div className="flex flex-col font-normal  gap-1">
                  {config.permissions.map((permission) => (
                    <Checkbox
                      onChange={(e) =>
                        handlePermissionUpdate(
                          permission.keys,
                          e.target.checked,
                          !permission.excludeFromSuperset
                            ? config.supersetPermission
                            : undefined
                        )
                      }
                      checked={permission.keys.every(
                        (key) => memberPermissions[key]
                      )}
                      disabled={isStandardBusinessPlan}
                      key={permission.label}
                    >
                      {permission.label}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalWithBorders>
      <ChangeBusinessTierModal
        modalType={isBusinessUpgradeModalOpen ? "upgrade" : null}
        close={() => setIsBusinessUpgradeModalOpen(false)}
        businessName={currentBusiness.title}
        businessId={currentBusiness.id}
      />
    </>
  );
};

export default EditMemberPermissions;
