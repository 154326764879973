import { ColorPicker, Form, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import { getColorsFromLogo } from "api/businesses";
import ImageUploader from "components/Common/ImageUploader";
import { SectionComponent } from "pages/NewBusinessOnboarding/businessFormConstants";
import { DEFAULT_PALETTE } from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/PRESET_PALETTES";
import { useState } from "react";
import { uploadToS3 } from "utils/generalUtils";

const YourBusiness: SectionComponent = ({ form }) => {
  const [isUploading, setIsUploading] = useState(false);

  const { getFieldValue, setFieldValue } = form;

  const updateImage = async (file?: File) => {
    if (!file) {
      setFieldValue("logoUrl", "");
      return;
    }

    setIsUploading(true);
    let imageUrl = "";

    try {
      imageUrl = await uploadToS3(file);
      setFieldValue("logoUrl", imageUrl);
    } catch (error) {
      message.error("Unable to upload image, please try again.");
      console.error(error);
    }

    if (imageUrl) {
      try {
        const colors = await getColorsFromLogo(imageUrl);
        setFieldValue(
          "accentColor",
          colors.accent_color ?? DEFAULT_PALETTE.accent_color
        );
      } catch (error) {
        console.error(error);
      }
    }

    setIsUploading(false);
  };

  return (
    <FormItem>
      <div className="[&_input]:!hidden space-y-4">
        <Form.Item name="logoUrl" label="Logo">
          <ImageUploader
            handleRemove={updateImage}
            handleLogoUpload={updateImage}
            isLoading={isUploading}
            image={getFieldValue("logoUrl")}
            name="logo"
          />
        </Form.Item>
        <Form.Item name="accentColor">
          <ColorPicker
            showText={() =>
              isUploading ? "Processing logo color..." : "Brand Color"
            }
            onChange={(value) =>
              setFieldValue("accentColor", value.toHexString())
            }
            disabledAlpha
            disabled={isUploading}
          />
        </Form.Item>
      </div>
    </FormItem>
  );
};

export default YourBusiness;
