import { Button, InputNumber, Tooltip } from "antd";
import { isNull, lowerCase } from "lodash";
import { observer } from "mobx-react-lite";
import { ElementType } from "polotno/model/group-model";
import { useEffect, useState } from "react";
import { PolotnoAnimationType } from "../../../polotnoTypes";
import {
  ANIMATION_DIRECTIONS,
  AnimationConfigType,
  DEFAULT_ANIMATION_SETTINGS,
} from "./animationConstants";

const AnimationSettings = observer(
  ({
    config: { showDuration, animations, showDelay, animationType },
    element,
  }: {
    config: AnimationConfigType;
    element: ElementType;
  }) => {
    const [selectedAnimation, setSelectedAnimation] = useState<string | null>(
      null
    );
    const [duration, setDuration] = useState<number | null>(null);
    const [delay, setDelay] = useState<number | null>(null);
    const [direction, setDirection] = useState<
      (typeof ANIMATION_DIRECTIONS)[number]["name"] | null
    >(null);

    const isDirectionalAnimation = selectedAnimation === "move";

    const handleAnimationChange = () => {
      if (
        isNull(selectedAnimation) ||
        isNull(duration) ||
        isNull(delay) ||
        (isDirectionalAnimation && isNull(direction))
      ) {
        return;
      }

      const appliedAnimations = element.animations || [];

      const updateAnimationsSettings = {
        name: selectedAnimation,
        duration: duration * 1000,
        delay: delay * 1000,
        ...(direction && { data: { direction } }),
      };

      let isCurrentAnimationTypeApplied = false;

      const finalAnimations = appliedAnimations.reduce(
        (acc: PolotnoAnimationType[], item: PolotnoAnimationType) => {
          if (item.type !== animationType) {
            acc.push(item);
          }

          if (selectedAnimation === "none") {
            isCurrentAnimationTypeApplied = true;
            return acc;
          }

          if (item.type === animationType && item.enabled) {
            acc.push({
              ...item,
              ...updateAnimationsSettings,
            });
            isCurrentAnimationTypeApplied = true;
          }

          return acc;
        },
        []
      );

      if (!isCurrentAnimationTypeApplied) {
        finalAnimations.push({
          ...updateAnimationsSettings,
          type: animationType,
          enabled: true,
        });
      }

      element.set({ animations: finalAnimations });
    };

    useEffect(handleAnimationChange, [
      selectedAnimation,
      direction,
      duration,
      delay,
    ]);

    useEffect(() => {
      const { name, duration, delay, data } =
        element.animations?.find(
          (item: PolotnoAnimationType) =>
            item.type === animationType && item.enabled
        ) || DEFAULT_ANIMATION_SETTINGS;

      setSelectedAnimation(name);
      setDuration(duration / 1000);
      setDelay(delay / 1000);
      data?.direction && setDirection(data.direction);
    }, [element]);

    return (
      <div className="space-y-5">
        <div className="grid grid-cols-3 gap-3">
          {animations.map((animation) => (
            <Button
              type={
                selectedAnimation === lowerCase(animation)
                  ? "primary"
                  : "default"
              }
              className="w-full h-16"
              onClick={() => setSelectedAnimation(lowerCase(animation))}
              key={animation}
            >
              {animation}
            </Button>
          ))}
        </div>
        {showDelay && selectedAnimation !== "none" && (
          <div className="space-y-1.5">
            <div>Delay</div>
            <InputNumber
              onChange={(value) => setDelay(value)}
              className="w-full"
              value={delay}
              type="number"
              step="1"
              suffix="s"
            />
          </div>
        )}
        {showDuration && selectedAnimation !== "none" && (
          <div className="space-y-1.5">
            <div>Duration</div>
            <InputNumber
              type="number"
              className="w-full"
              onChange={(value) => setDuration(value)}
              value={duration}
              step="1"
              suffix="s"
            />
          </div>
        )}
        {isDirectionalAnimation && (
          <div className="space-y-1.5">
            <span>Direction</span>
            <div className="flex gap-2 flex-wrap">
              {ANIMATION_DIRECTIONS.map(({ name, icon }) => (
                <Tooltip title={name} key={name}>
                  <Button
                    type={name === direction ? "primary" : "default"}
                    onClick={() => setDirection(name)}
                    className="aspect-square p-0"
                  >
                    {icon}
                  </Button>
                </Tooltip>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default AnimationSettings;
