import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select } from "antd";
import { LANGUAGES } from "fixtures/languages";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";

const LanguageSelect = ({
  currentValue,
  handleChange,
}: {
  currentValue: string;
  handleChange: (value: string) => void;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(LANGUAGES);

  const checkAddCustomOption = (addValue: string) =>
    setOptions((prevOptions) =>
      prevOptions.find(({ value }) => value === addValue)
        ? prevOptions
        : prevOptions.concat({ value: addValue })
    );

  useEffect(() => {
    checkAddCustomOption(currentValue);
  }, []);

  const containerRef = useRef<HTMLDivElement | null>(null);
  useClickAway(containerRef, () => setIsDropdownOpen(false));

  const handleLanguageChange = (value: string) => {
    handleChange(value);
    setIsDropdownOpen(false);
  };

  const addItem = () => {
    if (!inputValue) {
      return;
    }

    checkAddCustomOption(inputValue);
    handleLanguageChange(inputValue);
    setInputValue("");
  };

  return (
    <div ref={containerRef} onClick={(e) => e.stopPropagation()}>
      <Select
        open={isDropdownOpen}
        showSearch
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        onChange={handleLanguageChange}
        options={options}
        value={currentValue}
        getPopupContainer={() => containerRef.current ?? document.body}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider className="my-1" />
            <div className="flex items-center justify-between gap-2 p-1">
              <Input
                placeholder="Other (Please enter)"
                value={inputValue}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={addItem}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Add item
              </Button>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default LanguageSelect;
