import { message } from "antd";
import { AxiosRequestConfig, isAxiosError, isCancel } from "axios";
import { OpenAPIClient } from "openapi-client-axios";
import { Dispatch, SetStateAction } from "react";
import { screenSizes } from "utils/tailwindTheme";

export const MOBILE_SIDEBAR_BREAKPOINT = screenSizes.lg;
/**
 * Extends AxiosStatic and AxiosInstance so that `create` function is correctly added
 * Axios instances have `create` available, but not in the typedef. Open issue.
 *
 * https://github.com/axios/axios/issues/4680
 *
 * https://github.com/axios/axios/pull/5096
 */
export type OpenApiClientIntercepted = {
  request<T = unknown>(config: AxiosRequestConfig): Promise<T>;
  get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T>;
  put<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T>;
  patch<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T>;
} & OpenAPIClient;

export type UseStateReturn<T> = [T, Dispatch<SetStateAction<T>>];
export const VOID_FUNCTION = () => null;

export const genericError = (error: unknown) => {
  if (isCancel(error)) {
    return;
  }

  console.error(error);
  message.error(
    isAxiosError(error) && error.response?.data?.Message
      ? error.response.data.Message
      : error instanceof Error && error.message
        ? error.message
        : "Something went wrong, please try again."
  );
};

export const LONG_DATE_FORMAT = "ddd, MMM D, YYYY";
export const LONG_DATE_TIME_FORMAT = "MMMM D [at] h:mm A";
