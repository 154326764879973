import { Button, Dropdown, Input } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { MdAccessTime } from "react-icons/md";

const CustomPageDuration: ObserverComponent = observer(({ store }) => {
  const [inputValue, setInputValue] = useState<number | null>(null);

  useEffect(() => {
    store.activePage && setInputValue(store.activePage.duration / 1000);
  }, [store.activePage]);

  useEffect(() => {
    if (store.activePage && inputValue) {
      store.activePage.set({ duration: inputValue * 1000 });
    }
  }, [inputValue]);

  if (!store.activePage) {
    return <div />;
  }

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <div className="flex items-center gap-1.5 p-4 bg-antd-colorBgBase shadow-xl rounded">
          <Input
            type="number"
            onChange={(event) => {
              if (/^\d*\.?\d{0,3}$/.test(event.target.value)) {
                setInputValue(Number(event.target.value));
              }
            }}
            value={Number(inputValue)}
            className="h-8"
          />
          s
        </div>
      )}
      arrow
    >
      <Button icon={<MdAccessTime size={18} />} type="text" size="small">
        {inputValue}s
      </Button>
    </Dropdown>
  );
});

export default CustomPageDuration;
