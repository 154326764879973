import { Button, Empty } from "antd";
import cx from "classnames";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import useThemes from "hooks/useThemes";
import { range } from "lodash";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useClickAway } from "react-use";
import { trackEvent } from "utils/eventTracking/trackEvents";
import ThemeCard from "./ThemeCard";

export const THEMES_PAGE_ID = "themes";

const ThemesPage = () => {
  const { hasPermission } = useUserPermissions();
  const canEditBusiness = hasPermission("business_details_write");

  const {
    selectedThemes,
    isEditing,
    isSaving,
    themes,

    handleSelect,
    setIsEditing,
    handleSave,
  } = useThemes();

  const handleEdit = () => {
    if (themes.length) {
      setIsEditing((prevValue) => !prevValue);
    }
  };

  useEffect(() => {
    if (location.hash) {
      location.href = location.hash;
    }
  }, []);

  const pageRef = useRef<HTMLDivElement>(null);
  useClickAway(pageRef, () => setIsEditing(false));

  return (
    <div
      id={THEMES_PAGE_ID}
      className={cx("card", {
        "cursor-pointer": isEditing,
      })}
      ref={pageRef}
    >
      <header>
        <h2>Your Themes</h2>
        <span>
          Give your message a look with themes. Choose one or many to tell us
          what to use in making your posts.
        </span>
      </header>

      {!themes.length && !!selectedThemes.length && (
        <main
          onClick={() => setIsEditing(canEditBusiness)}
          className="themes-card__grid your-book__section__main"
        >
          {range(4).map((item) => (
            <Skeleton key={item} className="aspect-square" borderRadius={8} />
          ))}
        </main>
      )}

      {!isEditing && !!themes.length && !!selectedThemes.length && (
        <div
          className={cx("themes-card__grid your-book__section__main", {
            "cursor-pointer": canEditBusiness,
          })}
          onClick={() => setIsEditing(canEditBusiness)}
        >
          {themes
            .filter(({ name }) => selectedThemes.includes(name))
            .map((theme) => (
              <ThemeCard
                selectedThemes={selectedThemes}
                isEditing={false}
                key={theme.name}
                theme={theme}
              />
            ))}
        </div>
      )}

      {!selectedThemes.length && !isEditing && (
        <Empty className="p-20" description="No themes selected" />
      )}

      {isEditing && (
        <>
          <div className="themes-card__grid your-book__section__main max-h-[50vh] lg:max-h-[62vh]">
            {themes.map((theme) => (
              <ThemeCard
                isEditing
                theme={theme}
                key={theme.name}
                handleSelect={handleSelect}
                selectedThemes={selectedThemes}
              />
            ))}
          </div>
          <footer>
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={handleSave}
              loading={isSaving}
              disabled={!selectedThemes.length || isSaving}
            >
              {isSaving ? "Saving" : "Save"}
            </Button>
          </footer>
        </>
      )}
      {canEditBusiness && !isEditing && (
        <footer>
          <Button
            type="text"
            onClick={() =>
              trackEvent("refiner_launch_survey", {
                refinerArgs: {
                  surveyId: "c45fd610-3d6a-11ef-a28b-fd27e7eec795",
                  triggerSurvey: true,
                },
              })
            }
          >
            Request Theme
          </Button>
          <Button onClick={handleEdit} disabled={!themes.length}>
            View All
          </Button>
        </footer>
      )}
    </div>
  );
};

export default ThemesPage;
