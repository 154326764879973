import { Button, Input, message } from "antd";
import { updateUser } from "api/user";
import PhoneNumberInput from "components/Common/PhoneNumberInput";
import ModalWithBorders from "components/ModalWithBorders";
import { genericError } from "fixtures/globalConstants";
import { useCallback, useEffect, useState } from "react";
import { Value as PhoneValue } from "react-phone-number-input";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";

const AgencyNameModal = () => {
  const { pathname } = useLocation();

  const { userInfo, isAgencyOwner } = useAppSelector((state) => state.user);
  const [phone, setPhone] = useState(userInfo.phone);

  const [isLoading, setIsLoading] = useState(false);
  const [agencyName, setAgencyName] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    setAgencyName("");
    setIsOpen(false);
  }, []);

  const saveAgencyName = useCallback(async () => {
    setIsLoading(true);
    try {
      await updateUser({
        agency_name: agencyName,
        phone,
      });
      close();
      message.success("Agency details saved successfully.");
    } catch (error) {
      genericError(error);
    }
    setIsLoading(false);
  }, [agencyName, phone]);

  useEffect(() => {
    if (!userInfo.id) {
      return;
    }

    setIsOpen(pathname.includes("welcome") && isAgencyOwner);
  }, [userInfo, isAgencyOwner]);

  return (
    <ModalWithBorders
      title="Enter Agency Details"
      onCancel={close}
      open={isOpen}
      footer={
        <>
          <Button onClick={close}>Cancel</Button>
          <Button
            disabled={!agencyName || !phone}
            onClick={saveAgencyName}
            loading={isLoading}
            type="primary"
          >
            Save
          </Button>
        </>
      }
    >
      <div>
        <label
          style={{ marginBottom: 8, display: "block" }}
          htmlFor="agency-name"
        >
          Agency Name
        </label>
        <Input
          onChange={(e) => setAgencyName(e.target.value)}
          value={agencyName}
          id="agency-name"
        />
      </div>
      <PhoneNumberInput
        onChange={(value: PhoneValue) => setPhone(value)}
        value={phone as PhoneValue}
        showLabel
      />
    </ModalWithBorders>
  );
};

export default AgencyNameModal;
