import { Link } from "react-router-dom";
import { ImproveYourPostsCardConfig } from "./improveYourPostsFixture";

const ImproveYourPostsCard = ({
  title,
  subtitle,
  path,
  imageUrl,
  icon,
}: ImproveYourPostsCardConfig) => {
  const contents = (
    <div className="w-36 h-32 pt-3 pb-2 rounded-lg border border-antd-colorBorderSecondary hover:border-antd-colorBorder bg-antd-colorBgContainer hover:dark:bg-antd-colorFillContentHover transition-colors shadow-sm hover:shadow-lg cursor-pointer flex flex-col items-center justify-around gap-3">
      {imageUrl ? (
        <img src={imageUrl} />
      ) : (
        icon && (
          <div className="relative h-12 aspect-square">
            <icon.background
              size={48}
              className="absolute text-antd-colorInfoBgHover"
            />
            <icon.foreground
              size={48}
              className="absolute text-antd-colorInfo"
            />
          </div>
        )
      )}
      <div className="space-y-1">
        <p className="text-sm font-semibold">{title}</p>
        <p className="text-xs text-antd-colorTextSecondary">{subtitle}</p>
      </div>
    </div>
  );

  return path ? (
    <Link to={path} className="text-antd-colorTextBase">
      {contents}
    </Link>
  ) : (
    contents
  );
};
export default ImproveYourPostsCard;
