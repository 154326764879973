import { DesignRenderType, MixMatchChoicesBody } from "api/config/chalice-api";
import { TypeEnum } from "api/config/django-api";
import { PostStatus } from "store/posts/postConstants";
import { IntegrationType } from "store/user/userConstants";

/**
 * Events specific to Google Tag Manager
 *
 * `GtmEventData` has to match the GTM event name found at:
 * https://tagmanager.google.com/#/container/accounts/6228788264/containers/183922873/workspaces/21/tags
 *
 * Additional events: https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 */
export const GTM_EVENT_DATA = {
  used_post_editor: { converted_to_video: false },
  updated_business_content: { page: "" as "profile" | "branding" },
  updated_topics: { action: "" as "add" | "edit" | "delete" },
  created_custom_post: {
    used_post_editor: false,
    post_type: "" as DesignRenderType | null,
  },
  added_social: { type: "" as IntegrationType },
  added_memory: { type: "" as TypeEnum },
  generator_panel: {} as { body?: MixMatchChoicesBody },
  generated_posts: {} as { body?: MixMatchChoicesBody },
  new_post_decided: {
    decision: "" as Omit<PostStatus, "NEW">,
    approved: false,
  },
  view_item_list: {
    items: [
      {
        item_id: "",
        item_name: "",
      },
    ],
  },
  begin_checkout: {
    coupon: "",
    items: [
      {
        currency: "",
        value: 0,
        item_name: "",
        quantity: 0,
      },
    ],
  },
  purchase: {
    currency: "",
    value: 0,
    transaction_id: "",
    items: [
      {
        item_name: "",
      },
    ],
    attribution: "" as string | undefined,
  },
  experiment_viewed: {
    experiment_id: "",
    variation_id: "",
  },
};
