import { loadStripe } from "@stripe/stripe-js";
import { message } from "antd";
import { createCheckoutSession } from "api/stripe";
import { signup } from "api/user";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import { PageLoader } from "designSystem/Loader";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { setUserInfo } from "store/user/userSlice";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const CreateCheckoutSession = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { openGlobalModal } = useGlobalModal();

  const {
    userInfo: { isAnonymous },
    token,
  } = useAppSelector((state) => state.user);

  // TODO: Duplicate code/function name with PlanCard
  const initiateStripeCheckout = async () => {
    try {
      const stripe = await stripePromise;

      const session = await createCheckoutSession(
        {
          plan: searchParams.get("plan") ?? undefined,
        },
        {
          successUrl: `${
            window.location.origin
          }/welcome?nextPage=${encodeURIComponent("/business-metadata")}`,
        }
      );

      const result = await stripe?.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (!result || result.error) {
        console.error("Error redirecting to Stripe:", result?.error);
        message.error({
          key: "redirect-error",
          content: `Error redirecting to Stripe: ${result?.error}`,
        });
      }
    } catch (error) {
      console.error("Error initiating Stripe Checkout:", error);
      message.error({
        key: "checkout-error",
        content: `Error initiating Stripe Checkout: ${error}`,
      });
    }
  };

  const redirectToStripe = async () => {
    if (!token) {
      const res = await signup();
      dispatch(setUserInfo(res));
    }

    if (isAnonymous || !token) {
      openGlobalModal({
        modalName: "signup",
        modalData: {
          successCallback: initiateStripeCheckout,
          closeAfterSuccessCallback: true,
          isClosable: false,
        },
      });
    } else {
      initiateStripeCheckout();
    }
  };

  useEffect(() => {
    if ((isAnonymous !== null || !token) && searchParams.get("plan")) {
      // If isAnonymous is null but a token is present, then isAnonymous will
      // be updated to a boolean valueonce the user's data is retrieved

      redirectToStripe();
    }
  }, [searchParams, isAnonymous, token]);

  return <PageLoader />;
};

export default CreateCheckoutSession;
