import { Button, Dropdown } from "antd";
import { ItemType } from "antd/es/menu/interface";
import { MixMatchChoicesBody } from "api/config/chalice-api";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import { genericError } from "fixtures/globalConstants";
import MediaEditor from "pages/Posts/PostEditor/ImageEditor/MediaEditor";
import MiniEditorModal from "pages/Posts/PostEditor/MiniEditor/MiniEditorModal";
import GeneratePostPanel from "pages/Posts/components/GeneratePostPanel";
import { useMemo, useState } from "react";
import {
  MdAddCircleOutline,
  MdOutlineModeEdit,
  MdOutlineTune,
  MdOutlineUpload,
} from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { generatePostsThunk } from "store/posts/postActions";
import { GENERATED_POST_CONTAINER_ID } from "store/posts/postConstants";

type ModalType = "upload" | "design" | "generate";

const DROPDOWN_ITEMS: (ItemType & { permissionNeeded: PermissionString })[] = [
  {
    icon: <MdOutlineModeEdit size={16} />,
    key: "design",
    label: "Blank Design",
    permissionNeeded: "post_design_write",
  },
  {
    icon: <MdOutlineUpload size={16} />,
    key: "upload",
    label: "Upload",
    permissionNeeded: "post_design_write",
  },
  {
    icon: <MdOutlineTune size={16} />,
    key: "generate",
    label: "Custom Generate",
    permissionNeeded: "post_status_generate_write",
  },
];

const CreatePostDropdown = () => {
  const { hasPermission } = useUserPermissions();

  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState<ModalType | null>(null);

  const generateMorePosts = async (generateBody: MixMatchChoicesBody) => {
    try {
      await dispatch(generatePostsThunk(generateBody));
      setSearchParams({ tab: "generated" });
      setTimeout(() => {
        const generatedPostsContainer = document.getElementById(
          GENERATED_POST_CONTAINER_ID
        );

        generatedPostsContainer &&
          generatedPostsContainer.scrollTo({
            top: generatedPostsContainer.scrollHeight,
            behavior: "smooth",
          });
      }, 500);
    } catch (error) {
      genericError(error);
    }
  };

  const dropdownItems = useMemo(
    () =>
      DROPDOWN_ITEMS.flatMap(({ permissionNeeded, ...item }) =>
        hasPermission(permissionNeeded) ? item : []
      ),
    [hasPermission]
  );

  return dropdownItems.length ? (
    <>
      <Dropdown
        menu={{
          items: dropdownItems,
          onClick: ({ key }) => setShowModal(key as ModalType),
        }}
      >
        <Button icon={<MdAddCircleOutline size={18} />} size="small">
          <label className="max-lg:hidden">Custom Post</label>
        </Button>
      </Dropdown>
      {showModal === "upload" && (
        <MiniEditorModal close={() => setShowModal(null)} isOpen />
      )}
      <GeneratePostPanel
        generateMorePosts={generateMorePosts}
        close={() => setShowModal(null)}
        isOpen={showModal === "generate"}
      />
      <MediaEditor
        closeFullscreen={() => setShowModal(null)}
        isOpen={showModal === "design"}
      />
    </>
  ) : null;
};

export default CreatePostDropdown;
