import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import useAppContext from "config/AppContext/useAppContext";
import { validateValueLength } from "pages/Memory/components/utils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParam } from "react-use";
import { useAppSelector } from "store";
import { SignupCredentials } from "store/user/userConstants";
import { selectUserInfo } from "store/user/userSlice";
import { passwordValidators } from "utils/getPasswordErrorMessage";

const SignupForm = ({
  handleSubmit,
}: {
  handleSubmit: (credentials: SignupCredentials) => Promise<void>;
}) => {
  const isAppsumo = useSearchParam("source") === "appsumo";
  const isInvited = useSearchParam("isInvited");
  const emailFromURL = useSearchParam("email");

  const [signupForm] = useForm();

  const { realm } = useAppContext();

  const user = useAppSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (signupData: SignupCredentials) => {
    setIsLoading(true);
    await handleSubmit(signupData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user?.email) {
      signupForm.setFieldValue("email", user.email);
    } else if ((isAppsumo || isInvited) && emailFromURL) {
      signupForm.setFieldValue("email", emailFromURL);
    }
  }, [user.email, isAppsumo, isInvited]);

  return (
    <Form
      onFinish={submitForm}
      form={signupForm}
      layout="vertical"
      initialValues={{
        firstName: "",
        lastName: "",
        password: "",
        email: "",
      }}
    >
      <div className="w-full flex gap-4">
        <Form.Item
          rules={[
            {
              required: true,
              validator: (rule, value) =>
                validateValueLength(rule, value, "First Name"),
            },
          ]}
          label="First Name"
          className="flex-1"
          name="firstName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              validator: (rule, value) =>
                validateValueLength(rule, value, "Last Name"),
            },
          ]}
          label="Last Name"
          className="flex-1"
          name="lastName"
        >
          <Input />
        </Form.Item>
      </div>
      <Form.Item
        rules={[
          { required: true },
          {
            type: "email",
            message: "The input is not a valid email!",
          },
        ]}
        label="Email"
        name="email"
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }, ...passwordValidators]}
        label="Password"
        name="password"
      >
        <Input.Password name="password" />
      </Form.Item>

      <p className="signup__note">
        By signing up, you agree to {realm.isMarky ? "Marky's " : "our "}
        <Link
          to={
            realm.isMarky
              ? "https://www.mymarky.ai/legal/terms-and-conditions"
              : "/terms-of-service"
          }
          target="_blank"
        >
          Terms of Service
        </Link>
        {" and "}
        <Link
          to={
            realm.isMarky
              ? "https://www.mymarky.ai/legal/privacy-policy"
              : "/privacy-policy"
          }
          target="_blank"
        >
          Privacy Policy
        </Link>
      </p>
      <Button loading={isLoading} htmlType="submit" type="primary" block>
        Sign up
      </Button>
    </Form>
  );
};

export default SignupForm;
