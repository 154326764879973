import { LoadingOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Divider, Empty, message, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Brand } from "api/config/chalice-api";
import { getPresignedUrl } from "api/postsApi";
import axios from "axios";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { genericError } from "fixtures/globalConstants";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BiUpload } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";

const FontInput = ({
  options,
  fontKey,
  fonts,
}: {
  options: DefaultOptionType[];
  fontKey: keyof Pick<Brand, "body_font" | "header_font">;
  fonts: string[];
}) => {
  const { hasPermission } = useUserPermissions();

  const dispatch = useAppDispatch();

  const currentBusiness = useAppSelector((state) => state.user.currentBusiness);
  const [isUploadingFont, setIsUploadingFont] = useState(false);
  const [selectedFont, setSelectedFont] = useState("");

  const font = currentBusiness.brand?.[fontKey];

  const fontValue = useMemo(() => {
    if (
      !font ||
      !selectedFont?.includes("https") ||
      !selectedFont?.includes("__")
    ) {
      return selectedFont;
    }

    try {
      return decodeURIComponent(selectedFont).split("__").pop()?.split(".")[0];
    } catch (error) {
      return "Custom Uploaded Font";
    }
  }, [selectedFont, font]);

  const updateFont = useCallback(async () => {
    if ((!selectedFont && selectedFont !== null) || selectedFont === font) {
      return;
    }

    message.success("Font choice saved successfully.");
    localStorage.setItem("isFontUpdated", "true");

    await dispatch(
      updateBusinessDetails({
        [fontKey]: selectedFont,
      })
    );
  }, [selectedFont, font, fontKey]);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    try {
      const file = e.target.files[0];
      const fileType =
        file?.type || file?.name?.split(".")?.pop()?.toLowerCase();

      if (!fileType?.includes("ttf")) {
        return message.error("The font must be in TTF format.");
      }

      setIsUploadingFont(true);

      const { url } = await getPresignedUrl(file, "font/ttf");
      await axios.put(url, file, {
        headers: {
          "Content-Type": "font/ttf",
        },
      });
      const fontUrl = url.split("?")[0];
      setSelectedFont(fontUrl);
      message.success("Font uploaded successfully.");
    } catch (error) {
      genericError(error);
    }

    setIsUploadingFont(false);
  };

  useEffect(() => {
    updateFont();
  }, [selectedFont, updateFont]);

  useEffect(() => {
    setSelectedFont(font ?? "");
  }, [font]);

  return (
    <div className="your-brand__font-input-row">
      <ConfigProvider
        renderEmpty={() => (
          <Empty className="font-not-found" description="Font not found" />
        )}
      >
        <Select
          showSearch
          disabled={!hasPermission("business_details_write")}
          style={{ width: window.innerWidth < 900 ? "100%" : 300 }}
          className="font-selector"
          onChange={(value) => setSelectedFont(value)}
          value={fontValue}
          placeholder="Select from given fonts"
          loading={!fonts.length || isUploadingFont}
          options={options}
          popupClassName="fonts-select"
          dropdownRender={(menu) => (
            <div className="mb-1">
              {menu}
              <Divider className="my-2" />
              {isUploadingFont ? (
                <div className="font-input-loader">
                  <LoadingOutlined />
                </div>
              ) : (
                <Button size="small" type="text" className="w-full !p-0">
                  <label
                    htmlFor={fontKey}
                    className="w-full flex items-center gap-2 justify-center cursor-pointer p-2"
                  >
                    <BiUpload />
                    <input
                      className="hidden"
                      onChange={handleUpload}
                      accept="font/ttf"
                      id={fontKey}
                      type="file"
                    />
                    Upload Custom Font
                  </label>
                </Button>
              )}
            </div>
          )}
        />
      </ConfigProvider>
    </div>
  );
};

export default FontInput;
