import axios from "api/config/axiosConfig";
import Cookies from "js-cookie";
import { REFERRAL_PARAM } from "pages/ReferralReroute";
import { trackEvent } from "utils/eventTracking/trackEvents";

export const createCheckoutSession = async (
  body: { plan?: string; advanced_whitelabel_businesses?: number },
  params: unknown,
  coupon?: string
) => {
  const isUserReferredByAffiliate = document.cookie.includes("_fprom_tid");
  const promotion = coupon ?? (isUserReferredByAffiliate ? "affiliate" : "");
  trackEvent("begin_checkout", {
    coupon: promotion,
    items: [
      {
        currency: "USD",
        value: 20, // TODO: get this from stripe
        item_name: body.plan ?? "",
        quantity: 1,
      },
    ],
  });

  try {
    const referralCode = Cookies.get(REFERRAL_PARAM);
    const response = await axios.post(
      "/stripe/checkout",
      {
        ...body,
        referring_user_id: referralCode,
        ...(promotion && { promotion }),
      },
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    // @ts-expect-error update types for error
    throw new Error(error.response.data.message || error.response.data.Message);
  }
};

export const changePlan = async (body: unknown) => {
  try {
    const response = await axios.put("/stripe/subscription", body);
    return response.data;
  } catch (error) {
    // @ts-expect-error update types for error
    throw new Error(error.response.data.message || error.response.data.Message);
  }
};

export const createCustomerPortalSession = async () => {
  try {
    const response = await axios.get("/stripe/create-customer-portal-session");
    return response.data;
  } catch (error) {
    // @ts-expect-error update types for error
    throw new Error(error.response.data.message || error.response.data.Message);
  }
};
