import { useQuery } from "@tanstack/react-query";
import { getRealmDetails } from "api/agency";
import { isAxiosError } from "axios";
import { ENV_NAME, MARKY_REALM_ID } from "config/envVars";
import ThemeConfigProvider from "config/theme/ThemeConfigProvider";
import { PageLoader } from "designSystem/Loader";
import {
  MOBILE_SIDEBAR_BREAKPOINT,
  UseStateReturn,
  VOID_FUNCTION,
} from "fixtures/globalConstants";
import useCrisp from "hooks/useCrisp";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import {
  extractRealmDetails,
  RealmDetails,
  setFavicon,
} from "./appContextUtil";

export type AppContextType = {
  realm: RealmDetails;
  sidebarCollapsedState: UseStateReturn<boolean>;
  headerChildrenState: UseStateReturn<ReactNode>;
  themeNameState: ReturnType<typeof useLocalStorage<string>>;
  previewModeState: ReturnType<typeof useLocalStorage<boolean | null>>;
};

const domain = document.location.hostname;

const DEFAULT_MARKY_REALM: RealmDetails = {
  id: MARKY_REALM_ID,
  isMarky: true,
  domain,
  logoUrl:
    "https://marky-image-posts.s3.us-east-2.amazonaws.com/marky-new-logo.png",
};

const DEFAULT_NONMARKY_REALM: RealmDetails = {
  isMarky: false,
  domain,
  logoUrl:
    "https://marky-image-posts.s3.us-east-2.amazonaws.com/simple-agency-logo.png",
};

const DEFAULT_APP_CONTEXT: AppContextType = {
  sidebarCollapsedState: [
    window.innerWidth < MOBILE_SIDEBAR_BREAKPOINT,
    VOID_FUNCTION,
  ],
  headerChildrenState: [null, VOID_FUNCTION],
  realm:
    ENV_NAME !== "PRODUCTION" || domain.endsWith("mymarky.ai")
      ? DEFAULT_MARKY_REALM
      : DEFAULT_NONMARKY_REALM,
  themeNameState: ["default", VOID_FUNCTION, VOID_FUNCTION],
  previewModeState: [false, VOID_FUNCTION, VOID_FUNCTION],
};

export const AppContext = createContext(DEFAULT_APP_CONTEXT);

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const sidebarCollapsedState = useState(
    window.innerWidth < MOBILE_SIDEBAR_BREAKPOINT
  );
  const headerChildrenState = useState<ReactNode>(null);
  const [themeName, setThemeName, resetTheme] = useLocalStorage(
    "currentTheme",
    "default"
  );
  const previewModeState = useLocalStorage<boolean | null>(
    "isPreviewMode",
    null
  );

  const { data: realm, ...realmDetailsQuery } = useQuery({
    queryKey: ["realmDetails"],
    retry: false,
    initialData: DEFAULT_APP_CONTEXT.realm,
    queryFn: async () => {
      try {
        const { realm } = await getRealmDetails();
        return extractRealmDetails(realm);
      } catch (error) {
        // TODO: SWE-826 raise error in Sentry
        if (isAxiosError(error) && error.response?.status === 404) {
          throw new Error(
            `${window.location.pathname} has no associated realm.`
          );
        }
      }

      return DEFAULT_APP_CONTEXT.realm;
    },
  });

  useCrisp(realm);

  useEffect(() => {
    setFavicon(realm, realmDetailsQuery.isSuccess);
  }, [realm, realmDetailsQuery.isSuccess]);

  return (
    <ThemeConfigProvider {...{ realm, themeName }}>
      {realmDetailsQuery.isLoading ? (
        <PageLoader large />
      ) : !realmDetailsQuery.isError ? (
        <AppContext.Provider
          value={{
            realm,
            sidebarCollapsedState,
            headerChildrenState,
            previewModeState,
            themeNameState: [themeName, setThemeName, resetTheme],
          }}
        >
          {children}
        </AppContext.Provider>
      ) : (
        <div className="h-dvh pb-40 w-dvw flex flex-col gap-4 justify-center items-center">
          <p className="text-4xl font-medium text-antd-colorTextSecondary">
            404 not found
          </p>
          This domain is not registered. Please contact support.
        </div>
      )}
    </ThemeConfigProvider>
  );
};

export default AppContextProvider;
