import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { sendInviteEmail as sendInviteEmailApi } from "api/businesses";
import { inviteUserToTeam } from "api/teams";
import { LoginResponse, searchUserByEmail, signup } from "api/user";
import { isAxiosError } from "axios";
import CopyLinkBox from "components/Common/CopyLinkBox";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import ModalWithBorders from "components/ModalWithBorders";
import { genericError } from "fixtures/globalConstants";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getUserDetails, TypesafeChaliceUser } from "store/user/userActions";
import {
  autoSetCurrentBusiness,
  currentBusinessGetter,
  currentUserBusinessesGetter,
} from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { isEmailValid } from "utils/generalUtils";
import getInviteLink from "./getInviteLink";

const InviteNewUserModal = ({
  isAgencyLevelTeam,
  isClientsPage,
}: {
  isAgencyLevelTeam: boolean;
  isClientsPage: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { id: currentBusinessId } = useAppSelector(currentBusinessGetter);
  const {
    userInfo: currentUser,
    isAgencyMember,
    isAgencyOwner,
    ownedRealm,
  } = useAppSelector((state) => state.user);
  const businesses = useAppSelector(currentUserBusinessesGetter);
  const { openGlobalModal } = useGlobalModal();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<LoginResponse | null>(null);
  const [email, setEmail] = useState("");

  const isAgency = isAgencyMember || isAgencyOwner;

  const { btnText, title, noteText } = useMemo(() => {
    const linkShareText =
      "Send this link to your new team member. It will give them access to your business.";

    if (isClientsPage) {
      return {
        btnText: "Invite Client",
        title: "Add a Client",
        noteText: user
          ? "Share this link with your client to let them create an account and log in through your client portal."
          : "",
      };
    } else if (isAgencyLevelTeam) {
      return {
        btn: "Add User",
        title: "Add an Agency Team Member",
        noteText: user
          ? linkShareText
          : "An agency team member will have access to the agency and it's businesses.",
      };
    } else {
      return {
        btnText: isAgency ? "Add User" : "Invite Member",
        title: `${isAgency ? "Add a" : "Invite"} Team Member`,
        noteText: user
          ? linkShareText
          : "A member will have full edit access except for changing team members.",
      };
    }
  }, [isClientsPage, isAgency, user, isAgencyLevelTeam]);

  const isEmailInvalid = useMemo(() => {
    return email.trim() && !isEmailValid(email.trim());
  }, [email]);

  const inviteLink = useMemo(
    () =>
      user
        ? getInviteLink({
            token: user.token,
            email: user.user.email!,
            whiteLabelDomain:
              isClientsPage && ownedRealm ? ownedRealm.domain : undefined,
          })
        : "",
    [user, ownedRealm, isClientsPage]
  );

  const closeModal = () => {
    setEmail("");
    setUser(null);
    setIsLoading(false);
    setIsOpen(false);
  };

  const addUserToTeam = async (userId: string) => {
    try {
      if (isAgencyLevelTeam) {
        await Promise.all(
          businesses.map((business) =>
            inviteUserToTeam({
              userId,
              businessId: business.id,
            })
          )
        );
      } else {
        await inviteUserToTeam({
          userId,
          businessId: currentBusinessId,
        });
      }
    } catch (error) {
      genericError(error);
    }

    if (isAgencyLevelTeam) {
      dispatch(getUserDetails());
    } else {
      await dispatch(getUserDetails());
      dispatch(autoSetCurrentBusiness());
    }
  };

  const createNewUser = async () => {
    setIsLoading(true);
    try {
      const res = await signup(
        {
          email: email.trim(),
          ...(isClientsPage && ownedRealm && { realmId: ownedRealm.id }),
        },
        false
      );

      if (res.user.id) {
        await addUserToTeam(res.user.id);
        setUser(res);
      }
    } catch (error) {
      genericError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchForUser = async () => {
    if (email === currentUser.email) {
      return message.error("You can not invite yourself to the team.");
    }

    setIsLoading(true);
    try {
      const { user } = await searchUserByEmail(
        email,
        isClientsPage ? ownedRealm?.id : undefined
      );

      await addUserToTeam(user.id);

      closeModal();
      message.success("Invited user successfully");
    } catch (error) {
      if (
        isAxiosError(error) &&
        error?.response?.data?.Code === "NotFoundError"
      ) {
        await createNewUser();
      } else {
        setIsLoading(false);
        genericError(error);
      }
    }
  };

  const sendInviteEmail = async (user: TypesafeChaliceUser) => {
    setIsLoading(true);
    try {
      const { id, email } = user;

      await Promise.all(
        businesses.map(({ title, id: businessId }) =>
          sendInviteEmailApi(businessId, id).catch((error) => {
            console.error(error);
            message.error(`Invitation to ${title} failed to send.`);
          })
        )
      );

      closeModal();
      message.success(`Invite email sent successfully to ${email}.`);
    } catch (error) {
      genericError(error);
    }
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    if (!user) {
      searchForUser();
    }

    if (user) {
      sendInviteEmail(user.user);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !user && !isEmailInvalid && email) {
      handleButtonClick();
    }
  };

  const handleInviteNewUserClick = () => {
    if (!currentUser.subscription?.restrictions?.teams_enabled) {
      openGlobalModal({
        modalName: "subscription",
        modalData: { modalType: "upgradeToProfessional" },
      });
    }
    setIsOpen(true);
    trackEvent(isClientsPage ? "invited_client" : "invited_team_member");
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={handleInviteNewUserClick}>
        {btnText}
      </Button>

      <ModalWithBorders
        open={isOpen}
        onCancel={closeModal}
        title={title}
        footer={
          <>
            <Button onClick={closeModal}>{user ? "Close" : "Cancel"}</Button>
            {(!user || !isClientsPage) && (
              <Button
                disabled={isEmailInvalid || !email}
                onClick={handleButtonClick}
                loading={isLoading}
                type="primary"
              >
                {user ? "Send Invite Email" : "Add"}
              </Button>
            )}
          </>
        }
      >
        <div className="space-y-2 [&_label]:font-medium">
          {!user ? (
            <>
              <label htmlFor="email-input">Email</label>
              <Input
                id="email-input"
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                placeholder={isAgency ? "Email" : "Team Member Email"}
                value={email}
                onKeyDown={handleKeyDown}
              />
              {isEmailInvalid && (
                <span className="text-antd-colorError">
                  Please enter a valid email
                </span>
              )}
            </>
          ) : (
            <CopyLinkBox label="Signup Link" link={inviteLink} />
          )}
        </div>

        {noteText && (
          <div className="mt-4 flex gap-2 text-antd-colorTextSecondary">
            <InfoCircleOutlined className="h-5" />
            <p>{noteText}</p>
          </div>
        )}
      </ModalWithBorders>
    </>
  );
};

export default InviteNewUserModal;
