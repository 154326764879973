import { Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ContentTopic } from "api/config/chalice-api";
import CrawlWebsiteToggle from "components/Common/CrawlWebsiteToggle";
import DescriptionStrengthIndicator from "components/Common/DescriptionStrengthIndicator";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import ModalWithBorders from "components/ModalWithBorders";
import useOmnibox from "hooks/useOmnibox";
import ConfirmNewTopicsModal from "pages/Topics/legacy/ConfirmNewTopicsModal";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "store";
import { updateBusinessDetails } from "store/business/businessActions";

const ImportFromWebsiteModal = ({
  close,
  recreateAccount,
}: {
  close: () => void;
  recreateAccount?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { openGlobalModal } = useGlobalModal();
  const {
    crawlEntireSite,
    inputValue,
    isURLValid,
    isLoading,

    setCrawlEntireSite,
    setInputValue,
    getChapters,
  } = useOmnibox({ defaultCrawlEntireSite: false });

  const [content, setContent] = useState("");
  const [confirmTopics, setConfirmTopics] = useState<ContentTopic[] | null>(
    null
  );

  const isButtonDisabled = useMemo(
    () =>
      Boolean(
        inputValue ? !isURLValid : !content || (content && content.length < 100)
      ),
    [inputValue, isURLValid, content]
  );

  const handleClose = () => {
    setCrawlEntireSite(true);
    close();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isButtonDisabled) {
      return;
    }

    if (event.key === "Enter" && (inputValue ? isURLValid : content)) {
      getBusinessData;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [inputValue, recreateAccount, isURLValid, content]);

  const getBusinessData = async () => {
    const res = await getChapters(content);

    if (!res) {
      return;
    }

    const { logo_url, ...businessData } = res;

    if (recreateAccount) {
      await dispatch(
        updateBusinessDetails({ logo: logo_url, ...businessData })
      );
      openGlobalModal({
        modalName: "create-business",
        modalData: {
          updatedBusinessDetails: {
            ...businessData,
            logoUrl: logo_url,
            scrapedImages: businessData.image_urls,
          },
        },
      });
      close();
    } else {
      setConfirmTopics(businessData.topics);
    }
  };

  return confirmTopics ? (
    <ConfirmNewTopicsModal close={close} topics={confirmTopics} />
  ) : (
    <ModalWithBorders
      centered
      width={window.innerWidth > 768 ? 730 : window.innerWidth - 30}
      className="import-from-website"
      title={recreateAccount ? "Change Business" : "Add Content Topics"}
      open
      closable={false}
      maskClosable={!isLoading}
      footer={
        <>
          <Button
            className="apply-changes-btn"
            onClick={close}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={isLoading}
            onClick={getBusinessData}
            disabled={isButtonDisabled}
          >
            {recreateAccount ? "Change Business" : "Pull Content Topics"}
          </Button>
        </>
      }
      onCancel={handleClose}
    >
      <div>
        <label htmlFor="website" className="mb-1">
          Website URL
        </label>
        <Input
          placeholder="Drop a link to your website, blog post, news article, etc."
          onChange={(e) => {
            content && setContent("");
            setInputValue(e.target.value);
          }}
          status={inputValue && !isURLValid ? "error" : undefined}
          disabled={isLoading}
          value={inputValue}
          id="website"
        />
        {inputValue && !isURLValid && (
          <span className="warning">Please enter a valid website link</span>
        )}
        {isURLValid && (
          <CrawlWebsiteToggle
            handleChange={(e) => setCrawlEntireSite(e.target.value)}
            checked={crawlEntireSite}
          />
        )}
      </div>
      <span className="divider">OR</span>
      <div>
        <div className="flex items-center gap-2 mb-1">
          <label htmlFor="new-content">Description</label>
          {content && <DescriptionStrengthIndicator text={content} />}
        </div>
        <TextArea
          id="new-content"
          placeholder="Describe in detail what you want to post about"
          onChange={(e) => {
            inputValue && setInputValue("");
            setContent(e.target.value);
          }}
          disabled={isLoading}
          value={content}
          autoSize={{
            minRows: 2,
            maxRows: 10,
          }}
        />
        {content && content.length < 100 && (
          <span className="warning">
            Description should be at least 100 characters long
          </span>
        )}
      </div>

      {recreateAccount && (
        <p className="import-from-website__note">
          Warning: This will <span>DELETE</span> your entire current business
          and branding and replace it with what we pull from your website. It
          will regenerate your posts, but won't delete and drafts or scheduled
          posts.
        </p>
      )}
    </ModalWithBorders>
  );
};

export default ImportFromWebsiteModal;
