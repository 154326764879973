import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Popover } from "antd";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import MagicPencil from "assets/icons/MagicPencil";
import classNames from "classnames";
import PremiumFeatureModal, {
  PremiumFeatureIcon,
} from "components/GlobalModals/PremiumFeatureModal";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import EmojiPicker, { SkinTones, Theme } from "emoji-picker-react";
import { UseStateReturn } from "fixtures/globalConstants";
import { isRTLLanguage } from "fixtures/languages";
import { DEFAULT_EMOJI_SKIN_TONE } from "fixtures/localStorageKeys";
import useUpdatePostActions from "pages/Posts/utils/useUpdatePostActions";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { useLocalStorage } from "react-use";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import { VIDEO_EXTENSIONS } from "utils/validateFileUpload";
import CaptionWatermark from "./CaptionWatermark";
import CustomAiRefineModal from "./CustomAiRefineModal";
import {
  CAPTION_MODIFY_MENU,
  CAPTION_MODIFY_MENU_ITEMS,
  CaptionModifyMenuKey,
} from "./miniEditorConstants";

const PostCaptionEditor = ({
  captionState: [caption, setCaption],
  mediaUrls,
}: {
  captionState: UseStateReturn<string>;
  mediaUrls: string[];
}) => {
  const {
    themeNameState: [theme],
  } = useAppContext();
  const { loading, getModifiedCaption, getCaptionForImage } =
    useUpdatePostActions();

  const { hasPermission } = useUserPermissions();
  const canEditCaption = hasPermission("caption_write");

  const business = useAppSelector(currentBusinessGetter);
  const {
    can_use_ai_refine: canAiRefine = true,
    post_caption_watermark: captionWatermark = false,
  } = business.owner.subscription?.restrictions ?? {};

  const [showModal, setShowModal] = useState<
    "custom-ai-refine" | "premium-feature" | null
  >(null);
  const [captionHistory, setCaptionHistory] = useState([caption]);
  const [captionPointer, setCaptionPointer] = useState(0);

  const [emojiSkinTone, setEmojiSkinTone] = useLocalStorage<SkinTones>(
    DEFAULT_EMOJI_SKIN_TONE,
    SkinTones.NEUTRAL
  );
  const textAreaRef = useRef<TextAreaRef>(null);
  const insertEmojiPos = useRef(0);
  const captionRef = useRef(caption);
  if (captionRef.current !== caption) captionRef.current = caption;

  useEffect(() => {
    const shiftCaption = (which: "undo" | "redo") => {
      if (
        (which === "undo" && captionPointer > 0) ||
        (which === "redo" && captionPointer < captionHistory.length - 1)
      ) {
        const newPointer = captionPointer + (which === "undo" ? -1 : 1);
        setCaptionPointer(newPointer);
        setCaption(captionHistory[newPointer]);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === "z" &&
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey
      ) {
        shiftCaption("redo");
      } else if (event.key === "z" && (event.metaKey || event.ctrlKey)) {
        shiftCaption("undo");
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [captionPointer, captionHistory]);

  const updateCaption = async (modifyInstruction: string) => {
    const newCaption = await getModifiedCaption({
      modifyInstruction,
      caption,
    });
    const newHistory = [
      ...captionHistory.slice(0, captionPointer + 1),
      newCaption as string,
    ];
    setCaptionHistory(newHistory);
    setCaptionPointer(newHistory.length - 1);
    newCaption && setCaption(newCaption);
  };

  const isVideo = (mediaUrl: string) => {
    const extension = mediaUrl.split(".").pop()?.toLowerCase() || "";
    return VIDEO_EXTENSIONS.includes(extension);
  };

  const disableGenerateCaptionButton = useMemo(
    () => mediaUrls.every(isVideo),
    [mediaUrls]
  );

  const generateCaption = async () => {
    if (!mediaUrls.length) {
      return;
    }

    const { caption } = await getCaptionForImage(
      mediaUrls.filter((mediaUrl) => !isVideo(mediaUrl))[0]
    );
    caption && setCaption(caption);
  };

  const handleModifyItemClick = (key: CaptionModifyMenuKey) => {
    if (!canAiRefine) {
      setShowModal("premium-feature");
    } else if (key === "custom") {
      setShowModal("custom-ai-refine");
    } else if (CAPTION_MODIFY_MENU[key].prompt) {
      updateCaption(CAPTION_MODIFY_MENU[key].prompt);
    } else {
      console.error(`Caption modifier ${String(key)} does not have a prompt`);
    }
  };

  const insertEmoji = (emoji: string) => {
    const textAreaCursor =
      textAreaRef.current?.resizableTextArea?.textArea.selectionStart;

    if (textAreaCursor) {
      // textAreaRef...selectionStart is reset once the textArea loses focus
      // Maintain last cursor position
      insertEmojiPos.current = textAreaCursor;
    }

    const emojiIndex = insertEmojiPos.current || captionRef.current.length;
    setCaption(
      `${captionRef.current.slice(0, emojiIndex)}${emoji}${captionRef.current.slice(emojiIndex)}`
    );

    if (insertEmojiPos.current) {
      insertEmojiPos.current += emoji.length;
    }
  };

  const commonModalProps = {
    open: true,
    onClose: () => setShowModal(null),
    onCancel: () => setShowModal(null),
  };

  const isRTL = useMemo(() => isRTLLanguage(caption), [caption]);

  return (
    <>
      <div className="flex gap-2 mb-1">
        <label htmlFor="post-caption">Caption</label>
        {!caption.length && mediaUrls.length > 0 && (
          <Button
            type="link"
            className="!p-0 !h-auto"
            disabled={loading === "caption" || disableGenerateCaptionButton}
            onClick={generateCaption}
            icon={loading === "caption" ? <LoadingOutlined /> : null}
          >
            (Generate Caption)
          </Button>
        )}
      </div>
      <TextArea
        ref={textAreaRef}
        className={classNames("mt-1 max-md:!max-h-64 !overflow-y-auto", {
          "rounded-b-none": captionWatermark,
          "text-right": isRTL,
        })}
        id="post-caption"
        placeholder="Text"
        autoSize={{ minRows: 5, maxRows: 20 }}
        readOnly={!canEditCaption}
        disabled={Boolean(loading)}
        value={caption}
        onChange={({ target }) => setCaption(target.value)}
      />
      {captionWatermark && <CaptionWatermark watermark={captionWatermark} />}
      {canEditCaption && (
        <>
          <div className="text-right text-antd-colorTextSecondary">
            {caption.length} Characters
          </div>
          <div className="flex gap-2 items-center">
            {caption.length > 0 && (
              <Dropdown
                menu={{
                  items: CAPTION_MODIFY_MENU_ITEMS,
                  onClick: ({ key }) =>
                    handleModifyItemClick(key as CaptionModifyMenuKey),
                }}
              >
                <Button
                  loading={loading === "caption"}
                  size="small"
                  icon={canAiRefine ? <MagicPencil /> : <PremiumFeatureIcon />}
                >
                  AI Refine <DownOutlined />
                </Button>
              </Dropdown>
            )}
            <Popover
              className="[&_.ant-popover-inner]:!p-0"
              overlayClassName="[&_.ant-popover-inner]:!p-0"
              placement="bottomRight"
              showArrow={false}
              arrow={false}
              content={
                <EmojiPicker
                  height={248}
                  onEmojiClick={({ emoji }) => insertEmoji(emoji)}
                  theme={theme === "dark" ? Theme.DARK : Theme.AUTO}
                  defaultSkinTone={emojiSkinTone}
                  onSkinToneChange={setEmojiSkinTone}
                  previewConfig={{ showPreview: false }}
                />
              }
            >
              <Button size="small" icon={<MdOutlineEmojiEmotions />} />
            </Popover>
          </div>
        </>
      )}

      {showModal === "custom-ai-refine" ? (
        <CustomAiRefineModal
          {...commonModalProps}
          applyInstructions={(instructions) => {
            setShowModal(null);
            updateCaption(instructions);
          }}
        />
      ) : (
        showModal === "premium-feature" && (
          <PremiumFeatureModal
            {...commonModalProps}
            title="AI Refine"
            content="Unlock AI Refine to automatically refine your captions."
          />
        )
      )}
    </>
  );
};

export default PostCaptionEditor;
