import { Button, Modal } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import { buildAuthURL } from "fixtures/socialAuthConfig";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { IntegrationType } from "store/user/userConstants";

type InstagramNoteModalProps = {
  integrationName: IntegrationType;
  isError?: boolean;
  isOpen: boolean;
  close: () => void;
};

const InstagramNoteModal = ({
  integrationName = "facebook",
  isError,
  isOpen,
  close,
}: InstagramNoteModalProps) => {
  const isFacebook = integrationName === "facebook";

  const { realm } = useAppContext();

  const notes = useMemo(() => {
    if (isFacebook) {
      return [];
    }

    return [
      "You will first be redirected to Facebook sign-in.",
      "Unfortunately, Facebook requires that you have a Facebook page to use Instagram.",
      "Your Instagram account must be a business account.",
      "You must have a Facebook page (it can be a dummy page).",
      `You must connect your Instagram to your Facebook page. ${
        realm.isMarky && (
          <a
            href="https://help.mymarky.ai/en/article/how-to-connect-an-instagram-account-to-marky-a5jeiz/?bust=1708090816780"
            target="_blank"
          >
            See how
          </a>
        )
      }`,
      "We recommend you to click <strong>Opt into all current and future</strong> (as shown in the image below) when prompted during the auth flow.",
    ];
  }, [integrationName]);

  return (
    <Modal
      open={isOpen}
      className="facebook-note-modal"
      footer={
        <Footer
          close={close}
          isError={isError}
          integrationName={integrationName}
        />
      }
      title={isError ? "Did you remember?" : "Please read before you connect"}
      onCancel={close}
      centered
    >
      <div className="facebook-note-modal__notes">
        {notes.map((note, i) => (
          <div key={i} className="flex">
            {i + 1}.{" "}
            <div
              dangerouslySetInnerHTML={{ __html: note }}
              style={{ marginLeft: 5 }}
            />
          </div>
        ))}
      </div>
      {isFacebook && (
        <div>
          <div>
            {isError ? "Did you remember" : "We recommend you"} to click{" "}
            <strong>Opt into all current and future</strong> (as shown in the
            image below) {isError ? "" : "when prompted"} during the auth flow.
            {!isError && (
              <span className="subtitle">
                (Don't worry, you'll be able to choose later which page you want
                to post to)
              </span>
            )}
          </div>
        </div>
      )}
      <img
        src="https://marky-image-posts.s3.us-east-2.amazonaws.com/futurebusiness_360.png"
        alt=""
        className="screenshot"
      />
    </Modal>
  );
};

const Footer = ({
  close,
  isError,
  integrationName,
}: Omit<InstagramNoteModalProps, "isOpen">) => {
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = () => {
    setIsLoading(true);
    window.location.href = buildAuthURL({
      socialType: integrationName,
      pathname,
    });
    close();
  };

  return (
    <div className="flex gap-2 justify-end">
      {isError ? (
        <Button onClick={close}>Close</Button>
      ) : (
        <>
          <Button onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleContinue} loading={isLoading}>
            {window.innerWidth < 500 || integrationName === "facebook"
              ? "Continue"
              : "I have done these things, continue"}
          </Button>
        </>
      )}
    </div>
  );
};

export default InstagramNoteModal;
