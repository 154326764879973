import { useMemo } from "react";

const DescriptionStrengthIndicator = ({ text }: { text: string }) => {
  const descriptionStrength = useMemo(() => {
    if (text.length >= 1 && text.length <= 99) {
      return "Weak";
    } else if (text.length >= 100 && text.length <= 249) {
      return "So-so";
    } else if (text.length >= 250 && text.length <= 299) {
      return "Good";
    } else if (text.length >= 300) {
      return "Great";
    }

    return "None";
  }, [text]);

  return (
    <div className="strength-indicator-container">
      <div
        className={`strength-indicator strength-indicator--${descriptionStrength.toLowerCase()}`}
      >
        <span />
        <span />
        <span />
        <span />
      </div>
      <span className="strength">{descriptionStrength}</span>
    </div>
  );
};

export default DescriptionStrengthIndicator;
