import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import IntegrationsList from "./components/IntegrationList/IntegrationsList";
import PostScheduleManager from "./components/PostScheduleManager/PostScheduleManager";

const Integrations = () => {
  const { realm } = useAppContext();

  const { hasPermission } = useUserPermissions();

  return (
    <div className="relative flex flex-col gap-6 items-center max-lg:w-full max-lg:px-4">
      {hasPermission("posting_schedule_write") && <PostScheduleManager />}
      {hasPermission("socials_write") && (
        <div className="card">
          <header>
            <h2>Connect Socials</h2>
            <span>
              Connect your social media accounts to allow
              {realm.isMarky ? " Marky " : " your agency "} to post on your
              behalf.
            </span>
          </header>
          <IntegrationsList />
        </div>
      )}
    </div>
  );
};

export default Integrations;
