import { ColorPicker, ColorPickerProps } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { currentBusinessGetter } from "store/user/userSlice";

const ColorPickerWithPresets = (props: ColorPickerProps) => {
  const currentBusiness = useSelector(currentBusinessGetter);

  const presetColors = useMemo(() => {
    if (!currentBusiness.brand.palettes) {
      return [];
    }

    return currentBusiness.brand.palettes.map((palette, index) => ({
      label: `Palette ${index + 1}`,
      colors: Object.values(palette),
    }));
  }, [currentBusiness]);

  return (
    <ColorPicker
      {...props}
      getPopupContainer={() =>
        document.querySelector(".go3456988929") as HTMLElement
      }
      presets={presetColors}
    />
  );
};

export default ColorPickerWithPresets;
