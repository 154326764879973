import { Modal } from "antd";
import { useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";
import useGlobalModal from "./GlobalModalContext/useGlobalModal";

const PostLimitModal = ({
  closeModal,
  isOpen,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const { postLimitInfo } = useAppSelector(selectPostStore);
  const { openGlobalModal } = useGlobalModal();

  return (
    <Modal
      title="🏁 You've just reached your monthly post generation limit"
      onOk={() => openGlobalModal({ modalName: "subscription" })}
      onCancel={closeModal}
      cancelText="Dismiss"
      okText="Upgrade"
      open={isOpen}
      centered
    >
      You have generated {postLimitInfo?.count_generated_posts} posts this
      month! That's some great progress. Wait until next month for your post
      generations to refresh, or upgrade to a paid plan to instantly generate
      even more posts.
    </Modal>
  );
};

export default PostLimitModal;
