import { UseQueryResult } from "@tanstack/react-query";
import { Form, Input, message } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { djangoClient } from "api/baseClients";
import { Document, TypeEnum } from "api/config/django-api";
import { isAxiosError } from "axios";
import SegmentedFormItem from "components/Common/SegmentedFormItem";
import ModalWithBorders from "components/ModalWithBorders";
import { genericError } from "fixtures/globalConstants";
import { useEffect, useMemo, useState } from "react";
import { trackEvent } from "utils/eventTracking/trackEvents";
import {
  getInputFieldConfig,
  TaskInputForm,
  validateValueLength,
} from "./utils";

type ImportMemoryOrTopicForm = TaskInputForm & {
  title: string;
  type: TypeEnum;
  crawl?: boolean;
};

const AddTopicOrMemory = ({
  entityType,
  refetchMemoryList,
  isOpen,
  close,
}: {
  refetchMemoryList: UseQueryResult["refetch"];
  entityType: "topic" | "memory";
  close: () => void;
  isOpen: boolean;
}) => {
  const [form] = useForm<ImportMemoryOrTopicForm>();

  const isEntityTypeTopic = entityType === "topic";

  const [isLoading, setIsLoading] = useState(false);

  const inputType = useWatch("type", form);

  const memoryTypeConfig = useMemo(
    () => getInputFieldConfig(inputType),
    [inputType]
  );

  const closeModal = () => {
    close();
    form.resetFields();
  };

  const createMemory = async ({
    description,
    title,
    crawl,
    type,
    file,
    url,
  }: ImportMemoryOrTopicForm) => {
    setIsLoading(true);
    let body: Partial<Document> | FormData;

    switch (type) {
      case "URL":
        body = {
          crawl,
          title,
          type,
          url,
        };
        break;
      case "FILE":
        body = new FormData();
        body.append("title", title);
        body.append("type", type);
        if (file) {
          body.append("file", file);
          body.append("name", file?.name);
        }
        break;
      default:
        body = {
          title,
          type,
          text_content: description,
        };
        break;
    }

    try {
      await djangoClient.v1_documents_create(null, body as Document);
      await refetchMemoryList();
      closeModal();
      isEntityTypeTopic
        ? trackEvent("updated_topics", { action: "add" })
        : trackEvent("added_memory", { type });
      message.success("Memory created successfully.");
    } catch (error) {
      if (isAxiosError(error) && error.response?.data[0]) {
        console.error(error);
        // TODO: Need help from BE for how to display general error message/how it's returned
        message.error(error.response?.data[0]);
      } else {
        genericError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (inputType === "URL") {
      form.setFieldValue("crawl", true);
    }
  }, [inputType]);

  return (
    <ModalWithBorders
      title={isEntityTypeTopic ? "Import Topics" : "Add New Memory"}
      okButtonProps={{ loading: isLoading }}
      onOk={() => form.submit()}
      onCancel={closeModal}
      okText="Import"
      open={isOpen}
    >
      <Form
        onFinish={(values) => createMemory(values)}
        id="create-memory-form"
        initialValues={
          isEntityTypeTopic ? { type: "URL" } : { type: "TEXT", title: "" }
        }
        layout="vertical"
        form={form}
        requiredMark={false}
      >
        {!isEntityTypeTopic && (
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                validator: (rule, value) =>
                  validateValueLength(rule, value, "title"),
              },
            ]}
          >
            <Input placeholder="My new memory" />
          </Form.Item>
        )}
        <SegmentedFormItem
          formItemProps={{
            rules: [{ required: true }],
            label: "Type",
            name: "type",
          }}
          options={["text", "url", "pdf"]}
        />
        <Form.Item
          {...memoryTypeConfig}
          rules={[
            {
              required: true,
              ...(memoryTypeConfig.name === "description" && {
                validator: (rule, value) =>
                  validateValueLength(rule, value, "description"),
              }),
            },
          ]}
        />
      </Form>
    </ModalWithBorders>
  );
};

export default AddTopicOrMemory;
