import { Button, Input, message } from "antd";
import { updateUser } from "api/user";
import PasswordRequirements from "components/Common/PasswordRequirements";
import ModalWithBorders from "components/ModalWithBorders";
import { useCallback, useMemo, useState } from "react";
import getPasswordErrorMessage from "utils/getPasswordErrorMessage";

const UpdatePassword = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isButtonDisabled = useMemo(() => {
    return (
      !newPassword ||
      Object.values(getPasswordErrorMessage(newPassword)).some(
        (value) => !value
      )
    );
  }, [newPassword]);

  const updatePassword = useCallback(async () => {
    setIsLoading(true);
    try {
      await updateUser({ password: newPassword });
      message.success("Password updated successfully");
      close();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [newPassword, close]);

  return (
    <ModalWithBorders
      centered
      title="Update Password"
      open={isOpen}
      closable={!isLoading}
      footer={
        <div className="flex gap-2 justify-end">
          <Button
            className="apply-changes-btn"
            disabled={isLoading}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={updatePassword}
            loading={isLoading}
            disabled={isButtonDisabled}
          >
            Update
          </Button>
        </div>
      }
      onCancel={close}
      maskClosable={!isLoading}
    >
      <form className="form-wrapper">
        <div>
          <label htmlFor="old-password">New Password</label>
          <Input.Password
            type="password"
            id="new-password"
            name="newPassword"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            autoComplete="off"
            required
          />
          <PasswordRequirements password={newPassword} />
        </div>
      </form>
    </ModalWithBorders>
  );
};

export default UpdatePassword;
