import dayjs from "dayjs";

const getSeparator = (url: string) => (url.includes("?") ? "&" : "?");

const addStaticCacheBusterParam = (url: string | undefined | null) => {
  if (!url || url.startsWith("blob")) {
    return url ?? "";
  }
  return `${url}${getSeparator(url)}no_cache=1`;
};

export const addDateCacheBusterParam = (url: string) =>
  `${url}${getSeparator(url)}date=${dayjs().valueOf()}`;

export default addStaticCacheBusterParam;
