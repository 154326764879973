import { Button, Tooltip } from "antd";
import { MixMatchChoicesBody } from "api/config/chalice-api";
import { ValidGlobalModalData } from "components/GlobalModals/GlobalModalContext/globalModalConfig";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { genericError } from "fixtures/globalConstants";
import { SLIDE_UP_ANIMATION } from "fixtures/motionAnimations";
import { motion } from "framer-motion";
import AutoFindStockModal from "pages/ImageLibrary/AutoFindStockModal/AutoFindStockModal";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { MdOutlineTune } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "store";
import { generatePostsThunk } from "store/posts/postActions";
import { selectPostStore } from "store/posts/postSlice";
import { currentBusinessGetter, selectUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import GeneratePostPanel from "./GeneratePostPanel";

const GenerateMorePostsButton = () => {
  const dispatch = useAppDispatch();

  const { openGlobalModal } = useGlobalModal();

  const { isCurrentBusinessOwner, hasPermission } = useUserPermissions();

  const {
    postLimitInfo,
    generated: { isGenerating, loadingCount, postList },
  } = useAppSelector(selectPostStore);

  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { imagesInLibrary, isLoadingImages } = useAppSelector(
    (state) => state.imageLibrary
  );
  const {
    preferences: { image_sources: imageSources } = { image_sources: [] },
  } = useAppSelector(currentBusinessGetter);
  const user = useAppSelector(selectUserInfo);

  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isPostGeneratorPanelOpen, setIsPostGeneratorPanelOpen] =
    useState(false);

  const isOnlyMediaLibrarySource =
    imageSources?.length === 1 && imageSources.includes("media_library");

  const buttonText = useMemo(() => {
    return loadingCount
      ? `${pluralize("post", loadingCount, true)} loading`
      : "Generate Posts";
  }, [loadingCount]);

  const generateMorePosts = async (
    fromPanel?: boolean,
    generateBody?: MixMatchChoicesBody
  ) => {
    try {
      await dispatch(generatePostsThunk(generateBody));
      fromPanel
        ? trackEvent("generator_panel", { body: generateBody })
        : trackEvent("generated_posts", { body: generateBody });
    } catch (error) {
      genericError(error);
    }
  };

  const handleClick = (callback: () => void) => {
    if (
      postLimitInfo?.count_allowed_posts &&
      postLimitInfo.count_allowed_posts === postLimitInfo.count_generated_posts
    ) {
      return openGlobalModal({
        modalName: "post-limit",
      } as ValidGlobalModalData);
    }

    if (user.isAnonymous) {
      return openGlobalModal({
        modalName: "signup",
        modalData: {
          successCallback: callback,
          closeAfterSuccessCallback: true,
        },
      });
    }

    if (
      isCurrentBusinessOwner &&
      !imagesInLibrary?.length &&
      !isLoadingImages &&
      isOnlyMediaLibrarySource
    ) {
      setIsAddImageModalOpen(true);
    } else {
      callback();
    }
  };

  useEffect(() => {
    postLimitInfo?.count_allowed_posts &&
      postLimitInfo.count_allowed_posts ===
        postLimitInfo?.count_generated_posts &&
      postLimitInfo.count_allowed_posts === postList.length &&
      openGlobalModal({ modalName: "post-limit" } as ValidGlobalModalData);
  }, [postLimitInfo, postList]);

  if (!hasPermission("post_status_generate_write")) {
    return;
  }

  return (
    <motion.div
      {...SLIDE_UP_ANIMATION}
      transition={{
        delay: 0.3,
      }}
      className="delay-300"
    >
      <div className="flex gap-2">
        <Button
          loading={isGenerating}
          onClick={() => handleClick(generateMorePosts)}
          className="btn-hover-gradient"
          type="primary"
        >
          {buttonText}
        </Button>
        <Tooltip title="More options">
          <Button
            onClick={() => handleClick(() => setIsPostGeneratorPanelOpen(true))}
            disabled={isGenerating}
            icon={<MdOutlineTune size={18} />}
          />
        </Tooltip>
      </div>
      {postLimitInfo?.count_allowed_posts &&
        currentBusiness.user_id === user.id && (
          <p className="text-xs text-antd-colorTextSecondary mt-3 text-center">
            {postLimitInfo.count_generated_posts} /{" "}
            {postLimitInfo.count_allowed_posts} posts generated
          </p>
        )}
      <GeneratePostPanel
        close={() => setIsPostGeneratorPanelOpen(false)}
        generateMorePosts={(generateBody: MixMatchChoicesBody) =>
          generateMorePosts(true, generateBody)
        }
        isOpen={isPostGeneratorPanelOpen}
      />
      <AutoFindStockModal
        isOnlyMediaLibrarySource={isOnlyMediaLibrarySource}
        close={() => setIsAddImageModalOpen(false)}
        isOpen={isAddImageModalOpen}
        isGlobal
      />
    </motion.div>
  );
};

export default GenerateMorePostsButton;
