import { Button, Input } from "antd";
import { setAuthToken } from "api/config/axiosConfig";
import { getFrillSSOToken, login } from "api/user";
import GoogleLogin from "components/Common/GoogleLogin";
import useAppContext from "config/AppContext/useAppContext";
import LogoLink from "config/theme/LogoLink";
import { genericError } from "fixtures/globalConstants";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import AuthWrapper from "layout/AuthWrapper";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { getUserDetails } from "store/user/userActions";
import { autoSetCurrentBusiness, setUserInfo } from "store/user/userSlice";
import { invalidClientAccountMessage } from "utils/generalUtils";

const Login = () => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { realm } = useAppContext();

  const [searchParams] = useSearchParams();

  const isFrillSSO = localStorage.isFrillSSO === "true";
  const redirect = localStorage.redirect;

  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const isButtonDisabled = useMemo(() => {
    return !credentials.email || !credentials.password || isLoading;
  }, [credentials, isLoading]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCredentials((prevValue) => {
      return {
        ...prevValue,
        [event.target.name]: event.target.value,
      };
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const response = await login(credentials);
        setAuthToken(response.token);
        dispatch(setUserInfo(response));

        const userDetail = await dispatch(getUserDetails()).unwrap();
        dispatch(autoSetCurrentBusiness());

        if (isFrillSSO) {
          const { token } = await getFrillSSOToken();
          localStorage.removeItem("isFrillSSO");
          localStorage.removeItem("redirect");
          window.location.href = `${redirect}?ssoToken=${token}`;
          return;
        }

        if (!userDetail.businesses?.[0]?.title) {
          if (realm.isMarky) {
            navigate("/business-metadata");
          } else {
            invalidClientAccountMessage(realm);
          }
        } else {
          navigate(
            response.user.is_agency ? "/dashboard" : defaultBusinessRoute
          );
        }
      } catch (error) {
        genericError(error);
      }
      setIsLoading(false);
    },
    [credentials, redirect, isFrillSSO, defaultBusinessRoute]
  );

  return (
    <AuthWrapper>
      <div className="auth auth--login">
        <LogoLink linkToDomain />
        <h3>Log in</h3>

        {realm.isMarky && (
          <>
            <p>
              Don’t have an account?{" "}
              <Link
                to={`/sign-up${
                  searchParams.get("accountType") ? "?accountType=agency" : ""
                }`}
              >
                Sign up
              </Link>
            </p>
            <GoogleLogin buttonText="Log in with Google" />
          </>
        )}
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="auth__input-field">
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              name="email"
              data-testid="email-input"
              value={credentials.email}
              onChange={(e) => {
                handleChange(e);
              }}
              required
            />
          </div>
          <div className="auth__input-field">
            <label htmlFor="password">Password</label>
            <Input.Password
              type="password"
              id="password"
              name="password"
              data-testid="password-input"
              value={credentials.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="auth__forgot-password-container">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
          <Button
            data-testid="log-in-button"
            disabled={isButtonDisabled}
            loading={isLoading}
            htmlType="submit"
            type="primary"
            block
          >
            Log in
          </Button>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default Login;
