import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import {
  HTMLProps,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type SelectableImageCardProps = {
  id: string;
  src: string;
  isUploading?: boolean;
  isSelected: boolean;
  clickHandler: (e: React.MouseEvent) => void;
  onError?: HTMLProps<HTMLImageElement>["onError"];
};

const SelectableImageCard = ({
  id,
  src,
  isUploading,
  isSelected,
  clickHandler,
  onError,
}: SelectableImageCardProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const imageSrc = useMemo(() => {
    if (src.startsWith("blob:")) {
      return src;
    }

    const url = new URL(src, window.location.origin);
    url.searchParams.append("selectable", "");

    return url.toString();
  }, [src]);

  useLayoutEffect(() => {
    imgRef.current?.complete && setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
  }, [imageSrc]);

  return (
    !hasError && (
      <div
        className={cx(
          "selectable-image-card rounded-md overflow-hidden select-none",
          {
            "selectable-image-card--selected": isSelected,
            "selectable-image-card--disabled": isUploading,
          }
        )}
        onClick={clickHandler}
        key={id}
        id={id}
      >
        <img
          ref={imgRef}
          loading="lazy"
          draggable={false}
          src={imageSrc}
          onError={(e) => {
            setHasError(true);
            onError && onError(e);
          }}
          onLoad={() => setIsLoading(false)}
        />
        {(isUploading || isLoading) && (
          <div className="h-full w-full flex items-center justify-center absolute top-0 text-white bg-black/40">
            <LoadingOutlined />
          </div>
        )}
        <div className="selected-icon-container">
          <CheckOutlined className="selected-icon" />
        </div>
      </div>
    )
  );
};

export default SelectableImageCard;
