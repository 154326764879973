import { useQuery } from "@tanstack/react-query";
import { djangoClient } from "api/baseClients";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";

export const useMemoriesQuery = () => {
  const currentBusiness = useAppSelector(currentBusinessGetter);

  return useQuery({
    queryKey: ["memories", currentBusiness.id],
    queryFn: async () => {
      const { data } = await djangoClient.v1_documents_list();
      return data.results;
    },
    refetchInterval: ({ state: { data } }) =>
      data?.some((item) =>
        ["EXTRACTING", "PENDING", "INDEXING"].includes(item.status)
      )
        ? 5000
        : false,
  });
};
