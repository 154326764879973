import { Modal, ModalProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const CustomAiRefineModal = ({
  applyInstructions,
  ...modalProps
}: ModalProps & {
  applyInstructions: (instruction: string) => void;
}) => {
  const [customInstructions, setCustomInstructions] = useState("");
  return (
    <Modal
      {...modalProps}
      centered
      title="Custom Instructions"
      onOk={() => applyInstructions(customInstructions)}
    >
      <TextArea
        placeholder="Enter"
        value={customInstructions}
        onChange={({ target }) => setCustomInstructions(target.value)}
      />
    </Modal>
  );
};

export default CustomAiRefineModal;
