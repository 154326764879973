import { ColorType, Palette } from "api/config/chalice-api";
import { PolotnoPage } from "../../polotnoTypes";

export type CustomColorType = Exclude<ColorType, "STATIC" | "LOGO_BACKGROUND">;

type RgbList = [number, number, number];

export const BLACK_WHITE_HEXES = ["#000000", "#ffffff"];

export const getBestContrastBW = (hexCode: string) => {
  const contrasts = BLACK_WHITE_HEXES.map((hex) => contrastRatio(hexCode, hex));
  const maxContrastIndex = contrasts.indexOf(Math.max(...contrasts));

  return BLACK_WHITE_HEXES[maxContrastIndex];
};

export const contrastRatio = (hex1: string, hex2: string) => {
  const l1 = relativeLuminance(hexToRgb(hex1));
  const l2 = relativeLuminance(hexToRgb(hex2));
  return (l2 + 0.05) / (l1 + 0.05);
};

const relativeLuminance = ([r, g, b]: RgbList) =>
  r * 0.2126 + g * 0.7152 + b * 0.0722;

const hexToRgb = (hexColor: string): RgbList => {
  let hex = hexColor.replace("#", "");

  // Handle shorthand notation (#RGB)
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse the expanded hex (#RRGGBB)
  const lv = hex.length;
  return [
    parseInt(hex.substring(0, lv / 3), 16),
    parseInt(hex.substring(lv / 3, (2 * lv) / 3), 16),
    parseInt(hex.substring((2 * lv) / 3), 16),
  ];
};

const normalizedLuminance = (rgbList: RgbList) => {
  const [r, g, b] = rgbList.map((color) => {
    const colorPercent = color / 255;
    return colorPercent < 0.039828
      ? colorPercent / 12.92
      : ((colorPercent + 0.055) / 1.055) ** 2.4;
  });

  return r * 0.2126 + g * 0.7152 + b * 0.0722;
};

// More about APCA: https://github.com/xi/apca-introduction
export const apcaContrast = (
  foregroundColor: string,
  backgroundColor: string
): number => {
  const lum1 = Math.max(normalizedLuminance(hexToRgb(foregroundColor)), 0.01);
  const lum2 = Math.max(normalizedLuminance(hexToRgb(backgroundColor)), 0.01);

  const contrast = Math.abs((lum1 - lum2) / Math.max(lum1, lum2));
  return contrast * 100;
};

const getColorMapping = (palette: Palette) => ({
  ON_ACCENT: getBestContrastBW(palette.accent_color ?? "#000000"),
  BACKGROUND: palette.background_color,
  ACCENT: palette.accent_color,
  TEXT: palette.text_color,
});

export const updateColors = (page: PolotnoPage, palette: Palette) => {
  const colorMapping = getColorMapping(palette);
  const pageColor = page.custom?.colorType;
  if (pageColor && colorMapping[pageColor]) {
    page.set({
      background: colorMapping[pageColor],
    });
  }

  page.children.forEach((element) => {
    if (element.custom) {
      const color = colorMapping[element.custom.colorType as CustomColorType];
      const strokeColor =
        colorMapping[element.custom.strokeColorType as CustomColorType];

      if (element.type === "text" && color) {
        element.set({ fill: color });
      }

      if (element.type === "svg" && color) {
        Object.keys(element.colorsReplace.toJSON()).forEach((key) =>
          element.replaceColor(key, color)
        );
      }

      if (element.type === "figure" && color) {
        element.set({ fill: color });
      }

      if (element.type === "figure" && strokeColor) {
        element.set({ stroke: strokeColor });
      }
    }
  });
};
