import { message } from "antd";
import { setAuthToken } from "api/config/axiosConfig";
import { trackSignup } from "api/firstpromoter";
import { getFrillSSOToken, login, signup, updateUser } from "api/user";
import GoogleLogin from "components/Common/GoogleLogin";
import SignupForm from "components/Common/SignupForm";
import useAppContext from "config/AppContext/useAppContext";
import LogoLink from "config/theme/LogoLink";
import { genericError } from "fixtures/globalConstants";
import { SHOW_TOPICS_TOUR } from "fixtures/localStorageKeys";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import AuthWrapper from "layout/AuthWrapper";
import { useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { getUserDetails } from "store/user/userActions";
import { SignupCredentials } from "store/user/userConstants";
import {
  autoSetCurrentBusiness,
  selectUserInfo,
  setShowOnboardingTour,
} from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";

const SignUp = () => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, setShowTopicsTour] = useLocalStorage(SHOW_TOPICS_TOUR);
  const { isAnonymous } = useAppSelector(selectUserInfo);

  const {
    realm: { isMarky },
  } = useAppContext();

  const [searchParams] = useSearchParams();

  const isInvited = searchParams.get("isInvited");
  const isAppsumo = searchParams.get("source") === "appsumo";
  const isFrillSSO = localStorage.isFrillSSO === "true";
  const redirect = localStorage.redirect;

  const title = useMemo(() => {
    if (isAnonymous) {
      return "Enjoying the app? Sign up";
    }

    if (isAppsumo || isInvited) {
      return "Welcome";
    }

    return "Sign up";
  }, [isAnonymous, isAppsumo, isInvited]);

  const subtitle = useMemo(() => {
    if (isAppsumo || isInvited) {
      return "Setup your account.";
    }

    return "Already have an account?";
  }, [isAppsumo, isInvited]);

  const handleSubmit = async (credentials: SignupCredentials) => {
    let signupTracked = false;

    try {
      if (isAnonymous) {
        await updateUser({
          ...credentials,
          given_name: credentials.firstName,
          family_name: credentials.lastName,
        });
      } else if (isAppsumo || isInvited) {
        await updateUser(
          {
            ...credentials,
            given_name: credentials.firstName,
            family_name: credentials.lastName,
          },
          null,
          searchParams.get("token")
        );
        const response = await login({
          email: credentials.email,
          password: credentials.password,
        });
        setAuthToken(response.token);
        localStorage.setItem("token", response.token);
      } else {
        const response = await signup({
          ...credentials,
        });
        signupTracked = true;
        trackSignup({ email: credentials.email, uid: response.user.id });
      }

      setShowTopicsTour(true);
      dispatch(setShowOnboardingTour(true));
      const userDetails = await dispatch(getUserDetails()).unwrap();
      dispatch(autoSetCurrentBusiness());

      if (isAnonymous) {
        message.success("Signed up successfully");
      }

      !signupTracked && trackEvent("sign_up");

      window.$crisp?.push(["set", "session:event", ["user:created"]]);

      if (isFrillSSO) {
        const { token } = await getFrillSSOToken();
        localStorage.removeItem("isFrillSSO");
        localStorage.removeItem("redirect");
        window.location.href = `${redirect}?ssoToken=${token}`;
        return;
      }

      navigate(
        isAnonymous || userDetails.businesses.length
          ? defaultBusinessRoute
          : "/business-metadata"
      );
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "User already exists") {
          navigate("/login");
        }
        message.error(error.message);
      } else {
        genericError(error);
      }
    }
  };

  return (
    <AuthWrapper>
      <div className="auth auth--sign-up">
        <LogoLink linkToDomain />
        <h3>{title}</h3>
        <p className="auth__have-an-account">
          {subtitle}{" "}
          {!isAppsumo && !isInvited && <Link to="/login">Sign in</Link>}
        </p>
        {!isAppsumo && !isInvited && isMarky && (
          <GoogleLogin buttonText="Sign up with Google" />
        )}

        <SignupForm handleSubmit={handleSubmit} />
      </div>
    </AuthWrapper>
  );
};

export default SignUp;
