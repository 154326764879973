import { Subscription } from "api/config/chalice-api";
import { SubscriptionInterval } from "components/GlobalModals/SubscriptionModal/subscriptionConstants";

export type CouponConfig = {
  isActive: boolean;
  couponCode: string;
  displayName: string;
  discountPercent: number;
  limitToPlans?: Subscription["plan"][];
  limitToIntervals?: SubscriptionInterval[];

  bannerTitle: string;
  sidebarTitle?: string;
  bannerSubtitle?: string;
  openOnInterval?: SubscriptionInterval;
};

const ALL_COUPONS: CouponConfig[] = [
  {
    isActive: true,
    couponCode: "annual50",
    displayName: "ANNUAL50",
    discountPercent: 0.5,
    limitToPlans: ["free", "personal-monthly", "personal-yearly"],
    limitToIntervals: ["yearly"],

    bannerTitle: "☘️ Claim 50% Off Annual Plans",
    sidebarTitle: "☘️ Claim 50% Off Annually",
    bannerSubtitle:
      "Lucky You! Get 50% Off Your First Year for St. Patrick's Day! ☘️",
    openOnInterval: "yearly",
  },
  {
    isActive: false,
    couponCode: "annual50",
    displayName: "ANNUAL50",
    discountPercent: 0.5,
    limitToPlans: ["free"],
    limitToIntervals: ["yearly"],

    bannerTitle: "🎉 Claim 50% Off Annual Plans",
    sidebarTitle: "🎉 Claim 50% Off Annually",
    bannerSubtitle:
      "Get 50% off your first year when purchasing an annual plan",
    openOnInterval: "yearly",
  },
];

export const getActiveCoupons = ({
  interval,
  currentPlan,
}: {
  interval?: SubscriptionInterval;
  currentPlan: Subscription["plan"];
}) =>
  ALL_COUPONS.filter(
    ({ isActive, limitToIntervals, limitToPlans }) =>
      isActive &&
      (!interval || !limitToIntervals || limitToIntervals.includes(interval)) &&
      (!limitToPlans || limitToPlans.includes(currentPlan))
  );
