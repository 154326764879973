import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import {
  SLIDE_LEFT_ANIMATION,
  SLIDE_RIGHT_ANIMATION,
  SLIDE_UP_ANIMATION,
} from "fixtures/motionAnimations";
import { AnimatePresence, motion } from "framer-motion";
import {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Post } from "store/posts/postConstants";
import PostEditor, { PostEditorType } from "../PostEditor/PostEditor";
import { LoadingType } from "../utils/useUpdatePostActions";

export type PostCardCommonProps = {
  openFullScreenEditor: () => void;
  openMiniEditor: (instant?: boolean) => void;
};

/** Wrap a Post Card component with state to control the PostEditor modal, and animations. */
const PostCardWrapper = ({
  post,
  loadingPostAction,
  children,
}: {
  post: Post;
  loadingPostAction: LoadingType | false;
  children: FunctionComponent<PostCardCommonProps>;
}) => {
  const { hasPermission } = useUserPermissions();

  const [showEditor, setShowEditor] = useState<PostEditorType | null>(null);
  const [miniByDefault, setMiniByDefault] = useState(false);
  const [hidePost, setHidePost] = useState(false);

  // This is extracted to an effect/state so that the post becomes hidden on the next tick after
  // loadingPostAction updates. This is necessary so that the animation inferred from
  // loadingPostAction is passed to the motion.div component before it exits the DOM.
  useEffect(() => {
    if (
      (loadingPostAction &&
        ["save", "schedule", "delete", "publish"].includes(
          loadingPostAction
        )) ||
      (loadingPostAction === "update-review-status" && post.status === "NEW")
    ) {
      setHidePost(true);
    } else {
      setHidePost(false);
    }
  }, [loadingPostAction]);

  let miniEditorTimeout: ReturnType<typeof setTimeout>;
  const openMiniEditor = (isInstant?: boolean) => {
    const setToMiniEditor = () => {
      setMiniByDefault(true);
      setShowEditor("mini");
    };

    if (post.status === "PUBLISHED") {
      return;
    }

    if (isInstant) {
      setToMiniEditor();
    } else {
      // This is to prevent the mini editor from opening on the first click of the double click event
      clearTimeout(miniEditorTimeout);
      miniEditorTimeout = setTimeout(setToMiniEditor, 300);
    }
  };

  const openFullScreenEditor = () => {
    if (post.status === "PUBLISHED" || !hasPermission("post_design_write")) {
      return;
    }
    clearTimeout(miniEditorTimeout);
    setMiniByDefault(false);
    setShowEditor("fullScreen");
  };

  const useAnimation = useMemo(() => {
    if (
      !loadingPostAction ||
      (loadingPostAction === "update-review-status" && post.status === "LIKED")
    ) {
      return SLIDE_UP_ANIMATION;
    } else if (
      loadingPostAction === "delete" ||
      (post.status === "SCHEDULED" && loadingPostAction === "save")
    ) {
      return SLIDE_LEFT_ANIMATION;
    } else {
      return SLIDE_RIGHT_ANIMATION;
    }
  }, [post, loadingPostAction]);

  return (
    <Fragment key={post.id}>
      <AnimatePresence>
        {!hidePost && (
          <motion.div {...useAnimation} className="max-sm:w-full">
            {children({ openMiniEditor, openFullScreenEditor })}
          </motion.div>
        )}
      </AnimatePresence>

      <PostEditor
        {...{
          post,
          miniByDefault,
          showEditor,
          setShowEditor,
        }}
      />
    </Fragment>
  );
};

export default PostCardWrapper;
