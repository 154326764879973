import { Empty } from "antd";
import classNames from "classnames";
import { ReactNode } from "react";
import { Post } from "store/posts/postConstants";
import GenericPostCard from "./GenericPostCard";

const PostCardList = <T extends Post = Post>({
  postList,
  className,
  defaultSpacing,
  children,
}: {
  postList: T[];
  className?: string;
  defaultSpacing?: boolean;
  children?: (...args: Parameters<Parameters<Array<T>["map"]>[0]>) => ReactNode;
}) => {
  return postList.length ? (
    <div
      className={classNames("w-full", className, {
        "space-y-20": defaultSpacing,
      })}
    >
      {postList.map((post, ...args) =>
        children ? (
          children(post, ...args)
        ) : (
          <GenericPostCard key={post.id} post={post} />
        )
      )}
    </div>
  ) : (
    <Empty className="my-auto" description="Nothing to show" />
  );
};

export default PostCardList;
