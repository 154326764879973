import { Button, Input, message } from "antd";
import { updateOwner } from "api/businesses";
import { searchUserByEmail } from "api/user";
import ModalWithBorders from "components/ModalWithBorders";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getUserDetails } from "store/user/userActions";
import { currentBusinessGetter } from "store/user/userSlice";
import { isEmailValid } from "utils/generalUtils";

const ChangeOwnerModal = ({ close }: { close: () => void }) => {
  const dispatch = useAppDispatch();
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const isEmailInvalid = useMemo(() => {
    return Boolean(email) && !isEmailValid(email);
  }, [email]);

  const closeModal = () => {
    setEmail("");
    close();
  };

  const changeOwner = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await searchUserByEmail(email);
      await updateOwner({
        businessId: currentBusiness.id,
        ownerId: response.user.id,
      });
      await dispatch(getUserDetails());
      message.success("Business owner updated successfully.");
      closeModal();
    } catch (error) {
      error instanceof Error &&
        error.message.includes("not") &&
        message.error(error.message);
    }
    setIsLoading(false);
  }, [email, currentBusiness]);

  return (
    <ModalWithBorders
      title="Change Business Owner"
      onCancel={close}
      open
      footer={
        <>
          <Button onClick={closeModal} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!email || isEmailInvalid}
            onClick={changeOwner}
            loading={isLoading}
          >
            Change
          </Button>
        </>
      }
    >
      <div className="global-modal__input-block">
        <label htmlFor="">Email</label>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          disabled={isLoading}
          placeholder="Email"
          value={email}
        />
        {isEmailInvalid && (
          <span className="email-validation-warning">
            Please enter a valid email
          </span>
        )}
      </div>
    </ModalWithBorders>
  );
};

export default ChangeOwnerModal;
