import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import { isAxiosError } from "axios";
import cx from "classnames";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import addStaticCacheBusterParam from "utils/addNoCacheParam";
import {
  ACCEPTED_IMAGE_FORMATS_STR,
  validateUploadedFile,
} from "utils/validateFileUpload";

type ImageUploaderProps = {
  handleRemove?: () => void;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  handleLogoUpload?: (file: File) => Promise<void>;
  image: string | null;
  name: string;
  isLoading?: boolean;
  isCircle?: boolean;
};

const ImageUploader = ({
  handleRemove,
  handleChange,
  handleLogoUpload,
  isLoading,
  isCircle,
  image,
  name,
}: ImageUploaderProps) => {
  const [thumbnail, setThumbnail] = useState<string | null>(image);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && validateUploadedFile(file, "image")) {
      try {
        setThumbnail(URL.createObjectURL(file));
        handleChange && handleChange(e);
        handleLogoUpload && handleLogoUpload(file);
      } catch (error) {
        isAxiosError(error) && error.message && message.error(error.message);
      }
    }
  };

  useEffect(() => {
    setThumbnail(image);
  }, [image]);

  return (
    <div>
      {!image && !thumbnail && (
        <label
          htmlFor={name}
          className={cx("logo-upload", {
            "logo-upload--circle": isCircle,
          })}
        >
          <BiPlus />
          <input
            accept={ACCEPTED_IMAGE_FORMATS_STR}
            onChange={handleUpload}
            type="file"
            id={name}
          />
          Upload
        </label>
      )}

      {(image || thumbnail) && (
        <div
          className={cx("logo-container", {
            "logo-container--loading": isLoading,
            "logo-container--circle": isCircle,
          })}
        >
          <img
            src={
              image || thumbnail
                ? addStaticCacheBusterParam(image || thumbnail)
                : undefined
            }
            className="logo-image"
          />
          <Tooltip
            trigger="hover"
            title={isLoading ? "" : "Upload new"}
            placement="right"
          >
            <label htmlFor={`${name}-edit`} className="edit-icon">
              {isLoading ? (
                <LoadingOutlined className="loading-icon" />
              ) : (
                <FiEdit2 />
              )}
              <input
                accept={ACCEPTED_IMAGE_FORMATS_STR}
                onChange={handleUpload}
                type="file"
                id={`${name}-edit`}
                disabled={isLoading}
              />
            </label>
          </Tooltip>
          {handleRemove && (
            <Tooltip title="Remove" placement="right">
              <AiFillCloseCircle
                className="remove-icon"
                onClick={() => {
                  setThumbnail(null);
                  handleRemove();
                }}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
