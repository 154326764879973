import { Alert } from "antd";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";
import { integrationsGetter } from "store/user/userSlice";
import { POST_CALENDAR_ROUTE } from "../utils/postCalendarFixtures";

const PostAlerts = () => {
  const { pathname } = useLocation();
  const { scheduled } = useAppSelector(selectPostStore);
  const connectedSocials = useAppSelector(integrationsGetter);

  const isCalendarPage = pathname === POST_CALENDAR_ROUTE;

  return (
    <div className="sticky top-0 z-[100]">
      {!connectedSocials.length && scheduled.count > 0 && (
        <Alert
          message={
            <>
              {isCalendarPage
                ? "Publish dates may be inaccurate because no social accounts are currently connected."
                : "You have scheduled posts but no socials connected."}{" "}
              <Link to={INTEGRATIONS_ROUTE} className="underline">
                Connect a social
              </Link>{" "}
              to start publishing your posts.
            </>
          }
          className="w-full flex justify-center [&_.ant-alert-content]:max-w-fit"
          type={isCalendarPage ? "info" : "warning"}
          banner
        />
      )}
      {"brave" in navigator && (
        <Alert
          type="error"
          className="w-full text-center"
          message={
            <>
              <span className="font-medium">
                Editing designs on the Brave browser is not officially
                supported.
              </span>
              <br />
              We recommend using a different browser such as Safari or Chrome.
            </>
          }
        />
      )}
    </div>
  );
};

export default PostAlerts;
