const PrivacyPolicy = () => (
  <div className="px-20 py-40 md:px-8 md:py-12">
    <h2 className="mb-6 text-2xl md:text-xl">Privacy Policy</h2>
    <p className="mb-4 text-base md:text-sm">
      This Privacy Policy ("Policy") describes how Marky ("we," "us," or the
      "Company") collects, uses, and discloses personal information when you use
      our application ("App"). By using the App, you consent to the practices
      described in this Policy.
    </p>
    <ul className="space-y-6">
      <li>
        <h3 className="text-lg mb-3">1. Information We Collect</h3>
        <ul className="pl-4 space-y-2">
          <li>
            1.1 Personal Information: When you create an account or use the App,
            we may collect personal information such as your name, email
            address, social media account credentials, and other information you
            provide to us.
          </li>
          <li>
            1.2 Social Media Information: The App may require access to your
            social media accounts in order to generate content and post on your
            behalf. We may collect information from your connected social media
            accounts, such as profile information, posts, and engagement data.
          </li>
          <li>
            1.3 App Usage Information: We may collect information about your use
            of the App, including log data, device information, and usage
            patterns. This may include IP addresses, browser type, operating
            system, and other technical information.
          </li>
        </ul>
      </li>

      <li>
        <h3 className="text-lg mb-3">2. Use of Information</h3>
        <ul className="pl-4 space-y-2">
          <li>
            2.1 Provision of Services: We use the information we collect to
            provide, personalize, and improve the App's functionality, features,
            and services. This includes generating AI-generated content,
            facilitating content approval and posting, and managing your
            account.
          </li>
          <li>
            2.2 Communication: We may use your email address or other contact
            information to communicate with you about the App, updates, and
            important notices.
          </li>
          <li>
            2.3 Aggregated Data: We may aggregate and anonymize data collected
            from the App to generate statistical and analytical insights for
            improving the App and for other business purposes.
          </li>
          <li>
            2.4 Legal Compliance: We may use and disclose your information as
            necessary to comply with applicable laws, regulations, legal
            processes, or enforceable governmental requests.
          </li>
        </ul>
      </li>

      <li>
        <h3 className="text-lg mb-3">3. Information Sharing</h3>
        <ul className="pl-4 space-y-2">
          <li>
            3.1 Service Providers: We may engage third-party service providers
            to perform services on our behalf, such as hosting, data analysis,
            customer support, and marketing. These service providers may have
            access to your personal information but are only authorized to use
            it as necessary to provide the services to us.
          </li>
          <li>
            3.2 Consent and Authorization: With your consent and authorization,
            we may share your approved content on your connected social media
            accounts.
          </li>
          <li>
            3.3 Business Transfers: In the event of a merger, acquisition, or
            sale of all or a portion of our assets, your personal information
            may be transferred to the acquiring entity or third party as part of
            the transaction.
          </li>
          <li>
            3.4 Legal Requirements: We may disclose your personal information if
            required to do so by law or in the good-faith belief that such
            action is necessary to comply with applicable laws, regulations,
            legal processes, or enforceable governmental requests.
          </li>
        </ul>
      </li>

      <li>
        <h3 className="text-lg mb-3">4. Data Security</h3>
        <p className="text-base">
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. However, please note that no method of transmission over
          the Internet or electronic storage is 100% secure, and we cannot
          guarantee the absolute security of your information.
        </p>
      </li>

      <li>
        <h3 className="text-lg mb-3">5. Your Choices and Rights</h3>
        <ul className="pl-4 space-y-2">
          <li>
            5.1 Account Information: You may review, update, or delete your
            account information by accessing your account settings within the
            App.
          </li>
          <li>
            5.2 Marketing Communications: You can opt-out of receiving
            promotional communications from us by following the instructions
            provided in such communications or by contacting us directly.
          </li>
        </ul>
      </li>

      <li>
        <h3 className="text-lg mb-3">6. Children's Privacy</h3>
        <p className="text-base">
          The App is not intended for use by individuals under the age of 13. We
          do not knowingly collect personal information from children under 13.
          If we become aware that we have collected personal information from a
          child under 13 without parental consent, we will take steps to delete
          such information.
        </p>
      </li>

      <li>
        <h3 className="text-lg mb-3">7. Changes to the Privacy Policy</h3>
        <p className="text-base">
          We reserve the right to update or modify this Privacy Policy from time
          to time. We will notify you of any material changes by posting the
          revised Policy within the App or by other means. Your continued use of
          the App after the effective date of the revised Policy constitutes
          your acceptance of the changes.
        </p>
      </li>

      <li>
        <h3 className="text-lg mb-3">8. Contact Us</h3>
        <p className="text-base">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at{" "}
          <a href="mailto:josiah@coad.net">josiah@coad.net</a>.
        </p>
      </li>
    </ul>

    <span className="block mt-10">Last Updated: Dec 10, 2023</span>
  </div>
);

export default PrivacyPolicy;
