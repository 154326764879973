import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, CheckboxOptionType } from "antd";
import { ReviewStatus } from "api/config/chalice-api";
import CheckboxDropdown from "components/Common/CheckboxDropdown";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { useEffect, useMemo, useState } from "react";
import { Post } from "store/posts/postConstants";

type ReviewStatusFilterType = ReviewStatus | "none";

type FilterOption = CheckboxOptionType & {
  value: ReviewStatusFilterType;
  needsPermission?: PermissionString;
};

const FILTER_OPTIONS: FilterOption[] = [
  {
    value: "none" as const,
    label: "No Status",
    needsPermission: "post_approval_status_ready_for_review_write",
  },
  { value: "READY_FOR_REVIEW" as const, label: "Ready for Review" },
  { value: "CHANGES_REQUESTED" as const, label: "Changes Requested" },
  { value: "APPROVED" as const, label: "Approved" },
];

const ReviewStatusFilter = ({
  allPosts,
  enabled,
  setFilteredPosts,
}: {
  allPosts: Post[];
  enabled?: boolean;
  setFilteredPosts: (posts: Post[]) => void;
}) => {
  const { hasPermission } = useUserPermissions();

  const [selectedFilters, setSelectedFilters] = useState(
    [] as ReviewStatusFilterType[]
  );

  useEffect(() => {
    enabled &&
      setFilteredPosts(
        selectedFilters.length
          ? allPosts.filter((post) =>
              post.review_status
                ? selectedFilters.includes(post.review_status)
                : selectedFilters.includes("none" as const)
            )
          : allPosts
      );
  }, [enabled, allPosts, selectedFilters]);

  const reviewStatusCounts = useMemo(
    () =>
      allPosts.reduce(
        (acc, post) => {
          const status = post.review_status || "none";
          acc[status] = (acc[status] ?? 0) + 1;
          return acc;
        },
        {} as Record<ReviewStatusFilterType, number>
      ),
    [allPosts]
  );

  const filterOptions = useMemo(
    () =>
      FILTER_OPTIONS.reduce<CheckboxOptionType[]>(
        (prev, { needsPermission, ...option }) => {
          if (needsPermission && !hasPermission(needsPermission)) {
            return prev;
          }
          const postsCount =
            reviewStatusCounts[option.value as ReviewStatusFilterType] ?? 0;
          prev.push({
            ...option,
            label: `${option.label} (${postsCount})`,
          });
          return prev;
        },
        []
      ),
    [reviewStatusCounts]
  );

  return enabled ? (
    <CheckboxDropdown
      checkboxGroupProps={{
        value: selectedFilters,
        options: filterOptions,
        onChange: setSelectedFilters,
      }}
    >
      <Button size="small">
        {selectedFilters.length ? (
          <FilterFilled className="text-antd-colorInfo" />
        ) : (
          <FilterOutlined />
        )}
        Filter
      </Button>
    </CheckboxDropdown>
  ) : null;
};

export default ReviewStatusFilter;
