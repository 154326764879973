export const WEEK_DAYS = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
] as const;

export type DayOfWeek = (typeof WEEK_DAYS)[number];

export const JITTER_OPTIONS = [
  { value: 0, label: "0 min (disabled)" },
  { value: 5, label: "5 min" },
  { value: 10, label: "10 min" },
  { value: 30, label: "30 min" },
  { value: 60, label: "1 hour" },
];

export const DAYS_OPTION: { label: string; value: DayOfWeek }[] = [
  { label: "Mon", value: "MON" },
  { label: "Tue", value: "TUE" },
  { label: "Wed", value: "WED" },
  { label: "Thu", value: "THU" },
  { label: "Fri", value: "FRI" },
  { label: "Sat", value: "SAT" },
  { label: "Sun", value: "SUN" },
];

export const JITTER_TOOLTIP =
  "This will add/subtract time to create some randomness in when you post. Set to 0 min to disable. (If you're scheduled to post at 5:00 PM, and you randomize by 10 minutes, the post will be published sometime between 4:50 PM - 5:10 PM)";
