import useAppContext from "config/AppContext/useAppContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidGlobalModalData } from "./globalModalConfig";
import useGlobalModal from "./useGlobalModal";

const OpenGlobalModal = ({
  markyOnly,
  modalName,
  modalData,
}: ValidGlobalModalData) => {
  const {
    realm: { isMarky },
  } = useAppContext();
  const navigate = useNavigate();
  const { openGlobalModal } = useGlobalModal();

  useEffect(() => {
    if (isMarky || !markyOnly) {
      openGlobalModal({ modalName, modalData } as ValidGlobalModalData);
    } else {
      navigate("/login");
    }
  }, [isMarky]);

  return null;
};

export default OpenGlobalModal;
