import { Select } from "antd";
import PaletteColorBlocks from "components/PaletteColorBlocks";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { currentBusinessGetter } from "store/user/userSlice";
import { PolotnoPage } from "../../polotnoTypes";
import { updateColors } from "./colorsUtils";
import PRESET_PALETTES from "./PRESET_PALETTES";

const ColorPaletteSelect: ObserverComponent = ({ store }) => {
  const { brand } = useSelector(currentBusinessGetter);

  const [selectedPaletteIndex, setSelectedPaletteIndex] = useState<
    number | null
  >(null);

  const { allPalettes, paletteOptions } = useMemo(() => {
    const allPalettes = brand.palettes
      ? [...brand.palettes, ...PRESET_PALETTES]
      : PRESET_PALETTES;

    return {
      allPalettes,
      paletteOptions: allPalettes.map((palette, index) => ({
        label: (
          <PaletteColorBlocks
            onMouseEnter={() => setSelectedPaletteIndex(index)}
            palette={palette}
          />
        ),
        value: index,
      })),
    };
  }, [brand.palettes]);

  useEffect(() => {
    if (selectedPaletteIndex !== null && allPalettes[selectedPaletteIndex]) {
      store.pages.forEach((page) =>
        updateColors(page as PolotnoPage, allPalettes[selectedPaletteIndex])
      );
    }
  }, [selectedPaletteIndex]);

  return (
    <Select
      getPopupContainer={() =>
        document.querySelector(".go3456988929") as HTMLElement
      }
      onChange={(value) => setSelectedPaletteIndex(value)}
      className="!w-[108px] !h-8 color-palette-select"
      value={selectedPaletteIndex}
      placeholder="Palette"
      options={paletteOptions}
    />
  );
};

export default ColorPaletteSelect;
