import { message, Select, Tooltip } from "antd";
import Cookies from "js-cookie";
import { REFERRAL_PARAM } from "pages/ReferralReroute";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSearchParam } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { Business } from "store/business/businessConstants";
import { trackEvent } from "utils/eventTracking/trackEvents";
import ChangeBusinessTierModal, {
  UPGRADE_BUSINESS_PARAM,
} from "./ChangeBusinessTierModal";

const BusinessTierDropdown = ({
  title: businessName,
  whitelabel_tier: currentTier,
  id: businessId,
  disabled,
}: Business & { disabled?: boolean }) => {
  const businessToBeUpgraded = useSearchParam(UPGRADE_BUSINESS_PARAM);

  const dispatch = useAppDispatch();
  const businesses = useAppSelector((state) => state.user.businesses);

  const [showModal, setShowModal] = useState<"upgrade" | "downgrade" | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const upgradeBusinessTier = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          updateBusinessDetails({
            id: businessId,
            whitelabel_tier: "ADVANCED",
          })
        );
        trackEvent("upgraded_wl_tier");

        const businessName = businesses.find(
          ({ id }) => id === businessId
        )?.title;

        Cookies.remove(REFERRAL_PARAM);

        message.success(
          `${businessName} business upgraded successfully to Advanced tier.`
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (businessToBeUpgraded === businessId) {
      upgradeBusinessTier();
    }
  }, [businessToBeUpgraded]);

  return (
    <>
      {isLoading ? (
        <Skeleton height={30} width={100} />
      ) : (
        <Tooltip
          title={
            disabled
              ? "Only business owners can change the White Labeling tier."
              : ""
          }
        >
          <Select
            disabled={disabled}
            options={[
              { label: "Standard", value: "STANDARD" },
              { label: "Advanced", value: "ADVANCED" },
            ]}
            value={currentTier}
            onChange={(value) =>
              setShowModal(value === "ADVANCED" ? "upgrade" : "downgrade")
            }
            variant="borderless"
            className="!w-fit"
          />
        </Tooltip>
      )}
      <ChangeBusinessTierModal
        close={() => setShowModal(null)}
        businessName={businessName}
        businessId={businessId}
        modalType={showModal}
      />
    </>
  );
};

export default BusinessTierDropdown;
