import { message } from "antd";
import { UploadFile } from "antd/lib";
import { getPresignedUrl } from "api/postsApi";
import axios from "axios";
import { RealmDetails } from "config/AppContext/appContextUtil";
import { startCase } from "lodash";

// TODO: Merge this with constants/INTEGRATION_ITEMS_DICT
export const formatSocialName = (name: string) => {
  if (name === "linkedInProfile") {
    return "LinkedIn Profile";
  } else if (name === "googleBusiness") {
    return "Google Business";
  } else if (name === "instagramStory") {
    return "Instagram Story";
  }

  return startCase(name).replace(/ /g, "");
};

export const invalidClientAccountMessage = (realm: RealmDetails) =>
  message.error({
    key: "invalid-client-account",
    content: `This account does not have access to any businesses in ${realm.name ?? "this domain"}. Please reach out to ${realm.name ?? "the domain owners"} for support.`,
  });

export const isEmailValid = (email?: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !!email && emailRegex.test(email);
};

export const uploadToS3 = async (
  file: UploadFile | File,
  fileName?: string
) => {
  const { url } = await getPresignedUrl(file, file.type, fileName);
  await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
  return url.split("?")[0];
};
