import dayjs, { Dayjs } from "dayjs";
import { uniq, uniqBy } from "lodash";
import {
  DayOfWeek,
  WEEK_DAYS,
} from "pages/Integrations/components/PostScheduleManager/scheduleConstants";

export const extractDaysAndTimes = (timeSlots: string[]) => {
  return timeSlots.reduce<{
    days: DayOfWeek[];
    times: dayjs.Dayjs[];
  }>(
    (acc, timeSlot) => {
      const [day, time] = timeSlot.split(" ");
      const dayIndex = WEEK_DAYS.indexOf(day as DayOfWeek);
      const timeWithDay = dayjs(time, "HH:mm").day(dayIndex).utc(true).local();

      const formattedDay = timeWithDay.format("ddd").toUpperCase();

      acc.days = uniq(acc.days.concat(formattedDay as DayOfWeek));
      acc.times = uniqBy(acc.times.concat(timeWithDay), (t) =>
        t.format("HH:mm")
      );

      return acc;
    },
    { days: [], times: [] }
  );
};

export const generateTimeSlotStrings = (
  times: Dayjs[],
  days: DayOfWeek[]
): string[] => {
  return times.flatMap((time) =>
    days.map((day) =>
      dayjs(time)
        .day(WEEK_DAYS.indexOf(day))
        .utc()
        .format("ddd HH:mm")
        .toUpperCase()
    )
  );
};

export const isWithinOneHour = (time: Dayjs, times: Dayjs[]): boolean => {
  const timeMinutes = time.hour() * 60 + time.minute();

  return times.some((existingTime) => {
    const existingTimeMinutes =
      existingTime.hour() * 60 + existingTime.minute();
    const diffInMinutes = Math.abs(existingTimeMinutes - timeMinutes);
    const wrapAroundDiff = 1440 - diffInMinutes; // 1440 minutes in a day

    return diffInMinutes < 60 || wrapAroundDiff < 60;
  });
};

export const findOverlappingTimeIndices = (times: Dayjs[]): number[] => {
  const indices: Set<number> = new Set();

  for (let i = 0; i < times.length; i++) {
    for (let j = i + 1; j < times.length; j++) {
      if (isWithinOneHour(times[i], [times[j]])) {
        indices.add(i);
        indices.add(j);
      }
    }
  }

  return Array.from(indices);
};
