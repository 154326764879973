// https://medium.com/@for.mvpetrov/using-css-variables-with-antd-design-system-3d0cd43c438b

import { includes, isNumber, isString } from "lodash";
import { getAntdVarName } from "../../../tailwind.config";

export const saveToken = (value: unknown, tokenName: string) => {
  if (tokenName.startsWith("_")) {
    return;
  }

  const variableName = getAntdVarName(tokenName);

  if (isString(value)) {
    document.documentElement.style.setProperty(variableName, value);
  } else if (isNumber(value)) {
    const propertyValue = isPureNumberProperty(tokenName)
      ? String(value)
      : `${value}px`;

    document.documentElement.style.setProperty(variableName, propertyValue);
  }
};

const isPureNumberProperty = (tokenName: string) =>
  includes(tokenName, "zIndex") ||
  includes(tokenName, "Weight") ||
  includes(tokenName, "motion") ||
  includes(tokenName, "opacity") ||
  includes(tokenName, "lineHeight");
