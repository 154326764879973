import ColorPickerWithPresets from "components/Common/ColorPickerWithPresets";
import { observer } from "mobx-react-lite";
import { FigureElementType } from "polotno/model/figure-model";

const CustomColorPicker: ObserverComponent<{
  element: FigureElementType;
}> = observer(({ element }) => (
  <ColorPickerWithPresets
    value={element?.fill}
    onChange={(value: { toHexString: () => string }) => {
      element?.set({
        fill: value.toHexString(),
      });
    }}
  />
));

export default CustomColorPicker;
