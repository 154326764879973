import { Button, ButtonProps, Tooltip } from "antd";
import { ReactNode } from "react";

export type ControlButtonProps = {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  onClick: () => void;
} & ButtonProps;

const ControlButton = ({ title, ...buttonProps }: ControlButtonProps) => (
  <Tooltip title={title}>
    <Button {...buttonProps} />
  </Tooltip>
);

export default ControlButton;
