import { InputNumber } from "antd";
import { observer } from "mobx-react-lite";
import { TextElementType } from "polotno/model/text-model";

const TextSizeInput: ObserverComponent<{
  element: TextElementType;
}> = observer(({ element }) => (
  <InputNumber
    onChange={(value) => element.set({ fontSize: value })}
    value={element.fontSize}
    className="text-size-input"
    key={element.id}
    max={200}
    step={5}
    min={1}
  />
));

export default TextSizeInput;
