import { Button } from "antd";
import { ReactComponent as SuccessImage } from "assets/icons/client-empty-state.svg";
import dayjs from "dayjs";
import useAddNewBusiness from "hooks/useAddNewBusiness";
import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";

const EmptyState = () => {
  const { handleNewBusinessCreation } = useAddNewBusiness();

  const user = useAppSelector(selectUserInfo);

  const { title, subtitle } = useMemo(() => {
    const now = dayjs();
    const createdAt = dayjs(user.created_at);
    const isOldUser = now.diff(createdAt, "days") >= 1;

    return isOldUser
      ? {
          title: "No active client accounts",
          subtitle:
            "You currently have no active client accounts. To get started, create a client account.",
        }
      : {
          title: "This is your client dashboard",
          subtitle:
            "From here you'll be able to view and manage all your client accounts. To get started, create your first client account.",
        };
  }, [user.created_at]);

  return (
    <div className="client-accounts__empty-state-container">
      <div className="client-accounts__empty-state">
        <SuccessImage />
        <div className="client-accounts__empty-state__text">
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
        <Button type="primary" onClick={() => handleNewBusinessCreation()}>
          Add new Business
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
