import { InboxOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Radio } from "antd";
import { Rule } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { FormItemProps } from "antd/lib";
import { TypeEnum } from "api/config/django-api";
import InfoTooltip from "components/Common/InfoTooltip";

export const MEMORY_STATUS_MESSAGES = {
  SCRAPING: "Failed to scrape.",
  EXTRACTING: "Unable to extract the data.",
  INVALID_FILE_TYPE: "Invalid File Type.",
  UNKNOWN: "",
};

export type TaskInputForm = Partial<{
  file: File;
  url: string;
  description: string;
}>;

// Without picking and limiting certain properties, antd erroneously does not allow an object of this
// type to be sent as props for Form.Item
export type TaskFormItemProps<T = TaskInputForm> = Pick<
  FormItemProps<T>,
  "label" | "name"
> & {
  children: React.ReactNode;
};

export type MemoryType = "text" | "pdf" | "url";

export const addToMemoryCheckbox = (type: "pdf" | "url") => (
  <Form.Item name="addToMemory">
    <Checkbox defaultChecked>
      Add {type.toUpperCase()} to memory{" "}
      <InfoTooltip title="Add information to memory so that it can be included in future posts. The more you add, the better we can tailor content to your business." />
    </Checkbox>
  </Form.Item>
);

export const getPdfInputFieldConfig = ({
  label,
  showAddToMemoryCheckbox,
}: {
  label: string;
  showAddToMemoryCheckbox: boolean;
}): TaskFormItemProps => ({
  label,
  name: "file",
  children: (
    <>
      <Form.Item
        name="file"
        className="!m-0"
        getValueFromEvent={(e) => e?.fileList?.[0]?.originFileObj || null}
        valuePropName="file"
      >
        <Dragger beforeUpload={() => false} accept=".pdf" maxCount={1}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag a file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Supports a single PDF document under 20 MB.
          </p>
        </Dragger>
      </Form.Item>
      {showAddToMemoryCheckbox && addToMemoryCheckbox("pdf")}
    </>
  ),
});

export const getInputFieldConfig = (
  type: TypeEnum,
  isGeneratorPanel?: boolean
): TaskFormItemProps => {
  switch (type) {
    case "URL":
      return {
        label: "URL",
        name: "url",
        children: (
          <>
            <Form.Item name="url" className="!m-0">
              <Input placeholder="Drop a link to your website, blog post, news article, etc." />
            </Form.Item>
            {!isGeneratorPanel && (
              <Form.Item name="crawl" className="mb-0">
                <Radio.Group id="crawl">
                  <Radio value={true}>Scan entire site</Radio>
                  <Radio value={false}>Scan single page</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {isGeneratorPanel && addToMemoryCheckbox("url")}
          </>
        ),
      };
    case "FILE":
      return getPdfInputFieldConfig({
        label: "Upload",
        showAddToMemoryCheckbox: false,
      });
    default:
      return {
        label: "Description",
        name: "description",
        children: (
          <TextArea placeholder="What do you want us to remember?" rows={5} />
        ),
      };
  }
};

export const validateValueLength = (
  _rule: Rule,
  value: string | undefined,
  fieldName: string
) =>
  value?.trim().length
    ? Promise.resolve()
    : Promise.reject(`Please add a ${fieldName}`);
