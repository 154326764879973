import { LineChart } from "@tremor/react";
import { Empty } from "antd";
import { SocialMetricTimeseries } from "api/config/chalice-api";
import InfoTooltip from "components/Common/InfoTooltip";
import dayjs from "dayjs";
import { isNaN } from "lodash";
import { useMemo } from "react";
import usePostAnalyticsContext from "../usePostAnalytics";
import PercentChangeIndicator from "./PercentChangeIndicator";
import PreviousPeriodChartTooltip from "./PreviousPeriodChartTooltip";

type ChartDataItemType = {
  formattedDate: string;
  date: string;
  currentValue: number;
  previousPeriodValue?: number;
  previousPeriodDate?: string;
};

const ABBREVIATION_SUFFIXES = ["", "K", "M", "B", "T"];

const abbreviateNumber = (value: number): string => {
  if (value < 10000) return value.toLocaleString();

  const tier = Math.floor(Math.log10(Math.abs(value)) / 3);
  const suffix = ABBREVIATION_SUFFIXES[tier] || "";

  const scaleFactor = Math.pow(10, tier * 3);
  const scaledValue = value / scaleFactor;

  const roundedValue = Number(scaledValue.toPrecision(3));

  return `${roundedValue}${suffix}`;
};

const IntegrationStatCard = ({
  title,
  data,
  description,
  name,
}: SocialMetricTimeseries) => {
  const {
    periodState: [period],
    dateRangeState: [dateRange],
  } = usePostAnalyticsContext();

  // TODO: Remove this when the BE adds a property to indicate which metrics are summable https://mymarky.atlassian.net/browse/SWE-1964
  const isFollowersMetric = name === "page_follows";

  const dataLength = Object.keys(data).length;

  const computedValue = Object.values(data)[0]?.total;

  const { chartData, currentPeriodSum, previousPeriodSum } = useMemo(() => {
    const result = Object.entries(data).reduce<{
      chartData: ChartDataItemType[];
      currentPeriodSum: number;
      previousPeriodSum: number;
    }>(
      (acc, [key, value]) => {
        const shouldInclude =
          period.value === "all-time" ||
          (dayjs(key).isAfter(dateRange.current?.start?.subtract(1, "day")) &&
            dayjs(key).isBefore(dateRange.current?.end, "day"));

        if (!shouldInclude) {
          return acc;
        }

        const initialData: ChartDataItemType = {
          formattedDate: dayjs(key).format("MMM D"),
          currentValue: value.total,
          date: key,
        };

        if (isFollowersMetric) {
          acc.currentPeriodSum = value.total;
        } else {
          acc.currentPeriodSum += value.total;
        }

        if (dateRange.current?.start) {
          const subtractAmount = Math.round(
            dateRange.current?.start.diff(
              dateRange.previous?.start,
              "days",
              true
            )
          );

          initialData.previousPeriodValue =
            data[
              dayjs(key).subtract(subtractAmount, "days").format("YYYY-MM-DD")
            ]?.total;
          initialData.previousPeriodDate = dayjs(key)
            .subtract(subtractAmount, "days")
            .format("MMM D, YYYY");

          if (isFollowersMetric) {
            acc.previousPeriodSum =
              initialData.previousPeriodValue || acc.previousPeriodSum;
          } else {
            acc.previousPeriodSum += initialData.previousPeriodValue;
          }
        }

        acc.chartData.push(initialData);

        return acc;
      },
      {
        chartData: [],
        currentPeriodSum: 0,
        previousPeriodSum: 0,
      }
    );

    return {
      ...result,
      chartData: result.chartData.sort((a, b) =>
        dayjs(a.date).diff(dayjs(b.date))
      ),
    };
  }, [data, period, dateRange]);

  const shouldShowSumValue = dataLength > 1;

  return (
    <div
      className="min-h-72 p-4 flex flex-col rounded-lg dark:border border-antd-colorBorder text-sm space-y-2 bg-antd-colorBgContainer shadow-sm"
      key={title}
    >
      <div className="flex justify-between items-center">
        <span className="font-semibold flex items-center gap-2">
          {title}
          {shouldShowSumValue && (
            <PercentChangeIndicator
              previousValue={previousPeriodSum}
              currentValue={currentPeriodSum}
            />
          )}
        </span>
        <InfoTooltip title={description} />
      </div>

      {shouldShowSumValue && (
        <div className="flex justify-between items-end">
          <span className="text-xl">{abbreviateNumber(currentPeriodSum)}</span>
          {!isNaN(previousPeriodSum) && (
            <span className="text-xs text-antd-colorTextSecondary">
              from {abbreviateNumber(previousPeriodSum)}
            </span>
          )}
        </div>
      )}

      {dataLength === 0 ? (
        <Empty
          description="Unable to retrieve data"
          className="flex flex-col justify-center items-center flex-1"
        />
      ) : dataLength === 1 ? (
        <div className="flex flex-col justify-center items-center flex-1">
          <span className="text-4xl font-semibold">
            {abbreviateNumber(computedValue)}
          </span>
          <div className="text-antd-colorTextSecondary text-center">
            {title}
            <br />
            {dayjs(Object.keys(data)[0]).format("MMM D")}
          </div>
        </div>
      ) : (
        <LineChart
          customTooltip={({ payload }) => (
            <PreviousPeriodChartTooltip payload={payload} chartTitle={title} />
          )}
          categories={["previousPeriodValue", "currentValue"]}
          className="!h-52"
          intervalType="preserveStartEnd"
          colors={["gray-300", "sky"]}
          curveType="monotone"
          data={chartData}
          index="formattedDate"
          connectNulls
          startEndOnly
          showLegend={false}
          showYAxis={false}
        />
      )}
    </div>
  );
};

export default IntegrationStatCard;
