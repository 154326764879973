import { Button, Popconfirm } from "antd";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { MdOutlineVideoSettings } from "react-icons/md";

const AutoAnimateButton: ObserverComponent<{
  autoAnimate: () => void;
  children?: ReactNode;
  isLoading: boolean;
}> = observer(({ autoAnimate, isLoading, children, store }) => {
  const areAnimationsAlreadyApplied = store.pages.some((page) =>
    page.children.some((el) => el.animations?.length > 0)
  );

  return (
    <Popconfirm
      title={`This will remove all current animations, are you sure you want to proceed?`}
      overlayClassName="delete-business-confirmation"
      disabled={!areAnimationsAlreadyApplied}
      onConfirm={autoAnimate}
      cancelText="No"
      okText="Yes"
    >
      {children ? (
        <div onClick={() => !areAnimationsAlreadyApplied && autoAnimate()}>
          {children}
        </div>
      ) : (
        <Button
          onClick={() => !areAnimationsAlreadyApplied && autoAnimate()}
          className="[&_.ant-btn-icon]:flex"
          icon={<MdOutlineVideoSettings />}
          loading={isLoading}
          size="small"
        >
          Auto-animate
        </Button>
      )}
    </Popconfirm>
  );
});

export default AutoAnimateButton;
