import { Permissions } from "api/config/chalice-api";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";

type MemberPermissionConfig = {
  label: string;
  supersetPermission?: PermissionString;

  permissions: {
    label: string;
    keys: PermissionString[];
    excludeFromSuperset?: boolean;
  }[];
};

export const PERMISSIONS_CONFIG: MemberPermissionConfig[] = [
  {
    label: "Review Process",
    supersetPermission: "post_status_draft_read",
    permissions: [
      {
        label: "Review posts",
        keys: [
          "post_approval_status_approve_write",
          "post_approval_status_reject_write",
        ],
      },
      {
        label: "Comment on posts",
        keys: ["comment_read", "comment_write"],
      },
      {
        label: "Mark posts as ready for review",
        keys: ["post_approval_status_ready_for_review_write"],
      },
    ],
  },
  {
    label: "Business",
    permissions: [
      {
        label: "Connect Social Accounts",
        keys: ["socials_write"],
      },
      { label: "View Team tab", keys: ["business_team_members_read"] },
      { label: "View Clients tab", keys: ["business_clients_read"] },
      {
        label: "View Library and Business tabs",
        keys: ["business_details_read"],
      },
      {
        label: "Edit Library and Business tabs",
        keys: ["business_details_write"],
      },
    ],
  },
  {
    label: "Posts",
    supersetPermission: "post_status_draft_read",
    permissions: [
      {
        label: "View drafts",
        keys: ["post_status_draft_read"],
      },
      {
        label: "Save to drafts",
        keys: ["post_status_draft_write"],
      },
      {
        label: "Edit captions",
        keys: ["caption_write"],
      },
      {
        label: "Edit designs",
        keys: ["post_design_write"],
      },
      {
        label: "Trash posts",
        keys: ["post_status_trash_write", "post_status_trash_read"],
      },
      {
        label: "Upload posts",
        keys: ["upload_custom_post_write"],
        excludeFromSuperset: true,
      },
      {
        label: "Generate posts",
        keys: ["post_status_generate_read", "post_status_generate_write"],
        excludeFromSuperset: true,
      },
    ],
  },
  {
    label: "Publishing",
    permissions: [
      {
        label: "Manage posting schedule",
        keys: ["posting_schedule_read", "posting_schedule_write"],
      },
      {
        label: "Schedule and publish posts",
        keys: ["post_status_schedule_write", "post_status_publish_write"],
      },
    ],
  },
];

const REVIEWER_PERMISSIONS: Required<Permissions> = {
  business_clients_read: true,
  billing_details_write: false,
  business_details_read: false,
  business_details_write: false,
  business_team_members_read: true,
  caption_write: false,
  comment_read: true,
  comment_write: true,
  client_permissions_write: false,
  members_write: false,
  post_approval_status_approve_write: true,
  post_approval_status_ready_for_review_write: false,
  post_approval_status_reject_write: true,
  post_design_write: false,
  post_status_draft_read: true,
  post_status_draft_write: false,
  post_status_generate_read: false,
  post_status_generate_write: false,
  post_status_publish_read: true,
  post_status_publish_write: false,
  post_status_schedule_read: true,
  post_status_schedule_write: false,
  post_status_trash_read: false,
  post_status_trash_write: false,
  posting_schedule_read: false,
  posting_schedule_write: false,
  review_tab_read: true,
  socials_write: true,
  upload_custom_post_write: false,
};

const CONTRIBUTOR_PERMISSIONS: Required<Permissions> = {
  business_clients_read: true,
  billing_details_write: true,
  business_details_read: true,
  business_details_write: true,
  business_team_members_read: true,
  caption_write: true,
  comment_read: true,
  comment_write: true,
  client_permissions_write: false,
  members_write: false,
  post_approval_status_approve_write: false,
  post_approval_status_ready_for_review_write: false,
  post_approval_status_reject_write: false,
  post_design_write: true,
  post_status_draft_read: true,
  post_status_draft_write: true,
  post_status_generate_read: true,
  post_status_generate_write: true,
  post_status_publish_read: true,
  post_status_publish_write: true,
  post_status_schedule_read: true,
  post_status_schedule_write: true,
  post_status_trash_read: true,
  post_status_trash_write: true,
  posting_schedule_read: true,
  posting_schedule_write: true,
  review_tab_read: false,
  socials_write: true,
  upload_custom_post_write: true,
};

export const PERMISSIONS_PRESET: Record<
  PermissionPreset,
  { label: string; permissions: Required<Permissions> }
> = {
  reviewer: { label: "Reviewer", permissions: REVIEWER_PERMISSIONS },
  contributor: { label: "Contributor", permissions: CONTRIBUTOR_PERMISSIONS },
};

export type PermissionPreset = "reviewer" | "contributor";
