import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import BusinessSelector from "components/BusinessSelector";
import SidebarBottomMenu from "components/SidebarNav/SidebarBottomMenu";
import SidebarNavItems from "components/SidebarNav/SidebarNavItems";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import LogoLink from "config/theme/LogoLink";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { MOBILE_SIDEBAR_BREAKPOINT } from "fixtures/globalConstants";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { currentBusinessGetter } from "store/user/userSlice";
import CreatePostDropdown from "./CreatePostDropdown";

const MobileHeader = () => {
  const postCalendar = useTypedFeatureIsOn("post-calendar");

  const {
    headerChildrenState: [headerChildren],
    sidebarCollapsedState: [sidebarCollapsed, setSidebarCollapsed],
  } = useAppContext();

  const { hasPermission } = useUserPermissions();
  const { pathname } = useLocation();

  const currentBusiness = useSelector(currentBusinessGetter);

  useEffect(() => {
    window.innerWidth < MOBILE_SIDEBAR_BREAKPOINT && setSidebarCollapsed(true);
  }, [pathname, currentBusiness.id]);

  return (
    <div className="mobile-header w-full lg:!hidden">
      <div className="mobile-header__top py-3 px-6">
        <div className="flex gap-3 items-center">
          <LogoLink className="!px-0" />
          {!postCalendar && pathname === POSTS_PAGE_ROUTE
            ? hasPermission("upload_custom_post_write") && (
                <CreatePostDropdown />
              )
            : pathname === "/dashboard" && headerChildren}
        </div>

        <div
          onClick={() => setSidebarCollapsed(false)}
          className="menu-icon-container cursor-pointer"
        >
          <MenuOutlined className="menu-icon cursor-pointer" />
        </div>
      </div>
      <div className="flex gap-4 justify-between bg-antd-colorFillTertiary overflow-auto">
        {pathname !== "/dashboard" && headerChildren}
      </div>

      <Drawer
        open={!sidebarCollapsed}
        rootClassName="lg:hidden"
        onClose={() => setSidebarCollapsed(true)}
        closeIcon={null}
        title={
          <div className="mobile-sidebar-menu__header">
            <LogoLink className="!px-0" />
            <MdClose
              onClick={() => setSidebarCollapsed(true)}
              className="close-sidebar-menu-btn cursor-pointer"
            />
          </div>
        }
        className="mobile-sidebar-menu !bg-antd-colorBgContainer"
      >
        <div className="flex flex-col h-full">
          <BusinessSelector />
          <SidebarNavItems />
          <div className="mt-auto">
            <SidebarBottomMenu />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileHeader;
