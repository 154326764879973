import { Button, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/interface";
import dayjs from "dayjs";
import { FADE_IN_ANIMATION } from "fixtures/motionAnimations";
import { motion } from "framer-motion";
import PostPageOptionMenu from "pages/Posts/components/PostPageOptionMenu";
import { useMemo } from "react";
import { ToolbarProps } from "react-big-calendar";
import {
  MdInfoOutline,
  MdNavigateBefore,
  MdNavigateNext,
  MdOutlineEditCalendar,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { Post } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import LoadingIndicator from "../components/LoadingIndicator";
import { CALENDAR_POST_SCREENS } from "../utils/postCalendarFixtures";

const PostCalendarNavigation = ({
  date,
  postList,
  onNavigate,
}: { postList?: Post[] } & ToolbarProps) => {
  const navigate = useNavigate();
  const postData = useAppSelector(selectPostStore);

  const isLoadingPosts = useMemo(
    () =>
      Boolean(
        CALENDAR_POST_SCREENS.filter(
          (screen) =>
            !postData[screen].isInitialized || postData[screen].isLoading
        ).length
      ),
    [postData]
  );

  const dayjsDate = dayjs(date);

  const manageScheduleItem: ItemType = {
    key: "manage-schedule",
    label: "Manage schedule",
    icon: <MdOutlineEditCalendar />,
    onClick: () => navigate("/integrations"),
  };

  return (
    <div className="w-full sm:sticky top-0 bg-gradient-to-b from-antd-colorBgLayout from-95% z-10 pt-2 max-sm:-mt-4">
      <div className="w-full flex gap-6 items-center text-antd-colorTextBase">
        <motion.div
          className="flex max-sm:flex-wrap max-sm:gap-2 max-sm:w-1/2 gap-4 items-center sm:justify-center"
          {...FADE_IN_ANIMATION}
        >
          <div className="flex justify-center gap-2 text-3xl max-sm:text-xl">
            <span className="font-semibold">{dayjsDate.format("MMM")}</span>
            <span className="font-light text-antd-colorTextSecondary">
              {dayjsDate.format("YYYY")}
            </span>
          </div>

          <div className="flex gap-1">
            <Button size="small" onClick={() => onNavigate("TODAY")}>
              Today
            </Button>
            <Button
              onClick={() => onNavigate("PREV")}
              icon={<MdNavigateBefore />}
              size="small"
            />
            <Button
              onClick={() => onNavigate("NEXT")}
              icon={<MdNavigateNext />}
              size="small"
            />
          </div>
        </motion.div>

        {isLoadingPosts && <LoadingIndicator />}

        <div className="max-sm:self-end ml-auto flex gap-3 items-center justify-center">
          <Tooltip title="All post times are displayed in this timezone.">
            <div className="flex gap-1 items-center cursor-pointer text-antd-colorTextSecondary">
              <span className=" text-xs">{dayjsDate.format("z")}</span>
              <MdInfoOutline />
            </div>
          </Tooltip>
          <PostPageOptionMenu
            postList={postList}
            additionalItems={manageScheduleItem}
            downloadScreens={postList ? undefined : ["scheduled", "published"]}
          />
        </div>
      </div>
    </div>
  );
};

export default PostCalendarNavigation;
