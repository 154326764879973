export const JWT_TOKEN = "token";
export const CURRENT_BUSINESS_ID = "currentBusiness";
export const SHOW_TOPICS_TOUR = "SHOW_TOPICS_TOUR";
export const DEFAULT_EMOJI_SKIN_TONE = "DEFAULT_EMOJI_SKIN_TONE";
export const SELECTED_INTEGRATIONS = "SELECTED_INTEGRATIONS";
export const IS_NEW_USER = "IS_NEW_USER";
export const CALENDAR_VIEW = "CALENDAR_VIEW";
export const SHOW_SCHEDULED_POSTS_TOUR = "SHOW_SCHEDULED_POSTS_TOUR";
export const SHOW_ONBOARDING_TOUR = "SHOW_ONBOARDING_TOUR";
export const SCRAPED_IMAGES = "SCRAPED_IMAGES";
