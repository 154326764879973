export const STANDARD_TIKTOK_EVENT_CONTENT = {
  content_id: "",
  content_type: "",
  content_name: "",
};

// Standard ttq events https://ads.tiktok.com/help/article/supported-standard-events?lang=en
export const TIKTOK_EVENT_DATA = {
  CompletePayment: { currency: "", value: 0, ...STANDARD_TIKTOK_EVENT_CONTENT },
};

export const TTQ_REGISTRATION_CONTENT = {
  content_id: "complete-registration",
  content_type: "registration",
  content_name: "Complete Registration",
};
