import LogoUpload from "components/LogoAndAvatar/LogoUpload";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import AvatarUpload from "./AvatarUpload";
import { UploadComponentProps } from "./uploadConstants";

// TODO: LogoUpload and AvatarUpload should not be separate components
// Merge them into this file, remove duplicate code
const LogoAndAvatar = (props: UploadComponentProps) => {
  const { hasPermission } = useUserPermissions();
  if (props.isRecreatingAccount && !localStorage.logo) {
    return;
  }

  return (
    <div className="logo-and-avatar">
      <LogoUpload
        {...props}
        canEditBusiness={hasPermission("business_details_write")}
      />
      {!props.isNewClient && (
        <AvatarUpload
          {...props}
          canEditBusiness={hasPermission("business_details_write")}
        />
      )}
    </div>
  );
};

export default LogoAndAvatar;
