import { Alert, Button, ColorPicker, message } from "antd";
import { getColorsFromLogo } from "api/businesses";
import { Realm } from "api/config/chalice-api";
import {
  apcaContrast,
  BLACK_WHITE_HEXES,
} from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/colorsUtils";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "store";
import { updateRealmDetails } from "store/user/userActions";

const CONTRAST_THRESHOLD = 70; // Minimum APCA contrast level for AA standard

const DomainColorPicker = ({ realm }: { realm: Realm }) => {
  const dispatch = useAppDispatch();
  const [selectedColor, setSelectedColor] = useState(realm.primary_color);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [isAutoFillingColor, setIsAutoFillingColor] = useState(false);

  const showColorWarning = useMemo(() => {
    return BLACK_WHITE_HEXES.some(
      (hex) => apcaContrast(selectedColor, hex) < CONTRAST_THRESHOLD
    );
  }, [selectedColor]);

  useEffect(() => {
    const updateRealmColor = async () => {
      try {
        await dispatch(
          updateRealmDetails({
            ...realm,
            primary_color: selectedColor,
          })
        );
      } catch (error) {
        message.error("Couldn't save the color. Please try again.");
        setSelectedColor(realm.primary_color);
      }
    };

    if (!pickerOpen && realm.primary_color !== selectedColor) {
      updateRealmColor();
    }
  }, [selectedColor, pickerOpen]);

  const autoFillColorUsingLogo = async () => {
    setIsAutoFillingColor(true);
    try {
      const { accent_color: brandColor } = await getColorsFromLogo(
        realm.logo_url
      );
      await dispatch(
        updateRealmDetails({ ...realm, primary_color: brandColor })
      );
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
    setIsAutoFillingColor(false);
  };

  return (
    <div className="space-y-2">
      <div className="text-sm font-semibold">Colors</div>
      <div className="flex flex-wrap items-center gap-2">
        <ColorPicker
          disabled={isAutoFillingColor}
          disabledAlpha
          showText={() => "Brand"}
          value={selectedColor}
          onChangeComplete={(value) => setSelectedColor(value.toHexString())}
          onOpenChange={setPickerOpen}
        />
        {showColorWarning && (
          <Alert
            type="warning"
            showIcon
            message="The color you selected may not provide enough contrast in the client portal. We suggest choosing a color that is easy to see against both a black and white background."
          />
        )}
      </div>
      <Button
        onClick={autoFillColorUsingLogo}
        loading={isAutoFillingColor}
        className="p-0"
        type="link"
      >
        Autofill using logo
      </Button>
    </div>
  );
};

export default DomainColorPicker;
