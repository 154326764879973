import {
  Integration as ChaliceIntegration,
  Realm,
  SelectedPage,
} from "api/config/chalice-api";
import { INTEGRATION_ITEMS } from "fixtures/constants";
import { JWT_TOKEN, SHOW_ONBOARDING_TOUR } from "fixtures/localStorageKeys";
import { Business, EMPTY_BUSINESS } from "store/business/businessConstants";
import { TypesafeChaliceUser } from "./userActions";

export type Palette = { [key: string]: string };

// TODO: below is evaluating to 'string', update INTEGRATION_ITEMS to be TS and cast the 'name's as const
export type IntegrationType =
  (typeof INTEGRATION_ITEMS)[number]["integrationType"];

export type Integration = Omit<ChaliceIntegration, "id"> & {
  id: string;
  type: IntegrationType;
  selected_page?: SelectedPage;
};

export type UserInfo = TypesafeChaliceUser & {
  isAnonymous: boolean | null;
};

export type ShowOnboardingTour = boolean | "force";

export type UserState = {
  token: string | null;
  userLoading: boolean;
  isAgencyOwner: boolean;
  isAgencyMember: boolean;
  userInfo: UserInfo;
  ownedRealm: Realm | null;
  businesses: Business[];
  currentBusiness: Business;
  imageSearchTerms: string[];
  businessUpdated: { [key in "branding" | "content"]: boolean };
  onboardingTourStep: number | null;
  showOnboardingTour: ShowOnboardingTour;
};

const EMPTY_USER_INFO_USER = {
  id: "",
  created_at: "",
  subscription: {
    plan: "free" as const,
    extra_businesses: 0,
    subscription_id: "",
    customer_id: "",
  },
  invoice_data: {},
  picture: "",
  is_superuser: false,
  given_name: "",
  family_name: "",
  email: "",
  phone: "",
  isAnonymous: null,
};

export const INITIAL_USER_STATE: UserState = {
  token: localStorage.getItem(JWT_TOKEN),
  userLoading: false,
  isAgencyMember: false,
  isAgencyOwner: false,
  userInfo: EMPTY_USER_INFO_USER,
  ownedRealm: null,
  businesses: [],
  currentBusiness: EMPTY_BUSINESS,
  imageSearchTerms: [],
  businessUpdated: { content: false, branding: false },
  onboardingTourStep: null,
  showOnboardingTour: JSON.parse(
    localStorage.getItem(SHOW_ONBOARDING_TOUR) ?? "false"
  ),
};

export type SignupCredentials = {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
};
