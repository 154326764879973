import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

export type AnimationConfigType = {
  showDuration: boolean;
  animations: string[];
  showDelay: boolean;
  animationType: "enter" | "exit" | "loop";
};

export const ANIMATION_CONFIG: { [key: string]: AnimationConfigType } = {
  entry: {
    showDelay: true,
    showDuration: true,
    animations: ["None", "Move", "Fade", "Zoom"],
    animationType: "enter",
  },
  emphasis: {
    showDelay: false,
    showDuration: false,
    animations: ["None", "Rotate", "Blink", "Bounce"],
    animationType: "loop",
  },
  exit: {
    showDelay: false,
    showDuration: true,
    animations: ["None", "Move", "Fade", "Zoom"],
    animationType: "exit",
  },
};

export const ANIMATION_DIRECTIONS = [
  { name: "up", icon: <MdOutlineKeyboardArrowUp size={24} /> },
  {
    name: "top-right",
    icon: <MdOutlineKeyboardArrowUp size={24} className="rotate-45" />,
  },
  { name: "right", icon: <MdOutlineKeyboardArrowRight size={24} /> },
  {
    name: "bottom-right",
    icon: <MdOutlineKeyboardArrowRight size={24} className="rotate-45" />,
  },
  { name: "down", icon: <MdOutlineKeyboardArrowDown size={24} /> },
  {
    name: "bottom-left",
    icon: <MdOutlineKeyboardArrowDown size={24} className="rotate-45" />,
  },
  { name: "left", icon: <MdOutlineKeyboardArrowLeft size={24} /> },
  {
    name: "top-left",
    icon: <MdOutlineKeyboardArrowLeft size={24} className="rotate-45" />,
  },
];

export const DEFAULT_ANIMATION_SETTINGS = {
  name: "none",
  delay: 0,
  duration: 1000,
  data: { direction: "up" },
};
