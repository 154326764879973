import { Button, Empty, message } from "antd";
import axios from "api/config/axiosConfig";
import { Dayjs } from "dayjs";
import { genericError } from "fixtures/globalConstants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getPostsThunk } from "store/posts/postActions";
import { selectPostStore } from "store/posts/postSlice";
import { queuesGetter, setQueues } from "store/user/userSlice";
import {
  extractDaysAndTimes,
  findOverlappingTimeIndices,
  generateTimeSlotStrings,
} from "utils/scheduleUtils";
import CustomScheduleConfirmationModal from "./CustomScheduleConfirmationModal";
import DaySelector from "./DaySelector";
import JitterSelect from "./JitterSelect";
import TimePickers from "./TimePickers";
import { DayOfWeek } from "./scheduleConstants";

const PostScheduleManager = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector((state) => state.user.currentBusiness);
  const { scheduled } = useAppSelector(selectPostStore);
  const queues = useAppSelector(queuesGetter);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isScheduleEdited, setIsScheduleEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDays, setSelectedDays] = useState<DayOfWeek[]>([]);
  const [times, setTimes] = useState<Dayjs[]>([]);
  const [jitter, setJitter] = useState(0);

  const isButtonDisabled = useMemo(() => {
    return !selectedDays.length || findOverlappingTimeIndices(times).length > 0;
  }, [selectedDays, times]);

  const updateSchedule = async (showLoader = true, force?: boolean) => {
    if (!isScheduleEdited) {
      return setIsEditing(false);
    }

    setShowConfirmationModal(false);
    if (scheduled.count && isScheduleEdited && !force) {
      return setShowConfirmationModal(true);
    }

    showLoader && setIsLoading(true);
    try {
      const requestMethod = queues.length ? "put" : "post";
      const queueId = queues.length ? queues[0].id : "";
      const response = await axios[requestMethod](`/queues/${queueId}`, {
        schedule_timeslots: generateTimeSlotStrings(times, selectedDays),
        schedule_jitter: jitter,
        business_id: id,
      });
      dispatch(setQueues([response.data.queue]));
      showLoader && message.success("Posting schedule updated successfully");
      dispatch(getPostsThunk({ postScreen: "scheduled" }));
    } catch (error) {
      genericError(error);
    }

    setIsLoading(false);
    setIsEditing(false);
  };

  const resetSchedule = useCallback(() => {
    if (!queues?.length) {
      return;
    }

    const { days, times } = extractDaysAndTimes(queues[0].schedule_timeslots);
    setJitter(queues[0].schedule_jitter);
    setSelectedDays(days);
    setTimes(times);
    setTimeout(() => setIsScheduleEdited(false), 500);
  }, [queues]);

  const handleCancel = useCallback(() => {
    resetSchedule();
    setIsEditing(false);
  }, [resetSchedule]);

  useEffect(resetSchedule, [queues]);

  useEffect(() => {
    setIsScheduleEdited(true);
  }, [times, selectedDays, jitter]);

  return (
    <div className="card">
      <header>
        <h2>Publishing Schedule</h2>
        <span>
          When you click <i>schedule</i> on a post, we will schedule it to go
          out at these times.
        </span>
      </header>

      {!queues.length && !isEditing && (
        <main>
          <Empty description="No schedule configured" />
          <Button
            type="primary"
            onClick={() => setIsEditing(true)}
            className="config-btn"
          >
            Configure Schedule
          </Button>
        </main>
      )}
      {(!!queues.length || isEditing) && (
        <main onClick={() => setIsEditing(true)}>
          <div className="schedule-manager__main__block">
            <label>{isEditing ? "Select publishing days" : "Post on"}</label>
            <DaySelector
              setSelectedDays={setSelectedDays}
              selectedDays={selectedDays}
              isEditing={isEditing}
            />
          </div>
          <div className="schedule-manager__main__block">
            <label>At</label>
            <TimePickers
              isEditing={isEditing}
              setTimes={setTimes}
              times={times}
            />
          </div>
          <div className="schedule-manager__main__block schedule-manager__main__block--row">
            <label>
              {isEditing ? "Randomize posting time by +/-" : "Vary posting by"}{" "}
            </label>
            <JitterSelect
              setJitter={setJitter}
              isEditing={isEditing}
              jitter={jitter}
            />
          </div>
        </main>
      )}
      <footer>
        {(!!selectedDays.length || isEditing) &&
          (isEditing ? (
            <>
              <Button onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={isButtonDisabled}
                onClick={() => updateSchedule()}
                loading={isLoading}
              >
                Save
              </Button>
            </>
          ) : (
            <Button onClick={() => setIsEditing(true)}>Edit Schedule</Button>
          ))}

        <CustomScheduleConfirmationModal
          saveSchedule={() => updateSchedule(true, true)}
          close={() => setShowConfirmationModal(false)}
          resetSchedule={resetSchedule}
          open={showConfirmationModal}
        />
      </footer>
    </div>
  );
};

export default PostScheduleManager;
