/* eslint-disable react-refresh/only-export-components */
import { VOID_FUNCTION } from "fixtures/globalConstants";
import { StoreType } from "polotno/model/store";
import CropImage from "./ImageEditor/ControlButtons/CropImage";
import CustomColorPicker from "./ImageEditor/ControlButtons/CustomColorPicker";
import CustomPageDuration from "./ImageEditor/ControlButtons/CustomPageDuration";
import FontSelector from "./ImageEditor/ControlButtons/FontSelector";
import ImageControls from "./ImageEditor/ControlButtons/ImageControls";
import PageToolbar from "./ImageEditor/ControlButtons/PageToolbar";
import SVGColorFill from "./ImageEditor/ControlButtons/SVGColorFill";
import TextSizeInput from "./ImageEditor/ControlButtons/TextSizeInput";
import UndoRedoControls from "./ImageEditor/ControlButtons/UndoRedoControls";
import { SidePanelTab } from "./polotnoTypes";

export const HiddenPanel = () => <></>;
export const HiddenTab: SidePanelTab = () => HiddenPanel();
HiddenTab.displayName = "none";

export const ANIMATE_BUTTONS = [
  "FigureAnimations",
  "ImageAnimations",
  "VideoAnimations",
  "TextAnimations",
  "LineAnimations",
  "ManyAnimations",
  "SvgAnimations",
];

export const TOOLBAR_COMPONENTS = {
  History: UndoRedoControls,
  FigureFill: CustomColorPicker,
  PageDuration: CustomPageDuration,
  TextFontFamily: FontSelector,
  TextFontSize: TextSizeInput,
  TextFill: CustomColorPicker,
  SvgColors: SVGColorFill,
  ImageCrop: CropImage,
  PageToolbar,
  ImageControls,

  ImageRemoveBackground: VOID_FUNCTION,
  Group: VOID_FUNCTION,
};

export const addPolotnoElement = ({
  type = "image",
  store,
  src,
  position,
}: {
  type?: "video" | "image";
  store: StoreType;
  src: string;
  position?: { x: number; y: number };
}) => {
  return store.activePage.addElement({
    type,
    src,
    x: position?.x || store.activePage.computedWidth / 2 - 125,
    y: position?.y || store.activePage.computedWidth / 2 - 125,
    width: 600,
    height: 600,
    ...(type === "video" && { endTime: 36 }),
  });
};
