import { Button, Empty } from "antd";
import { MdErrorOutline } from "react-icons/md";

const ErrorFallback = () => (
  <Empty
    className="w-dvw h-dvh flex justify-center items-center flex-col"
    imageStyle={{ height: "auto" }}
    description={
      <div className="flex items-center flex-col gap-3">
        <h2 className="text-3xl text-black">Oops! Something went wrong.</h2>
        <p className="text-base font-normal w-2/3 mb-4">
          We're sorry for the inconvenience. Please try refreshing the page. If
          the problem persists, contact our support team for assistance.
        </p>
        <Button size="large" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </div>
    }
    image={<MdErrorOutline size={64} />}
  />
);

export default ErrorFallback;
