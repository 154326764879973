import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { genericError } from "fixtures/globalConstants";
import BusinessLoader from "pages/NewBusinessOnboarding/components/BusinessLoader";
import { DEFAULT_PALETTE } from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/PRESET_PALETTES";
import { useAppDispatch } from "store";
import { createNewBusiness } from "store/business/businessActions";
import { resetPostData } from "store/posts/postSlice";
import { getUserDetails } from "store/user/userActions";
import { setBusiness } from "store/user/userSlice";
import { BusinessDetails, IteratePartFn } from "../businessOnboardingConstants";

const GenerateBusinessProfile = ({
  businessDetails,
  saveBusinessDetails,
  iteratePart,
}: {
  businessDetails: BusinessDetails;
  saveBusinessDetails: (data: BusinessDetails) => void;
  iteratePart: IteratePartFn;
}) => {
  const dispatch = useAppDispatch();

  const createBusinessQuery = useQuery({
    queryKey: [businessDetails],
    queryFn: async () => {
      const publishTime = dayjs.utc(dayjs().hour(15).minute(0)).format("HH:mm");
      try {
        const { business } = await dispatch(
          createNewBusiness({
            summary: businessDetails.content ?? "",
            daily_publishing_time: publishTime,
            website: businessDetails.website,
            brand: {
              palettes: [
                {
                  ...DEFAULT_PALETTE,
                  ...(businessDetails.accentColor && {
                    accent_color: businessDetails.accentColor,
                  }),
                },
              ],
              logo: businessDetails.logoUrl,
            },
          })
        ).unwrap();

        await dispatch(getUserDetails());

        dispatch(setBusiness(business));
        dispatch(resetPostData());

        saveBusinessDetails(business);
        iteratePart("next");

        return "done";
      } catch (error) {
        genericError(error);
        iteratePart("previous");
        return "failed";
      }
    },
    enabled: !businessDetails.id,
  });

  return <BusinessLoader complete={createBusinessQuery.isSuccess} />;
};

export default GenerateBusinessProfile;
