import { Alert, Button, Empty } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import { range } from "lodash";
import { useMemoriesQuery } from "pages/Memory/hooks/useMemoriesQuery";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import AddTopicOrMemory from "./components/AddTopicOrMemory";
import MemoryCard from "./components/MemoryCard";

const MemoryPage = () => {
  const { realm } = useAppContext();
  const [isAddMemoryModalOpen, setIsAddMemoryModalOpen] = useState(false);

  const { data: memoryList, isLoading, isError, refetch } = useMemoriesQuery();

  return (
    <div className="card">
      <header>
        <h2>Memory</h2>
        {realm.isMarky && (
          <span>
            Memory is where you store important details for Marky to reference,
            like products, services, or insights you want us to remember when
            creating posts.
          </span>
        )}
      </header>

      <main className="flex flex-col gap-2">
        {isLoading ? (
          range(5).map((i) => <Skeleton height={62} key={i} />)
        ) : isError ? (
          <Alert
            description="We're having difficulty loading your memories right now, please try again or contact support if this error persists."
            type="error"
          />
        ) : !memoryList?.length ? (
          <Empty description="No memories found" />
        ) : (
          memoryList?.map((memory) => (
            <MemoryCard
              refetchMemoryList={refetch}
              key={memory.id}
              memory={memory}
            />
          ))
        )}
      </main>

      <footer>
        <Button
          size="large"
          onClick={() => setIsAddMemoryModalOpen(true)}
          icon={<MdAdd />}
        >
          New Memory
        </Button>
      </footer>

      <AddTopicOrMemory
        close={() => setIsAddMemoryModalOpen(false)}
        isOpen={isAddMemoryModalOpen}
        entityType="memory"
        refetchMemoryList={refetch}
      />
    </div>
  );
};

export default MemoryPage;
