import { Modal, ModalProps } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import { getPostsThunk } from "store/posts/postActions";
import { resetGeneratedPosts } from "store/posts/postSlice";
import { setBusinessUpdated } from "store/user/userSlice";
import useApplyBrandingChanges from "utils/useApplyBrandingChanges";

const BrandingChangesModal = ({
  setIsApplyingBrandingChanges,
  ...contentModalProps
}: ModalProps & {
  setIsApplyingBrandingChanges: (applying: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const businessUpdated = useAppSelector((state) => state.user.businessUpdated);

  const { applyBrandingChanges, clearLocalStorage } = useApplyBrandingChanges();

  const resetContentUpdated = () => {
    dispatch(setBusinessUpdated({ content: false }));
    clearLocalStorage(true);
  };

  const resetBrandingUpdated = () => {
    dispatch(setBusinessUpdated({ content: false, branding: false }));
    clearLocalStorage();
  };

  const handleApplyBranding = async () => {
    setIsApplyingBrandingChanges(true);
    await applyBrandingChanges();

    dispatch(resetGeneratedPosts());
    await dispatch(getPostsThunk({ postScreen: "generated" }));

    setIsApplyingBrandingChanges(false);
    resetBrandingUpdated();
  };

  return (
    <>
      <Modal
        {...contentModalProps}
        open={businessUpdated.content}
        title="Account changes detected"
        maskClosable={false}
        closable={false}
        centered
        okText="Okay"
        onOk={resetContentUpdated}
        cancelButtonProps={{ hidden: true }}
      >
        Changes were detected in your account which may affect your post
        generations. Generate new posts to see your changes in effect.
      </Modal>
      <Modal
        title="Apply new branding styles?"
        open={!businessUpdated.content && businessUpdated.branding}
        maskClosable={false}
        closable={false}
        centered
        okText="Apply branding changes"
        onOk={handleApplyBranding}
        cancelText="Keep current posts"
        onCancel={resetBrandingUpdated}
      >
        Branding changes were detected in your account. Would you like to apply
        the new branding styles? This will not delete your current posts, just
        reload them with your new brand styling.
      </Modal>
    </>
  );
};

export default BrandingChangesModal;
