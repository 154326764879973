type EnvVars = {
  FE_BASE_URL: string;
  LANDING_PAGE_URL: string;
  GROWTHBOOK_CLIENT_KEY: string;
  FRILL_WIDGET_KEY: string;
} & { [key in ApiName]: string };

export type ApiName =
  | "DJANGO_API"
  | "MEDIA_API"
  | "CHALICE_API"
  | "OPENVERSE_API";

const ALL_CONFIGS: Record<EnvName, EnvVars> = {
  DEV: {
    DJANGO_API: "https://stage.backend.mymarky.net",
    MEDIA_API: "https://stage.media.mymarky.net",
    CHALICE_API: "https://psuf5gocxc.execute-api.us-east-1.amazonaws.com",
    OPENVERSE_API: "https://api.openverse.org",

    FE_BASE_URL: "https://dev.mymarky.ai",
    GROWTHBOOK_CLIENT_KEY: "sdk-eRVT3CPIUXQudvX2",
    LANDING_PAGE_URL: "https://www.mymarky.ai",
    FRILL_WIDGET_KEY: "4b0d29ed-77e8-478a-96e8-eb65ed644d51",
  },
  PRODUCTION: {
    CHALICE_API: "https://x5x7v0gecd.execute-api.us-east-1.amazonaws.com",
    DJANGO_API: "https://backend.mymarky.net",
    MEDIA_API: "https://media.mymarky.net",
    OPENVERSE_API: "https://api.openverse.org",

    FE_BASE_URL: "https://app.mymarky.ai",
    GROWTHBOOK_CLIENT_KEY: "sdk-eRVT3CPIUXQudvX2",
    LANDING_PAGE_URL: "https://www.mymarky.ai/",
    FRILL_WIDGET_KEY: "4b0d29ed-77e8-478a-96e8-eb65ed644d51",
  },
};

export const MARKY_REALM_ID = "6d695045-2c51-4525-a920-d4367422b82f" as const;

// TODO: TEMP - Want to show Crisp for Lee. BE to add a generic property to check
// for this, instead of hardcoding the realm_id
// https://mymarky.atlassian.net/browse/SWE-2291
const LEE_REALM_ID = "db3d666c-69b6-4c79-9c8e-d4b5cd49b7ab" as const;
export const CRISP_CHAT_IDS: Record<string, string> = {
  [MARKY_REALM_ID]: "160ed2ac-c152-4a21-8db6-1fe8d859cfb7",
  [LEE_REALM_ID]: "7d94dc42-b376-4b80-af41-751addb6db00",
};

export const ENV_NAME = import.meta.env.VITE_ENV;

const env = ALL_CONFIGS[ENV_NAME];
export default env;
