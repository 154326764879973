import { MotionProps } from "framer-motion";

export const FADE_IN_ANIMATION: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: "easeInOut", duration: 0.25 },
};

export const FAST_FADE_IN_ANIMATION: MotionProps = {
  ...FADE_IN_ANIMATION,
  transition: { ...FADE_IN_ANIMATION.transition, duration: 0.15 },
};

export const SLIDE_UP_ANIMATION: MotionProps = {
  initial: { transform: "translateY(10px)", opacity: 0 },
  animate: { transform: "translateY(0) translateX(0)", opacity: 1 },
  exit: { transform: "translateY(-10px)", opacity: 0 },
  transition: { ease: "easeInOut" },
};

export const SLIDE_RIGHT_ANIMATION: MotionProps = {
  ...SLIDE_UP_ANIMATION,
  exit: { transform: "translateX(500px)", opacity: 0 },
  transition: { duration: 0.4, ease: "easeInOut" },
};

export const SLIDE_LEFT_ANIMATION: MotionProps = {
  ...SLIDE_UP_ANIMATION,
  exit: { transform: "translateX(-500px)", opacity: 0 },
  transition: { duration: 0.4, ease: "easeInOut" },
};
