import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "api/themes";
import { fetchThemesList } from "./themesActions";

type ThemesState = {
  areThemesLoading: boolean;
  themes: Theme[];
};

const initialState: ThemesState = {
  areThemesLoading: false,
  themes: [],
};

const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    resetThemes: (state) => {
      state.areThemesLoading = false;
      state.themes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThemesList.pending, (state) => {
        state.areThemesLoading = true;
      })
      .addCase(fetchThemesList.fulfilled, (state, action) => {
        state.areThemesLoading = false;
        state.themes = action.payload;
      })
      .addCase(fetchThemesList.rejected, (state) => {
        state.areThemesLoading = false;
      });
  },
});

export const { resetThemes } = themesSlice.actions;
export default themesSlice.reducer;
