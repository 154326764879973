import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib";

const CrawlWebsiteToggle = ({
  handleChange,
  checked,
}: {
  handleChange: (e: RadioChangeEvent) => void;
  checked: boolean;
}) => {
  return (
    <div className="crawl-toggle">
      <Radio.Group onChange={handleChange} value={checked}>
        <Radio value={true}>Scan entire site</Radio>
        <Radio value={false}>Scan single page</Radio>
      </Radio.Group>
    </div>
  );
};

export default CrawlWebsiteToggle;
