import { LoadingOutlined } from "@ant-design/icons";
import { message } from "antd";
import { getPresignedUrl } from "api/postsApi";
import { updateUser } from "api/user";
import axios, { isAxiosError } from "axios";
import AvatarInitials from "components/AvatarInitials";
import { genericError } from "fixtures/globalConstants";
import { ChangeEvent, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo, setUserInfo } from "store/user/userSlice";
import {
  ACCEPTED_IMAGE_FORMATS_STR,
  validateUploadedFile,
} from "utils/validateFileUpload";

const ProfilePicture = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserInfo);

  const [isUploading, setIsUploading] = useState(false);

  const updateProfilePicture = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !validateUploadedFile(file, "image")) {
      return;
    }

    setIsUploading(true);
    try {
      const { url } = await getPresignedUrl(file);
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      const updatedUser = await updateUser({ picture_url: url.split("?")[0] });
      dispatch(setUserInfo({ user: updatedUser }));
      message.success("Profile picture updated successfully");
    } catch (error) {
      if (isAxiosError(error) && error.message) {
        message.error(error.message);
        console.error(error);
      } else {
        genericError(error);
      }
    }
    setIsUploading(false);
  };

  return (
    <div className="profile-pic-container">
      {isUploading ? (
        <div className="loading-icon-container">
          <LoadingOutlined className="loading-icon" />
        </div>
      ) : (
        <AvatarInitials user={user} size={64} />
      )}

      {!isUploading && (
        <label htmlFor="profile-pic-upload">
          <MdOutlineModeEditOutline />
          <input
            accept={ACCEPTED_IMAGE_FORMATS_STR}
            onChange={updateProfilePicture}
            type="file"
            id="profile-pic-upload"
          />
        </label>
      )}
    </div>
  );
};

export default ProfilePicture;
