import { Avatar, AvatarProps } from "antd";
import classNames from "classnames";
import { useMemo } from "react";
import { Business } from "store/business/businessConstants";
import { UserInfo } from "store/user/userConstants";
import addStaticCacheBusterParam from "utils/addNoCacheParam";
import { BACKGROUND_COLORS } from "./Common/UserAvatar/backgroundColors";

type AvatarInitialsProps = Either<
  { user: Pick<UserInfo, "family_name" | "given_name" | "picture"> },
  { business: Business }
> &
  AvatarProps;

const AvatarInitials = ({
  user,
  business,
  size = 36,
  ...avatarProps
}: AvatarInitialsProps) => {
  const avatarSrc = useMemo(() => {
    let initials: (string | undefined)[];

    if (business) {
      const { brand, title } = business;
      if (brand.logo || brand.avatar) {
        return addStaticCacheBusterParam(brand.logo ?? brand.avatar);
      }
      const splitTitle = title.split(" ");
      initials = [splitTitle[0]?.[0] ?? "U", splitTitle[1]?.[0]];
    } else {
      if (user.picture) {
        return user.picture;
      }
      // G for Guest
      initials = [user.given_name?.[0] ?? "G", user.family_name?.[0]];
    }

    initials = initials.map((initial) => initial?.toUpperCase());

    return (
      <div
        className={classNames(
          "w-full h-full flex items-center justify-center",
          {
            "text-[8px] leading-[8px]": typeof size === "number" && size <= 20,
            "text-[10px] leading-[10px]":
              (typeof size === "number" && size > 20 && size < 36) ||
              size === "small",
            "text-sm": typeof size === "number" && size >= 36 && size < 72,
            "text-2xl font-bold": typeof size === "number" && size >= 72,
          }
        )}
        style={{ backgroundColor: BACKGROUND_COLORS[initials[0] as string] }}
      >
        {initials[0]}
        {initials[1]}
      </div>
    );
  }, [user, business]);

  return <Avatar size={size} {...avatarProps} src={avatarSrc} />;
};

export default AvatarInitials;
