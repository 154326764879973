import { Modal, ModalProps } from "antd";
import { IconBaseProps } from "react-icons";
import { FaCrown } from "react-icons/fa";
import useGlobalModal from "./GlobalModalContext/useGlobalModal";

const PremiumFeatureModal = ({
  title,
  content,
  ...props
}: ModalProps & { content?: string }) => {
  const { openGlobalModal } = useGlobalModal();
  return (
    <Modal
      {...props}
      title={title ?? "You discovered a premium feature!"}
      cancelText="Dismiss"
      okText="Upgrade"
      onOk={(e) => {
        props.onClose && props.onClose(e);
        openGlobalModal({ modalName: "subscription" });
      }}
    >
      {content ??
        "Upgrade your account to unlock new features and boost your social media game."}
    </Modal>
  );
};

export const PremiumFeatureIcon = ({ className, ...props }: IconBaseProps) => (
  <FaCrown {...props} className={`${className} fill-antd-colorInfo`} />
);

export default PremiumFeatureModal;
