import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NewVersionBanner from "components/AppBanner/NewVersionBanner";
import GlobalModalProvider from "components/GlobalModals/GlobalModalContext/GlobalModalProvider";
import GlobalModals from "components/GlobalModals/GlobalModals";
import Navigation from "components/Navigation";
import ServerDownMessage from "components/ServerDownMessage";
import AppContextProvider from "config/AppContext/AppContextProvider";
import GrowthBookHandler from "config/Growthbook/GrowthBookHandler";
import growthbook from "config/Growthbook/growthbookUtils";
import UserPermissionsProvider from "config/UserPermissionsContext/UserPermissionsProvider";
import { PageLoader } from "designSystem/Loader";
import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import _refiner from "refiner-js";
import { useAppDispatch, useAppSelector } from "store";
import { getFullName, getUserDetails } from "store/user/userActions";
import { autoSetCurrentBusiness, selectUserInfo } from "store/user/userSlice";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import AppRoutes from "./AppRoutes";
import { AGENCY_ROUTES, PUBLIC_ROUTES } from "./fixtures/constants";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const App = () => {
  const pathname = window.location.pathname;

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(
    !PUBLIC_ROUTES.includes(pathname) && localStorage.token
  );
  const [isServerDown, setIsServerDown] = useState(false);

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      await dispatch(getUserDetails());
      !AGENCY_ROUTES.includes(pathname) && dispatch(autoSetCurrentBusiness());
    } catch (error) {
      console.error(error);
      setIsServerDown(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      import.meta.env.VITE_ENV === "PRODUCTION" &&
      pathname !== "/" &&
      // @ts-expect-error missing types
      !LogRocket._isInitialized
    ) {
      LogRocket.init("ozd3n4/marky");
    }
  }, [pathname]);

  useEffect(() => {
    _refiner("setProject", "f9ddd930-2b71-11ef-9a71-6701c988cce0");

    if (pathname === "/sign-up") {
      localStorage.clear();
    } else if (localStorage.token) {
      fetchUserDetails();
    }

    (!localStorage.currentTheme ||
      [undefined, "undefined"].includes(localStorage.currentTheme)) &&
      localStorage.setItem("currentTheme", "light");
  }, []);

  useEffect(() => {
    if (user.id) {
      const plan = user.subscription?.plan;
      const commonConfig = {
        name: getFullName(user),
        email: user.email ?? "N/A",
      };

      _refiner("identifyUser", {
        id: user.id,
        subscription_plan: plan,
        created_at: user.created_at,
        ...commonConfig,
      });

      if (import.meta.env.VITE_ENV === "PRODUCTION") {
        const getProduct = (plan: string): string => {
          if (plan === "free") {
            return "free";
          }
          if (plan.includes("tier")) {
            return "appsumo";
          }
          return plan.split("-")[0];
        };

        LogRocket.identify(user.id, {
          ...commonConfig,
          ...(plan && { plan: plan, product: getProduct(plan) }),
        });
        Sentry.setUser({ email: user.email ?? "N/A", id: user.id });
      }
    }
  }, [user]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          {isServerDown ? (
            <ServerDownMessage />
          ) : isLoading ||
            (!PUBLIC_ROUTES.includes(pathname) &&
              !user.id &&
              localStorage.token) ? (
            <PageLoader large />
          ) : (
            <GoogleOAuthProvider
              clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
            >
              <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <GrowthBookHandler />
                  <UserPermissionsProvider>
                    <GlobalModalProvider>
                      <NewVersionBanner />
                      <Navigation />
                      <AppRoutes />
                      <GlobalModals />
                    </GlobalModalProvider>
                  </UserPermissionsProvider>
                </QueryParamProvider>
              </BrowserRouter>
            </GoogleOAuthProvider>
          )}
        </AppContextProvider>
      </QueryClientProvider>
    </GrowthBookProvider>
  );
};

export default App;
