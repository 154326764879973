import { Flex, InputNumber, Menu } from "antd";
import { observer } from "mobx-react-lite";

import { FaFacebook, FaInstagram, FaPinterest } from "react-icons/fa";

const AVAILABLE_SIZES = [
  {
    width: 1080,
    height: 1080,
    label: "Instagram Post",
    icon: <FaInstagram />,
    key: "1",
  },
  {
    width: 1080,
    height: 1920,
    label: "Instagram Story",
    icon: <FaInstagram />,
    key: "2",
  },
  {
    width: 1080,
    height: 1080,
    label: "Instagram Ad",
    icon: <FaInstagram />,
    key: "3",
  },
  {
    width: 940,
    height: 788,
    label: "Facebook Post",
    icon: <FaFacebook />,
    key: "4",
  },
  {
    width: 851,
    height: 315,
    label: "Facebook Cover",
    icon: <FaFacebook />,
    key: "5",
  },
  {
    width: 1200,
    height: 628,
    label: "Facebook Ad",
    icon: <FaFacebook />,
    key: "6",
  },
  {
    width: 1200,
    height: 628,
    label: "Facebook Ad",
    icon: <FaFacebook />,
    key: "7",
  },
  {
    width: 1000,
    height: 1500,
    label: "Pinterest Post",
    icon: <FaPinterest />,
    key: "8",
  },
];

const CustomResizePanel: ObserverComponent = observer(({ store }) => {
  return (
    <div className="h-full overflow-auto">
      <Flex align="center" justify="space-between">
        <span>Width (px)</span>
        <InputNumber
          onChange={(value) => store.set({ width: value })}
          value={store.width}
        />
      </Flex>
      <Flex
        style={{ marginTop: 16, marginBottom: 24 }}
        justify="space-between"
        align="center"
      >
        <span>Height (px)</span>
        <InputNumber
          onChange={(value) => store.set({ height: value })}
          value={store.height}
        />
      </Flex>
      <Menu selectable={false} className="!border-none resize-panel">
        {AVAILABLE_SIZES.map(({ width, height, label, icon, key }) => (
          <Menu.Item
            onClick={() => store.setSize(width, height, true)} // true to use magic resize
            icon={icon}
            key={key}
            className="!flex items-center"
          >
            {label}
            <span className="text-xs text-antd-colorTextSecondary ml-2">
              ({width}x{height})
            </span>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
});

export default CustomResizePanel;
