import { Button } from "antd";
import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { FADE_IN_ANIMATION } from "fixtures/motionAnimations";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import ImproveYourPostsCard from "./ImproveYourPostsCard";
import {
  IMPROVE_POSTS_CHILD_CARDS,
  IMPROVE_POSTS_ROOT_CARDS,
} from "./improveYourPostsFixture";

const ImproveYourPosts = () => {
  const improveYourPostsEnabled = useTypedFeatureIsOn(
    "improve-your-posts-cards"
  );

  const {
    realm: { isMarky },
  } = useAppContext();
  const { hasPermission } = useUserPermissions();

  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const showCards = useMemo(
    () =>
      selectedCardId
        ? IMPROVE_POSTS_CHILD_CARDS.filter(
            ({ parentId }) => parentId === selectedCardId
          )
        : IMPROVE_POSTS_ROOT_CARDS,
    [selectedCardId]
  );

  if (!improveYourPostsEnabled || !hasPermission("business_details_write")) {
    return null;
  }

  return (
    <div className="py-9 space-y-6 text-center max-w-sm">
      <div className="space-y-1 max-w-xs mx-4">
        <span className="text-xl font-semibold">Improve Your Posts</span>
        <p className="text-antd-colorTextSecondary">
          {isMarky && "Marky gets better over time. "}What would you like to
          improve about your posts?
        </p>
      </div>
      <AnimatePresence>
        <div
          className={classNames("flex gap-4 justify-center items-center", {
            "sm:-ml-12": selectedCardId,
          })}
        >
          {selectedCardId && (
            <motion.div {...FADE_IN_ANIMATION}>
              <Button
                type="text"
                size="small"
                className="flex items-center"
                icon={<MdArrowBackIosNew size={16} />}
                onClick={() => setSelectedCardId(null)}
              />
            </motion.div>
          )}
          {showCards.map((card) => (
            <motion.div
              key={card.id}
              id={card.id}
              onClick={() => !card.path && setSelectedCardId(card.id)}
              {...FADE_IN_ANIMATION}
            >
              <ImproveYourPostsCard {...card} />
            </motion.div>
          ))}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default ImproveYourPosts;
