import { Post } from "store/posts/postConstants";
import MediaEditor from "./ImageEditor/MediaEditor";
import MiniEditorModal from "./MiniEditor/MiniEditorModal";

export type PostEditorProps = {
  post: Post;
  showEditor: PostEditorType | null;
  miniByDefault?: boolean;
  refetchAfterSchedule?: boolean;
  setShowEditor: (showEditor: PostEditorType | null) => void;
};

export type PostEditorType = "mini" | "fullScreen";

const PostEditor = ({
  post,
  miniByDefault,
  showEditor,
  refetchAfterSchedule,
  setShowEditor,
}: PostEditorProps) => {
  const close = () => setShowEditor(null);
  return !showEditor ? null : (
    <>
      <MiniEditorModal
        isOpen={showEditor === "mini" || Boolean(miniByDefault)}
        {...{
          post,
          close,
          refetchAfterSchedule,
          postId: post.id,
          openFullScreen: () => setShowEditor("fullScreen"),
        }}
      />

      <MediaEditor
        closeFullscreen={() => setShowEditor(miniByDefault ? "mini" : null)}
        isOpen={showEditor === "fullScreen"}
        post={post}
      />
    </>
  );
};

export default PostEditor;
