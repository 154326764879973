import { Modal } from "antd";
import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import AppSumoPlanCard from "./AppSumoPlanCard";
import { APPSUMO_PLANS, EXTRA_BRANDS_PACK } from "./appSumoPlansConstants";

import { GlobalModalConfig } from "../GlobalModalContext/globalModalConfig";

export type AppSumoPlansModalProps = {
  modalType: "businessLimitReached" | "upgrade" | "default";
};

export type AppSumoPlansModalData = GlobalModalConfig<
  "appsumo-plans",
  AppSumoPlansModalProps,
  true
>;

const AppSumoPlans = ({
  closeModal,
  modalType,
  isOpen,
}: AppSumoPlansModalData["propTypes"]) => {
  const user = useAppSelector(selectUserInfo);

  const currentPlan = user.subscription?.plan || "";
  const currentTier = Number(currentPlan.replace("marky_tier", ""));

  const title = useMemo(() => {
    const titleMapping = {
      businessLimitReached: "Add More Businesses",
      upgrade: "Upgrade Plan",
      default: "AppSumo Plans",
    };

    return titleMapping[modalType];
  }, [modalType]);

  const description = useMemo(() => {
    const descriptionMapping = {
      businessLimitReached: "To add more businesses, please upgrade your plan.",
      upgrade:
        "Unlock additional premium features of Marky by upgrading your plan.",
      default: "",
    };

    return descriptionMapping[modalType];
  }, [modalType]);

  const plans = useMemo(() => {
    if (modalType === "businessLimitReached") {
      return [
        ...APPSUMO_PLANS.filter(({ tier }) => tier > currentTier),
        EXTRA_BRANDS_PACK,
      ];
    }

    return APPSUMO_PLANS;
  }, [modalType]);

  return (
    <Modal
      className="daily-limit-modal appsumo-plans-modal"
      open={isOpen}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      title={title}
      width={970}
      centered
    >
      {description && (
        <p className="stripe-container__subtitle mt-0">{description}</p>
      )}
      <div className="plan-cards">
        <div className="plan-cards-container">
          {plans.map((plan, i) => (
            <AppSumoPlanCard plan={plan} key={i} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AppSumoPlans;
