import { message } from "antd";
import { genericError } from "fixtures/globalConstants";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { resetGeneratedPosts } from "store/posts/postSlice";
import { fetchThemesList } from "store/themes/themesActions";

const useThemes = () => {
  const dispatch = useAppDispatch();
  const { themes, areThemesLoading } = useAppSelector((state) => state.themes);
  const { themes: preSelectedThemes } = useAppSelector(
    (state) => state.user.currentBusiness?.brand || {}
  );

  const [selectedThemes, setSelectedThemes] = useState([] as string[]);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const sortedThemes = useMemo(() => {
    return [...themes].sort((a, b) => {
      const aName = a.name;
      const bName = b.name;

      const aNameInArray2 = [...(preSelectedThemes || [])].includes(aName);
      const bNameInArray2 = [...(preSelectedThemes || [])].includes(bName);

      if (aNameInArray2 && !bNameInArray2) {
        return -1;
      } else if (!aNameInArray2 && bNameInArray2) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [themes, preSelectedThemes]);

  const handleSelect = (name: string) => {
    if (selectedThemes.includes(name)) {
      setSelectedThemes((prev) => prev.filter((item) => item !== name));
    } else {
      setSelectedThemes((prev) => [...prev, name]);
    }
  };

  // This method is only used in ThemesEditor, which is only used in ThemesCard, which is only rendered on path /themes,
  const handleSave = async () => {
    setIsSaving(true);
    try {
      dispatch(resetGeneratedPosts());
      await dispatch(
        updateBusinessDetails({
          themes: selectedThemes,
        })
      );

      setIsEditing(false);
      message.success("Updated themes successfully");
      localStorage.setItem("areThemesUpdated", "true");
    } catch (error) {
      console.error(error);
    }
    setIsSaving(false);
  };

  const skeletonHeight = () => {
    let containerWidth =
      window.innerWidth > 900 ? 728 : window.innerWidth - 102;

    const container = document.querySelector(".themes-card__grid");

    if (container?.clientWidth) {
      containerWidth = container?.clientWidth - 32;
    }

    let itemsInRow = 3;
    const gridGap = 10;

    if (window.innerWidth <= 600) {
      itemsInRow = 2;
    } else if (window.innerWidth > 600 && window.innerWidth <= 900) {
      itemsInRow = 3;
    } else if (window.innerWidth > 900) {
      itemsInRow = 4;
    }

    return (containerWidth - gridGap * (itemsInRow - 1)) / itemsInRow;
  };

  useEffect(() => {
    dispatch(fetchThemesList()).catch((error) => genericError(error));
  }, []);

  useEffect(() => {
    setSelectedThemes(preSelectedThemes || []);
  }, [preSelectedThemes]);

  useEffect(() => {
    if (!isEditing) {
      setSelectedThemes(preSelectedThemes || []);
    }
  }, [isEditing]);

  return {
    skeletonHeight: skeletonHeight(),
    preSelectedThemes,
    selectedThemes,
    isEditing,
    isSaving,
    sortedThemes,
    themes,
    isLoading: areThemesLoading,

    handleSelect,
    setIsEditing,
    handleSave,
  };
};

export default useThemes;
