import { Button } from "antd";
import { observer } from "mobx-react-lite";

const AnimateButton: ObserverComponent = observer(({ store }) => {
  return (
    <Button onClick={() => store.openSidePanel("animations")} type="text">
      Animate
    </Button>
  );
});

export default AnimateButton;
