import { useContext } from "react";
import { PostStatus } from "store/posts/postConstants";
import { PostSelectionContext } from "./PostSelectionProvider";

const usePostSelectionContext = () => useContext(PostSelectionContext);

export const SELECTION_ENABLED_STATUSES: PostStatus[] = [
  "NEW",
  "LIKED",
] as const;

export default usePostSelectionContext;
