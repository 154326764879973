import { message, Select, Tooltip } from "antd";
import { inviteUserToTeam, removeMember } from "api/teams";
import classNames from "classnames";
import { isEqual, sortBy } from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getUserDetails } from "store/user/userActions";
import { UserInfo } from "store/user/userConstants";
import { currentUserBusinessesGetter } from "store/user/userSlice";
import addStaticCacheBusterParam from "utils/addNoCacheParam";

type AccessDropdownProps = {
  setDisableActionButtons: (disabled: string | null) => void;
  userBusinesses: { [businessId: string]: { roleId: string } };
  removeMemberFromBusiness: (roleId: string, businessId?: string) => void;
  user: UserInfo & { isOwner: boolean };
};

const AccessDropdown = ({
  setDisableActionButtons,
  userBusinesses,
  user,
}: AccessDropdownProps) => {
  const dispatch = useAppDispatch();
  const allBusinesses = useAppSelector(currentUserBusinessesGetter);

  const [isSearching, setIsSearching] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState(
    Object.keys(userBusinesses)
  );

  const handleDeselect = (businessId: string) => {
    if (selectedBusinesses.length === 1) {
      message.error(
        "A team member must have access to at least one business. If you want to remove their access from all businesses, remove them from the team.",
        7
      );
    } else {
      setSelectedBusinesses(selectedBusinesses.filter((b) => b !== businessId));
    }
  };

  const saveChanges = async () => {
    setIsSearching(false);
    if (
      isEqual(sortBy(selectedBusinesses), sortBy(Object.keys(userBusinesses)))
    ) {
      setDisableActionButtons(null);
      return;
    }

    setIsSavingChanges(true);

    try {
      const membershipChangePromises = allBusinesses.map(
        ({ id: businessId, title }) => {
          const isSelected = selectedBusinesses.includes(businessId);
          const isRemoved = userBusinesses[businessId] && !isSelected;
          const isAdded = !userBusinesses[businessId] && isSelected;

          if (isRemoved) {
            return removeMember({
              businessId,
              roleId: userBusinesses[businessId].roleId,
            }).catch((error) => {
              console.error(error);
              message.error(`Failed to remove user from ${title} business.`);
            });
          } else if (isAdded) {
            return inviteUserToTeam({
              userId: user.id,
              businessId,
            }).catch((error) => {
              console.error(error);
              message.error(`Failed to add user to ${title} business.`);
            });
          }
        }
      );
      await Promise.all(membershipChangePromises);
    } catch (error) {
      message.error(
        "Something went wrong. Please refresh the page and try again later."
      );
      console.error(error);
    }

    await dispatch(getUserDetails());
    setDisableActionButtons(null);
    setIsSavingChanges(false);
  };

  return (
    !user.isOwner && (
      <Select
        disabled={isSavingChanges}
        loading={isSavingChanges}
        showSearch
        size="small"
        popupMatchSelectWidth={false}
        className="access-dropdown !w-48 [&_.ant-select-selection-item]:!bg-transparent h-6 [&.ant-select-focused_.ant-select-selection-overflow-item-rest]:!absolute [&.ant-select-focused_.ant-select-selection-overflow-item-rest]:text-antd-colorTextDisabled"
        popupClassName="[&_.ant-select-item]:!py-1 [&_.ant-select-item]:!min-h-8 [&_.ant-select-item]:!h-8"
        mode="multiple"
        optionFilterProp="title"
        value={selectedBusinesses}
        onSelect={(businessId) =>
          setSelectedBusinesses(selectedBusinesses.concat(businessId))
        }
        onSearch={(value) => setIsSearching(Boolean(value))}
        onDropdownVisibleChange={(open) =>
          open && setDisableActionButtons(user.id)
        }
        onDeselect={handleDeselect}
        onBlur={saveChanges}
        options={allBusinesses.map((b) => ({
          label: (
            <div className="flex gap-2 items-center">
              <img
                className="w-5 h-5 rounded-full"
                src={
                  b.brand?.logo || b.brand?.avatar
                    ? addStaticCacheBusterParam(
                        b.brand?.logo || b.brand?.avatar
                      )
                    : undefined
                }
              />
              <span className="text-[13px] truncate max-w-28">{b.title}</span>
            </div>
          ),
          value: b.id,
          title: b.title,
        }))}
        maxTagCount={0}
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip
            overlayStyle={{
              pointerEvents: "none",
            }}
            title={omittedValues.map(({ title }) => title).join(", ")}
          >
            <span
              className={classNames("text-xs", {
                hidden: isSearching,
              })}
            >
              {pluralize("account", omittedValues.length, true)}
            </span>
          </Tooltip>
        )}
      />
    )
  );
};

export default AccessDropdown;
