import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip, TooltipProps } from "antd";

const BrandingImageTooltip = ({
  image,
  description,
  background,
  children,
  ...tooltipProps
}: {
  image: string;
  description: string;
  background?: string;
} & TooltipProps) => (
  <Tooltip
    placement="right"
    {...tooltipProps}
    title={
      <div className="space-y-2">
        <span>{description}</span>
        <div className="aspect-square max-w-fit rounded" style={{ background }}>
          <img src={image} className="w-36" />
        </div>
      </div>
    }
  >
    <div className="flex gap-1 max-w-fit">
      {children}
      <InfoCircleOutlined className="text-antd-colorIcon !text-xs" />
    </div>
  </Tooltip>
);

export default BrandingImageTooltip;
