import { Button, Form } from "antd";
import { useWatch } from "antd/es/form/Form";
import InfoTooltip from "components/Common/InfoTooltip";
import useThemes from "hooks/useThemes";
import { SectionComponent } from "pages/NewBusinessOnboarding/businessFormConstants";
import ImageLibraryConfirmation from "pages/NewBusinessOnboarding/components/ImageLibraryConfirmation";
import OnboardingThemeCard from "pages/NewBusinessOnboarding/components/OnboardingThemeCard";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const BusinessDesign: SectionComponent = ({ form }) => {
  const [showAllThemes, setShowAllThemes] = useState(false);
  const {
    preSelectedThemes,
    sortedThemes,
    isLoading: themesLoading,
  } = useThemes();
  const { setFieldValue, getFieldsValue } = form;

  const formValues = useWatch([], form);
  const { images = [], themes = [] } = formValues ?? { themes: ["init"] };
  const { scrapedImages } = getFieldsValue(true);

  const selectedImages = form.getFieldValue("images");

  useEffect(() => {
    !selectedImages && scrapedImages && setFieldValue("images", scrapedImages);
  }, [scrapedImages, selectedImages]);

  useEffect(() => {
    // Watch is undefined on first render, can't access typesafe form values on first render
    // https://github.com/ant-design/ant-design/issues/26775

    if (themes[0] === "init" || themes.length) {
      return;
    }

    if (preSelectedThemes) {
      setFieldValue("themes", preSelectedThemes);
    } else {
      setShowAllThemes(true);
      setFieldValue("themes", []);
    }
  }, [themes, preSelectedThemes]);

  const toggleTheme = (selectTheme: string) => {
    if (themes.includes(selectTheme)) {
      themes.length > 1 &&
        setFieldValue(
          "themes",
          themes.filter((theme) => theme !== selectTheme)
        );
    } else {
      setFieldValue("themes", themes.concat(selectTheme) ?? [selectTheme]);
    }
  };

  return (
    <div className="min-h-[400px]">
      <Form.Item
        name="themes"
        label={
          <>
            Themes
            <InfoTooltip
              title="Choose how you want your post graphics to look."
              className="text-antd-colorIcon"
            />
          </>
        }
      >
        {themesLoading ? (
          <Skeleton
            count={3}
            width={100}
            height={100}
            containerClassName="flex flex-wrap gap-1"
          />
        ) : (
          <div className="space-y-4">
            <div className="grid grid-cols-3 lg:grid-cols-4 gap-2">
              {sortedThemes.map(
                (theme, index) =>
                  (showAllThemes || themes.includes(theme.name)) && (
                    <OnboardingThemeCard
                      key={index}
                      {...{
                        themes,
                        toggleTheme,
                        theme,
                      }}
                    />
                  )
              )}
            </div>
            <Button
              size="small"
              onClick={() => setShowAllThemes(!showAllThemes)}
            >
              {showAllThemes ? "Collapse" : "Show All Themes"}
            </Button>
          </div>
        )}
      </Form.Item>

      <Form.Item name="images">
        <ImageLibraryConfirmation
          hideInitialLoader
          scrapedImages={scrapedImages}
          selectedImages={images ?? []}
          setSelectedImages={(images) => setFieldValue("images", images)}
          labelClassName="font-medium"
          galleryClassName="-mt-3 -mx-3"
        />
      </Form.Item>
    </div>
  );
};

export default BusinessDesign;
