import { PageLoader } from "designSystem/Loader";
import { StateParam } from "fixtures/socialAuthConfig";
import { useEffect } from "react";
import { useQueryParam } from "use-query-params";

const IntegrationProxy = () => {
  const [state] = useQueryParam("state", StateParam);

  useEffect(() => {
    if (state?.redirectTo) {
      window.open(`${state.redirectTo}${window.location.search}`, "_self");
    }
  }, [state]);

  // TODO: make width and text props optional
  return <PageLoader large width={undefined} text={undefined} />;
};

export default IntegrationProxy;
