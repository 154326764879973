import { Tooltip } from "antd";
import { ReactComponent as Facebook } from "assets/icons/facebook.svg";
import { ReactComponent as GoogleBusiness } from "assets/icons/google-business.svg";
import { ReactComponent as InstagramStories } from "assets/icons/instagram-stories.svg";
import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "assets/icons/linkedin.svg";
import { ReactComponent as Pinterest } from "assets/icons/pinterest.svg";
import { ReactComponent as TikTok } from "assets/icons/tiktok.svg";
import { ReactComponent as Twitter } from "assets/icons/twitter.svg";
import dayjs from "dayjs";
import { FC, SVGProps, useMemo } from "react";
import { Link } from "react-router-dom";
import { Business } from "store/business/businessConstants";
import { IntegrationType } from "store/user/userConstants";

const SOCIALS_ICONS: Record<IntegrationType, FC<SVGProps<SVGSVGElement>>> = {
  googleBusiness: GoogleBusiness,
  linkedInProfile: LinkedIn,
  pinterest: Pinterest,
  instagram: Instagram,
  instagramStory: InstagramStories,
  linkedIn: LinkedIn,
  facebook: Facebook,
  twitter: Twitter,
  tiktok: TikTok,
};

const LastPublishedTime = ({
  postsData,
}: {
  postsData: Business["posts_data"];
}) => {
  const publishedLinks = useMemo(
    () =>
      (postsData?.last_published_links &&
        Object.entries(postsData.last_published_links).reduce<
          {
            link: string | null;
            Icon: (typeof SOCIALS_ICONS)[IntegrationType];
          }[]
        >((prev, [key, link]) => {
          const Icon =
            key in SOCIALS_ICONS
              ? SOCIALS_ICONS[key as IntegrationType]
              : undefined;

          return Icon ? prev.concat({ link, Icon }) : prev;
        }, [])) ??
      null,
    [postsData?.last_published_links]
  );

  return (
    <div className="flex items-center gap-4">
      {postsData?.last_published_time
        ? dayjs(postsData.last_published_time).format("MM/DD/YY, hh:mm A")
        : "Nothing published yet"}
      <div className="flex items-center gap-2 [&_*]:size-4">
        {publishedLinks?.map(({ Icon, link }, index) =>
          link ? (
            <Link key={index} target="_blank" to={link}>
              <Icon />
            </Link>
          ) : (
            <Tooltip key={index} title="Link is expired or unavailable">
              <Icon />
            </Tooltip>
          )
        )}
      </div>
    </div>
  );
};

export default LastPublishedTime;
