import { Modal, Segmented } from "antd";
import Link from "antd/es/typography/Link";
import { getActiveCoupons } from "fixtures/coupons";
import { ONBOARDING_ROUTE } from "pages/NewBusinessOnboarding/BusinessOnboardingModal";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { GlobalModalConfig } from "../GlobalModalContext/globalModalConfig";
import useGlobalModal from "../GlobalModalContext/useGlobalModal";
import AddBusinessModal from "./AddBusinessModal";
import PlanCard from "./PlanCard";
import {
  DEFAULT_YEARLY_DISCOUNT,
  MODAL_TEXT,
  PlanKey,
  PLANS_DICT,
  SubscriptionInterval,
} from "./subscriptionConstants";

type SubscriptionModalProps = {
  modalType?:
    | "upgradeToPro"
    | "upgradeToProfessional"
    | "discountedDeal"
    | "addBusiness"
    | "businessLimitReached"
    | "default";
  initialInterval?: SubscriptionInterval;
};

export type SubscriptionModalData = GlobalModalConfig<
  "subscription",
  SubscriptionModalProps
>;

const SubscriptionModal = ({
  modalType = "default",
  initialInterval,
  closeModal,
  isOpen,
}: SubscriptionModalData["propTypes"]) => {
  const { openGlobalModal } = useGlobalModal();
  const { pathname } = useLocation();

  const { subscription, is_agency } = useAppSelector(selectUserInfo);

  const [interval, setInterval] = useState<"monthly" | "yearly">(
    initialInterval ||
      (subscription?.plan?.split("-")?.[1] as SubscriptionInterval) ||
      "monthly"
  );
  const [loadingPlan, setLoadingPlan] = useState<PlanKey | null>(null);
  const [showAgencyPlan, setShowAgencyPlan] = useState(is_agency);

  const { planKeys, ...modalText } = useMemo(
    () => MODAL_TEXT[showAgencyPlan ? "agency" : "nonAgency"],
    [showAgencyPlan]
  );

  useEffect(() => {
    const items = planKeys.map((planKey) => ({
      item_id: planKey,
      item_name: PLANS_DICT[planKey].name,
    }));
    trackEvent("view_item_list", { items });
  }, [planKeys]);

  useEffect(() => {
    const showUpgradePlanModal = () =>
      openGlobalModal({ modalName: "subscription" });

    window.addEventListener("payment-required", showUpgradePlanModal);

    return () => {
      window.removeEventListener("payment-required", showUpgradePlanModal);
    };
  }, []);

  const successPath = useMemo(
    () =>
      ["businessLimitReached", "addNewBusiness"].includes(modalType)
        ? ONBOARDING_ROUTE
        : pathname,
    [modalType, pathname]
  );

  const intervalCoupons = useMemo(
    () => getActiveCoupons({ interval, currentPlan: subscription?.plan }),
    [interval, subscription?.plan]
  );

  const potentialCoupons = useMemo(
    () => getActiveCoupons({ currentPlan: subscription?.plan }),
    [subscription?.plan]
  );

  if (modalType === "businessLimitReached") {
    return <AddBusinessModal close={closeModal} />;
  }

  return (
    <Modal
      className="daily-limit-modal"
      maskClosable={!loadingPlan}
      closable={!loadingPlan}
      onCancel={closeModal}
      footer={
        <div className="text-antd-colorTextSecondary text-center">
          {modalText.footerStart}{" "}
          <Link
            onClick={() => setShowAgencyPlan(!showAgencyPlan)}
            className="!underline"
          >
            {modalText.footerLink}
          </Link>{" "}
          {pluralize("plan", planKeys.length)}
        </div>
      }
      title={modalText.title}
      open={isOpen}
      width={1000}
      centered
    >
      <div className="text-antd-colorTextSecondary mb-6">
        {modalText.description}
      </div>
      <div className="plan-cards space-y-4">
        <div className="scale-90">
          <Segmented
            options={[
              { label: "Monthly", value: "monthly" },
              {
                label: `Yearly ${potentialCoupons.length ? "" : `(Save ${DEFAULT_YEARLY_DISCOUNT * 100}%)`}`,
                value: "yearly",
              },
            ]}
            size="large"
            value={interval}
            onChange={(value: SubscriptionInterval) => setInterval(value)}
          />
        </div>
        <div className="plan-cards-container">
          {planKeys.map((planKey) => (
            <PlanCard
              key={planKey}
              activeCoupons={intervalCoupons}
              {...{
                planKey,
                interval,
                successPath,
                loadingPlan,
                setLoadingPlan,
                closeModal,
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
