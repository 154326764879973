import SelectableImageCard from "components/Common/SelectableImageCard";
import { range, uniq } from "lodash";
import {
  CSSProperties,
  HTMLProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { LibraryImage } from "store/imageLibrary/imageLibraryConstants";

const SCROLL_THRESHOLD = 20; // Distance from edge in pixels to start scrolling
const DRAG_THRESHOLD = 5; // Minimum pixels to move before drag is recognized
const SCROLL_SPEED = 10; // Scroll speed in pixels per interval

type GalleryImage = Pick<LibraryImage, "id" | "file" | "isUploading">;

export type ImageGalleryProps = {
  images: GalleryImage[];
  selectedImages: GalleryImage[];
  setSelectedImages: (selected: GalleryImage[]) => void;
  className?: string;
  loading?: boolean;
  showMissingImages?: boolean;
  onError?: HTMLProps<HTMLImageElement>["onError"];
};

const ImageGallery = ({
  images,
  selectedImages,
  setSelectedImages,
  className,
  loading,
  onError,
}: ImageGalleryProps) => {
  const [startingSelectionIndex, setStartingSelectionIndex] = useState<
    number | null
  >(null);
  const [currentPoint, setCurrentPoint] = useState({ x: 0, y: 0 });
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [dragStarted, setDragStarted] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const selectedImageIds = useMemo(
    () => selectedImages.map(({ id }) => id),
    [selectedImages]
  );

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !containerRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const scrollLeft = containerRef.current.scrollLeft;
      const scrollTop = containerRef.current.scrollTop;
      const newCurrentPoint = {
        x: e.clientX - rect.left + scrollLeft,
        y: e.clientY - rect.top + scrollTop,
      };
      setCurrentPoint(newCurrentPoint);

      // Auto-scroll logic
      const { clientY } = e;
      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();

      if (
        clientY - containerRect.top < SCROLL_THRESHOLD &&
        container.scrollTop > 0
      ) {
        container.scrollTop = Math.max(container.scrollTop - SCROLL_SPEED, 0);
      } else if (
        containerRect.bottom - clientY < SCROLL_THRESHOLD &&
        container.scrollTop < container.scrollHeight - container.clientHeight
      ) {
        container.scrollTop = Math.min(
          container.scrollTop + SCROLL_SPEED,
          container.scrollHeight - container.clientHeight
        );
      }

      if (!dragStarted) {
        const distanceMoved = Math.sqrt(
          Math.pow(newCurrentPoint.x - startPoint.x, 2) +
            Math.pow(newCurrentPoint.y - startPoint.y, 2)
        );
        if (distanceMoved >= DRAG_THRESHOLD) {
          setDragStarted(true);
        } else {
          return;
        }
      }

      const selectBoxRect = {
        left: Math.min(startPoint.x, newCurrentPoint.x) - scrollLeft,
        top: Math.min(startPoint.y, newCurrentPoint.y) - scrollTop,
        right: Math.max(startPoint.x, newCurrentPoint.x) - scrollLeft,
        bottom: Math.max(startPoint.y, newCurrentPoint.y) - scrollTop,
      };

      // Check for overlapping items
      const selected = images.reduce((acc, image) => {
        const itemElement = document.getElementById(image.id);

        if (!itemElement) return acc;

        const itemRect = itemElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        if (
          itemRect.left < containerRect.left + selectBoxRect.right &&
          itemRect.right > containerRect.left + selectBoxRect.left &&
          itemRect.top < containerRect.top + selectBoxRect.bottom &&
          itemRect.bottom > containerRect.top + selectBoxRect.top
        ) {
          acc.push(image);
        }

        return acc;
      }, [] as GalleryImage[]);

      setSelectedImages(selected);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setDragStarted(false);
      setCurrentPoint({ x: 0, y: 0 });
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startPoint, images, setSelectedImages, dragStarted]);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!containerRef.current) {
      return;
    }

    const rect = containerRef.current.getBoundingClientRect();
    const scrollLeft = containerRef.current.scrollLeft;
    const scrollTop = containerRef.current.scrollTop;
    setIsDragging(true);
    setStartPoint({
      x: e.clientX - rect.left + scrollLeft,
      y: e.clientY - rect.top + scrollTop,
    });
    setCurrentPoint({
      x: e.clientX - rect.left + scrollLeft,
      y: e.clientY - rect.top + scrollTop,
    });
  };

  const selectBoxStyle: CSSProperties = useMemo(
    () =>
      isDragging && dragStarted
        ? {
            display: "block",
            height: Math.abs(startPoint.y - currentPoint.y),
            width: Math.abs(startPoint.x - currentPoint.x),
            left: Math.min(startPoint.x, currentPoint.x),
            top: Math.min(startPoint.y, currentPoint.y),
          }
        : { display: "none" },
    [isDragging, dragStarted, startPoint, currentPoint]
  );

  const handleSelect = (image: GalleryImage) =>
    selectedImageIds.includes(image.id)
      ? setSelectedImages(selectedImages.filter((item) => item.id !== image.id))
      : setSelectedImages(selectedImages.concat(image));

  const handleMultiSelect = (prevIndex: number) => {
    if (startingSelectionIndex === null) {
      return;
    }
    const [startingIndex, endIndex] =
      startingSelectionIndex < prevIndex
        ? [startingSelectionIndex, prevIndex]
        : [prevIndex, startingSelectionIndex];
    const newImages = images.slice(startingIndex, endIndex + 1);

    setSelectedImages(uniq(selectedImages.concat(newImages)));
  };

  const itemClickHandler = (
    event: React.MouseEvent,
    image: GalleryImage,
    index: number
  ) => {
    if (event.shiftKey) {
      handleMultiSelect(index);
    } else {
      handleSelect(image);
      setStartingSelectionIndex(index);
    }
  };

  return (
    <div
      className={`px-3 py-4 grid grid-cols-[repeat(auto-fill,_minmax(90px,_1fr))] gap-1 sm:gap-2 outline-none relative select-none ${className}`}
      onMouseDown={handleMouseDown}
      ref={containerRef}
    >
      {images.map((image, index) => (
        <SelectableImageCard
          key={image.id}
          id={image.id}
          src={image.file.url}
          isUploading={image.isUploading}
          isSelected={selectedImageIds.includes(image.id)}
          clickHandler={(event) => itemClickHandler(event, image, index)}
          onError={onError}
        />
      ))}
      {loading &&
        range(0, 14).map((item) => (
          <Skeleton className="aspect-square" borderRadius={8} key={item} />
        ))}
      <div
        className="absolute border-2 border-antd-colorText pointer-events-none z-10 bg-antd-colorBgMask"
        style={selectBoxStyle}
      />
    </div>
  );
};

export default ImageGallery;
