import { ButtonProps } from "antd";
import { Subscription } from "api/config/chalice-api";
import { CouponConfig } from "fixtures/coupons";
import Cookies from "js-cookie";
import { REFERRAL_PARAM } from "pages/ReferralReroute";

type BannerType = "default" | "coupon";

export type BannerConfig = Pick<
  CouponConfig,
  "bannerTitle" | "bannerSubtitle" | "sidebarTitle" | "openOnInterval"
> & {
  bannerType: "default" | "coupon";
};

export const BANNER_BUTTON_CONFIGS: Record<BannerType, ButtonProps> = {
  default: {
    className: "underline text-antd-colorWhite",
    children: "View Plans",
    type: "link",
  },
  coupon: {
    children: "Claim",
    type: "default",
  },
};

export const SIDEBAR_BUTTON_CONFIGS: Record<BannerType, ButtonProps> = {
  default: { className: "bg-antd-colorInfo" },
  coupon: {},
};

// TODO: Find way to merge referral discount into coupons,
// will need custom settings for how it reduces price
export const REFERRAL_BANNER: BannerConfig = {
  bannerTitle: "🎉 Claim Referral Discount",
  bannerSubtitle: "Get your first month free",
  bannerType: "coupon",
};

export const DEFAULT_BANNER: BannerConfig = {
  bannerTitle: "Upgrade Account",
  bannerType: "default",
};

export const getActiveUpgradeInfo = (currentPlan: Subscription["plan"]) => {
  if (currentPlan !== "free") {
    return null;
  } else if (Cookies.get(REFERRAL_PARAM)) {
    return REFERRAL_BANNER;
  } else {
    return DEFAULT_BANNER;
  }
};
