import { Alert, Button } from "antd";
import { removeAuthToken } from "api/config/axiosConfig";
import useAppContext from "config/AppContext/useAppContext";
import env, { MARKY_REALM_ID } from "config/envVars";
import dayjs from "dayjs";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { getFullName } from "store/user/userActions";
import { selectUserInfo } from "store/user/userSlice";

const PreviewModeBanner = () => {
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectUserInfo);
  const {
    previewModeState: [, , removePreviewMode],
  } = useAppContext();

  const isPreviewingClient = userInfo.realm_id !== MARKY_REALM_ID;

  const resetPreviewMode = () => {
    removePreviewMode();
    localStorage.clear();
    removeAuthToken();
  };

  const getSessionExpirationTime = () => {
    if (!localStorage.token) return null;

    try {
      const payload = JSON.parse(atob(localStorage.token.split(".")[1]));
      const exp = payload.exp;

      const expirationTime = dayjs.unix(exp);
      const now = dayjs.utc();
      const remainingSeconds = Math.round(expirationTime.diff(now) / 1000);

      if (remainingSeconds <= 0) {
        resetPreviewMode();
        navigate({ pathname: "/preview", search: "expired=true" });

        return "Session has expired";
      }

      let remainingTime = "";

      if (remainingSeconds >= 3600) {
        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        remainingTime = `${pluralize("hour", hours, true)} and ${pluralize("minute", minutes, true)}`;
      } else if (remainingSeconds >= 60) {
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;
        remainingTime = `${pluralize("minute", minutes, true)} and ${pluralize("second", seconds, true)}`;
      } else {
        remainingTime = `${pluralize("second", remainingSeconds, true)}`;
      }

      return `Session expires in ${remainingTime}`;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return null;
    }
  };

  const [tokenRemainingTime, setTokenRemainingTime] = useState<string | null>(
    getSessionExpirationTime()
  );

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        const updatedRemainingTime = getSessionExpirationTime();
        setTokenRemainingTime(updatedRemainingTime);
      },
      tokenRemainingTime?.includes("second") ? 1000 : 60000
    );

    return () => clearInterval(intervalId);
  }, [tokenRemainingTime]);

  return (
    <Alert
      showIcon
      type="info"
      action={
        <Button
          type="link"
          icon={<MdArrowBack />}
          onClick={() => {
            resetPreviewMode();
            window.location.href = `${env.FE_BASE_URL}/${isPreviewingClient ? "clients" : "white-label"}`;
          }}
        >
          Return to agency
        </Button>
      }
      description={
        <div className="text-antd-colorTextSecondary">
          Viewing permissions as {!isPreviewingClient ? "a " : " "}
          <i>
            {!isPreviewingClient ? "reviewer" : getFullName(userInfo)}
          </i>. {tokenRemainingTime}.
        </div>
      }
      message="Preview Mode"
      className="[grid-area:banner] py-2 items-center [&_.ant-alert-content]:flex [&_.ant-alert-content]:gap-4 [&_.ant-alert-content]:items-center [&_.ant-alert-message]:!mb-0"
      banner
    />
  );
};

export default PreviewModeBanner;
