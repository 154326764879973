import { useMemo } from "react";

import cx from "classnames";
import { range } from "lodash";
import Skeleton from "react-loading-skeleton";

import { HIDDEN_SIDEBAR_ROUTES } from "fixtures/constants";

const Loader = () => {
  return <div className="loader" />;
};

// TODO: Combine memos
export const PageLoader = ({
  large = false,
  width: customWidth = 0,
  text = "",
}) => {
  const pathname = window.location.pathname;
  const isSidebarVisible = useMemo(
    () => !HIDDEN_SIDEBAR_ROUTES.includes(pathname),
    [pathname]
  );

  const width = useMemo(() => {
    if (customWidth) {
      return customWidth;
    }
    return isSidebarVisible && !large
      ? window.innerWidth - 250
      : window.innerWidth;
  }, [isSidebarVisible]);

  const height = useMemo(() => {
    return isSidebarVisible && !large
      ? window.innerHeight - 65
      : window.innerHeight;
  }, [isSidebarVisible]);

  return (
    <div
      className={cx("page-loader bg-antd-colorBgLayout", {
        "page-loader--large": large,
      })}
      style={{
        height,
        width,
      }}
    >
      <div className="loader" />
      {text && <p className="loader__text">{text}</p>}
    </div>
  );
};

export const PostSkeleton = ({
  numberOfButtons = 3,
  showButtons = true,
  className = "",
}) => {
  return (
    <div className={`post-skeleton-container ${className}`}>
      <div className="post-skeleton">
        <Skeleton
          className="post-skeleton__image"
          height={window.innerWidth > 600 ? 399 : 300}
        />
        <Skeleton className="post-skeleton__caption" />
      </div>

      {showButtons && (
        <div className="buttons">
          {range(numberOfButtons).map((item) => (
            <Skeleton width={32} height={32} className="button" key={item} />
          ))}
          <Skeleton width={150} height={32} className="button" />
        </div>
      )}
    </div>
  );
};

export default Loader;
