import env from "config/envVars";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { IntegrationType } from "store/user/userConstants";
import { decodeObject, encodeObject, QueryParamConfig } from "use-query-params";

const {
  VITE_TWITTER_APP1_CLIENT_ID,
  VITE_TWITTER_APP2_CLIENT_ID,
  VITE_TWITTER_APP3_CLIENT_ID,
  VITE_TWITTER_APP4_CLIENT_ID,
  VITE_GOOGLE_BUSINESS_CLIENT_ID,
  VITE_PINTEREST_CLIENT_ID,
  VITE_LINKEDIN_CLIENT_ID,
  VITE_TIKTOK_CLIENT_ID,
  VITE_FB_CLIENT_ID,
} = import.meta.env;

export const TWITTER_APPS = ["app_2", "app_4"];

export const GOOGLE_BUSINESS_AUTH_SCOPES =
  "https://www.googleapis.com/auth/business.manage";
export const INSTAGRAM_AUTH_SCOPES =
  "instagram_basic,instagram_content_publish,pages_show_list,business_management,instagram_manage_insights";
export const PINTEREST_AUTH_SCOPES =
  "ads:read,boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read,catalogs:read,catalogs:write";
export const FACEBOOK_AUTH_SCOPES =
  "pages_manage_posts,pages_read_engagement,business_management,read_insights";
export const LINKEDIN_AUTH_SCOPES =
  "r_liteprofile r_emailaddress w_member_social r_organization_admin rw_organization_admin w_organization_social rw_ads w_member_social r_organization_social";
export const TWITTER_AUTH_SCOPES =
  "tweet.write tweet.read users.read offline.access";
export const TIKTOK_AUTH_SCOPES =
  "user.info.basic,user.info.profile,user.info.stats,video.publish,video.upload,video.list";

export const GOOGLE_BUSINESS_BASE_AUTH_URL =
  "https://accounts.google.com/o/oauth2/v2/auth";
export const PINTEREST_BASE_AUTH_URL = "https://www.pinterest.com/oauth";
export const FACEBOOK_BASE_AUTH_URL =
  "https://www.facebook.com/v18.0/dialog/oauth";
export const LINKEDIN_BASE_AUTH_URL =
  "https://www.linkedin.com/oauth/v2/authorization";
export const TWITTER_BASE_AUTH_URL = "https://twitter.com/i/oauth2/authorize";
export const TIKTOK_BASE_AUTH_URL = "https://www.tiktok.com/v2/auth/authorize";

export const isCustomDomain = window.location.origin !== env.FE_BASE_URL;

type IntegrationAuthStateParams = {
  integrationType: IntegrationType;
  redirectTo?: string;
  appName?: string;
};

export const StateParam: QueryParamConfig<
  Parameters<typeof encodeObject>[0],
  IntegrationAuthStateParams
> = {
  encode: (obj) => encodeObject(obj, "-", "|"),
  decode: (str) => decodeObject(str, "-", "|") as IntegrationAuthStateParams,
};

const BASE_URLS: Record<IntegrationType, string> = {
  twitter: TWITTER_BASE_AUTH_URL,
  facebook: FACEBOOK_BASE_AUTH_URL,
  instagram: FACEBOOK_BASE_AUTH_URL,
  instagramStory: FACEBOOK_BASE_AUTH_URL,
  linkedIn: LINKEDIN_BASE_AUTH_URL,
  linkedInProfile: LINKEDIN_BASE_AUTH_URL,
  tiktok: TIKTOK_BASE_AUTH_URL,
  pinterest: PINTEREST_BASE_AUTH_URL,
  googleBusiness: GOOGLE_BUSINESS_BASE_AUTH_URL,
};

const TWITTER_CLIENTS: Record<string, string> = {
  app_1: VITE_TWITTER_APP1_CLIENT_ID,
  app_2: VITE_TWITTER_APP2_CLIENT_ID,
  app_3: VITE_TWITTER_APP3_CLIENT_ID,
  app_4: VITE_TWITTER_APP4_CLIENT_ID,
};

const BASE_PARAMS = (
  appName?: string
): Record<IntegrationType, Record<string, string | undefined>> => ({
  twitter: {
    scope: TWITTER_AUTH_SCOPES,
    client_id: appName ? TWITTER_CLIENTS[appName] : undefined,
    code_challenge: "challenge",
    code_challenge_method: "plain",
  },
  facebook: {
    client_id: VITE_FB_CLIENT_ID,
    scope: FACEBOOK_AUTH_SCOPES,
  },
  instagram: {
    client_id: VITE_FB_CLIENT_ID,
    scope: INSTAGRAM_AUTH_SCOPES,
  },
  instagramStory: {
    client_id: VITE_FB_CLIENT_ID,
    scope: INSTAGRAM_AUTH_SCOPES,
  },
  linkedIn: {
    client_id: VITE_LINKEDIN_CLIENT_ID,
    scope: LINKEDIN_AUTH_SCOPES,
  },
  linkedInProfile: {
    client_id: VITE_LINKEDIN_CLIENT_ID,
    scope: LINKEDIN_AUTH_SCOPES,
  },
  tiktok: {
    client_key: VITE_TIKTOK_CLIENT_ID,
    scope: TIKTOK_AUTH_SCOPES,
  },
  pinterest: {
    client_id: VITE_PINTEREST_CLIENT_ID,
    scope: PINTEREST_AUTH_SCOPES,
  },
  googleBusiness: {
    scope: GOOGLE_BUSINESS_AUTH_SCOPES,
    access_type: "offline",
    include_granted_scopes: "true",

    client_id: VITE_GOOGLE_BUSINESS_CLIENT_ID,
    prompt: "consent",
  },
});

export const buildAuthURL = ({
  pathname = INTEGRATIONS_ROUTE,
  appName = undefined,
  socialType,
}: {
  pathname?: string;
  appName?: string;
  socialType: IntegrationType;
}) => {
  const baseURL = BASE_URLS[socialType];
  const defaultParams = BASE_PARAMS(appName)[socialType];
  const shouldUseProxyRoute = isCustomDomain || pathname !== INTEGRATIONS_ROUTE;

  const redirectUri =
    env.FE_BASE_URL + (shouldUseProxyRoute ? "/proxy" : pathname);

  const state = StateParam.encode({
    integrationType: socialType,
    appName,
    ...(shouldUseProxyRoute && {
      redirectTo: window.location.origin + pathname,
    }),
  });

  const mergedParams = {
    ...defaultParams,
    state,
    redirect_uri: redirectUri,
    response_type: "code",
  };
  const url = new URL(baseURL);

  Object.entries(mergedParams).forEach(([key, value]) =>
    url.searchParams.append(key, value as string)
  );

  return url.toString();
};
