import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import Icon from "@ant-design/icons/lib/components/Icon";

const MagicPencil = (props: AntdIconProps) => (
  <Icon {...props} viewBox="0 0 14 14">
    <path
      d="M12.0007 4.50098L12.6274 3.12764L14.0007 2.50098L12.6274 1.87431L12.0007 0.500977L11.374 1.87431L10.0007 2.50098L11.374 3.12764L12.0007 4.50098Z"
      fill="currentColor"
      fillOpacity="0.88"
    />
    <path
      d="M10.474 5.91431L8.58736 4.02764C8.45402 3.90098 8.28736 3.83431 8.11402 3.83431C7.94069 3.83431 7.77402 3.90098 7.64069 4.02764L0.194023 11.4743C-0.0659766 11.7343 -0.0659766 12.1543 0.194023 12.4143L2.08069 14.301C2.21402 14.4343 2.38069 14.501 2.55402 14.501C2.72736 14.501 2.89402 14.4343 3.02736 14.3076L10.474 6.86098C10.734 6.60098 10.734 6.17431 10.474 5.91431ZM8.11402 5.44764L9.05402 6.38764L8.27402 7.16764L7.33402 6.22764L8.11402 5.44764ZM2.55402 12.8943L1.61402 11.9543L6.39402 7.16764L7.33402 8.10764L2.55402 12.8943Z"
      fill="currentColor"
      fillOpacity="0.88"
    />
  </Icon>
);

export default MagicPencil;
