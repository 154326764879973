import { ImportOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import SelectAllButton from "components/Common/SelectAllButton";
import FileUploadButton from "designSystem/FileUploadButton";
import pluralize from "pluralize";
import { useMemo } from "react";
import { ImageGalleryProps } from "../ImageGallery";
import useImageLibrary from "../useImageLibrary";

type StockModalFooterProps = {
  handleCancel: () => void;
  closeModal: () => void;
  setIsImportFromWebsiteModalOpen: (open: boolean) => void;
  isGlobal?: boolean;
} & Pick<ImageGalleryProps, "images" | "setSelectedImages" | "selectedImages">;

const StockModalFooter = ({
  images,
  selectedImages,
  setSelectedImages,
  handleCancel,
  closeModal,
  setIsImportFromWebsiteModalOpen,
  isGlobal,
}: StockModalFooterProps) => {
  const buttonText = useMemo(
    () =>
      selectedImages.length
        ? `Add ${pluralize("Image", selectedImages.length, true)}`
        : "Add",
    [selectedImages]
  );

  const { handleImageUpload, handleAddStock } = useImageLibrary();

  const addImagesToLibrary = async () => {
    closeModal();
    const filesAdded = await handleAddStock(selectedImages);

    if (filesAdded.length) {
      message.success(
        `${pluralize("Image", filesAdded.length, true)} added to library successfully.`
      );
    }
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div>
        {images.length > 1 && (
          <SelectAllButton
            allItems={images}
            setSelectedItems={setSelectedImages}
            selectedItems={selectedImages}
          />
        )}
      </div>
      <div className="ml-auto flex flex-wrap gap-2 justify-end">
        {isGlobal ? (
          <>
            <FileUploadButton
              id="image-folder-upload"
              onChange={handleImageUpload}
            />
            <Button
              onClick={() => setIsImportFromWebsiteModalOpen(true)}
              icon={<ImportOutlined />}
            >
              Pull From Website
            </Button>
          </>
        ) : (
          <Button onClick={handleCancel}>Cancel</Button>
        )}
        <Button
          type="primary"
          disabled={!selectedImages.length}
          onClick={addImagesToLibrary}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default StockModalFooter;
