import { RealmDetails } from "config/AppContext/appContextUtil";
import { CRISP_CHAT_IDS } from "config/envVars";
import { Crisp } from "crisp-sdk-web";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";

const CONVERSATION_RESOLVED_TEXT = "Have a great day!";

type CrispMessage = {
  user: { nickname: string; user_id: string };
  content: string;
};

const useCrisp = (realm: RealmDetails) => {
  const user = useAppSelector(selectUserInfo);

  const checkConvoResolved = (message: CrispMessage) =>
    message.content.endsWith(CONVERSATION_RESOLVED_TEXT) &&
    setTimeout(() => {
      Crisp.chat.hide();

      trackEvent("refiner_launch_survey", {
        refinerArgs: {
          surveyId: "3c364f60-356b-11ef-ad9e-15b941fd9308",
          addAttributes: {
            userId: user.id ?? "Unknown",
            userEmail: user.email ?? "N/A",
            operatorName: message.user.nickname,
            operatorId: message.user.user_id,
          },
          triggerSurvey: true,
        },
      });
    }, 1000);

  const initializeCrisp = () => {
    Crisp.message.onMessageReceived(checkConvoResolved);
    Crisp.chat.hide();
  };

  const crispId = useMemo(
    () => (realm.id ? CRISP_CHAT_IDS[realm.id] : null),
    [realm.id]
  );

  useEffect(() => {
    if (crispId) {
      try {
        Crisp.configure(crispId);
        initializeCrisp();
      } catch (error) {
        console.error("Unable to initialize Crisp", error);
      }
    }
  }, [crispId]);

  useEffect(() => {
    if (crispId) {
      Crisp.session.setData({ realm_id: realm.id, agency_name: realm.name });
      Crisp.chat.show();
    }
  }, [crispId, realm.id, realm.name]);
};

export default useCrisp;
