import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip, TooltipProps } from "antd";

const InfoTooltip = (props: TooltipProps) => (
  <Tooltip {...props}>
    <InfoCircleOutlined className="ml-2 text-antd-colorIcon cursor-pointer" />
  </Tooltip>
);

export default InfoTooltip;
