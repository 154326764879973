export type SubscriptionInterval = "monthly" | "yearly";

export type PlanKey = "professional" | "personal" | "agency";

export type PlanConfig = {
  name: string;
  monthlyPrice: number;
  subtitle: string;
  features: string[];
  isMostPopular?: boolean;
  isPrimaryOption?: boolean;
};

export const DEFAULT_YEARLY_DISCOUNT = 0.2;

export const PLANS_DICT: Record<PlanKey, PlanConfig> = {
  professional: {
    name: "Professional Plan",
    monthlyPrice: 46,
    subtitle:
      "Get access to all of Marky's features and build your social media presence.",
    features: [
      "2 businesses (+more for $15 each)",
      "Unlimited daily posting times",
      "Unlimited post generations",
      "Unlimited connected socials",
      "No watermark",
      "24/7 chat support",
    ],
    isMostPopular: true,
    isPrimaryOption: true,
  },

  personal: {
    name: "Personal Plan",
    monthlyPrice: 23,
    subtitle:
      "Get access to all of Marky's features and build your social media presence.",
    features: [
      "1 business",
      "1 daily posting time",
      "300 post generations (per month)",
      "4 connected socials",
      "No watermark",
      "24/7 chat support",
    ],
  },

  agency: {
    name: "Agency Plan",
    monthlyPrice: 197,
    subtitle: "Manage up to 10 accounts at a time.",
    features: [
      "10 businesses (+more for $15 each)",
      "Generate infinite content",
      "Client dashboard",
      "White labeled client portal",
      "Premium support",
      "24/7 chat support",
    ],
    isPrimaryOption: true,
  },
};

export const MODAL_TEXT: Record<
  "agency" | "nonAgency",
  { planKeys: PlanKey[] } & Record<
    "title" | "description" | "footerStart" | "footerLink",
    string
  >
> = {
  agency: {
    planKeys: ["agency"],
    title: "Supercharge your agency",
    description:
      "Marky equips you with the tools to scale your business and deliver results faster, all while offering a fully white-labeled solution that will impress your clients. Manage multiple client accounts, generate content with ease, and save valuable time.",
    footerStart: "Just you? Check out our",
    footerLink: "business",
  },
  nonAgency: {
    planKeys: ["personal", "professional"],
    title: "Ready to level up your social media?",
    description:
      "Get more done with a Marky subscription. Manage your posts, create awesome content, and save time—all with just a few clicks. Pick the plan that works best for you and start seeing more results, faster!",
    footerStart:
      "Manage multiple clients' socials? Check out our white-labeled",
    footerLink: "Agency",
  },
};
