import FrillWidgetContainer from "components/FrillWidgetContainer";
import useAppContext from "config/AppContext/useAppContext";
import { AGENCY_ROUTES, BUSINESS_ROUTES } from "fixtures/constants";
import { JWT_TOKEN } from "fixtures/localStorageKeys";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useAppSelector } from "store";
import { setCurrentBusiness } from "store/user/userSlice";
import { invalidClientAccountMessage } from "utils/generalUtils";
import PreviewModeBanner from "./AppBanner/PreviewModeBanner";
import useGlobalModal from "./GlobalModals/GlobalModalContext/useGlobalModal";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [token, , clearToken] = useLocalStorage(JWT_TOKEN, "", { raw: true });
  const {
    realm,
    previewModeState: [isPreviewMode],
  } = useAppContext();

  const { pathname } = useLocation();
  const {
    isAgencyOwner,
    currentBusiness: { id: currentBusinessId },
    businesses,
  } = useAppSelector((state) => state.user);

  const { globalModal } = useGlobalModal();

  useEffect(() => {
    if (localStorage.token) {
      return;
    }

    if (pathname.includes("/frill-sso")) {
      const redirect = searchParams.get("redirect");
      redirect && localStorage.setItem("redirect", redirect);
      localStorage.setItem("isFrillSSO", "true");
      navigate("/login");
      return;
    }

    navigate("/");
  }, []);

  useEffect(() => {
    const shouldNotUpdateCurrentBusiness =
      pathname === "/manage-account" ||
      globalModal.modalName === "create-business";

    if (shouldNotUpdateCurrentBusiness) {
      return;
    }

    if (!AGENCY_ROUTES.includes(pathname)) {
      if (!currentBusinessId && localStorage.currentBusiness) {
        dispatch(setCurrentBusiness(localStorage.currentBusiness));
      }
      return;
    }

    if (!isAgencyOwner) {
      navigate("/");
    }

    dispatch(setCurrentBusiness(null));
  }, [isAgencyOwner, pathname, globalModal.modalName]);

  useEffect(() => {
    if (!businesses.length) {
      if (!realm.isMarky) {
        clearToken();
        invalidClientAccountMessage(realm);
      } else if (BUSINESS_ROUTES.includes(pathname)) {
        navigate("/business-metadata");
      }
    }
  }, [realm, pathname, businesses]);

  useEffect(() => {
    containerRef.current?.scroll(0, 0);
  }, [pathname]);

  return token ? (
    <>
      {isPreviewMode && <PreviewModeBanner />}
      <FrillWidgetContainer />
      <div
        className="[grid-area:content] h-full flex flex-col overflow-auto bg-antd-colorBgLayout"
        ref={containerRef}
      >
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
