import { Dropdown, DropdownProps } from "antd";
import Checkbox, { CheckboxGroupProps } from "antd/es/checkbox";

const CheckboxDropdown = <T = unknown,>({
  checkboxGroupProps,
  ...dropdownProps
}: DropdownProps & { checkboxGroupProps: CheckboxGroupProps<T> }) => (
  <Dropdown
    dropdownRender={() => (
      <Checkbox.Group
        className="flex flex-col gap-2 bg-antd-colorBgElevated !p-3 ant-dropdown-menu"
        {...checkboxGroupProps}
      />
    )}
    {...dropdownProps}
  />
);

export default CheckboxDropdown;
