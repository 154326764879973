// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: convert userSlice to TS
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { CURRENT_BUSINESS_ID } from "fixtures/localStorageKeys";
import useAddNewBusiness from "hooks/useAddNewBusiness";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { sortBy } from "lodash";
import { PostStatusPill } from "pages/AgencyDashboard/components/PostStatuses";
import { useCallback, useEffect } from "react";
import { LuChevronsUpDown } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { resetImageLibrary } from "store/imageLibrary/imageLibrarySlice";
import { resetPostData, setPostCountsForBusiness } from "store/posts/postSlice";
import { setCurrentBusiness } from "store/user/userSlice";
import AvatarInitials from "./AvatarInitials";

const BusinessSelector = () => {
  const navigate = useNavigate();
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const { realm } = useAppContext();
  const { userPermissions } = useUserPermissions();

  const { handleNewBusinessCreation } = useAddNewBusiness();

  const dispatch = useDispatch();
  const { businesses, currentBusiness, isAgencyMember } = useAppSelector(
    (state) => state.user
  );

  const handleBusinessChange = useCallback(
    (businessId) => {
      localStorage.setItem(CURRENT_BUSINESS_ID, businessId);
      dispatch(setCurrentBusiness(businessId));
      navigate(defaultBusinessRoute);
    },
    [defaultBusinessRoute]
  );

  useEffect(() => {
    dispatch(
      setPostCountsForBusiness({
        business: currentBusiness,
        limitByPermissions: realm.isMarky ? undefined : userPermissions,
      })
    );
    return () => {
      dispatch(resetPostData());
      dispatch(resetImageLibrary());
    };
  }, [currentBusiness]);

  if (!realm.isMarky && businesses.length === 1) {
    const business = businesses[0];
    return (
      <div className="flex items-center py-1.5 px-3 rounded-lg bg-antd-colorBgContainer border-[1px] border-antd-colorBorder m-2.5">
        <AvatarInitials business={business} size={40} />
        <span
          className="overflow-hidden text-ellipsis font-medium text-sm"
          style={{ paddingLeft: "10px" }}
        >
          {business.title}
        </span>
      </div>
    );
  }

  return (
    <Select
      value={currentBusiness.id || undefined}
      placeholder="Select Business"
      suffixIcon={
        <LuChevronsUpDown className="!text-antd-colorTextSecondary" size={16} />
      }
      className="h-[50px] !w-[unset] mx-1 mb-3 max-sm:mt-2 [&_.ant-select-arrow]:!h-4 z-antd-popup"
      onChange={handleBusinessChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          {realm.isMarky && (
            <Button
              onClick={() => handleNewBusinessCreation()}
              className="w-full"
              icon={<PlusOutlined />}
            >
              Add New Business
            </Button>
          )}
        </>
      )}
    >
      {sortBy(businesses, (x) => x.title.toLowerCase()).map(
        (business, index) => (
          <Select.Option key={index} value={business.id}>
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center overflow-hidden">
                <AvatarInitials business={business} size={40} />
                <span className="font-medium overflow-ellipsis overflow-hidden whitespace-nowrap">
                  {business.title}
                </span>
              </div>
              {isAgencyMember && currentBusiness.id !== business.id && (
                <PostStatusPill
                  statusKey="CHANGES_REQUESTED"
                  count={
                    business.posts_data.review_status_counts.CHANGES_REQUESTED
                      ?.LIKED
                  }
                />
              )}
            </div>
          </Select.Option>
        )
      )}
    </Select>
  );
};

export default BusinessSelector;
