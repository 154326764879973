export const ANALYTICS_ITEM_ID = "analytics-menu-item";
export const POST_MENU_ITEM_ID = "posts-menu-item";
export const TOPICS_MENU_ITEM_ID = "topics-menu-item";
export const BUSINESS_PROFILE_MENU_ITEM_ID = "business-profile-menu-item";
export const THEMES_MENU_ITEM_ID = "themes-menu-item";
export const BRANDING_MENU_ITEM_ID = "branding-menu-item";
export const SOCIALS_MENU_ITEM_ID = "socials-menu-item";
export const TEAMS_MENU_ITEM_ID = "teams-menu-item";
export const CLIENTS_MENU_ITEM_ID = "clients-menu-item";
export const EDIT_POST_BUTTON_ID = "edit-post-btn";
export const SCHEDULE_TAB_ID = "schedule-posts-tab";
export const CALENDAR_PAGE_NAV_ID = "calendar-page-nav";
