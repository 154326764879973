import { Alert, Button, Tooltip } from "antd";
import { PublishStatus } from "api/config/chalice-api";
import { fetchPublishStatus } from "api/postsApi";
import cx from "classnames";
import dayjs from "dayjs";
import { genericError, LONG_DATE_TIME_FORMAT } from "fixtures/globalConstants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LuRefreshCw } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Post } from "store/posts/postConstants";
import { syncPostData } from "store/posts/postSlice";
import groupPublishLinksByState, {
  PublishResults,
} from "utils/groupPublishLinksByState";
import PublishErrorModal from "./PublishErrorModal";

type PublishedLinksProp = { results: PublishResults };

// TODO: REFACTOR
const PublishDetailsAlert = ({ post }: { post: Post<"PUBLISHED"> }) => {
  const dispatch = useDispatch();

  const [refreshPostTriesCount, setRefreshPostTriesCount] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const links = useMemo(
    () => groupPublishLinksByState(post.publish_results),
    [post]
  );

  const refreshPost = useCallback(
    async (isManual = false) => {
      setIsRefreshing(true);
      try {
        if (
          Object.values(post.publish_results).every((value) =>
            ["success", "failed"].includes(value.state)
          )
        ) {
          return;
        }

        const updatedStatus = (await fetchPublishStatus(post.id)) as Record<
          string,
          PublishStatus
        >;
        dispatch(
          syncPostData({ post: { ...post, publish_results: updatedStatus } })
        );

        if (
          !isManual &&
          Object.values(updatedStatus).some(
            (value) => !["success", "failed"].includes(value.state)
          )
        ) {
          const delay = Math.pow(1.5, refreshPostTriesCount) * 1000;

          setTimeout(() => {
            setRefreshPostTriesCount(refreshPostTriesCount + 1);
          }, delay);
        }
      } catch (error) {
        genericError(error);
      }
      setIsRefreshing(false);
    },
    [post, refreshPostTriesCount]
  );

  useEffect(() => {
    refreshPost();
  }, [refreshPostTriesCount]);

  const pendingSocialsText = useMemo(() => {
    return (
      <div>
        Publishing to <PendingSocials results={links} /> is in progress.
        <Tooltip title="Refresh status">
          <Button
            icon={<LuRefreshCw className="relative top-0.5" />}
            onClick={() => refreshPost(true)}
            disabled={isRefreshing}
            className={cx("refresh-post-btn disabled:!text-antd-colorInfo", {
              "!pointer-events-none refresh-post-btn--loading": isRefreshing,
            })}
            type="link"
          />
        </Tooltip>
      </div>
    );
  }, [refreshPostTriesCount, links, post, isRefreshing]);

  return (
    <div>
      {links.successLinks.count > 0 && (
        <Alert
          type="success"
          description={
            <div>
              Published on{" "}
              {dayjs
                .utc(post.published_at)
                .local()
                .format(LONG_DATE_TIME_FORMAT)}
              {<PublishedLinks results={links} />}
            </div>
          }
        />
      )}
      {links.failedLinks.count > 0 && (
        <Alert
          type="error"
          message={
            <div>
              Failed to publish to <FailedLinks results={links} post={post} />
            </div>
          }
        />
      )}
      {links.pendingLinks.count > 0 && (
        <Alert type="info" message={pendingSocialsText} />
      )}
    </div>
  );
};

const FailedLinks = ({
  results: { failedLinks },
  post,
}: {
  post: Post<"PUBLISHED">;
} & PublishedLinksProp) => {
  const [publishError, setPublishError] = useState<{ name?: string }>({
    name: "",
  });

  if (!failedLinks.count) {
    return null;
  }

  return (
    <>
      {Object.entries(failedLinks.data).map(([name, error], i) => {
        const isLast = i === Object.entries(failedLinks).length - 1;
        const separator =
          Object.entries(failedLinks).length > 1 && i !== 0
            ? isLast
              ? " and "
              : ", "
            : "";

        return (
          <span key={name}>
            {i !== 0 && separator}
            {name}{" "}
            <span
              onClick={() => setPublishError(error)}
              className="cursor-pointer text-antd-colorInfo hover:underline"
            >
              (see why)
            </span>
          </span>
        );
      })}
      <PublishErrorModal
        close={() => setPublishError({ name: "" })}
        key={publishError.name}
        error={publishError}
        post={post}
      />
    </>
  );
};

const PublishedLinks = ({ results: { successLinks } }: PublishedLinksProp) => {
  if (!successLinks.count) {
    return null;
  }

  return (
    <>
      {" to "}
      {Object.entries(successLinks.data).map(([name, link], i) => {
        const isLast = i === successLinks.count - 1;
        const separator =
          successLinks.count > 1 && i !== 0 ? (isLast ? " and " : ", ") : "";

        return (
          <span key={link}>
            {i !== 0 && separator}
            <Link
              className="text-antd-colorInfo hover:underline"
              target="_blank"
              to={link}
            >
              {name}
            </Link>
          </span>
        );
      })}
    </>
  );
};

const PendingSocials = ({ results: { pendingLinks } }: PublishedLinksProp) => {
  if (!pendingLinks.count) {
    return null;
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const hours = Math.floor(time / 3600);

    if (time <= 60) {
      return `${time} seconds`;
    } else if (time <= 3600) {
      return `${minutes} minutes`;
    } else {
      return `${hours} hours`;
    }
  };

  return (
    <>
      {Object.entries(pendingLinks.data).map(
        ([name, { created_at, timeout, state }], i) => {
          const isLast = i === Object.entries(pendingLinks).length - 1;
          const createdAt = new Date(created_at ?? 0).getTime();
          const separator =
            Object.entries(pendingLinks).length > 1 && i !== 0
              ? isLast
                ? " and "
                : ", "
              : "";

          return (
            <React.Fragment key={i}>
              {i !== 0 && separator}
              <Tooltip
                title={() => (
                  <span>
                    {`Publish pending for ${formatTime(
                      Math.ceil((Date.now() - createdAt) / 1000)
                    )}.`}{" "}
                    {state === "pending"
                      ? `It can take up to ${formatTime(timeout ?? 0)}.`
                      : ""}
                  </span>
                )}
              >
                <span className="pending-social">{name}</span>
              </Tooltip>
            </React.Fragment>
          );
        }
      )}
    </>
  );
};

export default PublishDetailsAlert;
