import { useCallback, useMemo } from "react";

import { lowerCase, sortBy, startCase } from "lodash";

import cx from "classnames";

import { DayOfWeek, DAYS_OPTION } from "./scheduleConstants";

type DaySelectorProps = {
  setSelectedDays: (days: DayOfWeek[]) => void;
  selectedDays: DayOfWeek[];
  isEditing: boolean;
};

const DaySelector = ({
  setSelectedDays,
  selectedDays,
  isEditing,
}: DaySelectorProps) => {
  const selectedDaysString = useMemo(() => {
    const days = DAYS_OPTION.map((day) => day.value);
    const sortedDays = sortBy(selectedDays, (day) => days.indexOf(day));
    return sortedDays.map((day) => startCase(lowerCase(day))).join(", ");
  }, [selectedDays]);

  const handleClick = useCallback(
    (day: DayOfWeek) => {
      if (selectedDays.includes(day)) {
        setSelectedDays(
          selectedDays.filter((selectedDay) => selectedDay !== day)
        );
      } else {
        setSelectedDays([...selectedDays, day]);
      }
    },
    [selectedDays]
  );

  if (!isEditing) {
    return (
      <div className="schedule-manager__main__block__value">
        {selectedDaysString}
      </div>
    );
  }

  return (
    <div className="day-selector">
      {DAYS_OPTION.map((day) => (
        <div
          key={day.label}
          className={cx("day-selector__item", {
            "day-selector__item--active": selectedDays.includes(day.value),
          })}
          onClick={() => handleClick(day.value)}
        >
          {day.label}
        </div>
      ))}
    </div>
  );
};

export default DaySelector;
