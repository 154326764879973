import { createContext, useContext } from "react";
import {
  DEFAULT_ANALYTICS_DASHBOARD,
  PostAnalyticsContextType,
} from "./postAnalyticsFixtures";

export const PostAnalyticsContext = createContext<PostAnalyticsContextType>(
  DEFAULT_ANALYTICS_DASHBOARD
);

const usePostAnalyticsContext = () => useContext(PostAnalyticsContext);
export default usePostAnalyticsContext;
