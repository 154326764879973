import { Empty, Tour } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { PageLoader } from "designSystem/Loader";
import { CALENDAR_PAGE_NAV_ID } from "fixtures/elementIDs";
import { MOBILE_SIDEBAR_BREAKPOINT } from "fixtures/globalConstants";
import { SHOW_SCHEDULED_POSTS_TOUR } from "fixtures/localStorageKeys";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { getPostsThunk } from "store/posts/postActions";
import { POST_SCREENS, PostScreen } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import PostAlerts from "./components/PostAlerts";
import PostRejectionFeedbackModal from "./components/PostRejectionFeedbackModal";
import PostsPage from "./PostsPage";
import { useGetAllowedScreens } from "./utils/useGetAllowedScreens";

const FETCH_NEXT_PAGE_THRESHOLD = 2000;

/**
 * Responsible for:
 * - Get post data for a specific status
 * - Prevent rendering the post list until the status URL param is accurate
 * - Choosing the correct component for rendering the post list
 */
const PostsPageWrapper = () => {
  const postCalendar = useTypedFeatureIsOn("post-calendar");

  const { realm } = useAppContext();
  const { userPermissions } = useUserPermissions();
  const {
    screenParam,
    canSeePosts,
    visibleScreenKeys,
    canAccessScreen,
    setToDefaultScreen,
  } = useGetAllowedScreens();

  const dispatch = useAppDispatch();
  const { currentBusiness } = useAppSelector((state) => state.user);
  const postData = useAppSelector(selectPostStore);

  const [openTour, setOpenTour] = useState(false);
  const [showScheduledPostsTour, , clearShowScheduledPostsTour] =
    useLocalStorage<boolean>(SHOW_SCHEDULED_POSTS_TOUR);
  const calendarPageNav = document.getElementById(CALENDAR_PAGE_NAV_ID);

  const postContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      postCalendar &&
      showScheduledPostsTour &&
      postData.scheduled.count &&
      window.innerWidth >= MOBILE_SIDEBAR_BREAKPOINT
    ) {
      const timeout = setTimeout(() => {
        setOpenTour(true);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [postData.scheduled.count]);

  useEffect(() => {
    (!screenParam || !visibleScreenKeys.includes(screenParam as PostScreen)) &&
      setToDefaultScreen();
  }, [screenParam, visibleScreenKeys]);

  useEffect(() => {
    if (currentBusiness.id && canAccessScreen("scheduled")) {
      // Fetching scheduled posts because position is needed for post next option
      dispatch(getPostsThunk({ postScreen: "scheduled" }));
    }
  }, [currentBusiness]);

  useEffect(() => {
    if (
      screenParam &&
      POST_SCREENS.includes(screenParam as PostScreen) &&
      !postData[screenParam as PostScreen]?.isLoading &&
      canAccessScreen(screenParam as PostScreen)
    ) {
      dispatch(
        getPostsThunk({
          postScreen: screenParam as PostScreen,
          limitByPermissions: realm.isMarky ? undefined : userPermissions,
        })
      );
    }
  }, [screenParam]);

  useLayoutEffect(() => {
    postContainerRef.current?.scrollTo(0, 0);
  }, [screenParam]);

  const getNextPage = () => {
    if (
      screenParam &&
      !postData[screenParam].isLoading &&
      postContainerRef.current
    ) {
      const { nextPageUrl } = postData[screenParam];

      if (nextPageUrl) {
        const { scrollHeight, scrollTop, clientHeight } =
          postContainerRef.current;
        const distanceToEnd = scrollHeight - scrollTop - clientHeight;

        distanceToEnd < FETCH_NEXT_PAGE_THRESHOLD &&
          dispatch(getPostsThunk({ postScreen: screenParam, nextPageUrl }));
      }
    }
  };

  const showTour = Boolean(
    postCalendar &&
      calendarPageNav?.clientHeight &&
      showScheduledPostsTour &&
      openTour
  );

  return canSeePosts && screenParam ? (
    <div
      className="min-h-0 flex-grow flex flex-col overflow-y-auto overflow-x-hidden pb-48"
      ref={postContainerRef}
      onScroll={getNextPage}
    >
      <PostAlerts />
      <PostsPage postScreen={screenParam} />
      <PostRejectionFeedbackModal />

      {showTour && (
        <Tour
          onClose={clearShowScheduledPostsTour}
          steps={[
            {
              title: "Checkout Calendar Page",
              description:
                "Great you've approved your first post! Go here to see when we will post it for you",
              target: calendarPageNav,
              className: "!left-1",
            },
          ]}
          placement="bottomLeft"
        />
      )}
    </div>
  ) : canSeePosts ? (
    <PageLoader />
  ) : (
    <Empty className="h-full flex flex-col justify-center" />
  );
};

export default PostsPageWrapper;
