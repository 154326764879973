import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, TimePicker, Tooltip } from "antd";
import { ValidGlobalModalData } from "components/GlobalModals/GlobalModalContext/globalModalConfig";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { FaCrown } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import {
  findOverlappingTimeIndices,
  isWithinOneHour,
} from "utils/scheduleUtils";

const TimePickers = ({
  isEditing,
  setTimes,
  times,
}: {
  setTimes: (times: Dayjs[]) => void;
  isEditing: boolean;
  times: Dayjs[];
}) => {
  const { openGlobalModal } = useGlobalModal();
  const { isAgencyMember } = useAppSelector((state) => state.user);
  const currentBusiness = useAppSelector(currentBusinessGetter);
  const subscription = currentBusiness?.owner?.subscription;

  const isPremium = useMemo(() => {
    if (!subscription) {
      return;
    }

    return (
      (subscription?.plan &&
        (subscription.plan.includes("professional") ||
          ["marky_tier3", "marky_tier2"].includes(subscription.plan))) ||
      isAgencyMember
    );
  }, [subscription]);

  const overlappingTimes = useMemo(
    () => findOverlappingTimeIndices(times),
    [times]
  );

  const handleChange = (value: Dayjs | null, index: number) => {
    const updatedTimes = [...times];
    if (value) {
      updatedTimes[index] = value;
      setTimes(updatedTimes);
    }
  };

  const handleAddTime = () => {
    if (isPremium) {
      let newTime = dayjs("00`00", "HH:mm");
      while (isWithinOneHour(newTime, times)) {
        newTime = newTime.add(1, "minute");
      }
      setTimes(times.concat(newTime));
    } else {
      const isAppSumo = subscription?.is_appsumo;

      openGlobalModal({
        modalName: isAppSumo ? "appsumo-plans" : "subscription",
        modalData: {
          modalType: isAppSumo ? "upgrade" : "upgradeToProfessional",
        },
      } as ValidGlobalModalData);
    }
  };

  if (!isEditing) {
    return (
      <div>
        {times.map((time, index) => (
          <div className="schedule-manager__main__block__value" key={index}>
            {time.format("hh:mm A")}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-3">
      <div className="flex flex-col gap-2 max-w-fit">
        {times.map((time, i) => (
          <div key={i}>
            <TimePicker
              value={time}
              format="hh:mm A"
              onChange={(value) => handleChange(value, i)}
              showNow={false}
              allowClear={false}
              use12Hours
              inputReadOnly={window.innerWidth < 1000}
              status={overlappingTimes.includes(i) ? "error" : undefined}
              needConfirm={false}
            />
            {times.length > 1 && (
              <MinusCircleOutlined
                style={{ marginLeft: 8, color: "#797979", fontSize: 14 }}
                onClick={() =>
                  setTimes(times.filter((_, index) => i !== index))
                }
              />
            )}
          </div>
        ))}
        {!!overlappingTimes.length && (
          <div className="flex items-center gap-1 text-antd-colorError text-sm">
            <MdErrorOutline />
            Please ensure that no two times overlap within an hour of each
            other.
          </div>
        )}
      </div>

      {((isPremium && times.length < 10) || !isPremium) && (
        <Tooltip title={isPremium ? "" : "Premium Feature"}>
          <Button
            onClick={handleAddTime}
            className="add-time-btn"
            icon={isPremium ? null : <FaCrown color="#B931FC" />}
          >
            Add another time
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default TimePickers;
