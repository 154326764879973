import { Alert, Button, Radio } from "antd";
import classNames from "classnames";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { useEffect, useMemo, useState } from "react";
import { MdOutlineSchedule } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store";
import { Post } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import { sortScheduledPosts } from "store/posts/postUtil";
import { integrationsTypeGetter } from "store/user/userSlice";
import PostCardList from "../components/PostCardList";
import OrderedScheduledPosts, {
  AutoScheduledPost,
} from "./OrderedScheduledPosts";
import ScheduledPostCard from "./ScheduledPostCard";

type ScheduleTab = "queue" | "custom";
const SCHEDULED_TAB_PARAM = "scheduled-type";

const ScheduledPostsList = () => {
  // TODO: Delete this component once postCalendar is turned on.
  // Leaving commented out code so that it comes up in search of feature flag.
  // const postCalendar = useTypedFeatureIsOn("post-calendar");

  const { hasPermission } = useUserPermissions();
  const [searchParams, setSearchParams] = useSearchParams({
    [SCHEDULED_TAB_PARAM]: "queue",
  });
  const { scheduled } = useAppSelector(selectPostStore);
  const connectedSocials = useAppSelector(integrationsTypeGetter);

  const [scheduledTab, setScheduledTab] = useState<ScheduleTab>(
    (searchParams.get(SCHEDULED_TAB_PARAM) ?? "queue") as ScheduleTab
  );

  useEffect(() => {
    searchParams.set(SCHEDULED_TAB_PARAM, scheduledTab);
    setSearchParams(searchParams);
  }, [scheduledTab]);

  const { tabOptions, scheduledPosts } = useMemo(() => {
    const { queuedPosts, customScheduledPosts } = scheduled.postList.reduce(
      (prev, post) => {
        if (post.queue_ordering_number !== null) {
          prev.queuedPosts.push(post as AutoScheduledPost);
        } else if (post.adhoc_publish_minute_timestamp) {
          prev.customScheduledPosts.push(post as Post<"SCHEDULED">);
        }
        return prev;
      },
      {
        queuedPosts: [] as AutoScheduledPost[],
        customScheduledPosts: [] as Post<"SCHEDULED">[],
      }
    );

    return {
      tabOptions: [
        { value: "queue", label: `Queue (${queuedPosts.length})` },
        {
          value: "custom",
          label: `Custom Time (${customScheduledPosts.length})`,
        },
      ],
      scheduledPosts: {
        queue: queuedPosts.sort(sortScheduledPosts),
        custom: customScheduledPosts.sort(sortScheduledPosts),
      },
    };
  }, [scheduled.postList]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center h-full w-full mb-auto px-2">
      {!connectedSocials.length &&
        scheduledPosts.queue.length > 0 &&
        scheduledTab === "queue" && (
          <Alert
            message="Publish dates may be inaccurate because no social accounts are currently connected."
            type="info"
            showIcon
          />
        )}
      <div
        className={classNames("flex flex-col items-center gap-2", {
          "mb-10": scheduledTab === "queue",
          "mb-16": scheduledTab === "custom",
        })}
      >
        <Radio.Group
          optionType="button"
          className="md:[&_label]:w-44 text-center"
          value={scheduledTab}
          options={tabOptions}
          onChange={({ target }) =>
            setScheduledTab(target.value as ScheduleTab)
          }
        />

        {scheduledTab === "queue" &&
          hasPermission("posting_schedule_write") && (
            <Link to={INTEGRATIONS_ROUTE}>
              <Button
                icon={<MdOutlineSchedule />}
                className="text-xs"
                type="link"
              >
                Manage Schedule
              </Button>
            </Link>
          )}
      </div>

      {scheduledTab === "queue" ? (
        <OrderedScheduledPosts postList={scheduledPosts.queue} />
      ) : (
        <PostCardList className="space-y-4" postList={scheduledPosts.custom}>
          {(post) => <ScheduledPostCard key={post.id} post={post} />}
        </PostCardList>
      )}
    </div>
  );
};

export default ScheduledPostsList;
