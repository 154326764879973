import { Input } from "antd";
import { genericError } from "fixtures/globalConstants";
import { useState } from "react";
import { useDebounce } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { updateRealmDetails } from "store/user/userActions";

const AgencyNameEditor = () => {
  const dispatch = useAppDispatch();

  const { ownedRealm } = useAppSelector((state) => state.user);

  const [agencyName, setAgencyName] = useState(ownedRealm?.name ?? "");

  const saveAgencyName = async (agencyName: string) => {
    if (!ownedRealm || agencyName === ownedRealm.name || !agencyName) {
      return;
    }

    try {
      await dispatch(
        updateRealmDetails({
          ...ownedRealm,
          name: agencyName,
        })
      );
    } catch (error) {
      genericError(error);
    }
  };

  useDebounce(() => saveAgencyName(agencyName), 700, [agencyName]);

  return (
    <div className="space-y-2">
      <label htmlFor="agency-name" className="text-sm font-semibold block">
        Agency Name
      </label>
      <Input
        onChange={(e) => setAgencyName(e.target.value)}
        status={!agencyName ? "error" : undefined}
        value={agencyName}
        className="w-56"
        id="agency-name"
      />
      {!agencyName && (
        <p className="text-antd-colorError text-sm">
          Agency name cannot be empty.
        </p>
      )}
    </div>
  );
};

export default AgencyNameEditor;
