import { Select } from "antd";
import classNames from "classnames";
import { INTEGRATION_ITEMS, INTEGRATION_ITEMS_DICT } from "fixtures/constants";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "store";
import {
  currentBusinessGetter,
  integrationsGetter,
} from "store/user/userSlice";
import {
  UNSUPPORTED_SOCIALS_FOR_ANALYTICS,
  UnsupportedSocialForAnalytics,
} from "../postAnalyticsFixtures";
import usePostAnalyticsContext from "../usePostAnalytics";

const SocialAccountFilter = () => {
  const integrations = useAppSelector(integrationsGetter);
  const currentBusiness = useAppSelector(currentBusinessGetter);

  const {
    integrationIdsState: [selectedIntegrationIds, setSelectedIntegrationIds],
  } = usePostAnalyticsContext();

  const accountOptions = useMemo(
    () =>
      integrations.reduce(
        (acc, integration) => {
          if (
            !UNSUPPORTED_SOCIALS_FOR_ANALYTICS.includes(
              integration.type as UnsupportedSocialForAnalytics
            )
          ) {
            acc.push({
              value: integration.id,
              className:
                "border-b border-antd-colorBorderSecondary !rounded-none",
              label: {
                itemConfig: INTEGRATION_ITEMS_DICT[integration.type] ?? {
                  title: integration.type,
                },
                accountName:
                  integration.selected_page?.name || integration.username,
                integrationType: integration.type,
              } as SocialAccountLabelProps,
            });
          }

          return acc;
        },
        [] as {
          value: string;
          label: SocialAccountLabelProps;
          className?: string;
        }[]
      ),
    [integrations]
  );

  const isAllSelected =
    selectedIntegrationIds?.length === accountOptions.length;

  useEffect(() => {
    const validSelectedIds = selectedIntegrationIds?.filter((id) =>
      integrations.some((i) => i.id === id)
    );

    if (
      validSelectedIds?.length !== selectedIntegrationIds?.length ||
      !validSelectedIds?.length
    ) {
      setSelectedIntegrationIds(
        validSelectedIds?.length
          ? validSelectedIds
          : accountOptions.map((option) => option.value)
      );
    }
  }, [currentBusiness.id]);

  return (
    <Select
      className={classNames(
        "!w-full md:!w-64 [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:overflow-hidden",
        {
          "[&_.ant-select-selection-overflow_.ant-select-selection-item]:bg-transparent":
            isAllSelected,
        }
      )}
      popupMatchSelectWidth={false}
      maxTagCount={isAllSelected ? 0 : 1}
      maxTagPlaceholder={(omitted) =>
        isAllSelected ? "All Accounts" : `+${omitted.length}...`
      }
      mode="multiple"
      options={accountOptions}
      optionRender={({ label }) => (
        <SocialAccountLabel {...(label as SocialAccountLabelProps)} />
      )}
      labelRender={({ label }) =>
        selectedIntegrationIds?.length ? (
          <SocialAccountLabel {...(label as SocialAccountLabelProps)} compact />
        ) : (
          label
        )
      }
      filterOption={(input, option) =>
        [option?.label?.accountName, option?.label?.integrationType].some(
          (item) => item?.toLowerCase().includes(input.toLowerCase())
        )
      }
      notFoundContent={
        !integrations.length
          ? "No connected accounts found"
          : "No analytics-supported socials found"
      }
      value={selectedIntegrationIds}
      onChange={(selected) => setSelectedIntegrationIds(selected)}
      placeholder={
        accountOptions.length
          ? "Select socials"
          : !accountOptions.length && integrations.length > 0
            ? "No analytics-supported socials"
            : "No socials connected"
      }
    />
  );
};

type SocialAccountLabelProps = {
  itemConfig?: (typeof INTEGRATION_ITEMS)[number];
  accountName?: string;
  compact?: boolean;
  integrationType?: string;
};

const SocialAccountLabel = ({
  itemConfig,
  accountName,
  compact,
}: SocialAccountLabelProps) => (
  <div className="flex items-center gap-3 min-w-fit py-2">
    {itemConfig?.icon && (
      <img className={compact ? "h-4" : "h-8"} src={itemConfig.icon} />
    )}
    <div className="flex flex-col gap-0.5">
      <span className="font-medium text-xs flex gap-1">
        {accountName ?? itemConfig?.title ?? "Unknown"}
        {!itemConfig?.icon && compact && itemConfig?.title && (
          <span>({itemConfig.title})</span>
        )}
      </span>
      {accountName && !compact && (
        <span className="text-[10px] font-normal text-antd-colorTextSecondary">
          {itemConfig?.title}
        </span>
      )}
    </div>
  </div>
);

export default SocialAccountFilter;
