import { inferBusinessDetails } from "api/businesses";
import { omniboxRequest, ScrapedData } from "api/user";
import { genericError } from "fixtures/globalConstants";
import { useMemo, useState } from "react";
import { Business } from "store/business/businessConstants";

export const validateUrl = (url: string) => {
  const urlWithHttpPattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  const urlWithoutHttpPattern =
    /^[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

  const enteredURL = url.trim();
  return (
    urlWithHttpPattern.test(enteredURL) ||
    urlWithoutHttpPattern.test(enteredURL)
  );
};

const useOmnibox = (props: { defaultCrawlEntireSite?: boolean } = {}) => {
  const { defaultCrawlEntireSite = true } = props;

  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [crawlEntireSite, setCrawlEntireSite] = useState(
    defaultCrawlEntireSite
  );

  const isURLValid = useMemo(() => {
    const urlWithHttpPattern =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
    const urlWithoutHttpPattern =
      /^[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

    const enteredURL = inputValue.trim();
    return (
      urlWithHttpPattern.test(enteredURL) ||
      urlWithoutHttpPattern.test(enteredURL)
    );
  }, [inputValue]);

  const getContent = async (url: string) => {
    setIsLoading(true);
    try {
      const res = await omniboxRequest({
        crawl: crawlEntireSite,
        url: url ?? inputValue,
      });
      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      return {};
    }
  };

  const getChapters = async (
    content: string
  ): Promise<(Business & ScrapedData) | undefined> => {
    setIsLoading(true);

    try {
      let res: ScrapedData = {};

      if (!content) {
        res = await omniboxRequest({
          crawl: crawlEntireSite,
          url: inputValue,
        });
      }

      const response = await inferBusinessDetails({
        content: content || (res.content ?? ""),
      });
      setIsLoading(false);
      return {
        ...res,
        ...response.details,
        topics: response.topics,
      };
    } catch (error) {
      genericError(error);
      setIsLoading(false);
    }
  };

  return {
    crawlEntireSite,
    inputValue,
    isURLValid,
    isLoading,

    setCrawlEntireSite,
    setInputValue,
    getChapters,
    getContent,
  };
};

export default useOmnibox;
