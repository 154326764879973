import dayjs from "dayjs";
import { VOID_FUNCTION } from "fixtures/globalConstants";
import { useEffect, useMemo, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import { useAppDispatch, useAppSelector } from "store";
import { getPostsThunk } from "store/posts/postActions";
import { Post } from "store/posts/postConstants";
import { selectPostStore } from "store/posts/postSlice";
import MonthCard, { PostCalendarEvent } from "./PostCalendar/MonthCard";
import PostCalendarNavigation from "./PostCalendar/PostCalendarNavigation";
import { CALENDAR_POST_SCREENS } from "./utils/postCalendarFixtures";
import { useGetAllowedScreens } from "./utils/useGetAllowedScreens";

const localizer = dayjsLocalizer(dayjs);

const postsToEvents = (posts: Post[]) =>
  posts.flatMap((post) => {
    const postDateRaw =
      post[post.status === "SCHEDULED" ? "will_publish_at" : "published_at"];
    if (postDateRaw) {
      const postDate = dayjs(postDateRaw).toDate();
      return { start: postDate, end: postDate, post };
    }
    return [];
  });

const PostCalendarPage = () => {
  const dispatch = useAppDispatch();
  const postData = useAppSelector(selectPostStore);
  const { canAccessScreen } = useGetAllowedScreens();

  const [dateRange, setDateRange] = useState({
    start: dayjs().startOf("month").subtract(7, "day"),
    end: dayjs().endOf("month").add(7, "day"),
  });
  const [selectedEvent, setSelectedEvent] = useState<PostCalendarEvent | null>(
    null
  );

  const eventList = useMemo(
    () =>
      postsToEvents(
        postData.scheduled.postList.concat(postData.published.postList)
      ),

    [postData.scheduled.postList, postData.published.postList]
  );

  const shouldQueryScreen = (
    postScreen: (typeof CALENDAR_POST_SCREENS)[number]
  ) =>
    canAccessScreen(postScreen) &&
    (postData[postScreen].isInitialized || !postData[postScreen].isLoading) &&
    ((postScreen === "published" && dateRange.start.isBefore(dayjs())) ||
      (postScreen === "scheduled" && dateRange.end.isAfter(dayjs())));

  useEffect(() => {
    CALENDAR_POST_SCREENS.forEach(
      (postScreen) =>
        shouldQueryScreen(postScreen) &&
        dispatch(getPostsThunk({ postScreen, ...dateRange }))
    );
  }, [dateRange.start, dateRange.end]);

  return (
    <div className="max-lg:pt-6 max-sm:px-2 px-6 py-10 mx-auto h-full w-full max-w-screen-2xl">
      <Calendar
        onRangeChange={(dates) => {
          !Array.isArray(dates) &&
            setDateRange({
              // Can only fetch exclusive date range from BE, extend date range by 1 millisecond
              // in either direction.
              // https://mymarky.atlassian.net/browse/SWE-2244
              start: dayjs(dates.start).subtract(1, "ms"),
              end: dayjs(dates.end).add(1, "ms"),
            });
        }}
        className="h-full text-antd-colorTextSecondary space-y-4"
        components={{
          month: { event: MonthCard },
          toolbar: PostCalendarNavigation,
        }}
        onSelectEvent={setSelectedEvent}
        selected={selectedEvent}
        localizer={localizer}
        events={eventList}
        formats={{
          dateFormat: (date, culture, localizer) =>
            localizer?.format(date, "D", culture) ??
            date.toLocaleDateString(culture),
        }}
        showAllEvents
        defaultView="month"
        onDrillDown={VOID_FUNCTION}
      />
    </div>
  );
};

export default PostCalendarPage;
