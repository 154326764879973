import { Theme } from "api/config/chalice-api";
import cx from "classnames";
import { useState } from "react";
import { MdCheck } from "react-icons/md";
import Skeleton from "react-loading-skeleton";

type ThemeCardProps = {
  theme: Theme;
  selectedThemes: string[];
  isEditing: boolean;
  handleSelect?: (themeName: string) => void;
};

const ThemeCard = ({
  theme,
  selectedThemes,
  isEditing,
  handleSelect,
}: ThemeCardProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (isEditing) {
    return (
      <div
        key={theme.name}
        className="themes-card__grid__item"
        onClick={() => handleSelect && handleSelect(theme.name)}
      >
        {selectedThemes.includes(theme.name) && (
          <div className="themes-card__grid__item__check">
            <div className="themes-card__grid__item__check__icon-container">
              <MdCheck />
            </div>
          </div>
        )}
        <img
          onLoad={() => setIsImageLoaded(true)}
          src={theme.image_url}
          className={cx({ "loading-image": !isImageLoaded })}
          alt=""
        />
        {!isImageLoaded && (
          <Skeleton className="aspect-square absolute" borderRadius={8} />
        )}
      </div>
    );
  }

  return (
    <div
      key={theme.name}
      className="themes-card__grid__item themes-card__grid__item--not-selectable"
    >
      <img
        className={cx({ "loading-image": !isImageLoaded })}
        onLoad={() => setIsImageLoaded(true)}
        src={theme.image_url}
        alt={theme.name}
      />
      {!isImageLoaded && (
        <Skeleton className="aspect-square absolute" borderRadius={8} />
      )}
    </div>
  );
};

export default ThemeCard;
