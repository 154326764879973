import {
  BrowserCookieStickyBucketService,
  GrowthBook,
  useFeatureIsOn,
  useFeatureValue,
} from "@growthbook/growthbook-react";
import env from "config/envVars";
import Cookies from "js-cookie";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { AppFeatures } from "./feature-flags";

const growthbook = new GrowthBook<AppFeatures>({
  apiHost: "https://cdn.growthbook.io",
  clientKey: env.GROWTHBOOK_CLIENT_KEY,
  trackingCallback: (experiment, result) => {
    trackEvent("experiment_viewed", {
      experiment_id: experiment.key,
      variation_id: result.key,
    });
  },
  enableDevMode: true,
  stickyBucketService: new BrowserCookieStickyBucketService({
    jsCookie: Cookies,
  }),
});

growthbook.init({
  streaming: true,
});

export const useTypedFeatureIsOn = (key: keyof AppFeatures) =>
  useFeatureIsOn<AppFeatures>(key);
export const useTypedFeatureValue = <T extends keyof AppFeatures>(
  key: T,
  defaultValue: AppFeatures[T]
) => useFeatureValue<AppFeatures[T]>(key, defaultValue);

export default growthbook;
