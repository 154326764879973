import { Button, Dropdown, message, Switch, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/interface";
import Link from "antd/es/typography/Link";
import { updateBusiness } from "api/businesses";
import { ContentTopic, MixMatchChoicesBody } from "api/config/chalice-api";
import classNames from "classnames";
import TourModal from "components/TourModal";
import useAppContext from "config/AppContext/useAppContext";
import growthbook from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { genericError } from "fixtures/globalConstants";
import { SHOW_TOPICS_TOUR } from "fixtures/localStorageKeys";
import _ from "lodash";
import ImportFromWebsiteModal from "pages/BusinessProfile/components/ImportFromWebsiteModal";
import NewGeneratePanel from "pages/Posts/components/NewGeneratePanel";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { POST_SCREEN_TAB } from "pages/Posts/utils/useGetAllowedScreens";
import { useEffect, useMemo, useState } from "react";
import {
  MdMoreVert,
  MdOutlineAdd,
  MdOutlineFileDownload,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import {
  currentBusinessGetter,
  updateBusinessTopics,
} from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import TopicModal, { TopicModalData, TopicModalType } from "./TopicModal";
import { TOPIC_ACTIONS } from "./topicsFixtures";

const TopicsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { realm } = useAppContext();

  const { hasPermission } = useUserPermissions();
  const canEditBusiness = hasPermission("business_details_write");
  const canGeneratePosts = hasPermission("post_status_generate_write");

  const currentBusiness = useAppSelector(currentBusinessGetter);
  const [showTopicsTour, , removeShowTopicsTour] =
    useLocalStorage<boolean>(SHOW_TOPICS_TOUR);
  const [showModal, setShowModal] = useState<TopicModalData | null>(null);

  const [topics, setTopics] = useState<ContentTopic[]>([]);
  useEffect(() => {
    !_.isEqual(currentBusiness.topics, topics) &&
      setTopics(currentBusiness.topics);
  }, [currentBusiness.topics]);

  const enabledTopicsCount = useMemo(
    () => topics.filter(({ enabled }) => enabled).length,
    [topics]
  );

  const topicActions = (checkTopic: ContentTopic) =>
    TOPIC_ACTIONS.reduce(
      (
        prev,
        { requiresPerm, requiresEnabledTopic, requiresFlag, ...action }
      ) => {
        (!requiresFlag || growthbook.isOn(requiresFlag)) &&
          (!requiresEnabledTopic || checkTopic.enabled) &&
          (!requiresPerm || hasPermission(requiresPerm)) &&
          prev.push(action);

        return prev;
      },
      [] as ItemType[]
    );

  const handleActionClick = (
    key: "delete" | TopicModalType,
    topic: ContentTopic,
    index: number
  ) => {
    if (key === "delete") {
      deleteTopic(topic, index);
    } else if (key === "edit-topic" || key === "generate-posts") {
      setShowModal({
        modalType: key,
        topic: { ...topic, index },
      });
    }
  };

  const updateTopics = async (newTopic: ContentTopic, updateIndex?: number) => {
    const newTopics =
      updateIndex === undefined
        ? topics.concat(newTopic)
        : topics.map((topic, index) =>
            index === updateIndex ? newTopic : topic
          );

    saveTopics(newTopics);
  };

  const saveTopics = async (newTopics: ContentTopic[]) => {
    const currentTopics = topics;
    setTopics(newTopics);

    try {
      await updateBusiness(currentBusiness.id, { topics: newTopics });
      dispatch(updateBusinessTopics(newTopics));
    } catch (error) {
      setTopics(currentTopics);
      genericError(error);
    }
  };

  const minimumTopicsMessage = () =>
    message.info({
      key: "minimum-enabled-topics",
      content: "At least one topic must be enabled.",
    });

  const toggleEnabled = (topicIndex: number) => {
    const newEnabled = !topics[topicIndex].enabled;
    if (!newEnabled && enabledTopicsCount === 1) {
      minimumTopicsMessage();
    } else {
      const toggleTopic = topics[topicIndex];
      updateTopics(
        { ...toggleTopic, enabled: !toggleTopic.enabled },
        topicIndex
      );
    }
  };

  const deleteTopic = (topic: ContentTopic, topicIndex: number) => {
    if (topic.enabled && enabledTopicsCount === 1) {
      minimumTopicsMessage();
    } else {
      const newTopics = [...topics];
      newTopics.splice(topicIndex, 1);

      saveTopics(newTopics).then(() =>
        trackEvent("updated_topics", { action: "delete" })
      );
    }
  };

  // TODO: Temp -- Generate panel should handle submission
  const generatePostsForTopic = async (_body: MixMatchChoicesBody) => {
    message.info({
      key: "view-generated-posts",
      content: (
        <>
          New posts are being generated! View them on the{" "}
          <Link
            className="!text-antd-colorInfo hover:!text-antd-colorInfoHover"
            onClick={() => {
              message.destroy("view-generated-posts");
              navigate({
                pathname: POSTS_PAGE_ROUTE,
                search: `${POST_SCREEN_TAB}=generated`,
              });
            }}
          >
            Generated Posts
          </Link>{" "}
          page.
        </>
      ),
    });
    // try {
    //   await dispatch(generatePostsThunk(body));
    //   setShowModal(null);
    //   message.info({
    //     key: "view-generated-posts",
    //     content: (
    //       <>
    //         New posts are being generated! View them on the{" "}
    //         <Link
    //           className="!text-antd-colorInfo hover:!text-antd-colorInfoHover"
    //           onClick={() => {
    //             message.destroy("view-generated-posts");
    //             navigate({
    //               pathname: POSTS_PAGE_ROUTE,
    //               search: "tab=generated",
    //             });
    //           }}
    //         >
    //           Generated Posts
    //         </Link>{" "}
    //         page.
    //       </>
    //     ),
    //   });
    // } catch (error) {
    //   genericError(error);
    // }
  };

  const TopicDisplay = (topic: ContentTopic) => (
    <>
      <div className="font-medium text-sm leading-6">{topic.title}</div>
      <span className="block text-xs leading-5 text-antd-colorTextSecondary">
        {topic.body}
      </span>
    </>
  );

  return (
    <div className="card">
      <header>
        <h2>Topics</h2>
        {realm.isMarky && (
          <span>
            Marky uses your content topics to generate posts. Add or change your
            topics to see new content.
          </span>
        )}
      </header>
      <main className="space-y-2">
        {topics.map((topic, index) => (
          <div
            key={index}
            className={classNames(
              "grid grid-cols-[auto_1fr_auto] items-center gap-2 p-3 rounded-md border border-antd-colorBorder bg-antd-colorBgContainer transition-colors",
              {
                "!text-antd-colorTextSecondary bg-antd-colorBgContainerDisabled":
                  !topic.enabled,
              }
            )}
          >
            {canEditBusiness && (
              <Switch
                className="scale-[0.90]"
                value={topic.enabled}
                onChange={() => toggleEnabled(index)}
              />
            )}

            <Tooltip
              title={
                !canEditBusiness && (
                  <div className="[&_span]:!text-white/75">
                    {TopicDisplay(topic)}
                  </div>
                )
              }
              trigger="click"
              mouseEnterDelay={3}
            >
              <div className="min-w-0 [&_*]:text-ellipsis [&_*]:whitespace-nowrap [&_*]:overflow-hidden">
                {TopicDisplay(topic)}
              </div>
            </Tooltip>

            {(canEditBusiness || canGeneratePosts) && (
              <Dropdown
                menu={{
                  items: topicActions(topic),
                  onClick: ({ key }) =>
                    handleActionClick(
                      key as "delete" | TopicModalType,
                      topic,
                      index
                    ),
                }}
              >
                <Button
                  type="text"
                  size="small"
                  icon={
                    <MdMoreVert className="text-antd-colorIcon" size={20} />
                  }
                />
              </Dropdown>
            )}
          </div>
        ))}
      </main>
      <footer>
        <Button
          size="large"
          onClick={() => setShowModal({ modalType: "import-topics" })}
          icon={<MdOutlineFileDownload />}
        >
          Import Topics
        </Button>
        <Button
          size="large"
          onClick={() => setShowModal({ modalType: "new-topic" })}
          icon={<MdOutlineAdd />}
        >
          New Topic
        </Button>
      </footer>

      {showModal?.modalType === "import-topics" ? (
        <ImportFromWebsiteModal
          close={() => setShowModal(null)}
          recreateAccount={false}
        />
      ) : showModal?.modalType === "generate-posts" ? (
        <NewGeneratePanel
          onSubmit={() => {
            setShowModal(null);
            generatePostsForTopic({});
          }}
          onCancel={() => setShowModal(null)}
          generateFrom={{ type: "topic", id: showModal.topic.title }}
        />
      ) : (
        showModal !== null && (
          <TopicModal
            modalData={showModal}
            closeModal={() => setShowModal(null)}
            addTopic={updateTopics}
            updateTopic={updateTopics}
          />
        )
      )}
      {realm.isMarky && showTopicsTour && (
        <TourModal
          data={{
            title: "Automatically Generated Content Ideas",
            description:
              "This is what we base your posts on. To post about something else, edit or add here.",
          }}
          close={removeShowTopicsTour}
        />
      )}
    </div>
  );
};

export default TopicsPage;
