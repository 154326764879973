import { Button, Descriptions } from "antd";
import { PlanType } from "api/config/chalice-api";
import { createCustomerPortalSession } from "api/stripe";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import useAppContext from "config/AppContext/useAppContext";
import useAddNewBusiness from "hooks/useAddNewBusiness";
import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "store";
import { selectPostStore } from "store/posts/postSlice";
import { selectUserInfo } from "store/user/userSlice";

type LoadingButtonType = "manage" | "invoice" | null;

const PLAN_MAPPING: { [key in PlanType]?: string } = {
  "professional-monthly": "Professional",
  "professional-yearly": "Professional",
  "personal-monthly": "Personal",
  "personal-yearly": "Personal",
  "agency-monthly": "Agency",
  "agency-yearly": "Agency",
  marky_tier1: "AppSumo - Tier 1",
  marky_tier2: "AppSumo - Tier 2",
  marky_tier3: "AppSumo - Tier 3",
  marky_tier4: "AppSumo - Tier 4",
  marky_tier5: "AppSumo - Tier 5",
  free: "Free",
};

const AccountDetails = () => {
  const { openGlobalModal } = useGlobalModal();
  const { handleNewBusinessCreation } = useAddNewBusiness();

  const {
    subscription,
    id: userId,
    invoice_data,
  } = useAppSelector(selectUserInfo);
  const { businesses } = useAppSelector((state) => state.user);
  const { postLimitInfo } = useAppSelector(selectPostStore);

  const [loadingButton, setLoadingButton] = useState<LoadingButtonType>(null);

  const isFreePlan = subscription?.plan === "free";
  const isAppSumo = subscription?.is_appsumo;

  const { realm } = useAppContext();

  const usersBusinesses = useMemo(() => {
    return businesses.filter(({ user_id }) => user_id === userId);
  }, [userId, businesses]);

  const plan = useMemo(() => {
    const key = subscription?.plan || "free";
    return `${PLAN_MAPPING[key] || startCase(key)}`;
  }, [subscription]);

  const redirectToBilling = useCallback(async (name: LoadingButtonType) => {
    setLoadingButton(name);
    try {
      const { url } = await createCustomerPortalSession();
      window.open(url, "_self");
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setLoadingButton(null);
    }, 1000);
  }, []);

  const handleManagePlanButtonClick = useCallback(() => {
    if (isAppSumo) {
      openGlobalModal({
        modalName: "appsumo-plans",
        modalData: { modalType: "default" },
      });
      return;
    }

    redirectToBilling("manage");
  }, [isAppSumo]);

  const billingCycle = useMemo(() => {
    if (isFreePlan || isAppSumo) {
      return;
    }

    return startCase(subscription?.plan?.split("-")[1]);
  }, [subscription, isFreePlan, isAppSumo]);

  const nextPayment = useMemo(() => {
    if (!invoice_data?.current_period_end) {
      return;
    }

    const date = new Date(invoice_data?.current_period_end * 1000);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
      day: "2-digit",
    }).format(date);

    return `$${invoice_data.next_invoice_amount} on ${formattedDate}`;
  }, [invoice_data]);

  const items = useMemo(() => {
    return [
      {
        key: "1",
        span: 3,
        label: "Your Plan",
        children: (
          <p>
            {plan}{" "}
            {!isFreePlan && !isAppSumo && (
              <Button
                onClick={handleManagePlanButtonClick}
                loading={loadingButton === "manage"}
                type="link"
              >
                Manage Plan
              </Button>
            )}
          </p>
        ),
      },
      ...(billingCycle
        ? [
            {
              key: "2",
              span: 3,
              label: "Billing Cycle",
              children: billingCycle,
            },
          ]
        : []),
      ...(nextPayment && !subscription?.is_appsumo
        ? [
            {
              key: "3",
              span: 3,
              label: "Next Payment",
              children: (
                <p>
                  {nextPayment}
                  <Button
                    onClick={() => redirectToBilling("invoice")}
                    loading={loadingButton === "invoice"}
                    type="link"
                  >
                    View Invoices
                  </Button>
                </p>
              ),
            },
          ]
        : []),
      {
        key: "4",
        span: 3,
        label: "Businesses",
        children: (
          <p>
            {`${usersBusinesses.length} / ${
              (subscription?.restrictions?.businesses ?? 0) +
              (subscription?.extra_businesses ?? 0)
            } Businesses`}{" "}
            {realm.isMarky && (
              <Button
                onClick={() => handleNewBusinessCreation(true)}
                type="link"
              >
                Increase Limit
              </Button>
            )}
          </p>
        ),
      },
      ...(subscription?.plan === "marky_tier5"
        ? [
            {
              key: "advance-wl-businesses",
              span: 3,
              label: "Advanced Whitelabel Businesses",
              children: (
                <p>
                  {`${usersBusinesses.filter((business) => business.whitelabel_tier === "ADVANCED").length} / ${
                    subscription?.restrictions
                      ?.advanced_whitelabel_businesses ?? 0
                  } Businesses`}{" "}
                </p>
              ),
            },
          ]
        : []),
      ...(postLimitInfo?.count_allowed_posts
        ? [
            {
              key: "generated-posts",
              span: 3,
              label: "Posts Generated",
              children: (
                <p>
                  {postLimitInfo.count_generated_posts} /{" "}
                  {postLimitInfo.count_allowed_posts} Posts{" "}
                  <span className="text-antd-colorTextDescription">
                    (this month)
                  </span>
                  {realm.isMarky && (
                    <Button
                      onClick={() =>
                        openGlobalModal({ modalName: "subscription" })
                      }
                      type="link"
                    >
                      Increase Limit
                    </Button>
                  )}
                </p>
              ),
            },
          ]
        : []),
    ];
  }, [subscription, businesses, loadingButton]);

  return (
    <div className="user-details-card__account-details">
      <span className="user-details-card__account-details__title">
        Account Details
      </span>
      <Descriptions bordered items={items} />
    </div>
  );
};

export default AccountDetails;
