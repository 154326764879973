import PublishDetails from "pages/Posts/components/PublishDetails";
import { Post } from "store/posts/postConstants";

const PublishedPostDetail = ({ post }: { post: Post<"PUBLISHED"> }) => (
  <div className="space-y-6">
    <PublishDetails post={post} />
    <div className="space-y-1">
      <label className="font-medium">Caption</label>
      <p className="whitespace-pre-wrap text-antd-colorTextSecondary">
        {post.caption || "Post did not have a caption."}
      </p>
    </div>
  </div>
);

export default PublishedPostDetail;
