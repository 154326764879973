import { Button } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import { MOBILE_SIDEBAR_BREAKPOINT } from "fixtures/globalConstants";
import useGlobalModal from "../GlobalModals/GlobalModalContext/useGlobalModal";
import { SIDEBAR_BUTTON_CONFIGS } from "./upgradePlanConstants";
import useGetUpgradeInfo from "./useGetUpgradeInfo";

const UpgradePlanButton = () => {
  const { openGlobalModal } = useGlobalModal();
  const {
    sidebarCollapsedState: [, setSidebarCollapsed],
    realm,
  } = useAppContext();

  const upgradeConfig = useGetUpgradeInfo();

  return (
    realm.isMarky &&
    upgradeConfig && (
      <Button
        {...SIDEBAR_BUTTON_CONFIGS[upgradeConfig.bannerType]}
        type="primary"
        onClick={() => {
          window.innerWidth <= MOBILE_SIDEBAR_BREAKPOINT &&
            setSidebarCollapsed(true);
          openGlobalModal({
            modalName: "subscription",
            modalData: { initialInterval: upgradeConfig.openOnInterval },
          });
        }}
      >
        {upgradeConfig.sidebarTitle ?? upgradeConfig.bannerTitle}
      </Button>
    )
  );
};

export default UpgradePlanButton;
