import axios from "axios";
import Cookies from "js-cookie";

export const trackSignup = async (data: { email: string; uid: string }) => {
  try {
    const tid = Cookies.get("_fprom_tid");

    if (!tid) {
      return;
    }

    const response = await axios.post(
      "https://firstpromoter.com/api/v1/track/signup",
      { ...data, tid },
      {
        headers: {
          "x-api-key": "74a73e85d135e496fdf6c6c7769ebec6",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
