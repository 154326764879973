import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";
import useGetPostDetailsQuery from "hooks/useGetPostDetailsQuery";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Post } from "store/posts/postConstants";
import { validateImageUrl } from "utils/validateFileUpload";
import { useGetPostRenderInfo } from "../utils/useGetPostRenderInfo";
import Carousel from "./Carousel";

type PostMediaRendererProps = {
  openFullScreenEditor?: () => void;
  updatePostCardHeight?: () => void;
  inModal?: boolean;
  post: Post;
};

const PostMediaRenderer = ({
  openFullScreenEditor,
  updatePostCardHeight,
  inModal,
  post,
}: PostMediaRendererProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { isVideo, isCarousel, isVideoRendering } = useGetPostRenderInfo(post);
  useGetPostDetailsQuery(post.id, {
    refetchOnMount: false,
    refetchInterval: isVideoRendering ? 5000 : undefined,
    enabled: isVideoRendering,
  });

  const mediaUrl = useMemo(
    () =>
      (post.media_urls?.length && (isVideo || !post.thumbnail_url)
        ? post.media_urls?.[0]
        : post.thumbnail_url) ?? "",
    [isVideo, post.media_urls]
  );

  const handleVideoClick = (e: React.MouseEvent) => {
    !document.fullscreenElement && e.preventDefault();
    openFullScreenEditor && openFullScreenEditor();
  };

  useEffect(() => {
    mediaUrl && setIsImageLoaded(!validateImageUrl(mediaUrl));
  }, [mediaUrl]);

  useEffect(() => {
    isImageLoaded && updatePostCardHeight && updatePostCardHeight();
  }, [isImageLoaded]);

  if (!isVideo && isCarousel) {
    return (
      <Carousel
        {...{ handleVideoClick, post, inModal, openFullScreenEditor }}
      />
    );
  }

  const imageClassNames = classNames(
    "object-contain rounded border border-solid border-antd-colorBorder sm:w-[366px]",
    {
      "loading-image": !isImageLoaded,
      "cursor-pointer": openFullScreenEditor,
    }
  );
  const renderMedia = () => {
    if (mediaUrl && validateImageUrl(mediaUrl)) {
      return (
        <img
          loading="lazy"
          onClick={() => openFullScreenEditor && openFullScreenEditor()}
          className={imageClassNames}
          onLoad={() => setIsImageLoaded(true)}
          alt={post.caption ?? undefined}
          src={mediaUrl}
        />
      );
    } else {
      return (
        <video
          className={imageClassNames}
          poster={post.thumbnail_url ?? undefined}
          src={mediaUrl}
          onDoubleClick={handleVideoClick}
          onClick={handleVideoClick}
          controls
        />
      );
    }
  };

  const renderPendingMedia = () => (
    <div className="relative">
      <img
        loading="lazy"
        src={post.thumbnail_url || mediaUrl}
        className={classNames(imageClassNames, "!cursor-default")}
        onLoad={() => setIsImageLoaded(true)}
      />
      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 mix-blend-difference">
        <LoadingOutlined className="text-white [&_svg]:w-8 [&_svg]:h-8" />
      </div>
    </div>
  );

  return (
    <>
      {!isVideoRendering && mediaUrl && renderMedia()}
      {isVideoRendering && renderPendingMedia()}
      {!isImageLoaded && !isCarousel && (
        <Skeleton
          height={window.innerWidth <= 600 ? window.innerWidth - 64 : 366}
          borderRadius={8}
        />
      )}
    </>
  );
};

export default PostMediaRenderer;
