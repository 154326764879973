import { upperFirst } from "lodash";
import { SidePanelWrap } from "polotno";
import { StoreType } from "polotno/model/store";
import {
  DEFAULT_SECTIONS,
  SectionTab,
  SidePanel,
  VideosSection,
} from "polotno/side-panel";
import { useMemo } from "react";
import { IconType } from "react-icons";
import {
  MdAnimation,
  MdAudiotrack,
  MdOutlineLandscape,
  MdOutlineLayers,
  MdOutlinePhoto,
  MdOutlinePhotoSizeSelectLarge,
  MdOutlineShapeLine,
  MdOutlineTextIncrease,
} from "react-icons/md";
import { SidePanelSection, SidePanelTab } from "../../polotnoTypes";
import { HiddenPanel, HiddenTab } from "../../polotnoUtil";
import AnimationsPanel from "./AnimationsPanel/AnimationsPanel";
import AudiosPanel from "./Audios/AudiosPanel";
import CustomResizePanel from "./CustomResizePanel";
import CustomTextPanel from "./CustomTextPanel";
import ImageLibraryPanel from "./ImageLibraryPanel";

const SECTION_NAMES = [
  "images",
  "background",
  "layers",
  "elements",
  "size",
] as const;
type SectionName = (typeof SECTION_NAMES)[number];

const ICONS_MAPPING: { [key in SectionName]: IconType } = {
  size: MdOutlinePhotoSizeSelectLarge,
  background: MdOutlineLandscape,
  elements: MdOutlineShapeLine,
  layers: MdOutlineLayers,
  images: MdOutlinePhoto,
};

const CUSTOM_PANELS: {
  [key in SectionName]?: ObserverComponent;
} = {
  images: ImageLibraryPanel,
  size: CustomResizePanel as ObserverComponent,
};

const SECTIONS = DEFAULT_SECTIONS.reduce((prev, { Panel, ...section }) => {
  const sectionName =
    section.name === "photos" ? "images" : (section.name as SectionName);

  if (SECTION_NAMES.includes(sectionName)) {
    const tabFn: SidePanelSection["Tab"] = (props) => {
      const Icon = ICONS_MAPPING[sectionName];
      return (
        <SectionTab {...props} name={upperFirst(sectionName)}>
          <Icon size={20} />
        </SectionTab>
      );
    };
    tabFn.displayName = sectionName;

    prev.push({
      ...section,
      name: sectionName,
      Panel: (CUSTOM_PANELS[sectionName] ?? Panel) as unknown,
      Tab: tabFn,
    } as SidePanelSection);
  }

  return prev;
}, [] as SidePanelSection[]);

const CustomTextTab: SidePanelTab = (props) => (
  <SectionTab {...props} name="Text">
    <MdOutlineTextIncrease size={20} />
  </SectionTab>
);
CustomTextTab.displayName = "custom-text";

const CustomTextSection: SidePanelSection = {
  name: "custom-text",
  Tab: CustomTextTab,
  Panel: CustomTextPanel as ObserverComponent,
};

const AnimationsTab: SidePanelTab = (props) => (
  <SectionTab {...props} name="Animations">
    <MdAnimation size={20} />
  </SectionTab>
);

const AudiosTab: SidePanelTab = (props) => (
  <SectionTab {...props} name="Audios">
    <MdAudiotrack size={20} />
  </SectionTab>
);

AnimationsTab.displayName = "animations";
AudiosTab.displayName = "audios";

const AnimationSection: SidePanelSection = {
  name: "animations",
  Tab: AnimationsTab,
  Panel: AnimationsPanel,
};

const AudioSection: SidePanelSection = {
  name: "audios",
  Tab: AudiosTab,
  Panel: AudiosPanel,
};

const SECTION_ORDER = [
  "custom-text",
  "images",
  "videos",
  "audios",
  "elements",
  "background",
  "layers",
  "size",
  "animations",
];

const CustomSidePanel: ObserverComponent<{
  store: StoreType;
  isVideoEditor?: boolean;
}> = ({ store, isVideoEditor = true }) => {
  const sortedSections = useMemo(() => {
    return [
      CustomTextSection,
      ...SECTIONS,
      {
        ...VideosSection,
        // TODO: Temp -- SUP-878 videos not rendering, hide panel for all users until fixed
        Panel: HiddenPanel,
        Tab: HiddenTab,
        //   ...(!isVideoEditor && { Panel: HiddenPanel, Tab: HiddenTab }),
      },
      ...(isVideoEditor ? [AnimationSection, AudioSection] : []),
    ].sort(
      (a, b) => SECTION_ORDER.indexOf(a.name) - SECTION_ORDER.indexOf(b.name)
    );
  }, [isVideoEditor]);

  return (
    <SidePanelWrap className="max-md:!w-full">
      <SidePanel
        sections={sortedSections}
        defaultSection="custom-text"
        store={store}
      />
    </SidePanelWrap>
  );
};

export default CustomSidePanel;
