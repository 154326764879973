import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { UserInfo } from "store/user/userConstants";
import { selectUserInfo } from "store/user/userSlice";
import growthbook from "./growthbookUtils";

const GrowthBookHandler = () => {
  const location = useLocation();

  const user = useAppSelector(selectUserInfo);

  useEffect(() => {
    setGrowthBookAttributes(user);
  }, [user]);

  useEffect(() => {
    growthbook.setURL(window.location.href);
  }, [location]);

  return null;
};

const setGrowthBookAttributes = (user: UserInfo) => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const userAgent = window.navigator.userAgent.toLowerCase();

  // Determine device type
  const deviceType =
    /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    )
      ? "mobile"
      : "desktop";

  // Determine browser
  let browser = "unknown";
  // https://github.com/brave/brave-browser/wiki/Detecting-Brave-(for-Websites)
  if ("brave" in navigator) browser = "brave";
  else if (userAgent.indexOf("safari") > -1) browser = "safari";
  else if (userAgent.indexOf("firefox") > -1) browser = "firefox";
  else if (userAgent.indexOf("edge") > -1) browser = "edge";
  else if (userAgent.indexOf("opera") > -1 || userAgent.indexOf("opr") > -1)
    browser = "opera";
  else if (userAgent.indexOf("trident") > -1) browser = "ie";

  if (user.id) {
    growthbook.setAttributes({
      id: user.id,
      email: user.email,
      plan: user.subscription?.plan || "",
      url,
      path: urlObj.pathname,
      host: urlObj.hostname,
      query: urlObj.search,
      deviceType: deviceType,
      browser: browser,
      utmSource: urlObj.searchParams.get("utm_source") || "",
      utmMedium: urlObj.searchParams.get("utm_medium") || "",
      utmCampaign: urlObj.searchParams.get("utm_campaign") || "",
      utmTerm: urlObj.searchParams.get("utm_term") || "",
      utmContent: urlObj.searchParams.get("utm_content") || "",
    });
  }
};

export default GrowthBookHandler;
