import { Radio, Tour } from "antd";
import { default as classNames, default as cx } from "classnames";
import CreatePostDropdown from "components/Header/CreatePostDropdown";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { SHOW_SCHEDULED_POSTS_TOUR } from "fixtures/localStorageKeys";
import { FAST_FADE_IN_ANIMATION } from "fixtures/motionAnimations";
import { motion } from "framer-motion";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { selectPostStore, setAnimateTab } from "store/posts/postSlice";
import { ScreenPermissionDetailKeyed } from "../utils/screenSelectorConstants";
import {
  POST_SCREEN_TAB,
  useGetAllowedScreens,
} from "../utils/useGetAllowedScreens";

const PostScreenSelector = ({
  showUploadButton,
  children,
}: {
  showUploadButton: boolean;
  children?: ReactNode;
}) => {
  const postCalendar = useTypedFeatureIsOn("post-calendar");

  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const postStore = useAppSelector(selectPostStore);

  const [shouldShowTourComponent, setShouldShowTourComponent] = useState(false);
  const [showScheduledPostsTour, , clearShowScheduledPostsTour] =
    useLocalStorage<true | undefined>(SHOW_SCHEDULED_POSTS_TOUR);
  const scheduleBtnRef = useRef<HTMLDivElement>(null);

  const { screenParam, canSeePosts, visibleScreens } = useGetAllowedScreens();

  let animateTimeout: ReturnType<typeof setTimeout>;
  useEffect(() => {
    clearTimeout(animateTimeout);
    animateTimeout = setTimeout(() => {
      dispatch(setAnimateTab(null));
    }, 1000);
  }, [postStore.animateTab]);

  useEffect(() => {
    if (
      showScheduledPostsTour &&
      postStore.scheduled.count &&
      window.innerWidth > 900
    ) {
      const timeout = setTimeout(() => {
        setShouldShowTourComponent(true);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [postStore.scheduled.count]);

  const closeTour = () => {
    setShouldShowTourComponent(false);
    clearShowScheduledPostsTour();
  };

  const screenOptions = useMemo(
    () =>
      visibleScreens.reduce<Partial<ScreenPermissionDetailKeyed>[]>(
        (prev, { hideFromSelector, key, label, showCount, Icon, id }) =>
          postCalendar && hideFromSelector
            ? prev
            : prev.concat({
                key,
                label: showCount ? `${label} (${postStore[key].count})` : label,
                Icon,
                id,
              }),
        []
      ),
    [visibleScreens, postStore]
  );

  if (!canSeePosts) {
    return null;
  }

  return (
    <>
      <motion.div
        className="max-lg:flex-grow max-lg:p-2 flex place-self-center"
        {...FAST_FADE_IN_ANIMATION}
      >
        <Radio.Group
          onChange={(e) =>
            setSearchParams({ [POST_SCREEN_TAB]: e.target.value })
          }
          className="flex flex-grow max-lg:gap-4 max-lg:justify-between lg:justify-center lg:bg-antd-colorFillTertiary rounded-lg p-1"
          value={screenParam}
        >
          {screenOptions.map(({ label, key, id, Icon }) => {
            const isScheduledTab = key === "scheduled";

            return (
              <div
                id={id}
                key={key}
                ref={isScheduledTab ? scheduleBtnRef : undefined}
                onClick={() => isScheduledTab && closeTour()}
                className={classNames("flex", postCalendar && "flex-grow")}
              >
                <Radio.Button
                  key={key}
                  value={key}
                  className={cx(
                    "flex-grow !border-none !border-transparent bg-transparent font-medium dark:text-antd-colorTextBase hover:text-antd-colorTextBase max-lg:!h-full max-lg:p-2.5 min-w-max flex-grow",
                    {
                      "rainbow-animation": postStore.animateTab === key,
                      "flex-grow": postCalendar,
                    }
                  )}
                >
                  <div className="flex max-lg:flex-col gap-1 items-center justify-center">
                    {Icon && <Icon />}
                    <span className="max-lg:text-xs ">{label}</span>
                  </div>
                </Radio.Button>
              </div>
            );
          })}
        </Radio.Group>
      </motion.div>

      <motion.div
        {...FAST_FADE_IN_ANIMATION}
        className={classNames(
          "flex gap-2 items-center place-self-end self-center",
          postCalendar ? "max-lg:p-2" : "max-lg:hidden"
        )}
      >
        {showUploadButton && <CreatePostDropdown />} {children}
      </motion.div>

      {/* TODO: Remove after post-calendar enabled */}
      {(scheduleBtnRef.current?.clientHeight ?? 0) > 0 && (
        <Tour
          open={shouldShowTourComponent}
          onClose={closeTour}
          steps={[
            {
              title: "Checkout scheduled posts",
              description:
                "Great you've approved your first post! Go here to see when we will post it for you",
              target: scheduleBtnRef.current,
            },
          ]}
          placement="bottomLeft"
        />
      )}
    </>
  );
};

export default PostScreenSelector;
