import { Button, Empty, Input, message, Modal, RadioChangeEvent } from "antd";
import { addImageViaTaskQueue } from "api/media";
import { omniboxRequest } from "api/user";
import CrawlWebsiteToggle from "components/Common/CrawlWebsiteToggle";
import SelectAllButton from "components/Common/SelectAllButton";
import useOmnibox from "hooks/useOmnibox";
import DiscardChangesConfirmation from "pages/Posts/PostEditor/DiscardChangesConfirmation";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { fetchImagesInLibrary } from "store/imageLibrary/imageLibraryActions";
import { LibraryImage } from "store/imageLibrary/imageLibraryConstants";
import useMissingImages from "../../hooks/useMissingImages";
import ImageGallery from "./ImageGallery";
import { IMAGES_LIMIT, SHIFT_CLICK_MESSAGE } from "./imageLibraryFixtures";
import { allowUploadCount } from "./useImageLibrary";

// TODO: Some duplicate code/logic with AutoFindStockModal, extract this to a general modal component
const AddImagesFromWebsite = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const {
    crawlEntireSite,
    inputValue,
    isURLValid,
    setCrawlEntireSite,
    setInputValue,
  } = useOmnibox({ defaultCrawlEntireSite: false });

  const dispatch = useAppDispatch();

  const { imagesInLibrary } = useAppSelector((state) => state.imageLibrary);

  const { MissingImagesMessage, incrementMissing, resetMissing } =
    useMissingImages();

  const [selectedImages, setSelectedImages] = useState<LibraryImage[]>([]);
  const [pulledImages, setPulledImages] = useState<LibraryImage[] | null>(null);
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const buttonText = useMemo(
    () =>
      selectedImages.length
        ? `Add ${pluralize("image", selectedImages.length, true)}`
        : pulledImages
          ? "Add"
          : "Pull",
    [selectedImages, pulledImages]
  );

  const closeModal = () => {
    close();
    resetMissing();
    setSelectedImages([]);
    setPulledImages(null);
    setInputValue("");
  };

  const pullImages = async () => {
    setIsLoading(true);
    try {
      const res = await omniboxRequest({
        crawl: crawlEntireSite,
        url: inputValue,
      });
      setPulledImages(
        (res.image_urls as string[]).map((url) => ({
          id: url,
          file: { url },
          isUploading: false,
        }))
      );
    } catch (error) {
      console.error(error);
      message.error("Unable to pull images. Please try again.");
    }
    setIsLoading(false);
  };

  // TODO: Add method in imageLibraryHook for this, remove duplicate code
  const clickHandler = async () => {
    if (!pulledImages) {
      await pullImages();
    } else {
      try {
        setIsLoading(true);

        const uploadCount = allowUploadCount(
          imagesInLibrary.length,
          IMAGES_LIMIT,
          pulledImages.length
        );

        if (!uploadCount) {
          return;
        }

        const uploadImages =
          typeof uploadCount === "number"
            ? selectedImages.slice(0, uploadCount)
            : selectedImages;

        await Promise.all(
          uploadImages.map((image) => {
            return addImageViaTaskQueue({
              task: {
                task_kwargs: {
                  image_url: image.file.url,
                },
              },
            });
          })
        );

        setIsLoading(false);
        closeModal();
        message.success("Image upload has started.");
        dispatch(fetchImagesInLibrary());
      } catch (error) {
        message.error("Failed to add images to the library. Please try again.");
      }
    }
  };

  const handleCancel = () =>
    selectedImages.length ? setShowDiscardChangesModal(true) : closeModal();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!isURLValid) {
      return;
    }

    if (event.key === "Enter" && isOpen) {
      clickHandler();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <>
      <Modal
        centered
        classNames={{
          header: "!mb-0",
          content: "!p-0",
          body: "!px-0 flex flex-col gap-4",
          footer: "!-mt-0",
        }}
        title={
          <div className="global-modal-header p-4 border-b border-antd-colorBorderSecondary">
            <h3>Pull Images from Website</h3>
            {pulledImages && (
              <p>
                Found {pluralize("image", pulledImages.length, true)}.{" "}
                {SHIFT_CLICK_MESSAGE}
              </p>
            )}
          </div>
        }
        onCancel={handleCancel}
        closable={false}
        open={isOpen}
        width={584}
        footer={
          <div className="border-t border-t-antd-colorBorderSecondary p-2 flex justify-between w-full">
            {pulledImages && (
              <SelectAllButton
                setSelectedItems={setSelectedImages}
                selectedItems={selectedImages}
                allItems={pulledImages}
              />
            )}
            <div className="ml-auto flex gap-2">
              <Button onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={pulledImages ? !selectedImages.length : !isURLValid}
                onClick={clickHandler}
                loading={isLoading}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        }
      >
        {pulledImages ? (
          <div className="max-h-[50vh] overflow-auto">
            {pulledImages.length ? (
              <ImageGallery
                setSelectedImages={setSelectedImages}
                selectedImages={selectedImages}
                images={pulledImages}
                onError={incrementMissing}
              />
            ) : (
              <Empty description="No images found" />
            )}
          </div>
        ) : (
          <div className="p-4 space-y-2">
            <label className="font-medium" htmlFor="website-1">
              Website URL
            </label>
            <Input
              placeholder="Drop a link to your website, blog post, news article, etc."
              onChange={(e) => setInputValue(e.target.value)}
              status={inputValue && !isURLValid ? "error" : undefined}
              disabled={isLoading}
              value={inputValue}
              id="website-1"
            />
            {inputValue && !isURLValid && (
              <span className="warning">Please enter a valid website link</span>
            )}
            {isURLValid && (
              <CrawlWebsiteToggle
                handleChange={(e: RadioChangeEvent) =>
                  setCrawlEntireSite(e.target.value)
                }
                checked={crawlEntireSite}
              />
            )}
          </div>
        )}

        <MissingImagesMessage className="px-3 -mt-1 mb-3" />
      </Modal>
      <DiscardChangesConfirmation
        buttonText="Keep Selecting"
        confirmationOpen={showDiscardChangesModal}
        setConfirmationOpen={setShowDiscardChangesModal}
        discardChanges={closeModal}
      />
    </>
  );
};

export default AddImagesFromWebsite;
