import { Collapse, Modal } from "antd";

type IntegrationNoteModalProps = {
  close: () => void;
  message: string;
  isOpen: boolean;
};

const IntegrationNoteModal = ({
  message,
  isOpen,
  close,
}: IntegrationNoteModalProps) => {
  const items = [
    {
      key: 1,
      label: "1. Create a Instagram business account",
      children: (
        <ul>
          <li>
            <strong>Log into your Instagram Account:</strong> Open the Instagram
            app or go to <a href="https://www.instagram.com/">Instagram.com</a>{" "}
            and log in with your credentials.
          </li>
          <li>
            <strong>Go to your Profile:</strong> Tap on your profile picture or
            click on your profile name to access your profile page.
          </li>
          <li>
            <strong>Access Settings:</strong> On your profile page, click the
            three horizontal lines (menu icon) in the top right corner, then
            select &apos;Settings.&apos;
          </li>
          <li>
            <strong>Switch to a Business Account:</strong> In
            &apos;Settings,&apos; scroll down and select &apos;Account.&apos;
            Under &apos;Account,&apos; you&apos;ll find an option called
            &apos;Switch to Professional Account&apos; or &apos;Switch to
            Business Account.&apos; Click on it.
          </li>
          <li>
            <strong>Choose a Business Category:</strong> Instagram will ask you
            to choose a business category that best describes your web app or
            services.
          </li>
        </ul>
      ),
    },
    {
      key: 2,
      label: "2. Create a Facebook business page",
      children: (
        <ul>
          <li>Log in to your personal Facebook account.</li>
          <li>
            Go to the Facebook Business Page creation page:{" "}
            <a
              href="https://www.facebook.com/pages/create"
              rel="noreferrer"
              target="_blank"
            >
              https://www.facebook.com/pages/create
            </a>
            .
          </li>
          <li>
            You can skip all the setup for now (you can always come back to
            this)
          </li>
          <li>
            Click &apos;Create Page&apos; to publish your Facebook business
            page.
          </li>
        </ul>
      ),
    },
    {
      key: 3,
      label: "3. Connect your facebook page to your Instagram account",
      children: (
        <ul>
          <li>
            Log in to your Facebook account and make sure you have
            administrative access to the Facebook Page you want to connect to
            your Instagram account.
          </li>
          <li>
            Go to your Facebook Page&apos;s settings by clicking on the settings
            icon or the &apos;Settings&apos; link located on your Page.
          </li>
          <li>
            Find &apos;Business Meta Suite&apos; in the left-hand menu and click
          </li>
          <li>
            In the settings menu, scroll down to the &apos;Instagram&apos;
            option and click &apos;Connect&apos;.
          </li>
          <li>
            Click on the &apos;Connect Account&apos; or &apos;Add Account&apos;
            button to initiate the connection process.
          </li>
          <li>
            You will be prompted to log in to your Instagram account. Enter your
            Instagram username and password and click &apos;Log In.&apos;
          </li>
          <li>
            Follow the on-screen instructions to authorize the connection
            between your Facebook Page and Instagram account. This usually
            involves granting permissions for sharing content between the two
            platforms.
          </li>
          <li>
            After the connection is successfully authorized, you can configure
            the settings for what content you want to share between Facebook and
            Instagram, such as posts and stories.
          </li>
          <li>
            Save your settings, and your Instagram account is now connected to
            your Facebook Page.
          </li>
        </ul>
      ),
    },
  ];
  return (
    <Modal
      open={isOpen}
      className="integration-note-modal"
      footer={null}
      title="Oops! Error connecting your Instagram"
      centered
      onCancel={close}
    >
      <p>{message}</p>
      <p>
        <strong>Common Fixes</strong>: Please make <em>sure</em> that
        <ol>
          <li>
            You have a facebook page and a business/professional Instagram
            account
          </li>
          <li>Your Instagram account is connected to your Facebook page</li>
          <li>
            You have selected the proper pages and accounts in instagram
            connection flow (or just to be safe, select &quot;Opt in to all
            current and future accounts&quot;)
          </li>
        </ol>
      </p>

      <div className="integration-note-modal__notes">
        <Collapse ghost accordion items={items} expandIconPosition="end" />
      </div>
    </Modal>
  );
};

export default IntegrationNoteModal;
