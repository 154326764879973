import {
  Button,
  DatePicker,
  DatePickerProps,
  message,
  Modal,
  ModalProps,
  TimePicker,
} from "antd";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import dayjs, { Dayjs } from "dayjs";
import { LONG_DATE_FORMAT } from "fixtures/globalConstants";
import { range } from "lodash";
import pluralize from "pluralize";
import { DisabledTimes } from "rc-picker/lib/interface";
import { MouseEventHandler, useMemo, useState } from "react";
import { Post } from "store/posts/postConstants";
import useUpdatePostActions from "../utils/useUpdatePostActions";

// Must be less than 60
const MIN_MINUTES_IN_FUTURE = 3;

export type ScheduleCustomTimeModalProps = {
  refetchAfterSchedule?: boolean;
  publishAt?: Dayjs;
  onCompleteSchedule?: () => void;
} & Either<{ post: Post }, { getPostToSchedule: () => Promise<Post | void> }>;

const ScheduleCustomTimeModal = ({
  post,
  publishAt,
  refetchAfterSchedule,
  getPostToSchedule,
  onCompleteSchedule,
  ...props
}: ScheduleCustomTimeModalProps & ModalProps) => {
  const { schedulePost } = useUpdatePostActions();
  const [scheduledDate, setScheduledDate] = useState(publishAt);
  const { hasPermission } = useUserPermissions();

  const canSchedulePosts = hasPermission("post_status_schedule_write");
  const [loading, setLoading] = useState(false);

  const confirmSchedule: MouseEventHandler = async (e) => {
    // Re-validate date in case user has waited at least 1 minute before clicking submit
    if (!validateDate()) {
      message.error(
        `Scheduled publish date must be at least ${pluralize("minute", MIN_MINUTES_IN_FUTURE, true)} in the future.`
      );
      return;
    } else if (!scheduledDate) {
      return;
    }

    setLoading(true);

    const savedPost = getPostToSchedule ? await getPostToSchedule() : post;

    if (!savedPost) {
      message.error("Unable to save post before scheduling, please try again.");
      setLoading(false);
      return;
    }

    const postScheduled = await schedulePost({
      post: savedPost,
      scheduleType: "schedule-custom",
      publishTimestamp: scheduledDate.unix(),
      refetchAfterSchedule,
    });

    if (postScheduled) {
      props.onClose && props.onClose(e);
      onCompleteSchedule && onCompleteSchedule();
    }

    setLoading(false);
  };

  const validateDate = () =>
    Boolean(
      scheduledDate?.isAfter(dayjs().add(MIN_MINUTES_IN_FUTURE, "minute"))
    );

  const { dateValid, pickerProps } = useMemo<{
    dateValid: boolean;
    pickerProps: DatePickerProps;
  }>(() => {
    const changeDate = (date?: Dayjs) => {
      if (!scheduledDate && date?.isToday()) {
        setScheduledDate(
          dayjs()
            .second(0)
            .add(MIN_MINUTES_IN_FUTURE + 1, "minutes")
        );
      } else {
        setScheduledDate(date);
      }
    };

    return {
      dateValid: validateDate(),
      pickerProps: {
        value: scheduledDate,
        minDate: dayjs(),
        onChange: changeDate,
      },
    };
  }, [scheduledDate]);

  const disabledTimes = (selectedDate: Dayjs): DisabledTimes => {
    const now = dayjs();

    const disabledMinutesEnd = now.minute() + MIN_MINUTES_IN_FUTURE;
    const nextHourDisabledMinutes =
      disabledMinutesEnd > 59 && disabledMinutesEnd % 60;

    if (nextHourDisabledMinutes === false && !selectedDate.isToday()) {
      return {};
    }

    const disabledMinutes = (hour: number) => {
      let disabled = [] as number[];

      if (selectedDate.isToday() && now.hour() >= hour) {
        disabled =
          now.hour() === hour
            ? range(Math.min(disabledMinutesEnd + 1, 60))
            : range(60);
      } else if (nextHourDisabledMinutes !== false) {
        const nextHourDate = now.add(1, "hour");
        if (
          selectedDate.isSame(nextHourDate, "day") &&
          selectedDate.isSame(nextHourDate, "hour")
        ) {
          disabled = range(nextHourDisabledMinutes + 1);
        }
      }

      return disabled;
    };

    return {
      disabledHours: () => (selectedDate.isToday() ? range(now.hour()) : []),
      disabledMinutes,
    };
  };

  return (
    <Modal
      {...props}
      title="When to post"
      okText={canSchedulePosts ? "Schedule" : "Done"}
      okButtonProps={{
        onClick: confirmSchedule,
        disabled: !dateValid,
        loading,
      }}
      footer={
        canSchedulePosts ? undefined : (
          <Button type="primary" onClick={props.onClose ?? props.onCancel}>
            Done
          </Button>
        )
      }
    >
      <div className="flex py-2 gap-2">
        <DatePicker
          {...pickerProps}
          format={LONG_DATE_FORMAT}
          disabled={!canSchedulePosts}
        />
        <TimePicker
          {...pickerProps}
          use12Hours
          needConfirm={false}
          showSecond={false}
          disabled={!scheduledDate || !canSchedulePosts}
          disabledTime={disabledTimes}
        />
      </div>
    </Modal>
  );
};

export default ScheduleCustomTimeModal;
