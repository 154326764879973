import { message } from "antd";
import env from "config/envVars";
import { PageLoader } from "designSystem/Loader";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import { useAppSelector } from "store";

export const REFERRAL_REROUTE_ROUTE = "/refer";
export const REFERRAL_PARAM = "referral-code";

const ReferralReroute = () => {
  const navigate = useNavigate();
  const referralCode = useSearchParam(REFERRAL_PARAM);

  const {
    userLoading,
    userInfo: { id: userId, isAnonymous },
  } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (userLoading) {
      return;
    }

    if (userId && !isAnonymous) {
      message.info({
        key: "referral-message",
        content: "Existing members cannot be referred.",
      });
      navigate("/", { replace: true });
    } else if (referralCode) {
      Cookies.set(REFERRAL_PARAM, referralCode);
      window.location.replace(`${env.LANDING_PAGE_URL}/pricing`);
    } else {
      navigate("/login", { replace: true });
    }
  }, [userLoading, userId, isAnonymous, referralCode]);

  return <PageLoader large />;
};

export default ReferralReroute;
