import { LoadingOutlined } from "@ant-design/icons";
import { Select, SelectProps } from "antd";
import { SelectedPage } from "api/config/chalice-api";
import { sortBy } from "lodash";
import { useEffect, useMemo } from "react";

const PageSelect = ({
  setSelectedPage,
  selectedPage,
  selectProps,
  isLoading,
  pages,
}: {
  setSelectedPage: (page: SelectedPage) => void;
  selectedPage?: SelectedPage;
  pages: SelectedPage[];
  selectProps?: SelectProps;
  isLoading: boolean;
}) => {
  const options = useMemo(() => {
    if (!pages) {
      return [];
    }

    return sortBy(pages, (page) => page.name.toLowerCase()).map((page) => ({
      label: (
        <div className="flex gap-2 items-center">
          <img
            src={page.picture_url ?? undefined}
            className="w-5 h-5 rounded-full"
          />
          <span className="text-sm truncate">{page.name}</span>
        </div>
      ),
      value: page.id,
      title: page.name,
    }));
  }, [pages]);

  useEffect(() => {
    if (pages?.length === 1 && !selectedPage) {
      setSelectedPage(pages[0]);
    }
  }, [pages]);

  return (
    <Select
      notFoundContent={
        isLoading ? <LoadingOutlined className="mx-auto" /> : "No pages found"
      }
      value={!isLoading ? selectedPage?.id : null}
      onChange={(value) =>
        setSelectedPage(pages.find((x) => x.id === value) as SelectedPage)
      }
      placeholder="Select page"
      loading={isLoading}
      options={options}
      className="!w-60"
      {...selectProps}
    />
  );
};

export default PageSelect;
