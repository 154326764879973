import { observer } from "mobx-react-lite";
import { ImageElementType } from "polotno/model/image-model";
import ImageChanger from "./ImageChanger";
import ImageUploader from "./ImageUpload";

const ImageControls: ObserverComponent = observer(({ store }) => {
  const element = store.selectedElements[0] as ImageElementType;

  return (
    <div className="image-controls">
      {/* @ts-expect-error bad types */}
      <ImageUploader element={element} />
      <ImageChanger {...{ store, element }} />
    </div>
  );
});

export default ImageControls;
