import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/es/menu/interface";
import { useMemo, useState } from "react";
import { Post, PostScreen } from "store/posts/postConstants";
import useDownloadPostsMenuItems from "../utils/useDownloadPostsMenuItems";

const PostPageOptionMenu = ({
  postList,
  downloadScreens,
  additionalItems,
}: {
  postList?: Post[];
  downloadScreens?: PostScreen[];
  additionalItems?: ItemType[] | ItemType;
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { downloadItem } = useDownloadPostsMenuItems({
    postList,
    downloadScreens,
    setIsDownloading,
  });

  const dropdownItems = useMemo(
    () =>
      additionalItems ? [downloadItem].concat(additionalItems) : [downloadItem],
    [downloadItem, additionalItems]
  );

  return (
    <Dropdown
      disabled={isDownloading}
      menu={{
        className: "min-w-max w-[150px]",
        items: dropdownItems,
      }}
    >
      <Button
        loading={isDownloading}
        size="small"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
};

export default PostPageOptionMenu;
