import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { MemberResponse } from "api/config/chalice-api";
import { getOTCForClientPortal } from "api/user";
import { ENV_NAME } from "config/envVars";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { genericError, UseStateReturn } from "fixtures/globalConstants";
import { useState } from "react";
import { MdOpenInNew, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import EditMemberPermissions from "../EditMemberPermissions/EditMemberPermissions";

const ClientPermissionsColumn = ({
  member,
  loadingPreviewForState: [loadingPreviewFor, setLoadingPreviewFor],
}: {
  member: MemberResponse;
  loadingPreviewForState: UseStateReturn<string | null>;
}) => {
  const navigate = useNavigate();
  const currentBusiness = useAppSelector(currentBusinessGetter);
  const { ownedRealm } = useAppSelector((state) => state.user);
  const { hasPermission } = useUserPermissions();

  const [openEditModal, setOpenEditModal] = useState(false);

  const previewClient = async (userId: string) => {
    if (loadingPreviewFor || !ownedRealm) {
      return;
    }

    try {
      setLoadingPreviewFor(userId);
      const data = await getOTCForClientPortal({
        userId,
        businessId: currentBusiness.id,
      });

      if (ENV_NAME === "PRODUCTION") {
        window.location.href = `https://${ownedRealm.domain}/preview?code=${data.one_time_code}`;
      } else {
        navigate({
          pathname: "/preview",
          search: `code=${data.one_time_code}`,
        });
      }
    } catch (error) {
      genericError(error);
      setLoadingPreviewFor(null);
    }
  };

  return (
    <>
      {loadingPreviewFor === member.id ? (
        <LoadingOutlined />
      ) : (
        <Tooltip title="Preview client portal">
          <MdOpenInNew
            onClick={() => previewClient(member.id)}
            className={
              loadingPreviewFor ? "cursor-not-allowed" : "cursor-pointer"
            }
          />
        </Tooltip>
      )}
      {hasPermission("client_permissions_write") && (
        <Tooltip title="Edit">
          <MdOutlineEdit
            onClick={() => setOpenEditModal(true)}
            className="cursor-pointer"
          />
        </Tooltip>
      )}

      {openEditModal && (
        <EditMemberPermissions
          close={() => setOpenEditModal(false)}
          member={member}
        />
      )}
    </>
  );
};

export default ClientPermissionsColumn;
