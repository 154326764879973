import { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import env, { ApiName } from "config/envVars";
import { OpenApiClientIntercepted } from "fixtures/globalConstants";
import { CURRENT_BUSINESS_ID, JWT_TOKEN } from "fixtures/localStorageKeys";
import OpenAPIClientAxios, { Document } from "openapi-client-axios";
import qs from "qs";
import { Client as ChaliceClient } from "./config/chalice-api";
import { Client as DjangoClient } from "./config/django-api";
import { Client as MediaClient } from "./config/media-api";
import { Client as OpenverseClient } from "./config/openverse-api";
import chaliceSchema from "./config/swagger-schemas/chalice-schema.json";
import djangoSchema from "./config/swagger-schemas/django-schema.json";
import mediaSchema from "./config/swagger-schemas/media-schema.json";
import openverseSchema from "./config/swagger-schemas/openverse-schema.json";

type ClientOptions = {
  baseUrlSuffix?: string;
  extractData?: boolean;
  addBusinessId?: boolean;
  noToken?: boolean;
  retries?: number;
};

const CLIENT_DEFINITION: Record<ApiName, Document> = {
  DJANGO_API: djangoSchema as Document,
  MEDIA_API: mediaSchema as Document,
  CHALICE_API: chaliceSchema as Document,
  OPENVERSE_API: openverseSchema as Document,
};

const addStandardIntercepts = (
  client: AxiosInstance,
  options?: ClientOptions
) => {
  client.interceptors.request.use((config) => {
    const token = options?.noToken
      ? undefined
      : localStorage.getItem(JWT_TOKEN);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (options?.addBusinessId) {
      const businessId = localStorage.getItem(CURRENT_BUSINESS_ID);
      config.headers["X-Business-Id"] = businessId;
    }

    return config;
  });

  options?.retries && axiosRetry(client, { retries: options.retries });

  client.interceptors.response.use(
    (response) => (options?.extractData ? response.data : response),
    (error) => {
      console.error(error);
      throw error;
    }
  );
};

type OpenApiInstance<T> = OpenApiClientIntercepted & T;

export const createOpenApiInstance = async <T>(
  apiName: ApiName,
  { baseUrlSuffix = "", ...options }: ClientOptions = {}
) => {
  const instancePromise = new OpenAPIClientAxios({
    definition: CLIENT_DEFINITION[apiName],
    axiosConfigDefaults: {
      baseURL: `${env[apiName]}${baseUrlSuffix}`,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "comma" }),
    },
  });
  const instance = (await instancePromise.init<T>()) as OpenApiInstance<T>;
  addStandardIntercepts(instance, options);
  return instance;
};

let djangoClient: OpenApiInstance<DjangoClient>;
const djangoInstance = createOpenApiInstance<DjangoClient>("DJANGO_API", {
  addBusinessId: true,
});

let mediaClient: OpenApiInstance<MediaClient>;
const mediaInstance = createOpenApiInstance<MediaClient>("MEDIA_API", {
  addBusinessId: true,
  retries: 3,
});

// TODO: Once chaliceApi docs have all methods, can do chaliceClient.methodName
// the ChaliceClient type will include `/api/v1` in the URL when calling methodName directly
let chaliceClient: OpenApiInstance<ChaliceClient>;
const chaliceInstance = createOpenApiInstance<ChaliceClient>("CHALICE_API", {
  baseUrlSuffix: "/api/v1",
  extractData: true,
});

let openverseClient: OpenApiInstance<OpenverseClient>;
const openverseInstance = createOpenApiInstance<OpenverseClient>(
  "OPENVERSE_API",
  {
    noToken: true,
  }
);

export const setupApiClients = () =>
  Promise.all([
    chaliceInstance.then((client) => (chaliceClient = client)),
    djangoInstance.then((client) => (djangoClient = client)),
    mediaInstance.then((client) => (mediaClient = client)),
    openverseInstance.then((client) => (openverseClient = client)),
  ]);

export { chaliceClient, djangoClient, mediaClient, openverseClient };
