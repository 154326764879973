import { ModalProps } from "antd";
import classNames from "classnames";

export type OnboardingModalTitleProps = {
  title?: string | ModalProps["title"];
  header: string;
  subHeader?: string;
  centerTitle?: boolean;
};

const OnboardingModalTitle = ({
  title,
  header,
  subHeader,
  centerTitle,
}: OnboardingModalTitleProps) => (
  <div
    className={classNames("mt-2 space-y-5", {
      "flex flex-col items-center": centerTitle,
    })}
  >
    {title}
    <div className="space-y-1">
      <h3>{header}</h3>
      {subHeader && <h6>{subHeader}</h6>}
    </div>
  </div>
);

export default OnboardingModalTitle;
