import { Button, Tooltip } from "antd";
import { default as cx } from "classnames";
import BusinessSelector from "components/BusinessSelector";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import LogoLink from "config/theme/LogoLink";
import { useEffect, useMemo } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { GoRocket } from "react-icons/go";
import { useAppDispatch, useAppSelector } from "store";
import { setShowOnboardingTour } from "store/user/userSlice";
import SidebarBottomMenu from "./SidebarBottomMenu";
import SidebarNavItems from "./SidebarNavItems";

const SidebarMenu = () => {
  const onboardingTourEnabled = useTypedFeatureIsOn("onboarding-tour");

  const {
    sidebarCollapsedState: [isSidebarCollapsed, setIsSidebarCollapsed],
  } = useAppContext();

  const {
    userInfo: { subscription },
    isAgencyMember,
    showOnboardingTour,
  } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const isTourButtonVisible = useMemo(
    () =>
      onboardingTourEnabled &&
      subscription?.plan === "free" &&
      !showOnboardingTour &&
      !isAgencyMember,
    [isAgencyMember, showOnboardingTour, subscription?.plan]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === "[" &&
        !["INPUT", "TEXTAREA"].includes((event.target as Element).tagName)
      ) {
        setIsSidebarCollapsed((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div
      className={cx("sidebar-nav [grid-area:sidebar] slide-in flex flex-col", {
        "sidebar-nav--collapsed": isSidebarCollapsed,
      })}
    >
      <Tooltip title={isSidebarCollapsed ? "Expand" : "Collapse"}>
        <Button
          icon={isSidebarCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
          onClick={() => setIsSidebarCollapsed((prev) => !prev)}
          className="sidebar-nav__collapse-btn"
          type="default"
          shape="circle"
        />
      </Tooltip>
      <div className="sidebar-nav__top">
        <LogoLink />
      </div>

      <div className="sidebar-nav__items min-h-0">
        <BusinessSelector />
        <SidebarNavItems />
      </div>

      <div className="mt-auto">
        {isTourButtonVisible && (
          <Button
            onClick={() => dispatch(setShowOnboardingTour("force"))}
            style={{ width: 218, marginLeft: 16, marginBottom: 12 }}
            className="desktop-sidebar-btn"
            icon={<GoRocket />}
          >
            Product Tour
          </Button>
        )}
        <SidebarBottomMenu />
      </div>
    </div>
  );
};

export default SidebarMenu;
