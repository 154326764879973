import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import WatermarkTooltip from "./WatermarkTooltip";

const CaptionWatermark = ({ watermark }: { watermark: string }) => {
  const [textTooltipOpen, setTextTooltipOpen] = useState(false);

  return (
    watermark && (
      <div className="flex justify-between cursor-pointer py-1 px-3 border border-t-0 rounded-b border-antd-colorBorder bg-antd-colorBgContainerDisabled text-antd-colorTextSecondary">
        <WatermarkTooltip
          className="flex-grow"
          placement="topLeft"
          trigger="click"
          onOpenChange={setTextTooltipOpen}
        >
          {watermark}
        </WatermarkTooltip>
        <WatermarkTooltip suppress={textTooltipOpen}>
          <InfoCircleOutlined />
        </WatermarkTooltip>
      </div>
    )
  );
};

export default CaptionWatermark;
