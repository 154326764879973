import { useQuery } from "@tanstack/react-query";
import { Button } from "antd";
import { setAuthToken } from "api/config/axiosConfig";
import { getPreviewToken } from "api/user";
import useAppContext from "config/AppContext/useAppContext";
import env, { ENV_NAME } from "config/envVars";
import { PageLoader } from "designSystem/Loader";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { getUserDetails } from "store/user/userActions";
import { autoSetCurrentBusiness } from "store/user/userSlice";

const Preview = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const defaultBusinessRoute = useDefaultBusinessRoute();

  const {
    previewModeState: [, setPreviewMode],
  } = useAppContext();
  const code = searchParams.get("code");
  const isExpired = searchParams.get("expired");

  const { data: token, isError } = useQuery({
    queryKey: ["previewToken"],
    queryFn: async () => {
      const { jwt_token: token } = await getPreviewToken(code!);
      return token;
    },
    retry: false,
    enabled: Boolean(code),
  });

  useEffect(() => {
    const handleTokenSetup = async () => {
      if (token && !isExpired) {
        localStorage.setItem("token", token);
        setAuthToken(token);
        await dispatch(getUserDetails());
        dispatch(autoSetCurrentBusiness());
        navigate(defaultBusinessRoute);
        setPreviewMode(true);
      }
    };
    handleTokenSetup();
  }, [token]);

  const exitPreview = () => {
    if (ENV_NAME === "PRODUCTION") {
      window.location.href = env.FE_BASE_URL;
    } else {
      navigate("/login");
    }
  };

  return (isError && !token) || isExpired ? (
    <div className="h-dvh pb-40 w-dvw flex flex-col gap-4 justify-center items-center">
      <p className="text-xl font-medium text-antd-colorTextSecondary">
        {isExpired
          ? "Client preview session has expired."
          : "Something went wrong. Please go back to the white label settings page and try again."}
      </p>
      <Button type="primary" onClick={exitPreview}>
        Return to Agency
      </Button>
    </div>
  ) : (
    <PageLoader large />
  );
};

export default Preview;
