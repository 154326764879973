import { Tag } from "antd";
import classNames from "classnames";

const PercentChangeIndicator = ({
  currentValue,
  previousValue,
}: {
  currentValue: number;
  previousValue: number | undefined;
}) => {
  const percentChange = previousValue
    ? Math.round(
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100
      )
    : previousValue === 0
      ? currentValue === 0
        ? 0
        : 100
      : null;

  return (
    percentChange !== null && (
      <Tag
        className={classNames("border h-fit m-0", {
          "text-antd-colorSuccess bg-antd-colorSuccessBg border-antd-colorSuccessBorder":
            percentChange > 0,
          "text-antd-colorError bg-antd-colorErrorBg border-antd-colorErrorBorder":
            percentChange < 0,
          "text-antd-colorWarn bg-antd-colorWarnBg border-antd-colorWarnBorder":
            percentChange === 0,
        })}
      >
        {`${percentChange > 0 ? "+" : ""}${percentChange}%`}
      </Tag>
    )
  );
};

export default PercentChangeIndicator;
