import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Modal, Tag, message } from "antd";
import { fetchStockImages } from "api/media";
import useDebounce from "hooks/useDebounce";
import { sample } from "lodash";
import DiscardChangesConfirmation from "pages/Posts/PostEditor/DiscardChangesConfirmation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "store";
import { LibraryImage } from "store/imageLibrary/imageLibraryConstants";
import { imageSearchTermsGetter } from "store/user/userSlice";
import AddImagesFromWebsite from "../AddImagesFromWebsite";
import ImageDropZone from "../ImageDropZone";
import ImageGallery from "../ImageGallery";
import { SHIFT_CLICK_MESSAGE } from "../imageLibraryFixtures";
import useImageLibrary from "../useImageLibrary";
import StockModalFooter from "./StockModalFooter";

// TODO: Some duplicate code/logic with AddImagesFromWebsite, extract this to a general modal component
const AutoFindStockModal = ({
  isOnlyMediaLibrarySource,
  isGlobal,
  isOpen,
  close,
}: {
  isOnlyMediaLibrarySource?: boolean;
  isOpen: boolean;
  close: () => void;
  isGlobal?: boolean;
}) => {
  const imageSearchTerms = useAppSelector(imageSearchTermsGetter) as string[];

  const { imagesInLibrary, isLoadingImages } = useAppSelector(
    (state) => state.imageLibrary
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
  const [isImportFromWebsiteModalOpen, setIsImportFromWebsiteModalOpen] =
    useState(false);
  const [areImagesLoading, setAreImagesLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState<LibraryImage[]>([]);
  const [searchedImages, setSearchedImages] = useState<LibraryImage[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [images, setImages] = useState<LibraryImage[]>([]);
  const [page, setPage] = useState(1);

  const debouncedSearchQuery = useDebounce(
    searchInput,
    searchInput === "" ? 0 : 700
  );

  const imageList = debouncedSearchQuery ? searchedImages : images;

  const title = useMemo(() => {
    if (isGlobal) {
      return imagesInLibrary?.length > 1
        ? "Library Is Low On Images"
        : "Library Is Empty";
    } else {
      return "Add Stock Images";
    }
  }, [isGlobal, imagesInLibrary]);

  const subtitle = useMemo(() => {
    if (isGlobal) {
      return isOnlyMediaLibrarySource
        ? "Please add images to your image library or enable additional image sources."
        : imagesInLibrary?.length > 1
          ? "You're running low on images. We recommend adding these stock photos to your image library to use in your posts."
          : "To generate new posts, please add images to your image library.";
    } else {
      return "Add stock photos to your image library to use in your posts.";
    }
  }, [isGlobal, imagesInLibrary, isOnlyMediaLibrarySource]);

  const closeModal = () => {
    close && close();
    setSelectedImages([]);
    setSearchedImages([]);
    setSearchInput("");
    setImages([]);
  };

  const fetchSearchedPhotos = useCallback(async () => {
    if (!debouncedSearchQuery) {
      return;
    }

    setAreImagesLoading(true);

    try {
      const stockImages = await fetchStockImages(debouncedSearchQuery, page);
      setSearchedImages((prev) =>
        page === 1 ? stockImages : prev.concat(stockImages)
      );
    } catch (error) {
      console.error(error);
      message.error("Unable to fetch images. Please try again.");
    } finally {
      setAreImagesLoading(false);
    }
  }, [debouncedSearchQuery, page, setSearchedImages]);

  const fetchImages = async () => {
    setAreImagesLoading(true);

    try {
      const stockImages = await fetchStockImages(
        sample(imageSearchTerms) ?? imageSearchTerms[0],
        page
      );
      setImages((prev) => prev.concat(stockImages));
    } catch (error) {
      console.error(error);
    }
    setAreImagesLoading(false);
  };

  const { handleImageUpload } = useImageLibrary();

  const handleCancel = () =>
    selectedImages.length ? setShowDiscardChangesModal(true) : closeModal();

  useEffect(() => {
    if (isOpen) {
      debouncedSearchQuery ? fetchSearchedPhotos() : fetchImages();
    }
  }, [page, isOpen, debouncedSearchQuery, imageSearchTerms]);

  useEffect(() => {
    setPage(1);
    setSearchedImages([]);
    setSelectedImages([]);
  }, [debouncedSearchQuery]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 300 &&
      !isLoadingImages &&
      !areImagesLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [isOpen, handleScroll]);

  if (isImportFromWebsiteModalOpen) {
    return (
      <AddImagesFromWebsite
        close={() => setIsImportFromWebsiteModalOpen(false)}
        isOpen={isImportFromWebsiteModalOpen}
      />
    );
  }

  return (
    <>
      <Modal
        centered
        classNames={{
          content: "!p-0",
          body: "!px-0 !pt-2 flex flex-col gap-4 h-[70vh]",
          footer: "space-x-2 !p-2 !-mt-0",
        }}
        onCancel={handleCancel}
        title={
          <div className="global-modal-header p-4 border-b border-antd-colorBorderSecondary">
            <h3>{title}</h3>
            <p>
              {subtitle} {SHIFT_CLICK_MESSAGE}
            </p>
          </div>
        }
        open={isOpen}
        width={696}
        footer={
          <StockModalFooter
            setIsImportFromWebsiteModalOpen={setIsImportFromWebsiteModalOpen}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            handleCancel={handleCancel}
            closeModal={closeModal}
            images={imageList}
            isGlobal={isGlobal}
          />
        }
      >
        <div className="px-4 space-y-2">
          <Input
            onChange={(e) => setSearchInput(e.target.value)}
            className="w-full"
            placeholder="Search for images"
            prefix={<SearchOutlined />}
            value={searchInput}
          />

          {imageSearchTerms.length > 0 && (
            <div className="flex flex-wrap gap-y-2">
              {imageSearchTerms.map((searchTerm) => (
                <Tag
                  className="cursor-pointer"
                  key={searchTerm}
                  onClick={() => setSearchInput(searchTerm)}
                >
                  {searchTerm}
                </Tag>
              ))}
            </div>
          )}
        </div>

        {debouncedSearchQuery &&
          !searchedImages.length &&
          !areImagesLoading && <Empty description="No images found" />}

        <ImageDropZone
          scrollRef={containerRef}
          onDropAccepted={handleImageUpload}
          className="h-full border-y border-antd-colorBorderSecondary"
          noClick
        >
          <ImageGallery
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            loading={areImagesLoading}
            images={imageList}
          />
        </ImageDropZone>
      </Modal>
      <DiscardChangesConfirmation
        buttonText="Keep Selecting"
        confirmationOpen={showDiscardChangesModal}
        setConfirmationOpen={setShowDiscardChangesModal}
        discardChanges={closeModal}
      />
    </>
  );
};

export default AutoFindStockModal;
