import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, message, Tag, Tooltip } from "antd";
import { deleteIntegration } from "api/integrations";
import classNames from "classnames";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import PremiumFeatureModal, {
  PremiumFeatureIcon,
} from "components/GlobalModals/PremiumFeatureModal";
import { genericError } from "fixtures/globalConstants";
import { buildAuthURL, TWITTER_APPS } from "fixtures/socialAuthConfig";
import useInstagramConnection from "hooks/useInstagramConnection";
import { sample } from "lodash";
import { useMemo, useState } from "react";
import { BsGear } from "react-icons/bs";
import { GoUnlink } from "react-icons/go";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { IntegrationType } from "store/user/userConstants";
import { integrationsGetter, removeIntegration } from "store/user/userSlice";
import { formatSocialName } from "utils/generalUtils";
import InstagramNoteModal from "./InstagramNoteModal";
import {
  INTEGRATIONS_WITH_SETTINGS,
  IntegrationWithSettings,
} from "./IntegrationSettings/settingConstants";

type IntegrationItemProps = {
  title: string;
  integrationType: IntegrationType;
  loadingItem: IntegrationType | null;
  icon: string;
  isComingSoon?: boolean;
  maxSocialsReached?: boolean;
  setShowSettingsModalFor: (integrationType: IntegrationWithSettings) => void;
  isAnalyticsUnavailable?: boolean;
  setLoadingItem: (loadingItem: IntegrationType | null) => void;
};

const IntegrationItem = ({
  setShowSettingsModalFor,
  maxSocialsReached,
  isComingSoon,
  loadingItem,
  title,
  integrationType,
  icon,
  isAnalyticsUnavailable,
  setLoadingItem,
}: IntegrationItemProps) => {
  const { handleInstagramConnection, contextHolder } =
    useInstagramConnection(setLoadingItem);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { openGlobalModal } = useGlobalModal();

  const allBusinesses = useAppSelector((state) => state.user.businesses);
  const connectedSocials = useAppSelector(integrationsGetter);

  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState<
    "facebook-note" | "premium-feautre" | null
  >(null);

  const currentSocial = connectedSocials.find(
    ({ type }) => type === integrationType
  );

  const isLoading = useMemo(() => {
    return loadingItem === integrationType;
  }, [loadingItem, integrationType]);

  const isConnected = useMemo(() => {
    return connectedSocials.some(({ type }) => type === integrationType);
  }, [integrationType, connectedSocials]);

  const isExpired = useMemo(() => {
    return !!currentSocial?.refresh_token_expired;
  }, [integrationType, connectedSocials, currentSocial]);

  const accountName = useMemo(() => {
    return (
      currentSocial?.selected_page?.name ||
      currentSocial?.username ||
      "Connected"
    );
  }, [currentSocial]);

  const buttonText = useMemo(() => {
    if (isComingSoon) {
      return "In Development";
    }

    if (isExpired) {
      return "Reconnect";
    }

    if (!isConnected) {
      return "Connect";
    }

    return accountName;
  }, [isComingSoon, isExpired, isConnected, accountName]);

  const tooltipText = useMemo(() => {
    if (!isExpired) {
      return;
    }
    const integrationName = formatSocialName(integrationType);
    return `Your ${integrationName} integration has expired. Please connect your ${integrationName} account again`;
  }, [isExpired, isConnected]);

  const appName = useMemo(() => {
    if (integrationType !== "twitter") {
      return "app_2";
    }

    const allIntegrations = allBusinesses.flatMap(
      (business) => business.integrations
    );

    const unusedApps = TWITTER_APPS.filter(
      (app) =>
        !allIntegrations.some(
          (integration) => integration.publish_settings?.appName === app
        )
    );

    if (unusedApps.length) {
      return unusedApps[0];
    }

    return sample(TWITTER_APPS);
  }, [integrationType, allBusinesses]);

  const handleConnect = () => {
    if (isComingSoon) {
      return;
    }

    if (localStorage.isAnonymous) {
      return openGlobalModal({
        modalName: "signup",
        modalData: { successCallback: handleConnect },
      });
    }

    if (["instagram", "instagramStory", "facebook"].includes(integrationType)) {
      handleInstagramConnection(integrationType, () =>
        setShowModal("facebook-note")
      );
      return;
    }

    window.location.href = buildAuthURL({
      socialType: integrationType,
      appName,
      pathname,
    });
  };

  const handleDelete = async () => {
    if (!currentSocial) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteIntegration(currentSocial?.id);
      message.success(
        `${formatSocialName(integrationType)} integration removed successfully.`
      );
      dispatch(removeIntegration(integrationType));
    } catch (error) {
      genericError(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="p-4 flex items-center justify-between">
      {contextHolder}
      <div className="flex items-center gap-2.5">
        <img
          className={classNames("size-6 lg:size-9", {
            "object-cover !h-12": integrationType === "tiktok",
          })}
          src={icon}
          alt={integrationType}
        />
        <span className="text-sm capitalize">{title}</span>
      </div>
      {isLoading && <Skeleton width={87} height={40} />}
      {!isLoading && (
        <div className="flex items-center gap-3">
          {!isConnected && (
            <Tooltip title={tooltipText}>
              <Button
                danger={isExpired}
                className={classNames({
                  "pointer-events-none": isComingSoon,
                })}
                onClick={() =>
                  maxSocialsReached
                    ? setShowModal("premium-feautre")
                    : handleConnect()
                }
                disabled={isComingSoon}
              >
                {maxSocialsReached && <PremiumFeatureIcon />}
                {buttonText}
              </Button>
            </Tooltip>
          )}
          {isConnected && (
            <div className="flex flex-col items-center gap-1.5">
              <Tag color="success" className="max-w-[150px] truncate mx-0">
                {accountName}
              </Tag>
              {isAnalyticsUnavailable && (
                <div className="flex items-center gap-1">
                  <span className="text-xs text-antd-colorTextSecondary">
                    Analytics unavailable
                  </span>
                  <Tooltip title="This platform does not provide analytics for this connection.">
                    <InfoCircleOutlined className="text-antd-colorIcon !size-3.5" />
                  </Tooltip>
                </div>
              )}
            </div>
          )}
          {isConnected &&
            INTEGRATIONS_WITH_SETTINGS.includes(
              integrationType as IntegrationWithSettings
            ) && (
              <BsGear
                className="cursor-pointer"
                onClick={() =>
                  setShowSettingsModalFor(
                    integrationType as IntegrationWithSettings
                  )
                }
              />
            )}
          {isConnected && (
            <Tooltip title="Disconnect">
              <Button
                danger
                onClick={handleDelete}
                loading={isDeleting}
                icon={<GoUnlink />}
              />
            </Tooltip>
          )}
        </div>
      )}

      <InstagramNoteModal
        close={() => setShowModal(null)}
        isOpen={showModal === "facebook-note"}
        integrationName={integrationType}
        isError={undefined}
      />
      <PremiumFeatureModal
        title="You've reached your social limit"
        content="Upgrade your account to unlock more social connections along with additional features."
        open={showModal === "premium-feautre"}
        onCancel={() => setShowModal(null)}
        onClose={() => setShowModal(null)}
      />
    </div>
  );
};

export default IntegrationItem;
