import cx from "classnames";
import AvatarInitials from "components/AvatarInitials";
import { CURRENT_BUSINESS_ID } from "fixtures/localStorageKeys";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Business } from "store/business/businessConstants";
import { resetPostData } from "store/posts/postSlice";
import { setCurrentBusiness } from "store/user/userSlice";

const BusinessProfileIcon = (business: Business) => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    localStorage.setItem(CURRENT_BUSINESS_ID, business.id);
    dispatch(setCurrentBusiness(business.id));
    dispatch(resetPostData());
    navigate(defaultBusinessRoute);
  }, [defaultBusinessRoute]);

  return (
    <div
      className={cx("flex business-table__profile", {
        "flex business-table__profile--disabled": false,
      })}
      onClick={handleClick}
    >
      <AvatarInitials size={36} business={business} />
      <span className="business-title">{business?.title}</span>
    </div>
  );
};

export default BusinessProfileIcon;
