import { Tooltip } from "antd";
import { ReviewStatus } from "api/config/chalice-api";
import classNames from "classnames";
import { IconType } from "react-icons";
import {
  MdBookmarkBorder,
  MdCheckCircleOutline,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
  MdOutlineSchedule,
} from "react-icons/md";
import { PostCount } from "store/business/businessConstants";

type PostStatusType = ReviewStatus | "LIKED" | "SCHEDULED";

const POST_STATUS_CONFIG: Record<
  PostStatusType,
  {
    Icon: IconType;
    tailwindClasses: string;
    tooltip: string;
    alwaysShow?: boolean;
  }
> = {
  LIKED: {
    tooltip: "Drafts",
    Icon: MdBookmarkBorder,
    tailwindClasses: "bg-antd-colorFillSecondary text-antd-colorTextSecondary",
    alwaysShow: true,
  },
  SCHEDULED: {
    tooltip: "Scheduled",
    Icon: MdOutlineSchedule,
    tailwindClasses: "bg-antd-colorFillSecondary text-antd-colorTextSecondary",
    alwaysShow: true,
  },
  READY_FOR_REVIEW: {
    tooltip: "In Review",
    Icon: MdOutlineRemoveRedEye,
    tailwindClasses: "bg-antd-colorInfo text-antd-colorWhite",
  },
  APPROVED: {
    tooltip: "Approved",
    Icon: MdCheckCircleOutline,
    tailwindClasses: "bg-antd-colorSuccess text-antd-colorWhite",
  },
  CHANGES_REQUESTED: {
    tooltip: "Changes Requested",
    Icon: MdOutlineEdit,
    tailwindClasses: "bg-antd-colorWarning text-antd-colorWhite",
  },
};

const PostStatuses = ({
  postStatuses,
}: {
  postStatuses: Partial<PostCount> & Record<ReviewStatus, number | undefined>;
}) => {
  return (
    <div className="flex gap-2">
      {Object.entries(postStatuses).map(([key, value]) => (
        <PostStatusPill
          statusKey={key as PostStatusType}
          count={value ?? 0}
          key={key}
        />
      ))}
    </div>
  );
};

export const PostStatusPill = ({
  statusKey,
  count,
}: {
  statusKey: PostStatusType;
  count: number;
}) => {
  const { Icon, tailwindClasses, tooltip, alwaysShow } =
    POST_STATUS_CONFIG[statusKey];

  return (
    Boolean(count || alwaysShow) && (
      <Tooltip title={tooltip}>
        <div
          className={classNames(
            tailwindClasses,
            "rounded-2xl text-xs w-max flex items-center py-0.5 px-2 gap-1 max-h-min"
          )}
        >
          <Icon size={16} /> {count}
        </div>
      </Tooltip>
    )
  );
};

export default PostStatuses;
