import { Tooltip, TooltipProps } from "antd";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import { useEffect, useState } from "react";

const WatermarkTooltip = ({
  suppress,
  ...props
}: Omit<TooltipProps, "title"> & { suppress?: boolean }) => {
  const { openGlobalModal } = useGlobalModal();

  const [placeBehindModal, setPlaceBehindModal] = useState(false);

  const openUpgradeModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPlaceBehindModal(true);
    openGlobalModal({ modalName: "subscription" });
  };

  useEffect(() => {
    const resetPlaceBehindModal = () =>
      // Reset to false after a timeout to prevent a brief flicker of the tooltip
      setTimeout(() => setPlaceBehindModal(false), 500);

    document.addEventListener("click", resetPlaceBehindModal);
    return () => document.removeEventListener("click", resetPlaceBehindModal);
  }, []);

  return (
    <Tooltip
      zIndex={placeBehindModal ? 999 : undefined}
      title={
        suppress ? (
          ""
        ) : (
          <>
            This post has Marky watermarks on the design and at the bottom of
            the caption. To remove these watermarks,{" "}
            <span
              className="underline cursor-pointer hover:font-medium"
              onClick={openUpgradeModal}
            >
              upgrade
            </span>{" "}
            to a paid plan.
          </>
        )
      }
      {...props}
    />
  );
};

export default WatermarkTooltip;
