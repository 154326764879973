import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { ImageElementType } from "polotno/model/image-model";
import { useMemo } from "react";
import { CiCrop } from "react-icons/ci";

const CropImage: ObserverComponent<{
  element: ImageElementType;
}> = observer(({ element }) => {
  const initialCropDetails = useMemo(() => {
    return {
      cropHeight: element.cropHeight,
      cropWidth: element.cropWidth,
      cropX: element.cropX,
      cropY: element.cropY,
    };
  }, [element._cropModeEnabled]);

  if (element._cropModeEnabled) {
    return (
      <div className="crop-image-container">
        <Button icon={<CheckOutlined />} />
        <Button
          onClick={() => element.set(initialCropDetails)}
          icon={<CloseOutlined />}
          className="ml-5"
        />
      </div>
    );
  }

  return (
    <div className="crop-image-container">
      <Button
        icon={<CiCrop className="crop-icon" />}
        style={{ height: 32, paddingBlock: 0 }}
        onClick={(e) => {
          element.toggleCropMode();
          e.stopPropagation();
        }}
      >
        Crop
      </Button>
    </div>
  );
});

export default CropImage;
