import { LoadingOutlined } from "@ant-design/icons";
import { message, Slider, Tag } from "antd";
import { Audio } from "api/config/openverse-api";
import dayjs from "dayjs";
import { genericError } from "fixtures/globalConstants";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { MdAddBox, MdOutlinePlayArrow, MdPause } from "react-icons/md";

const AudioItem: ObserverComponent<{
  audioTrack: Audio;
}> = observer(({ audioTrack, store }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTimeUpdate = () => {
    audioRef.current && setCurrentTime(audioRef.current.currentTime);
  };

  const handleSliderChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
    setCurrentTime(value);
  };

  const formatTime = (
    time: number | null | undefined,
    timeUnit: "milliseconds" | "seconds" = "milliseconds"
  ) => (time ? dayjs.duration(time, timeUnit).format("mm:ss") : "00:00");

  const handlePlay = async () => {
    setIsLoading(true);
    const audioElements = document.querySelectorAll("audio");
    audioElements.forEach((audio) => !audio.paused && audio.pause());
    try {
      if (audioRef.current && !isPlaying) {
        await audioRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      if (!(error instanceof Error) || error.name !== "AbortError") {
        // AbortError is thrown if an AudioItem that was awaiting `play()` is then paused
        // by a different AudioItem attempting to play. Don't show error message.
        genericError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addAudio = async () => {
    // `stop` does appear to return a promise, need to await stop before removing audio
    store.isPlaying && (await store.stop());

    store.audios[0]?.id && store.removeAudio(store.audios[0].id);
    store.addAudio({ src: audioTrack.url });
    message.success("Audio added successfully.");
  };

  return (
    <div className="flex items-center border rounded-lg border-antd-colorBorderSecondary p-4">
      <audio
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={handleTimeUpdate}
        src={audioTrack.url as string}
        ref={audioRef}
      />
      <div className="flex flex-col gap-2 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-3 items-center">
            {isLoading ? (
              <LoadingOutlined />
            ) : isPlaying ? (
              <MdPause
                className="cursor-pointer size-5"
                onClick={() => {
                  if (audioRef.current) {
                    audioRef.current.pause();
                  }
                }}
              />
            ) : (
              <MdOutlinePlayArrow
                className="cursor-pointer size-5"
                onClick={handlePlay}
              />
            )}
            <div className="flex flex-col gap-1 max-w-48">
              <span className="truncate">{audioTrack.title}</span>
              <span className="text-antd-colorTextSecondary text-xs truncate">
                {audioTrack.creator}
              </span>
              <div className="flex gap-1 mt-0.5 flex-wrap">
                {audioTrack.genres?.map((genre) => (
                  <Tag key={genre} className="mx-0">
                    {capitalize(genre)}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
          <MdAddBox onClick={addAudio} className="cursor-pointer size-5" />
        </div>
        <div className="flex items-center gap-2 w-full">
          <span className="text-antd-colorTextSecondary w-10">
            {formatTime(currentTime, "seconds")}
          </span>

          <Slider
            max={Math.round((audioTrack.duration as number) / 1000)}
            onChange={handleSliderChange}
            className="flex-grow"
            value={currentTime}
            tooltip={{
              formatter: (value) => formatTime(value as number, "seconds"),
            }}
            min={0}
          />

          <span className="text-antd-colorTextSecondary w-10 text-right">
            {formatTime(audioTrack.duration)}
          </span>
        </div>
      </div>
    </div>
  );
});

export default AudioItem;
