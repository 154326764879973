import { ParsedPublishError } from "api/config/chalice-api";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export type PublishErrorKey = Required<ParsedPublishError>["error_type"];

export const PUBLISH_ERRORS: {
  [key in PublishErrorKey]?: ReactNode;
} = {
  INSUFFICIENT_PERMISSIONS: "",
  TIKTOK_NEEDS_APP_UPDATE:
    "Please update your Tiktok to the latest version to use this functionality.",
  TIKTOK_TOO_MANY_PENDING: (
    <div>
      Unable to post at the moment due to an excess of pending content. Kindly
      visit your TikTok profile to publish some of the queued posts, allowing
      you to share this one seamlessly.{" "}
      <a
        href="https://help.mymarky.ai/en/article/how-to-publish-a-tiktok-draft-grslah/?bust=1708090816777"
        rel="noreferrer"
        target="_blank"
      >
        Instructions on how to publish a draft
      </a>
      .
    </div>
  ),
  ACCOUNT_RESTRICTED: "",
  TOO_MANY_HASHTAGS: (
    <div>
      Too many hashtags. Please reduce the number of hashtags on the{" "}
      <Link to="/business">Business Page</Link>
    </div>
  ),
  BAD_LINK: "Please remove link from the caption.",
  UNCONFIRMED_USER: (
    <div>
      Please log in at{" "}
      <a
        href="https://www.facebook.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        Facebook
      </a>{" "}
      to correct an issue.
    </div>
  ),
  UNKNOWN: "",
};
