import { Button, message } from "antd";
import { trackSubscription } from "api/user";
import { ReactComponent as SuccessImage } from "assets/icons/payment-success.svg";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";
import { PLAN_COSTS } from "utils/eventTracking/fixtures/planCosts";
import { trackEvent } from "utils/eventTracking/trackEvents";
import { POSTS_PAGE_ROUTE } from "./Posts/PostsPage";
import { REFERRAL_PARAM } from "./ReferralReroute";

const WelcomeToFamily = () => {
  const [searchParams] = useSearchParams();

  const user = useAppSelector(selectUserInfo);

  const nextPage = useMemo(() => {
    return searchParams.get("nextPage") || POSTS_PAGE_ROUTE;
  }, [searchParams, user]);

  useEffect(() => {
    window.$crisp?.push(["set", "session:event", ["user:started-trial"]]);

    const isReferredBySomeone = Cookies.get(REFERRAL_PARAM);

    Cookies.remove(REFERRAL_PARAM);

    trackEvent("purchase", {
      currency: "USD",
      value:
        (user.subscription?.plan && PLAN_COSTS[user.subscription?.plan]) ||
        PLAN_COSTS.DEFAULT,
      transaction_id: "",
      items: [
        {
          item_name:
            user.subscription?.plan ?? "User missing subscription.plan",
        },
      ],
      content_id: "premium-subscription",
      content_type: "subscription",
      content_name: "Premium Subscription",
      attribution: isReferredBySomeone ? "referral" : undefined,
    });

    message.success("Payment successful!");
  }, []);

  useEffect(() => {
    if (user.email) {
      window.fpr &&
        window.fpr("referral", {
          email: user.email,
          uid: user.subscription?.customer_id,
        });

      trackSubscription({
        email: user.email,
        phone: user.phone ?? undefined,
      });
    }
  }, [user]);

  return (
    <div className="payment-success">
      <div className="payment-success__container">
        <SuccessImage />
        <h3>Welcome to the Marky family!</h3>
        <p>
          We’re excited to partner with you in making your business a slam
          success.
        </p>
        <Link to={nextPage} className="payment-success__buttons">
          <Button type="primary">Get started</Button>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeToFamily;
