import { Palette } from "api/config/chalice-api";
import { HTMLProps } from "react";

const PaletteColorBlocks = ({
  palette,
  ...props
}: { palette: Palette } & HTMLProps<HTMLUListElement>) => (
  <ul className="w-full flex gap-1" {...props}>
    {Object.values(palette).map((color, i) => (
      <li
        style={{ backgroundColor: color }}
        key={i}
        className="size-5 border border-antd-colorBorderSecondary rounded"
      />
    ))}
  </ul>
);

export default PaletteColorBlocks;
