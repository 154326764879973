import {
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ButtonProps } from "@tremor/react";
import { Button, Dropdown } from "antd";
import { DropdownButtonProps } from "antd/es/dropdown/dropdown-button";
import ModalWithBorders from "components/ModalWithBorders";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { genericError } from "fixtures/globalConstants";
import useGetPostDetailsQuery from "hooks/useGetPostDetailsQuery";
import PostInputs from "pages/Posts/components/PostInputs";
import PostMediaRenderer from "pages/Posts/components/PostMediaRenderer";
import SocialsSelect from "pages/Posts/components/SocialsSelect";
import PublishedPostDetail from "pages/Posts/PostEditor/MiniEditor/PublishedPostDetail";
import useScheduleCustomTime, {
  CUSTOM_SCHEDULE_ITEM_KEY,
} from "pages/Posts/ScheduleCustomTime/useScheduleCustomTime";
import useUpdatePostActions from "pages/Posts/utils/useUpdatePostActions";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store";
import { Post, PostStatus } from "store/posts/postConstants";
import { IntegrationType } from "store/user/userConstants";
import {
  currentBusinessGetter,
  integrationsTypeGetter,
} from "store/user/userSlice";
import { trackEvent } from "utils/eventTracking/trackEvents";
import DiscardChangesConfirmation from "../DiscardChangesConfirmation";
import CustomPostMedia from "./CustomPostMedia";
import {
  DROPDOWN_BUTTONS,
  EditorActionKey,
  PostEditingDetails,
  SaveDraftActionKey,
  ScheduleActionKey,
} from "./miniEditorConstants";
import PostCaptionEditor from "./PostCaptionEditor";
import WatermarkTooltip from "./WatermarkTooltip";

type MiniEditorProps = {
  post?: Post;
  editedPostTemplate?: Post["template"];
  refetchAfterSchedule?: boolean;
  close: () => void;
  openFullScreen?: () => void;
  isOpen: boolean;
};

const ACTION_REQUIRES_SAVE: EditorActionKey[] = [
  "save",
  "publish",
  "schedule",
  "schedule-next",
  "schedule-last",
] as const;

const MiniEditorModal = ({
  post: existingPost,
  editedPostTemplate,
  refetchAfterSchedule,
  close,
  openFullScreen,
  isOpen,
}: MiniEditorProps) => {
  const { hasPermission, hasSomePermissions } = useUserPermissions();
  const {
    loading,
    createNewPost,
    savePost,
    duplicatePost,
    schedulePost,
    discardPost,
    downloadPost,
    publishPost,
  } = useUpdatePostActions();

  const connectedSocials = useAppSelector(integrationsTypeGetter);
  const business = useAppSelector(currentBusinessGetter);
  const { image_watermark_enabled: imageWatermark = false } =
    business.owner.subscription?.restrictions ?? {};

  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
  const [mediaUrls, setMediaUrls] = useState([] as string[]);
  const [newPostPublishTo, setNewPostPublishTo] = useState(
    [] as IntegrationType[] | null
  );

  const [caption, setCaption] = useState(existingPost?.caption ?? "");

  const { customTimeMenuItem, CustomTimeModal } = useScheduleCustomTime();

  const getSavedPost = async ({
    showMessage,
    status,
  }: {
    showMessage: boolean;
    status?: PostStatus;
  }) => {
    if (!existingPost) {
      const savedPost = await createNewPost({
        caption,
        mediaUrls,
        publishTo: newPostPublishTo,
        showMessage,
      });

      savedPost &&
        trackEvent("created_custom_post", {
          used_post_editor: false,
          post_type: savedPost.render_design_as,
        });

      return savedPost;
    } else if (
      existingPost.caption !== caption ||
      existingPost.media_urls !== mediaUrls ||
      existingPost.status !== status
    ) {
      return savePost({
        post: existingPost,
        updateBody: {
          caption,
          status,
          media_urls: mediaUrls,
        },
        showMessage,
      });
    }

    return existingPost;
  };

  const isPublishedPost = existingPost?.status === "PUBLISHED";

  const {
    data: postDetail,
    refetch,
    isError,
    isLoading: isLoadingPostData,
    error,
  } = useGetPostDetailsQuery<PostEditingDetails | undefined, false>(
    existingPost?.id,
    {
      refetchOnMount: false,
      select: (post) => {
        if (!post) {
          return;
        }
        const { template, media_urls, inputs } = post;

        return {
          mediaUrls: media_urls,
          template,
          inputs,
        };
      },
    }
  );

  useEffect(() => {
    postDetail?.mediaUrls && setMediaUrls(postDetail.mediaUrls);
  }, [postDetail]);

  const dropdownButtons = useMemo(
    () =>
      DROPDOWN_BUTTONS({
        hasConnectedSocials: Boolean(connectedSocials.length),
        customTimeMenuItem,
        post: existingPost,
      }),

    [existingPost, connectedSocials.length]
  );

  if (isError) {
    genericError(error);
    close();
    return null;
  }

  const confirmClose = () => {
    if (caption !== existingPost?.caption || editedPostTemplate) {
      setShowDiscardChangesModal(true);
    } else {
      close();
    }
  };

  const ACTION_HANDLER_MAP: {
    [key in EditorActionKey]?: (
      post: Post,
      action: EditorActionKey
    ) => Promise<Promise<boolean>>;
  } = {
    download: downloadPost,
    delete: discardPost,
    publish: publishPost,
    duplicate: (post) =>
      duplicatePost({
        ...post,
        caption,
        template: postDetail?.template ?? null,
      }),
    schedule: (post, action) =>
      schedulePost({
        post,
        refetchAfterSchedule,
        scheduleType: action,
      }),
  };

  const handleAction = async (
    action:
      | SaveDraftActionKey
      | ScheduleActionKey
      | typeof CUSTOM_SCHEDULE_ITEM_KEY
  ) => {
    if (action === CUSTOM_SCHEDULE_ITEM_KEY) {
      return;
    }

    const isSaveDraftAction = action === "save";
    const exitAfterSave =
      isSaveDraftAction ||
      (action === "schedule" && existingPost?.status === "SCHEDULED");

    let isSuccess = false;
    let savedPost = existingPost;

    if (!savedPost || ACTION_REQUIRES_SAVE.includes(action)) {
      savedPost = await getSavedPost({
        showMessage: isSaveDraftAction,
        status: isSaveDraftAction ? "LIKED" : undefined,
      });

      isSuccess = Boolean(savedPost);
    }

    if (savedPost && !exitAfterSave) {
      const actionHandler = action.includes("schedule")
        ? ACTION_HANDLER_MAP.schedule
        : ACTION_HANDLER_MAP[action];

      isSuccess = actionHandler
        ? await actionHandler(savedPost, action)
        : false;
    } else if (isSuccess && exitAfterSave) {
      await refetch();
    }

    isSuccess && close();
  };

  return (
    <>
      <ModalWithBorders
        open={isOpen}
        centered
        title={
          isPublishedPost
            ? "Post Details"
            : existingPost
              ? "Edit Post"
              : "Create Post"
        }
        onCancel={confirmClose}
        size="large"
        footer={
          !isPublishedPost &&
          hasSomePermissions([
            "post_status_draft_write",
            "posting_schedule_write",
          ]) ? (
            <div className="w-full flex max-sm:flex-col gap-2 justify-between items-center">
              {(existingPost || connectedSocials.length > 0) && (
                <div className="flex items-center gap-2 pl-2 min-w-max">
                  Publish to{" "}
                  <SocialsSelect
                    setPublishTo={setNewPostPublishTo}
                    post={existingPost}
                  />
                </div>
              )}
              <div className="flex max-sm:flex-col-reverse max-sm:w-full gap-2 [&_*]:w-[unset] ml-auto">
                <Button onClick={close} type="text">
                  Cancel
                </Button>
                {dropdownButtons.map(
                  (
                    {
                      labelIcon,
                      permissionNeeded,
                      items,
                      menuActionsRequirePost,
                      labelActionKey,
                      label,
                      loadingKeys,
                      buttonType,
                    },
                    index
                  ) => {
                    if (!hasPermission(permissionNeeded)) {
                      return null;
                    }

                    const showDropdownItems =
                      existingPost || !menuActionsRequirePost;

                    const commonProps: Pick<
                      DropdownButtonProps & ButtonProps,
                      "loading" | "disabled" | "onClick" | "children"
                    > = {
                      loading: loadingKeys.includes(loading as string),
                      disabled:
                        Boolean(loading) ||
                        (!existingPost && !mediaUrls.length),
                      onClick: () => handleAction(labelActionKey),
                      children: (
                        <div className="flex gap-2 items-center">
                          {labelIcon}
                          {label}
                        </div>
                      ),
                    };

                    return (
                      hasPermission(permissionNeeded) &&
                      (showDropdownItems ? (
                        <Dropdown.Button
                          {...commonProps}
                          key={index}
                          type={buttonType}
                          icon={<DownOutlined />}
                          className="[&_button]:flex-grow"
                          menu={{
                            items,
                            onClick: ({ key }) =>
                              handleAction(
                                key as SaveDraftActionKey | ScheduleActionKey
                              ),
                          }}
                        />
                      ) : (
                        <Button
                          key={index}
                          type={buttonType}
                          {...commonProps}
                        />
                      ))
                    );
                  }
                )}
              </div>
            </div>
          ) : (
            <Button type="primary" onClick={close}>
              Close
            </Button>
          )
        }
      >
        <div className="w-full flex gap-4 flex-col-reverse md:flex-row min-h-80">
          <div className="md:pr-6 flex-grow basis-2/5">
            {isPublishedPost ? (
              <PublishedPostDetail post={existingPost as Post<"PUBLISHED">} />
            ) : (
              <PostCaptionEditor
                captionState={[caption, setCaption]}
                mediaUrls={mediaUrls}
              />
            )}
          </div>

          <div className="text-center flex justify-center bg-antd-colorBgLayout p-6 -mx-4 md:!-mb-4 !-mt-4 md:!ml-0">
            {existingPost && postDetail?.template ? (
              <div className="space-y-2 relative [&_>_span]:absolute [&_>_span]:left-0 [&_>_span]:top-0">
                <PostMediaRenderer
                  openFullScreenEditor={
                    isPublishedPost || !hasPermission("post_design_write")
                      ? undefined
                      : openFullScreen
                  }
                  post={existingPost}
                  inModal
                />

                <div className="flex gap-4">
                  {!isPublishedPost &&
                    openFullScreen &&
                    hasPermission("post_design_write") && (
                      <Button
                        icon={<EditOutlined />}
                        size="small"
                        disabled={Boolean(loading)}
                        onClick={() => openFullScreen()}
                      >
                        Edit Design
                      </Button>
                    )}

                  <div className="ml-auto text-xs text-right flex flex-col gap-1 text-antd-colorTextSecondary">
                    {imageWatermark && (
                      <span className="flex items-center gap-1.5">
                        Post watermarked{" "}
                        <WatermarkTooltip>
                          <InfoCircleOutlined />
                        </WatermarkTooltip>
                      </span>
                    )}
                    <PostInputs showAsPopover postDetail={postDetail} />
                  </div>
                </div>
              </div>
            ) : (
              <CustomPostMedia
                postLoading={isLoadingPostData}
                mediaState={[mediaUrls, setMediaUrls]}
              />
            )}
          </div>
        </div>
      </ModalWithBorders>

      <DiscardChangesConfirmation
        confirmationOpen={showDiscardChangesModal}
        setConfirmationOpen={setShowDiscardChangesModal}
        discardChanges={close}
      />
      <CustomTimeModal
        refetchAfterSchedule={refetchAfterSchedule}
        getPostToSchedule={() => getSavedPost({ showMessage: false })}
        onCompleteSchedule={close}
      />
    </>
  );
};

export default MiniEditorModal;
