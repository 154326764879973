import { UseQueryResult } from "@tanstack/react-query";
import { Badge, Button, Dropdown, message, Tooltip } from "antd";
import { BadgeProps } from "antd/lib";
import { djangoClient } from "api/baseClients";
import { Document, TypeEnum } from "api/config/django-api";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { genericError } from "fixtures/globalConstants";
import NewGeneratePanel from "pages/Posts/components/NewGeneratePanel";
import { ReactNode, useMemo, useState } from "react";
import {
  MdDeleteOutline,
  MdLink,
  MdOutlineEdit,
  MdOutlineFileUpload,
  MdOutlineMoreVert,
  MdOutlineTune,
  MdRefresh,
  MdShortText,
} from "react-icons/md";
import EditTopicOrMemory from "./EditTopicOrMemory";
import { MEMORY_STATUS_MESSAGES } from "./utils";

const MEMORY_TYPE_MAPPING: Record<
  TypeEnum,
  { icon: ReactNode; key: keyof Document }
> = {
  URL: { icon: <MdLink />, key: "url" },
  FILE: { icon: <MdOutlineFileUpload />, key: "file" },
  TEXT: { icon: <MdShortText />, key: "text_content" },
};

const MemoryCard = ({
  refetchMemoryList,
  memory,
}: {
  refetchMemoryList: UseQueryResult["refetch"];
  memory: Document;
}) => {
  const newGeneratorPanelEnabled = useTypedFeatureIsOn("new-generator-panel");

  const [showGeneratorPanel, setShowGeneratorPanel] = useState(false);
  const [selectedMemory, setSelectedMemory] = useState<Document | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const { memoryIcon, memoryLabel } = useMemo(() => {
    const { icon, key } = MEMORY_TYPE_MAPPING[memory.type ?? "TEXT"];
    return { memoryIcon: icon, memoryLabel: memory[key] };
  }, [memory]);

  const deleteMemory = async () => {
    setIsLoading(true);
    try {
      await djangoClient.v1_documents_destroy({ id: memory.id });
      await refetchMemoryList();
      message.success("Memory deleted successfully");
    } catch (error) {
      genericError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMemory = async (body: Partial<Document>) => {
    try {
      await djangoClient.v1_documents_destroy({ id: memory.id });
      await djangoClient.v1_documents_create(null, body as Document);
      await refetchMemoryList();
      message.success("Memory updated successfully.");
    } catch (error) {
      genericError(error);
    }
  };

  const rescan = async () => {
    setIsLoading(true);
    try {
      await djangoClient.v1_documents_destroy({ id: memory.id });
      await djangoClient.v1_documents_create(null, {
        title: memory.title,
        crawl: memory.crawl,
        type: memory.type,
        url: memory.url,
      } as Document);
      await refetchMemoryList();
      message.success("Re-scanned successfully.");
    } catch (error) {
      genericError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const menuItems = useMemo(() => {
    const menuItems = newGeneratorPanelEnabled
      ? [
          {
            key: "generate",
            label: "Generate from Memory",
            icon: <MdOutlineTune size={16} />,
            onClick: () => setShowGeneratorPanel(true),
          },
        ]
      : [];

    if (memory.type === "URL") {
      menuItems.push({
        key: "rescan",
        label: "Rescan website",
        icon: <MdRefresh size={16} />,
        onClick: rescan,
      });
    }

    if (memory.type === "TEXT") {
      menuItems.push({
        key: "edit",
        label: "Edit Text",
        icon: <MdOutlineEdit size={16} />,
        onClick: () => setSelectedMemory(memory),
      });
    }

    menuItems.push({
      key: "delete",
      label: "Delete",
      icon: <MdDeleteOutline size={16} />,
      onClick: deleteMemory,
    });

    return menuItems;
  }, [memory]);

  const statusConfig = useMemo(() => {
    if (["EXTRACTING", "PENDING", "INDEXING"].includes(memory.status)) {
      return { status: "processing", text: "Processing..." };
    } else if (memory.status === "FAILED") {
      return { status: "error", text: "Failed to upload" };
    }
  }, [memory.status]);

  const failedReasonTooltip = useMemo(
    () => MEMORY_STATUS_MESSAGES[memory.failure_reason],
    [memory.failure_reason]
  );

  return (
    <div className="flex items-center justify-between p-3 border border-antd-colorBorderSecondary rounded-md">
      <div>
        <div className="font-medium text-sm">{memory.title}</div>
        <div className="flex gap-1 items-center text-antd-colorTextSecondary">
          {memoryIcon}
          <span className="text-xs truncate max-w-48">{memoryLabel}</span>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {statusConfig && (
          <Tooltip title={failedReasonTooltip}>
            <Badge
              status={statusConfig.status as BadgeProps["status"]}
              text={statusConfig.text}
            />
          </Tooltip>
        )}
        <Dropdown menu={{ items: menuItems }}>
          <Button
            loading={isLoading}
            className="w-8 p-0"
            type="text"
            size="small"
            icon={
              <MdOutlineMoreVert size={20} className="text-antd-colorIcon" />
            }
          />
        </Dropdown>
        <EditTopicOrMemory
          title="Edit Text Memory"
          entityType="memory"
          open={Boolean(selectedMemory)}
          initialValues={
            selectedMemory && {
              title: selectedMemory.title,
              description: selectedMemory.text_content ?? "",
            }
          }
          onSubmit={(values) =>
            updateMemory({
              title: values.title,
              text_content: values.description,
            })
          }
          onClose={() => setSelectedMemory(undefined)}
        />
        {showGeneratorPanel && (
          <NewGeneratePanel
            generateFrom={{ type: "memory", id: memory.id }}
            onSubmit={() => setShowGeneratorPanel(false)}
            onCancel={() => setShowGeneratorPanel(false)}
          />
        )}
      </div>
    </div>
  );
};

export default MemoryCard;
