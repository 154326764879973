import { Form, Segmented } from "antd";
import { FormItemProps } from "antd/lib";
import { MdEdit, MdLink, MdOutlineFolder } from "react-icons/md";

const OPTIONS = {
  text: { label: "Text", value: "TEXT", icon: <MdEdit /> },
  topic: { label: "Topic", value: "topic", icon: <MdOutlineFolder /> },
  memory: { label: "Memory", value: "memory", icon: <MdOutlineFolder /> },
  url: { label: "URL", value: "URL", icon: <MdLink /> },
  pdf: { label: "PDF", value: "FILE", icon: <MdOutlineFolder /> },
};

const SegmentedFormItem = ({
  formItemProps,
  options,
}: {
  formItemProps: FormItemProps;
  options: Array<keyof typeof OPTIONS>;
}) => (
  <Form.Item
    label={formItemProps.label}
    rules={formItemProps.rules}
    name={formItemProps.name}
  >
    <Segmented
      className="w-full [&_.ant-segmented-item]:flex-1 [&_.ant-segmented-item-label]:flex [&_.ant-segmented-item-label]:items-center [&_.ant-segmented-item-label]:justify-center"
      options={options.map((key) => OPTIONS[key])}
    />
  </Form.Item>
);

export default SegmentedFormItem;
