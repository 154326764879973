import { Button } from "antd";
import ModalWithBorders from "components/ModalWithBorders";
import DomainSetupInstructions from "./DomainSetupInstructions";

const SetupCustomDomainModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  return (
    <ModalWithBorders
      footer={
        <Button type="primary" onClick={close}>
          Close
        </Button>
      }
      title="Setup Your Domain"
      onCancel={close}
      open={isOpen}
      centered
    >
      <div className="space-y-4">
        <p>
          Now that you've entered you custom domain, you'll need to connect it
          to Marky. Complete the following steps to get your domain up and
          running.
        </p>
        <DomainSetupInstructions isModal />
      </div>
    </ModalWithBorders>
  );
};

export default SetupCustomDomainModal;
