import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { POST_SCREENS, PostScreen } from "store/posts/postConstants";
import {
  ALL_POST_SCREEN_DETAILS,
  POST_STATUS_PERMISSIONS,
  SCREEN_PERMISSION_DETAILS,
} from "./screenSelectorConstants";

export const POST_SCREEN_TAB = "tab";

export const useGetAllowedScreens = () => {
  const showPostCalendar = useTypedFeatureIsOn("post-calendar");

  const [searchParams, setSearchParams] = useSearchParams();
  const screenParam = searchParams.get("tab");
  const { pathname } = useLocation();

  const { realm } = useAppContext();
  const { hasPermission, hasSomePermissions } = useUserPermissions();

  const canSeePosts = hasSomePermissions(POST_STATUS_PERMISSIONS);

  const canAccessScreen = (postScreen: PostScreen, ownerOverride?: boolean) => {
    const { markyOnly, permissionNeeded } =
      SCREEN_PERMISSION_DETAILS[postScreen];
    if (markyOnly && !realm.isMarky) {
      return false;
    }

    return hasPermission(permissionNeeded, ownerOverride);
  };

  const { visibleScreens, visibleScreenKeys, defaultScreen } = useMemo(() => {
    const visibleScreens = ALL_POST_SCREEN_DETAILS.filter(({ key }) =>
      canAccessScreen(key)
    );
    const visibleScreenKeys = POST_SCREENS.filter((key) =>
      canAccessScreen(key)
    );

    if (canSeePosts) {
      const checkScreen =
        screenParam && visibleScreenKeys.includes(screenParam as PostScreen)
          ? (screenParam as PostScreen)
          : visibleScreenKeys[0];

      let screenDetails = SCREEN_PERMISSION_DETAILS[checkScreen];

      if (screenDetails) {
        let defaultScreen: PostScreen | undefined = checkScreen;

        while (!canAccessScreen(defaultScreen)) {
          if (screenDetails.fallback) {
            defaultScreen = screenDetails.fallback;
            screenDetails = SCREEN_PERMISSION_DETAILS[defaultScreen];
          } else {
            defaultScreen = undefined;
            break;
          }
        }

        return { visibleScreens, visibleScreenKeys, defaultScreen };
      }
    }

    return { visibleScreens, visibleScreenKeys, defaultScreen: undefined };
  }, [realm, canSeePosts, screenParam, pathname, showPostCalendar]);

  const setToDefaultScreen = () => {
    if (defaultScreen) {
      searchParams.set("tab", defaultScreen);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    screenParam !== defaultScreen && setToDefaultScreen();
  }, [defaultScreen, screenParam]);

  return {
    screenParam: defaultScreen,
    canSeePosts,
    visibleScreens,
    visibleScreenKeys,
    canAccessScreen,
    setToDefaultScreen,
  };
};
