import { message } from "antd";
import { AuthCredentials } from "api/config/chalice-api";
import { fetchSocialAccountDetails, getToken } from "api/integrations";
import { isAxiosError } from "axios";
import classNames from "classnames";
import env from "config/envVars";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { INTEGRATION_ITEMS } from "fixtures/constants";
import { isCustomDomain, StateParam } from "fixtures/socialAuthConfig";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { addIntegration } from "store/user/userActions";
import { IntegrationType } from "store/user/userConstants";
import {
  currentBusinessGetter,
  integrationsGetter,
} from "store/user/userSlice";
import { useQueryParam } from "use-query-params";
import { formatSocialName } from "utils/generalUtils";
import InstagramAccountSelector from "../InstagramAccountSelector/InstagramAccountSelector";
import InstagramNoteModal from "../InstagramNoteModal";
import IntegrationItem from "../IntegrationItem";
import IntegrationNoteModal from "../IntegrationNoteModal";
import IntegrationSettings from "../IntegrationSettings/IntegrationSettings";
import {
  HandleIntegrationConnect,
  IntegrationRequiringPageSelectionType,
  INTEGRATIONS_REQUIRING_PAGE_SELECTION,
  INTEGRATIONS_ROUTE,
  INTEGRATIONS_WITH_SETTINGS,
  IntegrationWithSettings,
  NonInstagramIntegration,
} from "../IntegrationSettings/settingConstants";

const IntegrationsList = () => {
  const instagramStories = useTypedFeatureIsOn("instagram-stories");

  const [integrationCredentials, setIntegrationCredentials] =
    useState<AuthCredentials | null>(null);
  const connectedSocials = useAppSelector(integrationsGetter);
  const business = useAppSelector(currentBusinessGetter);

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname ?? "/";
  const { integrationType, appName } =
    useQueryParam("state", StateParam)[0] ?? {};

  const dispatch = useAppDispatch();

  const [loadingItem, setLoadingItem] = useState<IntegrationType | null>(null);

  const [showSettingsModalFor, setShowSettingsModalFor] =
    useState<IntegrationWithSettings | null>(null);
  const [instagramErrorMessage, setInstagramErrorMessage] = useState("");
  const [isFacebookModalOpen, setIsFacebookModalOpen] = useState(false);

  const [showNoteModal, setShowNoteModal] = useState(false);

  const clearAllParams = () => setSearchParams({}, { replace: true });

  const isInstagram =
    integrationType === "instagram" || integrationType === "instagramStory";

  const createIntegration: HandleIntegrationConnect = async ({
    publishSettings,
    authCredentials,
    selectedPage,
    type,
  }) => {
    if (!authCredentials) {
      return;
    }

    try {
      const accountDetails = await fetchSocialAccountDetails({
        token: authCredentials?.access_token,
        type,
      });

      await dispatch(
        addIntegration({
          auth_credentials: authCredentials,
          ...accountDetails,
          profile_picture: accountDetails.picture_url,
          profile_user_id: accountDetails.profile_id,
          type,
          ...(selectedPage && { selected_page: selectedPage }),
          publish_settings: publishSettings ?? {
            ...(type === "tiktok" && {
              brand_organic_toggle: true,
              direct_publish: true,
            }),
            ...(INTEGRATIONS_WITH_SETTINGS.includes(
              type as IntegrationWithSettings
            ) && { ai_generate_title: true }),
          },
        })
      );

      message.success(`Successfully linked to ${formatSocialName(type)}`);

      setIntegrationCredentials(null);
    } catch (error) {
      if (isAxiosError(error)) {
        setInstagramErrorMessage(error?.response?.data.message);
        setShowNoteModal(error?.response?.data?.code === "NotFoundError");
        setIsFacebookModalOpen(type === "facebook");
      }
      message.error(
        `Error connecting your ${formatSocialName(
          type
        )}. Please try again later.`
      );
    } finally {
      clearAllParams();
      setLoadingItem(null);
    }
  };

  const handleAuthSuccess = async (type: IntegrationType) => {
    const code = searchParams.get("code");

    if (!code) {
      return;
    }

    setLoadingItem(type);
    try {
      const { credentials } = await getToken({
        code,
        type,
        redirectUri: isCustomDomain
          ? `${env.FE_BASE_URL}/proxy`
          : `${window.location.origin}/integrations`,
        appName,
      });

      setIntegrationCredentials(credentials);

      INTEGRATIONS_WITH_SETTINGS.includes(type as IntegrationWithSettings) &&
        setShowSettingsModalFor(type as IntegrationWithSettings);

      if (
        !INTEGRATIONS_REQUIRING_PAGE_SELECTION.includes(
          type as IntegrationRequiringPageSelectionType
        ) &&
        !isInstagram
      ) {
        createIntegration({
          type: type as NonInstagramIntegration,
          authCredentials: credentials,
        });
      }
    } catch (error) {
      message.error(`Error connecting your ${formatSocialName(type)}`);
      clearAllParams();
      isAxiosError(error) &&
        setIsFacebookModalOpen(
          type === "facebook" && error?.response?.data?.code
        );
      setLoadingItem(null);
      console.error(error);
    }
  };

  const handleAuthError = (integrationType: IntegrationType) => {
    message.error(
      searchParams.get("error_description") ||
        `Oops! Failed to connect with ${formatSocialName(
          integrationType
        )}. Please try again later`
    );
    clearAllParams();
  };

  useEffect(() => {
    if (!integrationType) {
      return;
    }

    if (searchParams.get("error")) {
      handleAuthError(integrationType);
    } else {
      handleAuthSuccess(integrationType);
    }
  }, [integrationType]);

  // TODO: Business has no owner when creating new business in onboarding modal
  if (!business?.owner?.subscription) {
    return null;
  }

  const { socials_per_business: maxSocialConnections = null } =
    business.owner?.subscription?.restrictions ?? {};

  return (
    <>
      <div
        className={classNames(
          "divide-y-[1px] divide-antd-colorBorderSecondary",
          {
            "border border-antd-colorBorderSecondary rounded-lg":
              pathname !== INTEGRATIONS_ROUTE,
          }
        )}
      >
        {INTEGRATION_ITEMS.map(
          (item, i) =>
            (instagramStories || item.integrationType !== "instagramStory") && (
              <IntegrationItem
                {...item}
                key={i}
                setLoadingItem={setLoadingItem}
                loadingItem={loadingItem}
                maxSocialsReached={
                  maxSocialConnections !== null &&
                  connectedSocials.length >= maxSocialConnections
                }
                setShowSettingsModalFor={setShowSettingsModalFor}
              />
            )
        )}
      </div>

      <IntegrationNoteModal
        message={instagramErrorMessage}
        isOpen={showNoteModal}
        close={() => setShowNoteModal(false)}
      />
      <InstagramNoteModal
        integrationName={integrationType}
        close={() => setIsFacebookModalOpen(false)}
        isOpen={isFacebookModalOpen}
        isError
      />
      {isInstagram && (
        <InstagramAccountSelector
          resetCredentials={() => setIntegrationCredentials(null)}
          integrationCredentials={integrationCredentials}
          integrationType={integrationType}
          setLoadingItem={setLoadingItem}
        />
      )}
      {showSettingsModalFor && (
        <IntegrationSettings
          closeModal={() => setShowSettingsModalFor(null)}
          integrationCredentials={integrationCredentials}
          integrationType={showSettingsModalFor}
          createIntegration={createIntegration}
        />
      )}
    </>
  );
};

export default IntegrationsList;
