import { ContentTopic } from "api/config/chalice-api";
import EditTopicOrMemory from "pages/Memory/components/EditTopicOrMemory";

export type TopicModalType = TopicModalData["modalType"];

export type TopicModalData =
  | { modalType: "new-topic" | "import-topics"; topic?: undefined }
  | {
      modalType: "edit-topic" | "generate-posts";
      topic: ContentTopic & { index: number };
    };

export type TopicModalProps = {
  modalData: TopicModalData;
  addTopic: (newTopic: ContentTopic) => void;
  updateTopic: (newTopic: ContentTopic, index: number) => void;
  closeModal: () => void;
};

const TopicModal = ({
  modalData: { modalType, topic: currentTopic },
  addTopic,
  updateTopic,
  closeModal,
}: TopicModalProps) => (
  <EditTopicOrMemory
    open
    entityType="topic"
    title={modalType === "new-topic" ? "Add Topic" : "Edit Topic"}
    initialValues={
      currentTopic
        ? {
            title: currentTopic.title,
            description: currentTopic.body,
          }
        : undefined
    }
    onClose={closeModal}
    onSubmit={(values) => {
      closeModal();
      const newTopic = {
        title: values.title,
        body: values.description,
        enabled: true,
      };

      modalType === "edit-topic"
        ? updateTopic(newTopic, currentTopic.index)
        : addTopic(newTopic);
    }}
  />
);

export default TopicModal;
