import { Permissions } from "api/config/chalice-api";
import { VOID_FUNCTION } from "fixtures/globalConstants";

export type PermissionString = keyof Permissions;

export type UserPermissionsContextType = {
  isCurrentBusinessOwner: boolean;
  userPermissions: Permissions;
  hasPermission: (
    checkPerm: PermissionString,
    ownerOverride?: boolean
  ) => boolean;
  hasSomePermissions: (
    checkPerms: PermissionString[] | readonly PermissionString[],
    ownerOverride?: boolean
  ) => boolean;

  setPermissionOverrides: (newPermissions: Permissions) => void;
  clearPermissionOverrides: () => void;
};

/**
 * Cannot generate an object based on a type definition. Create this as an
 * object rather than a list of permissions so that typescript will verify
 * that every key of chalice-api.Permissions is represented. An error
 * will be thrown if there's a mismatch.
 */
export const DEFAULT_PERMISSIONS = (
  defaultPermission: boolean = false
): Required<Permissions> => ({
  business_clients_read: defaultPermission,
  billing_details_write: defaultPermission,
  business_details_read: defaultPermission,
  business_details_write: defaultPermission,
  business_team_members_read: defaultPermission,
  caption_write: defaultPermission,
  client_permissions_write: defaultPermission,
  comment_read: defaultPermission,
  comment_write: defaultPermission,
  members_write: defaultPermission,
  post_approval_status_approve_write: defaultPermission,
  post_approval_status_ready_for_review_write: defaultPermission,
  post_approval_status_reject_write: defaultPermission,
  post_design_write: defaultPermission,
  post_status_draft_read: defaultPermission,
  post_status_draft_write: defaultPermission,
  post_status_generate_read: defaultPermission,
  post_status_generate_write: defaultPermission,
  post_status_publish_read: defaultPermission,
  post_status_publish_write: defaultPermission,
  post_status_schedule_read: defaultPermission,
  post_status_schedule_write: defaultPermission,
  post_status_trash_read: defaultPermission,
  post_status_trash_write: defaultPermission,
  posting_schedule_read: defaultPermission,
  posting_schedule_write: defaultPermission,
  review_tab_read: defaultPermission,
  socials_write: defaultPermission,
  upload_custom_post_write: defaultPermission,
});

export const PERMISSION_STRINGS = Object.keys(
  DEFAULT_PERMISSIONS()
) as readonly PermissionString[];

export const OVERRIDE_PERMISSIONS_KEY = "user-permission-overrides";

export const DEFAULT_USER_PERMISSIONS_CONTEXT: UserPermissionsContextType = {
  isCurrentBusinessOwner: false,
  userPermissions: {},
  hasPermission: () => false,
  hasSomePermissions: () => false,

  setPermissionOverrides: VOID_FUNCTION,
  clearPermissionOverrides: VOID_FUNCTION,
};
