import pluralize from "pluralize";
import { IMAGE_FULL_EXTENSION } from "utils/validateFileUpload";

export const SHIFT_CLICK_MESSAGE =
  "Shift+click or drag to select multiple images at once.";

export const IMAGES_LIMIT = 2000;
export const MAX_FILE_SIZE = 8e6;
export const ACCEPTED_FILE_TYPES = IMAGE_FULL_EXTENSION.concat([
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".bmp",
  ".gif",
  ".webp",
]);
export const IMAGE_TOO_BIG_MESSAGE = "Maximum file size is 8 MB.";
export const INVALID_FILE_TYPE_MESSAGE = (count: number) =>
  `${pluralize("Image", count)} must be in PNG or JPG format.`;
