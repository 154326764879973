import { addImageViaTaskQueue } from "api/media";
import { GlobalModalConfig } from "components/GlobalModals/GlobalModalContext/globalModalConfig";
import { PageLoader } from "designSystem/Loader";

import { genericError } from "fixtures/globalConstants";
import {
  IS_NEW_USER,
  SHOW_SCHEDULED_POSTS_TOUR,
} from "fixtures/localStorageKeys";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "store";
import { fetchImagesInLibrary } from "store/imageLibrary/imageLibraryActions";
import { setIsAnonymous, setShowOnboardingTour } from "store/user/userSlice";
import {
  BusinessDetails,
  IteratePartFn,
  ONBOARDING_PARTS,
  ScrapedBusinessData,
} from "./businessOnboardingConstants";
import BusinessSummaryInput from "./components/BusinessSummary";
import GenerateBusinessProfile from "./components/GenerateBusinessProfile";
import SimpleSignupModal from "./components/SimpleSignupModal";
import SkipToPosts from "./components/SkipToPosts";
import BusinessOnboardingStepper from "./onboardingStepper/BusinessOnboardingStepper";

export const ONBOARDING_ROUTE = "/business-metadata";
export const GENERATE_FOR_ANONYMOUS_ROUTE = "/generate-for-my-website";

export type BusinessOnboardingModalData = GlobalModalConfig<
  "create-business",
  {
    anonymousUser?: boolean;
    updatedBusinessDetails?: ScrapedBusinessData;
    openConnectSocials?: boolean;
  }
>;

const BusinessOnboardingModal = ({
  updatedBusinessDetails,
  openConnectSocials,
  anonymousUser,
  closeModal,
  isOpen,
}: BusinessOnboardingModalData["propTypes"]) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const {
    businesses,
    userLoading,
    token,
    isAgencyOwner,
    currentBusiness,
    userInfo: { isAnonymous },
  } = useAppSelector((state) => state.user);
  const defaultBusinessRoute = useDefaultBusinessRoute();

  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>(
    openConnectSocials
      ? {
          ...currentBusiness,
          logoUrl: currentBusiness.brand.logo,
          accentColor: currentBusiness.brand.palettes?.[0]?.accent_color,
        }
      : {}
  );

  const [newUser, setNewUser, removeNewUser] =
    useLocalStorage<boolean>(IS_NEW_USER);
  const [, setShowScheduledPostsTour] = useLocalStorage<true | undefined>(
    SHOW_SCHEDULED_POSTS_TOUR
  );

  useEffect(() => {
    if (token && anonymousUser) {
      if (businesses.length) {
        closeModal();
        navigate(isAgencyOwner ? "/dashboard" : defaultBusinessRoute);
      } else {
        navigate(ONBOARDING_ROUTE);
      }
    } else {
      dispatch(setIsAnonymous(anonymousUser ?? false));
    }
  }, []);

  const onboardingParts = useMemo(
    () =>
      // Need to use anonymousUser prop here: isAnonymous will be toggled off after the account is created
      anonymousUser
        ? ONBOARDING_PARTS
        : ONBOARDING_PARTS.filter((step) => step !== "signup"),
    [anonymousUser]
  );

  const [openConnectSocialsStepState, setOpenConnectSocialsStepState] =
    useState(openConnectSocials ?? false);

  const [partIndex, setPartIndex] = useState(
    openConnectSocials
      ? onboardingParts.findIndex((part) => part === "onboarding-stepper")
      : 0
  );

  useEffect(() => {
    if (updatedBusinessDetails) {
      saveScrapedData(updatedBusinessDetails);
      setPartIndex(onboardingParts.indexOf("skip-to-posts"));
    }
  }, [updatedBusinessDetails]);

  useEffect(() => {
    if (!userLoading) {
      const isNewUser =
        businesses.length === 0 ||
        (businesses.length === 1 && businesses[0].id === businessDetails?.id);
      setNewUser(isNewUser);

      if (isNewUser) {
        setShowScheduledPostsTour(true);
        dispatch(setShowOnboardingTour(true));
      }
    }
  }, [userLoading]);

  const saveScrapedData = ({ palette, ...data }: ScrapedBusinessData) => {
    setBusinessDetails({
      ...data,
      accentColor: palette?.accent_color,
    });
    iteratePart("next");
  };

  const saveBusinessDetails = (data: BusinessDetails) =>
    setBusinessDetails({ ...businessDetails, ...data });

  const uploadImages = async (selectedImages?: string[]) => {
    const images = selectedImages || businessDetails.images;

    if (images) {
      try {
        await Promise.all(
          images.map((image: string) =>
            addImageViaTaskQueue({
              task: {
                task_kwargs: {
                  image_url: image,
                },
              },
            })
          )
        );
        dispatch(fetchImagesInLibrary());
      } catch (error) {
        genericError(error);
      }
    }
  };

  const onClose = (forceNavigate?: boolean) => {
    closeModal();
    removeNewUser();
    (forceNavigate || pathname === ONBOARDING_ROUTE) &&
      navigate(POSTS_PAGE_ROUTE, { replace: true });
  };

  const iteratePart: IteratePartFn = (direction) => {
    const newPartIndex = direction === "next" ? partIndex + 1 : partIndex - 1;

    if (
      ["signup", "skip-to-posts"].includes(onboardingParts[newPartIndex]) &&
      direction === "next" &&
      [ONBOARDING_ROUTE, GENERATE_FOR_ANONYMOUS_ROUTE].includes(pathname)
    ) {
      navigate(POSTS_PAGE_ROUTE, { replace: true });
    }

    if (newPartIndex === onboardingParts.length) {
      onClose(true);
    } else {
      setPartIndex(newPartIndex);
    }

    setOpenConnectSocialsStepState(false);
  };

  const onboardingPart = onboardingParts[partIndex];

  useEffect(() => {
    if (!businessDetails.summary && currentBusiness.id && !openConnectSocials) {
      setBusinessDetails({
        ...currentBusiness,
        logoUrl: currentBusiness.brand.logo,
        accentColor: currentBusiness.brand.palettes?.[0]?.accent_color,
      });
    }
  }, [currentBusiness]);

  if (!isOpen) {
    return;
  }

  return isAnonymous === null ? (
    <PageLoader />
  ) : onboardingPart === "summary" ? (
    <BusinessSummaryInput {...{ saveScrapedData, onClose, newUser }} />
  ) : onboardingPart === "generating" ? (
    <GenerateBusinessProfile
      {...{ businessDetails, saveBusinessDetails, iteratePart }}
    />
  ) : onboardingPart === "signup" ? (
    <SimpleSignupModal
      prefillEmail={businessDetails?.contact_email}
      iterateNext={() => iteratePart("next")}
    />
  ) : onboardingPart === "skip-to-posts" ? (
    <SkipToPosts
      iterateNext={() => iteratePart("next")}
      onClose={() => onClose(true)}
      uploadImages={uploadImages}
      newUser={newUser}
    />
  ) : (
    onboardingPart === "onboarding-stepper" && (
      <BusinessOnboardingStepper
        initialValues={businessDetails}
        {...{
          openConnectSocialsStepState,
          saveBusinessDetails,
          uploadImages,
          iteratePart,
          newUser,
        }}
      />
    )
  );
};

export default BusinessOnboardingModal;
