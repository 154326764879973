import { QueueTaskStatusType } from "store/imageLibrary/imageLibraryConstants";
import { djangoClient, mediaClient } from "./baseClients";
import { Paths, StockImage } from "./config/media-api";

export const fetchImagesInBatches = async function* () {
  // Using a generator function to yield images in batches as they are fetched
  let next: string | null | undefined = null;
  const {
    data: { next: firstNext, results },
  } = await mediaClient.v1_images_list();

  yield results; // Yield first batch immediately
  next = firstNext;

  while (next) {
    const {
      data: { next: nextPage, results: newResults },
    } = await mediaClient.get<{ data: Paths.V1ImagesList.Responses.$200 }>(
      next
    );

    yield newResults; // Yield subsequent batches
    next = nextPage;
  }
};

export const fetchStockImages = async (query: string, page: number) => {
  const {
    data: { results = [] },
  } = await mediaClient.v1_stock_images_list({
    ordering: ["media_similarity"],
    similar_to_string: query,
    page,
  });

  return results;
};

export const addImage = async (body: FormData) => {
  // @ts-expect-error Media service says it doesn't accept FormData for body, but current code sends FormData to
  // upload files
  const response = await mediaClient.v1_images_create(null, body);

  // @ts-expect-error Return type specified by mediaClient seems to be incorrect, StockImage is actually returned
  return response.data as StockImage;
};

export const addImageViaTaskQueue = async (body: {
  task: {
    task_kwargs: { image_url: string };
  };
}) =>
  djangoClient.v1_longpolling_tasks_tasks_store_image_in_media_service_create(
    null,
    // @ts-expect-error Wrong body type in swagger def, indicates that it needs more properties
    body
  );

export const getImageQueueTasks = async (status: QueueTaskStatusType) => {
  const response =
    await djangoClient.v1_longpolling_tasks_tasks_store_image_in_media_service_list(
      {
        task_name: "longpolling_task.store_image_in_media_service",
        task__status: status,
      }
    );

  return response.data;
};

export const deleteQueueTask = (taskId: string) =>
  djangoClient.v1_longpolling_tasks_tasks_store_image_in_media_service_destroy({
    task_id: taskId,
  });

export const addStockImage = async (imageId: string) => {
  const response = await mediaClient.v1_images_from_stock_create({
    media_id: imageId,
  });
  return response.data;
};

export const deleteImage = async (imageId: string) => {
  const response = await mediaClient.v1_images_destroy({ media_id: imageId });
  return response.data;
};
