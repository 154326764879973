import { PlusOutlined } from "@ant-design/icons";
import classNames from "classnames";

import {
  HTMLProps,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdChevronLeft, MdChevronRight, MdVisibility } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { usePrevious } from "react-use";
import { useAppDispatch } from "store";
import { Post } from "store/posts/postConstants";
import { setActivePostPageIndex } from "store/posts/postSlice";
import { validateImageUrl } from "utils/validateFileUpload";

const FALLBACK_WIDTH = 366;

type CarouselProps = {
  openFullScreenEditor?: () => void;
  inModal?: boolean;
  children?: ReactNode;
  addNewSlideProps?: Omit<HTMLProps<HTMLDivElement>, "className"> & {
    icon?: ReactNode;
  };
  handleVideoClick?: (event: React.MouseEvent) => void;
  currentSlideCallback?: (currentSlide: number) => void;
} & Either<{ post: Post }, { mediaUrls: string[] }>;

const Carousel = ({
  openFullScreenEditor,
  post,
  mediaUrls,
  inModal,
  children: imageOverlay,
  addNewSlideProps,
  handleVideoClick,
  currentSlideCallback,
}: CarouselProps) => {
  const dispatch = useAppDispatch();

  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const mediaWrapper = useRef<HTMLDivElement>(null);
  const thumbnailWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    post?.id &&
      dispatch(
        setActivePostPageIndex({ postId: post.id, pageIndex: currentSlide })
      );
    currentSlideCallback && currentSlideCallback(currentSlide);
  }, [currentSlide, post?.id]);

  const media = useMemo(
    () => post?.media_urls ?? mediaUrls ?? [],
    [mediaUrls, post?.media_urls]
  );

  useEffect(() => {
    setCurrentSlide(Math.min(currentSlide, media.length - 1));
    !validateImageUrl(media[0]) && setIsMediaLoaded(true);
  }, [media]);

  const prevMediaUrlsLength = usePrevious(mediaUrls?.length);
  useLayoutEffect(() => {
    if (
      mediaUrls &&
      prevMediaUrlsLength !== undefined &&
      mediaUrls.length > prevMediaUrlsLength &&
      thumbnailWrapper.current
    ) {
      thumbnailWrapper.current.scrollLeft =
        thumbnailWrapper.current.scrollWidth;
    }
  }, [mediaUrls?.length]);

  return (
    <div
      className={classNames("flex flex-col gap-2 max-sm:max-w-full", {
        "max-w-sm max-h-[75vh]": inModal,
      })}
      style={inModal ? { width: FALLBACK_WIDTH } : undefined}
    >
      <div className="w-full flex flex-col relative overflow-hidden rounded border border-antd-colorBorder">
        <div
          className="w-full min-h-0 grid grid-flow-col items-center transition-transform duration-300"
          ref={mediaWrapper}
          style={{
            gridAutoColumns:
              mediaWrapper.current?.clientWidth || FALLBACK_WIDTH,
            transform: `translateX(-${currentSlide * (mediaWrapper.current?.clientWidth ?? FALLBACK_WIDTH)}px)`,
          }}
        >
          {media.map((mediaItem, index) => {
            const commonProps = {
              src: mediaItem,
              className: classNames("object-contain min-h-0 m-auto", {
                "absolute -z-10": !isMediaLoaded,
              }),
              onLoad: () => index === 0 && setIsMediaLoaded(true),
            };

            return validateImageUrl(mediaItem) ? (
              <img
                onClick={() => openFullScreenEditor && openFullScreenEditor()}
                {...commonProps}
                key={index}
                alt={post?.caption ?? undefined}
                className={classNames({
                  "cursor-pointer": openFullScreenEditor,
                })}
              />
            ) : (
              <video
                {...commonProps}
                preload="metadata"
                key={index}
                onDoubleClick={handleVideoClick}
                onClick={handleVideoClick}
                controls
              />
            );
          })}
          {!isMediaLoaded && (
            <Skeleton
              containerClassName="aspect-square w-full [&_br]:hidden"
              className="h-full block"
              borderRadius={0}
            />
          )}
        </div>
        <div className="absolute h-full w-full top-0 left-0 flex items-center p-2 touch-none pointer-events-none">
          <span className="absolute right-2 top-2 py-1.5 px-2 rounded bg-antd-colorBgSpotlight text-white/80 text-xs">
            {currentSlide + 1}/{media?.length}
          </span>

          {currentSlide > 0 && (
            <CarouselArrow
              direction="previous"
              onClick={() => setCurrentSlide(currentSlide - 1)}
            />
          )}
          {currentSlide + 1 < media.length && (
            <CarouselArrow
              direction="next"
              onClick={() => setCurrentSlide(currentSlide + 1)}
            />
          )}
        </div>
        {imageOverlay}
      </div>

      <div
        ref={thumbnailWrapper}
        className="flex overflow-x-auto min-h-[60px] gap-2 scroll-smooth"
      >
        {media?.map((url, index) => (
          <div
            className={classNames(
              "relative min-w-[60px] h-[60px] aspect-square flex items-center border border-antd-colorBorder rounded overflow-hidden",
              {
                "cursor-pointer": currentSlide !== index,
              }
            )}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentSlide(index);
            }}
          >
            {currentSlide === index && (
              <ThumbnailIcon className="absolute bg-antd-colorBgMask text-antd-colorBgContainer opacity-85">
                <MdVisibility size={24} />
              </ThumbnailIcon>
            )}
            {validateImageUrl(url) ? (
              <img src={url} />
            ) : (
              <video src={url} preload="metadata" />
            )}
          </div>
        ))}
        {addNewSlideProps && (
          <ThumbnailIcon
            className="cursor-pointer bg-antd-colorTextQuaternary text-antd-colorTextTertiary"
            {...addNewSlideProps}
          >
            {addNewSlideProps?.icon ?? <PlusOutlined />}
          </ThumbnailIcon>
        )}
      </div>
    </div>
  );
};

const CarouselArrow = ({
  direction,
  onClick,
}: {
  direction: "next" | "previous";
  onClick: () => void;
}) => (
  <div
    className={classNames(
      "bg-antd-colorBgLayout p-0.5 rounded-full cursor-pointer pointer-events-auto opacity-55 hover:opacity-65 transition-opacity",
      {
        "ml-auto": direction === "next",
      }
    )}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    {direction === "next" ? (
      <MdChevronRight size={20} />
    ) : (
      <MdChevronLeft size={20} />
    )}
  </div>
);

const ThumbnailIcon = ({ className, ...props }: HTMLProps<HTMLDivElement>) => (
  <div
    className={`${className} min-w-[60px] max-w-[60px] aspect-square flex items-center justify-center rounded`}
    {...props}
  />
);

export default Carousel;
