import { Dayjs, ManipulateType } from "dayjs";
import { UseStateReturn, VOID_FUNCTION } from "fixtures/globalConstants";
import { useLocalStorage } from "react-use";

// TODO: all routes should be stored in a constant variable, so that the route path could be updated globally
// by updating the variable. https://mymarky.atlassian.net/browse/SWE-1346
export const POST_ANALYTICS_ROUTE = "/analytics-dashboard";

export type DateRange = { start?: Dayjs; end?: Dayjs };

export type PostAnalyticsContextType = {
  dateRangeState: UseStateReturn<
    Record<"current" | "previous", DateRange | undefined>
  >;
  periodState: UseStateReturn<PeriodOption>;
  integrationIdsState: ReturnType<typeof useLocalStorage<string[]>>;
  allTimeRangeState: UseStateReturn<DateRange | undefined>;
};

export const DEFAULT_PERIOD_OPTION: Required<PeriodOption> = {
  value: "past-14-days",
  label: "Past 14 Days",
  subtractAmount: 14,
  subtractUnit: "days",
};

export const CUSTOM_PERIOD_OPTION: PeriodOption = {
  value: "custom",
  label: "Custom",
};

export const ALL_TIME_PERIOD_OPTION: PeriodOption = {
  value: "all-time",
  label: "All Time",
};

export type PeriodOption = {
  value: string;
  label: string;
  subtractAmount?: number;
  subtractUnit?: ManipulateType;
};

export const PERIOD_OPTIONS: PeriodOption[] = [
  {
    value: "past-7-days",
    label: "Past 7 Days",
    subtractAmount: 7,
    subtractUnit: "days",
  },
  DEFAULT_PERIOD_OPTION,
  {
    value: "past-30-days",
    label: "Past 30 Days",
    subtractAmount: 30,
    subtractUnit: "days",
  },
  {
    value: "past-90-days",
    label: "Past 90 Days",
    subtractAmount: 90,
    subtractUnit: "days",
  },
  ALL_TIME_PERIOD_OPTION,
  CUSTOM_PERIOD_OPTION,
];

export const DEFAULT_ANALYTICS_DASHBOARD: PostAnalyticsContextType = {
  dateRangeState: [{ current: undefined, previous: undefined }, VOID_FUNCTION],
  periodState: [DEFAULT_PERIOD_OPTION, VOID_FUNCTION],
  integrationIdsState: [[], VOID_FUNCTION, VOID_FUNCTION],
  allTimeRangeState: [undefined, VOID_FUNCTION],
};

export const UNSUPPORTED_SOCIALS_FOR_ANALYTICS = ["linkedInProfile"] as const;
export type UnsupportedSocialForAnalytics =
  (typeof UNSUPPORTED_SOCIALS_FOR_ANALYTICS)[number];
