import useAppContext from "config/AppContext/useAppContext";
import env, { ENV_NAME } from "config/envVars";
import dayjs from "dayjs";
import { CancelablePromise, FrillWidget } from "declarations/frill";
import { useLayoutEffect, useRef } from "react";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";

const FrillWidgetContainer = () => {
  const {
    realm: { isMarky },
  } = useAppContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const { created_at: userCreatedAt } = useAppSelector(selectUserInfo);

  useLayoutEffect(() => {
    const accountAge = dayjs().diff(dayjs(userCreatedAt), "days");
    let widget: CancelablePromise<FrillWidget> | undefined;

    if (isMarky && accountAge > 1 && ENV_NAME === "PRODUCTION") {
      widget = window.Frill.widget({
        key: env.FRILL_WIDGET_KEY,
        container: containerRef.current ?? undefined,
      });
    }

    return () => widget?.destroy();
  }, [isMarky, userCreatedAt]);

  return (
    <div
      ref={containerRef}
      className="[&_button]:!bg-antd-colorBgContainer [&_button]:!text-antd-colorText [&_button]:transition-colors max-lg:hidden z-antd-popup"
    />
  );
};

export default FrillWidgetContainer;
