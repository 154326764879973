import yourBusiness from "assets/onboardingImages/Business.svg";
import content from "assets/onboardingImages/Content.svg";
import design from "assets/onboardingImages/Design.svg";
import signup from "assets/onboardingImages/Signup.svg";
import marketStrategy from "assets/onboardingImages/Strategy.svg";
import userPreferences from "assets/onboardingImages/UserPreferences.svg";
import { BusinessInfoGroup } from "pages/NewBusinessOnboarding/businessFormConstants";

const ONBOARDING_STEPPER_IMAGE_DICT: {
  [key in BusinessInfoGroup | "userPreferences" | "signup"]?: string;
} = {
  "your-business": yourBusiness,
  signup,
  design,
  userPreferences,
  content,
  "connect-socials": signup,
  "market-strategy": marketStrategy,
};

export default ONBOARDING_STEPPER_IMAGE_DICT;
