import { ClockCircleFilled, DeleteFilled } from "@ant-design/icons";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import { SCHEDULE_TAB_ID } from "fixtures/elementIDs";
import { ExoticComponent } from "react";
import { IconType } from "react-icons";
import { BiSolidMagicWand } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { POST_SCREENS, PostScreen } from "store/posts/postConstants";

export const POST_STATUS_PERMISSIONS: readonly PermissionString[] = [
  "post_status_generate_read",
  "post_status_draft_read",
  "post_status_schedule_read",
  "post_status_publish_read",
  "post_status_trash_read",
] as const;

type ScreenPermissionDetail = {
  label: string;
  Icon: IconType | ExoticComponent;
  showCount?: boolean;
  id?: string;
  hideFromSelector?: boolean;

  permissionNeeded: (typeof POST_STATUS_PERMISSIONS)[number];
  fallback?: PostScreen;
  markyOnly?: boolean;
};

export const SCREEN_PERMISSION_DETAILS: {
  [key in PostScreen]: ScreenPermissionDetail;
} = {
  generated: {
    label: "Generated",
    Icon: BiSolidMagicWand,

    permissionNeeded: "post_status_generate_read",
    fallback: "draft",
  },
  draft: {
    label: "Drafts",
    Icon: BsFillBookmarkFill,
    showCount: true,

    permissionNeeded: "post_status_draft_read",
    fallback: "published",
  },
  scheduled: {
    label: "Scheduled",
    Icon: ClockCircleFilled,
    showCount: true,
    id: SCHEDULE_TAB_ID,

    permissionNeeded: "post_status_schedule_read",
    fallback: "published",
  },
  published: {
    label: "Published",
    Icon: MdAssignmentTurnedIn,
    showCount: true,

    permissionNeeded: "post_status_publish_read",
  },
  rejected: {
    label: "Trash",
    Icon: DeleteFilled,
    hideFromSelector: true,

    permissionNeeded: "post_status_trash_read",
    markyOnly: true,
    fallback: "published",
  },
};

export type ScreenPermissionDetailKeyed = ScreenPermissionDetail & {
  key: PostScreen;
};

export const ALL_POST_SCREEN_DETAILS: ScreenPermissionDetailKeyed[] =
  POST_SCREENS.map((screen: PostScreen) => ({
    ...SCREEN_PERMISSION_DETAILS[screen],
    key: screen,
  }));
