import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAppDispatch } from "store";
import { getPostDetailsThunk } from "store/posts/postActions";
import { Post } from "store/posts/postConstants";

const useGetPostDetailsQuery = <
  SelectReturnType extends object | undefined | null = Post,
  PostRequired extends boolean = true,
>(
  postId?: string,
  queryOptions?: Omit<
    UseQueryOptions<
      PostRequired extends true ? Post : false | Partial<Post>,
      Error,
      SelectReturnType
    >,
    "queryKey" | "queryFn"
  >
) => {
  const dispatch = useAppDispatch();

  return useQuery({
    enabled: Boolean(postId),
    queryKey: ["post-detail", postId],
    queryFn: async () => {
      const postDetail = (
        postId ? dispatch(getPostDetailsThunk(postId)).unwrap() : false
      ) as Promise<PostRequired extends true ? Post : false | Partial<Post>>;
      return postDetail;
    },
    ...queryOptions,
  });
};

export default useGetPostDetailsQuery;
