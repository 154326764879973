import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Tooltip } from "antd";
import classNames from "classnames";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { ReactNode } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdDragIndicator } from "react-icons/md";

type DraggablePostProps = {
  id: string;
  children: ReactNode;
  handleDown?: () => void;
  handleUp?: () => void;
};

const DraggablePost = ({
  handleDown,
  handleUp,
  children,
  id,
}: DraggablePostProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const { hasPermission } = useUserPermissions();

  const dragButtonClasses = (enabled: boolean) =>
    classNames("active:cursor-grabbing focus:outline-none", {
      "opacity-50 !cursor-default": !enabled,
    });

  return (
    <div
      className={classNames(
        "w-full flex max-sm:flex-col items-center justify-center max-sm:gap-1 gap-3",
        {
          "relative z-[999]": isDragging,
        }
      )}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      {hasPermission("post_status_schedule_write") && (
        <div className="flex sm:flex-col items-center max-sm:gap-4 gap-2 [&_svg]:cursor-pointer">
          <Tooltip title={handleUp && "Move up"}>
            <FaChevronUp
              size={14}
              className={dragButtonClasses(Boolean(handleUp))}
              onClick={handleUp}
            />
          </Tooltip>
          <MdDragIndicator
            size={20}
            className={classNames(
              "max-sm:rotate-90",
              dragButtonClasses(Boolean(handleUp || handleDown))
            )}
            {...attributes}
            {...listeners}
          />
          <Tooltip title={handleDown ? "Move down" : ""}>
            <FaChevronDown
              className={dragButtonClasses(Boolean(handleDown))}
              onClick={handleDown}
            />
          </Tooltip>
        </div>
      )}
      {children}
    </div>
  );
};

export default DraggablePost;
