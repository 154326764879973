import useAppContext from "config/AppContext/useAppContext";
import env from "config/envVars";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import Logo from "./Logo";

const LogoLink = (props: {
  linkToDomain?: boolean;
  className?: string;
  linkToLandingPage?: boolean;
}) => {
  const defaultBusinessRoute = useDefaultBusinessRoute();
  const { isAgencyOwner } = useAppSelector(({ user }) => user);
  const { realm } = useAppContext();

  const className = `w-full h-7 lg:h-9 text-antd-colorText flex justify-start lg:block !no-underline ${props.className || ""}`;

  return (
    realm &&
    (props.linkToLandingPage || props.linkToDomain ? (
      <a
        href={
          props.linkToLandingPage ? env.LANDING_PAGE_URL : `//${realm.domain}`
        }
        className={className}
      >
        <Logo />
      </a>
    ) : (
      <Link
        to={isAgencyOwner ? "/dashboard" : defaultBusinessRoute}
        className={`px-5 ${className}`}
      >
        <Logo />
      </Link>
    ))
  );
};

export default LogoLink;
