const getInviteLink = ({
  token,
  email,
  whiteLabelDomain,
}: {
  whiteLabelDomain?: string;
  token: string;
  email: string;
}) => {
  const url = new URL(
    whiteLabelDomain ? `https://${whiteLabelDomain}` : window.location.origin
  );

  url.pathname = "/sign-up";
  url.searchParams.append("token", token);
  url.searchParams.append("email", email);
  url.searchParams.append("isInvited", "true");

  return url.toString();
};

export default getInviteLink;
