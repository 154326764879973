import { VOID_FUNCTION } from "fixtures/globalConstants";
import { observer } from "mobx-react-lite";
import { handleHotkey } from "polotno/canvas/hotkeys";
import Workspace from "polotno/canvas/workspace";
import { ElementType } from "polotno/model/group-model";
import { StoreType } from "polotno/model/store";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import { PageControlProps } from "../../polotnoTypes";
import ControlButton, { ControlButtonProps } from "./ControlButton";
import PageControls from "./PageControls";

const ImageWorkspace: ObserverComponent<{ store: StoreType }> = observer(
  ({ store }) => {
    const [copiedElements, setCopiedElements] = useState<null | object[]>(null);

    const copyElements = () =>
      store.selectedElements.length &&
      setCopiedElements(
        store.selectedElements.map(
          ({ id: _id, animations: _animations, ...element }) => element
        )
      );

    const pasteElements = () =>
      store.activePage &&
      copiedElements?.forEach((element) => {
        store.activePage.addElement({ ...(element as ElementType) });
      });

    const DUPLICATE_BUTTON_PROPS: ControlButtonProps = {
      title: "Copy",
      icon: <MdContentCopy />,
      onClick: copyElements,
      size: "small",
      type: "text",
    };

    const REMOVE_BUTTON_PROPS: ControlButtonProps = {
      title: "Remove",
      icon: <AiOutlineDelete />,
      onClick: () => store.deleteElements(store.selectedElementsIds),
      size: "small",
      type: "text",
    };

    const handleKeyDown = (e: KeyboardEvent, store: StoreType) => {
      if (e.key === "c" && (e.ctrlKey || e.metaKey)) {
        copyElements();
      }
      handleHotkey(e, store);
    };

    return (
      <Workspace
        store={store}
        onKeyDown={handleKeyDown}
        components={{
          PageControls: (props: PageControlProps) => (
            <PageControls
              {...props}
              disablePaste={!copiedElements}
              onPaste={pasteElements}
            />
          ),
          Position: VOID_FUNCTION,
          Duplicate: () => <ControlButton {...DUPLICATE_BUTTON_PROPS} />,
          Remove: () => <ControlButton {...REMOVE_BUTTON_PROPS} />,
          NoPages: () => (
            <div className="flex items-center justify-center h-full w-full">
              Loading...
            </div>
          ),
        }}
      />
    );
  }
);

export default ImageWorkspace;
