import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import useAppContext from "config/AppContext/useAppContext";
import useAddNewBusiness from "hooks/useAddNewBusiness";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "store";
import { selectUserInfo, sortedBusinessesGetter } from "store/user/userSlice";
import { BUSINESS_TABLE_COLUMNS } from "./agencyDashboardUtil";
import EmptyState from "./components/EmptyState";

const AgencyDashboard = () => {
  const userInfo = useAppSelector(selectUserInfo);
  const businesses = useAppSelector(sortedBusinessesGetter);
  const { handleNewBusinessCreation } = useAddNewBusiness();
  const {
    headerChildrenState: [, setHeaderChildren],
  } = useAppContext();

  useEffect(() => {
    setHeaderChildren(
      <Button
        icon={<PlusOutlined />}
        onClick={() => handleNewBusinessCreation()}
        className="place-self-end"
      >
        <span className="!hidden lg:!inline-block">Add New Business</span>
      </Button>
    );

    return () => setHeaderChildren(null);
  }, [handleNewBusinessCreation]);

  const dataSource = useMemo(
    () =>
      businesses.map((business, index) => ({
        ...business,
        key: index,
      })),
    [businesses]
  );

  const businessTableColumns = useMemo(
    () => BUSINESS_TABLE_COLUMNS(userInfo.id),
    [userInfo.id, BUSINESS_TABLE_COLUMNS]
  );

  // TODO: I don't think it's possible to get to this page without having any businesses, <EmptyState /> component not needed?
  return businesses.length === 0 ? (
    <EmptyState />
  ) : (
    <div className="business-table">
      <Table
        dataSource={dataSource}
        scroll={{ x: true }}
        pagination={false}
        columns={businessTableColumns}
      />
      <Button
        onClick={() => handleNewBusinessCreation()}
        className="create-client-btn"
        icon={<PlusOutlined />}
      >
        Add New Business
      </Button>
    </div>
  );
};

export default AgencyDashboard;
