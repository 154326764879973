import { ColumnsType } from "antd/es/table";
import InfoTooltip from "components/Common/InfoTooltip";
import dayjs from "dayjs";
import { Business } from "store/business/businessConstants";
import BusinessProfileIcon from "./components/BusinessProfileIcon";
import BusinessTierDropdown from "./components/BusinessTierDropdown";
import LastPublishedTime from "./components/LastPublishedTime";
import PostStatuses from "./components/PostStatuses";

const getPostsCount = (postsData: Business["posts_data"]) => {
  const {
    status_counts: { LIKED = 0, SCHEDULED = 0 },
    review_status_counts: { READY_FOR_REVIEW, CHANGES_REQUESTED, APPROVED },
  } = postsData;

  return (
    LIKED +
    SCHEDULED +
    (READY_FOR_REVIEW?.LIKED ?? 0) +
    (CHANGES_REQUESTED?.LIKED ?? 0) +
    (APPROVED?.LIKED ?? 0)
  );
};

const WHITE_LABEL_TIER_ORDER = { STANDARD: 1, ADVANCED: 2 };

const sortByWhitelabelTier = (a: Business, b: Business) => {
  const aValue = a.whitelabel_tier
    ? WHITE_LABEL_TIER_ORDER[a.whitelabel_tier]
    : 0;
  const bValue = b.whitelabel_tier
    ? WHITE_LABEL_TIER_ORDER[b.whitelabel_tier]
    : 0;

  return aValue - bValue;
};

const sortByLastPublishedTime = (a: Business, b: Business) => {
  const dateA = a.posts_data?.last_published_time
    ? dayjs(a.posts_data.last_published_time)
    : dayjs(0);
  const dateB = b.posts_data?.last_published_time
    ? dayjs(b.posts_data.last_published_time)
    : dayjs(0);

  return dateA.diff(dateB);
};

export const BUSINESS_TABLE_COLUMNS = (
  userId: string
): ColumnsType<Business & { key: number }> => [
  {
    title: "Profile",
    key: "profile",
    sorter: (a, b) => a.title.localeCompare(b.title),
    defaultSortOrder: "ascend",
    render: (_, { key: _key, ...business }) => (
      <BusinessProfileIcon {...business} />
    ),
  },
  {
    title: (
      <div>
        White Labeling
        <InfoTooltip title="Standard includes whitelabeling, client portal, and review process. Upgrade to advanced to enable editing and custom permissions for clients." />
      </div>
    ),
    key: "tier",
    sorter: sortByWhitelabelTier,
    render: (_, { key: _key, ...business }) => (
      <BusinessTierDropdown
        {...business}
        disabled={business.user_id !== userId}
      />
    ),
  },
  {
    title: "Status",
    key: "postCounts",
    dataIndex: "posts_data",
    sorter: (a, b) => getPostsCount(a.posts_data) - getPostsCount(b.posts_data),
    render: (postsData: Business["posts_data"]) => {
      if (postsData) {
        const {
          status_counts: { LIKED, SCHEDULED },
          review_status_counts: {
            READY_FOR_REVIEW,
            CHANGES_REQUESTED,
            APPROVED,
          },
        } = postsData;

        return (
          <PostStatuses
            postStatuses={{
              LIKED,
              SCHEDULED,
              READY_FOR_REVIEW: READY_FOR_REVIEW?.LIKED,
              CHANGES_REQUESTED: CHANGES_REQUESTED?.LIKED,
              APPROVED: APPROVED?.LIKED,
            }}
          />
        );
      }
    },
  },
  {
    title: "Last Published",
    key: "lastPublished",
    dataIndex: "posts_data",
    sorter: sortByLastPublishedTime,
    render: (postsData: Business["posts_data"]) => (
      <LastPublishedTime postsData={postsData} />
    ),
  },
];
