import { CopyOutlined } from "@ant-design/icons";
import { Badge, Button, Input, message } from "antd";
import { getOTC } from "api/user";
import ImageUploader from "components/Common/ImageUploader";
import InfoTooltip from "components/Common/InfoTooltip";
import { genericError } from "fixtures/globalConstants";
import { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import { MdOpenInNew, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { fetchRealmDetails, updateRealmDetails } from "store/user/userActions";
import { uploadToS3 } from "utils/generalUtils";
import AgencyNameEditor from "./components/AgencyNameEditor";
import DomainColorPicker from "./components/DomainColorPicker";
import DomainSetupInstructions from "./components/DomainSetupInstructions";
import SetupCustomDomainModal from "./components/SetupCustomDomainModal";
import { getCNameStatus, ROOT_WHITE_LABEL_DOMAIN } from "./whiteLabelConstants";

const WhiteLabel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ownedRealm, userInfo } = useAppSelector((state) => state.user);

  const isBasicDomainActive = ownedRealm
    ? ownedRealm.domain.endsWith(ROOT_WHITE_LABEL_DOMAIN)
    : false;

  const [isBasicDomain, setIsBasicDomain] = useState(isBasicDomainActive);
  const [isEditingDomain, setIsEditingDomain] = useState(false);
  const [setupDomainModalOpen, setSetupDomainModalOpen] = useState(false);
  const [domain, setDomain] = useState("");
  const [loadingButton, setLoadingButton] = useState<
    "verify" | "save" | "preview" | "save-logo" | null
  >(null);

  const isSavingDomain = loadingButton === "save";

  const { tooltip, label, status } = getCNameStatus(ownedRealm?.cname);

  const resetDomain = (basicDomain?: boolean) => {
    if (!ownedRealm) {
      return;
    }

    if (basicDomain ?? isBasicDomain) {
      const subDomain = isBasicDomainActive
        ? ownedRealm.domain.match(/^(.*?)\.[^.]+\.[^.]+$/)?.[1] || "" // Extract the subdomain
        : "";
      setDomain(subDomain);
    } else {
      setDomain(!isBasicDomainActive ? ownedRealm.domain : "");
    }
  };

  useEffect(() => {
    if (!ownedRealm) {
      navigate("/");
    }
  }, [ownedRealm]);

  useEffect(() => {
    ownedRealm && setIsBasicDomain(isBasicDomainActive);
  }, [isBasicDomainActive, isEditingDomain]);

  const isCustomDomainInvalid = useMemo(
    () =>
      domain
        ? !/^(?!:\/\/)(?!.*\.mymarky\.ai$)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,63}$/.test(
            domain
          )
        : false,
    [domain, isBasicDomain, domain]
  );

  useEffect(() => {
    resetDomain();
  }, []);

  const toggleIsBasicDomain = () => {
    setIsBasicDomain((prev) => !prev);
    resetDomain(!isBasicDomain);
  };

  const toggleIsEditing = () => {
    setIsEditingDomain((prev) => !prev);
    resetDomain();
  };

  useEffect(() => {
    if (!ownedRealm?.id || status !== "warning") {
      return;
    }

    const interval = setInterval(
      () => dispatch(fetchRealmDetails(ownedRealm.id as string)),
      10000
    );

    return () => clearInterval(interval);
  }, [
    ownedRealm?.cname?.ssl_status,
    ownedRealm?.cname?.cloudfront_distribution_status,
  ]);

  if (!ownedRealm) {
    return null;
  }

  const updateLogo = async (event?: BaseSyntheticEvent) => {
    setLoadingButton("save-logo");

    let logoURL = "";

    try {
      if (event) {
        logoURL = await uploadToS3(event.target.files[0]);
      }

      await dispatch(
        updateRealmDetails({
          ...ownedRealm,
          logo_url: logoURL,
        })
      );
    } catch (error) {
      message.error("Couldn't save logo. Please try again later.");
    }

    setLoadingButton(null);
  };

  const saveDomain = async () => {
    setLoadingButton("save");
    try {
      await dispatch(
        updateRealmDetails({
          ...ownedRealm,
          domain: isBasicDomain ? domain + ROOT_WHITE_LABEL_DOMAIN : domain,
        })
      );
      setIsEditingDomain(false);

      setSetupDomainModalOpen(!isBasicDomain);
    } catch (error) {
      console.error(error);
    }
    setLoadingButton(null);
  };

  const handlePreviewClick = async () => {
    try {
      setLoadingButton("preview");
      const data = await getOTC(userInfo.id);
      window.location.href =
        "https://" + ownedRealm.domain + `/preview?code=${data.one_time_code}`;
      setLoadingButton(null);
    } catch (error) {
      genericError(error);
    }
  };

  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="card">
        <header>
          <h2>White Labeling</h2>
          <span>
            Personalize the look of the client portal to represent your agency.
          </span>
        </header>
        <main className="space-y-4">
          <div className="space-y-2">
            <div className="text-sm font-semibold">Logo</div>
            <ImageUploader
              isLoading={loadingButton === "save-logo"}
              image={ownedRealm?.logo_url}
              handleChange={updateLogo}
              name="white-label-log"
              isCircle={false}
            />
          </div>
          <DomainColorPicker realm={ownedRealm} />
          <AgencyNameEditor />
        </main>
      </div>

      <div className="card">
        <header>
          <h2>Client Domain</h2>
          <span>Hook up your own custom domain for the client portal.</span>
        </header>

        <main className="space-y-3">
          <div>
            <label
              htmlFor="basic-domain"
              className="block mb-1 text-sm font-semibold"
            >
              {isBasicDomain ? "Basic" : "Custom"} Domain{" "}
              <InfoTooltip
                title={
                  isBasicDomain
                    ? "Using Marky's provided domain. If you have your own domain you'd like to use, switch to CNAME Domain."
                    : "You are currently using a CNAME domain. If you don't have your own CNAME, you can switch to a basic domain."
                }
              />
            </label>
            {isEditingDomain ? (
              <>
                <Input
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                  id="basic-domain"
                  addonAfter={isBasicDomain ? ROOT_WHITE_LABEL_DOMAIN : ""}
                  disabled={isSavingDomain}
                />
                {!isBasicDomain && isCustomDomainInvalid && (
                  <p className="text-antd-red text-sm mt-1">
                    {domain.includes("mymarky.ai")
                      ? "mymarky.ai domains are not allowed as custom domains"
                      : "Please enter a valid domain"}
                  </p>
                )}
                <Button
                  onClick={toggleIsBasicDomain}
                  disabled={isSavingDomain}
                  className="px-0"
                  type="link"
                >
                  Switch to {isBasicDomain ? "Custom" : "Basic"} Domain
                </Button>
              </>
            ) : (
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <span className="text-sm">{ownedRealm.domain}</span>
                  {isBasicDomain && (
                    <Button
                      type="text"
                      className="p-2 h-max"
                      onClick={() => {
                        navigator.clipboard.writeText(ownedRealm.domain);
                        message.success("Domain copied successfully.");
                      }}
                    >
                      <CopyOutlined />
                    </Button>
                  )}
                </div>

                {!isBasicDomain && (
                  <>
                    {ownedRealm.cname?.ssl_status && (
                      <div className="flex text-sm">
                        <Badge status={status} text={label} />
                        <InfoTooltip title={tooltip} />
                      </div>
                    )}
                    {status === "warning" && <DomainSetupInstructions />}
                  </>
                )}
                <Button
                  loading={loadingButton === "preview"}
                  icon={<MdOpenInNew size={18} />}
                  onClick={handlePreviewClick}
                  type="primary"
                >
                  Preview
                </Button>
              </div>
            )}
          </div>
        </main>

        <footer>
          {isEditingDomain ? (
            <>
              <Button onClick={toggleIsEditing}>Cancel</Button>
              <Button
                disabled={
                  isBasicDomain ? !domain : !domain || isCustomDomainInvalid
                }
                loading={isSavingDomain}
                onClick={saveDomain}
                type="primary"
              >
                Save
              </Button>
            </>
          ) : (
            <Button onClick={toggleIsEditing} icon={<MdOutlineEdit />}>
              Edit
            </Button>
          )}
        </footer>
      </div>
      <SetupCustomDomainModal
        isOpen={setupDomainModalOpen}
        close={() => {
          setSetupDomainModalOpen(false);
        }}
      />
    </div>
  );
};

export default WhiteLabel;
