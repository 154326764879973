import { Button, Modal } from "antd";
import { Link } from "react-router-dom";

export type TourModalData = {
  title: string;
  description: string;
  btnText?: string;
  pathname?: string;
};

const TourModal = ({
  close,
  data,
}: {
  close: () => void;
  data: TourModalData;
}) => {
  const Footer = () => {
    if (!data.btnText || !data.pathname) {
      return (
        <div className="tour-modal__footer">
          <Button type="primary" onClick={close}>
            Okay
          </Button>
        </div>
      );
    }

    return (
      <div className="tour-modal__footer">
        <Button onClick={close}>Close</Button>
        <Link to={data.pathname}>
          <Button type="primary" className="!w-fit">
            {data.btnText}
          </Button>
        </Link>
      </div>
    );
  };

  return (
    <Modal
      open
      centered={true}
      className="tour-modal"
      closable={false}
      footer={<Footer />}
      onCancel={close}
    >
      <div className="tour-modal__container">
        <div className="tour-modal__right">
          <h3 className="tour-modal__heading">{data.title}</h3>
          <p className="tour-modal__content">{data.description}</p>
        </div>
      </div>
    </Modal>
  );
};

export default TourModal;
