import { AuthCredentials, SelectedPage } from "api/config/chalice-api";
import { IntegrationType } from "store/user/userConstants";

export const TIKTOK_INITIAL_SETTINGS = {
  directPublish: false,
  autoAddMusic: false,
};

export const INTEGRATIONS_REQUIRING_PAGE_SELECTION = [
  "pinterest",
  "facebook",
  "linkedIn",
  "googleBusiness",
] as const;

export const INTEGRATIONS_WITH_SETTINGS = [
  "pinterest",
  "facebook",
  "linkedIn",
  "googleBusiness",
  "tiktok",
] as const;

export type IntegrationWithSettings =
  (typeof INTEGRATIONS_WITH_SETTINGS)[number];

export type IntegrationRequiringPageSelectionType =
  (typeof INTEGRATIONS_REQUIRING_PAGE_SELECTION)[number];

export const DIRECT_PUBLISH_TOOLTIP = (
  <span>
    If enabled, we directly post the content to your feed; if off, we upload
    content to TikTok drafts for you to complete the post using TikTok&apos;s
    editing flow.{" "}
    <a
      href="https://help.mymarky.ai/en/article/how-to-publish-a-tiktok-draft-grslah/?bust=1710615482309"
      rel="noreferrer"
      className="global-link"
      target="_blank"
    >
      {" "}
      See how to find your drafts
    </a>
  </span>
);

export const AUTO_ADD_MUSIC_TOOLTIP =
  "If enabled, TikTok will automatically add recommended music to photos. You can choose to change the post's music in TikTok app after it's posted if desired. Note that we have no choice on what music gets chosen initially.";

export const DISABLED_STATE_TOOLTIP =
  "Enable Direct Publish first in order to enable this feature.";

export type PublishSettingType = {
  brandOrganicToggle: boolean;
  aiGeneratedTitle: boolean;
  directPublish: boolean;
  autoAddMusic: boolean;
  autoResize: boolean;
};

export type PublishSettingItemType = {
  disabledStateTooltip?: string;
  disabled?: boolean;
  checked: boolean;
  tooltip: string | JSX.Element;
  label: string;
  field: keyof PublishSettingType;
};

export const getSettingItems = ({
  publishSettings,
  integrationType,
}: {
  publishSettings: PublishSettingType;
  integrationType: IntegrationWithSettings;
}): PublishSettingItemType[] => {
  const AI_GENERATED_TITLE_SETTING: PublishSettingItemType = {
    label: "AI Generated Title",
    field: "aiGeneratedTitle",
    checked: publishSettings.aiGeneratedTitle,
    tooltip:
      "If enabled, you authorize AI to choose a title for the post based off the caption. If disabled, we will just use the first few words of the caption as the title.",
  };

  if (
    ["pinterest", "facebook", "linkedIn", "googleBusiness"].includes(
      integrationType
    )
  ) {
    return [AI_GENERATED_TITLE_SETTING];
  } else {
    return [
      AI_GENERATED_TITLE_SETTING,
      {
        label: "Add 'Promotional' label",
        field: "brandOrganicToggle",
        checked: publishSettings.brandOrganicToggle,
        tooltip: (
          <p>
            This will add a <i>Promotional</i> label under each post. According
            to tiktok, this is required for brand promotional content and does
            not effect the distribution of your video. Although we leave it up
            to you to choose.{" "}
            <a href="https://support.tiktok.com/en/business-and-creator/creator-and-business-accounts/promoting-a-brand-product-or-service#1">
              Read more.
            </a>
          </p>
        ),
      },
      {
        label: "Direct Publish",
        field: "directPublish",
        checked: publishSettings.directPublish,
        tooltip: DIRECT_PUBLISH_TOOLTIP,
      },
      {
        label: "Auto-add music to photos",
        field: "autoAddMusic",
        checked: publishSettings.autoAddMusic,
        disabled: !publishSettings.directPublish,
        tooltip: AUTO_ADD_MUSIC_TOOLTIP,
        disabledStateTooltip: !publishSettings.directPublish
          ? DISABLED_STATE_TOOLTIP
          : "",
      },
    ];
  }
};

export const INSTAGRAM_INTEGRATION = ["instagram", "instagramStory"] as const;

export type InstagramIntegrationType = (typeof INSTAGRAM_INTEGRATION)[number];

export type NonInstagramIntegration = Exclude<
  IntegrationType,
  InstagramIntegrationType
>;

export type HandleIntegrationConnect = (params: {
  publishSettings?: PublishSettingType;
  authCredentials: AuthCredentials | null;
  type: NonInstagramIntegration;
  selectedPage?: SelectedPage;
}) => Promise<void>;

export const INTEGRATIONS_ROUTE = "/integrations";
