import { ItemType } from "antd/es/menu/interface";
import { AppFeatures } from "config/Growthbook/feature-flags";
import { PermissionString } from "config/UserPermissionsContext/userPermissionsConstants";
import { MdOutlineDelete, MdOutlineEdit, MdOutlineTune } from "react-icons/md";

export const TOPIC_ACTIONS: (ItemType & {
  requiresPerm?: PermissionString;
  requiresEnabledTopic?: boolean;
  requiresFlag?: keyof AppFeatures;
})[] = [
  {
    key: "generate-posts",
    label: "Generate from Topic",
    icon: <MdOutlineTune size={16} />,
    requiresEnabledTopic: true,
    requiresPerm: "post_status_generate_write",
    requiresFlag: "new-generator-panel",
  },

  {
    key: "edit-topic",
    label: "Edit",
    icon: <MdOutlineEdit size={16} />,
    requiresPerm: "business_details_write",
  },
  {
    key: "delete",
    label: "Delete",
    icon: <MdOutlineDelete size={16} />,
    requiresPerm: "business_details_write",
  },
];
