import { ColorValueType } from "antd/es/color-picker/interface";
import ColorPickerWithPresets from "components/Common/ColorPickerWithPresets";
import { observer } from "mobx-react-lite";
import { SVGElementType } from "polotno/model/svg-model";
import { useMemo } from "react";

const SVGColorFill: ObserverComponent<{
  element: SVGElementType;
}> = observer(({ element }) => {
  const colorsReplaceJson = element.colorsReplace.toJSON();

  const colors = useMemo(() => {
    try {
      return Object.entries(colorsReplaceJson).reduce(
        (acc, [key, value]) => {
          if (!Object.keys(acc).length) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, ColorValueType>
      );
    } catch (error) {
      return [];
    }
  }, [colorsReplaceJson]);

  return (
    <div className="mr-5">
      {Object.entries(colors).map(([key, color], i) => (
        <ColorPickerWithPresets
          style={{ marginLeft: 5 }}
          key={i}
          value={color}
          onChange={(value) => {
            element.replaceColor(key, value.toHexString());
          }}
        />
      ))}
    </div>
  );
});

export default SVGColorFill;
