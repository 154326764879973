import Link from "antd/es/typography/Link";
import dayjs from "dayjs";
import PublishErrorModal from "pages/Posts/components/PublishErrorModal";
import { ReactNode, useMemo, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import { Post } from "store/posts/postConstants";
import { formatSocialName } from "utils/generalUtils";
import groupPublishLinksByState, {
  PublishError,
} from "utils/groupPublishLinksByState";

const PublishDetails = ({ post }: { post: Post<"PUBLISHED"> }) => {
  const [publishError, setPublishError] = useState<PublishError | null>(null);

  const publishedDate = dayjs(post.published_at);

  const { successLinks, failedLinks, pendingLinks, totalLinksCount } =
    useMemo(() => {
      const publishLinks = groupPublishLinksByState(post.publish_results);

      return {
        ...publishLinks,
        totalLinksCount:
          publishLinks.successLinks.count +
          publishLinks.failedLinks.count +
          publishLinks.pendingLinks.count,
      };
    }, [post.publish_results]);

  return (
    <div className="space-y-4 text-antd-colorTextSecondary">
      {!totalLinksCount && "No data available"}
      <PublishDetailSection
        title="Published on"
        subtitle={
          <p>
            <strong className="text-antd-colorPrimary">
              {publishedDate.format("MMMM D")}
            </strong>{" "}
            at{" "}
            <strong className="text-antd-colorPrimary">
              {publishedDate.format("hh:mm A")}
            </strong>
          </p>
        }
        childrenDict={successLinks.data}
        children={([social, link]) => (
          <Link
            key={social}
            href={link}
            target="_blank"
            className="block"
            underline={Boolean(link)}
            disabled={!link}
          >
            {formatSocialName(social)}
          </Link>
        )}
      />
      <PublishDetailSection
        title={
          <p className="flex items-center gap-1 text-antd-colorError">
            <MdErrorOutline /> Failed to publish to:
          </p>
        }
        childrenDict={failedLinks.data}
        children={([social, error]) => (
          <div
            className="flex items-center gap-2.5 text-antd-colorTextDisabled"
            key={social}
          >
            {formatSocialName(social)}{" "}
            <Link underline onClick={() => setPublishError(error)}>
              more info
            </Link>
          </div>
        )}
      />
      <PublishDetailSection
        title="Publishing in progress for:"
        childrenDict={pendingLinks.data}
        children={([social]) => (
          <div key={social}>{formatSocialName(social)}</div>
        )}
      />

      <PublishErrorModal
        key={publishError?.name}
        post={post}
        error={publishError}
        close={() => setPublishError(null)}
      />
    </div>
  );
};

type ObjectEntry<T extends object> = [keyof T, T[keyof T]];
type PublishDetailSectionProps<T extends object> = {
  title: string | ReactNode;
  subtitle?: ReactNode;
  childrenDict: T;
  children: (child: ObjectEntry<T>) => ReactNode;
};

const PublishDetailSection = <T extends object>({
  title,
  subtitle,
  childrenDict,
  children,
}: PublishDetailSectionProps<T>) => {
  const childrenEntries = Object.entries(childrenDict) as ObjectEntry<T>[];

  return (
    childrenEntries.length > 0 && (
      <div className="space-y-2">
        <div>
          {title}
          {subtitle}
        </div>
        <div className="space-y-1">{childrenEntries.map(children)}</div>
      </div>
    )
  );
};

export default PublishDetails;
