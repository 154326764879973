import { Button, Dropdown, Input, InputRef, Tag } from "antd";
import axios from "axios";
import { genericError } from "fixtures/globalConstants";
import useDebounce from "hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import { MdPhotoSizeSelectActual } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useClickAway } from "react-use";
import { imageSearchTermsGetter } from "store/user/userSlice";
import { UnsplashImage } from "../../polotnoTypes";

const ImageSearch = ({
  handleImageClick,
}: {
  handleImageClick: (data: string) => void;
}) => {
  const imageSearchTerms = useSelector(imageSearchTermsGetter);
  const [unsplashImages, setUnsplashImages] = useState([] as UnsplashImage[]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  let controller: AbortController;
  const debouncedSearchQuery = useDebounce(
    searchInput,
    searchInput === "" ? 0 : 700
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<InputRef>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickAway(dropdownRef, () => setOpen(false));

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    fetchUnsplashImages(debouncedSearchQuery);
  }, [page, debouncedSearchQuery]);

  let focusTimeout: ReturnType<typeof setTimeout>;
  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setSearchInput("");
      setPage(1);
      setUnsplashImages([]);
    } else {
      clearTimeout(focusTimeout);
      focusTimeout = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleScroll = (event: React.UIEvent) => {
    const target = event.target as Element;

    if (
      target.scrollHeight - target.scrollTop < 950 * page &&
      page !== Math.ceil(unsplashImages.length / 10) + 1
    ) {
      setPage(Math.ceil(unsplashImages.length / 10) + 1);
    }
  };

  // TODO: Use useQuery, then can add loading state easily
  const fetchUnsplashImages = async (query?: string) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    try {
      const { data } = await axios.get<
        { results: UnsplashImage[] } | UnsplashImage[]
      >(
        query
          ? "https://api.unsplash.com/search/photos"
          : "https://api.unsplash.com/photos",
        {
          signal: controller.signal,
          params: {
            client_id: import.meta.env.VITE_UNSPLASH_CLIENT_ACCESS_KEY,
            query,
            page,
          },
        }
      );

      const results = "results" in data ? data.results : data;

      results &&
        setUnsplashImages(
          page === 1 ? results : (prev) => prev.concat(results)
        );
      if (page === 1 && scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    } catch (error) {
      genericError(error);
    }
  };

  return (
    <Dropdown
      trigger={["click"]}
      open={open}
      placement="bottom"
      dropdownRender={() => (
        <div
          ref={dropdownRef}
          className="bg-antd-colorBgContainer p-4 rounded flex flex-col gap-2 max-h-[75vh] w-[80vw] shadow"
        >
          <Input
            placeholder="Search for images"
            ref={inputRef}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="h-8"
          />
          <div className="flex flex-wrap gap-y-2">
            {imageSearchTerms.slice(0, 4).map((searchTerm) => (
              <Tag
                className="cursor-pointer"
                key={searchTerm}
                onClick={() => setSearchInput(searchTerm)}
              >
                {searchTerm}
              </Tag>
            ))}
          </div>

          <div
            ref={scrollContainerRef}
            className="-mx-4 pl-4 pr-2 overflow-auto flex-grow space-y-4"
            onScroll={handleScroll}
          >
            {unsplashImages.map((item, index) => (
              <div
                key={`${item.id}-${index}`}
                className="text-center text-antd-colorTextDescription w-full cursor-pointer"
                onClick={() => {
                  handleImageClick(item.urls.small);
                  setOpen(false);
                }}
              >
                <img
                  className="w-full rounded"
                  src={item.urls.small}
                  alt={item.alt_description}
                />
                <p>
                  Photo by{" "}
                  <Link
                    to={`${item.user.links.html}/?utm_source=marky&utm_medium=referral`}
                    target="_blank"
                    className="text-antd-colorTextDescription"
                  >
                    {item.user.name}
                  </Link>{" "}
                  on{" "}
                  <Link
                    to="https://unsplash.com/?utm_source=marky&utm_medium=referral"
                    target="_blank"
                    className="text-antd-colorTextDescription"
                  >
                    Unsplash
                  </Link>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      onOpenChange={(isOpen) => onOpenChange(isOpen)}
    >
      <Button size="small" onClick={() => setOpen(true)}>
        <MdPhotoSizeSelectActual size={20} /> Change Image
      </Button>
    </Dropdown>
  );
};

export default ImageSearch;
