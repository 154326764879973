import { ValidGlobalModalData } from "components/GlobalModals/GlobalModalContext/globalModalConfig";
import useGlobalModal from "components/GlobalModals/GlobalModalContext/useGlobalModal";
import { ENV_NAME } from "config/envVars";
import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectUserInfo } from "store/user/userSlice";

// TODO: Tech debt, consider refactoring this hook https://github.com/Marky-Team/web-frontend/pull/837#discussion_r1968762810
const useAddNewBusiness = () => {
  const { openGlobalModal } = useGlobalModal();

  const { businesses } = useAppSelector((state) => state.user);
  const user = useAppSelector(selectUserInfo);
  const subscription = user.subscription;

  const usersBusinesses = useMemo(() => {
    return businesses.filter(({ user_id }) => user_id === user.id);
  }, [user, businesses]);

  const handleNewBusinessCreation = (increaseLimit?: boolean) => {
    if (user.isAnonymous) {
      return openGlobalModal({
        modalName: "signup",
        modalData: {
          successCallback: handleNewBusinessCreation,
        },
      });
    }

    const isBusinessLimitReached =
      (subscription?.restrictions?.businesses ?? 0) +
        (subscription?.extra_businesses ?? 0) <=
      usersBusinesses.length;

    const isFreePlan = subscription?.plan === "free";

    if (
      ENV_NAME !== "DEV" &&
      (isFreePlan || isBusinessLimitReached || increaseLimit) &&
      !subscription?.is_appsumo
    ) {
      return openGlobalModal({
        modalName: "subscription",
        modalData: {
          modalType: isFreePlan ? "addNewBusiness" : "businessLimitReached",
        },
      } as ValidGlobalModalData);
    }

    if (
      (isBusinessLimitReached || increaseLimit) &&
      subscription?.is_appsumo &&
      subscription?.plan !== "marky_tier3"
    ) {
      openGlobalModal({
        modalName: "appsumo-plans",
        modalData: { modalType: "businessLimitReached" },
      });
      return;
    }

    localStorage.removeItem("newBusinessDetails");
    openGlobalModal({ modalName: "create-business" });
  };

  return { handleNewBusinessCreation };
};

export default useAddNewBusiness;
