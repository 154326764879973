import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useMemo } from "react";
import {
  MdCheck,
  MdErrorOutline,
  MdInfoOutline,
  MdLink,
  MdWarningAmber,
} from "react-icons/md";
import { Post } from "store/posts/postConstants";
import groupPublishLinksByState from "utils/groupPublishLinksByState";
import PublishDetails from "../components/PublishDetails";

const STATUS_ICONS = {
  noResults: <MdInfoOutline className="text-antd-colorInfo" />,
  successOnly: <MdCheck className="text-antd-colorSuccess" />,
  failureMix: <MdWarningAmber className="text-antd-colorWarning" />,
  failureOnly: <MdErrorOutline className="text-antd-colorError" />,
  pendingOnly: <LoadingOutlined className="text-antd-colorIcon" />,
};

type StatusIconKey = keyof typeof STATUS_ICONS;

const PublishDetailsPopover = ({ post }: { post: Post<"PUBLISHED"> }) => {
  const statusIconKey = useMemo((): StatusIconKey => {
    const { successLinks, pendingLinks, failedLinks } =
      groupPublishLinksByState(post.publish_results);

    if (successLinks.count && !(pendingLinks.count + failedLinks.count)) {
      return "successOnly";
    } else if (failedLinks.count) {
      if (successLinks.count || pendingLinks.count) {
        return "failureMix";
      } else {
        return "failureOnly";
      }
    } else if (pendingLinks.count && !successLinks.count) {
      return "pendingOnly";
    }
    return "noResults";
  }, [post.publish_results]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        overlayClassName="z-10"
        trigger={["click"]}
        className="h-full flex gap-1 items-center"
        content={<PublishDetails post={post} />}
      >
        {STATUS_ICONS[statusIconKey]}
        <MdLink className="min-w-4 text-antd-colorIcon cursor-pointer" />
      </Popover>
    </div>
  );
};

export default PublishDetailsPopover;
