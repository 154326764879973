import { removeAuthToken } from "api/config/axiosConfig";
import useDefaultBusinessRoute from "hooks/useDefaultBusinessRoute";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store";
import { resetPostData } from "store/posts/postSlice";
import { resetThemes } from "store/themes/themesSlice";
import { resetUser, selectUserInfo } from "store/user/userSlice";

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { isAnonymous } = useAppSelector(selectUserInfo);
  const defaultBusinessRoute = useDefaultBusinessRoute();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isAppsumo = searchParams.get("source") === "appsumo";

    if ((isAnonymous && pathname === "/sign-up") || pathname === "/login") {
      return;
    }

    if (isAppsumo) {
      dispatch(resetUser());
      dispatch(resetThemes());
      dispatch(resetPostData());
      localStorage.clear();
      removeAuthToken();
      return;
    }

    if (token) {
      navigate(defaultBusinessRoute);
    }
  }, [defaultBusinessRoute]);

  return (
    <div className="col-span-full row-span-full w-full overflow-auto">
      {children}
    </div>
  );
};

export default AuthWrapper;
