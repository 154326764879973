import { InfoCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import LanguageSelect from "components/Common/LanguageSelect";
import { BusinessDetails } from "pages/NewBusinessOnboarding/businessOnboardingConstants";
import { MdAdd } from "react-icons/md";
import { SectionItem } from "../businessFormConstants";

type BusinessInfoSectionProps = {
  sectionItems: SectionItem[];
  form: FormInstance<BusinessDetails>;
};

const BusinessSectionTemplate = ({
  sectionItems,
  form,
}: BusinessInfoSectionProps) => {
  const { getFieldValue, setFieldValue } = form;

  return (
    <div>
      {sectionItems.map(({ field, label, hideFromOnboarding, ...item }) => {
        if (hideFromOnboarding) {
          return null;
        }

        const curValue = getFieldValue(field);
        const isArray = Array.isArray(curValue) || item.type === "array";

        return (
          <Form.Item
            key={field}
            name={field}
            label={
              <div className="flex gap-2 items-center">
                <span>{label}</span>

                {item.tooltip && (
                  <Tooltip title={item.tooltip}>
                    <InfoCircleOutlined className="text-antd-colorIcon" />
                  </Tooltip>
                )}
              </div>
            }
            rules={item.rules}
          >
            {isArray ? (
              <Form.List key={field} name={field}>
                {(arrayFields, { add, remove }) => (
                  <div className="space-y-2">
                    {arrayFields.map((arrayField, index) => (
                      <div key={index} className="flex items-center gap-3">
                        <Form.Item key={arrayField.key} name={[index]} noStyle>
                          {field === "ctas" ? (
                            <Input maxLength={400} />
                          ) : (
                            <TextArea
                              autoSize={{ minRows: 1, maxRows: 15 }}
                              maxLength={300}
                            />
                          )}
                        </Form.Item>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => remove(index)}
                          className="text-antd-colorError"
                          icon={<MinusCircleOutlined />}
                        />
                      </div>
                    ))}

                    <Button onClick={() => add("")}>
                      <MdAdd size={18} onClick={() => add("")} /> New CTA
                    </Button>
                  </div>
                )}
              </Form.List>
            ) : item.type === "textarea" ? (
              <TextArea
                placeholder={item.placeholder ?? "Enter"}
                value={curValue}
                onChange={(e) => setFieldValue(field, e.target.value)}
                autoSize={{ minRows: 1, maxRows: 15 }}
                maxLength={400}
              />
            ) : item.type === "text" ? (
              <Input
                type="text"
                placeholder={item.placeholder ?? "Enter"}
                value={curValue}
                onChange={(e) => setFieldValue(field, e.target.value)}
                addonBefore={item.prefix}
                maxLength={400}
              />
            ) : (
              item.type === "languageSelect" && (
                <LanguageSelect
                  handleChange={(value) => setFieldValue(field, value)}
                  currentValue={curValue}
                />
              )
            )}
          </Form.Item>
        );
      })}
    </div>
  );
};

export default BusinessSectionTemplate;
