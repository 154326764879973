import { ReactComponent as StreakFireIcon } from "assets/icons/streak-fire.svg";
import AvatarInitials from "components/AvatarInitials";
import InfoTooltip from "components/Common/InfoTooltip";
import pluralize from "pluralize";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "store";
import { getFullName } from "store/user/userActions";

const BusinessHeader = () => {
  const { currentBusiness, userInfo } = useAppSelector((state) => state.user);

  return currentBusiness ? (
    <div className="w-full space-y-8">
      <div className="flex gap-4 items-center">
        <AvatarInitials size={72} business={currentBusiness} />
        <div>
          <div className="font-medium text-xl truncate max-w-[60vw]">
            {currentBusiness.title}
          </div>
          <div className="text-sm text-antd-colorTextTertiary">
            Logged in as {getFullName(userInfo)}
          </div>
        </div>
      </div>
      <div className="rounded-lg bg-antd-colorBgContainer dark:border border-antd-colorBorder p-4 pb-5 w-52 text-antd-colorTextSecondary text-sm flex flex-col gap-4">
        <div className="flex justify-between">
          Posting Streak
          <InfoTooltip title="Your posting streak is calculated based on your schedule. Always have posts scheduled to keep your streak going!" />
        </div>
        <div className="flex-grow flex items-baseline gap-1">
          <StreakFireIcon className="inline-block h-7" />
          <span className="text-3xl text-antd-colorText font-semibold">
            {currentBusiness.posting_streak}
          </span>{" "}
          {pluralize("post", currentBusiness.posting_streak)}
        </div>
      </div>
    </div>
  ) : (
    <Skeleton className="h-16" />
  );
};

export default BusinessHeader;
