import OpenGlobalModal from "components/GlobalModals/GlobalModalContext/OpenGlobalModal";
import BusinessProfilePage from "pages/BusinessProfile/BusinessProfilePage";
import ImageLibrary from "pages/ImageLibrary/ImageLibrary";
import IndexRoutePage from "pages/IndexRoutePage";
import IntegrationProxy from "pages/IntegrationProxy";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import MemoryPage from "pages/Memory/MemoryPage";
import {
  GENERATE_FOR_ANONYMOUS_ROUTE,
  ONBOARDING_ROUTE,
} from "pages/NewBusinessOnboarding/BusinessOnboardingModal";
import { POST_ANALYTICS_ROUTE } from "pages/PostAnalytics/postAnalyticsFixtures";
import PostAnalyticsProvider from "pages/PostAnalytics/PostAnalyticsProvider";
import PostCalendarPage from "pages/Posts/PostCalendarPage";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { POST_CALENDAR_ROUTE } from "pages/Posts/utils/postCalendarFixtures";
import Preview from "pages/Preview";
import ReferralReroute, { REFERRAL_REROUTE_ROUTE } from "pages/ReferralReroute";
import TeamMembers from "pages/Teams/components/TeamMembers/TeamMembers";
import ThemesPage from "pages/Themes/ThemesPage";
import TopicsPage from "pages/Topics/TopicsPage";
import WhiteLabel from "pages/WhiteLabel/WhiteLabel";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import AgencyDashboard from "./pages/AgencyDashboard/AgencyDashboard";
import ForgotPassword from "./pages/Authorization/ForgotPassword";
import FrillSSO from "./pages/Authorization/FrillSSO";
import Login from "./pages/Authorization/Login";
import Logout from "./pages/Authorization/Logout";
import ResetPassword from "./pages/Authorization/ResetPassword";
import SignUp from "./pages/Authorization/Signup";
import BrandingPage from "./pages/Branding/BrandingPage";
import CreateCheckoutSession from "./pages/CreateCheckoutSession";
import PrivacyPolicy from "./pages/Disclaimers/PrivacyPolicy";
import TermsAndConditions from "./pages/Disclaimers/TermsAndConditions";
import Integrations from "./pages/Integrations/Integrations";
import ManageAccount from "./pages/ManageAccount/ManageAccount";
import NotFoundPage from "./pages/NotFoundPage";
import PostsPageWrapper from "./pages/Posts/PostsPageWrapper";
import WelcomeToFamily from "./pages/WelcomeToFamily";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={REFERRAL_REROUTE_ROUTE} element={<ReferralReroute />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path={GENERATE_FOR_ANONYMOUS_ROUTE}
        element={
          <OpenGlobalModal
            modalData={{ anonymousUser: true }}
            modalName="create-business"
            markyOnly
          />
        }
      />
      <Route path="/" element={<IndexRoutePage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsAndConditions />} />
      <Route path="/preview" element={<Preview />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/welcome" element={<WelcomeToFamily />} />
        <Route
          path={POST_ANALYTICS_ROUTE}
          element={<PostAnalyticsProvider />}
        />
        <Route path="/frill-sso" element={<FrillSSO />} />
        <Route path="/dashboard" element={<AgencyDashboard />} />
        <Route
          path={ONBOARDING_ROUTE}
          element={<OpenGlobalModal markyOnly modalName="create-business" />}
        />
        <Route path={INTEGRATIONS_ROUTE} element={<Integrations />} />
        <Route path="/topics" element={<TopicsPage />} />
        <Route path="/business-profile" element={<BusinessProfilePage />} />
        <Route path="/library-media" element={<ImageLibrary />} />
        <Route path="/manage-account" element={<ManageAccount />} />
        <Route path="/branding" element={<BrandingPage />} />
        <Route path="/themes" element={<ThemesPage />} />
        <Route path="/memory" element={<MemoryPage />} />
        <Route path="/team" element={<TeamMembers />} />
        <Route path="/agency-team" element={<TeamMembers />} />
        <Route path="/clients" element={<TeamMembers />} />
        <Route path={POSTS_PAGE_ROUTE} element={<PostsPageWrapper />} />
        <Route path={POST_CALENDAR_ROUTE} element={<PostCalendarPage />} />
        <Route path="/white-label" element={<WhiteLabel />} />
      </Route>

      <Route path="/checkout" element={<CreateCheckoutSession />} />
      <Route path="/not-found" element={<NotFoundPage />} />
      <Route path="/accounts" element={<Navigate to="/dashboard" />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
      <Route path="/proxy" element={<IntegrationProxy />} />
    </Routes>
  );
};

export default AppRoutes;
