import { InfoCircleOutlined } from "@ant-design/icons";
import { message, Popover } from "antd";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { PostEditingDetails } from "pages/Posts/PostEditor/MiniEditor/miniEditorConstants";
import PostInputsPopoverContent from "pages/Posts/PostEditor/MiniEditor/PostInputsPopoverContent";
import { useRef, useState } from "react";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { useClickAway } from "react-use";
import NewGeneratePanel, { GeneratePostsFrom } from "./NewGeneratePanel";

const PostInputs = ({
  showAsPopover,
  postDetail,
  close,
}: {
  postDetail: PostEditingDetails;
  showAsPopover: boolean;
  close?: () => void;
}) => {
  const useNewGenerate = useTypedFeatureIsOn("new-generator-panel");

  const [generatePostsFrom, setGeneratePostsFrom] =
    useState<GeneratePostsFrom | null>(null);
  const [showInputsPopover, setShowInputsPopover] = useState(false);

  const inputsPopoverRef = useRef<HTMLDivElement>(null);

  useClickAway(inputsPopoverRef, () => setShowInputsPopover(false));

  return (
    <>
      {showAsPopover ? (
        <Popover
          title="Post Inputs"
          open={showInputsPopover}
          align={{ offset: [-14, -8] }}
          content={
            <PostInputsPopoverContent
              ref={inputsPopoverRef}
              postDetail={postDetail}
              onOpenGeneratePanel={(generateFrom) => {
                setGeneratePostsFrom(generateFrom);
                setShowInputsPopover(false);
              }}
            />
          }
        >
          <div
            onClick={() => setShowInputsPopover(!showInputsPopover)}
            className="flex gap-1.5 items-center cursor-pointer"
          >
            Post Inputs
            <InfoCircleOutlined />
          </div>
        </Popover>
      ) : (
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col w-full"
        >
          <div className="flex justify-between items-center">
            <span>Post Inputs</span>
            <MdOutlineVisibilityOff
              onClick={() => close && close()}
              className="cursor-pointer"
            />
          </div>
          <PostInputsPopoverContent
            postDetail={postDetail}
            onOpenGeneratePanel={(generateFrom) => {
              setGeneratePostsFrom(generateFrom);
              showAsPopover && close && close();
            }}
          />
        </div>
      )}
      {useNewGenerate && generatePostsFrom && (
        <NewGeneratePanel
          generateFrom={generatePostsFrom}
          onSubmit={() => {
            setGeneratePostsFrom(null);
            message.success("Post generation started");
          }}
          onCancel={() => setGeneratePostsFrom(null)}
        />
      )}
    </>
  );
};

export default PostInputs;
