import { ConfigProvider, Empty, Select } from "antd";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { getGoogleFontImage, getGoogleFontsListAPI } from "polotno/config";
import { TextElementType } from "polotno/model/text-model";
import { useCallback, useEffect, useMemo, useState } from "react";

const FontSelector: ObserverComponent<{ element: TextElementType }> = observer(
  ({ element }) => {
    const [fonts, setFonts] = useState<string[]>([]);

    const fontValue = useMemo(() => {
      if (
        !element.fontFamily?.includes("https") ||
        !element.fontFamily?.includes("__")
      ) {
        return element.fontFamily;
      }

      try {
        return decodeURIComponent(element.fontFamily).substring(
          element.fontFamily.lastIndexOf("__") + 2,
          element.fontFamily.lastIndexOf(".") - 4
        );
      } catch (error) {
        return "Custom Uploaded Font";
      }
    }, [element.fontFamily]);

    const options = useMemo(() => {
      return fonts.map((font) => ({
        value: font,
        label: (
          <img src={getGoogleFontImage(font)} className="h-5 dark:invert" />
        ),
      }));
    }, [fonts]);

    // TODO: use useQuery to get the fonts, combine duplicate logic here and in YourBrandCard
    const fetchFonts = useCallback(async () => {
      const url = getGoogleFontsListAPI();
      const response = await axios.get<string[]>(url);
      setFonts(
        response.data.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
        )
      );
    }, []);

    useEffect(() => {
      if (!fonts.length) {
        fetchFonts();
      }
    }, []);

    return (
      <ConfigProvider
        renderEmpty={() => (
          <Empty className="font-not-found" description="Font not found" />
        )}
      >
        <Select
          getPopupContainer={() =>
            document.querySelector(".go3456988929") as HTMLElement
          }
          onChange={(value) => element.set({ fontFamily: value })}
          popupClassName="text-font-selector-dropdown"
          placeholder="Select from given fonts"
          className="text-font-selector !-ml-0.5"
          loading={!fonts.length}
          options={options}
          value={fontValue}
          showSearch
        />
      </ConfigProvider>
    );
  }
);

export default FontSelector;
