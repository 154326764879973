import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { StoreType } from "polotno/model/store";
import { ANIMATION_CONFIG } from "./animationConstants";
import AnimationSettings from "./AnimationSettings";

const getTabItems = (store: StoreType) => {
  return ["entry", "emphasis", "exit"].map((key) => ({
    key,
    label: key,
    children: (
      <AnimationSettings
        element={store.selectedElements?.[0]}
        config={ANIMATION_CONFIG[key]}
      />
    ),
  }));
};

const AnimationsPanel: ObserverComponent = observer(({ store }) => {
  const selectedElement = store.selectedElements?.[0];

  if (!selectedElement) {
    return (
      <div className="flex items-center justify-center h-48">
        Select an element to animate
      </div>
    );
  }

  return (
    <Tabs className="w-full" defaultActiveKey="1" items={getTabItems(store)} />
  );
});

export default AnimationsPanel;
