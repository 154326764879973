import { fetchImageSearchTerms } from "api/businesses";
import {
  Paths,
  PlanRestrictions,
  Realm,
  Subscription,
  UpdateUserBody,
  User,
  UserResponse,
} from "api/config/chalice-api";
import { connectSocial } from "api/integrations";
import {
  fetchRealm,
  fetchUserDetails,
  updateRealm,
  updateUser,
} from "api/user";
import { Business } from "store/business/businessConstants";
import { fetchPostsQuotaThunk } from "store/posts/postActions";
import { PostCommentData } from "store/posts/postConstants";
import { createAppAsyncThunk } from "store/storeHelpers";
import { Integration, UserInfo } from "./userConstants";

export type TypesafeChaliceUser = User & {
  id: string;
  invoice_data: {
    current_period_end?: number;
    next_invoice_amount?: string;
  };
  subscription: Subscription & {
    is_appsumo?: boolean;
    // TODO: BE to add Subscription.restrictions to swagger def and use that instead
    restrictions?: PlanRestrictions;
  };
  is_agency?: boolean;
};

export type TypesafeUserResponse = UserResponse & {
  businesses: Business[];
  user: TypesafeChaliceUser;
};

export const getUserDetails = createAppAsyncThunk<TypesafeUserResponse>(
  "posts/getUserDetails",
  async (_, { dispatch }) => {
    const userResponse = await fetchUserDetails();
    await dispatch(fetchPostsQuotaThunk());
    return userResponse;
  }
);

export const updateUserDetails = createAppAsyncThunk(
  "posts/updateUserDetails",
  (payload: UpdateUserBody) => updateUser(payload) as Promise<UserInfo>
);

export const updateRealmDetails = createAppAsyncThunk(
  "posts/updateRealmDetails",
  (payload: Realm) => updateRealm(payload)
);

export const fetchRealmDetails = createAppAsyncThunk(
  "posts/fetchRealmDetails",
  (payload: string) => fetchRealm(payload)
);

export const fetchSearchTerms = createAppAsyncThunk(
  "posts/fetchSearchTerms",
  async (_: undefined, { getState }) => {
    const businessId = getState().user.currentBusiness.id;
    const user = await fetchImageSearchTerms(businessId);
    return user;
  }
);

export const addIntegration = createAppAsyncThunk(
  "user/addIntegration",
  async (
    data: Omit<Paths.V1CreateIntegration.RequestBody, "business_id">,
    { getState }
  ): Promise<Integration> => {
    const businessId = getState().user.currentBusiness.id;
    const { integration } = await connectSocial({
      ...data,
      business_id: businessId,
    });

    return integration as Integration;
  }
);

export const getFullName = (
  user: PostCommentData | User,
  { fallbackToEmail, fallbackName } = {
    fallbackToEmail: true,
    fallbackName: "Guest",
  }
) =>
  user.given_name
    ? `${user?.given_name?.trim()} ${user?.family_name?.trim()}`.trim()
    : fallbackToEmail && "email" in user && user.email
      ? user.email
      : fallbackName;
