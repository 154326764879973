import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { ImageElementType } from "polotno/model/image-model";
import { MdPhotoSizeSelectActual } from "react-icons/md";
import ImageSearch from "./ImageSearch";

const ImageChanger: ObserverComponent<{ element: ImageElementType }> = observer(
  ({ element }) => {
    if (window.innerWidth > 800) {
      return (
        <Tooltip title="This functionality has been moved. Drag/drop images from the side panel onto an existing image to replace it.">
          <Button
            icon={<MdPhotoSizeSelectActual className="scale-125" />}
            className="h-8"
            disabled
          >
            Change Image
          </Button>
        </Tooltip>
      );
    }

    return (
      <ImageSearch
        handleImageClick={(imageUrl: string) => element.set({ src: imageUrl })}
      />
    );
  }
);

export default ImageChanger;
