import { useMemo } from "react";
import { Post } from "store/posts/postConstants";

export const getPostRenderInfo = (post: Post) => {
  const isVideo = post.render_design_as === "VIDEO";

  return {
    isVideo,
    isVideoRendering: isVideo && post.media_rendering_status === "PENDING",
    isCarousel: !isVideo && (post.media_urls?.length ?? 0) > 1,
  };
};

export const useGetPostRenderInfo = (post: Post) => {
  const renderInfo = useMemo(
    () => getPostRenderInfo(post),
    [post.render_design_as, post.media_urls, post.media_rendering_status]
  );

  return renderInfo;
};
