import { Tooltip } from "antd";
import { INTEGRATIONS_ROUTE } from "pages/Integrations/components/IntegrationSettings/settingConstants";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
import { integrationsGetter } from "store/user/userSlice";

const ConnectSocialsTooltip = ({ children }: { children: React.ReactNode }) => {
  const connectedSocials = useAppSelector(integrationsGetter);

  return (
    <Tooltip
      title={
        !connectedSocials.length ? (
          <p>
            <Link to={INTEGRATIONS_ROUTE} className="underline text-inherit">
              Connect a social
            </Link>{" "}
            to enable this feature
          </p>
        ) : (
          ""
        )
      }
    >
      {children}
    </Tooltip>
  );
};

export default ConnectSocialsTooltip;
