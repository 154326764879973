import { SearchOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import FileUploadButton from "designSystem/FileUploadButton";
import useImageLibrary from "pages/ImageLibrary/useImageLibrary";
import LoadingIndicator from "pages/Posts/components/LoadingIndicator";
import { addPolotnoElement } from "pages/Posts/PostEditor/polotnoUtil";
import { StoreType } from "polotno/model/store";
import { useEffect, useMemo, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useAppDispatch, useAppSelector } from "store";
import { fetchImagesInLibrary } from "store/imageLibrary/imageLibraryActions";
import { LibraryImage } from "store/imageLibrary/imageLibraryConstants";
import { addDateCacheBusterParam } from "utils/addNoCacheParam";
import CustomPhotosPanel from "./CustomPhotosPanel";

const ImageLibraryPanel: ObserverComponent<{ store: StoreType }> = ({
  store,
}) => {
  const { isMediaLibraryFetched, isLoadingImages, imagesInLibrary } =
    useAppSelector((state) => state.imageLibrary);
  const dispatch = useAppDispatch();

  const { handleImageUpload } = useImageLibrary();

  const [showStockPhotos, setShowStockPhotos] = useState(false);

  useEffect(() => {
    !isLoadingImages &&
      !isMediaLibraryFetched &&
      dispatch(fetchImagesInLibrary());
  }, [isMediaLibraryFetched, isLoadingImages]);

  const uploadImages = async (files: File[]) => {
    const filesAdded = await handleImageUpload(files);

    if (filesAdded.length) {
      message.success(
        `Image${filesAdded.length > 1 ? "s" : ""} uploaded successfully.`
      );
      const doc = document.querySelector(".go4160152499");
      doc && doc.scrollTo(0, 100000);
    }
  };

  const selectImage = async (image: LibraryImage) =>
    addPolotnoElement({
      store,
      src: addDateCacheBusterParam(image.file.url),
    });

  const splitImages = useMemo(
    () =>
      imagesInLibrary.reduce(
        (prev, cur, index) => {
          index % 2 === 0 ? prev.left.push(cur) : prev.right.push(cur);
          return prev;
        },
        {
          left: [] as LibraryImage[],
          right: [] as LibraryImage[],
        }
      ),
    [imagesInLibrary]
  );

  return (
    <div className="flex flex-col min-h-0 overflow-hidden">
      <div className="flex items-center mb-3 gap-2">
        {showStockPhotos ? (
          <>
            <MdArrowBack
              onClick={() => setShowStockPhotos(false)}
              className="cursor-pointer"
            />
            <span>Stock Photos</span>
          </>
        ) : (
          <>
            <FileUploadButton
              id="image-library-panel-upload"
              onChange={uploadImages}
              isSmall
            />
            <Button
              onClick={() => setShowStockPhotos(true)}
              icon={<SearchOutlined />}
              className="h-8"
            >
              Search Stock
            </Button>
          </>
        )}
      </div>
      {showStockPhotos ? (
        <CustomPhotosPanel store={store} />
      ) : isLoadingImages ? (
        <LoadingIndicator className="mx-auto">Loading library</LoadingIndicator>
      ) : (
        <div className="overflow-auto grid grid-cols-2 gap-4">
          {Object.values(splitImages).map((imageList, index) => (
            <div className="space-y-2" key={index}>
              {imageList.map((image, index) =>
                image.isUploading ? (
                  <Skeleton key={index} height={200} />
                ) : (
                  <img
                    key={index}
                    onClick={() => selectImage(image)}
                    src={`${image.file.url}?sidebar=true`}
                    className="rounded shadow-md cursor-pointer"
                    loading="lazy"
                  />
                )
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageLibraryPanel;
