import { Button, Form, StepProps, Steps } from "antd";
import { useForm } from "antd/es/form/Form";
import { UpdateBusinessBody } from "api/config/chalice-api";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import { SCRAPED_IMAGES } from "fixtures/localStorageKeys";
import { isEqual } from "lodash";
import { DEFAULT_PALETTE } from "pages/Posts/PostEditor/ImageEditor/ColorPaletteSelect/PRESET_PALETTES";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage, usePrevious } from "react-use";
import { useAppDispatch } from "store";
import { updateBusinessDetails } from "store/business/businessActions";
import { generatePostsThunk } from "store/posts/postActions";
import { ONBOARDING_FORM_SECTIONS } from "../businessFormConstants";
import { BusinessDetails, IteratePartFn } from "../businessOnboardingConstants";
import NewUserPreferences from "../components/NewUserPreferences";
import OnboardingModalTemplate from "../components/OnboardingModalTemplate";
import BusinessSectionTemplate from "./StepperSectionTemplate";
import ONBOARDING_STEPPER_IMAGE_DICT from "./onboardingStepperImageDict";

const BusinessOnboardingStepper = ({
  openConnectSocialsStepState,
  saveBusinessDetails,
  initialValues,
  uploadImages,
  iteratePart,
  newUser,
}: {
  saveBusinessDetails: (details: BusinessDetails) => void;
  initialValues: Partial<BusinessDetails>;
  openConnectSocialsStepState: boolean;
  iteratePart: IteratePartFn;
  uploadImages: (images?: string[]) => void;
  newUser?: boolean;
}) => {
  const showSocialsStep = useTypedFeatureIsOn(
    "connect-socials-during-onboarding"
  );

  const [
    storedScrapedImages,
    setStoredScrapedImages,
    removeStoredScrapedImages,
  ] = useLocalStorage<{
    [key: string]: boolean;
  }>(SCRAPED_IMAGES);

  const dispatch = useAppDispatch();

  const [businessDetails] = useForm<BusinessDetails>();
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [onUserPrefsStep, setOnUserPrefsStep] = useState(false);

  const steps = useMemo(
    () =>
      ONBOARDING_FORM_SECTIONS.filter(
        ({ key }) => key !== "connect-socials" || showSocialsStep
      ),
    [showSocialsStep]
  );

  const [stepperIndex, setStepperIndex] = useState(
    openConnectSocialsStepState
      ? steps.findIndex((step) => step.key === "connect-socials")
      : 0
  );

  const {
    StepComponent,
    items: stepItems,
    ...currentStep
  } = steps[stepperIndex];

  const imageSrc = onUserPrefsStep
    ? ONBOARDING_STEPPER_IMAGE_DICT.userPreferences
    : ONBOARDING_STEPPER_IMAGE_DICT[currentStep.key];
  const imageBg = usePrevious(imageSrc);

  const totalSteps = steps.length;
  const showSubmitButton = stepperIndex === totalSteps - 1;

  const iterateStep: IteratePartFn = async (direction) => {
    if (direction === "next" && stepperIndex < totalSteps - 1) {
      const newIndex = stepperIndex + 1;
      if (steps[newIndex].key === "connect-socials") {
        setStoredScrapedImages(
          initialValues?.scrapedImages?.reduce<{ [key: string]: boolean }>(
            (acc, image) => {
              acc[image] = businessDetails
                .getFieldValue("images")
                .includes(image);
              return acc;
            },
            {}
          )
        );
        await updateBusiness();
      }
      setStepperIndex(newIndex);
    } else if (direction === "previous") {
      if (stepperIndex === 0) {
        saveBusinessDetails(businessDetails.getFieldsValue(true));
        iteratePart("previous");
      } else if (onUserPrefsStep) {
        setOnUserPrefsStep(false);
      } else {
        setStepperIndex(stepperIndex - 1);
      }
    }

    formWrapperRef.current?.parentElement?.scroll({ top: 0 });
  };

  const uploadSelectedImages = () =>
    uploadImages(businessDetails.getFieldValue("images"));

  const submitForm = async () => {
    const updatePromise = updateBusiness();

    if (newUser) {
      setOnUserPrefsStep(true);
    } else {
      uploadSelectedImages();
      await updatePromise;
      iteratePart("next");
    }
  };

  const updateBusiness = async () => {
    const formData: BusinessDetails = businessDetails.getFieldsValue(true);

    setSubmitLoading(true);

    if (!isEqual(initialValues, formData)) {
      const { accentColor, logoUrl, themes, topics, ctas, ...data } = formData;

      await dispatch(
        updateBusinessDetails({
          palettes: [
            {
              ...DEFAULT_PALETTE,
              ...(accentColor && { accent_color: accentColor }),
            },
          ],
          logo: logoUrl,
          themes,
          topics: topics?.filter(({ body }) => body),
          ctas: ctas?.filter((value) => value),
          ...(data as UpdateBusinessBody),
        })
      );
    }

    await dispatch(generatePostsThunk());
    setSubmitLoading(false);
  };

  useEffect(() => {
    return () => removeStoredScrapedImages();
  }, []);

  return onUserPrefsStep ? (
    <NewUserPreferences
      {...{ imageSrc, imageBg, uploadSelectedImages }}
      parentSubmitting={submitLoading}
      iteratePart={(direction) =>
        direction === "previous"
          ? iterateStep(direction)
          : iteratePart(direction)
      }
    />
  ) : (
    <OnboardingModalTemplate
      {...{ imageSrc, imageBg }}
      open
      hideTransitions
      header={currentStep.header}
      subHeader={currentStep.subHeader}
      title={
        <div className="space-y-5">
          <div className="space-y-1">
            <span className="text-xs text-antd-colorTextDescription font-normal">
              Step {stepperIndex + 1}/{totalSteps}
            </span>
            <Steps
              progressDot
              responsive={false}
              size="small"
              current={stepperIndex}
              items={steps.map(({ key }) => ({ key })) as StepProps[]}
              className="-ml-[64px] w-[calc(100%_+_64px)] [&_.ant-steps-item-content]:!hidden [&_:first-child_.ant-steps-items-tail]:!ml-0"
            />
          </div>
        </div>
      }
      footer={
        <div className="flex gap-2">
          <Button
            onClick={() => iterateStep("previous")}
            disabled={submitLoading}
          >
            Back
          </Button>
          {
            <Button
              className="flex-grow"
              type="primary"
              form="stepperForm"
              key={showSubmitButton ? "submit" : "next"}
              htmlType={showSubmitButton ? "submit" : undefined}
              onClick={() => iterateStep("next")}
              loading={submitLoading}
            >
              {showSubmitButton && !newUser ? "Finish" : "Next"}
            </Button>
          }
        </div>
      }
    >
      <div ref={formWrapperRef}>
        <Form
          disabled={submitLoading}
          initialValues={{
            ...initialValues,
            ...(storedScrapedImages && {
              scrapedImages: Object.keys(storedScrapedImages),
              images: Object.entries(storedScrapedImages).reduce<string[]>(
                (acc, [image, isSelected]) => {
                  isSelected && acc.push(image);
                  return acc;
                },
                []
              ),
            }),
          }}
          form={businessDetails}
          onFinish={submitForm}
          layout="vertical"
          id="stepperForm"
        >
          {StepComponent && <StepComponent form={businessDetails} />}
          {stepItems && (
            <BusinessSectionTemplate
              form={businessDetails}
              sectionItems={stepItems}
            />
          )}
        </Form>
      </div>
    </OnboardingModalTemplate>
  );
};

export default BusinessOnboardingStepper;
