import { ReactComponent as MarkyLogo } from "assets/icons/new-logo.svg";
import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import addStaticCacheBusterParam from "utils/addNoCacheParam";

const Logo = () => {
  const { pathname } = useLocation();
  const { isAgencyMember, isAgencyOwner } = useAppSelector(({ user }) => user);
  const { realm } = useAppContext();
  const [logoLoading, setLogoLoading] = useState(true);

  useEffect(() => {
    realm.isMarky && setLogoLoading(false);
  }, [realm]);

  return realm.isMarky ? (
    <div className="flex h-full gap-1">
      <MarkyLogo className="h-full" />
      {(isAgencyMember || isAgencyOwner) && (
        <span
          className="text-antd-colorWhite text-[8px] font-semibold rounded-sm px-1 py-0.5 self-start"
          style={{
            background: "linear-gradient(98deg, #bb40f5 2.1%, #6440f5 100%)",
          }}
        >
          Agency
        </span>
      )}
    </div>
  ) : (
    realm.logoUrl && (
      <>
        {logoLoading && <Skeleton className="h-full" />}

        <div className="h-full flex items-center gap-3">
          <img
            className={classNames("h-full aspect-square object-cover", {
              hidden: logoLoading,
            })}
            onLoad={() => setLogoLoading(false)}
            onError={() => setLogoLoading(false)}
            src={addStaticCacheBusterParam(realm.logoUrl)}
          />
          {realm.name && !logoLoading && (
            <strong
              className={classNames(
                "text-lg truncate",
                ["/login", "/sign-up"].includes(pathname)
                  ? "max-w-md"
                  : "max-w-40"
              )}
            >
              {realm.name}
            </strong>
          )}
        </div>
      </>
    )
  );
};

export default Logo;
