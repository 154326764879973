import AgencyNameModal from "./AgencyNameModal";
import NewPostGenerationsModal from "./NewPostGenerationsModal";
import OnboardingTour from "./OnboardingTour/OnboardingTour";

const GlobalModals = () => (
  <>
    <AgencyNameModal />
    <OnboardingTour />
    <NewPostGenerationsModal />
    {/* <NewVersionPrompt /> */}
  </>
);

export default GlobalModals;
