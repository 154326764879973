import {
  BusinessResponse,
  ContentTopic,
  Palette,
} from "api/config/chalice-api";

export type ScrapedBusinessData = {
  content?: string;
  palette?: Palette;
  logoUrl?: string;
  socialHandle?: string;
  website?: string | null;
  scrapedImages?: string[];
  images?: string[];
  topics?: ContentTopic[];
};

export type BusinessDetails = Omit<ScrapedBusinessData, "palette"> & {
  accentColor?: string;
  themes?: string[];
  images?: string[];
  ctas?: string[];
} & Partial<BusinessResponse>;

export const ONBOARDING_PARTS = [
  "summary",
  "generating",
  "signup",
  "skip-to-posts",
  "onboarding-stepper",
] as const;

export type IteratePartFn = (direction: "next" | "previous") => void;
