import { Button } from "antd";
import GlowBrandIdentity from "assets/icons/GlowBrandIdentity";
import { useState } from "react";
import { useAppDispatch } from "store";
import { generatePostsThunk } from "store/posts/postActions";
import { trackEvent } from "utils/eventTracking/trackEvents";
import OnboardingModalTemplate from "../components/OnboardingModalTemplate";

const SkipToPosts = ({
  uploadImages,
  iterateNext,
  onClose,
  newUser,
}: {
  iterateNext: () => void;
  uploadImages: () => void;
  onClose: () => void;
  newUser?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [generateLoading, setGenerateLoading] = useState(false);

  const skipToPosts = async () => {
    setGenerateLoading(true);
    uploadImages();
    await dispatch(generatePostsThunk());

    newUser && trackEvent("tutorial_complete");

    setGenerateLoading(false);
    onClose();
  };

  return (
    <OnboardingModalTemplate
      open
      hideTransitions
      centerTitle
      header="A great profile makes great posts."
      subHeader="We set up everything up for you, but you can tweak things further. The better the profile, the better the posts."
      title={<GlowBrandIdentity />}
      footer={
        <div className="flex gap-2">
          <Button loading={generateLoading} onClick={() => skipToPosts()}>
            Skip to Posts
          </Button>
          <Button
            disabled={generateLoading}
            type="primary"
            className="flex-grow"
            onClick={iterateNext}
          >
            Improve Post Quality
          </Button>
        </div>
      }
    />
  );
};

export default SkipToPosts;
